import { AppActions, AppState } from "../store";
import { postRequest, getRequest } from "../utils/utils";
import { Dispatch } from "react";
import setAuthToken from "../utils/setAuthToken";
import axios from "axios";
import Error from "../types/models/Error";
import Alert from "../types/models/Alert";
import uuid from "uuid";
import { setAlert } from "../utils/utils";

// types
import User from "../types/models/User";
import { AxiosResponse } from "axios";
import { setAlertAction, removeAlertAction } from "./alerts";

export const createUserAction = (user: User): AppActions => ({
  type: "CREATE_USER",
  user,
});

export const getUserAction = (user: User): AppActions => ({
  type: "GET_USER",
  user,
});

export const removeUserAction = (): AppActions => ({
  type: "REMOVE_USER",
});

export const getAuthTokenAction = (user: User): AppActions => ({
  type: "GET_AUTH_TOKEN",
  user,
});

// export const createUser = (user: User) => {
//   return postRequest<User>(user, "/api/users", createUserAction);
// };

export interface LoginInfo {
  email: string;
  password: string;
}
export const getAuthToken = (loginInfo: LoginInfo) => {
  return postRequest(loginInfo, "/api/users/login", getAuthTokenAction);
};

export const getUser = (userId: string) => {
  return getRequest(userId, `/api/users/host/${userId}`, getUserAction);
};

export const removeUser = () => (dispatch: Dispatch<AppActions>) => {
  setAuthToken();
  dispatch(removeUserAction());
};

// export const getAuthToken = (loginInfo: LoginInfo) => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       "Accept-Language": "ko",
//     },
//   };

//   const body = JSON.stringify(loginInfo);

//   try{
//     const res: AxiosRe
//   }catch(err) {

//   }
// }

export const createUser = (user: User) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  // const {email, name, company, countryNumber, phoneNumber, password, role} = user;
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(user);

  try {
    const res: AxiosResponse<User> = await axios.post(
      "/api/users",
      body,
      config
    );
    return res.data;
  } catch (err) {
    const error: Error = err.response.data;

    let message: string = "";
    switch (err.response.status) {
      case 403:
        message = "접근권한이 없습니다.";
        break;
      case 500:
        message = "접근할 수 없습니다.";
        break;
      default:
        message = error.detail;
    }

    const alert: Alert = {
      alertType: "warning",
      id: uuid.v4(),
      msg: message,
    };

    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

// 아이디 찾기
export const getFindEmail = (name: string, phoneNumber: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/users/findUserEmail?name=${name}&phoneNumber=${phoneNumber}&role=host`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    return res.data;
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

// 비밀번호 변경 코드 이메일 발송
export const postPwdCode = (email: { [key: string]: string }) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  // const {email, name, company, countryNumber, phoneNumber, password, role} = user;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(email);

  try {
    const res: AxiosResponse = await axios.post(
      `/api/users/postPwdCode`,
      body,
      config
    );
    return res.data;
  } catch (err) {
    const error: Error = err.response.data;

    let message: string = error.detail;

    const alert: Alert = {
      alertType: "warning",
      id: uuid.v4(),
      msg: message,
    };

    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

// 비밀번호 코드로 이메일 찾기
export const findEmailByCode = (code: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/users/findEmailByCode?code=${code}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    return res.data;
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

// 비밀번호 변경
export const changePwd = (changeData: any) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(changeData);

  const path = "/api/users/changePwd";
  try {
    var res: AxiosResponse = await axios.put(path, body, config);

    return res.data;
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return false;
  }
};
