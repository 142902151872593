import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { Column, MTableToolbar } from "material-table";

// actions
import { getPrograms } from "../../../../actions/program";
import { AppState } from "../../../../store";

// custom ui comp
import PxTable from "../../../../components/Table/PxTable";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import PxGridContainer from "../../../Grid/PxGridContainer";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";

interface RowType {
  programTitle: string;
  programCreatedDate: string;
  programSpeakerCount: number;
  programDate: string;
  programUpdatedDate: string;
  programId: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const ProgramList: React.FC<RouteComponentProps> = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const param: { id: string } = useParams();

  const programs = useSelector((state: AppState) => state.programs.programs);
  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: "제목",
        field: "programTitle",
        cellStyle: {
          width: "calc(100%-600px)",
          paddingRight: 0,
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "기간",
        field: "programDate",
        cellStyle: {
          width: "200px",
        },
      },
      {
        title: "스피커 수",
        field: "programSpeakerCount",
        cellStyle: { width: "120px" },
      },
      {
        title: "등록일",
        field: "programCreatedDate",
        cellStyle: { width: "110px" },
      },
      {
        title: "수정일",
        field: "programUpdatedDate",
        cellStyle: { width: "110px" },
      },
    ],
    data: [],
  });

  const callGetPrograms = useCallback(async () => {
    dispatch(getPrograms(param.id));
    dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  useEffect(() => {
    callGetPrograms();
  }, [callGetPrograms]);

  useEffect(() => {
    let tableData: any = [];
    if (Array.isArray(programs) && programs.length > 0) {
      programs.map((program) => {
        const rowData = {
          programTitle: program.title,
          programDate: `${moment(program.startDate).format(
            "YYYY-MM-DD"
          )} ~ ${moment(program.endDate).format("YYYY-MM-DD")}`,
          programSpeakerCount: `${
            program.speakers !== undefined ? program.speakers.length : 0
          } 개`,
          programCreatedDate: moment(program.createdDate).format("YYYY-MM-DD"),
          programUpdatedDate: moment(program.modifiedDate).format("YYYY-MM-DD"),
          programId: program.id,
        };
        tableData.push(rowData);
      });
    }
    setState((prevCreateDate) => {
      return { ...prevCreateDate, data: tableData };
    });
  }, [programs]);

  return (
    <>
      <div style={{ textAlign: "right", margin: "10px" }}>
        <PxButton
          backgroundcolor="purple"
          onClick={() => history.push(`${match.url}/add`)}
        >
          <ButtonTypo>프로그램 추가</ButtonTypo>
        </PxButton>
      </div>

      <PxTable<RowType>
        components={{
          Toolbar: (props) => {
            return (
              <PxGridContainer direction="column">
                {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                <MTableToolbar {...props} />
              </PxGridContainer>
            );
          },
        }}
        title="Basic Selection Preview"
        columns={state.columns}
        data={state.data}
        onRowClick={(evt, selectedRow) => {
          history.push(`${match.url}/${selectedRow?.programId}`);
        }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
      />
    </>
  );
};

export default ProgramList;
