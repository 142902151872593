import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { AppActions, AppState } from "../store";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import Meeting, {
  MeetingInfo,
  ChangeMeetingStateInterface,
  MeetingVideo,
  PagingMeetingInfo,
  MeetingUpdate,
} from "../types/models/Meeting";
import { ApplicationInfo } from "../types/models/Application";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { getRequest, postRequest, setAlert } from "../utils/utils";
import { setProgressAction } from "./progresses";

// 미사용중
export const createMeetingAction = (meeting: MeetingInfo): AppActions => ({
  type: "CREATE_MEETING",
  meeting,
});

export const getMeetingsAction = (meetings: MeetingInfo[]): AppActions => ({
  type: "GET_MEETINGS",
  meetings,
});

export const getMatchingList = (matchingList : PagingMeetingInfo) :AppActions => ({
  type: "GET_MATCHINGLIST",
  matchingList,
});

// 미사용중
export const getMeetingAction = (meeting: MeetingInfo): AppActions => ({
  type: "GET_MEETING",
  meeting,
});

export const getMeetingTargetsAction = (
  meetingTargets: ApplicationInfo[]
): AppActions => ({
  type: "GET_MEETING_TARGETS",
  meetingTargets,
});

export const getWaitingAcceptMeetingTargetsAction = (
  waitingAcceptMeetingTargets: ApplicationInfo[]
): AppActions => ({
  type: "GET_WAITING_ACCEPT_MEETING_TARGETS",
  waitingAcceptMeetingTargets,
});

export const resetMeetingTargetsAction = (): AppActions => ({
  type: "MEETING_TARGETS_RESET",
});

export const resetWaitingAcceptMeetingTargetsAction = (): AppActions => ({
  type: "WAITING_ACCEPT_MEETING_TARGETS_RESET",
});

export const resetMeetingsAction = (): AppActions => ({
  type: "MEETINGS_RESET",
});

export const getMeetingVideoAction = (
  meetingVideo: MeetingVideo
): AppActions => ({
  type: "SET_MEETING_VIDEO",
  meetingVideo,
});

export const getMeetingTotalCountAction = (meetingTotalCount : Meeting["meetingTotalCount"]):AppActions => ({
  type: "GET_MEETING_TOTAL_COUNT",
  meetingTotalCount
})

export const meetingOrderUpdate = (meetingData: MeetingUpdate) => {
  return postRequest(meetingData, "/api/meetings/meetingOrderUpdate");
}

// 미사용중
export const createMeeting = (meeting: MeetingInfo) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(meeting);

  const path = `/api/meetings`;
  try {
    var res: AxiosResponse<Event> = await axios.post(path, body, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

export const getMeetings = <Meeting>(subEventId: string,page:number=0) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/meetings?subEventId=${subEventId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getMeetingsAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

export const getMonitoringMeetings = <Meeting>(subEventId: string,page:number=0) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/meetings/monitoringMeetings?subEventId=${subEventId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getMeetingsAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};



export const getMatchings = <Meeting>(subEventId:string,page:number=0,
  pageSize: number =10,
  searchWord:string="") => async (
  dispatch: Dispatch<AppActions>,
  getState:() => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, 
  };
  const path = `/api/meetings/matchingList?subEventId=${subEventId}&page=${page}&size=${pageSize}&searchWord=${searchWord}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getMatchingList(res.data));
    return res.data;
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
}

// 미사용중
export const getMeeting = <Meeting>(meetingId: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/meetings/detail?meetingId=${meetingId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getMeetingAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

// 미팅현황 - 미팅 대상 리스트 조회
export const getMeetingTargets = <Meeting>(
  meetingId: string,
  subEventId: string,
  applicationId: string,
  type: string,
  searchName: string,
  searchKeyword: string,
  searchCategory: string
) => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/applications/getMeetingTargets?meetingId=${meetingId}&subEventId=${subEventId}&applicationId=${applicationId}&type=${type}&searchName=${searchName}&searchKeyword=${searchKeyword}&searchCategory=${searchCategory}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getMeetingTargetsAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

// 미팅현황 - 미팅 대상 리스트 초기화
export const meetingTargetsActionStateToInitial = () => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  dispatch(resetMeetingTargetsAction());
};

// 미팅 수락대기 리스트 조회
export const getWaitingAcceptMeetingTargets = <Meeting>(
  meetingId: string,
  applicationId: string
) => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/meetings/getWaitingAcceptMeetingTargets?meetingId=${meetingId}&applicationId=${applicationId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getWaitingAcceptMeetingTargetsAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

// 미팅 수락대기 리스트 조회 리스트 초기화
export const waitingAcceptMeetingTargetsActionStateToInitial = () => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  dispatch(resetWaitingAcceptMeetingTargetsAction());
};

// 미팅 신청
interface MeetingApplicantDataInterface {
  subEventId: string;
  applicant: string;
  acceptor: string;
  meetingId: string;
  status: string;
  mailPostToggleAt?: string;
}
export const applicationMeeting = (
  meetingApplicantData: MeetingApplicantDataInterface
) => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(meetingApplicantData);

  const path = `/api/meetings/applicant`;
  try {
    var res: AxiosResponse = await axios.post(path, body, config);
    return true;
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return false;
  }
};

// 미팅 신청 취소
export const cancelApplicationMeeting = (meetingsId: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    params: {},
    data: {},
  };
  const mailPostToggleAt =
    localStorage.getItem("mailPostToggleAt") === null
      ? "false"
      : localStorage.getItem("mailPostToggleAt")!.toString();

  const path = `/api/meetings/cancelApplicationMeeting/${meetingsId}/${mailPostToggleAt}`;
  try {
    const res: AxiosResponse = await axios.delete(path, config);
    return true;
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return false;
  }
};

// 사용자 미팅 불가시간 제거
export const removeUserImpossibleMeeting = (meetingsId: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    params: {},
    data: {},
  };
  const mailPostToggleAt =
    localStorage.getItem("mailPostToggleAt") === null
      ? "false"
      : localStorage.getItem("mailPostToggleAt")!.toString();

  const path = `/api/meetings/removeImpossible/${meetingsId}/${mailPostToggleAt}`;
  try {
    const res: AxiosResponse = await axios.delete(path, config);
    return true;
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return false;
  }
};

// 내 미팅 신청리스트 조회
export const getMyMeetings = <Meeting>(applicationId: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/meetings/myMeeting?applicationId=${applicationId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getMeetingsAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

// 미팅 목록 초기화
export const meetingsActionStateToInitial = () => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  dispatch(resetMeetingsAction());
};

// 미팅 수락 or 거절
export const changeMeetingState = (
  changeMeetingStateData: ChangeMeetingStateInterface
) => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(changeMeetingStateData);

  const path = `/api/meetings/changeMeetingState`;
  try {
    var res: AxiosResponse<Event> = await axios.put(path, body, config);

    return true;
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return false;
  }
};

// 전체 매칭
export const allMatching = (matchingParam: any) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };
  dispatch(setProgressAction({ open: "true" }));
  const body = JSON.stringify(matchingParam);
  const path = `/api/meetings/allMatching`;
  try {
    var res: any = await axios.post(path, body, config);
    dispatch(setProgressAction({ open: "false" }));
    return res.data;
  } catch (err) {
    dispatch(setProgressAction({ open: "false" }));
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

// 전체 매칭 취소
export const allMatchingCancel = (matchingParam: any) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };
  dispatch(setProgressAction({ open: "true" }));
  const path = `/api/meetings/allMatchingCancel`;
  try {
    const body = JSON.stringify(matchingParam);
    var res: any = await axios.post(path, body, config);
    dispatch(setProgressAction({ open: "false" }));
    return "success";
  } catch (err) {
    dispatch(setProgressAction({ open: "false" }));
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

// 선택 매칭
export const choiceMatching = (matchingParam: any) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };
  dispatch(setProgressAction({ open: "true" }));
  const body = JSON.stringify(matchingParam);
  const path = `/api/meetings/choiceMatching`;
  try {
    var res: any = await axios.post(path, body, config);
    dispatch(setProgressAction({ open: "false" }));
    return res.data;
  } catch (err) {
    dispatch(setProgressAction({ open: "false" }));
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

// 선택 대상자 매칭 취소
export const choiceMatchingCancel = (matchingParam: any) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    params: {},
    data: matchingParam,
  };

  dispatch(setProgressAction({ open: "true" }));
  // const body = JSON.stringify(matchingParam);
  const path = `/api/meetings/choiceMatchingCancel`;
  try {
    var res: any = await axios.delete(path, config);
    dispatch(setProgressAction({ open: "false" }));
    return "success";
  } catch (err) {
    dispatch(setProgressAction({ open: "false" }));
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

export const getMeetingVideo = (meetingId: number, applicantId: number) => {
  return getRequest(
    null,
    "/api/meetings/videoCallCheck",
    getMeetingVideoAction,
    { meetingId: meetingId, applicantId: applicantId }
  );
};

// 메일 발송
export const matchingMailSend = (mailTargetParam: any) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };
  dispatch(setProgressAction({ open: "true" }));
  const body = JSON.stringify(mailTargetParam);
  const path = `/api/meetings/matchingMailSend`;
  try {
    var res: any = await axios.post(path, body, config);
    dispatch(setProgressAction({ open: "false" }));
    return true;
  } catch (err) {
    dispatch(setProgressAction({ open: "false" }));
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return false;
  }
};

export const getMeetingList = async (subEventId: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    params: { subEventId: subEventId },
    data: {},
  };

  try {
    const res = await axios.get(
      `/api/meetings`,
      config
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getMeetingSurveyList = async (subEventId: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: { subEventId: subEventId },
    data: {},
  };

  try {
    const res = await axios.get("/api/meetingSurveys/list", config);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};



export const getVideoConnectedUser = async (subEventId: string) => {
  try {
    // const url =
    //   process.env.NODE_ENV === "production"
    //     ? "/video/videoCall/roomsPartiList"
    //     : "http://localhost:3001/video/videoCall/roomsPartiList";
    const url =
    process.env.NODE_ENV === "production"
      ? "/chime/roomsPartiList"
      : "http://localhost:3001/chime/roomsPartiList";

    const result = await axios.get(url, {
      params: { subEventId: subEventId },
    });
    return result.data;
  } catch (err) {
    console.log(err);
    // dispatch(
    //   setAlert({
    //     id: "VideoAttendeeList",
    //     msg: "비디오콜 참석자 리스트를 불러오지 봇했습니다.",
    //     alertType: "warning",
    //   })
    // );
  }
};

export const getMeetingTotalCount = (subEventId: string) => {
  return getRequest(null, `/api/applications/meetingTotalCount`, getMeetingTotalCountAction, {
    subEventId: subEventId,
  });
};