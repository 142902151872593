import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {Form} from "../../../../types/models/Form";
import {SubEventCategory, SubEventInfo} from "../../../../types/models/SubEvent";

import AutoForm from "../../autonomous/AutoForm";
import {registrationData} from "../template/templateData";
import uuid from "uuid";
import {AutonoForm, FormData} from "../../../../types/models/AutonoForm";
import {Paper} from "@material-ui/core";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {getSubEvent} from "../../../../actions/subEvents";
import {getRequestAndReturn} from "../../../../utils/utils";
import {getForms, removeFormAction} from "../../../../actions/forms";

type Props = {
  subEventCategory: SubEventCategory;
  registerForm: FormData | undefined;
  setRegisterForm: React.Dispatch<React.SetStateAction<FormData | undefined>>;
};

const RegistrationPartiForm: React.FC<Props> = ({subEventCategory, registerForm, setRegisterForm}) => {
  const params = useParams<{id: string}>(); // subEvent id
  const location = useLocation();
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState<Form>();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const forms = useSelector((state: AppState) => state.forms);
  const map: Form = {
    type: "REGISTRATION",
    formData: {formData: {}, formIds: []},
    content: "",
    subEventId: parseInt(params.id),
  };

  // sub event 수정이면 저장된 form data 불러오고
  // sub event 생성이면 default data 사용
  useEffect(() => {
    if (location.pathname.includes("SubEventModify") && subEvent?.registerForm) {
      dispatch(getForms(subEvent.registerForm));
    } else {
      setInitialData(makeDefaultFormData());
    }

    return () => {
      dispatch(removeFormAction());
    };
  }, [location, subEvent, dispatch]);

  // form data가 있으면 가공
  useEffect(() => {
    if (forms.content !== "" && forms.content !== undefined) {
      const data: Form = {
        id: forms.id,
        type: forms.type,
        formData: JSON.parse(forms.content!),
        content: "",
        subEventId: parseInt(params.id),
      };
      setInitialData({
        ...data,
        formData: JSON.parse(forms.content!),
        additionalFormData: JSON.parse(forms.additional!),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  // sub event 생성시 초기 데이터 생성
  const makeDefaultFormData = () => {
    const data = registrationData.map(v => {
      const formId = `form-${uuid.v4()}`;
      let formIds = [formId];
      map.formData!.formIds.push(formId);

      let formData = {
        [formId]: {
          id: formId,
          explain: "",
          index: 0,
        },
      };

      if (v.content) {
        const answerData = v.content?.map((item, index) => {
          const answerId = `form-${uuid.v4()}`;
          index == 0 ? (formIds = [answerId]) : formIds.push(answerId);

          return {
            [answerId]: {
              id: answerId,
              explain: item,
              index: index,
            },
          };
        });

        formData = Object.assign({}, ...answerData);
      }

      return {
        [formId]: {
          id: formId,
          index: 0,
          type: v.type,
          selectable: false,
          optionable: false,
          title: v.title,
          explain: "",
          secretCheck: "N",
          explainCheck: "N",
          requireCheck: "N",
          selectContent: {
            formData,
            formIds: formIds,
          },
          content: "",
        },
      };
    });
    Object.assign(map.formData!.formData, ...data);
    return map;
  };

  return (
    <>
      <Paper className="mb-1">
        <DefaultFormItem>질문 : 이름</DefaultFormItem>
      </Paper>
      <Paper className="mb-1">
        <DefaultFormItem>질문 : 이메일</DefaultFormItem>
      </Paper>
      <Paper className="mb-1">
        <DefaultFormItem>질문 : 직함</DefaultFormItem>
      </Paper>
      <Paper className="mb-1">
        <DefaultFormItem>질문 : 조직</DefaultFormItem>
      </Paper>
      <Paper className="mb-1">
        <DefaultFormItem>질문 : 휴대폰 번호</DefaultFormItem>
      </Paper>
      {initialData && <AutoForm initialFormData={initialData} subEventCategory={subEventCategory} setFormData={setRegisterForm} />}
    </>
  );
};

export default RegistrationPartiForm;

const DefaultFormItem = styled.div`
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 15px;
`;
