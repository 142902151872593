import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import HeadingTypo from "../../../Typhography/HeadingTypo";
import {useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {CircularProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";

import {WebinarQAExcelTypes} from "../../../../types/models/Webinar";
import moment from "moment";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";
import {getWebinarQAForExcel} from "../../../../actions/webinar";

const groupBy = require("json-groupby");

const WebinarQAExcel = ({classes}: {classes: Record<"root" | "companyDiv" | "radioForm" | "meetingDiv", string>}) => {
  const [t, i18n] = useTranslation("lang", {useSuspense: false});
  const params: {id: string} = useParams(); // subEventId
  const [data, setData] = useState<Array<any>>([]);
  const reg = /[\{\}\[\]\/?*\\\=\(\)"]/gi;
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  // 전체 참가기업 신청서 리스트를 가져온 후 excel로 export 할 data 생성
  const getWebinarQAs = useCallback(async () => {
    const result = await getWebinarQAForExcel(params.id);
    if (!subEvent || !result) return false;

    const newData = result.map((webinarQA: WebinarQAExcelTypes) => {
      const refinedAnswer: any = {};
      // 답변자 구분
      refinedAnswer[t("meetingList.corporateName")] = webinarQA.companyName;
      refinedAnswer["이름"] = webinarQA.userName;
      refinedAnswer["내용"] = webinarQA.content;
      refinedAnswer["비밀글"] = webinarQA.isPrivate;
      refinedAnswer["작성일"] = moment(webinarQA.createdDate).format("YYYY-MM-DD HH-mm");
      refinedAnswer["수정일"] = moment(webinarQA.modifiedDate).format("YYYY-MM-DD HH-mm");
      refinedAnswer["웨비나제목"] = webinarQA.webinarName.replace(reg, "");

      return refinedAnswer;
    });
    setData(newData);

    // resultData object 형식 = [{ 컬럼이름1: '컬럼내용1', 컬럼이름2: '컬럼내용2', ... }]
    // setData({ data: resultData, fileName: t("subEventSidebar.meetingStatus") });
  }, [params.id, subEvent]);

  useEffect(() => {
    if (subEvent !== undefined) {
      //   setGroupNames({
      //     seller: subEvent.sgroupName,
      //     buyer: subEvent.bgroupName,
      //   });
      getWebinarQAs();
    }
  }, [subEvent, getWebinarQAs]);

  // 참가자 탭 나누기

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";

  const exportToCSV = (QAData: any[]) => {
    let sheets = groupBy(QAData, ["웨비나제목"]);
    let newSheets: any = {};
    for (const key of Object.keys(sheets)) {
      newSheets[key] = {...XLSX.utils.json_to_sheet(sheets[key])};
    }
    const wb = {
      Sheets: newSheets,
      SheetNames: [...Object.keys(sheets)],
    };
    const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});

    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, "웨비나QA" + fileExtension);
  };

  return (
    <div className={classes.companyDiv}>
      <HeadingTypo fontweight="bold">웨비나QA</HeadingTypo>
      {data ? (
        <PxButton backgroundcolor="purple" onClick={e => exportToCSV(data)}>
          <ButtonTypo>{t("excel.download(xlsx)")}</ButtonTypo>
        </PxButton>
      ) : (
        <CircularProgress size={30} />
      )}
    </div>
  );
};

export default WebinarQAExcel;
