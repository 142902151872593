import React, { useEffect, useState } from "react";

// custom ui comp
import OutlinedTxtField from "../Inputs/OutlinedTxtField";
import SubHeadingTypo from "../Typhography/SubHeadingTypo";
import PxOutlinedFormControl from "../../components/Forms/PxOutlinedFormControl";

// @material-ui/core
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { TypographyProps } from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select, { SelectProps } from "@material-ui/core/Select";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { SubEventInfo } from "../../types/models/SubEvent";
import Event from "../../types/models/Event";
import ImpossibleTime from "./ImpossibleTime";
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

interface SelectBoxProps extends SelectProps {}

const SelectBox: React.FC<SelectBoxProps> = styled((props: SelectBoxProps) => {
  const { ...rest } = props;
  return <Select {...rest} />;
})`
  & > .MuiSelect-outlined.MuiSelect-outlined {
    padding: 10.5px 0 10.5px 10px;
  }
`;
const Matched: React.FC<any> = (props) => {
  const {
    subEvent,
    handleOnChange,
    event,
    onChangeMeetingTime,
    disabled,
    disabledTime, // {...year : {...month : ...'dayTime'}}
    setDisabledTime,
    setSubEvent,
  }: {
    subEvent: SubEventInfo;
    handleOnChange: any;
    event: Event["event"];
    onChangeMeetingTime: any;
    disabledTime: object;
    setDisabledTime: React.Dispatch<React.SetStateAction<Object>>;
    setSubEvent: React.Dispatch<React.SetStateAction<SubEventInfo>>;
    disabled: boolean | undefined;
  } = props;
  const [maxNumPerDay, setMaxNumPerDay] = useState<number>(
    subEvent.matchNumPerDay!
  );
  const [t] = useTranslation("lang", { useSuspense: false });

  useEffect(() => {
    if (subEvent.matchReqTime && subEvent.matchReqTime !== 0) {
      // 미팅 시작 시간 분으로 환산
      const startMin = moment(subEvent.matchStartTime, "HH:mm").diff(
        moment(`00:00`, "HH:mm"),
        "minute"
      );
      // 1440분 = 1일
      // 1일  - 미팅 시작시간 + 휴식시간 / 휴식시간 + 소요시간  = 결과값이 하루 최대 미팅 횟수
      setMaxNumPerDay(
        Math.floor(
          (1439 - startMin + (subEvent.matchBreakTime ?? 0)) /
            ((subEvent.matchBreakTime ?? 0) + subEvent.matchReqTime)
        )
      );
    }
  }, [subEvent.matchStartTime, subEvent.matchReqTime, subEvent.matchBreakTime]);

  useEffect(() => {
    //일일 미팅 횟수가 최대 일일 미팅 횟수보다 많을시
    if (subEvent.matchNumPerDay && maxNumPerDay < subEvent.matchNumPerDay) {
      setTimeout(async () => {
        await setSubEvent({ ...subEvent, matchNumPerDay: 0 });
      }, [100]);
    }
  }, [maxNumPerDay]);

  return (
    <>
      <Grid container sm={12} md={12} lg={12}>
        {/* 미팅 시작시간 */}
        <Grid sm={3} md={3} lg={3}>
          <SubTitle>{t("addSubEvent.consultationStart")}</SubTitle>
          <TextField
            style={{ width: "90%", margin: 0 }}
            id="time"
            type="time-local"
            name="matchStartTime"
            value={subEvent.matchStartTime}
            onChange={handleOnChange}
            variant="outlined"
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
              style: { padding: "10.5px 10px", margin: 0 },
              format: "HH:mm",
            }}
          />
        </Grid>
        {/* 소요시간 */}
        <Grid sm={3} md={3} lg={3}>
          <SubTitle>{t("addSubEvent.duration")}</SubTitle>
          <PxOutlinedFormControl fullWidth>
            <SelectBox
              style={{ width: "90%", margin: 0 }}
              name="matchReqTime"
              value={subEvent.matchReqTime}
              disabled={disabled}
              onChange={handleOnChange}
              displayEmpty
            >
              {/* 배열 생성후 반복 Array.from({length: 16}, (x,i) => i) */}
              {Array.from({ length: 25 }, (x, i) => (
                <MenuItem value={i * 5}>
                  {i * 5} {t("matched.min")}
                </MenuItem>
              ))}
            </SelectBox>
          </PxOutlinedFormControl>
        </Grid>
        {/* 휴식시간 */}
        <Grid sm={3} md={3} lg={3}>
          <SubTitle>{t("addSubEvent.break")}</SubTitle>
          <PxOutlinedFormControl fullWidth>
            <SelectBox
              style={{ width: "90%", margin: 0 }}
              name="matchBreakTime"
              type="number"
              value={subEvent.matchBreakTime}
              disabled={disabled}
              onChange={handleOnChange}
              displayEmpty
            >
              {Array.from({ length: 5 }, (x, i) => (
                <MenuItem value={i * 5}>
                  {i * 5} {t("matched.min")}
                </MenuItem>
              ))}
            </SelectBox>
          </PxOutlinedFormControl>
        </Grid>
        {/* 일일 미팅 횟수 */}
        <Grid sm={3} md={3} lg={3}>
          <SubTitle>{t("addSubEvent.numberOfConsultation")}</SubTitle>
          <PxOutlinedFormControl fullWidth>
            <SelectBox
              id="matchNumPerDay"
              style={{ width: "90%", margin: 0 }}
              name="matchNumPerDay"
              value={subEvent.matchNumPerDay}
              onChange={handleOnChange}
              disabled={disabled}
              displayEmpty
            >
              {Array.from({ length: maxNumPerDay + 1 }, (x, i) => (
                <MenuItem value={i}>{i} </MenuItem>
              ))}
            </SelectBox>
          </PxOutlinedFormControl>
        </Grid>
      </Grid>

      <SubTitle>{t("addSubEvent.estimatedEndTime")}</SubTitle>
      <Grid container direction="row">
        <OutlinedTxtField disabled value={subEvent.matchEndTime} fullWidth />
      </Grid>

      {/* 예상 종료 시간이 자정을 넘지않고, 미팅 시간 옵션을 선택했을경우 */}
      {/* 불가 시간 설정 */}
      {subEvent.matchEndTime !== undefined &&
        subEvent.matchEndTime!.indexOf(":") > 0 &&
        subEvent.matchStartTime !== subEvent.matchEndTime && (
          <ImpossibleTime
            event={event}
            subEvent={subEvent}
            disabledTime={disabledTime}
            setDisabledTime={setDisabledTime}
          />
        )}
    </>
  );
};

export default Matched;
