import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useFormContext from "../../../../components/Provider/FormProvider/useFormContext";

// types
import { Form } from "../../../../types/models/Form";
import { AppState } from "../../../../store";

// 자율양식 form component
import AutoForm from "../../autonomous/AutoForm";
import { getForms } from "../../../../actions/forms";

const BuyerSurveyForm = () => {
  const params = useParams<{ id: string }>(); // subEvent id
  const { initialLoad, setInitialLoad } = useFormContext();
  const dispatch = useDispatch();
  const { subEvent } = useSelector((state: AppState) => state.subEvents);
  const forms = useSelector((state: AppState) => state.forms);
  const [defaultData, setDefaultData] = useState<Form>();

  // 이미 등록한 양식이 있으면 db에서 불러옴
  useEffect(() => {
    if (
      subEvent?.buyerSurveyForm !== undefined &&
      subEvent.buyerSurveyForm !== 0 &&
      initialLoad
    ) {
      dispatch(getForms(subEvent.buyerSurveyForm));
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent]);

  // db에서 불러온 양식을 가공하여 자율양식 form으로 만들어줌
  useEffect(() => {
    if (forms.content !== "" && forms.content !== undefined) {
      const data: Form = {
        id: forms.id,
        type: forms.type,
        formData: JSON.parse(forms.content!),
        content: "",
        subEventId: parseInt(params.id),
      };
      setDefaultData({
        ...data,
        formData: JSON.parse(forms.content!),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  const makeDefaultFormData = () => {
    const map: Form = {
      type: "BYS",
      formData: { formData: {}, formIds: [] },
      content: "",
      subEventId: parseInt(params.id),
    };
    const data = [{}];
    Object.assign(map.formData!.formData, ...data);
    return map;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialData = useMemo(() => makeDefaultFormData(), [params.id]);

  return (
    <>
      {defaultData !== undefined && <AutoForm initialFormData={defaultData} />}
      {defaultData === undefined && <AutoForm initialFormData={initialData} />}
      {/* <AutoForm initialFormData={initialData} /> */}
    </>
  );
};

export default BuyerSurveyForm;
