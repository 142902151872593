import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { AppActions, AppState } from "../store";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import Speaker, { SpeakerInfo } from "../types/models/Speaker";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { setAlert } from "../utils/utils";

export const createSpeakerAction = (speaker: SpeakerInfo): AppActions => ({
  type: "CREATE_SPEAKER",
  speaker,
});

export const getSpeakersAction = (speakers: SpeakerInfo[]): AppActions => ({
  type: "GET_SPEAKERS",
  speakers,
});

export const getSpeakerAction = (speaker: SpeakerInfo): AppActions => ({
  type: "GET_SPEAKER",
  speaker,
});

export const createSpeaker = (speaker: SpeakerInfo) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(speaker);

  const path = `/api/speakers`;
  try {
    var res: AxiosResponse<Event> = await axios.post(path, body, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

export const getSpeakers = <Speaker>(subEventId: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/speakers?subEventId=${subEventId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getSpeakersAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

export const getSpeaker = <Speaker>(speakerId: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/speakers/detail?speakerId=${speakerId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getSpeakerAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};
