import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// types
import SubHeadingTypo from "../../../Typhography/SubHeadingTypo";
import { NoticeInfo } from "../../../../types/models/Notice";
import { SubEvenPathContext } from "../../../../layouts/SubEvent";

// actions
import { createNotice } from "../../../../actions/notice";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import { TypographyProps } from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// custom ui comp
import OutlinedTxtField from "../../../Inputs/OutlinedTxtField";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PxEditor from "../../../Editor/PxEditor";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const NoticeAdd = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const dispatch = useDispatch();
  const { subEventId } = useContext(SubEvenPathContext);
  const [notice, setNotice] = useState<NoticeInfo>({
    subEventId: subEventId, // 서브이벤트 아이디
    title: "", // 제목
    content: "", // 내용
    createdDate: "",
    modifiedDate: "",
  });

  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    let name: string = e.target.name as string;
    let value: string = e.target.value as string;

    setNotice({ ...notice, [name]: value });
  };

  // 공지사항 컨텐츠 내용(에디터) 변경
  const handleContentChange = (value: string) => {
    setNotice({ ...notice, content: value });
  };

  // 공지사항 등록
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const noticeRes: any = await dispatch(createNotice(notice));
    if (noticeRes === "success") history.goBack();
  };

  return (
    <Grid container>
      <Grid item>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
      </Grid>
      <Grid item style={{ padding: "0 0 0 64px" }}>
        <form onSubmit={onSubmit}>
          <SubTitle>{t("common.title")}</SubTitle>
          <OutlinedTxtField
            name="title"
            value={notice.title}
            fullWidth
            onChange={handleOnChange}
            placeholder={t("noticeAdd.typeTheTitle")}
          />

          <SubTitle>{t("common.content")}</SubTitle>
          <PxEditor
            value={notice.content}
            onChange={handleContentChange}
            folderPath="subEvent/notice/"
            placeholder={t("noticeAdd.typeTheContent")}
          />
          <Grid
            container
            alignContent="space-between"
            spacing={1}
            style={{ justifyContent: "flex-end", marginTop: "32px" }}
          >
            <Grid item>
              <PxButton backgroundcolor="purple" type="submit">
                <ButtonTypo>{t("common.save")}</ButtonTypo>
              </PxButton>
            </Grid>
            <Grid item>
              <PxButton backgroundcolor="grey" onClick={() => history.goBack()}>
                <ButtonTypo>{t("common.cancel")}</ButtonTypo>
              </PxButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default NoticeAdd;
