import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  WithGoogleMapProps,
} from "react-google-maps";

interface PxGoogleMapProps {
  lat: number;
  lng: number;
  marker: boolean;
}

type Props = WithGoogleMapProps & PxGoogleMapProps;

const PxGoogleMap = withGoogleMap(({ lat, lng, marker }: Props) => {
  return (
    <GoogleMap defaultZoom={10} defaultCenter={{ lat: lat, lng: lng }}>
      {marker && <Marker position={{ lat: lat, lng: lng }} />}
    </GoogleMap>
  );
});

export default PxGoogleMap;
