import React, { Fragment, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, RouteComponentProps } from "react-router-dom";
import { getProgram } from "../../../../actions/program";
import { AppState } from "../../../../store";

// @material-ui/core
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import { TypographyProps } from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// type
import { SubEvenPathContext } from "../../../../layouts/SubEvent";

// custom ui comp
import OutlinedTxtField from "../../../Inputs/OutlinedTxtField";
import SubHeadingTypo from "../../../Typhography/SubHeadingTypo";
import ShowFile from "../../../Common/ShowFile";

import styled from "styled-components";
import moment from "moment";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const ProgramDetail: React.FC<RouteComponentProps> = ({ match }) => {
  const history = useHistory();
  const params: { secondId: string } = useParams(); // program id
  const dispatch = useDispatch();
  const program = useSelector((state: AppState) => state.programs.program);
  const { basicPath } = useContext(SubEvenPathContext);

  useEffect(() => {
    dispatch(getProgram(params.secondId));
  }, []);

  return program !== undefined && program.id !== undefined ? (
    <Grid container>
      <Grid item>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
      </Grid>
      <Grid item style={{ padding: "0 0 0 64px", width: "100%" }}>
        <SubTitle>제목</SubTitle>
        <OutlinedTxtField value={program.title} fullWidth disabled />

        <SubTitle>내용</SubTitle>
        <Grid
          sm={12}
          md={12}
          lg={12}
          style={{
            border: "1px solid #ccc",
            padding: "10.5px 14px",
            overflow: "scroll",
            maxHeight: "500px",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: program.content }} />
        </Grid>

        <SubTitle>기간</SubTitle>
        <OutlinedTxtField
          fullWidth
          disabled
          value={`${moment(program.startDate).format(
            "yyyy-MM-DD HH:mm"
          )} - ${moment(program.endDate).format("yyyy-MM-DD HH:mm")}`}
        />

        {program.atchFile !== null && (
          <>
            <SubTitle>사진</SubTitle>
            <ShowFile fileId={program.atchFile.id} />
          </>
        )}

        {/* 스피커 */}
        <Grid container direction="column">
          {Array.isArray(program.speakers) && program.speakers.length > 0 ? (
            <>
              <SubTitle>참여 스피커</SubTitle>
              <SubHeadingTypo>
                ( 클릭시 스피커 상세화면으로 이동됩니다. )
              </SubHeadingTypo>
              <Grid>
                {program.speakers.map((speaker: any, idx: number) => (
                  <Fragment key={`manager-${idx}`}>
                    <Chip
                      style={{ margin: "5px 10px" }}
                      variant="outlined"
                      size="medium"
                      label={`${speaker.name} / ${speaker.belongs} / ${speaker.title}`}
                      onClick={() =>
                        history.push(`${basicPath}/speaker/${speaker.id}`)
                      }
                    />
                  </Fragment>
                ))}
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

export default ProgramDetail;
