import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import styled from "styled-components";
import Footer from "../Footer/Footer";
import PexpoLogoSvg from "../../assets/images/pexpo_signature.svg";
import ButtonTypo from "../Typhography/ButtonTypo";
import {Link} from "react-router-dom";
import SubHeadingTypo from "../Typhography/SubHeadingTypo";
import OutlinedTxtField from "../Inputs/OutlinedTxtField";
import {Button, Grid, Switch} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {getSignedRequest} from "../../actions/files";
import PxButton from "../Buttons/PxButton";
import {Url} from "url";
import {EtcLinkCustom, EventInfo, FooterItem, FooterType, ManualCumstom, SocialLinkCustom} from "../../types/models/Event";
import {withStyles} from "@material-ui/styles";
import {BluetoothSearchingOutlined, FreeBreakfastOutlined} from "@material-ui/icons";
import {getRequestAndReturn} from "../../utils/utils";
import {AppState} from "../../store";

interface FileData {
  fileName: string;
  filePath: string;
  fileType: string;
  id: number;
}

interface WebSite {
  img: FileData;
  text: string;
  url: string;
  hide: boolean;
}

type ManualType = "text" | "url" | "hide";
type SocialType = "blog" | "facebook" | "instagram" | "linkedin";
type EtcType = "term" | "privacy_policy" | "email";

interface UrlType {
  url: string;
}

interface Props {
  setEvent: Dispatch<SetStateAction<EventInfo>>;
  setFooterUseState: Dispatch<SetStateAction<boolean>>;
  // footerItems?: FooterItem[];
}

const EventFooterEdit: React.FC<Props> = ({setEvent, setFooterUseState}) => {
  const dispatch = useDispatch();
  const event = useSelector((state: AppState) => state.events.event);
  const [webSite, setWebSite] = useState<WebSite>({img: {id: 0, fileName: "", filePath: "", fileType: ""}, text: "", url: "", hide: false});
  const [manual, setManual] = useState<ManualCumstom>({text: "", url: "", hide: false});
  const [social, setSocial] = useState<SocialLinkCustom>({
    blog: {link: "", hide: false},
    facebook: {link: "", hide: false},
    instagram: {link: "", hide: false},
    linkedin: {link: "", hide: false},
  });
  const [etc, setEtc] = useState<EtcLinkCustom>({term: "", privacy_policy: "", email_inquiry: ""});
  const [customState, setCustomState] = useState<boolean>(false);
  const [copyright, setCopyright] = useState<boolean>(false);
  const [footerItems, setFooterItems] = useState<FooterItem[]>([]);

  const getFooterItems = async (eventId: number) => {
    const res: any = await dispatch(getRequestAndReturn(`/api/events/event-footer/${eventId}`, {}));
    setEvent(event => {
      return {...event, footer: res};
    });
    setFooterItems(res);
  };

  useEffect(() => {
    if (event && event.id) {
      getFooterItems(event.id);
    }
  }, [event]);

  useEffect(() => {
    setFooterUseState(customState);
  }, [customState]);

  useEffect(() => {
    if (!footerItems || footerItems.length === 0) return;

    const newState = {
      webSite: {...webSite},
      manual: {...manual},
      social: {...social},
      etc: {...etc},
      copyright: copyright,
    };

    footerItems.forEach(item => {
      const {type, imgUrl, link, hide} = item;
      const isPublic = hide === "PRIVATE";

      switch (type) {
        case "WEB_PAGE":
          const segments = (imgUrl as string).split("/");
          const fileName = segments.pop() || "";

          newState.webSite = {
            ...newState.webSite,
            img: {
              id: 0,
              fileName,
              filePath: imgUrl as string,
              fileType: "",
            },
            url: link,
            hide: isPublic,
          };

          break;
        case "MANUAL":
          newState.manual.url = link;
          newState.manual.hide = isPublic;
          break;
        case "BLOG":
        case "INSTAGRAM":
        case "LINKEDIN":
        case "FACEBOOK":
          newState.social[type.toLowerCase()] = {link, hide: isPublic};
          break;
        case "TERM":
          newState.etc.term = link;
          break;
        case "PRIVACY_POLICY":
          newState.etc.privacy_policy = link;
          break;
        case "EMAIL_INQUIRY":
          newState.etc.email_inquiry = link;
          break;
        case "COPYRIGHT":
          newState.copyright = isPublic;
          break;
        default:
          throw new Error(`Not found footer type: ${type}`);
      }
    });

    // Set all states at once
    setWebSite(newState.webSite);
    setManual(newState.manual);
    setSocial(newState.social);
    setEtc(newState.etc);
    setCopyright(newState.copyright);
    setCustomState(true);
  }, [footerItems]);

  useEffect(() => {
    Object.entries(etc).forEach(([key, url]) => {
      setEvent(event => updateFooter(event, key.toUpperCase() as FooterType, mapEtcLinkToFooterItem(key as keyof EtcLinkCustom, url)));
    });
  }, [etc, setEvent]);

  useEffect(() => {
    Object.entries(social).forEach(([key, url]) => {
      if (url) {
        setEvent(event => updateFooter(event, key.toUpperCase() as FooterType, mapSocialLinkToFooterItem(key as keyof SocialLinkCustom, url)));
      }
    });
  }, [social, setEvent]);

  useEffect(() => {
    setEvent(event =>
      updateFooter(event, "WEB_PAGE", {
        content: "",
        imgUrl: webSite.img.filePath,
        link: webSite.url,
        hide: webSite.hide === false ? "PUBLIC" : "PRIVATE",
      })
    );
  }, [webSite, setEvent]);

  useEffect(() => {
    setEvent(event =>
      updateFooter(event, "MANUAL", {
        content: manual.text,
        link: manual.url,
        hide: manual.hide === false ? "PUBLIC" : "PRIVATE",
      })
    );
  }, [manual, setEvent]);

  useEffect(() => {
    setEvent(event =>
      updateFooter(event, "COPYRIGHT", {
        content: "",
        link: "",
        hide: copyright === false ? "PUBLIC" : "PRIVATE",
      })
    );
  }, [copyright, setEvent]);

  function updateFooter(event: EventInfo, updateType: FooterType, updateContent: Partial<Omit<FooterItem, "type">>) {
    const footerCopy = event.footer && event.footer?.length > 0 ? [...event.footer!] : [];
    const targetIndex = footerCopy.findIndex(item => item.type === updateType);

    if (targetIndex !== -1) {
      // 해당 타입의 요소가 있다면 업데이트합니다.
      footerCopy[targetIndex] = {
        ...footerCopy[targetIndex],
        ...updateContent,
      };
    } else {
      // 해당 타입의 요소가 없다면 새로 추가합니다.
      footerCopy.push({
        type: updateType,
        ...updateContent,
      } as FooterItem);
    }

    return {...event, footer: footerCopy};
  }

  function mapSocialLinkToFooterItem(socialKey: keyof SocialLinkCustom, item: {link: string; hide: boolean}): Partial<Omit<FooterItem, "type">> {
    switch (socialKey) {
      case "blog":
        return {content: "", link: item.link, hide: item.hide === false ? "PUBLIC" : "PRIVATE"};
      case "facebook":
        return {content: "", link: item.link, hide: item.hide === false ? "PUBLIC" : "PRIVATE"};
      case "instagram":
        return {content: "", link: item.link, hide: item.hide === false ? "PUBLIC" : "PRIVATE"};
      case "linkedin":
        return {content: "", link: item.link, hide: item.hide === false ? "PUBLIC" : "PRIVATE"};
      default:
        return {};
    }
  }

  function mapEtcLinkToFooterItem(etcKey: keyof EtcLinkCustom, url: string): Partial<Omit<FooterItem, "type">> {
    switch (etcKey) {
      case "term":
        return {content: "", link: url};
      case "privacy_policy":
        return {content: "", link: url};
      case "email_inquiry":
        return {content: "", link: url};
      default:
        return {};
    }
  }

  const etcHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: EtcType) => {
    const value: string = e.target.value;

    switch (type) {
      case "email":
        setEtc({...etc, email_inquiry: value});
        break;
      case "privacy_policy":
        setEtc({...etc, privacy_policy: value});
        break;
      case "term":
        setEtc({...etc, term: value});
        console.log(`term value`, value);
        break;
      default:
        new Error("Unkown etc type");
        break;
    }
  };

  const socialLinkHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: SocialType) => {
    const value: string = e.target.value;

    switch (type) {
      case "blog":
        setSocial({...social, blog: {...social.blog, link: value}});
        break;
      case "facebook":
        setSocial({...social, facebook: {...social.facebook, link: value}});
        break;
      case "instagram":
        setSocial({...social, instagram: {...social.instagram, link: value}});
        break;
      case "linkedin":
        setSocial({...social, linkedin: {...social.linkedin, link: value}});
        break;
      default:
        new Error("Unknown social type");
        break;
    }
  };

  const socialVisibleHandler = (e: React.ChangeEvent<HTMLInputElement>, type: SocialType) => {
    const value: boolean = e.target.checked;

    switch (type) {
      case "blog":
        setSocial({...social, blog: {...social.blog, hide: value}});
        break;
      case "facebook":
        setSocial({...social, facebook: {...social.facebook, hide: value}});
        break;
      case "instagram":
        setSocial({...social, instagram: {...social.instagram, hide: value}});
        break;
      case "linkedin":
        setSocial({...social, linkedin: {...social.linkedin, hide: value}});
        break;
      default:
        new Error("Unknown social type");
        break;
    }
  };

  const manualHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: ManualType) => {
    switch (type) {
      case "text":
      case "url":
        const value = e.target.value;
        setManual({...manual, [type]: value});
        return;
      // case "url":
      //   setManual({...manual, url: value});
      //   break;
      case "hide":
        const checked = (e.target as HTMLInputElement).checked;
        setManual({...manual, hide: checked});
    }
  };

  const imageRegist = async (e: React.ChangeEvent<{files: any; name?: string | undefined}>) => {
    const file = e.target.files[0];
    const fileParts = file.name.split(".");
    const originalFileName = fileParts[0];
    const timestamp = new Date().getTime();
    const fileType = file.type.split("/")[1];
    const fileName = originalFileName + "&&" + timestamp + "." + fileType;
    const folderPath = "event/";
    const fileSize = file.size;

    const res: any = await dispatch(getSignedRequest(file, fileName, fileType, folderPath, fileSize));
    // setLogoData(res.data);
    const data = res.data;
    setWebSite({...webSite, img: {id: data.id, fileType: data.fileType, filePath: data.filePath, fileName: data.fileName}});
  };

  const logoImageCancel = () => {
    setWebSite({...webSite, img: {id: 0, fileName: "", filePath: "", fileType: ""}});
  };

  return (
    <div style={{marginTop: "30px"}}>
      <SubHeadingTypo style={{fontWeight: "bold"}}>footer 설정</SubHeadingTypo>
      <Container>
        <PreViewContainer>
          {webSite.hide === true ? (
            <></>
          ) : (
            <StyledRow>
              <SLink to={webSite.url == "" || !customState ? "" : webSite.url}>
                <PexpoLogo src={webSite.img.filePath === "" || !customState ? PexpoLogoSvg : webSite.img.filePath} alt="Pexpo Logo" style={{cursor: "pointer"}} />
                <ButtonTypo>{webSite.text == "" ? "웹사이트 바로가기" : webSite.text}</ButtonTypo>
              </SLink>
            </StyledRow>
          )}
          <Footer manual={manual} social={social} etc={etc} copyrightHide={copyright} customState={customState} />
        </PreViewContainer>
        <CustomContainer>
          <SwitchContainer>
            <SubHeadingTypo>변경</SubHeadingTypo>
            <PurpleSwitch checked={customState} onClick={() => setCustomState(!customState)} />
          </SwitchContainer>
          <InputContainer>
            {customState ? (
              <>
                <ListItem>
                  {webSite && (
                    <HideContainer>
                      <div style={{width: "100%"}}>
                        <SubTitle>웹사이트 바로가기</SubTitle>
                        <>
                          <SubTitle style={{margin: "0"}}>로고 변경</SubTitle>
                          <LogoContainer>
                            <SubHeadingTypo style={{wordBreak: "break-all"}}>{webSite.img.fileName !== "" ? webSite.img.fileName : "174*45px 권장"}</SubHeadingTypo>
                            {webSite.img.filePath !== "" ? (
                              <Button onClick={() => logoImageCancel()}>취소</Button>
                            ) : (
                              <Button variant="contained" component="label">
                                등록
                                <input type="file" style={{display: "none"}} name="logoFile" accept="image/jpg image/jpeg, image/png" onChange={e => imageRegist(e)} />
                              </Button>
                            )}
                          </LogoContainer>
                        </>
                        <label style={{marginTop: "10px"}}>url에 http:// 또는 https://를 포함시켜 입력해주세요</label>
                        <OutlinedTxtField placeholder="url 입력" style={{width: "100%"}} value={webSite.url} onChange={e => setWebSite({...webSite, url: e.target.value})} />
                      </div>
                      <div style={{display: "flex", alignItems: "center", width: "60px", paddingTop: "20px"}}>
                        <label htmlFor="checkboxId" style={{margin: "0 10px 0 0"}}>
                          숨김
                        </label>
                        <input type="checkbox" id="checkboxId" checked={webSite.hide} onChange={e => setWebSite({...webSite, hide: e.target.checked})} />
                      </div>
                    </HideContainer>
                  )}
                </ListItem>
                <ListItem>
                  {manual && (
                    <>
                      <SubTitle>온라인 매뉴얼</SubTitle>
                      <HideContainer>
                        <OutlinedTxtField placeholder="url 입력" value={manual.url} onChange={e => manualHandler(e, "url")} style={{width: "80%"}} />
                        <div style={{display: "flex", alignItems: "center", width: "60px", paddingTop: "20px"}}>
                          <label htmlFor="checkboxId" style={{margin: "10px"}}>
                            숨김
                          </label>
                          <input type="checkbox" id="checkboxId" checked={manual.hide} onChange={e => manualHandler(e, "hide")} />
                        </div>
                      </HideContainer>
                    </>
                  )}
                </ListItem>
                <ListItem>
                  <SubTitle>Social Icon Link</SubTitle>
                  {social.blog && (
                    <HideContainer>
                      <OutlinedTxtField placeholder="Blog url 입력" style={{width: "80%"}} value={social.blog.link} onChange={e => socialLinkHandler(e, "blog")} />
                      <div style={{display: "flex", alignItems: "center"}}>
                        <label htmlFor="checkboxId" style={{margin: "10px"}}>
                          숨김
                        </label>
                        <input type="checkbox" id="checkboxId" checked={social.blog.hide} onChange={e => socialVisibleHandler(e, "blog")} />
                      </div>
                    </HideContainer>
                  )}
                  {social.facebook && (
                    <HideContainer>
                      <OutlinedTxtField placeholder="Facebook url 입력" style={{width: "80%"}} value={social.facebook.link} onChange={e => socialLinkHandler(e, "facebook")} />
                      <div style={{display: "flex", alignItems: "center"}}>
                        <label htmlFor="checkboxId" style={{margin: "10px"}}>
                          숨김
                        </label>
                        <input type="checkbox" id="checkboxId" checked={social.facebook.hide} onChange={e => socialVisibleHandler(e, "facebook")} />
                      </div>
                    </HideContainer>
                  )}
                  {social.instagram && (
                    <HideContainer>
                      <OutlinedTxtField placeholder="Instagram url 입력" style={{width: "80%"}} value={social.instagram.link} onChange={e => socialLinkHandler(e, "instagram")} />
                      <div style={{display: "flex", alignItems: "center"}}>
                        <label htmlFor="checkboxId" style={{margin: "10px"}}>
                          숨김
                        </label>
                        <input type="checkbox" id="checkboxId" checked={social.instagram.hide} onChange={e => socialVisibleHandler(e, "instagram")} />
                      </div>
                    </HideContainer>
                  )}
                  {social.linkedin && (
                    <HideContainer>
                      <OutlinedTxtField placeholder="LinkedIn url 입력" style={{width: "80%"}} value={social.linkedin.link} onChange={e => socialLinkHandler(e, "linkedin")} />
                      <div style={{display: "flex", alignItems: "center"}}>
                        <label htmlFor="checkboxId" style={{margin: "10px"}}>
                          숨김
                        </label>
                        <input type="checkbox" id="checkboxId" checked={social.linkedin.hide} onChange={e => socialVisibleHandler(e, "linkedin")} />
                      </div>
                    </HideContainer>
                  )}
                </ListItem>
                <ListItem>
                  <SubTitle>기타 링크 설정</SubTitle>
                  <OutlinedTxtField placeholder="이용약관 url 입력" value={etc.term} onChange={e => etcHandler(e, "term")} />
                  <OutlinedTxtField placeholder="개인정보처리방침 url 입력" value={etc.privacy_policy} onChange={e => etcHandler(e, "privacy_policy")} />
                  <OutlinedTxtField placeholder="이메일 url 입력" value={etc.email_inquiry} onChange={e => etcHandler(e, "email")} />
                </ListItem>
                <ListItem>
                  <HideContainer>
                    <SubTitle>펙스포 권한 카피라이트</SubTitle>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <label htmlFor="checkboxId" style={{margin: "10px"}}>
                        숨김
                      </label>
                      <input type="checkbox" id="checkboxId" checked={copyright} onChange={e => setCopyright(!copyright)} />
                    </div>
                  </HideContainer>
                </ListItem>
              </>
            ) : (
              <BlindContainer>
                <PexpoTitleContainer>
                  <PexpoLogo src={PexpoLogoSvg} />
                  정보
                </PexpoTitleContainer>
                <SubHeadingTypo>-현재 상태에서는 Pexpo의 정보로 표시됩니다</SubHeadingTypo>
              </BlindContainer>
            )}
          </InputContainer>
        </CustomContainer>
      </Container>
    </div>
  );
};

export default EventFooterEdit;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const PreViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  padding: 10px 15px;
`;

const StyledRow = styled(Row)`
  margin: 0;
`;

const PexpoLogo = styled.img`
  width: 89.6px;
  height: 24px;
  display: inline-block;
  margin-right: 8.4px;
`;

const SLink = styled(Link)`
  background-color: rgba(0, 0, 0, 0.01);
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
`;

const SubTitle = styled(SubHeadingTypo)`
  font-weight: bold;
`;

const SwitchContainer = styled.div`
  display: flex;
  padding: 0 15px;
  align-items: center;
`;
const PurpleSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "rgb(81, 45, 168)",
    },
    "&$checked + $track": {
      backgroundColor: "rgb(81, 45, 168)",
    },
  },
  checked: {},
  track: {},
})(Switch);

const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`;

const BlindContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PexpoTitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.25rem;
`;

const HideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
