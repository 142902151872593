import React from "react";
import styled, { css } from "styled-components";

// material ui comp
import Button, { ButtonProps } from "@material-ui/core/Button";

interface PxButtonProps {
  margin?: string;
  padding?: string;
  backgroundcolor?:
    | "purple"
    | "bluePurple"
    | "grey"
    | "blueGrey"
    | "default"
    | "white"
    | "darkGrey"
    | "transparent"
    | "pale-grey"
    | "skyBlue"
    | "black.87"
    | "#f1f2f5"
    | "#512da8";
  boxshadow?: boolean;
  hover?: boolean;
  border?: boolean;
  outline?: boolean;
  txtColor?: "bluePurple" | "white" | "black0.6" | "blue" | "warning";
  noBorderRadius?: boolean;
}

type Prop = PxButtonProps & ButtonProps;

const PxButton: React.FC<Prop> = styled((props: Prop) => {
  const {
    margin,
    backgroundcolor,
    border,
    boxshadow,
    txtColor,
    padding,
    outline,
    noBorderRadius,
    ...rest
  } = props;
  return <Button variant="contained" {...rest} />;
})`
  padding: ${(props) => props.padding ?? "10px 24px 10px 24px"};
  margin: ${(props) => props.margin ?? "0"};
  outline: none !important;
  margin: ${(props) => (props.margin ? props.margin : "0px")};
  border: ${(props) => (props.border === false ? "none" : "1px solid")};
  color: ${(props) => {
    if (props.txtColor === "bluePurple") return "rgb(81, 45, 168)";
    else if (props.txtColor === "white") return "#ffffff";
    else if (props.txtColor === "black0.6") return "rgba(0, 0, 0, 0.6)";
    else if (props.txtColor === "blue") return "#3f51b5";
    else if (props.txtColor === "warning") return "#d32f2f";
  }};
  background-color: ${(props) => {
    if (props.backgroundcolor === "purple") {
      return "#5f4b8b";
    } else if (props.backgroundcolor === "bluePurple") {
      return "rgb(81, 45, 168)";
    } else if (props.backgroundcolor === "grey") {
      return "#eeeeee";
    } else if (props.backgroundcolor === "darkGrey") {
      return "#4b4c4e";
    } else if (props.backgroundcolor === "pale-grey") {
      return "#f6f7fa";
    } else if (props.backgroundcolor === "skyBlue") {
      return "#2f8de5";
    } else if (props.backgroundcolor === "transparent") return "transparent";
    else if (props.backgroundcolor === "black.87") return "rgba(0, 0, 0, 0.87)";
    else if (props.backgroundcolor === "#f1f2f5") return "#f1f2f5";
    else if (props.backgroundcolor === "#512da8") return "#512da8";
    else if (props.backgroundcolor === "blueGrey") return "#637381";
    return "white";
  }};
  border: ${(props) => {
    if (props.backgroundcolor === "white") {
      return "1px solid silver";
    } else {
      return "none";
    }
  }};
  ${(props) => {
    if (
      props.backgroundcolor === "purple" ||
      props.backgroundcolor === "darkGrey" ||
      props.backgroundcolor === "skyBlue" ||
      props.backgroundcolor === "blueGrey" ||
      props.backgroundcolor === "#512da8"
    ) {
      return css`
        & p {
          color: white;
        }
      `;
    }
  }};
  box-shadow: ${(props) => props.boxshadow === false && "none"};
  border-radius: ${(props) => {
    if (props.noBorderRadius) return "0";
    return "4px";
  }};
  &:hover {
    ${(props) => {
      if (props.hover === false) {
        return css`
          background-color: transparent;
          box-shadow: none;
        `;
      }
    }}
  }
  &:focus {
    outline: ${(props) => (props.outline === false ? "none" : "")};
  }
`;

PxButton.defaultProps = {
  boxshadow: false,
  backgroundcolor: "default",
};

export default PxButton;
