import React from "react";
import BaseTemplate, { EventContent, LinkButton, MailContent } from "./BaseTemplate";
import styled from "styled-components";

interface MeetingMailProp {
  language: "kr" | "en";
  userName: string;
  eventName: string;
  eventStart: string;
  eventEnd: string;
  link: string;
}

export default ({ language, userName, eventName, eventStart, eventEnd, link }: MeetingMailProp) => {
  return (
    <BaseTemplate
      title={language === "en" ? "Participating application notice" : "참가신청 확정 안내"}
      language={language}
    >
      {language === "en" ? (
        <>
          <MailContent>
            Hello, {userName}.
            <br />
            The event you participated in is just a day left
          </MailContent>
          <EventContent>
            Event Name:&nbsp; {eventName}
            <br />
            Period:
            <br />
            &nbsp;{eventStart}(GMT+9) ~&nbsp;{eventEnd}(GMT+9)
          </EventContent>
          <CheckList>
            <H4>Check before your video conferencing</H4>
            1. Connect a wired interent as possible.
            <br />
            2. Internet common connection of wireless router is not suitable for video conferencing
            <br />
            3. Down/Upload 2Mbps or higher for video conferencing
            <br />
            4. How to check your internet speed :{" "}
            <a href="speedtest.net" target="_blank">
              speedtest.net
            </a>
            ,{" "}
            <a href="fast.com" target="_blank">
              fast.com
            </a>
            <br />
            5. Check camera, mic., and speaker of your laptop in the preview
            <br />
          </CheckList>
          <br />
          <LinkButton target="_blank" href={link}>
            Go to event{" "}
          </LinkButton>
        </>
      ) : (
        <>
          <MailContent>
            {userName}님 안녕하세요.
            <br />
            참여하신 이벤트가 하루 앞으로 다가왔습니다.
          </MailContent>
          <EventContent>
            이벤트 명:&nbsp; {eventName}
            <br />
            이벤트 기간:
            <br />
            &nbsp;{eventStart}(GMT+9) ~&nbsp;{eventEnd}(GMT+9)
          </EventContent>
          <CheckList>
            <H4>펙스포 접속 전에 화상상담 전 체크사항</H4>
            1. 가급적 유선 network를 연결하세요
            <br />
            2. 무선 공유기 연결시 공용 접속은 화상 상담에 부적합합니다.
            <br />
            3. download / upload 2Mbps 이상에서 화상상담이 원활합니다
            <br />
            4. 인터넷 속도 체크{" "}
            <a href="speedtest.net" target="_blank">
              speedtest.net
            </a>
            ,{" "}
            <a href="fast.com" target="_blank">
              fast.com
            </a>
            <br />
            5. 접속PC의 카메라, 마이크, 오디오 상태를 프리뷰에서 꼭 체크하세요
            <br />
            <br />
          </CheckList>
          <LinkButton target="_blank" href={link}>
            이벤트 바로가기
          </LinkButton>
        </>
      )}
    </BaseTemplate>
  );
};

const CheckList = styled.div`
  font-size: 14px;
  margin-top: 5px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.84);
`;

const H4 = styled.h4`
  font-weight: 600;
  margin-bottom: 5px;
`;
