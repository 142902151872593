import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { AppActions, AppState } from "../store";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import Notice, { NoticeInfo, PagingNoticeInfo } from "../types/models/Notice";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { setAlert } from "../utils/utils";

export const createNoticeAction = (notice: NoticeInfo): AppActions => ({
  type: "CREATE_NOTICE",
  notice,
});

export const getNoticesAction = (notices: PagingNoticeInfo): AppActions => ({
  type: "GET_NOTICES",
  notices,
});

export const getNoticeAction = (notice: NoticeInfo): AppActions => ({
  type: "GET_NOTICE",
  notice,
});

export const createNotice = (notice: NoticeInfo) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(notice);

  const path = `/api/notices`;
  try {
    var res: AxiosResponse<Event> = await axios.post(path, body, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

export const getNotices = <Notice>(subEventId: string, page: number = 0, pageSize:number = 10, searchWord:string = "") => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/notices?subEventId=${subEventId}&page=${page}&size=${pageSize}&searchWord=${searchWord}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getNoticesAction(res.data));
    return res.data
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

export const getNotice = <Notice>(noticeId: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/notices/detail?noticeId=${noticeId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getNoticeAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

export const deleteNotice = async (id: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  try {
    const res = await axios.delete(`/api/notices/${id}`, config);

    return res;
  } catch (err) {
    return false;
  }
};
