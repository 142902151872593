import { AppActions, AppState } from "../store";
import axios, { AxiosResponse } from "axios";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { setAlert } from "../utils/utils";

// types
import Event, { EventInfo, EventPagination } from "../types/models/Event";
import { Dispatch } from "react";

export const createEventAction = (event: EventInfo): AppActions => ({
  type: "CREATE_EVENT",
  event,
});

export const getEventsAction = (events: EventPagination): AppActions => ({
  type: "GET_EVENTS",
  events,
});

export const getEventAction = (event: EventInfo): AppActions => ({
  type: "GET_EVENT",
  event,
});

export const resetEventAction = (): AppActions => ({
  type: "RESET_EVENT",
});

export const createEvent = (event: EventInfo) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(event);

  const path = "/api/events";
  try {
    var res: AxiosResponse<Event> = await axios.post(path, body, config);

    return res.data;
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

export const modifyEvent = (event: EventInfo) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(event);

  const path = "/api/events/modify";
  try {
    var res: AxiosResponse<Event> = await axios.put(path, body, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

export const getEvents = <Event>(page:number=0,pageSize:number=10) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/events?id=0&page=${page}&size=${pageSize}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    await dispatch(getEventsAction(res.data));
    return res.data
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

export const getEvent = <Event>(id: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/events/detail?id=${id}`;
  try {
    const res: AxiosResponse = await axios.get(path, config);
    dispatch(getEventAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    Object.assign(error, { detail: err.response.data.message });
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

// 이벤트 승인요청
export const changeEventApprovalRequest = (id: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify({ id: id });

  const path = "/api/events/changeEventApprovalRequest";
  try {
    var res: AxiosResponse<Event> = await axios.put(path, body, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

// 이벤트 승인요청 취소
export const changeEventApprovalWaiting = (id: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify({ id: id });

  const path = "/api/events/changeEventApprovalWaiting";
  try {
    var res: AxiosResponse<Event> = await axios.put(path, body, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

// 이벤트 상태변경([승인요청, 승인, 거절, 삭제] -> 삭제)
export const modifyEventApprovalDelete = (id: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    params: { id: id },
    data: {},
  };

  const path = "/api/events/eventApprovalDelete";
  try {
    var res: any = await axios.get(path, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

export const changeEventApproval = (id: number) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify({ id: id });

  const path = "/api/events/hostApproval";
  try {
    await axios.put(path, body, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

export const changeEventCancel = (id: number) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify({ id: id });

  const path = "/api/events/hostApprovalToWaiting";
  try {
    await axios.put(path, body, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};
