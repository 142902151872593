import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Route, Switch, useLocation, useHistory, withRouter, RouteComponentProps} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
// router
import {mainRoutes, subEventRoutes} from "../router/router";

// custom components
import FormNavbar from "../components/Navbars/FormNavbar";
import Header from "../components/Navbars/MainNavbar";
import Footer from "../components/Footer/Footer";

// types
import RouteType from "../types/models/Route";
import {setCountry} from "../actions/country";
import EventDetailNavbar from "../components/Navbars/EventDetailNavbar";
import {AppState} from "../store";
import EventDetailFooter from "../components/Footer/EventDetailFooter";
import {setRoutePath} from "../actions/routePath";

const StyledContainer = styled.div`
  max-width: 2000px;
  height: 100%;
`;

const SyledDiv = styled.div`
  min-height: 100%;
  position: relative;
  background-color: white;
`;

type RoutePath = {
  to: string;
  from: string;
};

const Main: React.FC<RouteComponentProps> = ({match}) => {
  // const themeContext = useContext(ThemeContext);
  // console.log("Current theme: ", themeContext);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [route, setRoute] = useState<RoutePath>({to: "/", from: ""});

  useEffect(() => {
    if (route.to === location.pathname) {
      dispatch(setRoutePath({previousPath: route.from}));
    }
  }, [route]);

  useEffect(() => {
    dispatch(setCountry());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const country = useSelector((state: AppState) => state.countries);

  useEffect(() => {
    if (history.location.pathname === "/") {
      const historyPushPath = localStorage.token === undefined ? "/auth/login" : "/event";
      history.push(historyPushPath);
    }

    setRoute(prev => {
      return {to: location.pathname, from: prev.to};
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      switch (prop.layout) {
        case "/":
          return <Route exact path={prop.layout + prop.path} render={props => <prop.component {...props} />} key={key} />;
        case "/auth":
        case "/event":
        case "/subEvent":
        case "/estimate":
        case "/form":
        case "/terms":
          return <Route path={prop.layout + prop.path} render={props => <prop.component {...props} />} key={key} />;
        default:
          return null;
      }
    });
  };

  const showHeader = () => {
    switch (history.location.pathname) {
      case "/":
      case "/event":
      case "/terms/terms":
      case "/terms/privacy":
        return <Header />;
      default:
        if (history.location.pathname.includes("/form")) {
          return <FormNavbar />;
        }
        if (history.location.pathname.includes("/event/detail")) {
          return <EventDetailNavbar />;
        }
        return;
    }
  };

  const showFooter = () => {
    switch (history.location.pathname) {
      case "/":
      case "/event":
      case "/terms/terms":
      case "/terms/privacy":
        return <Footer />;
      default:
        // if (
        //   history.location.pathname.includes("/event/detail") &&
        //   history.location.pathname.split("/").length === 4
        // ) {
        //   return <EventDetailFooter />;
        // }
        return null;
    }
  };

  return country.country?.countryCode !== "" ? (
    <SyledDiv>
      {showHeader()}
      <StyledContainer>
        <Switch>{getRoutes(mainRoutes)}</Switch>
      </StyledContainer>
      {showFooter()}
    </SyledDiv>
  ) : null;
};

export default withRouter(Main);
