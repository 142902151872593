import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams, useHistory} from "react-router-dom";
import {getMail} from "../../../../../actions/mail";
import {AppState} from "../../../../../store";

// types
import SubHeadingTypo from "../../../../Typhography/SubHeadingTypo";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import {TypographyProps} from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// custom ui comp
import OutlinedTxtField from "../../../../Inputs/OutlinedTxtField";

import styled from "styled-components";
import moment from "moment";
import {useTranslation} from "react-i18next";
import MeetingMail from "../MailTemplate/MeetingMail";
import MeetingConcluded from "../MailTemplate/MeetingConcluded";
import EventApplyRejectedMail from "../MailTemplate/EventApplyRejectedMail";
import EventApplyApproved from "../MailTemplate/EventApplyApproved";
import EventApply from "../MailTemplate/EventApply";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DDayEventMail from "../MailTemplate/DDayEventMail";
import {getRequestAndReturn} from "../../../../../utils/utils";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const {...rest} = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

interface PreviewInfo {
  title: string;
  content: string;
  recipients: number;
  dateOfMailing: string;
}

const RequestMailDetail = () => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const history = useHistory();
  const dispatch = useDispatch();
  const param: {secondId: string} = useParams();
  const classes = useStyles();
  const [previewInfo, setPreviewInfo] = useState<PreviewInfo>();

  useEffect(() => {
    getRequestInfo();
  }, []);

  const getRequestInfo = async () => {
    const res: any = await dispatch(getRequestAndReturn("/api/mails/request/preview", {requestId: param.secondId}));

    setPreviewInfo(res);
  };

  const getContent = (content: string) => {
    return <div className={classes.mailContent} dangerouslySetInnerHTML={{__html: content}} />;
  };

  return (
    <Grid container>
      <Grid item>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
      </Grid>
      <Grid item style={{padding: "0 0 0 64px", width: "100%"}}>
        <SubTitle>{t("mailList.recipient")}</SubTitle>
        <OutlinedTxtField value={previewInfo?.recipients} fullWidth disabled />

        <SubTitle>{t("mailList.sentDate")}</SubTitle>
        <OutlinedTxtField value={`${moment(previewInfo?.dateOfMailing).format("YYYY-MM-DD")}`} fullWidth disabled />

        <SubTitle>{t("common.title")}</SubTitle>
        <OutlinedTxtField value={previewInfo?.title} fullWidth disabled />

        <SubTitle>{t("common.content")}</SubTitle>
        <Grid
          style={{
            border: "1px solid #ccc",
            padding: "10.5px 14px",
            overflow: "scroll",
          }}
        >
          {previewInfo && getContent(previewInfo.content)}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RequestMailDetail;

const useStyles = makeStyles(theme => ({
  mailContent: {
    maxHeight: 800,
    minHeight: 500,
    "& img": {
      maxWidth: "100%",
    },
  },
}));
