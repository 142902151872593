import {MenuItem, OutlinedInput} from "@material-ui/core";
import React, {Dispatch, SetStateAction, useState} from "react";
import {useTranslation} from "react-i18next";
import PxFormControl from "../../../../Forms/PxOutlinedFormControl";
import PxSelectBox from "../../../../SelectBox/PxSelectBox";

interface Props {
  selectedMethod: string;
  setSelectedMethod: Dispatch<SetStateAction<string>>;
}

enum RegisterMethod {
  PRE_REGISTRATION = "사전등록", // 사전등록
  ON_SITE_REGISTRATION = "현장등록", // 현장등록
  DATA_IMPORT_REGISTRATION = "데이터 이전", // 데이터 받아서 하는 등록
}

const RegisterMethodSelector: React.FC<Props> = ({selectedMethod, setSelectedMethod}) => {
  //   const methodList: Array<keyof typeof RegisterMethod> = Object.keys(RegisterMethod) as Array<keyof typeof RegisterMethod>;
  const methodList: string[] = Object.values(RegisterMethod);
  const [t] = useTranslation("lang", {useSuspense: false});

  const onChangeSelectBox = (e: any) => {
    setSelectedMethod(e.target.value);
  };

  return (
    <div>
      <label>등록 방식</label>
      <PxFormControl>
        <PxSelectBox value={selectedMethod} onChange={e => onChangeSelectBox(e)} displayEmpty input={<OutlinedInput margin="dense" />}>
          <MenuItem value={""}>{t("applicationList.all")}</MenuItem>
          {methodList.map(methodItem => {
            return <MenuItem value={methodItem}>{methodItem}</MenuItem>;
          })}
        </PxSelectBox>
      </PxFormControl>
    </div>
  );
};

export default RegisterMethodSelector;
