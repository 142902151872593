import {Paper} from "@material-ui/core";
import MaterialTable, {Column, MTableToolbar} from "material-table";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import {createMailTargetUsersAction} from "../../../../../actions/mail";
import {setAlertMessage} from "../../../../../actions/alerts";
import {MailReSendUsersInfo} from "../../../../../types/models/Mail";
import {getRequestAndReturn} from "../../../../../utils/utils";
import PxButton from "../../../../Buttons/PxButton";
import {tableIcons} from "../../../../Table/PxTable";
import ButtonTypo from "../../../../Typhography/ButtonTypo";
import {MailStatus, StatsDataType} from "./RequestDashboard";
import {useHistory, useLocation} from "react-router-dom";

type Props = {
  statsData: StatsDataType;
};

const columnData: Array<Column<MailReSendUsersInfo>> = [
  {
    title: "이름",
    field: "name",
  },
  {
    title: "이메일",
    field: "email",
  },
  {
    title: "이유",
    field: "cause",
  },
];

const MailStatsTable: React.FC<Props> = React.memo(({statsData}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{secondId: string}>();
  const [data, setData] = useState<Array<MailReSendUsersInfo>>([]);
  const [selectedState, setSelectedState] = useState<MailStatus>();
  const [targetUsers, setTargetUsers] = useState<Array<MailReSendUsersInfo>>([]);

  useEffect(() => {
    dispatch(createMailTargetUsersAction(targetUsers));
  }, [targetUsers]);

  const getUserListByStatus = async (status: MailStatus) => {
    setSelectedState(status);
    const res: any = await dispatch(getRequestAndReturn(`/api/mails/request/${params.secondId}/status/${status}`, {}));
    setData(res);
  };

  const reSendMailOnClick = () => {
    if (targetUsers.length == 0) {
      dispatch(setAlertMessage("선택된 유저가 없습니다."));
      return;
    }
    history.push(`${location.pathname}/resend`);
  };

  return (
    <StateContainer elevation={8}>
      <StateBtnContainer>
        <PxButton backgroundcolor={selectedState === MailStatus.SEND ? "purple" : "white"} style={{border: "1px solid gray"}} onClick={() => getUserListByStatus(MailStatus.SEND)}>
          <ButtonTypo>수신자{`(${statsData.totalCount})`}</ButtonTypo>
        </PxButton>
        <PxButton backgroundcolor={selectedState === MailStatus.DELIVERY ? "purple" : "white"} style={{border: "1px solid gray"}} onClick={() => getUserListByStatus(MailStatus.DELIVERY)}>
          <ButtonTypo>전송{`(${statsData.deliveries})`}</ButtonTypo>
        </PxButton>
        <PxButton backgroundcolor={selectedState === MailStatus.REJECT ? "purple" : "white"} style={{border: "1px solid gray"}} onClick={() => getUserListByStatus(MailStatus.REJECT)}>
          <ButtonTypo>거절{`(${statsData.rejects})`}</ButtonTypo>
        </PxButton>
        <PxButton backgroundcolor={selectedState === MailStatus.COMPLAINTS ? "purple" : "white"} style={{border: "1px solid gray"}} onClick={() => getUserListByStatus(MailStatus.COMPLAINTS)}>
          <ButtonTypo>신고{`(${statsData.complaints})`}</ButtonTypo>
        </PxButton>
        <PxButton backgroundcolor={selectedState === MailStatus.OPEN ? "purple" : "white"} style={{border: "1px solid gray"}} onClick={() => getUserListByStatus(MailStatus.OPEN)}>
          <ButtonTypo>열기{`(${statsData.opens})`}</ButtonTypo>
        </PxButton>
        <PxButton backgroundcolor={selectedState === MailStatus.CLICK ? "purple" : "white"} style={{border: "1px solid gray"}} onClick={() => getUserListByStatus(MailStatus.CLICK)}>
          <ButtonTypo>클릭{`(${statsData.clicks})`}</ButtonTypo>
        </PxButton>
        <PxButton backgroundcolor={selectedState === MailStatus.BOUNCE ? "purple" : "white"} style={{border: "1px solid gray"}} onClick={() => getUserListByStatus(MailStatus.BOUNCE)}>
          {/* mail status bounce와 fail을 함께 보여줌 */}
          <ButtonTypo>전송실패{`(${statsData.bounces})`}</ButtonTypo>
        </PxButton>
      </StateBtnContainer>

      {/* <PxTable<FailRowType> getAction={dispatch} columns={tableData.columns} data={tableData.data} /> */}

      {columnData && (
        <MaterialTable
          columns={columnData}
          icons={tableIcons}
          data={data}
          options={{
            showTitle: false,
            actionsColumnIndex: -1,
            search: false,
            searchFieldVariant: "outlined",
            toolbar: false,
            selection: true,
            paging: false,
          }}
          totalCount={0}
          onSelectionChange={data => {
            setTargetUsers(data);
          }}
          localization={{
            toolbar: {searchPlaceholder: "이름, 소속, 휴대번호 조회"},
          }}
          onRowClick={(event, rowData) => {
            // history.push(`/subEvent/${params.id}/regitApplicationDetail/${rowData?.id}`);
          }}
          components={{
            Toolbar: props => (
              <MTableToolbar
                {...props}
                // classes={{
                //   root: classes.tableToolbarRoot,
                //   spacer: classes.tableToolbarSpacer,
                //   searchField: classes.tableSearchField,
                // }}
              />
            ),
          }}
        />
      )}
      <MailReSendContainer>
        <PxButton backgroundcolor="purple" onClick={() => reSendMailOnClick()}>
          <ButtonTypo>재전송</ButtonTypo>
        </PxButton>
      </MailReSendContainer>
    </StateContainer>
  );
});

export default MailStatsTable;

const StateContainer = styled(Paper)`
  padding: 30px;
  min-height: 1000px;
`;

const StateBtnContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const MailReSendContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0;
`;
