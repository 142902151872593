import MeetingHistory from "../types/models/MeetingHistory";
import MeetingHistoryActionTypes from "../types/actions/MeetingHistoryAction";


const meetingHistoryDefaultState: MeetingHistory = {
  meetingHistory: undefined,
  applicantMeetingHistory: [],
  meetingHistories:{
    content:[],
  pageable: {},
  last: false,
  totalElements: 0,
  totalPages: 0,
  size: 1,
  number: 0,
  sort: {
      sorted: false,
      unsorted: true,
      empty: true
  },
  numberOfElements: 1,
  first: true,
  empty: false}
};

const meetingHistoryReducer = (
  state = meetingHistoryDefaultState,
  action: MeetingHistoryActionTypes
): MeetingHistory => {
  switch (action.type) {
    case "CREATE_MEETING_HISTORY":
    case "GET_MEETING_HISTORY":
      return { meetingHistory: action.meetingHistory };
    case "GET_MEETING_HISTORIES":
      return {
        meetingHistories: action.meetingHistories,
      };
    case "GET_APPLICANT_MEETING_HISTORY":
      return {applicantMeetingHistory: action.applicantMeetingHistory}
    default:
      return state;
  }
};

export default meetingHistoryReducer;
