import React from "react";
import { Switch, RouteComponentProps, Route } from "react-router-dom";

// router
import { termsRoutes } from "../router/router";

// type
import RouteType from "../types/models/Route";

// material comp
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const Terms: React.FC<RouteComponentProps> = ({ match }) => {
  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      const path = prop.layout + prop.path;
      if (prop.layout === "/terms") {
        return <Route path={path} component={prop.component} key={key} />;
      }
    });
  };

  return (
    <Container>
      <Grid container direction="row">
        <Switch>{getRoutes(termsRoutes)}</Switch>
      </Grid>
    </Container>
  );
};

export default Terms;
