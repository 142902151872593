import React, {useEffect, useContext, useState} from "react";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import {Link, useHistory} from "react-router-dom";

// icon
import BusinessIcon from "@material-ui/icons/Business";
import TodayIcon from "@material-ui/icons/Today";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PersonIcon from "@material-ui/icons/Person";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import MapIcon from "@material-ui/icons/Map";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import ViewListIcon from "@material-ui/icons/ViewList";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import PrintIcon from "@material-ui/icons/Print";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import TranslateIcon from "@material-ui/icons/Translate";
import TextsmsIcon from "@material-ui/icons/Textsms";
import DuoIcon from "@material-ui/icons/Duo";
import styled from "styled-components";
import {SubEvenPathContext} from "../../../layouts/SubEvent";
import {AppState} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import CaptionTypo from "../../Typhography/CaptionTypo";
import {Category} from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import PeopleIcon from "@material-ui/icons/People";
import ListAltIcon from "@material-ui/icons/ListAlt";
import {useTranslation} from "react-i18next";
import {getForm, getForms} from "../../../actions/forms";

const CusMenuItem = styled(MenuItem)`
  height: 48px;
  &.MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    background-color: #e9ecef;
  }
`;

const useStyles = makeStyles(() => ({
  rootPaper: {
    boxShadow: "none",
    paddingRight: "16px",
    maxWidth: 256,
  },
  menuTitleDiv: {
    padding: "12px 0 8px 16px",
  },
}));

const SLink = styled(Link)`
  color: black;
  &:hover {
    text-decoration: none;
    color: black;
  }
`;

const Sidebar = () => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {basicPath, insidePath} = useContext(SubEvenPathContext);

  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const forms = useSelector((state: AppState) => state.forms);

  const [productMenu, setProductMenu] = useState<boolean>(false);

  // useEffect(() => {
  //   // 대쉬보드 페이지가 없어 특정페이지로 이동(프로그램페이지로 임시 이동)
  //   if (insidePath === "" || insidePath === "/") {
  //     history.push(`${basicPath}/application`);
  //   }
  // }, [basicPath, history, insidePath]);

  useEffect(() => {
    if (subEvent == undefined) return;
    if (forms.content === "" && subEvent.category !== "registration") {
      dispatch(getForm(String(subEvent?.sellerPartiForm)));
    } else if (forms.additional != "" && forms.additional != null && forms.content != "" && forms.content != null) {
      const additionalData = JSON.parse(forms.additional as string);
      (additionalData.formIds as string[]).map((formId, idx) => {
        if (additionalData.formData[formId].type === "product") {
          setProductMenu(true);
        }
      });
    }
  }, [forms, subEvent]);

  const menu = (Icon: any, title: string, path: string) => {
    let pushLink = `${basicPath}${path}`;
    if (title === t("subEventSidebar.eventOverview")) pushLink = subEvent?.mainEvent?.id ? `/event/detail/${subEvent?.mainEvent?.id}` : "/event";

    return (
      <CusMenuItem
        onClick={() => {
          if (title === t("subEventSidebar.regitDesk")) {
            window.open(`/subEvent/${subEvent?.id}/regitDesk`, "_blank");
          } else {
            history.push(pushLink);
          }
        }}
        selected={insidePath.split("/")[1] === path.split("/")[1]}
      >
        <ListItemIcon style={{minWidth: "36px"}}>
          <Icon style={{width: "24px", height: "24px", objectFit: "contain"}} />
        </ListItemIcon>
        <Typography variant="inherit" style={{fontSize: "14px", fontWeight: "bold"}}>
          {title}
        </Typography>
      </CusMenuItem>
    );
  };

  return (
    <Paper className={classes.rootPaper}>
      <div className={classes.menuTitleDiv}>
        <CaptionTypo>{t("subEventSidebar.menu")}</CaptionTypo>
      </div>
      <MenuList>
        {subEvent?.category !== "registration" && menu(BusinessIcon, t("subEventSidebar.participants"), "/application")}

        {productMenu && menu(ListAltIcon, "참가기업 제품", "/product")}

        {subEvent?.category === "registration" && (
          <div>
            {menu(AddIcon, t("subEventSidebar.participantsRegistration"), "/regitApplicationAdd")}
            {menu(BusinessIcon, t("subEventSidebar.participantsList"), "/regitApplicationList")}
          </div>
        )}

        {subEvent?.matching === "Y" && (
          <div>
            {menu(TodayIcon, t("subEventSidebar.meetingStatus"), "/meeting")}
            {menu(CompareArrowsIcon, t("subEventSidebar.matchingHistory"), "/meetingHistory")}
            {menu(AssignmentIcon, t("subEventSidebar.meetingNoteHistory"), "/counsel")}
            {menu(TranslateIcon, t("subEventSidebar.interpreter"), "/interpreter")}
          </div>
        )}
        {subEvent?.category === "presentation" && <div>{menu(DuoIcon, t("subEventSidebar.webinar"), "/webinar")} </div>}
        {subEvent?.category !== "registration" && (
          <div>
            {menu(NotificationsIcon, t("subEventSidebar.notice"), "/notice")}
            {menu(TextsmsIcon, "SMS", "/sms")}
          </div>
        )}
        {menu(MailOutlineIcon, t("subEventSidebar.mailing"), "/mail")}
        {menu(PeopleIcon, t("subEventSidebar.teamManage"), "/team")}
        {subEvent?.category !== "registration" && menu(FileCopyIcon, t("subEventSidebar.data"), "/excel")}
        {subEvent?.category !== "presentation" && subEvent?.category !== "registration" && (
          <div>
            <SLink to={basicPath + "/monitoring"} target="_blank">
              <CusMenuItem selected={insidePath.split("/")[1] === "/monitoring".split("/")[1]}>
                <ListItemIcon style={{minWidth: "36px"}}>
                  <DesktopWindowsIcon
                    style={{
                      width: "24px",
                      height: "24px",
                      objectFit: "contain",
                    }}
                  />
                </ListItemIcon>
                <Typography variant="inherit" style={{fontSize: "14px", fontWeight: "bold"}}>
                  {t("subEventSidebar.monitoring")}
                </Typography>
              </CusMenuItem>
            </SLink>
          </div>
        )}
        {subEvent?.category !== "registration" && menu(PrintIcon, t("subEventSidebar.regitDesk"), "/")}
        {menu(ExitToAppIcon, t("subEventSidebar.eventOverview"), "/")}

        {/* {menu(PersonIcon, "참가자", "/")} */}
        {/* {menu(DonutSmallIcon, "설문지 통계", "/")} */}
        {/* {menu(PermContactCalendarIcon, "연락처 교환", "/")} */}
        {/* {menu(MapIcon, "디지털맵", "/")} */}
        {/* {subEvent?.category !== "presentation" && (
          <div>
            {menu(RecordVoiceOverIcon, "스피커", "/speaker")}
            {menu(ViewListIcon, "프로그램", "/program")}
          </div>
        )} */}

        {/* <SLink to={basicPath + "/teamManage"} target="_blank">
              <CusMenuItem selected={insidePath.split("/")[1] === "/teamManage".split("/")[1]}>
                <ListItemIcon style={{minWidth: "36px"}}>
                  <PeopleIcon
                    style={{
                      width: "24px",
                      height: "24px",
                      objectFit: "contain",
                    }}
                  />
                </ListItemIcon>
                <Typography variant="inherit" style={{fontSize: "14px", fontWeight: "bold"}}>
                  {t("subEventSidebar.teamManage")}
                </Typography>
              </CusMenuItem>
            </SLink> */}

        {/* {menu(MailOutlineIcon, "단체 메일발송", "/")} */}
      </MenuList>
    </Paper>
  );
};

export default Sidebar;
