import CounselActionTypes from "../types/actions/CounselAction";
import Counsel from "../types/models/Counsel";

const counselDefaultState: Counsel = {
  counsel: {
    applicantMeetingSurvey: [],
    acceptorMeetingSurvey: [],
    meetingSurveys:[],
  },
  counsels: [],
  counselsExcel: [],
};

const counselReducer = (
  state = counselDefaultState,
  action: CounselActionTypes
): Counsel => {
  switch (action.type) {
    case "GET_COUNSELS":
      return { ...state, counsels: action.counsels };
    case "GET_COUNSELS_EXCEL":
      return { ...state, counselsExcel: action.counselsExcel };
    case "RESET_COUNSELS":
      return { ...state, counsels: counselDefaultState.counsels };
    case "RESET_COUNSELS_EXCEL":
      return { ...state, counselsExcel: counselDefaultState.counselsExcel };
    default:
      return state;
  }
};

export default counselReducer;
