import Container from "@material-ui/core/Container";
import React, { useState } from "react";
import QrReader from "react-qr-reader";

const QRreader = () => {
  const [data, setData] = useState<any>();

  const handleScan = (data: any) => {
    if (data) {
      console.log(data, "qr data");
      setData(data);
    }
  };

  const handelError = (err: any) => {
    console.log(err);
  };

  return (
    <Container maxWidth="sm">
      <QrReader
        delay={1000}
        onError={handelError}
        onScan={handleScan}
        style={{ width: "100%" }}
      />
    </Container>
  );
};

export default QRreader;
