import {find} from "lodash";
import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getRegitUserById} from "../../../../../../actions/registration";
import {AppState} from "../../../../../../store";
import {ApplicationFormAnswers} from "../../../../../../types/models/Application";
import {RegisterFormAnswer, RegisterMethod} from "../../../../../../types/models/Registration";
import ContentFormItem from "../../CompanyInfo/ContentFormItem";
import RegisterFormItem from "../RegisterFormItem";
import {useRegisterInfoContext} from "./RegisterInfoProvider/useRegisterInfoContext";

interface UserInfo {
  id: number;
  method: RegisterMethod;
  name: string;
  company: string;
  position: string;
  phoneNum: string;
  type: string;
  email: string;
  createdDate: string;
  answers: Array<ApplicationFormAnswers>;
}

const UserInfoContent: React.FC = props => {
  const {contentForm, modifyStatus, contentFormAnswers, modifiedFormAnswers} = useRegisterInfoContext();
  const registerUser = useSelector((state: AppState) => state.registration.userDetail);
  const dispatch = useDispatch();
  const params = useParams<{id: string; secondId: string}>();
  const [userInfo, setUserInfo] = useState<UserInfo>();

  useEffect(() => {
    if (!registerUser.registerFormAnswer) return;
    if (registerUser.registerFormAnswer.length < 1) return;
  }, [registerUser]);

  return (
    <Fragment>
      {contentForm != undefined &&
        contentForm.formIds.map((formId, index) => {
          let applicationFormAnswer: RegisterFormAnswer | undefined; // 일반 답변
          let applicationManyFormAnswer: RegisterFormAnswer[] = []; // 복수선택 답변

          console.log(`contentFormAnswers : `, contentFormAnswers);

          // 회사정보 페이지에서 수정하다가 추가정보로 가서 수정하고 다시 돌아오면 값이 원래 값으로 돌아가는 것을 방지
          //   if (modifyStatus === true) {
          //     applicationFormAnswer = find(modifiedFormAnswers, {
          //       uuid: formId,
          //       //   repeatInfo: "N",
          //     });
          //   } else {
          console.log(`요기`);
          applicationFormAnswer = find(contentFormAnswers, {
            uuid: formId,
          });
          //   }

          // 복수선택 답변값 세팅
          if (contentForm.formData[formId].type === "many") {
            if (modifyStatus === true) {
              modifiedFormAnswers.forEach(answer => {
                if ((answer.uuid === formId, answer.type === "many")) {
                  applicationManyFormAnswer.push(answer);
                }
              });
            } else {
              contentFormAnswers.forEach(answer => {
                if (answer.uuid === formId) {
                  applicationManyFormAnswer.push(answer);
                }
              });
            }
          }

          console.log(`applicationFormAnswer : `, applicationFormAnswer);

          return (
            <>
              {contentForm.formData[formId].title != "분류" && (
                <RegisterFormItem key={index} modify={modifyStatus} contentFormData={contentForm.formData[formId]} formAnswer={applicationFormAnswer!} manyFormAnswers={applicationManyFormAnswer} />
              )}
            </>
            // <ContentFormItem
            //   key={index}
            //   modify={modifyStatus}
            //   contentFormData={contentForm.formData[formId]} // 신청서 자율양식 form 형식
            //   applicationFormAnswer={applicationFormAnswer!} // 신청서 답변
            //   applicationManyFormAnswers={applicationManyFormAnswer}
            //   whtAdditional={false}
            // />
          );
        })}
    </Fragment>
  );
};

export default UserInfoContent;
