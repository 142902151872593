import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import grapesjs from "grapesjs";
import {useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {geditorConfig} from "../../../../utils/editor/gEditorConfig";

interface Props {
  eventId: number;
  setTemplateContent: Dispatch<SetStateAction<string>>;
  logoImage?: string;
  eventMainImage?: string;
}

const MailEditor: React.FC<Props> = ({eventId, setTemplateContent, logoImage, eventMainImage}) => {
  //   const event = useSelector((state: AppState) => state.events.event);
  // const [editor, setEditor] = useState<grapesjs.Editor>();
  // let editor: grapesjs.Editor;

  useEffect(() => {
    let editor: grapesjs.Editor = geditorConfig(eventId, "mail");

    // editor.Panels.addButton("options", {
    //   id: "open-code",
    //   className: "fa fa-code",
    //   command: "open-code",
    //   attributes: {title: "Open Code Editor"},
    // });

    // // open-code 명령어 추가
    // editor.Commands.add("open-code", {
    //   run(editor, sender) {
    //     sender && sender.set("active", false); // 버튼 비활성화
    //     const htmlContent = editor.getHtml();
    //     editor.Modal.open({
    //       title: "Edit Code",
    //       content: `<textarea style="width:100%; height:100%;">${htmlContent}</textarea>`,
    //       attributes: {style: "width:100%; height:100%;"},
    //     });
    //   },
    // });

    editor.setComponents(`
    <style>
    div[data-gjs-type="text"] {
      font-family: 'Nanum Gothic', sans-serif;
      word-break: break-all;
    }
  </style>
  <body style="box-sizing: border-box; margin: 0;">
  <table width="100%" height="100%" bgcolor="rgb(234, 236, 237)" id="iwrh" style="box-sizing: border-box; min-height: 150px; padding-top: 5px; padding-right: 5px; padding-bottom: 5px; padding-left: 5px; width: 100%; height: 120vh; background-color: rgb(234, 236, 237);">
    <tbody id="i0a4" style="box-sizing: border-box;">
      <tr valign="top" id="iaoi" class="row" style="box-sizing: border-box; vertical-align: top;">
        <td id="ig62" style="box-sizing: border-box;">
          <table width="90%" height="100%" id="ip6o" style="box-sizing: border-box; font-family: Helvetica, serif; min-height: 150px; padding-top: 5px; padding-right: 5px; padding-bottom: 5px; padding-left: 5px; margin-top: auto; margin-right: auto; margin-bottom: auto; margin-left: auto; width: 90%; max-width: 550px; height: 100%;">
            <tbody id="iolv" style="box-sizing: border-box;">
              <tr id="i5zi" style="box-sizing: border-box;">
                <td valign="top" id="insu" style="box-sizing: border-box; vertical-align: top; font-size: medium; padding: 0px 0px 0px 0px;">
                  <table width="100%" id="icl9j" class="c1766" style="box-sizing: border-box; margin-top: 0px; margin-right: auto; margin-bottom: 10px; margin-left: 0px; padding-top: 5px; padding-right: 5px; padding-bottom: 5px; padding-left: 5px; width: 100%; min-height: 30px;">
                    <tbody id="idrpc" style="box-sizing: border-box;">
                      <tr id="ilrt6" style="box-sizing: border-box;">
                        <td width="11%" id="ihfru" class="cell c1769" style="box-sizing: border-box; width: 11%;">
                          <img id="i1bset" src="${logoImage}" width="85" height="85" style="box-sizing: border-box; color: black; object-fit: contain; width: 85px; height: 85px;">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table height="85%" id="i01qn" width="100%" style="box-sizing: border-box; min-height: 150px; padding-top: 5px; padding-right: 5px; padding-bottom: 5px; padding-left: 5px; margin-bottom: 20px; width: 100%; height: 85%;">
                    <tbody id="iqx61" style="box-sizing: border-box;">
                      <tr id="idlil" style="box-sizing: border-box;">
                        <td bgcolor="rgb(255, 255, 255)" align="center" id="idihb" valign="top" style="box-sizing: border-box; background-color: rgb(255, 255, 255); overflow-x: hidden; overflow-y: hidden; border-top-left-radius: 3px; border-top-right-radius: 3px; border-bottom-right-radius: 3px; border-bottom-left-radius: 3px; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px; text-align: center; vertical-align: top;">
                          <img id="it7qqq" src="${eventMainImage}" height="326.0088806152344" style="box-sizing: border-box; color: black; width: 100%; height: 326.0088806152344px; object-fit: contain;">
                          <table width="100%" height="52%" id="id1ew" style="box-sizing: border-box; width: 100%; min-height: 150px; padding-top: 5px; padding-right: 5px; padding-bottom: 5px; padding-left: 5px; margin-top: 0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; border-collapse: collapse; height: 52%;">
                            <tbody id="i36dq" style="box-sizing: border-box;">
                              <tr id="ic81h" style="box-sizing: border-box;">
                                <td valign="top" id="ik6fs" style="box-sizing: border-box; font-size: 13px; line-height: 20px; color: rgb(111, 119, 125); vertical-align: top; padding: 0px 0vw 0px 0px;">
                                  <div id="ijztx" style="text-align: center; box-sizing: border-box; font-size: 25px; color: rgb(68, 68, 68); width: 100%;">
                                  </div>
                                  <br id="i7pog" style="box-sizing: border-box;">
                                  <div id="ivu91" style="box-sizing: border-box; padding: 10px; white-space: pre-wrap; height:60px;">안녕하세요 킨터치 박장혁님 \n-> 안녕하세요 #organization #name님
                                  </div>
                                  <div id="i9b15" style="box-sizing: border-box; padding: 10px;">이메일 내용을 입력해주세요.
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table align="center" id="iv7ebm" class="footer" style="box-sizing: border-box; margin-top: 50px; color: rgb(152, 156, 165); text-align: center; font-size: 11px; padding-top: 5px; padding-right: 5px; padding-bottom: 5px; padding-left: 5px;">
                    <tbody id="iqq2p7" style="box-sizing: border-box;">
                      <tr id="img7kv" style="box-sizing: border-box;">
                        <td id="igszqj" class="footer-cell" style="box-sizing: border-box;">
                          <div id="i4lpdk" class="c2577" style="box-sizing: border-box; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;">
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</body>
          `);

    editor.onReady(() => {
      const inLineHtml = editor.runCommand("gjs-get-inlined-html");
      setTemplateContent(inLineHtml);
    });

    editor.on("component:update", () => {
      const inLineHtml = editor.runCommand("gjs-get-inlined-html");
      setTemplateContent(inLineHtml);
    });

    //
    editor.on("update", () => {
      const inLineHtml = editor.runCommand("gjs-get-inlined-html");
      setTemplateContent(inLineHtml);
    });
  }, []);

  return <div id="editor"></div>;
};

export default MailEditor;
