import ProductActionTypes from "../types/actions/ProductAction";
import { ProductList } from "../types/models/Product";

interface Product {
    productList: ProductList;
    productSearchWord: string;
}


const productDefaultState: Product = {
    productList: {
        content: [],
        totalElements: 0,
        totalPages: 0,
        size: 0,
        number: undefined,
        sort: {
            sorted: false,
            unsorted: false,
            empty:false
        },
        numberOfElements: 0,
        first: false,
        empty: false
    },
    productSearchWord:""
}

const productReducer = (
    state = productDefaultState,
    action: ProductActionTypes
):Product => {
    switch (action.type) {
        case "GET_PRODUCT_LIST": 
            return {...state, productList:action.productList};
        case "SET_PRODUCT_SEARCHWORD":
            return {...state, productSearchWord:action.productSearchWord};
        default:
            return state;
    }
}

export default productReducer;
