import React, { useEffect, createContext } from "react";
import { Switch, RouteComponentProps, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { AppState } from "../store";

import Header from "../components/SubEvent/InsideMenu/Header";
import Sidebar from "../components/SubEvent/InsideMenu/Sidebar";

// action
import { getSubEvent, removeSubEventAction } from "../actions/subEvents";

// router
import { subEventRoutes } from "../router/router";

// type
import RouteType from "../types/models/Route";

// material comp
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { setDialog } from "../actions/dialogs";

interface SubEventPathContextType {
  pathSplit: string[]; // url split("/")
  basicPath: string; // `/subEvent/${subEventId}`
  insidePath: string; // '/program/...', 'speaker/...' ...
  subEventId: string;
}
export const SubEvenPathContext = createContext<SubEventPathContextType>(null!);

const SubEvent: React.FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const pathSplit = location.pathname.split("/");
  const basicPath = `/${pathSplit[1]}/${pathSplit[2]}`;
  const insidePath = location.pathname.replace(basicPath, "");
  const subEventId = pathSplit[2];

  let idList = [":id", ":secondId"];
  let idNumber = 0;
  // path 설정 ( 모양: /:id/program/:secondId , 규칙: key는 idList에 지정된 순번대로 사용 )
  let pathEquals = "";
  pathSplit.map((path: string, idx: number) => {
    if (idx > 1 && is_number(path) === false) {
      pathEquals += `/${path}`;
    } else if (idx > 1 && is_number(path) === true) {
      pathEquals += `/${idList[idNumber]}`;
      idNumber++;
    }
  });

  // url 키 여부(숫자) 체크
  function is_number(value: string) {
    const reg = /^(\d)+$/;
    return reg.test(value);
  }

  const subEventStore = useSelector(
    (state: AppState) => state.subEvents.subEvent
  );

  // 인증여부 체크
  useEffect(() => {
    if (
      subEventStore !== undefined &&
      (subEventStore.mainEvent?.approval === "N" ||
        subEventStore.mainEvent?.approval === "R")
    ) {
      dispatch(
        setDialog(`관리자 미승인 이벤트`, [
          "관리자에게 승인 받은 후 이용가능합니다.",
        ])
      );
      history.push("/");
    }
  }, [subEventStore]);

  useEffect(() => {
    if (
      subEventStore !== undefined &&
      subEventStore.id !== "" &&
      subEventStore.id !== pathSplit[2]
    ) {
      history.push("/");
    }

    dispatch(getSubEvent(parseInt(pathSplit[2])));

    return () => {
      dispatch(removeSubEventAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      if (
        prop.path === pathEquals &&
        (prop.path.includes("monitoring") ||
          prop.path.includes("regitDesk") ||
          prop.path.includes("qrScanner") ||
          prop.path.includes("onSiteRegit"))
      ) {
        return (
          <Grid
            item
            style={{
              width: "100%",
              height: "calc(100vh - 50px)",
              overflowY: "scroll",
              paddingBottom: prop.path.includes("monitoring") ? 100 : 0,
            }}
          >
            <Route
              path={`/subEvent${pathEquals}`}
              component={prop.component}
              key={key}
            />
          </Grid>
        );
      }
      if (prop.path === pathEquals) {
        return (
          <div style={{ display: "flex" }}>
            <Grid
              item
              sm={3}
              md={2}
              lg={2}
              style={{ width: "100%", height: "100%" }}
            >
              <Sidebar />
            </Grid>
            <Grid
              item
              sm={9}
              md={10}
              lg={10}
              style={{
                width: "100%",
                paddingRight: 50,
                // height: "calc(100vh - 50px)",
                // overflowY: "scroll",
                paddingBottom: 100,
              }}
            >
              <Route
                path={`/subEvent${pathEquals}`}
                component={prop.component}
                key={key}
              />
            </Grid>
          </div>
        );
      }
    });
  };

  return (
    <SubEvenPathContext.Provider
      value={{
        pathSplit: pathSplit,
        basicPath: basicPath,
        insidePath: insidePath,
        subEventId: subEventId,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Grid item sm={12} md={12} lg={12}>
          <Header />
        </Grid>

        <Switch>{getRoutes(subEventRoutes)}</Switch>
      </div>
    </SubEvenPathContext.Provider>
  );
};

export default SubEvent;
