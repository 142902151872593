import { AppActions } from "../store";
import Template, { SaveTemplateInfo } from "../types/models/Template";
import { getRequest, patchRequest, postRequest } from "../utils/utils";



export const saveTemplateAction = (template: Template["templateContent"]): AppActions => ({
    type: "SAVE_TEMPLATE",
    templateContent:template,
})

export const getUserTemplateListAction = (templateList: Template["userTemplateList"]): AppActions => ({
    type: "GET_USER_TEMPLATE_LIST",
    userTemplateList:templateList
})


export const getDefaultTemplateListAction = (templateList: Template["defaultTemplateList"]): AppActions => ({
    type: "GET_DEFAULT_TEMPLATE_LIST",
    defaultTemplateList:templateList
})

export const getTemplateContentAction = (templateContent: Template["templateContent"]): AppActions => ({
    type: "GET_TEMPLATE_CONTENT",
    templateContent:templateContent
})

export const deleteContentAction = (): AppActions => ({
    type: "DELETE_TEMPLATE",
})

export const updateContentAction = (): AppActions => ({
    type: "UPDATE_TEMPLATE",
})

export const newTemplateAction = (): AppActions => ({
    type: "NEW_TEMPLATE",
    templateContent:{templateId:0,templateName:"",htmlContent:"",cssContent:""}
})


export const saveTemplate = (template: SaveTemplateInfo) => {
    return postRequest(template, "/api/template/", saveTemplateAction);
}

export const getTemplateContent = (templateId:number) => {
    return getRequest("", `/api/template/content/${templateId}`, getTemplateContentAction);
}

export const getUserTemplateList = (eventId: number) => {
    return getRequest("", `/api/template/event/${eventId}/list`, getUserTemplateListAction);
}


export const getDefaultTemplateList = () => {
    return getRequest("", `/api/template/event/default-list`, getDefaultTemplateListAction);
}

export const updateUserTemplate = (templateInfo: Template["templateContent"]) => {
    return patchRequest(templateInfo, `/api/template`, updateContentAction);
}

export const deleteTemplate = (templateId: number) => {
    return postRequest("",`/api/template/delete/${templateId}`);
}









