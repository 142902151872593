import React, { useEffect, useState } from "react";
import PxButton from "../../../Buttons/PxButton";
import PxGridContainer from "../../../Grid/PxGridContainer";
import TitleTypo from "../../../Typhography/TitleTypo";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../store";
import {
  getPrinterAction,
  getPrinters,
  setOnSiteRegitAction,
} from "../../../../actions/printers";
import { useHistory } from "react-router";
import FormControl from "@material-ui/core/FormControl";
import PxSelectBox from "../../../SelectBox/PxSelectBox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(() => ({
  rootContainer: {
    textAlign: "center",
    paddingTop: 100,
  },
  typeTxt: {
    fontSize: 35,
    marginBottom: 0,
  },
  formControl: {
    minWidth: 150,
  },
}));

const OnSiteRegistration = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const { printers, printer } = useSelector(
    (state: AppState) => state.printers
  );

  useEffect(() => {
    dispatch(getPrinters());
  }, [dispatch]);

  const printerOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (printers) {
      dispatch(
        getPrinterAction(
          printers.find((printer) => printer.id === event.target.value)!
        )
      );
    }
  };

  return (
    <Container className={classes.rootContainer} maxWidth="sm">
      <TitleTypo style={{ fontSize: 35 }}>현장등록</TitleTypo>
      {printers && (
        <FormControl className={classes.formControl}>
          <PxSelectBox
            input={<OutlinedInput margin="dense" style={{ padding: 3 }} />}
            onChange={printerOnChange}
            value={printer?.id}
          >
            {printers.map((printer) => (
              <MenuItem value={printer.id}>{printer.name}</MenuItem>
            ))}
          </PxSelectBox>
        </FormControl>
      )}
      <Box height={30} />
      <PxGridContainer direction="column">
        <PxButton
          backgroundcolor="purple"
          padding="80px 100px"
          onClick={() => {
            dispatch(setOnSiteRegitAction("seller"));
            history.push(`/subEvent/${subEvent?.id}/onSiteRegit/printer`);
          }}
        >
          <p className={classes.typeTxt}>{subEvent?.sgroupName ?? ""}</p>
        </PxButton>
        <Box height={20} />
        <PxButton
          backgroundcolor="grey"
          padding="80px 100px"
          onClick={() => {
            dispatch(setOnSiteRegitAction("buyer"));
            history.push(`/subEvent/${subEvent?.id}/onSiteRegit/printer`);
          }}
        >
          <p className={classes.typeTxt}>{subEvent?.bgroupName ?? ""}</p>
        </PxButton>
      </PxGridContainer>
    </Container>
  );
};

export default OnSiteRegistration;
