import { AppActions, AppState } from "../store";
import { FormAnswerInfo } from "../types/models/FormAnswer";
import { Dispatch } from "redux";
import { getRequest, postRequest } from "../utils/utils";

export const getFormAnswersAction = (
  formAnswers: FormAnswerInfo[]
): AppActions => ({
  type: "GET_FORM_ANSWERS",
  formAnswers: formAnswers,
});

export const removeFormAnswerAction = (): AppActions => ({
  type: "REMOVE_FORM_ANSWER",
});

export const getFormAnswers = (applicantionId: string) => {
  return getRequest(
    null,
    `/api/formAnswers?applicantionId=${applicantionId}`,
    getFormAnswersAction,
    {}
  );
};

export const removeFormAnswer = () => (dispatch: Dispatch<AppActions>) => {
  dispatch(removeFormAnswerAction());
};
