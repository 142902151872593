import Event from "../types/models/Event";
import EventActionTypes from "../types/actions/EventAction";

const eventDefaultState: Event = {
  event: {
    name: "", // 이벤트 이름
    startDate: "", // 이벤트 기간
    endDate: "", // 이벤트 기간
    image: "", // 이벤트 이미지
    locationCheck: true, // 이벤트 장소 여부
    location: "", // 이벤트 장소
    locationDetail: "", // 이벤트 장소 상세
    lat: "", // 위도
    lng: "", // 경도
    content: "", // 이벤트 개요
    homePageCheck: true, // 이벤트 웹사이트 체크
    homePage: "", // 이벤트 웹사이트
    atchFile: [], // 첨부파일
    atchFile1: -1, // 첨부파일1
    atchFile2: -1, // 첨부파일2
    atchFile3: -1, // 첨부파일3
    atchFile4: -1, // 첨부파일4
    atchFile5: -1, // 첨부파일5
    mainImageFile: {}, // 메인이미지 정보
    mainImage: -1, // 메인이미지
    digitalMap: "", // 디지털맵
    managers: [], // 담당자
  },
  events: {
    content:[],
    pageable: {},
    last: false,
    totalElements: 0,
    totalPages: 0,
    size: 1,
    number: 0,
    sort: {
        sorted: false,
        unsorted: true,
        empty: true
    },
    numberOfElements: 1,
    first: true,
    empty: false
    },
};

const eventReducer = (
  state = eventDefaultState,
  action: EventActionTypes
): Event => {
  switch (action.type) {
    case "CREATE_EVENT":
    case "GET_EVENT":
      return { ...state,event:action.event };
    case "GET_EVENTS":
      return {
        ...state,
        events: action.events,
      };
    case "RESET_EVENT":
      return { ...state, event: eventDefaultState.event };
    default:
      return state;
  }
};

export default eventReducer;
