import React, {useCallback, useEffect, useState} from "react";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";
import {AutonoForm, FormData} from "../../../../types/models/AutonoForm";
import * as XLSX from "xlsx-js-style";
import {saveAs} from "file-saver";

interface Props {
  formData: FormData;
}

const RegisterExcelForm: React.FC<Props> = ({formData}) => {
  const [columns, setColumns] = useState<string[]>();
  const defaultFormData = ["이름", "전화번호", "회사이름", "이메일", "직책"];
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileName = "사전 등록 양식.xlsx";

  useEffect(() => {
    if (formData == undefined) return;
    const formObj = formData.formData;
    const titles: string[] = formData.formIds.map(formId => formObj[formId].title).filter((title): title is string => title !== undefined);

    setColumns([...defaultFormData, ...titles]);
  }, [formData]);

  const fileDownLoad = useCallback(() => {
    if (!columns) return;
    if (!columns.includes("분류")) return;
    const workSheetData = [columns, []];

    const worksheet = XLSX.utils.aoa_to_sheet(workSheetData);
    const workbook = XLSX.utils.book_new();

    // column 넓이
    worksheet["!cols"] = columns.map(() => {
      return {wpx: 200};
    });

    // 1행 요소에만 색상 설정
    columns.forEach((_, C) => {
      const cell_address = XLSX.utils.encode_cell({r: 0, c: C});
      if (!worksheet[cell_address]) return;

      worksheet[cell_address].s = {
        fill: {
          fgColor: {rgb: "FFFFAA00"}, // 셀 배경색 설정
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
        },
      };
    });

    // 모든 행의 높이 설정
    worksheet["!rows"] = workSheetData.map(() => ({hpx: 30}));

    XLSX.utils.book_append_sheet(workbook, worksheet, "유저 등록");

    const excelBuffer = XLSX.write(workbook, {bookType: "xlsx", type: "array"});
    const blob = new Blob([excelBuffer], {type: fileType});
    saveAs(blob, fileName);
  }, [columns]);

  return (
    <PxButton backgroundcolor="purple" onClick={fileDownLoad}>
      <ButtonTypo>등록양식 다운로드</ButtonTypo>
    </PxButton>
  );
};

export default RegisterExcelForm;
