import RoutePathActionTypes from "../types/actions/RoutePathAction";
import { RoutePath } from "../types/models/RoutePath";


const routePathDefaultState: RoutePath = {
    previousPath:"/"
}

const routePathReducer = (
    state = routePathDefaultState,
    action: RoutePathActionTypes
): RoutePath => {

    switch (action.type) {
        case "SET_ROUTE_PATH":

            const previousPath = action.routePath.previousPath !== state.previousPath ? action.routePath : state;
            
            return previousPath;
        default:
            return {...state};
    }
    
}

export default routePathReducer;