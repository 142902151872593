import React from "react";
import { RouteComponentProps } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import MeetingMatchingList from "./MeetingMatchingList";
import MeetingHistoryList from "./MeetingHistoryList";

const MeetingHistory: React.FC<RouteComponentProps> = () => {
  const [tapNumber, setTapNumber] = React.useState(1);

  const handleChange = (event: React.ChangeEvent<{}>, newTapNumber: number) => {
    setTapNumber(newTapNumber);
  };

  return (
    <>
      <MeetingHistoryList />
      {/* <Paper square style={{ margin: "20px 0" }}>
        <Tabs
          value={tapNumber}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="matching" value={1} />
          <Tab label="history" value={2} />
        </Tabs>
      </Paper>
      {tapNumber === 1 ? <MeetingMatchingList /> : <MeetingHistoryList />} */}
    </>
  );
};
export default MeetingHistory;
