import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {getEvents as getEventsNoneDispatch} from "../../actions/events";
import {AppState, AppActions} from "../../store";

import {Column} from "material-table";

// types
import Event from "../../types/models/Event";

// custom comp
import PxTable from "../../components/Table/PxTable";

import {ThunkDispatch} from "redux-thunk";
import {bindActionCreators} from "redux";
import {connect, useDispatch, useSelector} from "react-redux";
import moment from "moment";
import ButtonTypo from "../Typhography/ButtonTypo";
import {parallaxCalculation} from "../../utils/momentTz";
import {setTableInfoLoadingAction} from "../../actions/tableInfo";
import {useTranslation} from "react-i18next";

interface RowType {
  evName: string;
  evCreatedDate: string;
  evDate: string;
  evUpdatedDate: string;
  approval: string;
  id: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

type Props = DispatchProps & reduxStateProps;

const EventList: React.FC<Props> = props => {
  const [t, i18n] = useTranslation("lang", {useSuspense: false});
  const history = useHistory();
  const dispatch = useDispatch();
  const {events, getEvents} = props;
  const {country, countries} = useSelector((state: AppState) => state.countries!);
  const tableInfo = useSelector((state: AppState) => state.tableInfo); // 리덕스에 저장한 테이블 정보

  const callGetEvents = useCallback(async () => {
    await getEvents(tableInfo.pageNum);
    if (!events || events.content.length <= 0) dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, getEvents]);

  useEffect(() => {
    window.scrollTo(0, 0);
    callGetEvents();
  }, []); // eslint-disable-line

  const timeset = (date: string, countryCode: string) => {
    return parallaxCalculation(date, "", countries![countryCode! as string] as any, "YYYY-MM-DD HH:mm");
  };

  useEffect(() => {
    if (events && Array.isArray(events.content) && events.content.length > 0) {
      let tableData: any = [];

      events.content.map(ev => {
        const rowData = {
          evName: ev.name,
          evDate: `${timeset(ev.startDate, ev.countryCode! as string)} - ${timeset(ev.endDate, ev.countryCode! as string)}`,
          evCreatedDate: moment(ev.createdDate).format("YYYY-MM-DD"),
          evUpdatedDate: moment(ev.modifiedDate).format("YYYY-MM-DD"),
          approval: ev.approval,
          id: ev.id,
        };

        tableData.push(rowData);
      });
      setState(prevCreatedDate => {
        return {...prevCreatedDate, data: tableData};
      });
      dispatch(setTableInfoLoadingAction(false));
    }
  }, [events]);

  useEffect(() => {
    const title = [t("eventList.eventList"), t("common.period"), t("common.registerDate"), t("common.reviseDate"), t("common.status"), ""];
    setState(prev => {
      const cols = prev.columns.map((data, index) => {
        return {...data, title: title[index]};
      });
      return {...prev, columns: cols};
    });
  }, [i18n.language]);

  const tableTitle = () => {
    return (
      <span
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          color: "rgba(0, 0, 0, 0.87)",
        }}
      >
        {t("eventList.eventList")}
      </span>
    );
  };
  const [state, setState] = useState<TableStateType>({
    columns: [
      {
        title: t("eventList.eventList"),
        field: "evName",
        cellStyle: {
          width: "calc(100%-630px)",
          paddingRight: 0,
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("common.period"),
        field: "evDate",
        cellStyle: {
          width: "290px",
          paddingRight: 0,
        },
      },
      {
        title: t("common.registerDate"),
        field: "evCreatedDate",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
      },
      {
        title: t("common.reviseDate"),
        field: "evUpdatedDate",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
      },
      {
        title: t("common.status"),
        field: "approval",
        width: 120,
        cellStyle: {
          paddingRight: 0,
        },
        render: (rowData: any) => {
          const approvalText = {
            Y: t("common.approval"),
            W: t("common.await"),
            R: t("common.request"),
            N: t("common.reject"),
          };
          return <ButtonTypo>{approvalText[rowData.approval as "Y" | "N" | "R" | "W"]}</ButtonTypo>;
        },
      },
      // {
      //   title: "",
      //   field: "subEvFormSetting",
      //   cellStyle: {
      //     width: "100px",
      //     paddingRight: 0,
      //   },
      //   render: (rowData) => (
      //     <>
      //       {rowData.approval === "W" || rowData.approval === "N" ? (
      //         <PxButton
      //           backgroundcolor="purple"
      //           style={{ padding: 5 }}
      //           onClick={async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      //             event.stopPropagation();
      //             const res: any = await dispatch(changeEventApprovalRequest(rowData.id));
      //             if (res === "success") {
      //               dispatch(
      //                 setDialog(t("eventList.chagneOfStatus"), [t("eventList.requestCompleted")])
      //               );
      //               getEvents();
      //             }
      //           }}
      //         >
      //           <ButtonTypo>{t("common.request")}</ButtonTypo>
      //         </PxButton>
      //       ) : rowData.approval === "R" ? (
      //         <PxButton
      //           backgroundcolor="purple"
      //           style={{ padding: 5 }}
      //           onClick={async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      //             event.stopPropagation();
      //             const res: any = await dispatch(changeEventApprovalWaiting(rowData.id));
      //             if (res === "success") {
      //               dispatch(
      //                 setDialog(t("eventList.chagneOfStatus"), [
      //                   "이벤트 승인요청이 취소되었습니다..",
      //                 ])
      //               );
      //               getEvents();
      //             }
      //           }}
      //         >
      //           <ButtonTypo>{t("common.cancel")}</ButtonTypo>
      //         </PxButton>
      //       ) : null}

      //       <PxButton
      //         backgroundcolor="grey"
      //         style={{ borderRadius: 5, padding: 7 }}
      //         onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      //           event.stopPropagation();
      //           confirmAlert(
      //             confirmModalOptions({
      //               title: "해당 이벤트를 삭제하시겠습니까?",
      //               click: async () => {
      //                 const res: any = await dispatch(modifyEventApprovalDelete(rowData.id));
      //                 if (res === "success") {
      //                   dispatch(
      //                     setDialog(t("eventList.chagneOfStatus"), ["해당이벤트가 삭제되었습니다."])
      //                   );
      //                   getEvents();
      //                 }
      //               },
      //             })
      //           );
      //         }}
      //       >
      //         <ButtonTypo>{t("common.delete")}</ButtonTypo>
      //       </PxButton>
      //     </>
      //   ),
      // },`
    ],
    data: [],
  });

  return (
    <>
      <PxTable<RowType>
        title={tableTitle()}
        columns={state.columns}
        data={state.data}
        getAction={getEventsNoneDispatch}
        totalPage={events?.totalPages}
        onRowClick={(evt, selectedRow) => history.push(`/event/detail/${selectedRow?.id}`)}
        options={{
          actionsColumnIndex: -1,
          showTitle: true,
          defaultExpanded: false,
          search: false,
        }}
        // actions={[
        //   (rowData) => ({
        //     icon: () => <MoreHorizIcon />,
        //     onClick: (event, row) =>
        //       console.log("You want to delete " + rowData.evName),
        //     // disabled: rowData.birthYear < 2000
        //     // tooltip: "Delete User",
        //   }),
        // ]}
      />
    </>
  );
};

interface DispatchProps {
  getEvents: (page?: number) => void;
}

interface reduxStateProps {
  events: Event["events"];
}

const mapStateToProps = (state: AppState): reduxStateProps => ({
  events: state.events.events,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>): DispatchProps => ({
  getEvents: bindActionCreators(getEventsNoneDispatch, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
