import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { AppActions, AppState } from "../store";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import {
  ApplicationFormAnswers,
  ApplicationInfo,
  ApplicationMeetingInfo,
  PaginationApplication,
  PaginationApplicationMeeting,
} from "../types/models/Application";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { getRequest, postRequest, setAlert } from "../utils/utils";
import findIndex from "lodash/findIndex";
import { ExcelUserRegister, RegitUser } from "../types/models/Registration";

export const createApplicationAction = (
  application: ApplicationInfo
): AppActions => ({
  type: "CREATE_APPLICATION",
  application,
});

export const getApplicationsAction = (
  applications: PaginationApplication
): AppActions => ({
  type: "GET_APPLICATIONS",
  applications,
});

export const getApplicationMeetingsAction = (
  applicationMeetings: PaginationApplicationMeeting
): AppActions => ({
  type: "GET_APPLICATION_MEETINGS",
  applicationMeetings,
});

export const getApplicationAction = (
  application: ApplicationInfo
): AppActions => ({
  type: "GET_APPLICATION",
  application,
});

export const resetApplicationAction = (): AppActions => ({
  type: "APPLICATION_RESET",
});

export const updateApplicationAnsersAction = (
  application: ApplicationInfo
): AppActions => ({
  type: "UPDATE_APPLICATION_ANSWERS",
  application: application,
});

export const getSelectedSubEvApplicationsAction = (
  applications: PaginationApplication
): AppActions => ({
  type: "GET_SELECTED_EVENT_APPLICATIONS",
  applications: applications,
});

export const createApplication =
  (application: ApplicationInfo) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
    };

    const body = JSON.stringify(application);

    const path = `/api/applications`;
    try {
      var res: AxiosResponse<Event> = await axios.post(path, body, config);

      return "success";
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return "error";
    }
  };

export const getApplications =
  <Application>(
    data: { subEventId: string; type: string },
    page: number = 0,
    pageSize: number = 0,
    searchWord: string = ""
  ) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const size =
      pageSize === 0
        ? (pageSize = getState().tableInfo.pageSize || 10)
        : pageSize;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {},
      params: {
        subEventId: data.subEventId,
        type: data.type,
        page: page,
        size: size,
        searchWord: searchWord,
      }, // get 호출시 필요
    };
    const path = `/api/applications`;
    try {
      var res: AxiosResponse<PaginationApplication> = await axios.get(
        path,
        config
      );
      await dispatch(getApplicationsAction(res.data));
      return res.data;
    } catch (err) {
      if (err.response) {
        const error: Error = err.response.data;
        const alert: Alert = setAlert(err.response.status, error, path);
        dispatch(setAlertAction(alert));
        setTimeout(() => {
          dispatch(removeAlertAction(alert.id));
        });
      }
    }
  };

export const getApplicationsExcelData =
  <Application>(subEventId: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/applications/getExcelData?subEventId=${subEventId}`;
    try {
      var res: AxiosResponse = await axios.get(path, config);
      return res.data;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

export const getApplicationMeetings =
  <Application>(
    data: { subEventId: string; type: string },
    page: number = 0,
    pageSize: number = 10,
    searchWord: string = ""
  ) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/applications/meeting?subEventId=${data.subEventId}&type=${data.type}&page=${page}&size=${pageSize}&searchWord=${searchWord}`;
    try {
      var res: AxiosResponse = await axios.get(path, config);
      dispatch(getApplicationMeetingsAction(res.data));
      return res.data;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

  export const getApplicationMeetingsForExcel = async (
    subEventId : string,
  ):Promise<ApplicationMeetingInfo[] | false>  =>  {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/applications/meetingForExcel?subEventId=${subEventId}`;
    try {
      var res: AxiosResponse<Array<ApplicationMeetingInfo>> = await axios.get(path, config);
  
      return res.data;
    } catch (err) {
      console.log(err)
      return false;
    }
  };
export const getApplication =
  <Application>(applicationId: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/applications/meetingDetail?applicationId=${applicationId}`;
    try {
      var res: AxiosResponse = await axios.get(path, config);
      dispatch(getApplicationAction(res.data));
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

// 참가업체 승인 거절 취소
export const changeApplicationState =
  (changeApplicationStateData: { [key: string]: string }) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
    };

    const body = JSON.stringify(changeApplicationStateData);

    const path = `/api/applications/changeApplicationState`;
    try {
      await axios.put(path, body, config);
      return true;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return false;
    }
    };
  
// 참가업체 list 승인 거절 취소
export const changeApplicationStateList =
  (changeApplicationStateData: { [key: string]: string }) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
    };

    const body = JSON.stringify(changeApplicationStateData);

    const path = `/api/applications/changeApplicationStateList`;
    try {
      await axios.put(path, body, config);
      return true;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return false;
    }
    };

// 미팅신청가능 목록 초기화
export const applicationActionStateToInitial =
  () => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(resetApplicationAction());
  };

export const updateApplicationAnswers = (
  id: string,
  formAnswers: ApplicationFormAnswers[]
) => {
  return postRequest(
    { id, formAnswers },
    "/api/applications/changeApplicationFormAnswers",
    updateApplicationAnsersAction
  );
};

export const getFormAnswers = async (subEventId: string, approval?: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: { subEventId: subEventId, approval: approval },
    data: {},
  };

  try {
    const res = await axios.get("/api/formAnswers/subEventFormAnswers", config);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateApplicationsZoomLink =
  (applicationIds: string[]) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Accept-Language": "ko",
        },
      };

      const body = JSON.stringify(applicationIds);

      const res: AxiosResponse<ApplicationInfo[]> = await axios.post(
        "/api/applications/updateZoomLink",
        body,
        config
      );

      const applications = [...getState().applications.applications?.content!];

      res.data.forEach((application) => {
        const modifiedIndex = findIndex(applications, { id: application.id });

        applications.splice(modifiedIndex, 1, application);
      });

      dispatch(
        getApplicationsAction({
          ...getState().applications.applications!,
          content: applications,
        })
      );
    } catch (err) {
      console.log(err);
    }
    };
  
export const attendeeTypeChange = (applicationId:string,attendeeType:string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    try {

      const updateType = attendeeType == "online" ? "offline" : "online";

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Accept-Language": "ko",
        },
      };

      const reqParam = {
        applicationId:applicationId,
        attendeeType : updateType
      }

      const body = JSON.stringify(reqParam);

      const res: AxiosResponse<ApplicationInfo[]> = await axios.post(
        "/api/applications/updateAttendeeType",
        body,
        config
      );

    
    const applications = [...getState().applications.applications?.content!];

    const changeData = applications.map(application => {
      if (application.id == applicationId) {
        application.attendeeType = updateType;
      } 
      return application;
    })

    dispatch(
      getApplicationsAction({
        ...getState().applications.applications!,
        content: changeData,
      })
    );
      
  } catch (err) {
      console.log(err)
  }
}

// 참가업체 승인 거절 취소
export const changeApplicationNote =
  (applicationNoteData: { id: string; note: string }) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
    };

    const body = JSON.stringify(applicationNoteData);

    const path = `/api/applications/changeApplicationNote`;
    try {
      await axios.put(path, body, config);
      return true;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return false;
    }
  };

export const addRegitApplications = (regitUsers: ExcelUserRegister) => {
  return postRequest(regitUsers, "/api/onSiteRegit/excelRegister");
};

export const getSelectedSubEvApplications =
  <Application>(
    data: { subEventId: string; type: string },
    page: number = 0,
    pageSize: number = 0,
    searchWord: string = ""
  ) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const size =
      pageSize === 0
        ? (pageSize = getState().tableInfo.pageSize || 10)
        : pageSize;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {},
      params: {
        subEventId: data.subEventId,
        type: data.type,
        page: page,
        size: size,
        searchWord: searchWord,
      }, // get 호출시 필요
    };
    const path = `/api/applications`;
    try {
      var res: AxiosResponse<PaginationApplication> = await axios.get(
        path,
        config
      );

      dispatch(getSelectedSubEvApplicationsAction(res.data));

      return res.data;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

export const copySubEventApplicationsToOtherSubEvent =
  <Application>(data: {
    subEventId: string;
    type: string;
    applicationIds: string[];
  }) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {},
    };
    const path = `/api/applications/copyApplications`;

    try {
      const body = {
        subEventId: data.subEventId,
        type: data.type,
        applicationIds: data.applicationIds,
      };

      const res: AxiosResponse<PaginationApplication> = await axios.post(
        path,
        body,
        config
      );

      return res.data;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

export const getApplicationCountBySubEventId = (subEventId: string) => {
  return getRequest(null, "/api/applications/count", undefined, {
    subEventId: subEventId,
  });
};


export const updateApplicationNote = (
  applicationId: string,
  noteContent: string,
  columnName: string
) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {},
  };
  const path = `/api/applications/updateNote`;

    try {
      const body = {
        applicationId: applicationId,
        noteContent: noteContent,
        columnName: columnName,
      };

      const res: AxiosResponse<PaginationApplication> = await axios.post(
        path,
        body,
        config
      );

      return res.data;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }


}
