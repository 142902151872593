import Home from "../views/home/Home";
import Route from "../types/models/Route";
import Auth from "../layouts/Auth";
import Login from "../views/auth/Login";
import Register from "../views/auth/Register";
import FindUser from "../views/auth/FindUser";
import ChangePwd from "../views/auth/ChangePwd";
import TemporaryCode from "../views/auth/TemporaryCode";

import Event from "../layouts/Event";
import EventList from "../components/Event/EventList";
import EventAdd from "../components/Event/EventAdd";
import EventModify from "../components/Event/EventModify";
import EventDetail from "../components/Event/EventDetail";

import SubEvent from "../layouts/SubEvent";
import SubEventAdd from "../components/SubEvent/SubEventAdd";
import SubEventModify from "../components/SubEvent/SubEventModify";
import ApplicationList from "../components/SubEvent/InsideMenu/Application/ApplicationList";
import ApplicationDetail from "../components/SubEvent/InsideMenu/Application/ApplicationDetail";
import MeetingList from "../components/SubEvent/InsideMenu/Meeting/MeetingList";
import MeetingDetail from "../components/SubEvent/InsideMenu/Meeting/MeetingDetail";
import MeetingHistory from "../components/SubEvent/InsideMenu/MeetingHistory/MeetingHistory";
import ProgramList from "../components/SubEvent/InsideMenu/Program/ProgramList";
import ProgramAdd from "../components/SubEvent/InsideMenu/Program/ProgramAdd";
import ProgramDetail from "../components/SubEvent/InsideMenu/Program/ProgramDetail";
import SpeakerList from "../components/SubEvent/InsideMenu/Speaker/SpeakerList";
import SpeakerAdd from "../components/SubEvent/InsideMenu/Speaker/SpeakerAdd";
import SpeakerDetail from "../components/SubEvent/InsideMenu/Speaker/SpeakerDetail";
import NoticeList from "../components/SubEvent/InsideMenu/Notice/NoticeList";
import NoticeAdd from "../components/SubEvent/InsideMenu/Notice/NoticeAdd";
import NoticeDetail from "../components/SubEvent/InsideMenu/Notice/NoticeDetail";
import MailAdd from "../components/SubEvent/InsideMenu/Mail/MailAdd";
import MailDetail from "../components/SubEvent/InsideMenu/Mail/MailDetail";
import MailTab from "../components/SubEvent/InsideMenu/Mail/MailTab";

import AutonoForm from "../layouts/Form";
import MeetingFormSetting from "../views/form/main/MeetingFormSetting";
import ExhibitionFormSetting from "../views/form/main/NoMeetingFormSetting";
import SellerPartiForm from "../views/form/setting/exportMeeting/SellerPartiForm";
import CounselForm from "../views/form/setting/exportMeeting/CounselForm";
import SurveyForm from "../views/form/setting/exportMeeting/SurveyForm";
import BuyerPartiForm from "../views/form/setting/exportMeeting/BuyerPartiForm";
import BuyerCounselForm from "../views/form/setting/exportMeeting/BuyerCounselForm";
import BuyerSurveyForm from "../views/form/setting/exportMeeting/BuyerSurveyForm";
import BoothPartiForm from "../views/form/setting/exhibition/BoothPartiForm";
import BoothSurveyForm from "../views/form/setting/exhibition/BoothSurveyForm";
import VisitorPartiForm from "../views/form/setting/exhibition/VisitorPartiForm";
import VisitorSurveyForm from "../views/form/setting/exhibition/VisitorSurveyForm";
import FormPreview from "../views/form/preview/FormPreview";
import AddtionalForm from "../views/form/additional/AdditionalForm";
import CounseList from "../components/SubEvent/InsideMenu/Counsel/CounseList";
import CounselExcel from "../components/SubEvent/InsideMenu/Counsel/CounselExcelTable";
import InterpreterList from "../components/SubEvent/InsideMenu/Interpreter/InterPreterList";
import InterpreterAdd from "../components/SubEvent/InsideMenu/Interpreter/InterpreterAdd";
import InterpreterModify from "../components/SubEvent/InsideMenu/Interpreter/InterpreterModify";
import Monitoring from "../components/SubEvent/InsideMenu/Monitoring/Monitoring";

import TermsLayout from "../layouts/Terms";
import Terms from "../components/Terms/Terms";
import Privacy from "../components/Terms/Privacy";
import Excel from "../components/SubEvent/InsideMenu/Excel/Excel";
import SmsList from "../components/SubEvent/InsideMenu/Sms/SmsList";
import SmsAdd from "../components/SubEvent/InsideMenu/Sms/SmsAdd";
import AttPartiForm from "../views/form/setting/presentation/AttPartiForm";
import PrePartiForm from "../views/form/setting/presentation/PrePartiForm";
import PreCounselForm from "../views/form/setting/presentation/PreSurveyForm";
import AttCounselForm from "../views/form/setting/presentation/AttSurveyForm";
import WebinarList from "../components/SubEvent/InsideMenu/Webinar/WebinarList";
import WebinarAdd from "../components/SubEvent/InsideMenu/Webinar/WebinarAdd";
import WebinarDetail from "../components/SubEvent/InsideMenu/Webinar/WebinarDetail";
import WebinarModify from "../components/SubEvent/InsideMenu/Webinar/WebinarModify";
import NoticeModify from "../components/SubEvent/InsideMenu/Notice/NoticeModify";
import RegistrationDesk from "../components/SubEvent/InsideMenu/RegistrationDesk/RegistrationDesk";
import ApplicantModify from "../components/SubEvent/InsideMenu/RegistrationDesk/ApplicantModify";
import QRreader from "../components/SubEvent/InsideMenu/RegistrationDesk/QRreader";
import OnSiteRegistration from "../components/SubEvent/InsideMenu/RegistrationDesk/OnSiteRegistration";
import PrinterOnSite from "../components/SubEvent/InsideMenu/RegistrationDesk/PrinterOnSite";
import ConsultingEstimate from "../components/SubEvent/Payment/ConsultingEstimate";
import Estimate from "../layouts/Estimate";
import UpgradeEstimate from "../components/SubEvent/Payment/UpgradeEstimate";
import ConferenceEstimate from "../components/SubEvent/Payment/ConferenceEstimate";
import ConsultingQuoation from "../components/SubEvent/Payment/ConsultingQuoation";
import ConferenceQuoation from "../components/SubEvent/Payment/ConferenceQuoation";
import SmsDetail from "../components/SubEvent/InsideMenu/Sms/SmsDetail";
import RegitApplicationAdd from "../components/SubEvent/InsideMenu/Application/RegisterApplication/RegitApplicationAdd";
import RegitApplicationList from "../components/SubEvent/InsideMenu/Application/RegisterApplication/RegitApplicationList";
import RegitApplicationModify from "../components/SubEvent/InsideMenu/Application/RegitApplicationModify";
import TeamManage from "../components/SubEvent/InsideMenu/TeamManage/TeamManage";
import TeamManageAdd from "../components/SubEvent/InsideMenu/TeamManage/TeamManageAdd";
import ProductList from "../components/SubEvent/InsideMenu/Product/ProductList";
import Geditor from "../components/Editor/GrapesEditor/geditor";
import RegisterUserDetail from "../components/SubEvent/InsideMenu/Application/RegisterApplication/userDetail/UserDetail";
import GroupMailAdd from "../components/SubEvent/InsideMenu/Mail/GroupMailAdd";
import RequestMailDetail from "../components/SubEvent/InsideMenu/Mail/MailRequest/RequestMailDetail";
import RequestDashboard from "../components/SubEvent/InsideMenu/Mail/MailRequest/RequestDashboard";




export const mainRoutes: Route[] = [
  { path: "", name: "HomePage", component: Home, layout: "/" },
  { path: "", name: "AuthPage", component: Auth, layout: "/auth" },
  { path: "", name: "EventPage", component: Event, layout: "/event" },
  { path: "", name: "SubEventPage", component: SubEvent, layout: "/subEvent" },
  {
    path: "",
    name: "AutonomousFormPage",
    component: AutonoForm,
    layout: "/form",
  },
  { path: "", name: "TermsPage", component: TermsLayout, layout: "/terms" },
  { path: "", name: "Estimate", component: Estimate, layout: "/estimate" },
];

export const authRoutes: Route[] = [
  {
    path: "/login",
    name: "LoginPage",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "RegisterPage",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/findUser",
    name: "FindUserPage",
    component: FindUser,
    layout: "/auth",
  },
  {
    path: "/changePwd/:id",
    name: "ChangePwdPage",
    component: ChangePwd,
    layout: "/auth",
  },
  {
    path: "/temporaryCode/:id",
    name: "TemporaryCodePage",
    component: TemporaryCode,
    layout: "/auth",
  },
];

export const eventRoutes: Route[] = [
  {
    path: "/",
    name: "EventListPage",
    component: EventList,
    layout: "/event",
  },
  {
    path: "/add",
    name: "EventAddPage",
    component: EventAdd,
    layout: "/event",
  },
  {
    path: "/modify",
    name: "EventModifyPage",
    component: EventModify,
    layout: "/event",
  },
  {
    path: "/detail/:id/subEventAdd",
    name: "SubEventAddPage",
    component: SubEventAdd,
    layout: "/event",
  },
  {
    path: "/detail/:id/subEventModify/:secondId",
    name: "SubEventModifyPage",
    component: SubEventModify,
    layout: "/event",
  },
  {
    path: "/detail",
    name: "EventDetailPage",
    component: EventDetail,
    layout: "/event",
  },
  {
    path: "/:id/editor",
    name: "EventPageEditor",
    component: Geditor,
    layout: "/event"
  }
];

export const subEventRoutes: Route[] = [
  {
    path: "/:id/application",
    name: "ApplicationListPage",
    component: ApplicationList,
    layout: "/subEvent",
  },
  {
    path: "/:id/application/:secondId",
    name: "ApplicationDetailPage",
    component: ApplicationDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/webinar",
    name: "WebinarListPage",
    component: WebinarList,
    layout: "/subEvent",
  },
  {
    path: "/:id/webinar/add",
    name: "WebinarAddPage",
    component: WebinarAdd,
    layout: "/subEvent",
  },
  {
    path: "/:id/webinar/:secondId",
    name: "WebinarDetailPage",
    component: WebinarDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/webinar/:secondId/modify",
    name: "WebinarModifyPage",
    component: WebinarModify,
    layout: "/subEvent",
  },
  {
    path: "/:id/meeting",
    name: "MeetingListPage",
    component: MeetingList,
    layout: "/subEvent",
  },
  {
    path: "/:id/meeting/:secondId",
    name: "MeetingDetailPage",
    component: MeetingDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/counsel",
    name: "CounselListPage",
    component: CounseList,
    layout: "/subEvent",
  },
  {
    path: "/:id/counsel/counselExcel",
    name: "CounselExcelPage",
    component: CounselExcel,
    layout: "/subEvent",
  },
  {
    path: "/:id/meetingHistory",
    name: "MeetingHistoryPage",
    component: MeetingHistory,
    layout: "/subEvent",
  },
  {
    path: "/:id/program",
    name: "ProgramListPage",
    component: ProgramList,
    layout: "/subEvent",
  },
  {
    path: "/:id/program/add",
    name: "ProgramAddPage",
    component: ProgramAdd,
    layout: "/subEvent",
  },
  {
    path: "/:id/program/:secondId",
    name: "ProgramDetailPage",
    component: ProgramDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/interpreter",
    name: "InterpreterListPage",
    component: InterpreterList,
    layout: "/subEvent",
  },
  {
    path: "/:id/interpreter/add",
    name: "InterpreterAddPage",
    component: InterpreterAdd,
    layout: "/subEvent",
  },
  {
    path: "/:id/interpreter/:secondId",
    name: "InterpreterModifyPage",
    component: InterpreterModify,
    layout: "subEvent",
  },
  {
    path: "/:id/speaker",
    name: "SpeakerListPage",
    component: SpeakerList,
    layout: "/subEvent",
  },
  {
    path: "/:id/speaker/add",
    name: "SpeakerAddPage",
    component: SpeakerAdd,
    layout: "/subEvent",
  },
  {
    path: "/:id/speaker/:secondId",
    name: "SpeakerDetailPage",
    component: SpeakerDetail,
    layout: "subEvent",
  },
  {
    path: "/:id/notice",
    name: "NoticeListPage",
    component: NoticeList,
    layout: "/subEvent",
  },
  {
    path: "/:id/notice/add",
    name: "NoticeAddPage",
    component: NoticeAdd,
    layout: "/subEvent",
  },
  {
    path: "/:id/notice/:secondId/modify",
    name: "NoticeModifyPage",
    component: NoticeModify,
    layout: "/subEvent",
  },
  {
    path: "/:id/notice/:secondId",
    name: "NoticeDetailPage",
    component: NoticeDetail,
    layout: "subEvent",
  },
  {
    path: "/:id/mail",
    name: "MailPage",
    component: MailTab,
    layout: "/subEvent",
  },
  // {
  //   path: "/:id/mail/add",
  //   name: "MailAddPage",
  //   component: MailAdd,
  //   layout: "/subEvent",
  // },
  {
    path: "/:id/mail/add",
    name: "MailAddPage",
    component: GroupMailAdd,
    layout: "/subEvent",
  },
  {
    path: "/:id/mail/:secondId",
    name: "MailDetailPage",
    component: MailDetail,
    layout: "/subEvent",
  },
  {
    // history.push(`/subEvent/${param.id}/mailPreview/${requestId}`);
    path: "/:id/mailPreview/:secondId",
    name: "RequestMailPreview",
    component: RequestMailDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/mailDashBoard/:secondId",
    name: "MailDashboard",
    component: RequestDashboard,
    layout: "/subEvent",
  },
  {
    path: "/:id/mailDashBoard/:secondId/resend",
    name: "MailReSend",
    component: GroupMailAdd,
    layout: "/subEvent"
  },
  {
    path: "/:id/mail/requestCopy/:secondId",
    name: "MailCopyPage",
    component: GroupMailAdd,
    layout: "/subEvent"
  },
  {
    path: "/:id/sms",
    name: "SMSPage",
    component: SmsList,
    layout: "/subEvent",
  },
  {
    path: "/:id/sms/add",
    name: "SmsAddPage",
    component: SmsAdd,
    layout: "/subEvent",
  },
  {
    path: "/:id/sms/:secondId",
    name: "SmsDetailPage",
    component: SmsDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/monitoring",
    name: "MonitoringPage",
    component: Monitoring,
    layout: "/subEvent",
  },
  {
    path: "/:id/excel",
    name: "ExcelPage",
    component: Excel,
    layout: "/subEvent",
  },
  {
    path: "/:id/regitDesk",
    name: "RegistrationDeskPage",
    component: RegistrationDesk,
    layout: "/subEvent",
  },
  {
    path: "/:id/regitDesk/:secondId",
    name: "applicantModify",
    component: ApplicantModify,
    layout: "/subEvent",
  },
  {
    path: "/:id/qrScanner",
    name: "QRScanner",
    component: QRreader,
    layout: "/subEvent",
  },
  {
    path: "/:id/onSiteRegit",
    name: "OnSiteRegit",
    component: OnSiteRegistration,
    layout: "/subEvent",
  },
  {
    path: "/:id/onSiteRegit/printer",
    name: "OnSiteRegit",
    component: PrinterOnSite,
    layout: "/subEvent",
  },
  // {
  //   path: "/:id",
  //   name: "SubEventListPage",
  //   component: ProgramList,
  //   layout: "/subEvent",
  // },
  {
    path: "/:id/regitApplicationAdd",
    name: "RegitApplicationAddPage",
    component: RegitApplicationAdd,
    layout: "/subEvent",
  },
  {
    path: "/:id/regitApplicationList",
    name: "RegitApplicationListPage",
    component: RegitApplicationList,
    layout: "/subEvent",
  },
  // {
  //   path: "/:id/regitApplicationDetail/:secondId",
  //   name: "RegitApplicationModifyPage",
  //   component: RegitApplicationModify,
  //   layout: "/subEvent",
  // },
  {
    path: "/:id/regitApplicationDetail/:secondId",
    name: "RegisterDetailPage",
    component: RegisterUserDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/team",
    name: "TeamManagePage",
    component: TeamManage,
    layout: "/subEvent"
  },
  {
    path: "/:id/team/add",
    name: "TeamManageAddPage",
    component: TeamManageAdd,
    layout: "/subEvent"
  },
  {
    path: "/:id/product",
    name: "CompanyProduct",
    component: ProductList,
    layout: "/subEvent"
  }
];

export const formRoutes: Route[] = [
  {
    path: "/subEvent/:id/match",
    name: "MeetingFormSettingPage",
    component: MeetingFormSetting,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/noMatch", //"/exhibition",
    name: "ExhibitionFormSettingPage",
    component: ExhibitionFormSetting,
    layout: "/form",
  },
  {
    path: "/:from/preview",
    name: "FormPreview",
    component: FormPreview,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/match/addBuyerPartiForm",
    name: "AddtionalBuyerForm",
    component: AddtionalForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/match/addSellerPartiForm",
    name: "AddtionalSellerForm",
    component: AddtionalForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/match/sellerPartiForm",
    name: "SellerPartiForm",
    component: SellerPartiForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/match/counselForm",
    name: "SellerCounselForm",
    component: CounselForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/match/surveyForm",
    name: "SellerSurveyForm",
    component: SurveyForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/match/buyerPartiForm",
    name: "BuyerPartiForm",
    component: BuyerPartiForm,
    layout: "/form",
  },
  // {
  //   path: "/subEvent/:id/match/buyerCounselForm",
  //   name: "BuyerCounselForm",
  //   component: BuyerCounselForm,
  //   layout: "/form",
  // },
  {
    path: "/subEvent/:id/match/buyerSurveyForm",
    name: "BuyerSurveyForm",
    component: BuyerSurveyForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/noMatch/attPartiForm",
    name: "PresentationAttendeeParticipationForm",
    component: AttPartiForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/noMatch/attCounselForm",
    name: "PresentationAttendeeSurveyForm",
    component: AttCounselForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/noMatch/prePartiForm",
    name: "PresentationPresenterParticipationForm",
    component: PrePartiForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/noMatch/preCounselForm",
    name: "PresentationPresenterSurveyForm",
    component: PreCounselForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/noMatch/boothPartiForm",
    name: "BoothParticipationForm",
    component: BoothPartiForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/noMatch/boothSurveyForm",
    name: "BoothSurveyForm",
    component: BoothSurveyForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/noMatch/visitorPartiForm",
    name: "VisitorPartiForm",
    component: VisitorPartiForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/noMatch/visitorSurveyForm",
    name: "VisitorSurveyForm",
    component: VisitorSurveyForm,
    layout: "/form",
  },
];

export const termsRoutes: Route[] = [
  {
    path: "/terms",
    name: "TermsPage",
    component: Terms,
    layout: "/terms",
  },
  {
    path: "/privacy",
    name: "PrivacyPage",
    component: Privacy,
    layout: "/terms",
  },
  
];

export const EstimatesRoutes: Route[] = [
  {
    path: "/subEvent/:subEventId/consulting",
    name: "estimatePage",
    component: ConsultingEstimate,
    layout: "/estimate",
  },
  {
    path: "/subEvent/:subEventId/consultingQuoation",
    name: "estimatePage",
    component: ConsultingQuoation,
    layout: "/estimate",
  },
  {
    path: "/subEvent/:subEventId/upgrade",
    name: "estimatePage",
    component: UpgradeEstimate,
    layout: "/estimate",
  },
  {
    path: "/subEvent/:subEventId/conference",
    name: "estimatePage",
    component: ConferenceEstimate,
    layout: "/estimate",
  },
  {
    path: "/subEvent/:subEventId/conferenceQuoation",
    name: "estimatePage",
    component: ConferenceQuoation,
    layout: "/estimate",
  },
];
