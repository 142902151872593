import React, {useCallback, useEffect, useState} from "react";

import * as xlsx from "xlsx";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {CreateRegitUserRes, ExcelUserData, ExcelUserRegister, InvalidRegitUser, RegitUser, ResultDialogParam} from "../../../../../types/models/Registration";
import {removeLoadingAction, setLoadingAction} from "../../../../../actions/loading";
import {useParams} from "react-router";
import {addRegitApplications} from "../../../../../actions/application";
import {AppState} from "../../../../../store";
import {getForm, getForms} from "../../../../../actions/forms";
import {AutonoForm, FormData} from "../../../../../types/models/AutonoForm";

import Dialog, {DialogProps} from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {DialogContentText} from "@material-ui/core";
import {DialogTitle} from "@material-ui/core";
import SubHeadingTypo from "../../../../Typhography/SubHeadingTypo";
import CaptionTypo from "../../../../Typhography/CaptionTypo";
import PxBackDrop from "../../../../BackDrop/PxBackDrop";
import PxGridContainer from "../../../../Grid/PxGridContainer";
import TitleTypo from "../../../../Typhography/TitleTypo";
import PxGridItem from "../../../../Grid/PxGridItem";
import SpreadSheet from "./../SpreadSheet";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import PxButton from "../../../../Buttons/PxButton";
import ButtonTypo from "../../../../Typhography/ButtonTypo";
import RegitInvalidTable from "./../RegitInvalidTable";
import RegisterExcelForm from "../../Excel/RegisterExcelForm";
import InvalidUserListModal from "./InvalidUserListModal";
import {ApplicationFormAnswers} from "../../../../../types/models/Application";
import CreateResultDialog from "./CreateResultDialog";

export interface RowType {
  name: string;
  phoneNum: string;
  type: string;
  companyName: string;
  position: string;
}

interface FormAnswerInfo {
  type: "single" | "many" | "short" | "long" | "file" | "url" | "keyword" | "category" | "star" | "product";
  selectContent?: Map<string, string>;
  formAnswers: ApplicationFormAnswers;
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 20,
  },
  uploadButton: {
    boxShadow: "none",
    backgroundColor: "#eee",
    padding: "5px 10px",
  },
  regitButton: {
    boxShadow: "none",
    backgroundColor: "#5f4b8b",
    padding: "5px 10px",
    color: "white",
    marginTop: 10,
  },
  input: {
    display: "none",
  },
  spanTitle: {
    color: "blue",
  },
  spanImportant: {
    color: "red",
  },
  resultDialog: {
    minWidth: 150,
  },
  failedUserList: {
    maxHeight: 300,
    overflow: "scroll",
  },
}));

const RegitApplicationAdd = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<{id: string}>();
  //   const users = useSelector((state: AppState) => state.onSiteUsers.onSiteUsers);
  const forms = useSelector((state: AppState) => state.forms);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [rowData, setRowData] = useState<{[key: string]: string}[]>([
    // {
    //   name: "",
    //   phoneNum: "",
    //   type: "",
    //   companyName: "",
    //   position: "",
    //   subEventId: "",
    // },
  ]);
  const [userData, setUserData] = useState<RegitUser[]>([]); // 등록성공한 유저데이터
  const [entireUserData, setEntireUserData] = useState<RegitUser[]>([]); // 등록시도한 전체 유저데이터
  const [resultDialogOpen, setResultDialogOpen] = React.useState(false);
  const [formData, setFormData] = useState<FormData>();
  const [typeList, setTypeList] = useState<{[key: string]: AutonoForm}>();
  const [invalidModal, setInvalidModal] = useState<boolean>(false);
  const [invalidUsers, setInvalidUsers] = useState<Array<InvalidRegitUser>>();
  const [createRegitUserRes, setCreateRegitUserRes] = useState<ResultDialogParam>();

  useEffect(() => {
    if (forms.content == "" && subEvent?.registerForm) {
      dispatch(getForms(subEvent.registerForm));
    } else if (forms.content !== "") {
      const formContent: FormData = JSON.parse(forms.content as string);
      setTypeList(getTypeList(formContent));
      setFormData(formContent);
    }
  }, [forms, subEvent]);

  useEffect(() => {
    if (rowData.length < 1 || typeList == undefined) return;

    const invalidUserList = new Array<InvalidRegitUser>();

    rowData.forEach((userData, idx) => {
      const message = validateUserData(userData, typeList);
      if (message) {
        const invalidUser: InvalidRegitUser = {
          index: idx + 2,
          name: userData["이름"],
          reason: message,
        };
        invalidUserList.push(invalidUser);
        setInvalidUsers(invalidUserList);
      }
    });

    if (invalidUserList.length < 1) return;
    setInvalidModal(true);
  }, [rowData, typeList]);

  const additionalDataProcess = (): Map<string, FormAnswerInfo> => {
    const formInfo = new Map<string, FormAnswerInfo>();
    const formContents = formData?.formData as {[key: string]: AutonoForm};
    formData?.formIds.forEach(formId => {
      switch (formContents[formId].type) {
        case "short":
        case "long":
          const formAnswersInfo: FormAnswerInfo = {
            type: formContents[formId].type!,
            formAnswers: {
              title: formContents[formId].title ?? "",
              type: formContents[formId].type!,
              uuid: formId,
              formId: forms.id!,
              repeatInfo: "N",
            },
          };
          formInfo.set(formContents[formId].title as string, formAnswersInfo);
          break;
        case "single":
          const selectContents = new Map<string, string>();
          formContents[formId].selectContent?.formIds.forEach(selectFormId => {
            selectContents.set(formContents[formId].selectContent?.formData[selectFormId].explain!, selectFormId);
          });
          const selectFormAnswersInfo: FormAnswerInfo = {
            type: formContents[formId].type!,
            selectContent: selectContents,
            formAnswers: {
              title: formContents[formId].title ?? "",
              type: formContents[formId].type!,
              uuid: formId,
              formId: forms.id!,
              repeatInfo: "N",
            },
          };

          formInfo.set(formContents[formId].title as string, selectFormAnswersInfo);
      }
    });
    return formInfo;
  };

  const validateUserData = (userData: any, typeList: any): string | null => {
    if (!typeList[userData["분류"]]) {
      return "등록되지 않은 분류 유형입니다.";
    }
    if (!isValidEmail(userData["이메일"])) {
      return "이메일 형식이 잘못됐습니다.";
    }
    if (userData["이름"] == "") {
      return "이름을 입력해주세요.";
    }
    if (userData["전화번호"] == "") {
      return "연락처를 입력해주세요.";
    }
    if (userData["회사이름"] == "") {
      return "회사명을 입력해주세요.";
    }

    return null;
  };

  // formData에서 분류 타입을 가져와서 분류타입의 명칭과 키값의 위치를 변환
  const getTypeList = (formData: FormData) => {
    const formItem = Object.values(formData.formData).find(item => item.title == "분류");
    const selectContent = formItem?.selectContent;
    return Object.entries(selectContent?.formData as {[key: string]: AutonoForm}).reduce((acc, [key, value]) => {
      acc[value.explain as string] = value;
      return acc;
    }, {} as {[key: string]: AutonoForm});
  };

  // form 데이터의 항목을 추출
  const getFormKeyList = (formData: FormData): string[] => {
    return Object.values(formData.formData)
      .map(value => value.title)
      .filter(key => key !== "분류" && key !== undefined) as string[];
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = e.target.files;

    if (files !== null) {
      const f = files![0];
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = e.target!.result;
        const readedData = xlsx.read(data, {type: "binary"});
        const sheetName = readedData.SheetNames[0];
        const sheetData = readedData.Sheets[sheetName];

        /* Convert array to json*/
        const dataParse = xlsx.utils.sheet_to_json(sheetData);
        setRowData(dataParse as {[key: string]: string}[]);
      };
      reader.readAsBinaryString(f);
      e.target.value = "";
    }
  };

  const onClickRegiterUsers = async () => {
    if (rowData.length < 1) return;
    if (formData == undefined) return;

    const keyList: string[] = getFormKeyList(formData);

    const invalidUserList = new Array<InvalidRegitUser>();

    const registerData: ExcelUserData[] = rowData
      .map((row, idx) => {
        if (!isValidEmail(row["이메일"])) {
          const invalidUser: InvalidRegitUser = {
            index: idx + 2,
            name: row["이름"],
            reason: "이메일 형식이 잘못됐습니다.",
          };
          invalidUserList.push(invalidUser);
        }

        if (row["전화번호"] == undefined || row["전화번호"] == "#ERROR!") {
          const invalidPhoneNumUser: InvalidRegitUser = {
            index: idx + 2,
            name: row["이름"],
            reason: "전화번호를 입력해주세요.",
          };

          invalidUserList.push(invalidPhoneNumUser);
        }

        if (row["분류"] == undefined || row["분류"] == "") {
          const emptyEnterTypeUser: InvalidRegitUser = {
            index: idx + 2,
            name: row["이름"],
            reason: "분류 유형을 입력해주세요.",
          };
          invalidUserList.push(emptyEnterTypeUser);
        }

        if (row["회사이름"] == undefined) {
          const emptyCompanyName: InvalidRegitUser = {
            index: idx + 2,
            name: row["이름"],
            reason: "회사이름을 입력해주세요.",
          };
          invalidUserList.push(emptyCompanyName);
        }

        const contentsInfo: Map<string, FormAnswerInfo> = additionalDataProcess();

        const additionalData: ApplicationFormAnswers[] = keyList.flatMap(key => {
          const formAnswerInfo: FormAnswerInfo | undefined = contentsInfo.get(key);

          if (formAnswerInfo == undefined) {
            const invalidAnswer: InvalidRegitUser = {
              index: 0,
              name: `존재하지 않는 질문 "${key}"`,
              reason: "해당 문항이 없습니다.",
            };
            invalidUserList.push(invalidAnswer);
            return [];
          }
          // 키 값이 없을때 invalid처리

          if (formAnswerInfo.selectContent?.get(row[key]) == undefined && formAnswerInfo.type != "short" && formAnswerInfo.type != "long") {
            const invalidAnswer: InvalidRegitUser = {
              index: 0,
              name: `질문 항목 "${key}" 답변 오류`,
              reason: "해당 질문 항목에 대한 답변 리스트에 없는 값입니다.",
            };
            invalidUserList.push(invalidAnswer);
            return [];
          }

          const formAnswer: ApplicationFormAnswers = formAnswerInfo.formAnswers;
          formAnswer["content"] = row[key];

          if (formAnswerInfo.type == "single" || formAnswerInfo.type == "many") {
            formAnswer["answerUuid"] = formAnswerInfo.selectContent?.get(row[key]);
          }

          return [formAnswer];
        });

        if (invalidUserList.find(invalidUser => invalidUser.index == idx + 2)) {
          return undefined;
        }

        return {
          name: row["이름"],
          phoneNum: row["전화번호"],
          type: row["분류"],
          companyName: row["회사이름"],
          position: row["직책"],
          email: row["이메일"],
          additionalData: additionalData,
        } as ExcelUserData;
      })
      .filter((userData): userData is ExcelUserData => userData !== undefined);

    const requestParam: ExcelUserRegister = {
      subEventId: Number(params.id),
      userData: registerData,
    };

    setEntireUserData(registerData);

    if (invalidUserList.length > 0) {
      setInvalidUsers(invalidUserList);
    }

    dispatch(setLoadingAction());
    const totalUsers: any = await dispatch(addRegitApplications(requestParam));

    // console.log(`totalUsers : `, totalUsers);
    const dialogUserData = makeDialogData(rowData, invalidUserList, totalUsers);

    setCreateRegitUserRes(dialogUserData);
    dispatch(removeLoadingAction());
    setResultDialogOpen(true);

    // if (invalidUserList.length > 0) {
    //   setInvalidUsers(invalidUserList);
    //   setInvalidModal(true);
    // } else {
    //   dispatch(setLoadingAction());
    //   const totalUsers: any = await dispatch(addRegitApplications(requestParam));
    //   setUserData(totalUsers);
    //   dispatch(removeLoadingAction());
    //   setResultDialogOpen(true);
    // }
  };

  const resultDialogClose = () => {
    setResultDialogOpen(false);
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const setDialogContent = useCallback(() => {
    // entireUserData: 전체유저
    // users: 성공한 유저
    // 실패한 데이터
    const failedData = entireUserData
      .map(user => {
        if (userData?.find(({phoneNum}) => user.phoneNum == phoneNum) === undefined) {
          return user;
        }
        return undefined;
      })
      .filter(data => data !== undefined);

    return (
      <div className={classes.resultDialog}>
        <SubHeadingTypo>성공: {userData.length}</SubHeadingTypo>
        <SubHeadingTypo>실패: {failedData.length}</SubHeadingTypo>
        <div className={classes.failedUserList}>
          {failedData.map(failedUser => (
            <CaptionTypo>{failedUser?.name}</CaptionTypo>
          ))}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entireUserData, userData]);

  const closeInvalidModal = () => {
    setInvalidUsers([]);
    setInvalidModal(false);
  };

  const makeDialogData = (rowData: {[key: string]: string}[], frontInvalidUsers: InvalidRegitUser[], createRegitUserRes: CreateRegitUserRes) => {
    const serverIndexInvalidUsers = createRegitUserRes.registerFailUsers.map(invalidUser => {
      const index = rowData.findIndex(row => row["이름"] === invalidUser.name);

      const indexInvalid: InvalidRegitUser = {
        index: index,
        name: invalidUser.name,
        reason: invalidUser.cause,
      };

      return indexInvalid;
    });

    return {
      failUsers: [...frontInvalidUsers, ...serverIndexInvalidUsers],
      successUsers: [...createRegitUserRes.registerSuccessUsers],
    } as ResultDialogParam;
  };

  return (
    <Container className={classes.container}>
      <PxBackDrop />
      {/* 등록 결과 dialog */}
      {/* <Dialog title="등록결과" open={resultDialogOpen} onClose={() => {}}>
        <DialogContent>{setDialogContent()}</DialogContent>
        <DialogActions>
          <Button onClick={resultDialogClose} color="primary" autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog> */}

      {resultDialogOpen && createRegitUserRes && <CreateResultDialog isOpen={resultDialogOpen} resultData={createRegitUserRes} closeModal={resultDialogClose} />}
      {/* <CreateResultDialog isOpen={true} /> */}
      {/* excel 데이터에 유효성 검사에서 실패 */}
      {invalidUsers && <InvalidUserListModal openStatus={invalidModal} closeModal={closeInvalidModal} invalidUsers={invalidUsers} />}
      <PxGridContainer justify="space-between">
        <TitleTypo>참가자 등록</TitleTypo>
        <div>
          {formData && <RegisterExcelForm formData={formData} />}
          <Button variant="contained" component="label" className={classes.uploadButton} style={{marginLeft: "5px"}}>
            엑셀파일 불러오기
            <input type="file" name="file1" onChange={handleUpload} className={classes.input} />
          </Button>
        </div>
      </PxGridContainer>
      <PxGridContainer direction="row" justify="space-between">
        <PxGridItem>
          <CaptionTypo>
            <span className={classes.spanTitle}>파일형식:</span> <span className={classes.spanImportant}>xlsx(엑셀)</span> 로 해야함. - 다른 형식으로 불러올 경우 한글이 깨짐
          </CaptionTypo>
          <CaptionTypo>
            <span className={classes.spanTitle}>엑셀파일 구조:</span> <span className={classes.spanImportant}>첫행</span>을 아래 보이는 1행의 가로안에 있는 영문(name, phoneNum, eventName ...)으로
            입력하고 그에 맞게 값을 채워줘야함.
          </CaptionTypo>
        </PxGridItem>
        <PxGridItem>
          <Button className={classes.regitButton} onClick={onClickRegiterUsers}>
            등록하기
          </Button>
        </PxGridItem>
      </PxGridContainer>
      <SpreadSheet rowData={rowData} setRowData={setRowData} formData={formData} />
    </Container>
  );
};

export default RegitApplicationAdd;
