import React, {useEffect, useState} from "react";
import {IconButton, ListItemSecondaryAction, List, ListItem, ListItemText} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import ListSubheader from "@material-ui/core/ListSubheader";
import {TemplateListType} from "./geditor";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {deleteTemplate, getDefaultTemplateList, getTemplateContent, getUserTemplateList, newTemplateAction} from "../../../actions/template";
import {TemplateItem} from "../../../types/models/Template";
import {makeStyles} from "@material-ui/core/styles";

type TemplateListProps = {
  type: TemplateListType;
};

const TemplateList: React.FC<TemplateListProps> = ({type}) => {
  const {userTemplateList, defaultTemplateList, templateContent} = useSelector((state: AppState) => state.template);
  const event = useSelector((state: AppState) => state.events.event);
  const [templateList, setTemplateList] = useState<TemplateItem[]>();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (event?.id == null) return;
    switch (type) {
      case TemplateListType.USER:
        dispatch(getUserTemplateList(event.id));
        break;
      case TemplateListType.DEFAULT:
        dispatch(getDefaultTemplateList());
        break;
    }
  }, [event]);

  useEffect(() => {
    if (userTemplateList != null && type === TemplateListType.USER) {
      setTemplateList(userTemplateList);
    }
  }, [userTemplateList]);

  useEffect(() => {
    if (defaultTemplateList != null && type === TemplateListType.DEFAULT) {
      setTemplateList(defaultTemplateList);
    }
  }, [defaultTemplateList]);

  /**
   *        템플릿 컨텐츠 가져오기 이벤트
   *
   * @param templateId
   */
  const getTemplateContentData = (templateId: number) => {
    dispatch(getTemplateContent(templateId));
  };

  /**
   *        템플릿 삭제 이벤트
   *
   * @param templateId
   * @returns
   */

  const deleteEmplateEvent = async (templateId: number) => {
    if (!event?.id) return;
    await dispatch(deleteTemplate(templateId));
    dispatch(getUserTemplateList(event?.id));
  };

  const newTemplateEvent = () => {
    dispatch(newTemplateAction());
  };

  return (
    <List
      style={{flex: 1, height: "50vh", border: "1px solid"}}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader" className={classes.listHeader}>
          {type === TemplateListType.USER ? "나의 템플릿" : "템플릿"}
        </ListSubheader>
      }
    >
      {type === TemplateListType.USER && (
        <ListItem style={{display: "flex", justifyContent: "center"}}>
          {/* <PxButton backgroundcolor="purple" onClick={() => newTemplateEvent()}>
            <ButtonTypo>new</ButtonTypo>
          </PxButton> */}
          <IconButton onClick={() => newTemplateEvent()}>
            <AddIcon />
            new
          </IconButton>
        </ListItem>
      )}
      {templateContent &&
        templateList &&
        templateList?.length > 0 &&
        templateList.map((template, idx) => {
          return (
            <ListItem button className={template.id == templateContent.templateId ? classes.active : ""} key={idx} onClick={() => getTemplateContentData(template.id)}>
              {type == TemplateListType.USER ? (
                <ListItemSecondaryAction>
                  {/* <IconButton edge="start">
                    <CopyIcon />
                  </IconButton> */}
                  <IconButton edge="end" onClick={() => deleteEmplateEvent(template.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              ) : (
                ""
              )}
              <ListItemText>{template.templateName}</ListItemText>
            </ListItem>
          );
        })}
    </List>
  );
};

export default TemplateList;

const useStyles = makeStyles({
  active: {
    backgroundColor: "#076ece",
    color: "white",
    "&:hover": {
      backgroundColor: "#076ece",
    },
  },
  listHeader: {
    backgroundColor: "#5f4b8b",
    color: "white",
  },
});
