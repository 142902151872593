import React from "react";
import { useHistory } from "react-router-dom";
import { Column } from "material-table";

// material icons
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

// custom Material-table comp
import PxTable from "../../components/Table/PxTable";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typhography/ButtonTypo";

interface RowType {
  evName: string;
  evState: string;
  evDate: string;
  evUpdatedDate: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

export default function HomeTable() {
  const history = useHistory();
  const [state] = React.useState<TableStateType>({
    columns: [
      {
        title: "이벤트",
        field: "evName",
        cellStyle: {
          // width: "700px",
          maxWidth: "700px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "",
        field: "evSetting",
        cellStyle: {
          width: "calc((100% - 800px) / 2)",
        },
        render: (rowData) => (
          <PxButton
            backgroundcolor="grey"
            style={{ borderRadius: 20, padding: 5 }}
          >
            <ButtonTypo>설정</ButtonTypo>
          </PxButton>
        ),
      },
      {
        title: "",
        field: "evFormSetting",
        cellStyle: {
          width: "calc((100% - 800px) / 2)",
        },
        render: (rowData) => (
          <PxButton
            backgroundcolor="purple"
            style={{ padding: 5 }}
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.stopPropagation();
              // history.push("/form/meeting");
              history.push({
                pathname: "/form/subEvent/36/match",
              });
            }}
          >
            <ButtonTypo>양식 만들기</ButtonTypo>
          </PxButton>
        ),
      },
      {
        title: "등록",
        field: "evState",
        cellStyle: {
          width: "100px",
        },
      },
      // {
      //   title: "기간",
      //   field: "evDate",
      //   cellStyle: {
      //     width: "calc((100% - 600px) / 2)",
      //   },
      // },
      {
        title: "최종수정일",
        field: "evUpdatedDate",
        cellStyle: {
          width: "calc((100% - 800px) / 2)",
        },
      },
    ],
    data: [
      {
        evName:
          "Free Beauty Samples What They Are And How To Find Them  They Are And How To Find Them",
        evState: "작성중",
        evDate: "05.20 ~ 05.23 2020",
        evUpdatedDate: "05.22 2020",
      },
      {
        evName:
          "Free Beauty Samples What They Are And How To Find Them  They Are And How To Find Them",
        evState: "작성중",
        evDate: "05.20 ~ 05.23 2020",
        evUpdatedDate: "05.22 2020",
      },
      {
        evName:
          "Free Beauty Samples What They Are And How To Find Them  They Are And How To Find Them",
        evState: "작성중",
        evDate: "05.20 ~ 05.23 2020",
        evUpdatedDate: "05.22 2020",
      },
      {
        evName:
          "Free Beauty Samples What They Are And How To Find Them  They Are And How To Find Them",
        evState: "작성중",
        evDate: "05.20 ~ 05.23 2020",
        evUpdatedDate: "05.22 2020",
      },
    ],
  });

  return (
    <PxTable<RowType>
      columns={state.columns}
      data={state.data}
      onRowClick={(evt, selectedRow) => console.log(selectedRow)}
      options={{
        actionsColumnIndex: -1,
        pageSize: 10,
        showTitle: false,
        defaultExpanded: false,
        search: false,
        toolbar: false,
      }}
      actions={[
        (rowData) => ({
          icon: () => <MoreHorizIcon />,
          onClick: (event, row) =>
            console.log("You want to delete " + rowData.evName),
          // disabled: rowData.birthYear < 2000
          // tooltip: "Delete User",
        }),
      ]}
    />
  );
}
