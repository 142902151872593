import TeamManageActionType from "../types/actions/TeamManageAction";
import TeamManage,{TeamMember} from "../types/models/TeamManage";


const teamManageDefaultState: TeamManage = {
    teamMemberList: []
}

const teamManageReducer = (
    state = teamManageDefaultState,
    action: TeamManageActionType
) => {

    switch (action.type) {
        case "GET_MEMBERS":
            
            return {...state, teamMemberList: action.teamMemberList};
        default:
            return {...state};
    }

}

export default teamManageReducer;