import RegistrationActionTypes from "../types/actions/Registration";
import { RegisterFormAnswer, RegisterMethod, RegisterUserDetail, RegisterUserPage, RegitUser } from "../types/models/Registration";
import { postRequest } from "../utils/utils";


interface RegistrationState {
    userDetail: RegisterUserDetail;
    userPage: RegisterUserPage;
}

const registrationState: RegistrationState = {
    userDetail: {
        id: 0,
        name: "",
        phoneNum: "",
        type: "",
        companyName: "",
        position: "",
        subEventId: "",
        email: "",
        method: RegisterMethod.DATA_IMPORT_REGISTRATION,
        createdDate: "",
        registerFormAnswer:[]
    },
    userPage: {
        content: [],
        totalElements: 0,
        totalPages: 0,
        size: 0,
        number: 0,
        sort: {
          sorted: false,
          unsorted: false,
          empty: false,
        },
        numberOfElements: 0,
        first: false,
        empty: false,
    }
} 


const registrationReducer = (
    state = registrationState,
    action: RegistrationActionTypes 
): RegistrationState => {
    switch (action.type) {
        case "GET_REGISTER_USER":
            return { ...state, userDetail: action.registerUser };
        case "GET_REGISTER_USER_PAGE":
            return { ...state, userPage: action.registerUserPage };
        case "REMOVE_REGISTER_USER":
            return { ...state, userDetail: registrationState.userDetail };
        case "REMOVE_REGISTER_USER_PAGE":
            return { ...state, userPage: registrationState.userPage };
        case "UPDATE_REGISTER_FORM_ANSWERS":
            return { ...state, userDetail: { ...state.userDetail, registerFormAnswer: action.formAnswers } }
        case "TOGGLE_ATTENDANCE_STATE":
            const copyContent = [...state.userPage.content];
            const targetItem = copyContent.find(item => item.id === action.userInfo.id);
        
            if (targetItem) {
                targetItem!.attendanceState = action.userInfo.attendanceState
            };
        
            return { ...state, userPage: { ...state.userPage, content: [...copyContent]}}
        default:
            return {...state};
    }
}

export default registrationReducer;
