import PrinterSubEventActionTypes from "../types/actions/PrinterAction";
import { getRequest, setAlert } from "../utils/utils";
import { OnSiteUser, Printer } from "../types/models/Printer";
import { Dispatch } from "redux";
import { AppActions } from "../store";
import axios from "axios";
import Alert from "../types/models/Alert";
import { removeAlertAction, setAlertAction } from "./alerts";

export const getPrintersction = (
  printers: Printer[]
): PrinterSubEventActionTypes => ({
  type: "GET_PRINTERS",
  printers: printers,
});

export const getPrinterAction = (
  printer: Printer
): PrinterSubEventActionTypes => ({
  type: "GET_PRINTER",
  printer: printer,
});

export const resetPrinterAction = (): PrinterSubEventActionTypes => ({
  type: "RESET_PRINTER",
});

export const setPrinterLoadingAction = (
  loading: boolean,
  alert?: Alert
): PrinterSubEventActionTypes => ({
  type: "SET_PRINTER_LOADING",
  alert: alert,
  loading: loading,
});

export const setOnSiteRegitAction = (
  regit: "seller" | "buyer" | "none"
): PrinterSubEventActionTypes => ({
  type: "SET_ONSITE_REGIT",
  onSiteRegit: regit,
});

export const getPrinters = () => {
  return getRequest(null, "/api/printer", getPrintersction);
};

export const getPrinter = (id: string) => {
  return getRequest(null, `/api/printer/${id}`, getPrinterAction);
};

export const createOnSiteRegitUser = (userInfo: OnSiteUser) => async (
  dispatch: Dispatch<AppActions>
) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: null,
    };

    const body = JSON.stringify(userInfo);

    const res = await axios.post("/api/onSiteRegit", body, config);

    return res.data;
  } catch (err) {
    const error: any = err.response.data;
    const alert: Alert = setAlert(
      err.response.status,
      error,
      "onSiteRegitError"
    );
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};
