import {makeStyles, MuiThemeProvider} from "@material-ui/core";
import MaterialTable, {Column, MTableToolbar} from "material-table";
import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {StyledDiv, tableIcons, customTheme} from "../../../../Table/PxTable";
import {useHistory, useParams} from "react-router-dom";

interface ColumnType {
  mailId: string;
  title: string;
  updateDate: string;
  recepientsNumber: number;
  sendSuccessNumber: number;
  mailOpenNumber: number;
  mailItemClickNumber: number;
}

interface TableStateType {
  columns: Array<Column<ColumnType>>;
  data: Array<ColumnType>;
}

const useStyles = makeStyles(() => ({
  tableToolbarRoot: {
    justifyContent: "center",
    "& fieldset": {
      borderRadius: 28,
      borderColor: "#e0e0e0",
    },
  },
  tableToolbarSpacer: {
    flex: "none",
  },
  tableSearchField: {
    minWidth: 420,
  },
  container: {
    marginTop: 20,
  },
  buttonRoot: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
  },
  formControl: {
    minWidth: 150,
  },
}));

const InvitationDetail: React.FC = () => {
  const params = useParams<{id: string; secondId: string}>();
  const history = useHistory();
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState<number>();
  const [page, setPage] = useState<number>();
  const [searchWord, setSearchWord] = useState<string>("");
  const [state, setState] = useState<TableStateType>({
    columns: [
      {
        title: "메일ID",
        field: "mailId",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
          maxWidth: "500px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "제목",
        field: "title",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
          maxWidth: "500px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "업데이트",
        field: "updateDate",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
          maxWidth: "500px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "수신자",
        field: "recipientsNumber",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
          maxWidth: "500px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "전송",
        field: "sendSuccessNumber",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
          maxWidth: "500px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "열기",
        field: "mailItemClickNumber",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
          maxWidth: "500px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    ],
    data: [],
  });

  useEffect(() => {
    // 서버로부터 api 요청
  }, [searchWord, page, rowsPerPage]);

  const onChangeSearchWord = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchWord(searchValue);
  };

  return (
    <MuiThemeProvider theme={customTheme}>
      <StyledDiv footer={true}>
        <TableHeader>
          {/* <RegisterMethodSelector selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} />
                <RegisterTypeSelector typeList={typeList} selectedType={selectedType} setSelectedType={setSelectedType} />
                <RegisterSearchBar onSearch={setSearch} /> */}
          <SearchInput value={searchWord} onChange={onChangeSearchWord} />
        </TableHeader>
        <MaterialTable
          columns={state.columns}
          icons={tableIcons}
          data={state.data}
          options={{
            showTitle: false,
            actionsColumnIndex: -1,
            pageSize: rowsPerPage,
            search: false,
            searchFieldVariant: "outlined",
            paginationType: "normal",
            pageSizeOptions: [1, 10, 20, 30, 40, 50, 80, 100],
            toolbar: false,
            paging: true,
          }}
          page={page}
          totalCount={0}
          onChangePage={page => setPage(page)}
          onChangeRowsPerPage={rows => {
            setRowsPerPage(rows);
            setPage(0);
          }}
          localization={{
            toolbar: {searchPlaceholder: "이름, 소속, 휴대번호 조회"},
          }}
          onRowClick={(event, rowData) => {
            // history.push(`/subEvent/${params.id}/regitApplicationDetail/${rowData?.id}`);
          }}
          components={{
            Toolbar: props => (
              <MTableToolbar
                {...props}
                classes={{
                  root: classes.tableToolbarRoot,
                  spacer: classes.tableToolbarSpacer,
                  searchField: classes.tableSearchField,
                }}
              />
            ),
          }}
        />
      </StyledDiv>
    </MuiThemeProvider>
  );
};

export default InvitationDetail;

const TableHeader = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const SearchInput = styled.input`
  width: 100%;
  font-size: 14px;
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
  }
`;
