import React from "react";
import TitleTypo from "../../../Typhography/TitleTypo";
import {makeStyles} from "@material-ui/core/styles";
import CompanyExcel from "./CompanyExcel";
import MeetingExcel from "./MeetingExcel";
import MeetingSurveyExcel from "./MeetingSurveyExcel";
import {useTranslation} from "react-i18next";
import SurveyExcel from "./SurveyExcel";
import {useSelector} from "react-redux";
import {AppState} from "../../../../store";
import MeetingResultExcel from "./MeetingResultExcel";
import WebinarQAExcel from "./WebinarQAExcel";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
  },
  companyDiv: {
    paddingTop: 30,
  },
  radioForm: {
    padding: "0px 5px",
  },
  meetingDiv: {
    paddingTop: 40,
  },
}));

const Excel = () => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const classes = useStyles();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  return (
    <div className={classes.root}>
      <TitleTypo>{t("excel.data")}</TitleTypo>
      <CompanyExcel classes={classes} />
      {subEvent?.matching === "Y" && (
        <>
          <MeetingExcel classes={classes} />
          <MeetingResultExcel classes={classes} />
          <MeetingSurveyExcel classes={classes} />
        </>
      )}
      <SurveyExcel classes={classes} />
      {subEvent?.category === "presentation" && <WebinarQAExcel classes={classes} />}
    </div>
  );
};

export default Excel;
