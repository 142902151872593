import { MailStatus } from "../../components/SubEvent/InsideMenu/Mail/MailRequest/RequestDashboard";

export default interface Mail {
  mail?: MailInfo;
  mails?: PaginationMail;
  systemMails?: PaginationMail;
  mailTargets?: MailTargetInfo[];
  sendGridMails?: {
    content: SendGridMailInfo[]
    page: number
  };
  setting: MailSettingType;
  mailResend?: MailReSendType;
  mailRequestInfo?: RequestInfo;
}

export type MailReSendType = {
  targetUsers: Array<MailReSendUsersInfo>;
}

export type MailReSendUsersInfo = {
  id: number;
  name: string;
  email: string;
}

export interface MailSettingType {
  id: number;
  mode: string;
  senderName: string;
  mailRegistState: MailRegistType;
  senderMailAddress: string;
  systemMailConfig?: SystemMailConfig;
}

export type MailRegistType = "REGISTERED" | "PENDING" | "UNREGISTERED";


export interface RegistRuternType {
  senderMailAddress: string;
  mailRegistState: MailRegistType;
}

export interface SystemMailConfig {
  id: number;
  logoImage: string;
  eventName: string;
  operator: string;
  replyMailAddress: string;
  copyrightState: string;
}

export interface PaginationMail {
  content:MailInfo[];
  pageable?: any,
  last?: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean
  },
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export interface MailInfo {
  id?: string;
  code?: string;
  title: string;
  content: string;
  createdDate?: string;
  createdUserId?: string;
  subEventId?: string;
  senderName?:string;
  toMail?: string;
  recipient?: MailRecipient[];
  status?:string;
  msgId?:string
  isSystemMail? : "Y"| "N";
  opened?:string;
}

export interface MailRecipient {
  email: string,
  company: string,
  name: string,
  recipientId: string
}
export interface MailTargetInfo {
  category?: string;
  keyword?: string;
  approval?: string;
  method?: string;
  name?: string;
  type?: string;
  company?: string;
  email?: string;
  phoneNumber?: string;
  usersId?: string;
}

export interface SendGridMailInfo {
  from_email: string,
  msg_id: string,
  subject: string,
  to_email: string,
  status: string,
  opens_count: number,
  clicks_count: number,
  last_event_time:number
}

export enum MailContentType {
  COMMON = "COMMON",
  TEMPLATE = "TEMPLATE",
}


export interface GroupMailSendReq {
  subEventId: number,
  title: string,
  contentType : MailContentType,
  content: string,
  userList: Array<number>
}

export type RequestInfo = {
  id:number,
  title:string,
  mailContentType: MailContentType,
  content:string,
  totalCount:number,
  deliveries:number,
  opens:number,
  clicks:number,
  fails:number,
  bounces:number,
  complaints:number,
  rejects:number
}

