import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { approvalTemporaryCode } from "../../actions/temporaryCode";

const TemporaryCode = () => {
  const dispatch = useDispatch();
  const param: { id: string } = useParams();
  const [resultData, setResultData]: any = useState({
    result: "",
    message: "",
  });
  const { result, message } = resultData;
  useEffect(() => {
    approval();
  }, []);

  const approval = async () => {
    const approvalResult: any = await dispatch(approvalTemporaryCode(param.id));

    setResultData(approvalResult);
  };

  return result === "error" ? (
    <>
      {message}
      <br />
      요청페이지에서 확인해주세요
    </>
  ) : result === "success" ? (
    <>
      success
      <br />
      인증되었습니다. 요청페이지에서 확인해주세요
    </>
  ) : (
    <>인증중입니다.</>
  );
};

export default TemporaryCode;
