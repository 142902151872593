import { AppActions } from "../store";
import { Dispatch } from "react";

// types
import { Form, FormResponse, historyList } from "../types/models/Form";

// utils
import { postRequest, getRequest } from "../utils/utils";

export const getFormsAction = (forms: Form): AppActions => ({
  type: "GET_FORMS",
  forms,
});

export const setFormsAction = (forms: Form): AppActions => ({
  type: "SET_FORMS",
  forms,
});

export const removeFormAction = (): AppActions => ({
  type: "REMOVE_FORMS",
});

export const setAddFormsAction = (forms: Form): AppActions => ({
  type: "SET_ADD_FORMS",
  forms,
});

export const getFormAction = (forms: Form): AppActions => ({
  type: "GET_FORM",
  forms,
});

export const updateFormAction = (form: Form): AppActions => ({
  type: "UPDATE_FORM",
  form:form
})

export const getHistoryFormAction = (list: historyList[]): AppActions => ({
  type: "GET_HISTORY_FORM",
  history: list
});

export const getBuyerFormAction = (list: historyList[]): AppActions => ({
  type: "GET_BUYER_HISTORY_FORM",
  history: list
})

export const updateTemplate = (template:"default"|"export" | "ir" | "job" | "tour") => ({
  type: "UPDATE_TEMPLATE",
  templateType: template
})

export const getForms = (formId: number) => {
  return getRequest(null, "/api/forms/detail", getFormsAction, { id: formId });
};

export const setForms = (forms: Form) => (dispatch: Dispatch<AppActions>) => {
  dispatch(setFormsAction(forms));
};

export const getHistoryForm = (userId: number) => {
  return getRequest(null, "/api/subEvents/subeventSellerForm", getHistoryFormAction, { userId: userId});
}

export const getHistoryBuyerForm = (userId: number) => {
  return getRequest(null, "/api/subEvents/subeventBuyerForm", getHistoryFormAction, { userId: userId });
}

export const updateForm = (formId: number) => {
  return getRequest(null, "/api/forms/historyForm", updateFormAction, { id: formId });
}

export const setAdditionalForms = (forms: Form) => (
  dispatch: Dispatch<AppActions>
) => {
  dispatch(setAddFormsAction(forms));
};

export const getForm = <Form>(formId: string) => {
  return getRequest(null, "/api/forms/detail", getFormAction, {
    id: formId,
  });
};

export const createForm = (form: Form) => {
  return postRequest<Form>(form, "/api/forms/");
};
