import React, { useState, useEffect } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { AppActions, AppState } from "../../store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";

// actions
import { getAuthToken, LoginInfo, getUser } from "../../actions/users";

// bs comp
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// material ui comp
import Container from "@material-ui/core/Container";

// custom ui comp
import OutlinedTxtField from "../../components/Inputs/PxOutlinedTxtField";
import ButtonTypo from "../../components/Typhography/ButtonTypo";
import PxButton from "../../components/Buttons/PxButton";
import User from "../../types/models/User";
import { useTranslation } from "react-i18next";

interface LoginForm {
  email: string;
  password: string;
}

type Props = reduxStateProps & DispatchProps;

const Login: React.FC<Props> = (props) => {
  const location = useLocation();
  const { users, getUser } = props;
  const [t] = useTranslation("lang", { useSuspense: false });
  const [isCapsLock, setIsCapsLock] = useState(false);
  const [loginForm, setLoginForm] = useState<LoginForm>({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (users.isAuthenticated === true) {
      getUser(localStorage.userId);
    }
  }, [users, getUser, location]);

  // user 로그인 성공 시 Redirect
  if (users.userId) {
    return <Redirect to="/" />;
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const s = String.fromCharCode(event.keyCode);
    if (
      s.toUpperCase() === s &&
      s.toLowerCase() !== s &&
      !event.shiftKey &&
      event.key.toLowerCase() !== event.key
    ) {
      setIsCapsLock(true);
    } else {
      setIsCapsLock(false);
    }
  };

  const handleOnChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>
  ) => {
    setLoginForm({
      ...loginForm,
      [event.target.name as string]: event.target.value as string,
    });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.getAuthToken(loginForm);
  };

  return (
    <Container maxWidth="xs">
      <form onSubmit={onSubmit}>
        <OutlinedTxtField
          label={t("login.email")}
          name="email"
          fullWidth
          padding="0px 0px 20px 0px"
          onChange={handleOnChange}
        />
        <OutlinedTxtField
          label={t("login.password")}
          type="password"
          name="password"
          fullWidth
          padding="0px 0px 20px 0px"
          helperText={isCapsLock ? "CapsLock 키가 눌려있습니다." : ""}
          onKeyDown={handleOnKeyDown}
          onChange={handleOnChange}
        />
        <PxButton className="mt-4 mb-2 p-2" fullWidth backgroundcolor="purple" type="submit">
          <ButtonTypo className="font-weight-bold">{t("login.login")}</ButtonTypo>
        </PxButton>
        <span className="text-muted">
          pexpo.io에서 만든 아이디는 이 페이지에서 사용할 수 없습니다.
        </span>
      </form>
      {/* <Link to="/">
        <span className="text-muted">{t("login.needHelp?")}</span>
      </Link> */}
      <p />
      <Link to="/auth/findUser">
        <span className="text-muted">{t("login.findIdAndPW")}</span>
      </Link>
      <Row className="mt-3">
        <Col>
          <span>{t("login.pexpoUser?")} </span>
          <Link to="/auth/register">
            <span className="text-muted"> {t("login.register")}</span>
          </Link>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Manual>{t("footer.onlineManual")}</Manual>
        </Col>
      </Row>
    </Container>
  );
};

interface reduxStateProps {
  users: User;
}

const mapStateToProps = (state: AppState) => ({
  users: state.users,
});

interface DispatchProps {
  getAuthToken: ({ email, password }: LoginInfo) => void;
  getUser: (userId: string) => void;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>): DispatchProps => ({
  getAuthToken: bindActionCreators(getAuthToken, dispatch),
  getUser: bindActionCreators(getUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

const Manual = styled.div`
  padding: 9px 36px;
  color: rgb(81, 45, 168);
  border: 1px solid rgb(81, 45, 168);
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;
