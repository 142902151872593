import TemplateActionType from "../types/actions/TemplateAction";
import Template from "../types/models/Template";


const templateDefaultState: Template = {
    userTemplateList: [],
    defaultTemplateList: [],
    templateContent: { templateId: 0, templateName: ``, htmlContent: '', cssContent: '' },
    templateImage: []
}

const templateReducer = (
    state = templateDefaultState,
    action: TemplateActionType
): Template => {
    switch (action.type) {
        case "GET_DEFAULT_TEMPLATE_LIST":
            return {...state, defaultTemplateList: action.defaultTemplateList};
        case "GET_TEMPLATE_CONTENT":
        case "SAVE_TEMPLATE":
        case "NEW_TEMPLATE":
            return { ...state, templateContent: action.templateContent };
        case "GET_USER_TEMPLATE_LIST":
            return { ...state, userTemplateList: action.userTemplateList };
        default:
            return state;
    }
}

export default templateReducer;