import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import React, {Dispatch, SetStateAction} from "react";
import {InvalidRegitUser} from "../../../../../types/models/Registration";
import PxButton from "../../../../Buttons/PxButton";
import ButtonTypo from "../../../../Typhography/ButtonTypo";
import RegitInvalidTable from "../RegitInvalidTable";

interface Props {
  openStatus: boolean;
  closeModal: () => void;
  invalidUsers: Array<InvalidRegitUser>;
}

const InvalidUserListModal: React.FC<Props> = ({openStatus, closeModal, invalidUsers}) => {
  return (
    <Dialog open={openStatus} onClose={closeModal} scroll="paper" maxWidth="lg" aria-labelledby="scroll-dialog-title" aria-describedby="scrioll-dialog-description">
      <DialogTitle>유효하지 않은 유저 목록</DialogTitle>
      <DialogContent dividers={true}>{invalidUsers && <RegitInvalidTable invalidUsers={invalidUsers} />}</DialogContent>
      <DialogActions>
        <PxButton onClick={closeModal}>
          <ButtonTypo>닫기</ButtonTypo>
        </PxButton>
      </DialogActions>
    </Dialog>
  );
};

export default InvalidUserListModal;
