import React, {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import PxButton from "../../../../../Buttons/PxButton";
import ButtonTypo from "../../../../../Typhography/ButtonTypo";
import {useRegisterInfoContext} from "./RegisterInfoProvider/useRegisterInfoContext";

// 탭 버튼 디자인(기업정보 추가정보 담당자)
const InfoChangeTapButton = styled(props => {
  const {active, ...rest} = props;
  return <button {...rest} />;
})`
  margin: 20px 14px 15px 0;
  padding: 6.5px 15px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  ${(props: any) => {
    if (props.active === true) {
      return `
            background-color: #616161;
            color: white;
          `;
    } else if (props.active === false) {
      return `
            background-color: white;
            color: #616161;
            border: 1px solid #ccc;
          `;
    }
  }}
`;

const PxStyledButton = styled(PxButton)`
  float: right;
  margin-top: 15px;
`;

const UserDetailTab: React.FC = () => {
  const {activeTab, setActiveTab, modifyStatus, setModifyStatus, submit} = useRegisterInfoContext();
  const [t] = useTranslation("lang", {useSuspense: false});

  const onClickModifyButton = (e: React.MouseEvent) => {
    setModifyStatus(!modifyStatus);
  };

  return (
    <Fragment>
      <InfoChangeTapButton
        active={activeTab === 0 ? true : false}
        onClick={() => {
          setActiveTab(0);
        }}
      >
        {t("applicationDetail.person")}
      </InfoChangeTapButton>
      <InfoChangeTapButton
        active={activeTab === 1 ? true : false}
        onClick={() => {
          setActiveTab(1);
        }}
      >
        {t("applicationDetail.applicationInfo")}
      </InfoChangeTapButton>

      {modifyStatus === false ? (
        activeTab !== 0 ? (
          <PxStyledButton padding={"10px 15px"} backgroundcolor="purple" onClick={onClickModifyButton}>
            <ButtonTypo>{t("common.modify")}</ButtonTypo>
          </PxStyledButton>
        ) : null
      ) : (
        <>
          <PxStyledButton padding={"10px 15px"} backgroundcolor="grey" onClick={onClickModifyButton} style={{marginLeft: 10}}>
            <ButtonTypo>{t("common.cancel")}</ButtonTypo>
          </PxStyledButton>
          <PxStyledButton padding={"10px 15px"} backgroundcolor="purple" onClick={submit}>
            <ButtonTypo>{t("common.confirm")}</ButtonTypo>
          </PxStyledButton>
        </>
      )}
    </Fragment>
  );
};

export default UserDetailTab;
