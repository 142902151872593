import { AppActions } from "../store";
import { RegisterFormAnswer, RegisterUserDetail, RegisterUserPage, RegitUser } from "../types/models/Registration";
import { getRequest, postRequest } from "../utils/utils";

export const getRegitUsersAction = (regitUsers: RegisterUserPage): AppActions => ({
    type: "GET_REGISTER_USER_PAGE",
    registerUserPage:regitUsers,
});
  
export const getRegisterUser = (registerUser:RegisterUserDetail): AppActions => ({
    type: "GET_REGISTER_USER",
    registerUser
})

export const updateRegisterFormAnswersActions = (formAnswers: Array<RegisterFormAnswer>): AppActions => ({
    type: "UPDATE_REGISTER_FORM_ANSWERS",
    formAnswers
})
  

export const toggleAttendanceStateAction = (regitUser: RegitUser): AppActions => ({
    type: "TOGGLE_ATTENDANCE_STATE",
    userInfo: regitUser,
  })
  
  
export const getRegitUserById = (id: string) => {
    return getRequest(null, `/api/onSiteRegit/${id}`,getRegisterUser);
  };
  

export const getRegitUser = (subEventId: string, method?: string, type?: string, searchWord?: string, page: number = 0, rowsPerPage: number = 10) => {
    return getRequest(null, "/api/onSiteRegit/users", getRegitUsersAction, {
      subEventId,
      method,
      type,
      searchWord,
      page:page,
      size:rowsPerPage
    });
};

export const updateRegitUser = (regitUserInfo: RegitUser) => {
    return postRequest(regitUserInfo, `/api/onSiteRegit/modifyRegit`);
  };
  
export const toggleAttendanceState = (id: number) => {
    return postRequest({id},"/api/onSiteRegit/toggleAttendanceState",toggleAttendanceStateAction)
  }