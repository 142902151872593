import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { AppActions, AppState } from "../store";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import MeetingHistory, {
  ApplicantMeetingHistory,
  MeetingHistoryInfo, MeetingHistoryPagination,
} from "../types/models/MeetingHistory";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { getRequest, postRequest, postRequestAndReturn, setAlert } from "../utils/utils";

export const createMeetingHistoryAction = (
  meetingHistory: MeetingHistoryInfo
): AppActions => ({
  type: "CREATE_MEETING_HISTORY",
  meetingHistory,
});

export const getMeetingHistoriesAction = (
  meetingHistories: MeetingHistoryPagination
): AppActions => ({
  type: "GET_MEETING_HISTORIES",
  meetingHistories,
});

export const getMeetingHistoryAction = (
  meetingHistory: MeetingHistoryInfo
): AppActions => ({
  type: "GET_MEETING_HISTORY",
  meetingHistory,
});

export const getApplicantMeetingHistoryAction = (
  applicantMeetingHistory: ApplicantMeetingHistory[]
): AppActions => ({
  type: "GET_APPLICANT_MEETING_HISTORY",
  applicantMeetingHistory
})

export const getApplicantMeetingHistory = (subEventId:number, applicantId:number) => {

  const path = "/api/meetingHistories/subEventApplicant";

  return getRequest(null, path, getApplicantMeetingHistoryAction, { subEventId: subEventId, userId: applicantId });
}

export const createMeetingHistory = (
  meetingHistory: MeetingHistoryInfo
) => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(meetingHistory);

  const path = `/api/meetingHistories`;
  try {
    var res: AxiosResponse<Event> = await axios.post(path, body, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

export const getMeetingHistories = <MeetingHistory>(
  subEventId: string,
  page : number =0,
  pageSize: number =10,
  search:string="",
) => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/meetingHistories?subEventId=${subEventId}&searchWord=${search}&page=${page}&size=${pageSize}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getMeetingHistoriesAction(res.data));
    return res.data
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
  };

  // next rise 미팅 목적 수정

export const updateMeetingPurpose = async (meetingId:string,meetingPurposeText:string) => {
    return await postRequestAndReturn("/api/meetings/meetingPurposeUpdate",{meetingId: meetingId, purposeText:meetingPurposeText})
  }

export const getMeetingHistory = <MeetingHistory>(
  meetingHistoryId: string
) => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/meetingHistories/detail?meetingHistoryId=${meetingHistoryId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getMeetingHistoryAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};
