import React, {useEffect} from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import {connect} from "react-redux";
import {AppState, AppActions} from "./store";
import setAuthToken, {deleteAuthToken} from "./utils/setAuthToken";
import {ThunkDispatch} from "redux-thunk";
import {getUser} from "./actions/users";
import {bindActionCreators} from "redux";
import CacheBuster from "./CacheBuster";

// types
import User from "./types/models/User";

// layouts
import Main from "./layouts/Main";
// import Video from "./video";
import ScrollToTop from "./components/Scrolls/ScrollToTop";
import Printer from "./layouts/Printer";
import {useTranslation} from "react-i18next";
import VideoMeeting from "./videoMeeting/App";

// 로그인 정보를 불러오기 위해 localStorage값에 저장된 token값과 userId값 확인
if (localStorage.token) {
  setAuthToken(localStorage.token, localStorage.userId);
}

type Props = reduxStateProps & DispatchProps;

const App: React.FC<Props> = props => {
  const {getUser} = props;
  const [t, i18n] = useTranslation("lang", {useSuspense: false});

  // local storage 값이 있으면 유저정보를 불러옴
  useEffect(() => {
    if (localStorage.token) {
      getLoggedUser();
    }
    const UserLanguageSetting = localStorage.getItem("language");
    if (UserLanguageSetting) {
      i18n.changeLanguage(UserLanguageSetting);
    }
  }, []); // eslint-disable-line

  const getLoggedUser = async () => {
    const result: any = await getUser(localStorage.userId);
    // token expired 경우 혹은 다른 에러 발생 시, token 삭제 후 refresh
    if (result === false) {
      deleteAuthToken();
      window.location.reload();
    }
  };

  return (
    <>
      <CacheBuster>
        <BrowserRouter basename="/">
          <ScrollToTop />
          <Switch>
            {/* <Route path="/video" component={Video} /> */}
            <Route path="/videoMeeting" component={VideoMeeting} />
            <Route path="/printer/:id" component={Printer} />
            <Route path="/" render={props => <Main {...props} />} />
          </Switch>
        </BrowserRouter>
      </CacheBuster>
    </>
  );
};

interface reduxStateProps {
  users: User;
}

const mapStateToProps = (state: AppState) => ({
  users: state.users,
});

interface DispatchProps {
  getUser: (userId: string) => void;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>): DispatchProps => ({
  getUser: bindActionCreators(getUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
