import React, { useEffect } from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { setDialogAction } from "../../actions/dialogs";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { useTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const PxDialogs: any = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState("false");
  const [t] = useTranslation("lang", { useSuspense: false });
  const dialogData = useSelector((state: AppState) => state.dialogs.materialDialog);

  useEffect(() => {
    setOpen(dialogData.open);
  }, [dialogData]);

  const handleClose = () => {
    dispatch(setDialogAction({ open: "false", title: "", content: [] }));
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open === "true"}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span style={{ paddingRight: "45px" }}>{dialogData.title}</span>
      </DialogTitle>
      {dialogData.content !== undefined && dialogData.content?.length > 0 && (
        <DialogContent dividers>
          {dialogData.content.map((content: string) => (
            <Typography gutterBottom>{content}</Typography>
          ))}
        </DialogContent>
      )}
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          {t("dialog.done")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PxDialogs;
