import User from "../types/models/User";
import UserActionTypes from "../types/actions/UserAction";

const userDefaultState: User = {
  name: "",
  email: "",
  company: "",
  countryNumber: "",
  phoneNumber: "",
  businessCards: [],
  isAuthenticated: false,
};

const userReducer = (
  state = userDefaultState,
  action: UserActionTypes
): User => {
  switch (action.type) {
    case "CREATE_USER":
      return action.user;
    case "GET_USER":
      return action.user;
    case "GET_AUTH_TOKEN":
      return { ...state, isAuthenticated: true };
    case "REMOVE_USER":
      return userDefaultState;
    default:
      return state;
  }
};

export default userReducer;
