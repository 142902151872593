import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../../../../../store";
import BusinessCard from "../../../../../../types/models/BusinessCard";
import CompanyInfoContent from "../../CompanyInfo/CompanyInfoContent";
import CompanyManagerInfo from "../../CompanyInfo/CompanyManagerInfo";
import {useRegisterInfoContext} from "./RegisterInfoProvider/useRegisterInfoContext";
import UserInfoContent from "./UserInfoContent";

const UserInfoView: React.FC = () => {
  const registerUser = useSelector((state: AppState) => state.registration.userDetail);
  const {activeTab} = useRegisterInfoContext();
  const [businessCards, setBusinessCards] = useState<BusinessCard[]>();

  useEffect(() => {
    const businessCard: BusinessCard = {
      name: registerUser.name,
      email: registerUser.email,
      id: registerUser.id,
      company: registerUser.companyName,
      phoneNumber: registerUser.phoneNum,
      position: registerUser.position,
      countryNumber: "82",
    };

    setBusinessCards(Array<BusinessCard>(businessCard));
  }, [registerUser]);

  // activeStep 0: 기업정보, 1: 추가정보, 2: 담당자
  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return businessCards && <CompanyManagerInfo businessCards={businessCards} />;
      case 1:
        return <UserInfoContent />;
      default:
        return null;
    }
  };

  return <>{renderContent()}</>;
};

export default UserInfoView;
