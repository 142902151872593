import React, {useCallback, useEffect, useState} from "react";
import HeadingTypo from "../../../Typhography/HeadingTypo";
import {useParams} from "react-router-dom";
import {getMeetingList} from "../../../../actions/meeting";
import {MeetingInfo} from "../../../../types/models/Meeting";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {ExportCSV} from "../../../ExportCSV/ExportCSV";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useTranslation} from "react-i18next";
import {meetingTimeSetting, TimeListProps} from "../../SubEventAdd";
import {currentCountryTime} from "../../../../utils/currentCountryTime";
import moment from "moment";
import {getApplications, getApplicationsExcelData} from "../../../../actions/application";
import {Applicants, ApplicationInfo} from "../../../../types/models/Application";
import {valuesIn} from "lodash";

const colorList = [
  "33FFFF",
  "006600",
  "CC9900",
  "333300",
  "999999",
  "6666FF",
  "3333FF",
  "330033",
  "660099",
  "CC66FF",
  "CC3300",
  "339999",
  "6633CC",
  "003333",
  "FFCCFF",
  "660033",
  "990066",
  "CCFF00",
  "336699",
  "004225",
  "a6b784",
];

type interpreterColor = {[k: string]: string};

// 현장실무자분들 돌아다니며 모니터링 페이지 확인 불가하여 인쇄하여 들고다닐 목적
const MeetingExcel = ({classes}: {classes: Record<"root" | "companyDiv" | "radioForm" | "meetingDiv", string>}) => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const params: {id: string} = useParams(); // subEventId
  const {country, countries} = useSelector((state: AppState) => state.countries!);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [applications, setApplications] = useState<ApplicationInfo[]>();
  const [interpreterColorList, setInterpreterColorList] = useState<interpreterColor>({});
  const dispatch = useDispatch();
  const [groupNames, setGroupNames] = useState<{
    // 전체 데이터
    buyer: string;
    seller: string;
  }>();
  const [data, setData] = useState<{
    // 엑셀 export data
    sellerData: any[];
    buyerData: any[];
    fileName: string;
    marges: {s: {r: number; c: number}; e: {r: number; c: number}}[];
  }>(null!);

  const eventName = subEvent?.mainEvent?.name;

  useEffect(() => {
    callGetApplications();
  }, [dispatch]);

  const callGetApplications = async () => {
    const result: any = await dispatch(getApplicationsExcelData(params.id));
    setApplications(result as ApplicationInfo[]);
  };

  useEffect(() => {
    if (subEvent && applications) {
      setGroupNames({
        seller: subEvent.sgroupName,
        buyer: subEvent.bgroupName,
      });
      createMeetingTimeForm(subEvent);
    }
  }, [applications, subEvent]);

  // 미팅 데이터 폼 생성
  const createMeetingTimeForm = useCallback(
    async subEvent => {
      const result = await getMeetingList(params.id);
      const meetingData: MeetingInfo[] = result ?? [];

      // 미팅 시간 추가
      const TimeList: TimeListProps[] = meetingTimeSetting(subEvent);
      let meetingDataJson: Array<string> = [];

      // 서브 이벤트 기간 일수 차
      let subDateCount = moment(currentCountryTime(subEvent, "subEventEndDate", countries!, country!), "YYYY-MM-DD").diff(
        moment(currentCountryTime(subEvent, "subEventStartDate", countries!, country!), "YYYY-MM-DD"),
        "day"
      );

      // 미팅날찌 기준 값
      let subEventDate = moment(currentCountryTime(subEvent, "subEventStartDate", countries!, country!));

      // 날짜, 미팅시간 가공
      Array.from({length: subDateCount + 1}, (x, i) => {
        const subEventDateString: string = subEventDate.format("YYYY-MM-DD");
        meetingDataJson.push(subEventDateString);
        subEventDate = subEventDate.add(1, "days");
      });
      //셀병합(번호, 기업이름)
      let marges = [
        //s = 병합시작점 ,e : 병합개수, r:row,  c:cell
        {s: {r: 0, c: 0}, e: {r: 1, c: 0}},
        {s: {r: 0, c: 1}, e: {r: 1, c: 1}},
      ];
      let data = [];
      let refinedData: any = new Object();
      refinedData["번호"] = "";
      refinedData["기업이름"] = "";
      for (let i = 0; i < subDateCount + 1; i++) {
        //같은날짜 셀병합
        const marge = {
          s: {r: 0, c: 2 + i * TimeList.length},
          e: {r: 0, c: 2 + TimeList.length - 1 + i * TimeList.length},
        };
        marges.push(marge);
        // 미팅 날짜별, 시간 별 입력
        // 날짜 시간별 키값 ex) 첫번째 시간일때 refinedData[2020-02-10], 첫번째 시간이 아닐때 refinedData[2020-02-1010:30]
        for (let j = 0; j < TimeList.length; j++) {
          j === 0 ? (refinedData[`${meetingDataJson[i]} (GMT+9)`] = `${TimeList[j].startTime}`) : (refinedData[`${meetingDataJson[i]}` + `${TimeList[j].startTime}`] = TimeList[j].startTime);
        }
      }
      data.push(refinedData);

      let sellerData: any[] = [];
      let buyerData: any[] = [];

      if (applications) {
        const sellerResult = await addMeetingData(meetingData, TimeList, applications, "seller");
        sellerData = [...data, ...sellerResult];
        const buyerResult = await addMeetingData(meetingData, TimeList, applications, "buyer");
        buyerData = [...data, ...buyerResult];
      }

      const fileName = `${eventName}_미팅 시간표(${moment().format("MMDDHHmm")})`;

      setData({
        sellerData,
        buyerData,
        fileName: fileName,
        marges: marges,
      });
      //날짜 셀렉트 박스 설정
    },
    [moment, subEvent, applications]
  );

  function addMeetingData(meetingData: MeetingInfo[], TimeList: TimeListProps[], applications: ApplicationInfo[], groupType: string) {
    const interpreterList: interpreterColor = {};
    const result: any[] = applications
      .filter(data => data.type === groupType)
      .map((ad, i) => {
        let refinedData: any = new Object();
        refinedData["번호"] = i + 1;
        refinedData["기업이름"] = ad.name + `${ad.applicants ? `(${ad.applicants[0].businessCardId!.name})` : ""}` + `\n(${ad.attendeeType == "online" ? "online" : "offline"})`;

        //서로 매칭된 미팅 필터
        const user = meetingData.filter(
          md =>
            (`${md.meetingAcceptor?.name}${md.meetingAcceptor?.managerName}` === `${ad.name}${(ad.applicants as Applicants[])[0]!.businessCardId!.name}` ||
              `${md.meetingApplicant?.name}${md.meetingApplicant?.managerName}` === `${ad.name}${(ad.applicants as Applicants[])[0]!.businessCardId!.name}`) &&
            md.status === "agree"
        );
        user.forEach((data, i) => {
          if (data.meetingApplicant && data.meetingAcceptor)
            TimeList[0].startTime === data.startTime
              ? //첫번째시간일때 (해당날 가장처음시작하는 시간)
                (refinedData[`${data.date} (GMT+9)`] =
                  //상대기업
                  data.meetingAcceptor.type !== groupType
                    ? `${data.interpreterId?.name == undefined ? `` : "통역 :" + data.interpreterId.name}\r\n` +
                      data.meetingAcceptor.name +
                      ` (${data.meetingAcceptor.managerName})` +
                      "\r\n" +
                      `+${data.meetingAcceptor.countryNumber ?? ""} ` +
                      data.meetingAcceptor.applicants +
                      "\r\n" +
                      data.meetingAcceptor.email
                    : `${data.interpreterId?.name == undefined ? `` : "통역 :" + data.interpreterId.name}\r\n` +
                      data.meetingApplicant.name +
                      ` (${data.meetingApplicant.managerName})` +
                      "\r\n" +
                      `+${data.meetingApplicant.countryNumber} ` +
                      data.meetingApplicant.applicants +
                      "\r\n" +
                      data.meetingApplicant.email)
              : //첫번째시간 외 시간들
                (refinedData[`${data.date}${data.startTime!}`] =
                  //상대기업
                  data.meetingAcceptor.type !== groupType
                    ? `${data.interpreterId?.name == undefined ? `` : "통역 :" + data.interpreterId.name}\r\n` +
                      data.meetingAcceptor.name +
                      ` (${data.meetingAcceptor.managerName})` +
                      "\r\n" +
                      `+${data.meetingAcceptor.countryNumber} ` +
                      data.meetingAcceptor.applicants +
                      "\r\n" +
                      data.meetingAcceptor.email
                    : `${data.interpreterId?.name == undefined ? `` : "통역 :" + data.interpreterId.name}\r\n` +
                      data.meetingApplicant.name +
                      ` (${data.meetingApplicant.managerName})` +
                      "\r\n" +
                      `+${data.meetingApplicant.countryNumber} ` +
                      data.meetingApplicant.applicants +
                      "\r\n" +
                      data.meetingApplicant.email);

          if (data.interpreterId?.name != undefined) {
            interpreterList[data.interpreterId.name] = colorList[i];
          }
        });

        return refinedData;
      });

    setInterpreterColorList(interpreterList);

    return result;
  }
  return (
    <div className={classes.meetingDiv}>
      <HeadingTypo fontweight="bold" gutterBottom>
        {t("excel.meetingSchedule")}
      </HeadingTypo>
      {data && data.sellerData && data.buyerData ? (
        <>
          {console.log(`open`)}
          <ExportCSV
            sellerData={data.sellerData}
            buyerData={data.buyerData}
            sellerName={groupNames!.seller}
            buyerName={groupNames!.buyer}
            fileName={data.fileName}
            marges={data.marges}
            type="meeting"
            interpreterColor={interpreterColorList}
          />
        </>
      ) : (
        <CircularProgress size={30} />
      )}
    </div>
  );
};

export default MeetingExcel;

// 성사된 미팅 리스트를 가져온 후 excel로 export 할 data 생성
// const getMeetings = useCallback(async () => {
//   const result = await getMeetingList(params.id);
//   const meetingData: MeetingInfo[] = result ?? [];

//   // 체결 된 미팅만 filtering
//   const filteredData = meetingData.filter((m) => m.status === "agree");

//   // excel로 export 할 data 형식에 맞게 meetingData 가공
//   let refinedData;

//   refinedData = filteredData.map((meeting) => {
//     let seller: string; // buyer 회사이름
//     let buyer: string; // seller 회사이름

//     // 미팅 수락자, 미팅 신청자 Application(신청정보)을 통해 buyer, seller 구분
//     if (meeting.meetingAcceptor?.type === "buyer") {
//       buyer = meeting.meetingAcceptor.name;
//       seller = meeting.meetingApplicant!.name;
//     } else {
//       buyer = meeting.meetingApplicant!.name;
//       seller = meeting.meetingAcceptor!.name;
//     }

//     return {
//       [subEvent!.sgroupName]: seller,
//       [subEvent!.bgroupName]: buyer,
//       날짜: meeting.date,
//       시작시간: meeting.startTime,
//       끝시간: meeting.endTime,
//     };
//   });

//   // setData({ data: refinedData, fileName: "미팅현황" });
// }, [params.id, subEvent]);
