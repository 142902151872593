import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";

import PxCheckBox from "../../../../../components/Checkboxes/PxCheckBox";
import PxRadioButton from "../../../../../components/Radios/PxRadioButton";

import {categoryAndCheckboxSelectTextToLinkAndText} from "../../../../../utils/utils";
import ContentFormItem from "./ContentFormItem";

interface TranslatedFormItemProps {
  type: "input" | "textField" | "radio" | "checkbox" | "string";
  content: string;
  [propsName: string]: {};
  link: boolean;
}

const TranslatedFormItem = ({type, content, link, ...rest}: TranslatedFormItemProps) => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const changeCategoryAndCheckboxSelectTextToLink = useCallback(
    (text: string) => {
      const linkTextStyle: React.CSSProperties = {
        textDecoration: "underline",
        fontWeight: "bold",
        color: "blue",
        cursor: "pointer",
      };

      // 신청서 양식에서 type의 input: 질문, string: 설명
      const generalTextStyle: React.CSSProperties = {
        whiteSpace: "pre-line",
        fontWeight: type === "input" ? 500 : "normal",
        fontSize: type === "input" ? 15 : 14,
      };
      let transText: string;
      //기본양식 번역
      if (text.trim() === "기업명" || text.trim() === "소속명") {
        transText = t("registeringForm.typeCorporatreName");
      } else if (text.trim() === "카테고리") {
        transText = t("registeringForm.selectCategory");
      } else if (text.trim() === "키워드") {
        transText = t("registeringForm.typeKeywords");
      } else if (text.trim() === "관심 카테고리") {
        transText = t("registeringForm.interestCategory");
      } else {
        transText = text;
      }

      const splitedText = categoryAndCheckboxSelectTextToLinkAndText(transText);

      let resultText;

      if (typeof splitedText === "string") {
        resultText = splitedText;
      } else {
        if (link) {
          resultText = (
            <>
              {splitedText.priorText}
              <span
                style={linkTextStyle}
                onClick={event => {
                  window.open(splitedText.link, "_blank");
                }}
              >
                {splitedText.linkText}
              </span>
              {splitedText.endText}
            </>
          );
        } else {
          resultText = splitedText.priorText + splitedText.linkText + splitedText.endText;
        }
      }

      return <div style={generalTextStyle}>{resultText}</div>;
    },
    [link, type]
  );

  return (
    <>
      {type === "string" && changeCategoryAndCheckboxSelectTextToLink(content == undefined ? "" : content)}
      {type === "checkbox" && <PxCheckBox label={changeCategoryAndCheckboxSelectTextToLink(content == undefined ? "" : content)} {...rest} />}
      {
        type === "input" && changeCategoryAndCheckboxSelectTextToLink(content == undefined ? "" : content)
        // <PxInputBase value={text} {...rest} />
      }
      {type === "radio" && <PxRadioButton label={changeCategoryAndCheckboxSelectTextToLink(content == undefined ? "" : content)} {...rest} />}
    </>
  );
};

export default TranslatedFormItem;
