import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

// bootstrap ui component
import Navbar from "react-bootstrap/Navbar";

// material icon
import HomeIcon from "@material-ui/icons/Home";

// material ui custom comp
import PxButton from "../Buttons/PxButton";
import ButtonTypo from "../Typhography/ButtonTypo";
import PxIconButton from "../Buttons/PxIconButton";
import PxGridContainer from "../Grid/PxGridContainer";
import { useTranslation } from "react-i18next";

const StyledNavbar = styled(Navbar)`
  padding: 15px;
  background-color: white;
  & .nav-item {
    padding-right: 2em;
  }
`;

interface EventDetailNavbarProps {}

const EventDetailNavbar: React.FC<EventDetailNavbarProps> = (props) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();

  return (
    <StyledNavbar expand="lg" sticky="top" className="border-bottom">
      <PxGridContainer
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <PxIconButton
          onClick={() => {
            history.push("/");
          }}
        >
          <HomeIcon />
        </PxIconButton>
      </PxGridContainer>
    </StyledNavbar>
  );
};

export default EventDetailNavbar;
