import React, { useEffect, useState } from "react";
import Estimate, {
  CalendarSelector,
  Item,
  Divider,
  Price,
  PexpoAccount,
  DialogImgBtn,
  Taxbill,
} from "./Estimate";

import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import moment from "moment";
import HeadingTypo from "../../Typhography/HeadingTypo";

export interface EstimateProps {
  subEventId: number;
  maxUsers: number;
  type: "estimate" | "quoation";
  numberOfPrinter: number;
  printStartDate: string;
  printEndDate: string;
  numberOfPassPocket: number;
  customDesign: boolean;
  customRolls: number;
  customDesignImg: any;
  supporters: number;
  supportStartDate: string;
  supportEndDate: string;
  totalPrice: number;
  deposit: number;
  finalPrice: number;
  taxBillEmail: string;
  businessRegistration: any;
  createdDate: string;
  modifiedDate: string;
}

export default () => {
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent); // layout 페이지에서 subEvent data load
  const user = useSelector((state: AppState) => state.users);
  const [filesName, setFilesName] = useState({
    customDesign: { name: "", path: "" },
    businessRegistration: { name: "", path: "" },
  });
  const now = moment().format("YYYY-MM-DD");
  const [estimateData, setEstimateData] = useState<EstimateProps>({
    subEventId: 0,
    maxUsers: 0,
    type: "estimate", // estiamte : 예상견적 , admin : 최종견적
    numberOfPrinter: 0,
    printStartDate: now,
    printEndDate: now,
    numberOfPassPocket: 0,
    customDesign: false,
    customRolls: 0,
    customDesignImg: 0,
    supporters: 0,
    supportStartDate: now,
    supportEndDate: now,
    totalPrice: 0,
    deposit: 500000,
    finalPrice: 0,
    taxBillEmail: "",
    businessRegistration: parseFloat(user.businessImageId || ""),
    createdDate: now,
    modifiedDate: now,
  });

  useEffect(() => {
    if (
      subEvent &&
      subEvent.estimates &&
      subEvent.estimates.length > 0 &&
      subEvent.estimates.some((data) => data.type === "quoation")
    ) {
      const estimate = subEvent.estimates.find((data) => data.type === "quoation")!;
      setEstimateData({
        ...estimate,
        subEventId: estimate.subEventId,
        maxUsers: estimate.maxUsers,
        taxBillEmail: estimate.taxBillEmail,
        type: estimate.type,
        numberOfPrinter: estimate.numberOfPrinter || 0,
        printStartDate: estimate.printStartDate || now,
        printEndDate: estimate.printEndDate || now,
        numberOfPassPocket: estimate.numberOfPassPocket || 0,
        customDesign: estimate.customDesign,
        customRolls: estimate.customRolls || 0,
        customDesignImg: estimate.customDesignImg || 0,
        supporters: estimate.supporters || 0,
        supportStartDate: estimate.supportStartDate || now,
        supportEndDate: estimate.supportEndDate || now,
        totalPrice: estimate.totalPrice,
        deposit: estimate.deposit,
        finalPrice: estimate.totalPrice - estimate.deposit,
        businessRegistration: estimate.businessRegistration,
      });
      setFilesName({
        customDesign: {
          name: estimate.customDesignImg ? estimate.customDesignImg.fileName : "",
          path: estimate.customDesignImg ? estimate.customDesignImg.filePath : "",
        },
        businessRegistration: {
          name: estimate.businessRegistration.fileName,
          path: estimate.businessRegistration.filePath,
        },
      });
    }
  }, [subEvent]);

  return (
    <Estimate
      title="상담회 최종 견적서"
      subEvent={subEvent!}
      createdDate={estimateData.createdDate}
      modifiedDate={
        estimateData.createdDate === estimateData.modifiedDate
          ? undefined
          : estimateData.modifiedDate
      }
    >
      <Item
        title="승인된 참가자 수"
        subTitle="1인당 ₩7,700"
        input={<HeadingTypo>{estimateData.maxUsers}</HeadingTypo>}
      />
      <Divider />
      <Item
        title="무선 출입증 프린터"
        subTitle="1대당 ₩110,000"
        input={<HeadingTypo>{estimateData.numberOfPrinter}</HeadingTypo>}
      />
      <Item
        title="대여 기간"
        subTitle="시작일 하루전 설치 및 테스트"
        justify="flex-start"
        input={
          <>
            <CalendarSelector
              name="printStartDate"
              value={estimateData.printStartDate}
              disabled={true}
            />{" "}
            -{" "}
            <CalendarSelector
              name="printEndDate"
              value={estimateData.printEndDate}
              disabled={true}
            />
          </>
        }
      />
      <Divider />
      <Item
        title="출입증 목걸이"
        subTitle="1개당 ₩1,500 [출입증 기본 디자인 보기]"
        input={<HeadingTypo>{estimateData.numberOfPassPocket}</HeadingTypo>}
      />
      <Divider />
      {estimateData && estimateData.customDesign && (
        <>
          <Item
            title="디자인 시안"
            subTitle={
              filesName.customDesign.name ? (
                <DialogImgBtn
                  title={filesName.customDesign.name}
                  link={filesName.customDesign.path}
                />
              ) : (
                "없음"
              )
            }
            input={<></>}
          />
          <Item
            title="주문 수량"
            subTitle="1롤당 ₩50,000 / 1롤당 250장 인쇄 / 최소 주문수량 10롤"
            input={<HeadingTypo>{estimateData.customRolls}</HeadingTypo>}
          />
        </>
      )}
      <Item
        title="기술지원"
        subTitle="1인당 ₩110,000 / 최소 1명 필수"
        input={<HeadingTypo>{estimateData.supporters}</HeadingTypo>}
      />
      <Item
        title="지원 기간"
        subTitle="시작일 하루전 오리엔테이션 포함"
        justify="flex-start"
        input={
          <>
            <CalendarSelector
              name="supportStartDate"
              value={estimateData.supportStartDate}
              disabled={true}
            />
            {/* disable*/}
            -
            <CalendarSelector
              name="supportEndDate"
              value={estimateData.supportEndDate}
              disabled={true}
            />
          </>
        }
      />
      <Price
        totalPrice={estimateData.totalPrice}
        deposit={estimateData.deposit}
        amount={estimateData.finalPrice}
      />
      <Taxbill
        name="taxBillMail"
        value={estimateData.taxBillEmail}
        subTitle={
          <DialogImgBtn
            title={filesName.businessRegistration.name}
            link={filesName.businessRegistration.path}
          />
        }
        nameInput="businessRegistration"
      />{" "}
      <PexpoAccount />
    </Estimate>
  );
};
