import React, {useEffect, useMemo} from "react";
import {Switch, RouteComponentProps, Route, useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

// router
import {eventRoutes} from "../router/router";

// type
import RouteType from "../types/models/Route";

// material comp
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {AppState} from "../store";

const Event: React.FC<RouteComponentProps> = ({match}) => {
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.users.userId);

  useEffect(() => {
    if (user === undefined) {
      history.push("/auth/login");
    }
  });

  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      const path = prop.layout + prop.path;
      if (path === "/event/") {
        return <Route exact path={path} component={prop.component} key={key} />;
      } else if (path.indexOf("/event/add") > -1) {
        return <Route path={path} component={prop.component} key={key} />;
      } else if (path.indexOf("/subEventAdd") > -1) {
        return <Route path={`/event/detail/:id/subEventAdd`} component={prop.component} key={key} />;
      } else if (prop.path.indexOf("/estimate") > -1) {
        return <Route path={`/event/detail/:id/subEvent/:secondId/estimate`} component={prop.component} key={key} />;
      } else if ((prop.layout + prop.path).indexOf("/subEventModify") > -1) {
        return <Route path={`/event/detail/:id/subEventModify/:secondId`} component={prop.component} key={key} />;
      } else if (prop.layout + prop.path === "/event/detail" || path === "/event/modify") {
        return <Route path={`${prop.layout}${prop.path}/:id`} component={prop.component} key={key} />;
      } else if (path.indexOf("/editor") > -1) {
        return <Route path={"/event/:id/editor"} component={prop.component} key={key} />;
      }
    });
  };

  const containerStyle = useMemo(() => {
    return location.pathname.indexOf("/editor") > -1
      ? {
          maxWidth: "none",
          paddingLeft: "0",
          paddingRight: "0",
        }
      : {
          height: "100%",
        };
  }, [location]);

  return (
    <>
      <Container style={containerStyle}>
        <Switch>{getRoutes(eventRoutes)}</Switch>
      </Container>
    </>
  );
};

export default Event;
