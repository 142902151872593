import React, { Fragment } from "react";
import ContentFormItem from "./ContentFormItem";
import { useCompanyInfoContext } from "./useCompanyInfoContext";
import find from "lodash/find";
import { ApplicationFormAnswers } from "../../../../../types/models/Application";

const CompanyInfoContent = () => {
  const {
    contentForm,
    contentApplicationFormAnswers,
    modify,
    modifiedApplicationFormAnswers,
  } = useCompanyInfoContext();

  return (
    <Fragment>
      {contentForm !== undefined &&
        contentForm.formIds.map((formId, index) => {
          let applicationFormAnswer: ApplicationFormAnswers | undefined; // 일반 답변
          let applicationManyFormAnswer: ApplicationFormAnswers[] = []; // 복수선택 답변

          // 회사정보 페이지에서 수정하다가 추가정보로 가서 수정하고 다시 돌아오면 값이 원래 값으로 돌아가는 것을 방지
          if (modify === true) {
            applicationFormAnswer = find(modifiedApplicationFormAnswers, {
              uuid: formId,
              repeatInfo: "N",
            });
          } else {
            applicationFormAnswer = find(contentApplicationFormAnswers, {
              uuid: formId,
            });
          }

          // 복수선택 답변값 세팅
          if (contentForm.formData[formId].type === "many") {
            if (modify === true) {
              modifiedApplicationFormAnswers.forEach((answer) => {
                if (
                  (answer.repeatInfo === "N" && answer.uuid === formId,
                  answer.type === "many")
                ) {
                  applicationManyFormAnswer.push(answer);
                }
              });
            } else {
              contentApplicationFormAnswers.forEach((answer) => {
                if (answer.uuid === formId) {
                  applicationManyFormAnswer.push(answer);
                }
              });
            }
          }

          return (
            <ContentFormItem
              key={index}
              modify={modify}
              contentFormData={contentForm.formData[formId]} // 신청서 자율양식 form 형식
              applicationFormAnswer={applicationFormAnswer!} // 신청서 답변
              applicationManyFormAnswers={applicationManyFormAnswer}
              whtAdditional={false}
            />
          );
        })}
    </Fragment>
  );
};

export default CompanyInfoContent;
