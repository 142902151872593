import Program from "../types/models/Program";
import ProgramActionTypes from "../types/actions/ProgramAction";

const programDefaultState: Program = {
  program: {
    subEventId: "", // 서브이벤트 id
    title: "", // 제목
    content: "", // 내용
    startDate: "", // 이벤트 시작일
    endDate: "", // 이벤트 종료일
    atch: "-1", // 첨부파일
    atchFile: {},
    classification: "", // 분류
    speakerList: [], // 스피커
  },
  programs: [],
};

const programReducer = (
  state = programDefaultState,
  action: ProgramActionTypes
): Program => {
  switch (action.type) {
    case "CREATE_PROGRAM":
    case "GET_PROGRAM":
      return { program: action.program };
    case "GET_PROGRAMS":
      return {
        programs: action.programs,
      };
    default:
      return state;
  }
};

export default programReducer;
