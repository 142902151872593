import React, {Fragment} from "react";
import RegisterInfoProvider from "./RegisterInfoProvider/RegisterInfoProvider";
import UserDetailTab from "./UserDetailTab";
import UserInfoView from "./UserInfoView";

const UserInfo: React.FC = props => {
  return (
    <Fragment>
      <RegisterInfoProvider>
        <UserDetailTab />
        <UserInfoView />
      </RegisterInfoProvider>
    </Fragment>
  );
};

export default UserInfo;
