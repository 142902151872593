import Axios, { AxiosResponse } from "axios";
import { Dispatch } from "react";
import { AppActions, AppState } from "../store";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import TeamManage, { TeamMember } from "../types/models/TeamManage";
import { getRequest, postRequest, setAlert } from "../utils/utils";
import { removeAlertAction, setAlertAction } from "./alerts";

interface TeamMemberForm {
    subEventId: number;
    name: string;
    phoneNumber: string;
    emailAddress: string;
    organization: string;
}

export const getTeamMemberListAction = (
    teamMemberList: TeamManage["teamMemberList"]
): AppActions => ({
    type: "GET_MEMBERS",
    teamMemberList
});


export const getTeamMemberList = (subEventId:number) => {

    const path = "/api/teamManage/list";

    return getRequest(null, path, getTeamMemberListAction, { subEventId: subEventId });
}

export const sendNoticeTeamMember = (mailList:string[], title: string, content: string, file: any) =>
    async (dispatch: Dispatch<AppActions>) => {

        const formData = new FormData();
        formData.append("toMail", mailList as any);
        formData.append("subject", title);
        formData.append("mailContent", content);
        formData.append("file", file);
    
    const path = "/api/teamManage/sendMail";

        try {
            const res: AxiosResponse = await Axios.post(path, formData);

            return res.data;
        } catch (err) {
            const error: Error = err.response.data;

            let message: string = "";
            console.log(`error : `, error);
        }
    }

export const sendSmsTeamMember = (data: any) => {
    const url = process.env.NODE_ENV === "production"
        ? "/api/smss/send"
        : "http://localhost:5000/api/smss/send";
    return postRequest(data,url);
}
    



export const addTeamMember = (
        // body:any
    subEventId:number, name:string, emailAddress:string, phoneNumber:string, organization:string
) => {
    const path = "/api/teamManage/add";

    const body: TeamMemberForm = {
        subEventId:subEventId,
        name:name,
        phoneNumber:`+82${phoneNumber}`,
        emailAddress:emailAddress,
        organization:organization
    }

    return postRequest(body, path);
}



export const deleteTeamMember = (memberId: number, subEventId:number) =>
    async (dispatch: Dispatch<AppActions>) =>
    {
        const path = "/api/teamManage/deleteMember";
        try {
            const res: AxiosResponse = await Axios.post(path, { memberId: memberId,subEventId:subEventId});
            
            dispatch(getTeamMemberListAction(res.data));
        } catch (err) {
            const error: Error = err.response.data;
            const alert: Alert = setAlert(err.response.status, error, path);
            dispatch(setAlertAction(alert));
            setTimeout(() => {
              dispatch(removeAlertAction(alert.id));
            });
            return "error";
        }

    // return postRequest({ memberId: memberId }, path);
}
