import axios, { AxiosResponse } from "axios";
import { Pagination } from "../reducers/webinars";
import { AppActions } from "../store";
import { ChangedType, PresenterType, Webinar, WebinarQA, WebinarQAExcelTypes, WebinarSubmitQA } from "../types/models/Webinar";
import { getRequest, postRequest, putRequest } from "../utils/utils";

const getWebinarsAction = (webinars: Webinar[]): AppActions => ({
  type: "GET_WEBINARS",
  webinars: webinars,
});

const getWebinarAction = (webinar: Webinar): AppActions => ({
  type: "GET_WEBINAR",
  webinar: webinar,
});

const createWebinarQA = (webinarQA :WebinarQA) : AppActions => ({
  type: "CREATE_WEBINARQA",
  webinarQA:webinarQA
})

const getWebinarQAAction = (webinarQA : Pagination) : AppActions => ({
  type: "GET_WEBINARQA",
  webinarQA:webinarQA,
}) 

const modifyQAAction = (webinarQA: WebinarQA) : AppActions=> ({
  type:"MODIFY_WEBINARQA",
  webinarQA:webinarQA
})

const deleteWebinarQAAction = (deleteA:{id:number,qa:"Q"|"A"}) :AppActions => ({
  type:"DELETE_WEBINARQA",
  delete:deleteA,
})

export const createWebinar = (webinarData: Webinar & PresenterType ) => {
  return postRequest(webinarData, "/api/webinars");
};

export const modifyWebinar = (webinarData  : Webinar & PresenterType & ChangedType) => {
  return putRequest(webinarData,"/api/webinars/modify",getWebinarAction )
}

export const getWebinars = (subEventId: string) => {
  return getRequest(null, "/api/webinars", getWebinarsAction, {
    subEventId: subEventId,
  });
};

export const getWebinar = (id: string) => {
  return getRequest(null, `/api/webinars/${id}`, getWebinarAction);
};

export const deleteWebinar = (id : string ) => {
  return getRequest(null, `/api/webinars/delete/${id}`)
}

export const isZoomWebinar = (id : string,isZoom:"Y"|"N") => {
  return getRequest(null, `/api/webinars/isZoom/${id}/${isZoom}`,getWebinarAction)

}

export const postWebinarQA =  (webinarQA:WebinarSubmitQA) =>{
  return  postRequest(webinarQA,"/api/webinarQA/createQA",createWebinarQA);
};

export const postModifyWEbinarQA = (webinarQA : WebinarSubmitQA) => {
  return postRequest(webinarQA,"/api/webinarQA/modifyQA",modifyQAAction);
}

export const getWebinarQA = (webinarId : string,complete:"" | "complete" | "incomplete",page:number) => {
  return  getRequest( null,`/api/webinarQA/getAll`,getWebinarQAAction,{webinarId,complete,page,size:5});
}

export const deleteWebinarQA = (id : number,QA:"Q"|"A") => {
  return getRequest(null, `/api/webinarQA/delteQA`,deleteWebinarQAAction,{id,QA});
}

export const getWebinarQAForExcel = async (
  subEventId: string
  ): Promise<WebinarQAExcelTypes[] | false> => {
  const config = {
  headers: {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Accept-Language": "ko",
  },
  data: {}, // get 호출시 필요
  };
  const path = `/api/webinarQA/getQAExcelData?subEventId=${subEventId}`;
  try {
  var res: AxiosResponse<WebinarQAExcelTypes[]> = await axios.get(path, config);

  return res.data;
  } catch (err) {
  console.log(err)
  return false;
  }
  }