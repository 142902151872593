import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, RouteProps } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Column } from "material-table";
import _ from "lodash";

// actions
import { AppState } from "../../../../store";

//icons

// custom ui comp
import PxTable from "../../../Table/PxTable";
import { getCounselsExcel, resetCounselsExcel } from "../../../../actions/counsels";
import { MeetingInfo } from "../../../../types/models/Meeting";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";

const groupBy = require("json-groupby");

interface TableStateType {
  columns: Array<Column<any>>;
  data: any[];
}

interface CounselExcelProps extends RouteProps {
  meetingInfo: MeetingInfo[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const CounselExcelTable: React.FC<CounselExcelProps> = ({ meetingInfo }: CounselExcelProps) => {
  const [tabs, setTabs] = useState<number>(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const param: { id: string } = useParams();

  const counsels = useSelector((state: AppState) => state.counsels.counselsExcel);

  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  const [sellerTableData, setSellerTableData] = React.useState<TableStateType>({
    columns: [],
    data: [],
  });
  const [buyerTableData, setBuyerTableData] = React.useState<TableStateType>({
    columns: [],
    data: [],
  });

  useEffect(() => {
    return () => {
      dispatch(resetCounselsExcel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getCounselsExcel(Number(param.id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, param.id]);

  useEffect(() => {
    if (counsels?.length !== 0) {
      const applicantNameList = groupBy(counsels, ["applicantName"]);
      const acceptorNameList = groupBy(counsels, ["acceptorName"]);
      const applicantsList = _.merge(applicantNameList, acceptorNameList);

      const list: any[] = [];

      _.mapKeys(applicantsList, (value, key) => {
        value.forEach((meeting: any) => {
          const counselData: any = {};
          if (meeting.meetingAcceptor.name === key) {
            if (meeting.acceptorMeetingSurvey.length !== 0) {
              counselData.name = meeting.meetingAcceptor.name;
              counselData.otherCompanyName = meeting.meetingApplicant.name;
              counselData.modifiedDate = moment(meeting.modifiedDate).format("YYYY-MM-DD");
              counselData.survey = meeting.acceptorMeetingSurvey;
              counselData.type = meeting.meetingAcceptor.type;
              list.push(counselData);
            }
          } else {
            if (meeting.applicantMeetingSurvey.length !== 0) {
              counselData.name = meeting.meetingApplicant.name;
              counselData.otherCompanyName = meeting.meetingAcceptor.name;
              counselData.modifiedDate = moment(meeting.modifiedDate).format("YYYY-MM-DD");
              counselData.survey = meeting.applicantMeetingSurvey;
              counselData.type = meeting.meetingApplicant.type;
              list.push(counselData);
            }
          }
        });
      });

      const sellerAndBuyerCounsels = groupBy(list, ["type"]);

      const sellerColumns: Array<Column<any>> = [
        {
          title: "소속명",
          field: "myCompanyName",
          cellStyle: {
            width: "calc(100%-125)",
            paddingRight: 0,
            maxWidth: "500px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        {
          title: "상대 소속명",
          field: "otherCompanyName",
          cellStyle: {
            width: "calc(100%-125px)",
            paddingRight: 0,
            maxWidth: "500px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        {
          title: "날짜",
          field: "date",
          cellStyle: { width: "150px" },
        },
      ];

      const buyerColumns: Array<Column<any>> = [
        {
          title: "소속명",
          field: "myCompanyName",
          cellStyle: {
            width: "calc(100%-125)",
            paddingRight: 0,
            maxWidth: "500px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        {
          title: "상대 소속명",
          field: "otherCompanyName",
          cellStyle: {
            width: "calc(100%-125px)",
            paddingRight: 0,
            maxWidth: "500px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        {
          title: "날짜",
          field: "date",
          cellStyle: { width: "150px" },
        },
      ];

      // buyer data and Columns
      const buyerListCounselList = sellerAndBuyerCounsels.buyer;
      if (buyerListCounselList !== undefined) {
        const buyerQuestions = groupBy(sellerAndBuyerCounsels.buyer[0].survey, ["uuid"]);

        // 테이블 컬럼 value
        _.mapKeys(buyerQuestions, (value, key) => {
          const data: any = {
            title: value[0].title,
            field: value[0].uuid,
            cellStyle: {
              width: "200px",
            },
          };

          buyerColumns.push(data);
        });

        const buyerTableRowData = buyerListCounselList.map((buyerCounsel: any) => {
          const counselData = {
            modifiedDate: buyerCounsel.modifiedDate,
            myCompanyName: buyerCounsel.name,
            otherCompanyName: buyerCounsel.otherCompanyName,
            date: buyerCounsel.modifiedDate,
          };

          const rowData: any = {};
          // 전체 바이어 설문답변을 table data 값에 추가 (복수선택의 경우 content를 붙여줘야함)
          buyerCounsel.survey.forEach((survey: any) => {
            if (_.has(rowData, survey.uuid)) {
              Object.assign(rowData, {
                [survey.uuid]: `${rowData[survey.uuid]}, ${survey.content}`,
              });
            } else {
              Object.assign(rowData, { [survey.uuid]: survey.content });
            }
          });

          _.merge(counselData, rowData);

          return counselData;
        });
        setBuyerTableData({
          ...buyerTableData,
          columns: buyerColumns,
          data: buyerTableRowData,
        });
      }

      // buyer data and Columns
      const sellerCounselList = sellerAndBuyerCounsels.seller;
      if (sellerCounselList !== undefined) {
        const sellerQuestions = groupBy(sellerAndBuyerCounsels.seller[0].survey, ["uuid"]);

        // 테이블 컬럼 value
        _.mapKeys(sellerQuestions, (value, key) => {
          const data = {
            title: value[0].title,
            field: value[0].uuid,
            cellStyle: { width: "200px" },
          };

          sellerColumns.push(data);
        });

        const sellerTableRowData = sellerCounselList.map((sellerCounsel: any) => {
          const counselData = {
            modifiedDate: sellerCounsel.modifiedDate,
            myCompanyName: sellerCounsel.name,
            otherCompanyName: sellerCounsel.otherCompanyName,
            date: sellerCounsel.modifiedDate,
          };

          const rowData: any = {};
          // 전체 바이어 설문답변을 table data 값에 추가 (복수선택의 경우 content를 붙여줘야함)
          sellerCounsel.survey.forEach((survey: any) => {
            if (_.has(rowData, survey.uuid)) {
              Object.assign(rowData, {
                [survey.uuid]: `${rowData[survey.uuid]}, ${survey.content}`,
              });
            } else {
              Object.assign(rowData, { [survey.uuid]: survey.content });
            }
          });

          _.merge(counselData, rowData);

          return counselData;
        });
        setSellerTableData({
          ...sellerTableData,
          columns: sellerColumns,
          data: sellerTableRowData,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counsels]);

  // useEffect(() => {
  //   console.log(buyerTableData);
  // }, [buyerTableData]);

  // useEffect(() => {
  //   console.log(sellerTableData);
  // }, [sellerTableData]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabs(newValue);
  };

  return (
    <>
      <div style={{ textAlign: "right", margin: "5px" }}>
        <PxButton
          backgroundcolor="purple"
          fullWidth
          onClick={() => {
            history.goBack();
          }}
          padding="15px 0"
        >
          <ButtonTypo>상담일지 작성현황 바로가기</ButtonTypo>
        </PxButton>
      </div>
      {/* <ArrowBackIcon
        style={{
          cursor: "pointer",
          margin: "23px",
          width: "18px",
          height: "18px",
          objectFit: "contain",
        }}
        onClick={() => history.goBack()}
      /> */}
      {/* style={{ padding: "0 0 0 64px", width: "100%" }} */}
      <Box padding={1}>
        <Paper square>
          <Tabs
            value={tabs}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            variant="fullWidth"
          >
            <Tab value={0} label="buyer" fullWidth />
            <Tab value={1} label="seller" fullWidth />
          </Tabs>
        </Paper>
        <TabPanel value={tabs} index={0}>
          <PxTable
            title={`${subEvent?.bgroupName} 상담일지`} // 바이어
            columns={buyerTableData.columns}
            data={buyerTableData.data}
            onRowClick={(event, rowData) => {}}
            options={{
              actionsColumnIndex: -1,
              pageSize: 5,
              showTitle: true,
              defaultExpanded: false,
              search: true,
              selection: false,
              exportButton: false,
            }}
          />
        </TabPanel>
        <TabPanel value={tabs} index={1}>
          <PxTable
            title={`${subEvent?.sgroupName} 상담일지`} // 셀러
            columns={sellerTableData.columns}
            data={sellerTableData.data}
            options={{
              actionsColumnIndex: -1,
              pageSize: 5,
              showTitle: true,
              defaultExpanded: false,
              search: true,
              selection: false,
              exportButton: false,
            }}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export default CounselExcelTable;
