import {CSSProperties} from "@material-ui/core/styles/withStyles";
import React, {ReactNode} from "react";
import {TargetBox} from "./BaseTemplate";

interface Props {
  children: ReactNode;
  type?: "default" | "custom";
  style?: CSSProperties;
}

const TargetBoxContainer: React.FC<Props> = ({children, type, style}) => {
  // const targetBoxContainer = (children: ReactNode, style?: CSSProperties) => {
  //     return <>{type === "custom" ? <TargetBox style={style}>{children}</TargetBox> : children}</>;
  // };

  return <>{type === "custom" ? <TargetBox style={style}>{children}</TargetBox> : children}</>;
};

export default TargetBoxContainer;
