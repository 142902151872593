import React, {Component, useEffect, useState} from "react";
import grapesjs from "grapesjs";
import {geditorConfig} from "../../../utils/editor/gEditorConfig";
import TemplateList from "./TemplateList";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import PxButton from "../../Buttons/PxButton";
import ButtonTypo from "../../Typhography/ButtonTypo";
import {Dialog, DialogActions, DialogContent, DialogContentText, TextField} from "@material-ui/core";
import TemplateSaveModal from "./TemplateSaveModal";
import {newTemplateAction, updateUserTemplate} from "../../../actions/template";
import Template from "../../../types/models/Template";
import {newAlert} from "../../../actions/alerts";
import {getSignedRequest} from "../../../actions/files";
import {useParams} from "react-router-dom";
import {Collection} from "lodash.isequal/node_modules/@types/lodash";

export enum TemplateListType {
  USER, // 유저가 생성한 목록
  DEFAULT, // 기본 제공 템플릿 목록
}

interface AddTraitType {
  type: string;
  label: string;
  name: string;
  placeholder?: string;
  options?: any[];
  changeProp?: number;
}

const Geditor: React.FC = props => {
  const dispatch = useDispatch();
  const param = useParams<{id: string}>();
  const user = useSelector((state: AppState) => state.users.userId);
  const {templateContent} = useSelector((state: AppState) => state.template);
  const [modalState, setModalState] = useState<boolean>(false);
  const [editor, setEditor] = useState<grapesjs.Editor>();

  useEffect(() => {
    if (user !== undefined) {
      setEditor(geditorConfig(param.id));
    }
  }, [user]);

  useEffect(() => {
    if (editor == null) return;
    editor.setComponents(templateContent?.htmlContent);
    editor.setStyle(templateContent?.cssContent);
  }, [templateContent, editor]);

  useEffect(() => {
    if (editor == null) return;
    editor?.onReady(() => {
      blockInit(editor);
      componentInit(editor);
    });
  }, [editor]);

  /**
   *      movetype(inside, outside) 에따른 trait추가
   *
   * @param component
   * @returns
   */
  const updateMoveTypeTrait = (component: grapesjs.Component) => {
    const moveTypeTrait = component.getTrait("move-type");
    if (!moveTypeTrait) return;

    const value = moveTypeTrait.getValue();
    const traitToAdd =
      value === "outside"
        ? {
            type: "text",
            label: "url",
            name: "href",
            placeholder: "https://example.com",
          }
        : {
            type: "text",
            label: "이동할 section",
            name: "move-section",
            placeholder: "이동할 섹션을 입력해주세요.",
          };

    const traitToRemove = value === "inside" ? "move-section" : "href";
    removeTraitFromComponent(component, traitToRemove);
    component.addTrait(traitToAdd, {at: 2});
  };

  /**
   *      cursor 설정에 따른 cursor css적용
   *
   * @param component
   * @returns
   */
  const updateCursorStyle = (component: grapesjs.Component) => {
    const trait = component.getTrait("cursor-style");
    if (!trait) return;

    const cursorStyle: string | undefined = trait.get("value");
    if (typeof cursorStyle === "undefined") return;
    const el: HTMLElement = component.getEl();
    el.style.cursor = cursorStyle;
  };

  /**
   *        설정 추가
   *
   * @param component   : 대상 컴포넌트
   * @param traitOptions  : 설정 옵션값
   */
  function addTraitToComponent(component: any, traitOptions: AddTraitType) {
    const traits = component.get("traits");
    const targetTrait = component.getTrait(traitOptions.name);

    if (traits && targetTrait == null) {
      traits.add(traitOptions);
    }
  }

  /**
   *        설정 삭제
   *
   * @param component   : 대상 컴포넌트
   * @param traitName   : 설정 이름
   */
  function removeTraitFromComponent(component: any, traitName: any) {
    const trait = component.getTrait(traitName);
    if (trait) {
      component.removeTrait(traitName);
    }
  }

  const currentHtmlContent = () => {
    if (editor == null) return;
    return editor.getHtml();
  };

  const currentCssContent = () => {
    if (editor == null) return;
    return editor.getCss();
  };

  /**
   *          block 초기 설정
   *
   * @param editor
   */
  const blockInit = (editor: grapesjs.Editor) => {
    const blockManager = editor.BlockManager;

    /**
     *      button block 추가
     */
    blockManager.add("custom-button", {
      label: "Button",
      category: "Basic",
      attributes: {class: "gjs-fonts gjs-f-button"},
      content: `<a 
                    style="
                    display:block;
                    background: blue;
                    width: 7rem;
                    height:3rem;
                    text-align: center;
                    line-height: 2.7rem;
                    border-radius: 9px;
                    color: white;
                    cursor: pointer
                    ">button</a>`,
    });

    const tabsBlock: grapesjs.Block = blockManager.get("tabs");
    tabsBlock.set("category", "Basic");

    const navbarBlock: grapesjs.Block = blockManager.get("navbar");
    navbarBlock.set("category", "Basic");

    blockManager.render([...blockManager.getAll()]);
  };

  /**
   *            component 초기 설정
   *        (선택, 추가시 발생 이벤트 지정)
   *
   * @param editor
   */
  const componentInit = (editor: grapesjs.Editor) => {
    editor.on("component:selected", (component: grapesjs.Component) => {
      removeTraitFromComponent(component, "href");
      removeTraitFromComponent(component, "target");

      addTraitToComponent(component, {
        type: "select",
        options: [
          {value: "inside", name: "내부"},
          {value: "outside", name: "외부"},
        ],
        label: "이동할 위치",
        name: "move-type",
        changeProp: 1,
      });

      addTraitToComponent(component, {
        type: "select",
        options: [
          {value: "default", name: "Default"},
          {value: "pointer", name: "Pointer"},
          {value: "not-allowed", name: "Not Allowed"},
        ],
        label: "Cursor Style",
        name: "cursor-style",
        changeProp: 1,
      });
    });

    /**
     *      에디터에 컴포넌트 추가때 발생 이벤트
     */
    editor.on("component:add", (component: grapesjs.Component) => {
      if (component.get("type") === "image") {
        console.log("image add test");
        component.addStyle({
          "max-width": "100%",
          "max-height": "auto",
        });
      }
    });

    /**
     *    컴포넌트의 변화가 감지된는 이벤트
     */
    editor.on("component:update", (component: grapesjs.Component) => {
      // const traitNames: string[] = component.getTraits().map(t => t.props().name);
      updateMoveTypeTrait(component);
      updateCursorStyle(component);
    });
  };

  /**
   *        현재 템플릿 템플릿 수정 클릭이벤트
   *
   * @returns
   */
  const updateTemplate = async () => {
    if (editor == null || !templateContent) return;

    const updateTemplateData: Template["templateContent"] = {
      templateId: templateContent?.templateId,
      templateName: templateContent?.templateName,
      htmlContent: editor.getHtml(),
      cssContent: editor.getCss() as string,
    };

    const res: any = await dispatch(updateUserTemplate(updateTemplateData));
    if (res) {
      dispatch(newAlert("수정완료", "templateUpdate", "success"));
    }
  };

  return (
    <>
      <Container>
        <NavContainer>
          <TemplateList type={TemplateListType.DEFAULT} />
          <TemplateList type={TemplateListType.USER} />
        </NavContainer>
        <div id="editor" style={{borderBottom: "1px solid"}}></div>
        <BottomContainer>
          {templateContent?.templateId !== 0 ? (
            <>
              <PxButton backgroundcolor="purple" style={{height: "60%"}} onClick={() => updateTemplate()}>
                <ButtonTypo>수정</ButtonTypo>
              </PxButton>
            </>
          ) : (
            <PxButton backgroundcolor="purple" style={{height: "60%"}} onClick={() => setModalState(true)}>
              <ButtonTypo>저장</ButtonTypo>
            </PxButton>
          )}
        </BottomContainer>
      </Container>

      <TemplateSaveModal modalState={modalState} setModalState={setModalState} htmlContent={currentHtmlContent() as string} cssContent={currentCssContent() as string} />
    </>
  );
};

export default Geditor;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 9fr 1fr;
  grid-template-areas:
  "a b"
  "c c";
}
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: c;
`;
