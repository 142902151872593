import React from "react";
import styled, { css } from "styled-components";

// material ui comp
import Grid, { GridProps } from "@material-ui/core/Grid";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

interface PxGridContainerProps {
  width: Breakpoint;
  parent?: boolean;
}

type Prop = GridProps & PxGridContainerProps;

const PxGridContainer: React.FC<Prop> = styled((props: Prop) => {
  const { width, parent, ...rest } = props;
  return <Grid container {...rest} />;
})`
  ${(props) => {
    if (props.parent === true && props.width === "sm") {
      return css`
        padding-left: 24px;
        padding-right: 24px;
      `;
    }

    if (props.parent === true && props.width === "xs") {
      return css`
        padding-left: 16px;
        padding-right: 16px;
      `;
    }
    return css`
      padding: 0;
    `;
  }}
`;

PxGridContainer.defaultProps = {
  parent: false,
};

export default withWidth()(PxGridContainer);
