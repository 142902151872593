import React from "react";
import ReactDOM from "react-dom";
import { StylesProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import store from "./store";
import { SnackbarProvider } from "notistack";

//i18n
import "./i18n/i18n";

// global css
import "./index.css";
// bootstrap css
import "bootstrap/dist/css/bootstrap.min.css";

// material ui global theme
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";

// layout component
import PxAlert from "./components/Alerts/PxAlert";
import PxProgress from "./components/Progresses/PxProgress";
import PxDialog from "./components/Dialogs/PxDialogs";
import App from "./App";
import PxBackDrop from "./components/BackDrop/PxBackDrop";
import DialogsLayout from "./components/Dialogs/DialogsLayout";

// break points
// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 960px
// lg, large: 1280px
// xl, extra-large: 1920px

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: green,
  },
  typography: {
    fontFamily: "'Spoqa Han Sans Neo', sans-serif !important",
  },
});

// 인터넷익스플로러를 Edge로 리다이렉트
if (window.navigator.userAgent.match(/MSIE|Internet Explorer|Trident/i)) {
  window.open("microsoft-edge:" + document.URL, "_self");
}

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <SnackbarProvider
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            maxSnack={3}
          >
            <PxAlert /> <PxProgress />
            <PxDialog />
            <DialogsLayout />
          </SnackbarProvider>
          <PxBackDrop />
          <App />
        </Provider>
      </MuiThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// import * as serviceWorker from './serviceWorker';
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
