import React from "react";
import styled from "styled-components";

// material ui comp
import Typography, { TypographyProps } from "@material-ui/core/Typography";

interface CaptionTypoProps {
  txtcolor?: "rgba(0,0,0,0.87)" | "purple" | "white";
}

type Prop = CaptionTypoProps & TypographyProps;

const CaptionTypo: React.FC<Prop> = styled((props: Prop) => {
  const { txtcolor, ...rest } = props;
  return <Typography {...rest} />;
})`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: rgba(0, 0, 0, 0.38);
`;

CaptionTypo.defaultProps = {
  txtcolor: "rgba(0,0,0,0.87)",
};

export default CaptionTypo;
