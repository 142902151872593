import { AppActions, AppState } from "../store";
import Progress from "../types/models/Progress";
import { Dispatch } from "react";

export const setProgressAction = (progress: Progress): AppActions => ({
  type: "SET_PROGRESS",
  progress: progress,
});

export const setProgress = (progress: Progress) => (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  dispatch(setProgressAction(progress));
};
