import { ApplicationFormAnswers } from "./Application";

export interface Registration {
    userDetail: RegitUser;
    userListPage: RegisterUserPage; 
}

export interface RegitUser {
    id?: string;
    name: string;
    phoneNum: string;
    type: string;
    companyName: string;
    position: string;
    subEventId?: string;
    email?: string;
  method?: string;
  attendanceState?: AttendanceState;
    addtinalData?: {[key:string] : string};
}

export enum AttendanceState {
  ATTENDANCE = "ATTENDANCE",
  ABSENT = "ABSENT" 
}
  
export interface RegisterUserDetail {
    id: number;
    name: string;
    phoneNum: string;
    type: string;
    companyName: string;
    position: string;
    subEventId: string;
    email: string;
    method: RegisterMethod;
    createdDate: string;
    registerFormAnswer?: RegisterFormAnswer[];
}

export interface RegisterFormAnswer {
    id?: number; // 답변의 primary key
    formId: number; // 답변한 자율양식 form의 id
    title: string; // 질문내용
    type: string; // 자율양식의 종류
    uuid: string; // 답변한 자율양식 질문의 uuid
    answerUuid?: string; // 자율양식 답변의 uuid (단일선택 or 복수선택)
    content?: string; // 답변 (keyword, category 답변 제외) 
}
  
  export interface RegisterUserPage{
    content: RegitUser[];
    pageable?: any;
    last?: boolean;
    totalElements: number;
    totalPages: number;
    size: number;
    number: number;
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    numberOfElements: number;
    first: boolean;
    empty: boolean;
  }
  
  export enum RegisterMethod {
    PRE_REGISTRATION = "사전등록",  // 사전등록
    ON_SITE_REGISTRATION = "현장등록",  // 현장등록
    DATA_IMPORT_REGISTRATION = "데이터 이전"  // 데이터 받아서 하는 등록
  }
  
  export interface ExcelUserRegister {
    subEventId: number;
    userData: Array<ExcelUserData>;
  }
  
  export interface ExcelUserData{
    name: string;
    phoneNum: string;
    type: string;
    companyName: string;
    position: string;
    email: string;
    additionalData?: ApplicationFormAnswers[];
  }
  
  export interface InvalidRegitUser{
    index: number;
    name: string;
    reason: string;
  }

export type CreateRegitUserRes = {
  registerFailUsers: Array<CreateServerFailUser>;
  registerSuccessUsers: Array<RegitUser>;
}
  
export type CreateServerFailUser = {
  name: string;
  phoneNum: string;
  type: string;
  companyName: string;
  position: string;
  email: string;
  cause: string;
}

export type ResultDialogParam = {
  failUsers: Array<InvalidRegitUser>;
  successUsers: Array<RegitUser>;
}
  