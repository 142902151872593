import axios from "axios";

export const getPostSurveys = async (subEventId: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: { subEventId: subEventId },
    data: {},
  };

  try {
    const res = await axios.get(
      `/api/postSurveys/surveys/${subEventId}`,
      config
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};
