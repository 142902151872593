// types
import { Form } from "../types/models/Form";
import FormActionTypes from "../types/actions/FormAction";

const formDefaultState: Form = {
  templateType: "default",
  id: undefined,
  type: undefined,
  subEventId: undefined,
  formData: { formData: {}, formIds: [] },
  additionalFormData: { formData: {}, formIds: [] },
  content: "",
  createdDate: undefined,
  modifiedDate: undefined,
  // history: historyList
};

const formReducer = (
  state = formDefaultState,
  action: FormActionTypes
): Form => {
  switch (action.type) {
    case "GET_FORMS":
    case "SET_FORMS":
    case "GET_FORM":
    case "SET_ADD_FORMS":
      return { ...state, ...action.forms };

      // const jsonFormData = JSON.parse(action.form.content);
      // const jsonAdditionalData = JSON.parse(action.form.additional);

      // return {
      //   ...state,
      //   id: action.form.id,
      //   type: action.form.type,
      //   formData: { formData: jsonFormData.formData, formIds: jsonFormData.formIds },
      //   additionalFormData: { formData: jsonAdditionalData.formData, formIds: jsonAdditionalData.formIds },
      //   createdDate: action.form.createDate,
      //   modifiedDate: action.form.modifiedDate
      // }
    case "UPDATE_FORM":
      return { ...state, ...action.form };
    case "GET_HISTORY_FORM":
    case "GET_BUYER_HISTORY_FORM":
      return { ...state, history: action.history };
    case "REMOVE_FORMS":
      return {
        ...{templateType:state.templateType,id:undefined, type:undefined, subEventId:undefined, formData:{formData:{}, formIds:[]},content:"",createdDate:undefined,modifiedDate:undefined},
        additionalFormData: { formData: {}, formIds: [] },
      };
    case "UPDATE_TEMPLATE":
      return {...state, templateType: action.templateType}
    default:
      return state;
  }
};

export default formReducer;
