import { SmsListItem, SmsTarget, PaginationSMS} from "../types/models/Sms";
import SmsActionTypes from "../types/actions/SmsAction";

interface SmsReduxState {
  smsTargets: SmsTarget[];
  smss?: PaginationSMS;
}

const smsDefaultState: SmsReduxState = {
  smsTargets: [],
  smss:{
    content:[],
    pageable: {},
    last: false,
    totalElements: 0,
    totalPages: 0,
    size: 1,
    number: 0,
    sort: {
        sorted: false,
        unsorted: true,
        empty: true
    },
    numberOfElements: 1,
    first: true,
    empty: false
  },
};

const smssReducer = (
  state = smsDefaultState,
  action: SmsActionTypes
): SmsReduxState => {
  switch (action.type) {
    case "GET_SMS_TARGETS":
      return { ...state, smsTargets: action.smsTargets };
    case "GET_SMS_LIST":
      return { ...state, smss: action.smss };
    default:
      return state;
  }
};

export default smssReducer;
