import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams, match} from "react-router-dom";
import {AppState} from "../../../../store";
import {Column, MTableToolbar} from "material-table";

// actions
import {getSystemMails} from "../../../../actions/mail";

// custom ui comp
import PxTable from "../../../../components/Table/PxTable";
import PxGridContainer from "../../../Grid/PxGridContainer";

import moment from "moment";
import {setTableInfoLoadingAction} from "../../../../actions/tableInfo";
import {useTranslation} from "react-i18next";
import {MailInfo} from "../../../../types/models/Mail";

interface RowType {
  id: string;
  code: string;
  title: string;
  // content: string;
  createdDate: string;
  createdUserId: string;
  subEventId: string;
  toMail: string;
  sendTime: string;
  status: string | undefined;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const SystemMailList = ({match}: {match: match}) => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const dispatch = useDispatch();
  const history = useHistory();
  const param: {id: string} = useParams();
  const mails = useSelector((state: AppState) => state.mails.systemMails);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("mailList.recipient"),
        field: "toMail",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: t("common.title"),
        field: "title",
        cellStyle: {
          width: "calc(100%-500px)",
          paddingRight: 0,
          maxWidth: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("mailList.sentDate"),
        field: "createdDate",
        cellStyle: {width: "120px", paddingRight: 0},
      },
      {
        title: t("mailList.sendTime"),
        field: "sendTime",
        cellStyle: {width: "120px", paddingRight: 0},
      },
      {
        title: t("mailList.status"),
        field: "status",
        cellStyle: {width: "120px", paddingRight: 0},
      },
    ],
    data: [],
  });

  const callGetSystemMails = useCallback(async () => {
    await dispatch(getSystemMails(param.id));
    if (!mails || mails.content.length <= 0) dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  useEffect(() => {
    // callGetSystemMails();
  }, [callGetSystemMails]);

  useEffect(() => {
    let tableData: RowType[] = [];
    if (mails && Array.isArray(mails.content) && mails.content.length > 0) {
      mails.content.map((mail: MailInfo) => {
        console.log(`mails create date : `, mail.createdDate);
        const rowData: RowType = {
          title: mail.title,
          id: mail.id || "",
          code: mail.code || "",
          // content: mail.content.replace(/(<([^>]+)>)/gi, ""),
          createdDate: moment(mail.createdDate).format("YYYY-MM-DD"),
          createdUserId: mail.createdUserId || "",
          subEventId: mail.subEventId || "",
          toMail: mail.toMail || "",
          sendTime: moment(mail.createdDate).format("HH:mm:ss"),
          status: mail.status,
        };
        tableData.push(rowData);
      });
      setState(prevCreateDate => {
        return {...prevCreateDate, data: tableData};
      });
      dispatch(setTableInfoLoadingAction(false));
    }
  }, [mails]);

  return (
    <>
      <PxTable<RowType>
        components={{
          Toolbar: props => {
            return (
              <PxGridContainer direction="column">
                <MTableToolbar {...props} />
              </PxGridContainer>
            );
          },
        }}
        title=""
        columns={state.columns}
        actionData={param.id}
        totalPage={mails?.totalPages}
        totalElements={mails?.totalElements}
        getAction={getSystemMails}
        data={state.data}
        onRowClick={(evt, selectedRow) => {
          history.push(`${match.url}/${selectedRow?.id}`);
        }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
      />
    </>
  );
};

export default SystemMailList;
