import React, {useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, TextField} from "@material-ui/core";
import PxButton from "../../Buttons/PxButton";
import ButtonTypo from "../../Typhography/ButtonTypo";
import {saveTemplate, getUserTemplateList} from "../../../actions/template";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {SaveTemplateInfo} from "../../../types/models/Template";

interface TemplateSaveProps {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  htmlContent: string;
  cssContent: string;
}

const TemplateSaveModal: React.FC<TemplateSaveProps> = props => {
  const {modalState, setModalState, htmlContent, cssContent} = props;
  const dispatch = useDispatch();
  const [templateName, setTemplateName] = useState<string>();
  const event = useSelector((state: AppState) => state.events.event);

  const templateSaveEvent = async () => {
    if (templateName == undefined && templateName == "" && !event?.id) {
      return;
    }
    const templateInfo: SaveTemplateInfo = {
      eventId: event?.id as number,
      templateName: templateName!,
      htmlContent: htmlContent,
      cssContent: cssContent,
    };

    await dispatch(saveTemplate(templateInfo));
    await dispatch(getUserTemplateList(event!.id as number));

    modalDataInit();
  };

  const modalDataInit = () => {
    setTemplateName("");
    setModalState(false);
  };

  return (
    <Dialog open={modalState}>
      <DialogContent>
        <DialogContentText>템플릿의 이름을 입력해주세요.(url에 노출될 이름이므로 영어로 입력해주세요.)</DialogContentText>
        <TextField autoFocus margin="dense" label="template 이름" onChange={e => setTemplateName(e.target.value)} fullWidth />
      </DialogContent>
      <DialogActions>
        <PxButton backgroundcolor="purple" onClick={() => templateSaveEvent()}>
          <ButtonTypo>저장</ButtonTypo>
        </PxButton>
        <PxButton backgroundcolor="purple" onClick={() => modalDataInit()}>
          <ButtonTypo>취소</ButtonTypo>
        </PxButton>
      </DialogActions>
    </Dialog>
  );
};

export default TemplateSaveModal;
