import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {InvalidRegitUser, RegitUser} from "../../../../types/models/Registration";

interface Props {
  successUsers: Array<RegitUser>;
}

interface Column {
  id: `company` | `name` | `phoneNum`;
  label: string;
  minWidth?: number;
  align?: `right`;
}

const columns: readonly Column[] = [
  {id: "name", label: "이름", minWidth: 170},
  {id: "company", label: "회사이름", minWidth: 170},
  {id: "phoneNum", label: "연락처", minWidth: 300},
];

const RegitSuccessTable: React.FC<Props> = ({successUsers}) => {
  return (
    <Paper style={{width: "100%"}}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth}}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {successUsers.map(userInfo => {
              return (
                <TableRow>
                  <TableCell>{userInfo.name}</TableCell>
                  <TableCell>{userInfo.companyName}</TableCell>
                  <TableCell>{userInfo.phoneNum}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default RegitSuccessTable;
