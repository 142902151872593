import React from "react";
import Banner from "./Banner";
import HourGlass from "../../../../assets/icons/hourglass-bottom.svg";
import { useDispatch } from "react-redux";
import { modifyPayment } from "../../../../actions/subEvents";
import { SubEventInfo } from "../../../../types/models/SubEvent";
import { Trans, useTranslation } from "react-i18next";

export default ({ subEvent }: { subEvent: SubEventInfo }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation("lang", { useSuspense: false });
  const onClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    await dispatch(modifyPayment({ id: parseFloat(subEvent.id || "0"), type: "N" }));
  };
  return (
    <Banner
      bgColor="#5ac8fa"
      IconNode={<img src={HourGlass} />}
      estimateLink={`/estimate/subEvent/${subEvent.id}/consulting`}
      actionBtnTypo={t("eventDetail.switchCancel")}
      actionDisabled={subEvent.mainEvent?.approval === "D"}
      actionBtnClick={onClick}
      title={
        <Trans t={t} i18nKey="eventDetail.switchingPaidPlanForConsulting">
          상담회 <span style={{ color: "#512da8" }}>유료 플랜</span> 변경중
        </Trans>
      }
      body={
        <Trans t={t} i18nKey="eventDetail.switchingpaidBannerInfo">
          담당자가 내용을 확인하고 있으며 곧 연락드릴 예정입니다.
          <br />
          바로 연결을 원하시는 경우 02-565-4237로 연락주시기 바랍니다.
        </Trans>
      }
    />
  );
};
