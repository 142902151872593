import React from "react";
import styled from "styled-components";

// material ui comp
import Typography, { TypographyProps } from "@material-ui/core/Typography";

interface TitleTypoProps {
  txtcolor?: "rgba(0,0,0,0.87)" | "secondary" | "purple";
  fontWeight?: string;
  fontSize?: number;
}

type Prop = TitleTypoProps & TypographyProps;

const TitleTypo: React.FC<Prop> = styled((props: Prop) => {
  const { txtcolor, fontWeight, ...rest } = props;
  return <Typography {...rest} />;
})`
  font-size: ${(props) => {
    if (props.fontSize === undefined) {
      return "1.3rem";
    } else {
      return props.fontSize + "px";
    }
  }};
  font-weight: ${(props) => {
    if (props.fontWeight !== undefined) {
      return props.fontWeight;
    }
    return "bold";
  }};
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  color: ${(props) => {
    if (props.txtcolor === "purple") {
      return "rgba(95, 75, 139, 1.0)";
    }
    if (props.txtcolor === "secondary") {
      return "rgba(0, 0, 0, 0.38)";
    }
    return props.txtcolor;
  }};
`;

TitleTypo.defaultProps = {
  txtcolor: "rgba(0,0,0,0.87)",
};

export default TitleTypo;
