import React, {useCallback, useEffect} from "react";
import {Route, useHistory} from "react-router-dom";
import {AppState, AppActions} from "../store";
import {ThunkDispatch} from "redux-thunk";
import {bindActionCreators} from "redux";
import {removeSubEvent, getSubEvent} from "../actions/subEvents";
import {connect, useDispatch} from "react-redux";

// router
import {formRoutes} from "../router/router";

// type
import RouteType from "../types/models/Route";
import SubEvent from "../types/models/SubEvent";

// material comp
import Container from "@material-ui/core/Container";

import FormProvider from "../components/Provider/FormProvider/FormProvider";

type Props = ReduxStateProps & DispatchProps;

const AutonoForm: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {removeSubEvent, subEvents} = props;
  const subEventId = history.location.pathname.split("/")[3];

  // subEvent를 가져온 후 redux에 저장, form page unmount 시 redux subEvents 초기화
  useEffect(() => {
    dispatch(getSubEvent(parseInt(subEventId)));
    return () => {
      removeSubEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/form") {
        return <Route exact path={prop.layout + prop.path} component={prop.component} key={key} />;
      }
      return null;
    });
  };

  return (
    <>
      <Container style={{maxWidth: "640px", paddingTop: 40}}>
        <FormProvider>{getRoutes(formRoutes)}</FormProvider>
      </Container>
    </>
  );
};

interface ReduxStateProps {
  subEvents: SubEvent;
}

const mapStateToProps = (state: AppState) => ({
  subEvents: state.subEvents,
});

interface DispatchProps {
  removeSubEvent: () => void;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>): DispatchProps => ({
  removeSubEvent: bindActionCreators(removeSubEvent, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutonoForm);
