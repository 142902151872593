import React, {useEffect} from "react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {AppState} from "../../../../store";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MailList from "./MailList";
import SystemMailList from "./SystemMailList";
import MailSetting from "./MailSetting";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";
import {useTranslation} from "react-i18next";
import InvitationDetail from "./Invitation/InvitationDetail";
import MailRequestList from "./MailRequest/MailRequestList";

const MailTab: React.FC<RouteComponentProps> = ({match}) => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const history = useHistory();
  const subEvent = useSelector((state: AppState) => state.subEvents!.subEvent);
  const [tabNumber, setTapNumber] = React.useState(1);

  const handleChange = (event: React.ChangeEvent<{}>, newTapNumber: number) => {
    setTapNumber(newTapNumber);
    window.localStorage.setItem("mailTab", JSON.stringify(newTapNumber));
  };

  useEffect(() => {
    const savedTabNumber = window.localStorage.getItem("mailTab");
    if (savedTabNumber == null) return;
    setTapNumber(Number(savedTabNumber));
  }, []);

  const renderTabContent = () => {
    switch (tabNumber) {
      case 1:
        // return <MailList match={match} />;
        return <MailRequestList match={match} />;
      case 2:
        return subEvent?.category != "registration" ? <SystemMailList match={match} /> : <InvitationDetail />;
      case 3:
        return <MailSetting />;
    }
  };

  return (
    <>
      <Paper square style={{margin: "20px 0 0"}}>
        <Tabs value={tabNumber} TabIndicatorProps={{style: {backgroundColor: "transparent"}}} indicatorColor="primary" textColor="primary" onChange={handleChange} aria-label="disabled tabs example">
          <Tab label="mail" value={1} />
          <Tab label={subEvent?.category != "registration" ? "system mail" : "invitation"} value={2} />
          <Tab label="setting" value={3} />

          <div style={{position: "absolute", right: 4, top: 4, textAlign: "right"}}>
            <PxButton backgroundcolor="purple" onClick={() => history.push(`${match.url}/add`)}>
              <ButtonTypo>{t("mailList.send")}</ButtonTypo>
            </PxButton>
          </div>
        </Tabs>
      </Paper>

      {renderTabContent()}
    </>
  );
};
export default MailTab;
