import SubEventActionTypes from "../types/actions/SubEventAction";
import SubEvent from "../types/models/SubEvent";

const subEventDefaultState: SubEvent = {
  subEvent: undefined,
  subEvents: [],
};

const subEventReducer = (
  state = subEventDefaultState,
  action: SubEventActionTypes
): SubEvent => {
  switch (action.type) {
    case "CREATE_SUB_EVENT":
    case "GET_SUB_EVENT":
      return { ...state, subEvent: action.subEvent };
    case "TOGGLE_MEETING_PURPOSE":
      return {...state, subEvent:{...state.subEvent!,meetingPurposeOpen:action.meetingPurposeOpen}}
    case "REMOVE_SUB_EVENT":
      return subEventDefaultState;
    case "GET_SUB_EVENTS":
      return { ...state, subEvents: action.subEvents };
    case "RESET_SUB_EVENTS":
      return { ...state, subEvents: [] };
    case "MODIFY_PAYMENT":
      return {
        ...state,
        subEvents: state.subEvents?.map((data) =>
          data.id === action.subEvent?.id
            ? { ...data, payment: action.subEvent?.payment }
            : { ...data }
        ),
      };
    case "MODIFY_MEETING_AVAILABLE":
      return {
        ...state,
        // subEvent: {
        //   ...state.subEvent!,
        //   meetingAvailable:
        //     state.subEvent?.meetingAvailable === "Y" ? "N" : "Y",
        // },
        subEvent: {
          ...state.subEvent!,
          meetingAvailable: action.subEvent.meetingAvailable
        }
      };
    case "MODIFY_COUNSEL_ONLYENG":
      return {
        ...state,
        subEvent: {
          ...state.subEvent!,
          counselFormOnlyEng:
          action.subEvent.counselFormOnlyEng
        }
      }
    default:
      return state;
  }
};

export default subEventReducer;
