import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Modal, Typography, Button, Paper, Divider} from "@material-ui/core";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 680,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "30px",
    borderRadius: "8px",
    outline: "none",
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(2),
    textAlign: "right",
  },
  text: {
    whiteSpace: "pre-line",
  },
}));

interface Props {
  openState?: boolean;
}

const MailRegistModal: React.FC<Props> = ({openState}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(openState != null ? openState : false);
  const modalText = "Email 인증 메일이 전송 되었습니다. \n 해당 이메일로 Amazon Web Service에서 전송된 인증 메일을 확인하여 인증을 해주세요.";

  const handleOpen = () => setOpen(open);
  const handleClose = () => setOpen(!open);

  return (
    <div>
      <Modal open={open} onClose={handleClose} className={classes.modal}>
        <Paper className={classes.paper}>
          <Typography variant="body1" className={classes.text}>
            {modalText}
          </Typography>
          <div className={classes.footer}>
            <PxButton onClick={handleClose} backgroundcolor="purple">
              <ButtonTypo>확인</ButtonTypo>
            </PxButton>
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

export default MailRegistModal;
