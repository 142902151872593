import React, {useState} from "react";
import {TextField, InputAdornment, IconButton} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

interface SearchBarProps {
  placeholder?: string;
  onSearch: (query: string) => void;
}

const RegisterSearchBar: React.FC<SearchBarProps> = ({placeholder = "이름,소속,이메일을 입력해주세요.", onSearch}) => {
  const [query, setQuery] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearch(query);
    }
  };

  const handleClear = () => {
    setQuery("");
    onSearch("");
  };

  const handleSearch = () => {
    onSearch(query);
  };

  return (
    <TextField
      variant="outlined"
      placeholder={placeholder}
      value={query}
      onChange={handleInputChange}
      onKeyPress={handleKeyDown}
      fullWidth
      InputProps={{
        style: {
          height: "40px",
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClear}>
              <ClearIcon />
            </IconButton>
            <IconButton onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default RegisterSearchBar;
