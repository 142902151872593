import { useContext } from "react";
import { RegisterInfoContext } from "./RegisterInfoProvider";

export function useRegisterInfoContext() {
    const context = useContext(RegisterInfoContext);

    if (!context) {
        throw new Error(
            "useRegisterInfoContext must be used within the RegisterInfoProvider"
        );
    }

    return context;
}