import React from "react";
import styled from "styled-components";

// material ui comp
import Typography, { TypographyProps } from "@material-ui/core/Typography";

interface CaptionTypoProps {
  txtcolor?: "rgba(0,0,0,0.87)" | "purple" | "white" | "red" | "black0.38";
  margin?: string;
}

type Prop = CaptionTypoProps & TypographyProps;

const CaptionTypo: React.FC<Prop> = styled((props: Prop) => {
  const { txtcolor, margin, ...rest } = props;
  return <Typography {...rest} />;
})`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  margin: ${(props) => (props.margin ? props.margin : "")};
  color: ${(props) => {
    if (props.txtcolor === "purple") {
      return "rgba(95, 75, 139, 1.0)";
    } else if (props.txtcolor === "red") {
      return "#FF0000";
    } else if (props.txtcolor === "black0.38") return "rgba(0,0,0,0.38)";
    return props.txtcolor;
  }};
`;

CaptionTypo.defaultProps = {
  txtcolor: "rgba(0,0,0,0.87)",
};

export default CaptionTypo;
