import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
// custom ui comp
import PxTable from "../../../../components/Table/PxTable";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import PxGridContainer from "../../../Grid/PxGridContainer";
import {Column, MTableToolbar} from "material-table";
import SendIcon from "@material-ui/icons/Send";

import moment from "moment";
import {setTableInfoLoadingAction} from "../../../../actions/tableInfo";
import {useTranslation} from "react-i18next";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {deleteTeamMember, getTeamMemberList, sendNoticeTeamMember, sendSmsTeamMember} from "../../../../actions/TeamManage";
import {Button, TextField, TextareaAutosize} from "@material-ui/core";
import {getMeetingList} from "../../../../actions/meeting";
import {MeetingInfo} from "../../../../types/models/Meeting";
import {meetingTimeSetting, TimeListProps} from "../../SubEventAdd";
import {currentCountryTime} from "../../../../utils/currentCountryTime";
import {ApplicationInfo, ApplicationMeetingInfo} from "../../../../types/models/Application";

import * as XLSX from "xlsx";
import uuid from "uuid";
import Alert from "../../../../types/models/Alert";
import {removeAlertAction, setAlertAction} from "../../../../actions/alerts";
import {getApplicationMeetingsForExcel, getApplicationsExcelData} from "../../../../actions/application";
import {AxiosResponse} from "axios";

interface RowType {
  memberId: number;
  memberEmail: string;
  memberName: string;
  memberPhoneNumber: string;
  organization: string;
  subEventId: number;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const ModalContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 40px;
  width: 75rem;
  height: 40rem;
  border-radius: 10px;
  z-index: 10;
  background-color: #fafafa;
  top: 20%;
  left: 50%;
  transform: translateX(-50%) translateY(-20%);
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.55);
  @media only screen and (max-width: 1300px) {
    width: 62.5rem;
  }
  @media only screen and (max-width: 1050px) {
    width: 50rem;
  }
`;
const NoticeExplainList = styled.ul`
  padding: 0 0 0 12px;
`;

const ModalBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const ModalTitle = styled.h1`
  font-size: 35px;
  font-weight: 700;
  align-self: center;
`;

const TeamManage: React.FC<RouteComponentProps> = ({match}) => {
  //   const NOTICE_TITLE = "미팅일정에 변동사항이 있습니다.";
  //   const NOTICE_CONTENT = "미팅 일정에 변경사항이 있습니다 확인 바랍니다.";
  const fileName = "미팅 시간표";
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const [t] = useTranslation("lang", {useSuspense: false});
  const dispatch = useDispatch();
  const history = useHistory();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const teamManage = useSelector((state: AppState) => state.teamManage);
  const {country, countries} = useSelector((state: AppState) => state.countries!);
  const [applications, setApplications] = useState<ApplicationInfo[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [noticeTitle, setNoticeTitle] = useState<string>();
  const [noticeContent, setNoticeContent] = useState<string>();

  const [data, setData] = useState<{
    // 엑셀 export data
    sellerData: any[];
    buyerData: any[];
    fileName: string;
    marges: {s: {r: number; c: number}; e: {r: number; c: number}}[];
  }>(null!);

  const [state, setState] = useState<TableStateType>({
    columns: [
      {
        title: "이름",
        field: "memberName",
      },
      {
        title: "이메일",
        field: "memberEmail",
      },
      {
        title: "연락처",
        field: "memberPhoneNumber",
      },
      {
        title: "소속",
        field: "organization",
      },
      {
        title: "",
        field: "memberId",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
        render: rowData => (
          <PxButton
            backgroundcolor="grey"
            style={{borderRadius: 20, padding: 5}}
            onClick={async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              event.stopPropagation();

              dispatch(deleteTeamMember(rowData.memberId, rowData.subEventId));
            }}
          >
            <ButtonTypo>삭제</ButtonTypo>
          </PxButton>
        ),
      },
    ],
    data: [],
  });

  useEffect(() => {
    if (subEvent !== undefined) {
      dispatch(getTeamMemberList(Number(subEvent?.id)));
      dispatch(setTableInfoLoadingAction(false));
      //   const NOTICE_TITLE = "미팅일정에 변동사항이 있습니다.";
      //   const NOTICE_CONTENT = "미팅 일정에 변경사항이 있습니다 확인 바랍니다.";
      setNoticeTitle(`[${subEvent.mainEvent?.name}] 미팅일정에 변동사항이 있습니다.`);
      setNoticeContent(`[${subEvent.mainEvent?.name}] 사무국 \n\n미팅 일정에 변경사항이 있습니다. \n등록된 이메일을 확인 바랍니다.`);
    }
  }, [subEvent]);

  useEffect(() => {
    let tableData: RowType[] = [];
    if (teamManage.teamMemberList.length !== 0) {
      teamManage.teamMemberList.map(listItem => {
        const rowData = {
          memberId: listItem.id,
          memberEmail: listItem.emailAddress,
          memberName: listItem.name,
          memberPhoneNumber: listItem.phoneNumber,
          organization: listItem.organization,
          subEventId: listItem.subEventId,
        };
        tableData.push(rowData);
      });
    }
    setState({...state, data: tableData});
  }, [teamManage]);

  const modalStateChange = () => {
    // if (openModal) {
    //   setNoticeTitle();
    //   setNoticeContent();
    // } else {
    //   // createMeetingTimeForm(subEvent?.id);
    // }
    setOpenModal(!openModal);
  };

  const sendMessage = async (type: string) => {
    let message = "";
    if (teamManage.teamMemberList.length < 1) {
      message = "알림을 받을 인원이 없습니다.";
    } else if (noticeTitle == "" || noticeContent == "") {
      message = "제목 또는 내용을 확인해주세요";
    } else {
      const result: any = await dispatch(getApplicationsExcelData(subEvent?.id as string));
      setApplications(result as ApplicationInfo[]);

      const refineData = teamManage.teamMemberList.map(item => ({
        [item.phoneNumber]: {
          name: item.name,
          companyName: "",
        },
      }));

      const bodyData = {
        subEventId: subEvent?.id,
        content: noticeContent,
        users: refineData,
      };

      const mailList = teamManage.teamMemberList.map(item => {
        return item.emailAddress;
      });

      if (type == "email") {
        createMeetingTimeForm(subEvent, mailList);
        message = "Email이 전송되었습니다.";
      } else {
        dispatch(sendSmsTeamMember(bodyData));
        message = "Sms가 전송되었습니다.";
      }
    }

    const alert: Alert = {
      alertType: "warning",
      id: uuid.v4(),
      msg: message,
    };

    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  };

  useEffect(() => {
    if (subEvent !== undefined) {
      callGetApplications(subEvent.id as string);
    }
  }, [subEvent]);

  const callGetApplications = async (subEventId: string) => {
    const result: any = await dispatch(getApplicationsExcelData(subEventId));
    setApplications(result as ApplicationInfo[]);
  };

  // 미팅 데이터 폼 생성
  const createMeetingTimeForm = useCallback(
    async (subEvent, mailList) => {
      const result = await getMeetingList(subEvent.id);

      const meetingData: MeetingInfo[] = result ?? [];

      // 미팅 시간 추가
      const TimeList: TimeListProps[] = meetingTimeSetting(subEvent);
      let meetingDataJson: Array<string> = [];

      // 서브 이벤트 기간 일수 차
      let subDateCount = moment(currentCountryTime(subEvent, "subEventEndDate", countries!, country!), "YYYY-MM-DD").diff(
        moment(currentCountryTime(subEvent, "subEventStartDate", countries!, country!), "YYYY-MM-DD"),
        "day"
      );

      // 미팅날찌 기준 값
      let subEventDate = moment(currentCountryTime(subEvent, "subEventStartDate", countries!, country!));

      // 날짜, 미팅시간 가공
      Array.from({length: subDateCount + 1}, (x, i) => {
        const subEventDateString: string = subEventDate.format("YYYY-MM-DD");
        meetingDataJson.push(subEventDateString);
        subEventDate = subEventDate.add(1, "days");
      });
      //셀병합(번호, 기업이름)
      let marges = [
        //s = 병합시작점 ,e : 병합개수, r:row,  c:cell
        {s: {r: 0, c: 0}, e: {r: 1, c: 0}},
        {s: {r: 0, c: 1}, e: {r: 1, c: 1}},
      ];
      let data = [];
      let refinedData: any = new Object();
      refinedData["번호"] = "";
      refinedData["기업이름"] = "";
      for (let i = 0; i < subDateCount + 1; i++) {
        //같은날짜 셀병합
        const marge = {
          s: {r: 0, c: 2 + i * TimeList.length},
          e: {r: 0, c: 2 + TimeList.length - 1 + i * TimeList.length},
        };
        marges.push(marge);
        // 미팅 날짜별, 시간 별 입력
        // 날짜 시간별 키값 ex) 첫번째 시간일때 refinedData[2020-02-10], 첫번째 시간이 아닐때 refinedData[2020-02-1010:30]
        for (let j = 0; j < TimeList.length; j++) {
          j === 0 ? (refinedData[`${meetingDataJson[i]} (GMT+9)`] = `${TimeList[j].startTime}`) : (refinedData[`${meetingDataJson[i]}` + `${TimeList[j].startTime}`] = TimeList[j].startTime);
        }
      }
      data.push(refinedData);
      let sellerData: any[] = [];
      let buyerData: any[] = [];

      if (applications) {
        const sellerResult = await addMeetingData(meetingData, TimeList, applications, "seller");
        sellerData = [...data, ...sellerResult];
        const buyerResult = await addMeetingData(meetingData, TimeList, applications, "buyer");
        buyerData = [...data, ...buyerResult];
      }
      setData({
        sellerData,
        buyerData,
        fileName: "미팅 시간표",
        marges: marges,
      });

      //날짜 셀렉트 박스 설정
      exportToCSV(sellerData, buyerData, subEvent?.sgroupName as string, subEvent?.bgroupName as string, mailList, noticeTitle, noticeContent);
    },
    [moment, subEvent, applications, noticeTitle, noticeContent]
  );

  function addMeetingData(meetingData: MeetingInfo[], TimeList: TimeListProps[], applications: ApplicationInfo[], groupType: string) {
    const result: any[] = applications
      .filter(data => data.type === groupType)
      .map((ad, i) => {
        let refinedData: any = new Object();
        refinedData["번호"] = i + 1;
        refinedData["기업이름"] = ad.name + `${ad.applicants ? `(${ad.applicants[0].businessCardId!.name})` : ""}`;

        //서로 매칭된 미팅 필터
        const user = meetingData.filter(md => (md.meetingAcceptor?.name === ad.name || md.meetingApplicant?.name === ad.name) && md.status === "agree");
        user.forEach((data, i) => {
          if (data.meetingApplicant && data.meetingAcceptor)
            TimeList[0].startTime === data.startTime
              ? //첫번째시간일때 (해당날 가장처음시작하는 시간)
                (refinedData[`${data.date} (GMT+9)`] =
                  //상대기업
                  data.meetingAcceptor.type !== groupType
                    ? data.meetingAcceptor.name + ` (${data.meetingAcceptor.managerName})` + "\n" + `+${data.meetingAcceptor.countryNumber ?? ""} ` + data.meetingAcceptor.applicants
                    : data.meetingApplicant.name + ` (${data.meetingApplicant.managerName})` + "\n" + `+${data.meetingApplicant.countryNumber} ` + data.meetingApplicant.applicants)
              : //첫번째시간 외 시간들
                (refinedData[`${data.date}${data.startTime!}`] =
                  //상대기업
                  data.meetingAcceptor.type !== groupType
                    ? data.meetingAcceptor.name + ` (${data.meetingAcceptor.managerName})` + "\n" + `+${data.meetingAcceptor.countryNumber} ` + data.meetingAcceptor.applicants
                    : data.meetingApplicant.name + ` (${data.meetingApplicant.managerName})` + "\n" + `+${data.meetingApplicant.countryNumber} ` + data.meetingApplicant.applicants);
        });
        return refinedData;
      });
    return result;
  }

  const exportToCSV = async (sellerData: any[], buyerData: any[], sellerName: string, buyerName: string, mailList?: string[], title?: string, content?: string) => {
    const sellerWs = XLSX.utils.json_to_sheet(sellerData);
    const buyerWs = XLSX.utils.json_to_sheet(buyerData);
    const pattern_sheetName = /[~!@\#$%<>?^&*\()\[\]\/\-|.{}=+_\’\s]+/g;
    const replaceSellerName = sellerName.replace(pattern_sheetName, "_");
    const replaceBuyerName = buyerName.replace(pattern_sheetName, "_");
    const wb = {
      Sheets: {
        [`${replaceSellerName}`]: {
          ...sellerWs,
          "!merges": [],
        },
        [`${replaceBuyerName}`]: {
          ...buyerWs,
          "!merges": [],
        },
      },
      SheetNames: [`${replaceSellerName}`, `${replaceBuyerName}`],
    };
    const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});

    const data = new Blob([excelBuffer], {type: fileType});

    const file = new File([data], fileName, {type: "xlsx"});

    return dispatch(sendNoticeTeamMember(mailList as string[], title as string, content as string, file));
  };

  return (
    <>
      <div style={{textAlign: "right", margin: "10px"}}>
        <PxButton backgroundcolor="purple" style={{marginRight: "10px"}} onClick={e => modalStateChange()}>
          <SendIcon style={{color: "white", marginRight: "2px"}} />
          <ButtonTypo>알림보내기</ButtonTypo>
        </PxButton>
        <PxButton backgroundcolor="purple" onClick={() => history.push(`${match.url}/add`)}>
          <ButtonTypo>{t("common.add")}</ButtonTypo>
        </PxButton>
      </div>

      <PxTable<RowType>
        components={{
          Toolbar: props => {
            return (
              <PxGridContainer direction="column">
                {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                <MTableToolbar {...props} />
              </PxGridContainer>
            );
          },
        }}
        columns={state.columns}
        data={state.data}
        onRowClick={(evt, selectedRow) => {
          // history.push(`${match.url}/${selectedRow?.interpreterId}`);
        }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
      />
      {openModal && (
        <>
          <ModalContainer>
            <ModalTitle>알림 전송</ModalTitle>
            <TextField label="제목" variant="outlined" value={noticeTitle} style={{backgroundColor: "white"}} onChange={e => setNoticeTitle(e.target.value)} />
            <TextareaAutosize rowsMin={10} value={noticeContent} onChange={e => setNoticeContent(e.target.value)} />
            <NoticeExplainList>
              <li>이메일은 미팅 시간표가 첨부되어 발송됩니다.</li>
              <li>팀원의 핸드폰으로 알림 문자가 발송됩니다.</li>
            </NoticeExplainList>
            <ModalBtnWrap>
              <PxButton backgroundcolor="purple" style={{marginRight: "20px"}} onClick={() => sendMessage("email")}>
                <ButtonTypo>Email</ButtonTypo>
              </PxButton>
              <PxButton backgroundcolor="purple" style={{marginRight: "20px"}} onClick={() => sendMessage("sms")}>
                <ButtonTypo>Sms</ButtonTypo>
              </PxButton>
              <PxButton backgroundcolor="purple" onClick={modalStateChange}>
                <ButtonTypo>취소</ButtonTypo>
              </PxButton>
            </ModalBtnWrap>
          </ModalContainer>
        </>
      )}
    </>
  );
};

export default TeamManage;
