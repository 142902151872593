import Box from "@material-ui/core/Box";
import React, { useEffect, useState } from "react";
import PxButton from "../../../../components/Buttons/PxButton";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import Body1Typo from "../../../../components/Typhography/Body1Typo";
import CaptionTypo from "../../../../components/Typhography/CaptionTypo";

import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { useTranslation } from "react-i18next";
import { postModifyWEbinarQA, postWebinarQA } from "../../../../actions/webinar";
import { WebinarSubmitQA } from "../../../../types/models/Webinar";
import { useParams } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBox from "@material-ui/icons/CheckBox";
import makeStyles from "@material-ui/core/styles/makeStyles";

const withStyle = makeStyles((theme) => ({
  checked: {
    padding: 6,
    // color: "black",
    "&$checked": {
      // color: "#673ab7",
    },
  },
  checkBoxSize: {
    width: 16,
    height: 16,
  },
  formControl: {
    marginRight: 25,
    marginBottom: 0,
  },
}));

const TextBox = styled.textarea`
  width: 100%;
  border: none;
  height: 70px;
  resize: none;
  margin-top: 5px;
  background-color: transparent;
  &::placeholder {
    color: #a09f9f;
  }
  &:focus {
    outline: none;
  }
`;

interface Props {
  questionId?: number;
  id?: number;
  pastText?: string;
  isPrivate?: "N" | "Y";
  setModifyToggle?: React.Dispatch<React.SetStateAction<boolean>>;
}

const WebinarTextAera = ({ questionId, id, pastText, setModifyToggle, isPrivate }: Props) => {
  const user = useSelector((state: AppState) => state.users);
  const { t } = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const classes = withStyle();
  const { secondId: webinarId } = useParams<{ secondId: string }>();
  const [webinarQA, setQA] = useState<WebinarSubmitQA>({
    id: id,
    userId: 0,
    webinarId: parseInt(webinarId),
    content: "",
    questionOrAnswer: questionId ? "A" : "Q",
    questionId: questionId,
    isPrivate: isPrivate || "N",
  });
  const [text, setText] = useState<string>(pastText ?? "");
  const [warning, setWarning] = useState("");

  useEffect(() => {
    if (user && user.id)
      setQA({ ...webinarQA, userId: parseInt(user.id), webinarId: parseInt(webinarId) });
  }, [user, webinarId]);

  useEffect(() => {
    setQA({
      ...webinarQA,
      webinarId: parseInt(webinarId),
      isPrivate: isPrivate,
      questionId: questionId,
      questionOrAnswer: questionId ? "A" : "Q",
    });
  }, [isPrivate, questionId]);

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (text.trim().length < 10) {
      setWarning(t("webinarDetail.contentVaildationTooShort"));
      return;
    } else if (!webinarId) {
      setWarning(t("contentVaildationNoneId"));
      return;
    }
    if (id && id > 0 && setModifyToggle !== undefined) {
      const result: any = await dispatch(
        postModifyWEbinarQA({
          ...webinarQA,
          content: text.trim().replace(/\n$/gm, ""),
        })
      );
      if (result) {
        setModifyToggle(false);
        setText("");
      }
    } else {
      const result: any = await dispatch(
        postWebinarQA({
          ...webinarQA,
          content: text.trim().replace(/\n$/gm, ""),
          questionId,
        })
      );
      if (result) {
        setText("");
        if (setModifyToggle !== undefined) {
          setModifyToggle(false);
        }
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    if (value.length > 1000) {
      setWarning(t("webinarDetail.contentVaildationTooLong"));
    } else {
      setWarning("");
      setText(value);
    }
  };
  return (
    <Box border="1px solid black" padding="10px">
      <Body1Typo fontWeight="600">{`${user.name}(${user.company})`}</Body1Typo>
      <TextBox
        placeholder={t("webinarDetail.contentPlaceholder")}
        onChange={handleChange}
        value={text}
      />
      <PxGridContainer alignItems="flex-end" justify="space-between">
        <Box display="flex" alignItems="center">
          <CaptionTypo>{text.length}/1000</CaptionTypo>

          <Body1Typo marginLeft="5px" txtcolor={warning == "" ? "white" : "red"}>
            {warning}
          </Body1Typo>
        </Box>
        <Box>
          <FormControlLabel
            className={classes.formControl}
            control={
              <Checkbox
                size="small"
                className={classes.checked}
                color="primary"
                name="private"
                disabled={webinarQA.questionOrAnswer === "A"}
                checked={webinarQA.isPrivate === "Y"}
                onChange={() => {
                  if (webinarQA.questionOrAnswer !== "A") {
                    setQA({
                      ...webinarQA,
                      isPrivate: webinarQA.isPrivate === "Y" ? "N" : "Y",
                    });
                  }
                }}
                icon={<CheckBoxOutlineBlank className={classes.checkBoxSize} />}
                checkedIcon={<CheckBox className={classes.checkBoxSize} />}
              />
            }
            label={<Body1Typo> {t("webinarDetail.writeInSecret")}</Body1Typo>}
          />
          {id && id > 0 && setModifyToggle !== undefined ? (
            <>
              <PxButton backgroundcolor="pale-grey" boxshadow={false} onClick={onSubmit}>
                {t("common.modify")}
              </PxButton>
              <PxButton
                boxshadow={false}
                padding="3px"
                hover={false}
                onClick={() => setModifyToggle(false)}
              >
                {t("common.cancel")}
              </PxButton>
            </>
          ) : (
            <PxButton backgroundcolor="pale-grey" boxshadow={false} onClick={onSubmit}>
              {t("webinarDetail.submit")}
            </PxButton>
          )}
        </Box>
      </PxGridContainer>
    </Box>
  );
};

export default WebinarTextAera;
