export default interface Event {
  event?: EventInfo;
  events?: EventPagination;
}

export interface EventInfo {
  id?: number;
  approval?: string;
  name: string; // 이벤트 이름
  nameEn?: string; // 이벤트 이름 (영문)
  startDate: string; // 이벤트 기간
  endDate: string; // 이벤트 기간
  image: string; // 이벤트 이미지
  countryCode?: string; // 주최지
  locationCheck: boolean; // 이벤트 장소 여부 체크 (등록수정에서만 사용)
  location: string; // 이벤트 장소
  locationDetail: string; // 이벤트 장소 상세
  lat?: string; // 위도
  lng?: string; // 경도
  content: string; // 이벤트 개요
  contentEn?: string; // 이벤트 개요(영문)
  categories?: eventCategory["category"];
  homePageCheck: boolean; // 이벤트 웹사이트 체크
  homePage: string; // 이벤트 웹사이트
  atchFile?: Array<any> | undefined; // 첨부파일
  atchFile1?: number;
  atchFile2?: number;
  atchFile3?: number;
  atchFile4?: number;
  atchFile5?: number;
  mainImageFile?: any;
  mainImage?: number;
  logoImage?: number;
  logoImageFile?: any;
  bannerImageFile?: any;
  bannerImage?: number;
  digitalMap?: string; // 디지털맵
  managers?: Array<{
    id: string;
    name: string;
    email: string;
    phone: string;
    countryNumber: string;
  }>; // 담당자
  createdDate?: string; // 최초등록일
  modifiedDate?: string; // 최종수정일
  payment?: string; // 결제여부
  footer?: Array<FooterItem>
}

export interface FooterItem{
    type: FooterType;
    content: string;
    link: string;
  imgUrl?: string;
  hide?: boolean | "PUBLIC" | "PRIVATE";
}

export type FooterType = "WEB_PAGE" | "MANUAL" | "BLOG" | "FACEBOOK" |
  "INSTAGRAM" | "LINKEDIN" | "TERM" | "PRIVACY_POLICY" | "EMAIL_INQUIRY" | "COPYRIGHT";

export interface EventPagination {
  content: EventInfo[];
  pageable?: any;
  last?: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export interface ManualCumstom {
  text: string;
  url: string;
  hide: boolean;
}

// export interface SocialLinkCustom {
//   blog: string;
//   facebook: string;
//   instagram: string;
//   linkedin: string;
// }

export interface SocialLinkCustom{
  blog: { link: string, hide: boolean };
  facebook: { link: string, hide: boolean };
  instagram: { link: string, hide: boolean };
  linkedin: { link: string, hide: boolean };
  [key: string]: { link: string; hide: boolean } | undefined;
}

export interface EtcLinkCustom {
  term: string;
  privacy_policy: string;
  email_inquiry: string;
}

type eventCategory = {
  category:
    | "B2B"
    | "B2"
    | "machinery"
    | "medical"
    | "power plan"
    | "startup"
    | "employment"
    | "investment"
    | "environment"
    | "association"
    | "society"
    | "food"
    | "beauty"
    | "entrepreneur"
    | "IT"
    | "textile"
    | "MICE"
    | "tourism"
    | "education"
    | "etc";
  category_ko:
    | "B2B"
    | "B2C"
    | "기계 "
    | "의료"
    | "발전소"
    | "창업"
    | "취업"
    | "투자"
    | "환경"
    | "협회"
    | "사회"
    | "식품"
    | "뷰티"
    | "기업"
    | "정보기술"
    | "섬유"
    | "마이스"
    | "관광"
    | "교육"
    | "etc";
};

export const eventCategoryArray = [
  "B2B",
  "B2C",
  "machinery",
  "medical",
  "power plan",
  "startup",
  "employment",
  "investment",
  "environment",
  "association",
  "society",
  "food",
  "beauty",
  "entrepreneur",
  "IT",
  "textile",
  "MICE",
  "tourism",
  "education",
  "etc",
];



