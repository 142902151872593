import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { AppState } from "../../../store";
import moment from "moment";
import { currentCountryTime } from "../../../utils/currentCountryTime";
import { useTranslation } from "react-i18next";

const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  height: 50px; //36px;
  background-color: #e9ecef;
`;

const dateFormat = (date: string) => {
  return moment(date).format("YYYY-MM-DD");
};

const Header = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const { countries, country } = useSelector((state: AppState) => state.countries);
  return subEvent !== undefined && subEvent.mainEvent !== undefined && subEvent.id !== "" ? (
    <HeaderBar>
      <div>
        {subEvent.mainEvent.name}
        <br />
        {subEvent.category === "presentation" ? "Conference" : subEvent.category}{" "}
        {/* presentation : Conference 모두 변경 / 21.04.19 */}
      </div>
      <div style={{ textAlign: "right" }}>
        {t("subEventHeader.mainPeriod")}:{" "}
        {dateFormat(currentCountryTime(subEvent, "mainStartDate", countries!, country!))} ~{" "}
        {dateFormat(currentCountryTime(subEvent, "mainEndDate", countries!, country!))}
        <br />
        {t("subEventHeader.subPeriod")}:{" "}
        {dateFormat(currentCountryTime(subEvent, "subEventStartDate", countries!, country!))} ~{" "}
        {dateFormat(currentCountryTime(subEvent, "subEventEndDate", countries!, country!))}
      </div>
    </HeaderBar>
  ) : null;
};

export default Header;
