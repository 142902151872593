import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import moment from "moment";
import { AppState } from "../../store";
import PxButton from "../../components/Buttons/PxButton";
import { useHistory, useParams } from "react-router-dom";
import {
  //   getPrinterSubEvent,
  setOnSiteRegitAction,
} from "../../actions/printers";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import Box from "@material-ui/core/Box";
import { printLabel } from "../../actions/deskPrint";
import fscreen from "fscreen";
import FormControl from "@material-ui/core/FormControl";
import PxSelectBox from "../../components/SelectBox/PxSelectBox";
import MenuItem from "@material-ui/core/MenuItem";
import HeadlineTypo from "../../components/Typhography/HeadlineTypo";
import SubHeadingTypo from "../../components/Typhography/SubHeadingTypo";
import TitleTypo from "../../components/Typhography/TitleTypo";
import { getSubEvent } from "../../actions/subEvents";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    "& .item": {
      flex: 1,
      "& p": {
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
        textAlign: "center",
      },
    },
    "& .item2": {
      flex: 1,
      width: "100%",
      marginBottom: 20,
      border: "1px solid",
      borderColor: "#bdbdbd",
      backgroundColor: "#f5f5f5",
      textAlign: "center",
      "& button": {
        "&:hover": {
          backgroundColor: "#5f4b8b",
          borderColor: "#5f4b8b",
          boxShadow: "none",
        },
      },
      "& .MuiGrid-container": {
        position: "relative",
        top: "20%",
      },
    },
    "& .button": {
      flex: 1,
      marginBottom: 20,
      "& .MuiButton-label": {
        justifyContent: "center",
      },
      "& p": {
        fontSize: 30,
        position: "relative",
        top: "50%",
        textAlign: "center",
      },
    },
    paddingTop: 15,
  },
  formControl: {
    backgroundColor: "white",
    margin: "0px 90px",
    // margin: theme.spacing(1),
    // minWidth: 120,
    // width: 60,
  },
}));

const PrinterEventTest = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  //   const subEvent = useSelector((state: AppState) => state.printers.subEvent);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const printer = useSelector((state: AppState) => state.printers.printer);
  const onSiteRegit = useSelector(
    (state: AppState) => state.printers.onSiteRegit
  );
  const [selectedType, setSelectedType] = useState<number>(0); // 현장등록 유무 0: 없음, 1: 있음

  const printTest = () => {
    if (printer) {
      printLabel({
        applicantId: 0,
        eventName: "TEST EVENT",
        companyName: "Test",
        name: "Test",
        position: "Test",
        type: "Type",
        dispatch,
        ipAddress: printer.ipAddress,
        printerName: printer.name,
      });
    }
  };

  const onRegitTargetOnChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    dispatch(
      setOnSiteRegitAction(event.target.value as "seller" | "buyer" | "none")
    );
  };

  return (
    <div className={classes.root}>
      <div className="item">
        <HeadlineTypo>{subEvent?.mainEvent?.name ?? ""} </HeadlineTypo>
        <SubHeadingTypo txtcolor="secondary">
          {subEvent
            ? `${moment(subEvent.subEventStartDate).format(
                "YYYY.MM.DD"
              )} - ${moment(subEvent.subEventEndDate).format("YYYY.MM.DD")}`
            : ""}
        </SubHeadingTypo>
      </div>
      <div className="item2">
        <PxGridContainer direction="column">
          <TitleTypo>현장등록 대상을 선택하세요.</TitleTypo>
          <Box height={25} />
          <FormControl className={classes.formControl} variant="outlined">
            <PxSelectBox value={onSiteRegit} onChange={onRegitTargetOnChange}>
              <MenuItem value="none">없음</MenuItem>
              <MenuItem value="seller">{subEvent?.sgroupName}</MenuItem>
              <MenuItem value="buyer">{subEvent?.bgroupName}</MenuItem>
            </PxSelectBox>
          </FormControl>
        </PxGridContainer>
      </div>
      <PxButton
        fullWidth
        className="button"
        backgroundcolor="purple"
        onClick={() => {
          fscreen.requestFullscreen(document.documentElement);
          history.replace({
            pathname: `/printer/${params.id}/print`,
            state: { onSiteRegit: selectedType },
          });
        }}
      >
        <HeadlineTypo>발급화면 바로가기</HeadlineTypo>
      </PxButton>
      <PxButton
        fullWidth
        className="button"
        backgroundcolor="blueGrey"
        onClick={printTest}
      >
        <HeadlineTypo>프린터기 인쇄 테스트</HeadlineTypo>
      </PxButton>
    </div>
  );
};

export default PrinterEventTest;
