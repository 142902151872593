import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// actiontypes
import UserActionTypes from "./types/actions/UserAction";
import AlertActionTypes from "./types/actions/AlertAction";
import ProgressActionTypes from "./types/actions/ProgressAction";
import DialogActionTypes from "./types/actions/DialogAction";
import EventActionTypes from "./types/actions/EventAction";
import SubEventActionTypes from "./types/actions/SubEventAction";
import FileActionTypes from "./types/actions/FileAction";
import FormActionTypes from "./types/actions/FormAction";
import FormAnswerActionType from "./types/actions/FormAnswerAction";
import ApplicationActionTypes from "./types/actions/ApplicationAction";
import MeetingActionTypes from "./types/actions/MeetingAction";
import MeetingHistoryActionTypes from "./types/actions/MeetingHistoryAction";
import SpeakerActionTypes from "./types/actions/SpeakerAction";
import ProgramActionTypes from "./types/actions/ProgramAction";
import NoticeActionTypes from "./types/actions/NoticeAction";
import MailActionTypes from "./types/actions/MailAction";
import CountryActionTypes from "./types/actions/CountryAction";
import CounselActionTypes from "./types/actions/CounselAction";

// reducer
import userReducer from "./reducers/users";
import alertReducer from "./reducers/alerts";
import progressReducer from "./reducers/progresses";
import dialogReducer from "./reducers/dialogs";
import eventReducer from "./reducers/events";
import subEventReducer from "./reducers/subEvents";
import fileReducer from "./reducers/files";
import formReducer from "./reducers/forms";
import formAnswerReducer from "./reducers/formAnswers";
import applicationReducer from "./reducers/applications";
import meetingReducer from "./reducers/meetings";
import meetingHistoryReducer from "./reducers/meetingHistories";
import speakerReducer from "./reducers/speakers";
import programReducer from "./reducers/programs";
import noticeReducer from "./reducers/notices";
import mailReducer from "./reducers/mails";
import countryReducer from "./reducers/countries";
import counselReducer from "./reducers/counsels";
import smssReducer from "./reducers/smss";
import InterpreterActionTypes from "./types/actions/InterpreterAction";
import interpreterReducer from "./reducers/interpreters";
import loadingReducer from "./reducers/loadings";
import LoadingActionTypes from "./types/actions/LoadingAction";
import tableInfoReducer from "./reducers/tableInfo";
import TableInfoActionTypes from "./types/actions/TableInfoAction";
import SmsActionTypes from "./types/actions/SmsAction";
import webinarReducer from "./reducers/webinars";
import WebinarActionTypes from "./types/actions/WebinarAction";
import applicantsReducer from "./reducers/applicants";
import ApplicantsActionTypes from "./types/actions/ApplicantsAction";
import PrinterSubEventActionTypes from "./types/actions/PrinterAction";
import printerReducer from "./reducers/printers";
import TeamManageActionType from "./types/actions/TeamManageAction";
import teamManageReducer from "./reducers/teamManage";
import ProductActionTypes from "./types/actions/ProductAction";
import productReducer from "./reducers/product";
import routePathReducer from "./reducers/routePath";
import RoutePathActionTypes from "./types/actions/RoutePathAction";
import TemplateActionType from "./types/actions/TemplateAction";
import templateReducer from "./reducers/Template";
import RegistrationActionTypes from "./types/actions/Registration";
import registrationReducer from "./reducers/registration";


const initialState = {};

export const rootReducer = combineReducers({
  users: userReducer,
  alerts: alertReducer,
  progresses: progressReducer,
  dialogs: dialogReducer,
  events: eventReducer,
  subEvents: subEventReducer,
  files: fileReducer,
  forms: formReducer,
  formAnswers: formAnswerReducer,
  applications: applicationReducer,
  meetings: meetingReducer,
  meetingHistories: meetingHistoryReducer,
  speakers: speakerReducer,
  programs: programReducer,
  notices: noticeReducer,
  mails: mailReducer,
  countries: countryReducer,
  counsels: counselReducer,
  interpreters: interpreterReducer,
  loadings: loadingReducer,
  tableInfo: tableInfoReducer,
  smss: smssReducer,
  webinars: webinarReducer,
  applicants: applicantsReducer,
  printers: printerReducer,
  teamManage: teamManageReducer,
  product: productReducer,
  template: templateReducer,
  registration: registrationReducer,
  routePath: routePathReducer,
});

const middleWare = [thunk as ThunkMiddleware<AppState, AppActions>];

export type AppState = ReturnType<typeof rootReducer>;
export type AppActions =
  | UserActionTypes
  | AlertActionTypes
  | ProgressActionTypes
  | DialogActionTypes
  | EventActionTypes
  | SubEventActionTypes
  | FileActionTypes
  | FormActionTypes
  | FormAnswerActionType
  | ApplicationActionTypes
  | MeetingActionTypes
  | MeetingHistoryActionTypes
  | SpeakerActionTypes
  | ProgramActionTypes
  | NoticeActionTypes
  | MailActionTypes
  | CountryActionTypes
  | CounselActionTypes
  | InterpreterActionTypes
  | LoadingActionTypes
  | TableInfoActionTypes
  | SmsActionTypes
  | WebinarActionTypes
  | ApplicantsActionTypes
  | TeamManageActionType
  | ProductActionTypes
  | RoutePathActionTypes
  | TemplateActionType
  | RegistrationActionTypes
  | PrinterSubEventActionTypes;

export const store = createStore(
  rootReducer,
  initialState,
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middleWare)
    : composeWithDevTools(applyMiddleware(...middleWare))
);

export default store;
