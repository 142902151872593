import { Dispatch } from "react";
import { AppActions } from "../store";
import { RoutePath } from "../types/models/RoutePath";


export const setRoutePathAction = (routePath: RoutePath): AppActions => ({
    type: "SET_ROUTE_PATH",
    routePath: routePath
});

export const setRoutePath = (routePath: RoutePath) =>
    (dispatch: Dispatch<AppActions>) => {
    dispatch(setRoutePathAction(routePath));
}