import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppState } from "../../../../store";

import MeetingTimeCard from "./MeetingTimeCard";

import styled from "styled-components";
import { MeetingData, MeetingTimeInfo } from "../../../../types/models/SubEvent";
import { Grid, InputLabel, MenuItem } from "@material-ui/core";
import PxSelectBox from "../../../../components/SelectBox/PxSelectBox";
import PxOutlinedFormControl from "../../../../components/Forms/PxOutlinedFormControl";
import { parallaxCalculation } from "../../../../utils/momentTz";
import { useTranslation } from "react-i18next";
import { CountryApiInfo, CountryInfo } from "../../../../types/models/Country";
import moment from "moment";

const MeetingForm = styled.div`
  width: 100%;
  overflow: scroll;
  display: grid;
  grid-column-gap: 16px;
  grid-auto-flow: column;
`;
const MeetingFormData = styled.div`
  width: 189px;
  height: 24px;
  font-size: 15px;
  letter-spacing: -0.4px;
  color: rgba(0, 0, 0, 0.6);
`;
const MeetingFormTime = styled.div`
  display: grid;
  grid-gap: 8px;
`;

const MeetingTimeForm = ({ date }: { date: moment.Moment }) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  // const meeting = useSelector((state: AppState) => state.meetings.meeting);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [meetingData, setMeetingData] = useState<MeetingData[]>();

  const [meetingStatus, setMeetingStatus] = useState(""); // 대기: waiting, 동의: agree, 불가시간: impossible
  const { country, countries } = useSelector((state: AppState) => state.countries!);

  useEffect(() => {
    if (subEvent !== undefined && subEvent.meetingData !== "") {
      const countryCode = subEvent.mainEvent?.countryCode;
      const subEventStartDate = parallaxCalculation(
        `${subEvent.subEventStartDate.split("T")[0]}T${subEvent.matchStartTime}`,
        countries![countryCode! as string] as any,
        countries![country!.countryCode!] as any
      );
      //현지시간
      let localStartOfWeek = date.clone().startOf("week").isSameOrAfter(subEventStartDate)
        ? date.clone().startOf("week")
        : moment(subEventStartDate); // subEventStartDate(현지날짜 변경되어야함) 보다 전날이면 안됨
      const endOfWeek = date.clone().endOf("week").isSameOrBefore(subEvent.subEventEndDate)
        ? date.clone().endOf("week")
        : moment(subEvent.subEventEndDate); // subEventEndDate(현지날짜 변경되엉야함) 보다 이후면 안됨

      //서버시간
      let serverTimeStartOfWeek = moment(
        parallaxCalculation(
          localStartOfWeek.format("YYYY-MM-DDTHH:ss"),
          countries![country!.countryCode!] as any,
          ""
        )
      );
      // 나라별 시간을 따로 보여주기위해 showDate, showStartTime, showEndTime 추가
      let newTimeMeeting: MeetingData = {};
      let newTimeMeetingArr: MeetingData[] = [];
      // 최대 7일치 만들기
      for (
        let d =
          endOfWeek.diff(localStartOfWeek, "days") + 1 >= 8
            ? 7
            : endOfWeek.diff(localStartOfWeek, "days") + 1;
        d > 0;
        d--
      ) {
        const newTImes = [
          ...timeArrGenerator(
            subEvent.subEventStartDate,
            subEvent.subEventEndDate,
            serverTimeStartOfWeek.add(-1, "days").format("YYYY-MM-DD"),
            subEvent.matchStartTime!,
            subEvent.matchReqTime!,
            subEvent.matchBreakTime!,
            subEvent.matchNumPerDay!,
            countries!,
            countryCode!,
            country!,
            subEvent.impossibleDateAndTime
          ),
          ...timeArrGenerator(
            subEvent.subEventStartDate,
            subEvent.subEventEndDate,
            serverTimeStartOfWeek.add(1, "days").format("YYYY-MM-DD"),
            subEvent.matchStartTime!,
            subEvent.matchReqTime!,
            subEvent.matchBreakTime!,
            subEvent.matchNumPerDay!,
            countries!,
            countryCode!,
            country!,
            subEvent.impossibleDateAndTime
          ),
          ...timeArrGenerator(
            subEvent.subEventStartDate,
            subEvent.subEventEndDate,
            serverTimeStartOfWeek.add(1, "days").format("YYYY-MM-DD"),
            subEvent.matchStartTime!,
            subEvent.matchReqTime!,
            subEvent.matchBreakTime!,
            subEvent.matchNumPerDay!,
            countries!,
            countryCode!,
            country!,
            subEvent.impossibleDateAndTime
          ),
        ];

        newTimeMeeting = {
          date: serverTimeStartOfWeek.add(-1, "days").format("YYYY-MM-DD"), //한국시간
          showDate: localStartOfWeek.format("YYYY-MM-DD"), // 현지시간
          time: newTImes.filter((d) => d.showDate == localStartOfWeek.format("YYYY-MM-DD")),
        };
        localStartOfWeek.add(1, "days");
        serverTimeStartOfWeek.add(1, "days");
        newTimeMeetingArr.push(newTimeMeeting);
      }
      setMeetingData(newTimeMeetingArr);

      // setMeetingData(newMeetings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return meetingData !== undefined ? (
    <>
      {/* 미팅 상태 필터 */}
      <div style={{ marginBottom: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PxOutlinedFormControl fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">{t("common.status")}</InputLabel>
              <PxSelectBox
                name="category"
                label={t("common.status")}
                value={meetingStatus}
                // value={filter.category || ""}
                onChange={(e: any) => setMeetingStatus(e.target.value)}
                displayEmpty
              >
                <MenuItem value={""}>{t("common.status")}</MenuItem>
                <MenuItem value={"waitingApplicant"}>{t("meetingDetail.requesting")}</MenuItem>
                <MenuItem value={"waitingAcceptor"}>{t("meetingDetail.awaiting")}</MenuItem>
                <MenuItem value={"agree"}>{t("meetingDetail.meetingConcluded")}</MenuItem>
                <MenuItem value={"impossible"}>{t("meetingDetail.setUnavailable")}</MenuItem>
              </PxSelectBox>
            </PxOutlinedFormControl>
          </Grid>
        </Grid>
      </div>

      <MeetingForm>
        {Array.isArray(meetingData) &&
          meetingData.map((meeting: any, dateIndex: number) => (
            <div key={`metting-card-${dateIndex}`}>
              <MeetingFormData>{meeting.showDate}</MeetingFormData>
              <MeetingFormTime>
                {meeting.time.map((tm: any, timeIndex: number) => (
                  <>
                    <MeetingTimeCard
                      key={`metting-time-card-${timeIndex}`}
                      id={`${tm.date}${tm.startTime}${tm.endTime}`}
                      date={tm.date}
                      startTime={tm.startTime}
                      endTime={tm.endTime}
                      showDate={meeting.showDate}
                      showStartTime={tm.showStartTime}
                      showEndTime={tm.showEndTime}
                      hostCountry={countries![subEvent!.mainEvent!.countryCode!].koName!}
                      showHostMeetingTime={tm.showHostMeetingTime}
                      impossibleAt={tm.impossibleAt}
                      dateIndex={dateIndex}
                      timeIndex={timeIndex}
                      meetingStatus={meetingStatus}
                    />
                  </>
                ))}
              </MeetingFormTime>
            </div>
          ))}
      </MeetingForm>
    </>
  ) : null;
};

export default MeetingTimeForm;

function timeArrGenerator(
  subEventStartDate: string,
  subEventEndDate: string,
  startDate: string,
  startTime: string,
  reqTime: number,
  breakTime: number,
  numPerDay: number,
  countries: CountryInfo,
  countryCode: string,
  country: CountryApiInfo,
  impossibleDateAndTime: string | undefined
): MeetingTimeInfo[] {
  // timecard 만들어주는 함수 -----
  // 서브이벤트 시작날
  // 서브이벤트 시작시간
  // 서브이벤트 끝나는날
  // 미팅 소요시간
  // 미팅 쉬는시간
  // 미팅 횟수
  // --------------------------
  // subEvent , countrycode 변경될때마다 업데이트

  let meetingTimeList: MeetingTimeInfo[] = []; // 필요데이터 {시작시간, 종료시간, 불가여부}
  // 날짜별 매칭시간 기준 값
  let impossibleStartTime = moment(`${startDate.split("T")[0]} ${startTime}`);

  if (impossibleStartTime.isBefore(subEventStartDate.split("T")[0])) return meetingTimeList;
  if (impossibleStartTime.isAfter(`${subEventEndDate.split("T")[0]}T${startTime}`))
    return meetingTimeList;
  const disabledDateAndTime = impossibleDateAndTime ? JSON.parse(impossibleDateAndTime) : {}; //{년 : {월 : [day_time]}}
  let localStartTime = moment(
    parallaxCalculation(
      impossibleStartTime.format("YYYY-MM-DDTHH:mm:ss"),
      countries![countryCode as string] as any,
      countries![country!.countryCode!] as any
    )
  );
  // 날짜별 매칭시간 조회 (시작시간, 종료시간, 불가여부)
  Array.from({ length: numPerDay || 0 }, (x, i) => {
    meetingTimeList.push({
      startTime: impossibleStartTime.format("HH:mm"),
      impossibleAt: checkDisabledTime(impossibleStartTime, disabledDateAndTime), // 주최자 지정 불가시간
      endTime: impossibleStartTime.add(reqTime, "m").format("HH:mm"),
      date: impossibleStartTime.format("YYYY-MM-DD"),
      showDate: localStartTime.format("YYYY-MM-DD"),
      showStartTime: localStartTime.format("HH:mm"),
      showEndTime: localStartTime.add(reqTime, "m").format("HH:mm"),
    });
    localStartTime.add(breakTime, "m");
    impossibleStartTime = impossibleStartTime.add(breakTime, "m");
  });

  return meetingTimeList;
}

function checkDisabledTime(serverTime: moment.Moment, disabledDateAndTime: any): "N" | "Y" {
  const [year, month, day] = serverTime.format("YYYY-MM-DD").split("-");
  if (
    disabledDateAndTime.hasOwnProperty(year) &&
    disabledDateAndTime[year].hasOwnProperty(month) &&
    disabledDateAndTime[year][month].length > 0 &&
    disabledDateAndTime[year][month].some(
      (d: string) => d == `${day}_${serverTime.format("HH:mm")}`
    )
  ) {
    return "Y";
  }
  return "N";
}

// let parsingData = subEvent.meetingData!;
//       const array: MeetingData[] = JSON.parse(parsingData);

//       // 나라별 시간을 따로 보여주기위해 showDate, showStartTime, showEndTime 추가
//       let newMeetings: MeetingData[] = [];
//       let prevDate: string; // 기존 날짜
//       let nextDate: string; // 변경 후 날짜

//       let newMeeting: MeetingData = {};
//       let newMeetinTimes: MeetingTimeInfo[] = [];

//       array.map((meetings: MeetingData, index: number) => {
//         meetings!.time!.map((time: MeetingTimeInfo, idx) => {
//           // 주최지 시간
//           const hostMeetingTime = `${meetings.date} ${time.startTime} ~ ${time.endTime}`;

//           // 국가에 맞는 시작시간 조회
//           const countryStartTime = parallaxCalculation(
//             `${meetings.date}T${time.startTime}`,
//             countries![countryCode! as string] as any,
//             countries![country!.countryCode!] as any,
//             "YYYY-MM-DD HH:mm"
//           );

//           // 국가에 맞는 종료시간 조회
//           const countryEndTime = parallaxCalculation(
//             `${meetings.date}T${time.endTime}`,
//             countries![countryCode! as string] as any,
//             countries![country!.countryCode!] as any,
//             "YYYY-MM-DD HH:mm"
//           );

//           const splitStartTime = countryStartTime.split(" ");
//           const splitEndTime = countryEndTime.split(" ");

//           // 국가에맞는 새로운 시간 저장
//           let newMeetinTime: MeetingTimeInfo = time;

//           Object.assign(newMeetinTime, {
//             ...newMeetinTime,
//             showStartTime: splitStartTime[1],
//             showEndTime: splitEndTime[1],
//             showHostMeetingTime: hostMeetingTime,
//             date: meetings.date,
//           });

//           // 처음일경우 시간지정
//           if (prevDate === undefined) {
//             prevDate = splitStartTime[0];

//             newMeeting = {
//               date: meetings.date,
//               showDate: prevDate,
//               time: newMeetinTimes,
//             };
//           }

//           nextDate = splitStartTime[0];
//           if (prevDate !== nextDate) {
//             Object.assign(newMeeting, { time: newMeetinTimes });

//             newMeetings.push(newMeeting);

//             newMeetinTimes = [newMeetinTime];

//             newMeeting = {
//               date: meetings.date,
//               showDate: nextDate,
//               time: newMeetinTimes,
//             };

//             prevDate = nextDate;
//           } else {
//             newMeetinTimes.push(newMeetinTime);
//           }

//           if (array.length === index + 1 && meetings!.time!.length === idx + 1) {
//             Object.assign(newMeeting, { time: newMeetinTimes });

//             newMeetings.push(newMeeting);
//           }
//         });
//       });
