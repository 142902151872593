import Application from "../types/models/Application";
import ApplicationActionTypes from "../types/actions/ApplicationAction";

const applicationDefaultState: Application = {
  application: undefined,
  applications: {
    content: [],
    pageable: {},
    last: false,
    totalElements: 0,
    totalPages: 0,
    size: 1,
    number: 0,
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    numberOfElements: 1,
    first: true,
    empty: false,
  },
  applicationMeetings: {
    content: [],
    pageable: {},
    last: false,
    totalElements: 0,
    totalPages: 0,
    size: 1,
    number: 0,
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    numberOfElements: 1,
    first: true,
    empty: false,
  },
};

const applicationReducer = (
  state = applicationDefaultState,
  action: ApplicationActionTypes
): Application => {
  switch (action.type) {
    case "CREATE_APPLICATION":
    case "GET_APPLICATION":
      return { ...state, application: action.application };
    case "GET_APPLICATION_MEETINGS":
      return { ...state, applicationMeetings: action.applicationMeetings };
    case "GET_APPLICATIONS":
      return {
        ...state,
        applications: action.applications,
      };
    case "APPLICATION_RESET":
      return { ...state, application: undefined };
    case "UPDATE_APPLICATION_ANSWERS":
      return { ...state, application: action.application };
    case "GET_SELECTED_EVENT_APPLICATIONS":
      return { ...state, selectedEventApplications: action.applications };
    default:
      return state;
  }
};

export default applicationReducer;
