import React, { useEffect, useState, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AppState } from "../../../../store";
import throttle from "lodash/throttle";
import axios, { AxiosResponse } from "axios";

// types
import SubHeadingTypo from "../../../Typhography/SubHeadingTypo";
import { SubEvenPathContext } from "../../../../layouts/SubEvent";

// @material-ui
import Grid from "@material-ui/core/Grid";
import { TypographyProps } from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Autocomplete from "@material-ui/lab/Autocomplete";

// custom ui comp
import OutlinedTxtField from "../../../Inputs/OutlinedTxtField";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";

import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";

// icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  getInterpreter,
  putDeleteInterpreter,
  updateInterpreter,
} from "../../../../actions/interpreter";
import { Box } from "@material-ui/core";

// User type for autocomplete User list
interface UserType {
  id: string;
  emailId: string;
  email: string;
  name: string;
  phoneNumber: string;
}

// meeting type for autocomplete Meeting list
interface MeetingType {
  id: string;
  applicantName: string;
  acceptorName: string;
  meetingId: string;
  endTime: string;
  startTime: string;
}

// subtitle style
type TypoProp = TypographyProps;

const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const InterpreterModify = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const param: { secondId: string } = useParams();
  const { subEventId } = useContext(SubEvenPathContext);
  const { interpreter } = useSelector((state: AppState) => state.interpreters);

  // 선택된 유저id
  const [userInfo, setUserInfo] = useState<UserType>({
    id: "",
    emailId: "",
    phoneNumber: "",
    email: "",
    name: "",
  });

  // 선택된 미팅 id 리스트
  const [meetingInfo, setMeetingInfo] = useState<MeetingType[]>([]);

  // meeting search autocomplete input
  const [meetingInput, setMeetingInput] = useState<string>("");
  // 검색한 meeting 리스트
  const [meetingList, setMeetingList] = useState<MeetingType[]>([]);

  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    let name: string = e.target.name as string;
    let value: string = e.target.value as string;

    setUserInfo({ ...userInfo, [name]: value });
  };

  const handelMeetingInfoOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();
    setMeetingInput(e.target.value as string);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: any = {
      name: userInfo.name,
      email: userInfo.email,
      phoneNumber: userInfo.phoneNumber,
      meetingIds: meetingInfo,
      userId: userInfo.id,
      subEventId: subEventId,
    };

    const result: any = await dispatch(updateInterpreter(data));
    if (result) {
      history.goBack();
    }
  };

  const deleteInterpreter = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const result: any = await dispatch(
      putDeleteInterpreter(parseInt(userInfo.id), parseInt(subEventId))
    );
    result && history.goBack();
  };

  const getMeetingList = useMemo(
    () =>
      throttle(async (input: string) => {
        const result = await axios.get("/api/meetings/interpreterMeetingList", {
          params: { subEventId: subEventId, companyName: input },
        });
        setMeetingList(result.data);
      }, 1500),
    [subEventId]
  );

  useEffect(() => {
    dispatch(getInterpreter(param.secondId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (interpreter !== undefined) {
      setUserInfo({
        id: interpreter.userId.id ?? "",
        emailId: interpreter.userId.email,
        phoneNumber: interpreter.phoneNumber,
        email: interpreter.email,
        name: interpreter.name,
      });
      if (interpreter.meetingIds !== undefined && interpreter.meetingIds?.length !== 0) {
        setMeetingInfo(interpreter.meetingIds as any);
      }
    }
  }, [interpreter]);

  useEffect(() => {
    if (meetingInput.length > 2) {
      getMeetingList(meetingInput);
    }
  }, [meetingInput, getMeetingList]);

  return (
    <Grid container>
      <Grid direction="row" justify="space-between" style={{ display: "flex" }} item md={12}>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
        <Box display="flex" alignContent="center">
          <PxButton onClick={deleteInterpreter}>
            <ButtonTypo>삭제</ButtonTypo>
          </PxButton>
        </Box>
      </Grid>
      <Grid item style={{ padding: "0 0 0 64px" }} md={12}>
        <form onSubmit={onSubmit}>
          <SubTitle>유저ID</SubTitle>
          <SubHeadingTypo>{userInfo.emailId}</SubHeadingTypo>

          <SubTitle>미팅 소속명(다중선택)</SubTitle>
          <Autocomplete
            multiple
            disableCloseOnSelect
            value={meetingInfo}
            options={meetingList}
            onChange={(event, value) => {
              setMeetingInfo([...value]);
            }}
            getOptionLabel={(option) =>
              `${option.acceptorName} / ${option.applicantName} / ${option.startTime} - ${option.endTime}`
            }
            getOptionSelected={(option, value) => {
              return option.id === value.id;
            }}
            renderOption={(option, { selected }) => {
              return (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.acceptorName} / {option.applicantName} / {option.startTime} -{" "}
                  {option.endTime}
                </React.Fragment>
              );
            }}
            renderInput={(params) => (
              <OutlinedTxtField
                {...params}
                name="meetingId"
                value={meetingInput}
                fullWidth
                onChange={handelMeetingInfoOnChange}
                placeholder={
                  meetingInfo.length === 0
                    ? "소속명을 입력 후 통역사를 지정할 미팅을 선택해주세요."
                    : ""
                }
                InputProps={{
                  ...params.InputProps,
                  style: { padding: 3 },
                }}
                inputProps={{
                  ...params.inputProps,
                  style: { paddingLeft: 10 },
                }}
              />
            )}
          />

          <SubTitle>이름</SubTitle>
          <OutlinedTxtField
            name="name"
            value={userInfo.name}
            fullWidth
            onChange={handleOnChange}
            placeholder="이름를 입력해주세요"
          />

          <SubTitle>휴대번호</SubTitle>
          <OutlinedTxtField
            name="phoneNumber"
            value={userInfo.phoneNumber}
            fullWidth
            onChange={handleOnChange}
            placeholder="휴대번호을 입력해주세요"
          />

          <SubTitle>이메일</SubTitle>
          <OutlinedTxtField
            name="email"
            value={userInfo.email}
            fullWidth
            onChange={handleOnChange}
            placeholder="이메일을 입력해주세요"
          />

          <Grid
            container
            alignContent="space-between"
            spacing={1}
            style={{ justifyContent: "flex-end", marginTop: "32px" }}
          >
            <Grid item>
              <PxButton backgroundcolor="purple" type="submit">
                <ButtonTypo>수정</ButtonTypo>
              </PxButton>
            </Grid>
            <Grid item>
              <PxButton
                backgroundcolor="grey"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ButtonTypo>취소</ButtonTypo>
              </PxButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default InterpreterModify;
