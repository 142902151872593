import React, {SetStateAction, useCallback, useEffect, useState} from "react";
import {Grid, Input} from "react-spreadsheet-grid";
import set from "lodash/set";
import {RegitUser} from "../../../../types/models/Registration";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {getForms} from "../../../../actions/forms";
import {FormData} from "../../../../types/models/AutonoForm";

interface SpreadSheetProps {
  rowData: {[key: string]: string}[];
  setRowData: React.Dispatch<SetStateAction<{[key: string]: string}[]>>;
  formData?: FormData;
}

const SpreadSheet = ({rowData, setRowData, formData}: SpreadSheetProps) => {
  const onFieldChange = useCallback(
    (rowId: string, field: string) => (value: string) => {
      const row: any = rowData.find(({phoneNum}) => phoneNum === rowId);
      set(row, field, value);
      setRowData(([] as {[key: string]: string}[]).concat(rowData));
    },
    [rowData, setRowData]
  );

  const initialColumns = useCallback(
    (formParam: FormData) => {
      const defaultColumn = [
        {
          id: "이름",
          title: "이름(name)",
          // width: 10,
          value: (row: {[key: string]: string}, {focus}: any) => {
            return <Input value={row[`이름`]} focus={focus} onChange={onFieldChange(row.phoneNum, "name")} />;
          },
        },
        {
          id: "전화번호",
          title: "전화번호(phoneNum)",
          // width: 15,
          value: (row: {[key: string]: string}, {focus}: any) => {
            return <Input value={row[`전화번호`]} focus={focus} onChange={onFieldChange(row.phoneNum, "phoneNum")} />;
          },
        },
        {
          id: "분류",
          title: "타입(type)",
          value: (row: {[key: string]: string}, {focus}: any) => {
            return <Input value={row[`분류`]} focus={focus} onChange={onFieldChange(row.phoneNum, "type")} />;
          },
          // width: 10,
        },
        {
          id: "회사이름",
          title: "회사이름(companyName)",
          value: (row: {[key: string]: string}, {focus}: any) => {
            return <Input value={row[`회사이름`]} focus={focus} onChange={onFieldChange(row.phoneNum, "companyName")} />;
          },
          // width: 20,
        },
        {
          id: "이메일",
          title: "이메일",
          value: (row: {[key: string]: string}, {focus}: any) => {
            return <Input value={row[`이메일`]} focus={focus} onChange={onFieldChange(row.phoneNum, "companyName")} />;
          },
        },
        {
          id: "직책",
          title: "직책(position)",
          value: (row: {[key: string]: string}, {focus}: any) => {
            return <Input value={row[`직책`]} focus={focus} onChange={onFieldChange(row.position, "position")} />;
          },
          // width: 10,
        },
      ];

      const additional = (formParam.formIds as Array<string>)
        .map(formId => {
          const formItem = formParam.formData[formId];
          if (formItem.title === "분류") return;

          return {
            id: formItem.title,
            title: formItem.title,
            value: (row: {[key: string]: string}, {focus}: any) => {
              return <Input value={row[`${formItem.title}`]} focus={focus} onChange={onFieldChange(row.position, "position")} />;
            },
          };
        })
        .filter(item => item !== undefined);

      return [...defaultColumn, ...additional];
    },
    [onFieldChange]
  );

  return <>{formData && <Grid columns={initialColumns(formData)} rows={rowData} getRowKey={(row: RegitUser) => row.phoneNum} />}</>;
};

export default SpreadSheet;
