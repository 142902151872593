import React from "react";
import styled from "styled-components";

// material ui comp
import Typography, { TypographyProps } from "@material-ui/core/Typography";

interface ButtonTypoProps {
  txtcolor?: "rgba(0,0,0,0.84)" | "purple" | "white" | "black0.6";
}

type Prop = ButtonTypoProps & TypographyProps;

const ButtonTypo: React.FC<Prop> = styled((props: Prop) => {
  const { txtcolor, ...rest } = props;
  return <Typography {...rest} />;
})`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${(props) => {
    if (props.txtcolor === "purple") {
      return "rgba(95, 75, 139, 1.0)";
    } else if (props.txtcolor === "white") {
      return "#ffffff";
    } else if (props.txtcolor === "black0.6") return "rgba(0,0,0,0.6)";
    return props.txtcolor;
  }};
`;

ButtonTypo.defaultProps = {
  txtcolor: "rgba(0,0,0,0.84)",
};

export default ButtonTypo;
