import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {InvalidRegitUser} from "../../../../types/models/Registration";

interface Props {
  invalidUsers: Array<InvalidRegitUser>;
}

interface Column {
  id: `idx` | `name` | `reason`;
  label: string;
  minWidth?: number;
  align?: `right`;
}

const columns: readonly Column[] = [
  {id: "idx", label: "행", minWidth: 170},
  {id: "name", label: "이름", minWidth: 170},
  {id: "reason", label: "이유", minWidth: 300},
];

const RegitInvalidTable: React.FC<Props> = ({invalidUsers}) => {
  return (
    <Paper style={{width: "100%"}}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth}}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {invalidUsers.map(userInfo => {
              return (
                <TableRow>
                  <TableCell>{userInfo.index}</TableCell>
                  <TableCell>{userInfo.name}</TableCell>
                  <TableCell>{userInfo.reason}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default RegitInvalidTable;
