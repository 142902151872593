import React, {useState, useEffect, useMemo, useCallback} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {AppState, AppActions} from "../../store";
import {changeEventApproval, changeEventCancel, getEvent, getEventAction, modifyEventApprovalDelete, resetEventAction} from "../../actions/events";
import EventDetailContent from "./EventDetailContent";
import SubEventList from "../SubEvent/SubEventList";

// @material-ui/core
import Container from "@material-ui/core/Container";
import Paper, {PaperProps} from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// types
import Event, {EventInfo} from "../../types/models/Event";
import {bindActionCreators} from "redux";

// custom ui comp
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typhography/ButtonTypo";

import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {getSubEvents, resetSubEventsAction} from "../../actions/subEvents";
import SubHeadingTypo from "../Typhography/SubHeadingTypo";
import Box from "@material-ui/core/Box";
import useCalcTime from "../../hooks/useCalcTime";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {removeLoadingAction, setLoadingAction} from "../../actions/loading";
import {setAlertAction} from "../../actions/alerts";
import HeadlineTypo from "../Typhography/HeadlineTypo";
import ConsultingFree from "../SubEvent/Payment/Banner/ConsultingFreeEstimateBanner";
import ConsultingPaidApplyEstimateBanner from "../SubEvent/Payment/Banner/ConsultingPaidApplyEstimateBanner";
import ConsultingPaidEstimateBanner from "../SubEvent/Payment/Banner/ConsultingPaidEstimateBanner";
import ConferenceEstimateBanner from "../SubEvent/Payment/Banner/ConferenceEstimateBanner";
import {setTableInfoLoadingAction} from "../../actions/tableInfo";
import EventPaidStateBanner from "../SubEvent/Payment/Banner/EventPaidStateBanner";
import RegisterBanner from "../SubEvent/Payment/Banner/RegisterBanner";

type PaperProp = PaperProps;

type Props = DispatchProps & reduxStateProps;

const EventDetail: React.FC<Props> = props => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [calcTimeByTimezone] = useCalcTime();
  const param: {id: string} = useParams();
  const {event, getEvent, resetSubEvents} = props;
  const subEvents = useSelector((state: AppState) => state.subEvents.subEvents);
  const [approvalDialogOpen, setApprovalDialogOpen] = useState<boolean>(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const [eventDetail, setEventDetail] = useState<EventInfo>();

  const [detailTaps, setDetailTaps] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setDetailTaps(newValue);
  };

  useEffect(() => {
    return () => {
      // resetEvent();
      resetSubEvents();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEvent(param.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.id]);

  const callGetSubEvents = useCallback(async () => {
    await dispatch(getSubEvents(param.id));
    dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  useEffect(() => {
    if (event?.approval || detailTaps) {
      callGetSubEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailTaps, event]);

  useEffect(() => {
    if (event) {
      const eventInfo = {...event};
      const startDate = calcTimeByTimezone(eventInfo.startDate, "YYYY-MM-DD");
      const endDate = calcTimeByTimezone(eventInfo.endDate, "YYYY-MM-DD");
      eventInfo.startDate = startDate;
      eventInfo.endDate = endDate;
      setEventDetail(eventInfo);
    }
  }, [calcTimeByTimezone, event]);

  const containerStyle = useMemo(() => {
    return {paddingTop: "70px", paddingBottom: "70px", height: "100%"};
  }, []);

  const approvalRequestDialogOnClose = () => {
    setApprovalDialogOpen(false);
  };
  const cancelDialogOnClose = () => {
    setCancelDialogOpen(false);
  };
  const deleteDialogOnClose = () => {
    setDeleteDialogOpen(false);
  };

  // 이벤트 승인요청
  // const onClickApprovalRequest = async () => {
  //   if (eventDetail) {
  //     setApprovalDialogOpen(false);
  //     dispatch(setLoadingAction());
  //     const result: any = await dispatch(
  //       changeEventApprovalRequest(eventDetail.id!.toString())
  //     );
  //     if (result === "success") {
  //       const event = { ...eventDetail };
  //       event.approval = "R"; // 대기상태 변경
  //       await dispatch(getEventAction(event));
  //       dispatch(
  //         setAlertAction({
  //           id: "approvalReqSuccess",
  //           msg: "이벤트 승인요청 완료",
  //           alertType: "success",
  //         })
  //       );
  //     }
  //     dispatch(removeLoadingAction());
  //   }
  // };

  // 이벤트 승인요청 취소
  // const onClickApprovalRequestCancel = async () => {
  //   if (eventDetail) {
  //     dispatch(setLoadingAction());
  //     const result: any = await dispatch(
  //       changeEventApprovalWaiting(eventDetail.id!.toString())
  //     );
  //     if (result === "success") {
  //       const event = { ...eventDetail };
  //       event.approval = "W"; // 대기상태 변경
  //       await dispatch(getEventAction(event));
  //       dispatch(
  //         setAlertAction({
  //           id: "approvalReqCancel",
  //           msg: "이벤트 승인요청 취소 완료",
  //           alertType: "success",
  //         })
  //       );
  //     }
  //     dispatch(removeLoadingAction());
  //   }
  // };

  // 이벤트 게시
  const onClickEventApproval = async () => {
    if (eventDetail) {
      setApprovalDialogOpen(false);
      dispatch(setLoadingAction());
      const result: any = await dispatch(changeEventApproval(eventDetail.id!));
      if (result === "success") {
        const event = {...eventDetail};
        event.approval = "Y"; // 승인상태 변경
        await dispatch(getEventAction(event));
        dispatch(
          setAlertAction({
            id: "approvalReqSuccess",
            msg: t("eventDetail.eventPublished"),
            alertType: "success",
          })
        );
      }
      dispatch(removeLoadingAction());
    }
  };

  // 이벤트 게시 취소
  const onClickEventCancel = async () => {
    if (eventDetail) {
      setCancelDialogOpen(false);
      dispatch(setLoadingAction());
      const result: any = await dispatch(changeEventCancel(eventDetail.id!));
      if (result === "success") {
        const event = {...eventDetail};
        event.approval = "W"; // 대기상태 변경
        await dispatch(getEventAction(event));
        dispatch(
          setAlertAction({
            id: "approvalReqSuccess",
            msg: t("eventDetail.eventCancelled"),
            alertType: "success",
          })
        );
      }
      dispatch(removeLoadingAction());
    }
  };

  // 이벤트 삭제
  const onClickDeleteEvent = async () => {
    if (eventDetail) {
      setDeleteDialogOpen(false);
      dispatch(setLoadingAction());
      const result: any = await dispatch(modifyEventApprovalDelete(eventDetail.id!.toString()));
      if (result === "success") {
        const event = {...eventDetail};
        event.approval = "D"; // 대기상태 변경
        await dispatch(getEventAction(event));
        history.replace("/event");
      }
      dispatch(removeLoadingAction());
      dispatch(
        setAlertAction({
          id: "approvalReqCancel",
          msg: t("eventDetail.requestEventDeleted"),
          alertType: "success",
        })
      );
    }
  };

  return (
    <>
      <div style={{display: "flex", color: "blue"}}></div>
      <Container maxWidth="lg" style={containerStyle}>
        {eventDetail !== undefined && (
          <>
            <TitleDiv>
              <HeadlineTypo gutterBottom>{eventDetail.name}</HeadlineTypo>
              <SubHeadingTypo txtcolor="secondary" gutterBottom>
                {eventDetail.startDate} ~ {eventDetail.endDate}
              </SubHeadingTypo>
              {eventDetail.approval !== "D" && (
                <div>
                  {/* {(eventDetail.approval === "N" ||
                    eventDetail.approval === "W") && (
                    <PxButton
                      backgroundcolor="white"
                      padding="5"
                      margin="0 5px 0 0"
                      onClick={() => setApprovalDialogOpen(true)}
                    >
                      <ButtonTypo>승인요청</ButtonTypo>
                    </PxButton>
                  )} */}
                  {/* {eventDetail.approval === "R" && (
                    <PxButton
                      backgroundcolor="white"
                      padding="5"
                      margin="0 5px 0 0"
                      onClick={onClickApprovalRequestCancel}
                    >
                      <ButtonTypo>승인요청 취소</ButtonTypo>
                    </PxButton>
                  )} */}
                  {(eventDetail.approval === "N" || eventDetail.approval === "W") && (
                    <PxButton backgroundcolor="white" padding="5" margin="0 5px 0 0" onClick={() => setApprovalDialogOpen(true)}>
                      <ButtonTypo>{t("eventDetail.publishEvnet")}</ButtonTypo>
                    </PxButton>
                  )}
                  {eventDetail.approval === "Y" && (
                    <PxButton backgroundcolor="white" padding="5" margin="0 5px 0 0" onClick={() => setCancelDialogOpen(true)}>
                      <ButtonTypo>{t("eventDetail.unpublishingEvent")}</ButtonTypo>
                    </PxButton>
                  )}
                  <PxButton backgroundcolor="grey" padding="5" onClick={() => setDeleteDialogOpen(true)}>
                    <ButtonTypo>{t("common.delete")}</ButtonTypo>
                  </PxButton>
                </div>
              )}
            </TitleDiv>
            {Array.isArray(subEvents) &&
              subEvents?.map(data => {
                if (data.category === "consulting") {
                  return data.payment === "Y" ? (
                    <ConsultingPaidEstimateBanner subEvent={data} />
                  ) : data.payment === "W" ? (
                    <ConsultingPaidApplyEstimateBanner subEvent={data} />
                  ) : (
                    <ConsultingFree subEvent={data} />
                    // <EventPaidStateBanner />
                  );
                } else if (data.category === "registration") return <RegisterBanner subEvent={data} />;
                else return <ConferenceEstimateBanner subEvent={data} />;
              })}
            {/* {event && event.id == 248145 && ( */}
            {/* {event && (
              <PxButton backgroundcolor="purple" onClick={() => history.push(`/event/${event.id}/editor`)}>
                <ButtonTypo>에디터</ButtonTypo>
              </PxButton>
            )} */}
            {eventDetail.approval !== "D" && eventDetail.approval !== "Y" ? (
              <PxButton
                backgroundcolor="purple"
                style={{
                  float: "right",
                }}
                onClick={() => history.push(`${location.pathname}/SubEventAdd`)}
              >
                <ButtonTypo>{t("eventDetail.createSubEvent")}</ButtonTypo>
              </PxButton>
            ) : (
              <Box height={30} />
            )}
            <StickyPaper
              square
              style={{
                marginTop: "10px",
              }}
            >
              <Tabs value={detailTaps} indicatorColor="primary" textColor="primary" variant="fullWidth" onChange={handleChange}>
                <Tab label={t("eventDetail.subEventList")} fullWidth />
                <Tab label={t("common.overview")} fullWidth />
              </Tabs>
            </StickyPaper>
            {/* eventDetail의 approval 값이 변경되어도 테이블의 렌더링이 안되어 상태값 Y,W로 두개 나누어줌 */}
            {eventDetail.approval === "W" && detailTaps === 0 && <SubEventList />}
            {eventDetail.approval === "Y" && detailTaps === 0 && <SubEventList />}
            {detailTaps === 1 && <EventDetailContent />}
          </>
        )}
      </Container>
      {/***************** Dialogs ******************/}
      {/* 승인요청 dialog */}
      {/* <Dialog open={approvalDialogOpen} onClose={approvalRequestDialogOnClose}>
        <DialogTitle>이벤트 승인요청</DialogTitle>
        <DialogContent>
          이벤트승인 요청중에는 서브이벤트의 수정이 안됩니다.
        </DialogContent>
        <DialogActions>
          <PxButton backgroundcolor="purple" onClick={onClickApprovalRequest}>
            <ButtonTypo>확인</ButtonTypo>{" "}
          </PxButton>
          <PxButton
            backgroundcolor="grey"
            onClick={approvalRequestDialogOnClose}
          >
            <ButtonTypo>취소</ButtonTypo>
          </PxButton>
        </DialogActions>
      </Dialog> */}
      {/* 이벤트 게시 dialog */}
      <Dialog open={approvalDialogOpen} onClose={approvalRequestDialogOnClose}>
        <DialogTitle>{t("eventDetail.publishEvnet")}</DialogTitle>
        <DialogContent>
          {t("eventDetail.publishEventMsg")}
          <br /> {t("eventDetail.publishEventMsg2")}
        </DialogContent>
        <DialogActions>
          <PxButton backgroundcolor="purple" onClick={onClickEventApproval}>
            <ButtonTypo>{t("common.confirm")}</ButtonTypo>{" "}
          </PxButton>
          <PxButton backgroundcolor="grey" onClick={approvalRequestDialogOnClose}>
            <ButtonTypo>{t("common.cancel")}</ButtonTypo>
          </PxButton>
        </DialogActions>
      </Dialog>
      {/* 이벤트 게시 취소 dialog */}
      <Dialog open={cancelDialogOpen} onClose={cancelDialogOnClose}>
        <DialogTitle>{t("eventDetail.unpublishingEvent")}</DialogTitle>
        <DialogContent>
          {t("eventDetail.unpublishingEventMSG")}
          <br /> {t("eventDetail.unpublishingEventMSG2")}
        </DialogContent>
        <DialogActions>
          <PxButton backgroundcolor="purple" onClick={onClickEventCancel}>
            <ButtonTypo>{t("common.confirm")}</ButtonTypo>{" "}
          </PxButton>
          <PxButton backgroundcolor="grey" onClick={cancelDialogOnClose}>
            <ButtonTypo>{t("common.cancel")}</ButtonTypo>
          </PxButton>
        </DialogActions>
      </Dialog>
      {/* 이벤트 삭제 dialog */}
      <Dialog open={deleteDialogOpen} onClose={deleteDialogOnClose}>
        <DialogTitle>{t("eventDetail.deleteEvent")}</DialogTitle>
        <DialogContent>{t("noticeDetail.deleteAlert")}</DialogContent>
        <DialogActions>
          <PxButton backgroundcolor="purple" onClick={onClickDeleteEvent}>
            <ButtonTypo>{t("common.confirm")}</ButtonTypo>
          </PxButton>
          <PxButton backgroundcolor="grey" onClick={deleteDialogOnClose}>
            <ButtonTypo>{t("common.cancel")}</ButtonTypo>
          </PxButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface DispatchProps {
  getEvent: (id: string) => void;
  resetEvent: () => void;
  resetSubEvents: () => void;
}

interface reduxStateProps {
  event: Event["event"];
}

const mapStateToProps = (state: AppState): reduxStateProps => ({
  event: state.events.event,
});

const mapDispatchToprops = (dispatch: ThunkDispatch<any, any, AppActions>): DispatchProps => ({
  getEvent: bindActionCreators(getEvent, dispatch),
  resetEvent: bindActionCreators(resetEventAction, dispatch),
  resetSubEvents: bindActionCreators(resetSubEventsAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToprops)(EventDetail);

const StickyPaper: React.FC<PaperProp> = styled((props: PaperProp) => {
  const {...rest} = props;
  return <Paper {...rest} />;
})`
  top: 75px;
  display: inline-block;
  position: sticky;
  background-color: white;
  width: 100%;
  z-index: 1030;
`;

const TitleDiv = styled.div`
  text-align: center;
  justify-content: center;
`;
