// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, {useEffect, useState} from "react";

import {Navbar, NavbarHeader, NavbarItem, Attendees, Eye, Chat, SignalStrength, Flex, ZoomIn, ZoomOut, useContentShareState, Badge} from "amazon-chime-sdk-component-library-react";

import {useNavigation} from "../../providers/NavigationProvider";
import {useAppState} from "../../providers/AppStateProvider";
import {LocalMediaStreamMetrics} from "../LocalMediaStreamMetrics";
import {Layout} from "../../types";
import GalleryLayout from "../../components/icons/GalleryLayout";
import FeaturedLayout from "../../components/icons/FeaturedLayout";
import {useVideoTileGridControl} from "../../providers/VideoTileGridProvider";
import {useRealtimeChatState} from "../../providers/ChatProvider";

const Navigation: React.FC = () => {
  const {toggleChat, toggleRoster, closeNavbar} = useNavigation();
  const {theme, toggleTheme, layout, setLayout, priorityBasedPolicy} = useAppState();
  const {sharingAttendeeId} = useContentShareState();
  const {zoomIn, zoomOut} = useVideoTileGridControl();
  const {alertCount} = useRealtimeChatState();
  const [badgeCount, setBadgeCount] = useState(0);

  useEffect(() => {
    setBadgeCount(alertCount);
    console.log(`badgeCount : ${badgeCount}`);
  }, [alertCount]);

  const navItemStyle = `
  button:hover{
    background-color:#5f4b8b;
  }
`;

  return (
    <Navbar className="nav" flexDirection="column" css={navItemStyle} container>
      <NavbarHeader title="Navigation" onClose={closeNavbar} />
      <Flex css="margin-top: 0rem;">
        <NavbarItem icon={<Attendees />} onClick={toggleRoster} label="Attendees" />
        <NavbarItem icon={<Chat />} onClick={toggleChat} label="Chat" badge={<Badge value={badgeCount} />} />
        <NavbarItem
          icon={layout === Layout.Gallery ? <FeaturedLayout /> : <GalleryLayout />}
          onClick={(): void => {
            if (layout === Layout.Gallery) {
              setLayout(Layout.Featured);
            } else {
              setLayout(Layout.Gallery);
            }
          }}
          disabled={!!sharingAttendeeId}
          label="Switch View"
        />
        {/* {layout === Layout.Gallery && priorityBasedPolicy && (
          <>
            <NavbarItem
              icon={<ZoomIn />}
              onClick={zoomIn}
              label="Zoom In"
              disabled={!!sharingAttendeeId}
            />
            <NavbarItem icon={<ZoomOut />} onClick={zoomOut} label="Zoom Out" />
          </>
        )} */}
      </Flex>
      {/* <Flex>
        <NavbarItem
          icon={<Eye />}
          onClick={toggleTheme}a
          label={theme === "light" ? "Dark mode" : "Light mode"}
        />
        <NavbarItem
          icon={<SignalStrength />}
          onClick={(): void => {
            // do nothing
          }}
          label="Media metrics"
        >
          <LocalMediaStreamMetrics />
        </NavbarItem>
      </Flex> */}
    </Navbar>
  );
};

export default Navigation;
