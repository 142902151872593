import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {matchPath, useParams} from "react-router-dom";
import {AppState} from "../../../../store";
import {Column, MTableToolbar} from "material-table";
import {TextareaAutosize} from "@material-ui/core";

// actions
import {getMeetingHistories, updateMeetingPurpose} from "../../../../actions/meetingHistory";

// custom ui comp
import PxTable from "../../../../components/Table/PxTable";
import PxGridContainer from "../../../Grid/PxGridContainer";

import moment from "moment";
import {useTranslation} from "react-i18next";
import {setTableInfoLoadingAction} from "../../../../actions/tableInfo";
import styled from "styled-components";
import PxButton from "../../../Buttons/PxButton";

interface RowType {
  meetingHistoryId: string;
  meetingHistoryApplicantId: string;
  meetingHistoryAcceptorId: string;
  meetingHistoryApplicantName: string;
  meetingHistoryAcceptorName: string;
  meetingHistoryMeetingId: string;
  // meetingHistoryPrevStatus: string;
  // meetingHistoryNextStatus: string;
  meetingApplicationPurpose: string;
  meetingHistoryStatus: string;
  executionPoint: string;
  meetingHistoryDate: string;
  meetingTime: string;
  // meetingHistoryStartTime: string;
  // meetingHistoryEndTime: string;
  // meetingHistoryCreatedDate: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

interface ModalDataType {
  meetingId: string;
  meetingAcceptor: string;
  meetingApplicator: string;
  meetingDate: string;
  meetingTime: string;
  meetingPurpose: string;
}

const MeetingHistoryList = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation("lang", {useSuspense: false});
  const param: {id: string} = useParams();
  const meetingHistories = useSelector((state: AppState) => state.meetingHistories.meetingHistories);
  const previousRoute = useSelector((state: AppState) => state.routePath.previousPath);
  const [initPage, setInitPage] = useState<number>();
  const [modal, setModal] = useState<boolean>();
  const [modalData, setModalData] = useState<ModalDataType>({meetingId: "", meetingAcceptor: "", meetingApplicator: "", meetingDate: "", meetingTime: "", meetingPurpose: ""});
  const [state, setState] = React.useState<TableStateType>({
    columns: [
      // {
      //   title: "제목",
      //   field: "meetingHistoryTitle",
      //   cellStyle: {
      //     width: "calc(100%-600px)",
      //     paddingRight: 0,
      //     maxWidth: "500px",
      //     whiteSpace: "nowrap",
      //     overflow: "hidden",
      //     textOverflow: "ellipsis",
      //   },
      // },
      {
        title: t("meetingHistoryList.applicant"),
        field: "meetingHistoryApplicantName",
        cellStyle: {width: "70px", paddingRight: 0},
      },
      {
        title: t("meetingHistoryList.acceptor"),
        field: "meetingHistoryAcceptorName",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      // {
      //   title: t("meetingHistoryList.beforeMatch"),
      //   field: "meetingHistoryPrevStatus",
      //   cellStyle: {width: "100px", paddingRight: 0},
      // },
      // {
      //   title: t("meetingHistoryList.afterMatch"),
      //   field: "meetingHistoryNextStatus",
      //   cellStyle: {width: "100px", paddingRight: 0},
      // },
      {
        title: "신청 사유",
        field: "meetingApplicationPurpose",
        cellStyle: {width: "300px", paddingRight: 0},
      },
      {
        title: t("meetingHistoryList.status"),
        field: "meetingHistoryStatus",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: t("meetingHistoryList.matchingWay"),
        field: "executionPoint",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: t("meetingHistoryList.date"),
        field: "meetingHistoryDate",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: "미팅 시간",
        field: "meetingTime",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      // {
      //   title: t("meetingHistoryList.start"),
      //   field: "meetingHistoryStartTime",
      //   cellStyle: {width: "100px", paddingRight: 0},
      // },
      // {
      //   title: t("meetingHistoryList.end"),
      //   field: "meetingHistoryEndTime",
      //   cellStyle: {width: "100px", paddingRight: 0},
      // },
      // {
      //   title: t("meetingHistoryList.registerDate"),
      //   field: "meetingHistoryCreatedDate",
      //   cellStyle: {width: "100px", paddingRight: 0},
      // },
    ],
    data: [],
  });

  useEffect(() => {
    const isApplicationDetail = matchPath(previousRoute, {path: "/subEvent/:id/application/:seconId"});

    isApplicationDetail !== null ? setInitPage(meetingHistories?.number) : setInitPage(0);
  }, [previousRoute]);

  const callGetMeetingHistories = useCallback(async () => {
    await dispatch(getMeetingHistories(param.id));
    if (!meetingHistories || meetingHistories.content.length <= 0) dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  useEffect(() => {
    // callGetMeetingHistories();
  }, [callGetMeetingHistories]);

  useEffect(() => {
    let tableData: RowType[] = [];
    if (Array.isArray(meetingHistories?.content) && meetingHistories && meetingHistories?.content.length > 0) {
      meetingHistories?.content.map(meetingHistory => {
        const rowData: RowType = {
          meetingHistoryId: meetingHistory.id,
          meetingHistoryApplicantId: meetingHistory.applicantId,
          meetingHistoryAcceptorId: meetingHistory.acceptorId,
          meetingHistoryApplicantName: meetingHistory.applicantName,
          meetingHistoryAcceptorName: meetingHistory.acceptorName,
          meetingHistoryMeetingId: meetingHistory.meetingId,
          // meetingHistoryPrevStatus: meetingHistory.prevStatus,
          // meetingHistoryNextStatus: meetingHistory.nextStatus,
          meetingApplicationPurpose: meetingHistory.meetingPurpose,
          meetingHistoryStatus: meetingHistory.status,
          executionPoint: meetingHistory.executionPoint === "autoMatching" ? t("meetingHistoryList.auto") : t("meetingHistoryList.manual"),
          meetingHistoryDate: meetingHistory.date,
          meetingTime: `${meetingHistory.startTime}~${meetingHistory.endTime}`,
          // meetingHistoryStartTime: meetingHistory.startTime,
          // meetingHistoryEndTime: meetingHistory.endTime,
          // meetingHistoryCreatedDate: moment(meetingHistory.createdDate).format("YYYY-MM-DD"),
        };
        tableData.push(rowData);
      });
      setState(prevCreateDate => {
        return {...prevCreateDate, data: tableData};
      });
      dispatch(setTableInfoLoadingAction(false));
    }
  }, [meetingHistories]);

  /**
   *      next rise 이벤트
   *
   */

  const updatePurpose = async () => {
    const res = await dispatch(await updateMeetingPurpose(modalData.meetingId, modalData.meetingPurpose));

    if (res !== undefined) {
      const index = state.data.findIndex(m => m.meetingHistoryId == modalData.meetingId);

      state.data[index].meetingApplicationPurpose = modalData.meetingPurpose;

      setModal(false);
    }
  };

  return (
    <>
      <PxTable<RowType>
        components={{
          Toolbar: props => {
            return (
              <PxGridContainer direction="column">
                {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                <MTableToolbar {...props} />
              </PxGridContainer>
            );
          },
        }}
        actionData={param.id}
        getAction={getMeetingHistories}
        totalPage={meetingHistories?.totalPages}
        totalElements={meetingHistories?.totalElements}
        currentPage={initPage}
        columns={state.columns}
        data={state.data}
        onRowClick={(evt, selectedRow) => {
          // history.push(`${match.url}/${selectedRow?.meetingHistoryId}`);
          setModal(true);
          setModalData({
            meetingId: selectedRow?.meetingHistoryId as string,
            meetingAcceptor: selectedRow?.meetingHistoryAcceptorName as string,
            meetingApplicator: selectedRow?.meetingHistoryApplicantName as string,
            meetingDate: selectedRow?.meetingHistoryDate as string,
            meetingTime: selectedRow?.meetingTime as string,
            meetingPurpose: selectedRow?.meetingApplicationPurpose as string,
          });
        }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
      />
      {/* next rise 이벤트 */}
      {modal && modalData && (
        <Container>
          <MeetingPurposeTitle>
            미팅 : {modalData.meetingApplicator} - {modalData.meetingAcceptor}
          </MeetingPurposeTitle>
          <MeetingInfoItem>- 일시 : {modalData.meetingDate}</MeetingInfoItem>
          <MeetingInfoItem>- 시간 : {modalData.meetingTime}</MeetingInfoItem>
          <MeetingPurposeContainer>
            <MeetingInfoItem>- 신청 사유</MeetingInfoItem>
            <TextareaAutosize rowsMin={5} value={modalData.meetingPurpose} onChange={e => setModalData({...modalData, meetingPurpose: e.target.value})} />
          </MeetingPurposeContainer>
          <BtnContainer>
            <PxButton backgroundcolor="purple" txtColor="white" margin="5px" onClick={() => updatePurpose()}>
              수정
            </PxButton>
            <PxButton backgroundcolor="purple" txtColor="white" margin="5px" onClick={() => setModal(false)}>
              닫기
            </PxButton>
          </BtnContainer>
        </Container>
      )}
    </>
  );
};

export default MeetingHistoryList;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: auto;
  z-index: 10;
  background: white;
  padding: 20px;
  border: 1px solid;
  border-radius: 8px;
`;

const MeetingInfoItem = styled.div`
  display: flex;
  padding: 2px 3px;
  font-size: 1rem;
`;

const MeetingPurposeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MeetingPurposeTitle = styled.h1`
  font-size: 1.5rem;
  padding: 10px 0;
  text-align: center;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
`;
