import React from "react";

// import "react-confirm-alert/src/react-confirm-alert.css"; // 배경 닐림

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import i18n from "../i18n/i18n";

let thisTitle: string; // 제목
let thisMessage: string; // 내용
let thisClick: Function; // 확인시 작동 함수

export const confirmModalOptions = ({ title, message, click }: any) => {
  thisTitle = title;
  thisMessage = message;
  thisClick = click;
  return options;
};

const handleOnClick = (onClose: any) => {
  thisClick();
  onClose();
};

const options = {
  childrenElement: () => <div />,
  customUI: ({ onClose }: any) => {
    return (
      <Dialog
        open={true}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{thisTitle}</DialogTitle>
        {thisMessage !== undefined && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {thisMessage}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {/* {t("common.close")} */}
            {i18n.language === "cn" ? "關閉" : i18n.language === "en" ? "close" : "닫기"}
          </Button>
          {thisClick !== undefined && (
            <Button onClick={() => handleOnClick(onClose)} color="primary" autoFocus>
              {/* {t("common.done")} */}
              {i18n.language === "cn" ? "確認" : i18n.language === "en" ? "CONFRIM" : "획인"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  },
  closeOnEscape: true,
  closeOnthisClickOutside: true,
  willUnmount: () => {},
  afterClose: () => {},
  onthisClickOutside: () => {},
  onKeypressEscape: () => {},
};
