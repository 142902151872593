import React, {useState, useEffect, Fragment} from "react";
import {useHistory, useParams} from "react-router-dom";
import {ThunkDispatch} from "redux-thunk";
import {connect, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";

// types
import {EventInfo} from "../../types/models/Event";

// actions
import {AppActions, AppState} from "../../store";
import {getEvent, modifyEvent} from "../../actions/events";
import {getSignedRequest, fileStateToInitial, getFiles} from "../../actions/files";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";
import {TypographyProps} from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select, {SelectProps} from "@material-ui/core/Select";

// icons
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import PersonIcon from "@material-ui/icons/Person";
import ClearIcon from "@material-ui/icons/Clear";

// types
import File from "../../types/models/File";

// custom ui comp
import OutlinedTxtField from "../Inputs/OutlinedTxtField";
import SubHeadingTypo from "../Typhography/SubHeadingTypo";
import PxOutlinedFormControl from "../../components/Forms/PxOutlinedFormControl";
import PxButton from "../Buttons/PxButton";
import PxSelectBox from "../../components/SelectBox/PxSelectBox";
import ButtonTypo from "../Typhography/ButtonTypo";
import GooglePlaces from "../Common/GooglePlaces";
import {gmtTimeAdjustment, parallaxCalculation} from "../../utils/momentTz";

// editor https://www.npmjs.com/package/react-quill#custom-toolbar
// import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import {countryPhoneNumberCode, EVENT_MAIN_IMAGES} from "../../utils/constants";
import TextField from "@material-ui/core/TextField/TextField";
import {isDate} from "../../utils/utils";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PxGridContainer from "../Grid/PxGridContainer";
import PxGridItem from "../Grid/PxGridItem";
import PxEditor from "../Editor/PxEditor";
import EventCategory from "./EventCategory";
import EventFooterEdit from "./EventFooterEdit";

interface SelectBoxProps extends SelectProps {}

const useStyles = makeStyles(theme => ({
  eventImage: {
    maxWidth: "100%",
    maxHeight: 370,
  },
  eventRecommendImage: {
    width: 438,
    heigth: 273,
  },
  exampleImageGridItem: {
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "skyblue",
    },
  },
}));

const SelectBox: React.FC<SelectBoxProps> = styled((props: SelectBoxProps) => {
  const {...rest} = props;
  return <Select {...rest} />;
})`
  & > .MuiSelect-outlined.MuiSelect-outlined {
    padding: 10.5px 0 10.5px 10px;
  }
`;

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const {...rest} = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const TapButton = styled.span`
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 30px;

  ${(props: {active?: boolean}) => {
    if (props.active) {
      return `
        background-color: #3f52b5;
        border: none;
        color: white;
      `;
    } else {
      return `
        background-color: white;
        border: 1px solid #333;
        color: black;
      `;
    }
  }}
`;

const InputContent = styled.div`
  width: 100%;
  align-self: center;
  padding-right: 10px;
`;

type DisProps = DispatchProps & reduxStateProps;

const EventModify: React.FC<DisProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params: {id: string} = useParams();
  const [t, i18n] = useTranslation("lang", {useSuspense: false});

  const [locationTapNumber, setLocationTapNumber] = useState(0);
  const eventDetail = useSelector((state: AppState) => state.events.event);
  const {country, countries} = useSelector((state: AppState) => state.countries!);
  const [categories, setCategories] = useState<string[]>([]);
  const [openRecommendImgDialog, setRecommendImgDialogOpen] = useState<boolean>(false);

  const now = moment().format("YYYY-MM-DDTHH:mm");
  const [footerUseState, setFooterUseState] = useState<boolean>(false);
  const [event, setEvent] = useState<EventInfo>({
    id: undefined,
    name: "", // 이벤트 이름
    nameEn: "", // 이벤트 이름 (영문)
    countryCode: "", // 주최지
    startDate: now, // 이벤트 시작일
    endDate: now, // 이벤트 종료일
    image: "", // 이벤트 이미지
    locationCheck: true, // 이벤트 장소 여부
    location: "", // 이벤트 장소
    locationDetail: "", // 이벤트 장소 상세
    lat: "", // 위도
    lng: "", // 경도
    content: "", // 이벤트 개요
    contentEn: "", // 이벤트 개요(영문)
    homePageCheck: true, // 이벤트 웹사이트 체크
    homePage: "", // 이벤트 웹사이트
    atchFile: [], // 첨부파일
    atchFile1: -1, // 첨부파일1
    atchFile2: -1, // 첨부파일2
    atchFile3: -1, // 첨부파일3
    atchFile4: -1, // 첨부파일4
    atchFile5: -1, // 첨부파일5
    mainImageFile: {}, // 메인이미지 정보
    mainImage: -1, // 메인이미지
    digitalMap: "", // 디지털맵
    managers: [], // 담당자
    logoImage: undefined,
  });

  useEffect(() => {
    dispatch(getEvent(params.id));
  }, []);

  useEffect(() => {
    if (eventDetail !== undefined) {
      eventDataSet(eventDetail);
      eventDetail.categories && setCategories(eventDetail.categories.split("^&"));
    }
  }, [eventDetail]);

  const eventDataSet = async (eventDetail: any) => {
    if (eventDetail.approval === "D") {
      alert("Page not found");
      history.push("/");
    }

    setLocationTapNumber((eventDetail.lat !== "" && eventDetail.lng !== "") || (eventDetail.location === "" && eventDetail.locationDetail === "") ? 0 : 1);

    const prevAtchFile = await dispatch(getFiles([eventDetail.atchFile1, eventDetail.atchFile2, eventDetail.atchFile3, eventDetail.atchFile4, eventDetail.atchFile5]));

    setEvent((prevState: EventInfo) => {
      const locationCheck = eventDetail.location !== "" ? true : false;
      const homePageCheck = eventDetail.homePage !== "" ? true : false;

      return {
        ...eventDetail,
        content: eventDetail.content ?? "",
        contentEn: eventDetail.contentEn ?? "",
        locationCheck: locationCheck,
        homePageCheck: homePageCheck,
        startDate: parallaxCalculation(eventDetail.startDate, "", countries![eventDetail.countryCode!] as any),
        endDate: parallaxCalculation(eventDetail.endDate, "", countries![eventDetail.countryCode!] as any),
        atchFile: prevAtchFile,
        logoImage: eventDetail.logoImageFile == null ? 0 : eventDetail.logoImageFile.id,
      };
    });
  };

  const {files, modifyEvent, getSignedRequest, fileStateToInitial} = props;

  // 파일추가시 이벤트에 파일 추가
  useEffect(() => {
    if (files.gubun === "atchFile") {
      let setFile = {
        id: parseInt(files.id),
        fileName: files.fileName,
        filePath: files.filePath,
        gubun: files.gubun,
      };

      setEvent((prevState: EventInfo) => {
        // let atchFile = prevState.atchFile;
        // atchFile?.push(setFile);

        // return { ...prevState, atchFile };
        let atchFile = prevState.atchFile;
        atchFile?.push(setFile);

        let atchFile1,
          atchFile2,
          atchFile3,
          atchFile4,
          atchFile5 = -1;
        if (atchFile !== undefined) {
          atchFile1 = atchFile.length > 0 ? atchFile[0].id : -1;
          atchFile2 = atchFile.length > 1 ? atchFile[1].id : -1;
          atchFile3 = atchFile.length > 2 ? atchFile[2].id : -1;
          atchFile4 = atchFile.length > 3 ? atchFile[3].id : -1;
          atchFile5 = atchFile.length > 4 ? atchFile[4].id : -1;
        }
        return {
          ...prevState,

          atchFile,
          atchFile1: atchFile1,
          atchFile2: atchFile2,
          atchFile3: atchFile3,
          atchFile4: atchFile4,
          atchFile5: atchFile5,
        };
      });

      // 리덕스 초기화
      fileStateToInitial();
    } else if (files.gubun === "mainImage") {
      setEvent({
        ...event,
        mainImageFile: files,
        mainImage: parseInt(files.id),
        image: "",
      });

      // 리덕스 초기화
      fileStateToInitial();
    } else if (files.gubun === "bannerImage") {
      setEvent({
        ...event,
        bannerImageFile: files,
        bannerImage: parseInt(files.id),
      });

      // 리덕스 초기화
      fileStateToInitial();
    } else if (files.gubun === "logo") {
      console.log(`logo : `, files);
      setEvent({
        ...event,
        logoImageFile: files,
        logoImage: parseInt(files.id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  // google place, 직접입력 선택 텝
  const handleLocationTapChange = (tapNumber: number) => {
    setEvent((prevState: EventInfo) => {
      return {
        ...prevState,
        location: "",
        lat: "",
        lng: "",
      };
    });
    setLocationTapNumber(tapNumber);
  };

  // 첨부파일 등록
  const handleFileAdd = (
    e: React.ChangeEvent<{
      files: any;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();

    const file = e.target.files[0];
    const fileParts = file.name.split(".");
    const originalFileName = fileParts[0];
    const timestamp = new Date().getTime();
    const fileType = fileParts[fileParts.length - 1];
    const fileName = originalFileName + "&&" + timestamp + "." + fileType;
    const folderPath = "event/";
    const fileSize = file.size;
    const gubun = "atchFile"; // 어떤 파일을 등록했는지 구분
    getSignedRequest(file, fileName, fileType, folderPath, fileSize, gubun);
  };

  // 첨부파일 삭제
  const handleFileRemove = (id: number) => {
    // 다중파일 삭제
    setEvent((prevState: EventInfo) => {
      let atchFile = prevState.atchFile;
      atchFile = atchFile?.filter((atchFileFilter: any) => {
        return (atchFileFilter.id as number) !== id;
      });
      // return { ...prevState, atchFile };

      let atchFile1,
        atchFile2,
        atchFile3,
        atchFile4,
        atchFile5 = -1;
      if (atchFile !== undefined) {
        atchFile1 = atchFile.length > 0 ? atchFile[0].id : -1;
        atchFile2 = atchFile.length > 1 ? atchFile[1].id : -1;
        atchFile3 = atchFile.length > 2 ? atchFile[2].id : -1;
        atchFile4 = atchFile.length > 3 ? atchFile[3].id : -1;
        atchFile5 = atchFile.length > 4 ? atchFile[4].id : -1;
      }
      return {
        ...prevState,
        atchFile,
        atchFile1: atchFile1,
        atchFile2: atchFile2,
        atchFile3: atchFile3,
        atchFile4: atchFile4,
        atchFile5: atchFile5,
      };
    });
  };

  // 대표이미지, 배너이미지 등록
  const handleImageAdd = (
    e: React.ChangeEvent<{
      files: any;
      name?: string | undefined;
    }>,
    gubun: string
  ) => {
    e.preventDefault();

    const file = e.target.files[0];
    const fileParts = file.name.split(".");
    const originalFileName = fileParts[0];
    const timestamp = new Date().getTime();
    const fileType = file.type.split("/")[1];
    const fileName = originalFileName + "&&" + timestamp + "." + fileType;
    const folderPath = "event/";
    const fileSize = file.size;
    // const gubun = "mainImage"; // 어떤 파일을 등록했는지 구분
    getSignedRequest(file, fileName, fileType, folderPath, fileSize, gubun);
  };

  // 대표이미지 삭제
  const handleMainImageRemove = () => {
    setEvent({...event, mainImageFile: {}, mainImage: -1});
  };

  // 대표이미지 삭제
  const handleBannerImageRemove = () => {
    setEvent({...event, bannerImageFile: undefined, mainImage: undefined});
  };

  // GooglePlaceAutoComplete selected
  const googlePlaceSelected = (address: string, lat: string, lng: string) => {
    setEvent({
      ...event,
      location: address,
      lat: lat,
      lng: lng,
    });
  };

  // 이벤트 시작일
  const handleStartDateChange = (value: string) => {
    if (false === isDate(value)) return false;
    const startDate = value;
    let endDate = event.endDate;

    const returnWorkingPeriod = gmtTimeAdjustment(startDate, endDate, "start");

    setEvent({
      ...event,
      startDate: returnWorkingPeriod.startTime,
      endDate: returnWorkingPeriod.endTime,
    });
  };

  // 이벤트 종료일
  const handleEndDateChange = (value: string) => {
    if (false === isDate(value)) return false;
    let startDate = event.startDate;
    const endDate = value;

    const returnWorkingPeriod = gmtTimeAdjustment(startDate, endDate, "end");

    setEvent({
      ...event,
      startDate: returnWorkingPeriod.startTime,
      endDate: returnWorkingPeriod.endTime,
    });
  };

  // 이벤트 내용 변경
  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    setEvent({
      ...event,
      [e.target.name as string]: e.target.value as string,
    });
  };

  // 이벤트 장소, 홈페이지 여부 변경
  const handleCheckboxChange = (name: string, value: boolean) => {
    let changeData = {...event, [name]: value};
    if (name === "locationCheck") {
      Object.assign(changeData, {locationDetail: "", location: ""});
    } else if (name === "homePageCheck") {
      Object.assign(changeData, {homePage: ""});
    }
    setEvent(changeData);
  };

  // 이벤트 컨텐츠 내용(에디터) 변경
  const handleContentChange = (value: string) => {
    setEvent({
      ...event,
      content: value as string,
    });
  };

  // 이벤트 컨텐츠 내용(영문)(에디터) 변경
  const handleContentEnChange = (value: string) => {
    setEvent({
      ...event,
      contentEn: value as string,
    });
  };

  // 이벤트 등록
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const saveStartDate = parallaxCalculation(event.startDate, countries![event.countryCode!] as any, "");
    const saveEndDate = parallaxCalculation(event.endDate, countries![event.countryCode!] as any, "");
    const category = categories.join("^&");
    const param = Object.assign(
      {...event},
      {
        startDate: saveStartDate,
        endDate: saveEndDate,
        categories: category,
      }
    );

    if (!footerUseState) {
      param.footer = [];
    } else {
      param.footer = event.footer;
    }

    const eventRes = await modifyEvent(param);
    if (eventRes === "success") history.push("/event");
  };

  // 담당자 추가
  const handleManagerAdd = () => {
    setEvent((prevState: EventInfo) => {
      let managers = prevState.managers;
      managers?.push({
        id: "",
        name: "",
        email: "",
        phone: "",
        countryNumber: "",
      });

      return {...prevState, managers};
    });
  };

  // 담당자 제거
  const handleManagerRemove = (managerIndex: number) => {
    setEvent((prevState: EventInfo) => {
      let returnManagers = prevState.managers;
      returnManagers?.splice(managerIndex, 1);
      return {...prevState, returnManagers};
    });
  };

  // 담당자 수정
  const handleManagerChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>,
    index: number
  ) => {
    const name = e.target.name as string;
    const value = e.target.value as string;
    setEvent((prevState: EventInfo) => {
      let prevManagers = prevState.managers!;
      Object.assign(prevManagers[index], {
        [name]: value,
      });

      return {...prevState, managers: prevManagers};
    });
  };

  const handleLogoImageRemove = () => {
    setEvent({...event, logoImage: undefined, logoImageFile: undefined});
  };

  return event.id !== undefined ? (
    <Container maxWidth="lg" style={{paddingTop: "120px"}}>
      <form onSubmit={onSubmit}>
        <SubTitle>{t("makeEvent.eventName")}</SubTitle>
        <InputBase
          inputProps={{
            style: {
              textAlign: "left",
              height: "30px",
              fontSize: "24px",
              fontWeight: "bold",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "1.25",
              letterSpacing: "-1px",
              color: "rgba(0, 0, 0, 0.38)",
            },
          }}
          fullWidth
          name="name"
          value={event.name}
          onChange={handleOnChange}
          placeholder={t("makeEvent.typeEventName")}
          autoComplete="off"
        />
        {/* 이벤트명 (영문) */}
        <SubTitle>{t("makeEvent.eventNameEn")}</SubTitle>
        <InputBase
          inputProps={{
            style: {
              textAlign: "left",
              height: "30px",
              fontSize: "24px",
              fontWeight: "bold",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "1.25",
              letterSpacing: "-1px",
              color: "rgba(0, 0, 0, 0.38)",
            },
          }}
          fullWidth
          name="nameEn"
          value={event.nameEn}
          onChange={handleOnChange}
          placeholder={t("makeEvent.typeEventName")}
          autoComplete="off"
        />

        {/* 로고 이미지 */}
        <Grid
          container
          alignContent="space-between"
          spacing={1}
          style={{
            margin: "32px 0 0 0",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <SubTitle style={{margin: "0"}}>{t("makeEvent.logo")}</SubTitle>
            <SubHeadingTypo>{t("makeEvent.logoTerms")}</SubHeadingTypo>
          </Grid>
          {event.logoImageFile == null && (
            <Grid>
              <Button
                variant="contained"
                component="label"
                style={{
                  boxShadow: "none",
                  backgroundColor: "#eee",
                  padding: "9px 16px",
                }}
              >
                {t("common.add")}
                <input
                  type="file"
                  style={{display: "none"}}
                  name="file1"
                  accept="image/gif, image/jpeg, image/png"
                  onChange={e => {
                    handleImageAdd(e, "logo");
                  }}
                />
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container direction="row">
          {event.logoImageFile != null && (
            <Grid sm={12} md={12} lg={12}>
              <img className={classes.eventImage} alt="event logo" src={event.logoImageFile.filePath} />
              <br />
              {event.logoImageFile.fileName}
              <Button
                variant="contained"
                component="label"
                style={{
                  boxShadow: "none",
                  backgroundColor: "#eee",
                  padding: "4.5px 8px",
                  margin: "5px 10px",
                }}
                onClick={handleLogoImageRemove}
              >
                {t("common.delete")}
              </Button>
            </Grid>
          )}
        </Grid>

        <SubTitle>{t("makeEvent.hostingPlace")}</SubTitle>
        <Grid container direction="row">
          <Grid sm={12} md={12} lg={12}>
            <InputContent>
              <PxOutlinedFormControl fullWidth>
                <PxSelectBox
                  style={{
                    justifyContent: "center",
                    backgroundColor: "white",
                  }}
                  value={event.countryCode}
                  onChange={handleOnChange}
                  displayEmpty
                  name="countryCode"
                  disabled={event.approval === "Y"}
                  input={<OutlinedInput margin="dense" />}
                >
                  <MenuItem value="" disabled>
                    {t("makeEvent.hostingPlace")}
                  </MenuItem>
                  {Object.keys(countries!).map(key => (
                    <MenuItem value={key}>{countries![key].koName}</MenuItem>
                  ))}
                </PxSelectBox>
              </PxOutlinedFormControl>
            </InputContent>
          </Grid>
        </Grid>

        <SubTitle>{t("makeEvent.period")}</SubTitle>
        <Grid style={{display: "flex", alignItems: "center"}}>
          <TextField
            variant="outlined"
            id="datetime-local"
            label={t("makeEvent.start")}
            type="datetime-local"
            value={event.startDate}
            name="startDate"
            disabled={event.approval === "Y"}
            onChange={e => handleStartDateChange(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: {padding: "10.5px 0 10.5px 10px", margin: 0},
            }}
            InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
          />
          <div style={{padding: "0 10px"}}>-</div>
          <TextField
            variant="outlined"
            id="datetime-local"
            label={t("makeEvent.end")}
            type="datetime-local"
            value={event.endDate}
            name="endDate"
            disabled={event.approval === "Y"}
            onChange={e => handleEndDateChange(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: {padding: "10.5px 0 10.5px 10px", margin: 0},
            }}
            InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
          />
        </Grid>

        <SubTitle>{t("makeEvent.categories")}</SubTitle>
        <Grid>
          <EventCategory categories={categories} setCategory={setCategories} />
        </Grid>

        <SubTitle>
          {t("makeEvent.placeSet")} <Checkbox checked={event.locationCheck === true} onChange={() => handleCheckboxChange("locationCheck", !event.locationCheck)} />
          {event.locationCheck &&
            ["google place", t("makeEvent.directTyping")].map((tapBtn: string, index: number) => (
              <TapButton active={locationTapNumber === index} onClick={() => handleLocationTapChange(index)}>
                {tapBtn}
              </TapButton>
            ))}
        </SubTitle>
        {event.locationCheck === true && (
          <Grid container direction="row">
            {locationTapNumber === 0 && (
              <Grid sm={12} md={12} lg={12}>
                <GooglePlaces setPlace={googlePlaceSelected} />
              </Grid>
            )}
            <Grid sm={12} md={12} lg={12}>
              <OutlinedTxtField
                name="location"
                fullWidth
                padding="0px 0px 8px 0px"
                onChange={handleOnChange}
                disabled={locationTapNumber === 0}
                value={event.location}
                placeholder={t("makeEvent.typePlace")}
              />
            </Grid>
            <Grid sm={12} md={12} lg={12}>
              <OutlinedTxtField fullWidth onChange={handleOnChange} name="locationDetail" value={event.locationDetail} placeholder={t("makeEvent.typeDetailPlace")} />
            </Grid>
          </Grid>
        )}
        {/* 내용 */}
        <SubTitle>{t("common.content")}</SubTitle>
        <Grid sm={12} md={12} lg={12}>
          <PxEditor value={event.content} onChange={handleContentChange} folderPath="event/" placeholder={t("makeEvent.Overview")} />
        </Grid>
        {/* 내용 (영문) */}
        <SubTitle>{t("common.contentEn")}</SubTitle>
        <Grid sm={12} md={12} lg={12}>
          <PxEditor value={event.contentEn} onChange={handleContentEnChange} folderPath="event/" placeholder={t("makeEvent.Overview")} />
        </Grid>
        <SubTitle>
          {t("makeEvent.websiteCheck")} <Checkbox checked={event.homePageCheck === true} onChange={() => handleCheckboxChange("homePageCheck", !event.homePageCheck)} />
        </SubTitle>
        {event.homePageCheck === true && (
          <Grid container direction="row">
            <Grid sm={12} md={12} lg={12}>
              <OutlinedTxtField
                name="homePage"
                value={event.homePage}
                fullWidth
                onChange={handleOnChange}
                placeholder={t("makeEvent.typeTheWebsite")}
                error={event.homePage.length > 0 && event.homePage.indexOf("://") === -1}
                helperText={event.homePage.length > 0 && event.homePage.indexOf("://") === -1 ? "풀주소를입력해주세요(https://...)" : ""}
              />
            </Grid>
          </Grid>
        )}

        {/* 대표 추천 이미지 dialog */}
        <Dialog
          open={openRecommendImgDialog}
          onClose={() => {
            setRecommendImgDialogOpen(false);
          }}
          maxWidth="md"
        >
          <DialogContent>
            <PxGridContainer spacing={2}>
              {EVENT_MAIN_IMAGES.map(url => (
                <PxGridItem
                  md={6}
                  className={classes.exampleImageGridItem}
                  onClick={() => {
                    setRecommendImgDialogOpen(false);
                    setEvent({...event, image: url, mainImage: -1});
                  }}
                >
                  <img className={classes.eventRecommendImage} alt="event main" src={url} />
                </PxGridItem>
              ))}
            </PxGridContainer>
          </DialogContent>
        </Dialog>

        {/* 대표 이미지 */}
        <Grid
          container
          alignContent="space-between"
          spacing={1}
          style={{
            margin: "32px 0 0 0",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <SubTitle style={{margin: "0"}}>
              {t("makeEvent.thumbnail")}
              &nbsp;&nbsp;
              {event.mainImage === -1 && (
                <PxButton
                  padding="2px 4px"
                  backgroundcolor="purple"
                  onClick={() => {
                    setRecommendImgDialogOpen(true);
                  }}
                >
                  <ButtonTypo>{t("makeEvent.recommandImage")}</ButtonTypo>
                </PxButton>
              )}
            </SubTitle>
            <SubHeadingTypo>{t("makeEvent.thumbnailTerms")}</SubHeadingTypo>
          </Grid>

          {/* 추천이미지 미리보기 */}
          <Grid container direction="row">
            {event.image !== null && event.image !== "" && (
              <Grid sm={12} md={12} lg={12}>
                <img className={classes.eventImage} alt="event main" src={event.image} />
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#eee",
                    padding: "4.5px 8px",
                    margin: "5px 10px",
                  }}
                  onClick={() => {
                    setEvent({
                      ...event,
                      image: "",
                      mainImageFile: {},
                      mainImage: -1,
                    });
                  }}
                >
                  {t("common.delete")}
                </Button>
              </Grid>
            )}
          </Grid>

          {event.image === "" && Object.keys(event.mainImageFile).length === 0 && JSON.stringify(event.mainImageFile) === JSON.stringify({}) && (
            <Grid>
              <Button
                variant="contained"
                component="label"
                style={{
                  boxShadow: "none",
                  backgroundColor: "#eee",
                  padding: "9px 16px",
                }}
              >
                {t("common.add")}
                <input
                  type="file"
                  style={{display: "none"}}
                  name="file1"
                  onChange={e => {
                    handleImageAdd(e, "mainImage");
                  }}
                />
              </Button>
            </Grid>
          )}
        </Grid>

        <Grid container direction="row">
          {event.image === "" && Object.keys(event.mainImageFile).length !== 0 && JSON.stringify(event.mainImageFile) !== JSON.stringify({}) && (
            <Grid sm={12} md={12} lg={12}>
              <img className={classes.eventImage} alt="event banner" src={event.mainImageFile.filePath} />
              <br />
              {event.mainImageFile.fileName}
              <Button
                variant="contained"
                component="label"
                style={{
                  boxShadow: "none",
                  backgroundColor: "#eee",
                  padding: "4.5px 8px",
                  margin: "5px 10px",
                }}
                onClick={handleMainImageRemove}
              >
                {t("common.delete")}
              </Button>
            </Grid>
          )}
        </Grid>

        {/* 배너 이미지 */}
        {/* <Grid
          container
          alignContent="space-between"
          spacing={1}
          style={{
            margin: "32px 0 0 0",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <SubTitle style={{margin: "0"}}>{t("makeEvent.banner")}</SubTitle>
            <SubHeadingTypo>{t("makeEvent.bannerTerms")}</SubHeadingTypo>
          </Grid>
          {(event.bannerImageFile === undefined || event.bannerImageFile === null) && (
            <Grid>
              <Button
                variant="contained"
                component="label"
                style={{
                  boxShadow: "none",
                  backgroundColor: "#eee",
                  padding: "9px 16px",
                }}
              >
                {t("common.add")}
                <input
                  type="file"
                  style={{display: "none"}}
                  name="file1"
                  onChange={e => {
                    handleImageAdd(e, "bannerImage");
                  }}
                />
              </Button>
            </Grid>
          )}
        </Grid>

        <Grid container direction="row">
          {event.bannerImageFile !== undefined && event.bannerImageFile !== null && (
            <Grid sm={12} md={12} lg={12}>
              <img alt="event banner" src={event.bannerImageFile.filePath} className={classes.eventImage} />
              <br />
              {event.bannerImageFile.fileName}
              <Button
                variant="contained"
                component="label"
                style={{
                  boxShadow: "none",
                  backgroundColor: "#eee",
                  padding: "4.5px 8px",
                  margin: "5px 10px",
                }}
                onClick={handleBannerImageRemove}
              >
                {t("common.delete")}
              </Button>
            </Grid>
          )}
        </Grid>
        */}

        <Grid
          container
          alignContent="space-between"
          spacing={1}
          style={{
            margin: "32px 0 0 0",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <SubTitle style={{margin: "0"}}> {t("makeEvent.attachment")}</SubTitle>
            <SubHeadingTypo>{t("makeEvent.filesTerms")}</SubHeadingTypo>
          </Grid>
          {Array.isArray(event.atchFile) && event.atchFile.length < 5 ? (
            <Grid>
              <Button
                variant="contained"
                component="label"
                style={{
                  boxShadow: "none",
                  backgroundColor: "#eee",
                  padding: "9px 16px",
                }}
              >
                {t("common.add")}
                <input type="file" style={{display: "none"}} name="file1" onChange={handleFileAdd} />
              </Button>
            </Grid>
          ) : null}
        </Grid>

        {/* 첨부파일 */}
        <Grid container direction="row">
          {Array.isArray(event.atchFile) &&
            event.atchFile.map((evAtchFile: any, idx: number) => (
              <Grid sm={12} md={12} lg={12} key={`file-list-${idx}`}>
                {evAtchFile.fileName}
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#eee",
                    padding: "4.5px 8px",
                    margin: "5px 10px",
                  }}
                  onClick={() => handleFileRemove(evAtchFile.id)}
                >
                  {t("common.delete")}
                </Button>
              </Grid>
            ))}
        </Grid>

        {/* 담당자 */}
        {Array.isArray(event.managers) && event.managers.length > 0 ? (
          event.managers.map((evManagers, idx: number) => (
            <Fragment key={`manager-${idx}`}>
              <Grid
                container
                alignContent="space-between"
                spacing={1}
                style={{
                  margin: "32px 0 0 0",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <Grid>
                  <SubTitle>{t("makeEvent.manager")}</SubTitle>
                </Grid>
                <Grid>
                  <Button
                    variant="contained"
                    component="label"
                    style={{
                      boxShadow: "none",
                      backgroundColor: "#eee",
                      padding: "2px 8px",
                    }}
                    onClick={() => handleManagerRemove(idx)}
                  >
                    {t("common.delete")}
                    <ClearIcon style={{width: "18px", height: "18px"}} />
                  </Button>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid sm={12} md={12} lg={12}>
                  <OutlinedTxtField
                    fullWidth
                    padding="0px 0px 8px 0px"
                    propPadding="10.5px 0"
                    name="name"
                    value={evManagers.name}
                    onChange={e => handleManagerChange(e, idx)}
                    placeholder="담당자를 입력해주세요"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon
                            style={{
                              color: "#ccc",
                              width: "18px",
                              height: "18px",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid sm={12} md={12} lg={12}>
                  <OutlinedTxtField
                    fullWidth
                    padding="0px 0px 8px 0px"
                    propPadding="10.5px 0"
                    name="email"
                    value={evManagers.email}
                    onChange={e => handleManagerChange(e, idx)}
                    placeholder=""
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon
                            style={{
                              color: "#ccc",
                              width: "18px",
                              height: "18px",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid container alignContent="space-between" spacing={1}>
                  <Grid item sm={6} md={6} lg={6}>
                    <OutlinedTxtField
                      fullWidth
                      padding="0px 0px 8px 0px"
                      propPadding="10.5px 0"
                      name="phone"
                      value={evManagers.phone}
                      onChange={e => handleManagerChange(e, idx)}
                      placeholder=""
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon
                              style={{
                                color: "#ccc",
                                width: "18px",
                                height: "18px",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} md={6} lg={6}>
                    <PxOutlinedFormControl fullWidth>
                      <SelectBox name="countryNumber" value={evManagers.countryNumber} onChange={e => handleManagerChange(e, idx)} displayEmpty>
                        <MenuItem value="" disabled>
                          {t("common.countryCode")}
                        </MenuItem>
                        {countryPhoneNumberCode.map(countryCode => (
                          <MenuItem value={countryCode.code}> {i18n.language === "ko" ? countryCode.country : countryCode.countryEn}</MenuItem>
                        ))}
                      </SelectBox>
                    </PxOutlinedFormControl>
                  </Grid>
                </Grid>
                {Array.isArray(event.managers) && event.managers.length - 1 === idx && (
                  <Grid style={{marginTop: "26px"}}>
                    <Button
                      variant="contained"
                      component="label"
                      style={{
                        boxShadow: "none",
                        backgroundColor: "#eee",
                        padding: "9px 16px",
                      }}
                      onClick={handleManagerAdd}
                    >
                      {t("makeEvent.addManager")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Fragment>
          ))
        ) : (
          <Grid
            container
            alignContent="space-between"
            spacing={1}
            style={{
              margin: "32px 0 0 0",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Grid>
              <SubTitle style={{margin: "0"}}> {t("makeEvent.manager")}</SubTitle>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                component="label"
                style={{
                  boxShadow: "none",
                  backgroundColor: "#eee",
                  padding: "9px 16px",
                }}
                onClick={handleManagerAdd}
              >
                {t("makeEvent.addManager")}
              </Button>
            </Grid>
          </Grid>
        )}
        {/* footer 설정 */}
        <EventFooterEdit setEvent={setEvent} setFooterUseState={setFooterUseState} />

        <Grid container alignContent="space-between" spacing={1}>
          <Grid item>
            <PxButton backgroundcolor="purple" type="submit">
              <ButtonTypo>{t("common.modify")}</ButtonTypo>
            </PxButton>
          </Grid>
          <Grid item>
            <PxButton
              backgroundcolor="grey"
              onClick={() => {
                history.goBack();
              }}
            >
              <ButtonTypo>{t("common.cancel")}</ButtonTypo>
            </PxButton>
          </Grid>
        </Grid>
      </form>
    </Container>
  ) : null;
};

interface DispatchProps {
  modifyEvent: (event: EventInfo) => any;
  getSignedRequest: (file: File, fileName: string, fileType: string, folderPath: string, fileSize: number, gubun: string) => void;
  fileStateToInitial: () => void;
}

interface reduxStateProps {
  files: File;
}

const mapStateToProps = (state: AppState): reduxStateProps => ({
  files: state.files,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>): DispatchProps => ({
  modifyEvent: bindActionCreators(modifyEvent, dispatch),
  getSignedRequest: bindActionCreators(getSignedRequest, dispatch),
  fileStateToInitial: bindActionCreators(fileStateToInitial, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventModify);
