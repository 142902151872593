import React from "react";
import styled from "styled-components";

// material ui comp
import Typography, { TypographyProps } from "@material-ui/core/Typography";

interface Body1TypoProps {
  txtcolor?:
    | "rgba(0,0,0,0.87)"
    | "secondary"
    | "purple"
    | "purple700"
    | "black0.6"
    | "azure"
    | "black0.38"
    | "white0.56"
    | "white"
    | "red";
  fontWeight?: string;
  fontSize?: string;
  marginLeft?: string;
}

type Prop = Body1TypoProps & TypographyProps;

const Body1Typo: React.FC<Prop> = styled((props: Prop) => {
  const { txtcolor, fontWeight, fontSize, marginLeft, ...rest } = props;
  return <Typography {...rest} />;
})`
  font-size: ${(props) => props.fontSize ?? "13px"};
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.4px;
  margin-left: ${(props) => props.marginLeft ?? "0px"};
  color: ${(props) => {
    if (props.txtcolor === "purple") {
      return "rgba(95, 75, 139, 1.0)";
    }
    if (props.txtcolor === "secondary") {
      return "#6d7278";
    }
    if (props.txtcolor === "azure") {
      return "#0091ea";
    }
    if (props.txtcolor === "purple700") {
      return "#512da8";
    }
    if (props.txtcolor === "white0.56") {
      return "rgba(255, 255, 255, 0.56)";
    }
    if (props.txtcolor === "black0.6") {
      return "rgba(0,0,0,0.6)";
    }
    if (props.txtcolor === "black0.38") {
      return "rgba(0, 0, 0, 0.38)";
    }
    if (props.txtcolor === "white") return "#ffffff";
    if (props.txtcolor === "red") return "#FF0000";
    return props.txtcolor;
  }};
  font-weight: ${(props) => props.fontWeight ?? "normal"};
`;

Body1Typo.defaultProps = {
  txtcolor: "rgba(0,0,0,0.87)",
};

export default Body1Typo;
