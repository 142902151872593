import React, {useState, useEffect} from "react";
import Container from "@material-ui/core/Container";
import {ThunkDispatch} from "redux-thunk";
import {connect, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {useHistory, useParams} from "react-router-dom";

// types
import {RegistSubEventReq, SubEventInfo} from "../../types/models/SubEvent";

// actions
import {AppActions, AppState} from "../../store";
import {getEvent} from "../../actions/events";
import {createRegisterSubEvent, createSubEvent} from "../../actions/subEvents";

// custom ui comp
import SubHeadingTypo from "../Typhography/SubHeadingTypo";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import {TypographyProps} from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import {gmtTimeAdjustment, parallaxCalculation, timeBlurEvent} from "../../utils/momentTz";

import styled from "styled-components";
import moment from "moment";
import Event from "../../types/models/Event";
import Matched from "./Matched";
// import Mismatch from "./Mismatch";
import uuid from "uuid";
import TextField from "@material-ui/core/TextField";
import {isDate} from "../../utils/utils";
import OutlinedTxtField from "../Inputs/OutlinedTxtField";
import {debounce} from "lodash";
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useCalcTime from "../../hooks/useCalcTime";
import {makeStyles} from "@material-ui/core/styles";

import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import {TimePicker} from "@material-ui/pickers";
import RegistrationPartiForm from "../../views/form/setting/registration/registrationPartiForm";
import {FormData} from "../../types/models/AutonoForm";
import {Form} from "../../types/models/Form";

const useStyles = makeStyles(() => ({
  dialogRoot: {
    zIndex: 1000,
  },
}));

// subtitle style
type TypoProp = TypographyProps;

const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const {...rest} = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

type DisProps = DispatchProps & reduxStateProps;

const SubEventAdd: React.FC<DisProps> = props => {
  const classes = useStyles();
  const [t] = useTranslation("lang", {useSuspense: false});
  const {createSubEvent, event, getEvent} = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const params: {id: string} = useParams();
  const {country, countries} = useSelector((state: AppState) => state.countries!);
  const [open, setOpen] = React.useState(false);
  const now = moment().format("YYYY-MM-DDTHH:mm");
  const [disabledTime, setDisabledTime] = useState({});
  const [defaultForm, setDefaultForm] = useState<SubEventInfo>();
  const [registerForm, setRegisterForm] = useState<FormData>();
  const [subEvent, setSubEvent] = useState<SubEventInfo>({
    eventId: params.id, // event key
    sgroupName: t("addSubEvent.seller"), // 참가그룹 A
    bgroupName: t("addSubEvent.buyer"), // 참가그룹 B
    matching: "Y", // 매칭 여부 (Y/N)
    targetScope: "others", // 참가기업 노출범위
    subEventStartDate: now, // 서브 이벤트 시작날짜
    subEventEndDate: now, // 서브 이벤트 끝날짜
    sellRegStartDate: now, // 셀러 참가신청 시작날짜
    sellRegEndDate: now, // 셀러 참가신청 끝날짜
    buyRegStartDate: now, // 바이어 참가신청 시작날짜
    buyRegEndDate: now, // 바이어 참가신청 끝날짜
    regStartDate: "", // 참가자 참가신청 시작날짜
    regEndDate: "", // 참가자 참가신청 끝날짜
    matchStartTime: "09:00", // 매칭(미팅) 시작시간
    matchEndTime: "09:00", // 매칭(미팅) 종료시간
    matchReqTime: 0, // 매칭(미팅) 소요시간
    matchBreakTime: 0, // 매칭(미팅) 휴식시간
    matchNumPerDay: 0, // 일일 매칭(미팅) 횟수
    session: "N", // 세션 여부 (Y/N)
    category: "consulting", // 구분
    // 매치메이킹 없음
    boothCompany: "N", // 부스업체 여부 (Y/N)
    boothCompanyStartDate: now, // 부스업체 시작날짜
    boothCompanyEndDate: now, // 부스업체 끝날짜
    preRegit: "N", // 사전등록 참관객 여부 (Y/N)
    preRegitStartDate: now, // 사전등록 시작날짜
    preRegitEndDate: now, // 사전등록 끝날짜
    onSiteRegit: "N", // 현장등록 참관객 여부 (Y/N)
    approval: "Y",
    impossibleDateAndTime: "",
  });

  // useEffect(() => {
  //   let backBlock = history.block((loc, action) => {
  //     if (action == "POP" && JSON.stringify(subEvent) !== JSON.stringify(defaultForm)) {
  //       if (!window.confirm(`저장되지 않은 변경사항.\n저장되지 않은 변경사항이 있습니다. 나가면 변경사항이 없어집니다.`)) {
  //         return false;
  //       }
  //     }
  //   });

  //   return () => {
  //     backBlock();
  //   };
  // }, [subEvent]);

  const matching = [
    {code: "Y", value: t("common.yes")},
    {code: "N", value: t("common.no")},
  ];

  let targetScope = [
    // { code: "others", value: "상대기업(바이어: 셀러만, 셀러: 바이어만)" },
    {
      code: "others",
      value: t("addSubEvent.counterparts"),
    },
    {code: "", value: t("common.all")},
  ];

  // 이후 카테고리 메뉴 만들때 등록예정
  const category = [
    // { code: "exhibition", value: "전시" },
    // { code: "conference", value: "회의" },
    // { code: "meeting", value: t("addSubEvent.meeting") },
    {code: "consulting", value: t("addSubEvent.consultation")},
    {code: "presentation", value: t("addSubEvent.presentation")},
    {code: "registration", value: t("addSubEvent.registration")},
  ];

  // 서브이벤트 생성 페이지에서 back 버튼을 누를 경우 서브이벤트 목록으로 무조건 이동하도록함
  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.push(`/event/detail/${params.id}`);
      }
    };
  }, [history, params.id]);

  // 이벤트 정보 조회 (매칭기간을 이벤트기간에 넘지않도록)
  useEffect(() => {
    // if (eventState !== undefined && eventState.name === "") {
    getEvent(params.id);
    // }
  }, [getEvent, history, params.id]);

  useEffect(() => {
    if (event !== undefined) {
      setSubEvent({
        ...subEvent,
        subEventStartDate: parallaxCalculation(event.startDate, "", countries![event.countryCode!] as any),
        subEventEndDate: parallaxCalculation(event.endDate, "", countries![event.countryCode!] as any),
      });
    }
  }, [event]);

  // 미팅 예상 종료 시간 계산
  useEffect(() => {
    const matchReqTime = subEvent.matchReqTime as number; // 미팅소요시간
    const matchBreakTime = subEvent.matchBreakTime as number; // 휴식 횟수
    const matchNumPerDay = subEvent.matchNumPerDay as number; // 일일 미팅 횟수

    if (matchReqTime !== 0 && matchNumPerDay !== 0) {
      const countMinute = matchNumPerDay * (matchReqTime + matchBreakTime) - matchBreakTime;
      // 미팅시작 기준 날짜
      const defaultDate = `2020-01-01 ${subEvent.matchStartTime}:00`;
      // 미팅 계산 날짜
      const endTimeCalculation = moment(defaultDate).add(countMinute, "m");

      // 날짜 비교(문구 미팅일정이 다음날로 넘어가는지)
      // moment.diff 날짜 차이 보여줌 0 이여야 같은 날짜
      const subDateCount = moment(endTimeCalculation, "YYYY-MM-DD").diff(moment(defaultDate, "YYYY-MM-DD"), "minute");

      const startMin = moment(defaultDate, "YYYY-MM-DD HH:mm").diff(moment(`2020-01-01 00:00`, "YYYY-MM-DD HH:mm"), "minute");
      // 위 결과에 따른 문구
      let matchEndTime = endTimeCalculation.format("HH:mm");
      // if (subDateCount > 0) {
      //   dispatch(setDialog("24시를 초과하여 미팅일정을 생성할 수 없습니다.", []));
      //   setSubEvent({ ...subEvent, matchNumPerDay: 0 });
      // }
      setSubEvent({
        ...subEvent,
        matchEndTime: matchEndTime,
      });
    } else {
      setSubEvent({...subEvent, matchEndTime: subEvent.matchStartTime});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent.matchStartTime, subEvent.matchReqTime, subEvent.matchBreakTime, subEvent.matchNumPerDay]);

  // // 미팅 시간 생성
  useEffect(() => {
    if (subEvent.matching === "Y") {
      // debounceTimeForm();
      createMeetingTimeForm();
    } else if (subEvent.matching === "N") {
      setSubEvent({
        ...subEvent,
        meetingData: "[]",
        meetingDataJson: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent.matching, subEvent.matchEndTime, subEvent.subEventStartDate, subEvent.subEventEndDate]);

  // 미팅 데이터 폼 생성
  const createMeetingTimeForm = () => {
    let meetingDataJson: any = [];
    // 자정이 넘어갔을경우 계산안함
    if (subEvent.matchEndTime!.indexOf(":") > 0) {
      // 서브 이벤트 기간 일수 차
      let subDateCount = moment(subEvent.subEventStartDate, "YYYY-MM-DD").diff(moment(subEvent.subEventEndDate, "YYYY-MM-DD"), "day");

      // 시작 종료 기간이 잘못됏을경우 리턴(수정함)
      if (subDateCount > 0) {
        return;
      } else {
        subDateCount = Math.abs(subDateCount) + 1;
      }

      // 미팅날찌 기준 값
      let subEventDate = moment(subEvent.subEventStartDate);

      // 날짜, 미팅시간 가공
      Array.from({length: subDateCount}, (x, i) => {
        const subEventDateString = subEventDate.format("YYYY-MM-DD");

        // 미팅 시간 추가
        const meetingTimeList: any = meetingTimeSetting(subEvent);

        meetingDataJson.push({
          date: subEventDateString,
          time: meetingTimeList,
        });
        subEventDate = subEventDate.add(1, "days");
      });
    }
    setSubEvent({
      ...subEvent,
      meetingData: JSON.stringify(meetingDataJson),
      meetingDataJson: meetingDataJson,
    });
    setDefaultForm({
      ...subEvent,
      meetingData: JSON.stringify(meetingDataJson),
      meetingDataJson: meetingDataJson,
    });
  };

  type impossibleAt = "Y" & "N";
  const onChangeMeetingTime = (impossibleAt: impossibleAt, dateIndex: number, timeIndex: number) => {
    setSubEvent(prevState => {
      let prevMeetingData = prevState.meetingDataJson;
      if (prevMeetingData !== undefined) {
        // prevMeetingData[dateIndex].time[timeIndex].impossibleAt = impossibleAt, Object.assign 안돼서 아래방법 사용
        const newTimeObject = {
          ...prevMeetingData[dateIndex].time![timeIndex],
          impossibleAt: impossibleAt,
        };

        const newTimeArray: any = [...prevMeetingData[dateIndex].time!.slice(0, timeIndex), newTimeObject, ...prevMeetingData[dateIndex].time!.slice(timeIndex + 1)];

        prevMeetingData[dateIndex].time = newTimeArray;
      }
      return {
        ...prevState,
        meetingDataJson: prevMeetingData,
        meetingData: JSON.stringify(prevMeetingData),
      };
    });
  };

  // 서브 이벤트 시작일
  const handleSubEventStartDateChange = (value: string, type: string) => {
    if (false === isDate(value)) return false;

    const timeDiff = moment(value, "YYYY-MM-DDTHH:mm").diff(moment(parallaxCalculation(event!.startDate, "", countries![event!.countryCode!] as any), "YYYY-MM-DDTHH:mm"), "minute");
    if (timeDiff < 0) {
      setOpen(true);
      return false;
    }

    const startDate = value;
    let endDate = subEvent.subEventEndDate;

    const returnWorkingPeriod = type === "onChange" ? gmtTimeAdjustment(startDate, endDate, "start") : timeBlurEvent(startDate, endDate, "start");

    setSubEvent({
      ...subEvent,
      subEventStartDate: returnWorkingPeriod.startTime,
      subEventEndDate: returnWorkingPeriod.endTime,
    });
  };

  // 서브 이벤트 종료일
  const handleSubEndDateChange = (value: string, type: string) => {
    if (false === isDate(value)) return false;

    const timeDiff = moment(value, "YYYY-MM-DDTHH:mm").diff(moment(parallaxCalculation(event!.endDate, "", countries![event!.countryCode!] as any), "YYYY-MM-DDTHH:mm"), "minute");
    if (timeDiff > 0) {
      setOpen(true);
      return false;
    }

    let startDate = subEvent.subEventStartDate;
    const endDate = value;

    const returnWorkingPeriod = type === "onChange" ? gmtTimeAdjustment(startDate, endDate, "end") : timeBlurEvent(startDate, endDate, "end");

    setSubEvent({
      ...subEvent,
      subEventStartDate: returnWorkingPeriod.startTime,
      subEventEndDate: returnWorkingPeriod.endTime,
    });
  };

  const debounceMatchNumPerDay = debounce((value: string) => {
    setSubEvent({
      ...subEvent,
      matchNumPerDay: parseInt(value),
    });
  }, 500);

  // 이벤트 내용 변경
  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();
    e.persist();
    if (e.target.name === "matchNumPerDay") {
      debounceMatchNumPerDay(e.target.value as string);
    } else {
      setSubEvent(prevState => {
        if ((e.target.name as string) === "category") {
          switch (e.target.value) {
            case "presentation": {
              return {
                ...prevState,
                sgroupName: t("addSubEvent.attendee"),
                bgroupName: t("addSubEvent.presenter"),
                matching: "N",
                [e.target.name as string]: e.target.value as string,
              };
            }
            case "consulting": {
              return {
                ...prevState,
                sgroupName: t("addSubEvent.seller"),
                bgroupName: t("addSubEvent.buyer"),
                matching: "Y",
                [e.target.name as string]: e.target.value as string,
              };
            }
            case "meeting": {
              return {
                ...prevState,
                sgroupName: t("addSubEvent.seller"),
                bgroupName: t("addSubEvent.buyer"),
                matching: "Y",
                [e.target.name as string]: e.target.value as string,
              };
            }
            case "registration": {
              return {
                ...prevState,
                sgroupName: t("addSubEvent.seller"),
                bgroupName: t("addSubEvent.buyer"),
                matching: "N",
                [e.target.name as string]: e.target.value as string,
              };
            }
            default: {
              return {
                ...prevState,
                [e.target.name as string]: e.target.value as string,
              };
            }
          }
        } else {
          return {
            ...prevState,
            [e.target.name as string]: e.target.value as string,
          };
        }
      });
    }
  };

  // 참가그룹A,B 참가신청 기간 조정
  const handleGroupDateChange = (
    e: React.ChangeEvent<{
      value: string;
      name?: string | undefined;
    }>,
    eventType: string
  ) => {
    e.preventDefault();

    if (false === isDate(e.target.value)) return false;

    const name = e.target.name;
    const type = name?.includes("sellReg") ? "seller" : "buyer";

    let returnWorkingPeriod;
    if (name?.includes("StartDate")) {
      const startDate = e.target.value;
      let endDate;
      if (name.includes("sellReg")) {
        endDate = subEvent.sellRegEndDate;
      } else {
        endDate = subEvent.buyRegEndDate;
      }
      returnWorkingPeriod = eventType === "onChange" ? gmtTimeAdjustment(startDate, endDate, "start") : timeBlurEvent(startDate, endDate, "start");
    } else {
      const endDate = e.target.value;
      let startDate;
      if (name?.includes("buyReg")) {
        startDate = subEvent.buyRegStartDate;
      } else {
        startDate = subEvent.sellRegStartDate;
      }
      returnWorkingPeriod = eventType === "onChange" ? gmtTimeAdjustment(startDate, endDate, "end") : timeBlurEvent(startDate, endDate, "end");
    }

    if (type === "seller") {
      setSubEvent({
        ...subEvent,
        sellRegStartDate: returnWorkingPeriod.startTime,
        sellRegEndDate: returnWorkingPeriod.endTime,
      });
    } else {
      setSubEvent({
        ...subEvent,
        buyRegStartDate: returnWorkingPeriod.startTime,
        buyRegEndDate: returnWorkingPeriod.endTime,
      });
    }
  };

  // 이벤트 등록
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (subEvent.matchNumPerDay === 0 && subEvent.category !== "presentation" && subEvent.category !== "registration") return;

    const saveEventStartDate = parallaxCalculation(subEvent.subEventStartDate, countries![event!.countryCode!] as any, "");

    const saveEventEndDate = parallaxCalculation(subEvent.subEventEndDate, countries![event!.countryCode!] as any, "");

    let saveSellRegStartDate = "";
    let saveSellRegEndDate = "";
    let saveBuyRegStartDate = "";
    let saveBuyRegEndDate = "";

    // 셀러 온라인 참가 기간 날짜 가공
    saveSellRegStartDate = subEvent.sellRegStartDate !== undefined ? parallaxCalculation(subEvent.sellRegStartDate, countries![event!.countryCode!] as any, "") : "";
    saveSellRegEndDate = subEvent.sellRegEndDate !== undefined ? parallaxCalculation(subEvent.sellRegEndDate, countries![event!.countryCode!] as any, "") : "";

    // 바이어 온라인 참가 기간 날짜 가공
    saveBuyRegStartDate = subEvent.buyRegStartDate !== undefined ? parallaxCalculation(subEvent.buyRegStartDate, countries![event!.countryCode!] as any, "") : "";
    saveBuyRegEndDate = subEvent.buyRegEndDate !== undefined ? parallaxCalculation(subEvent.buyRegEndDate, countries![event!.countryCode!] as any, "") : "";

    const object: SubEventInfo = {
      ...subEvent,
      impossibleDateAndTime: JSON.stringify(disabledTime),
    };

    let param = Object.assign(object, {
      subEventStartDate: saveEventStartDate,
      subEventEndDate: saveEventEndDate,
      sellRegStartDate: saveSellRegStartDate,
      sellRegEndDate: saveSellRegEndDate,
      buyRegStartDate: saveBuyRegStartDate,
      buyRegEndDate: saveBuyRegEndDate,
    });

    // 매칭이 없을 경우 참가자가 모든 참가자들의 정보를 볼 수 있도록 함
    if (subEvent.matching === "N") {
      param.targetScope = "";
    }

    if (subEvent.category === "registration") {
      const formParam: Form = {
        type: "registration",
        content: JSON.stringify(registerForm),
      };

      const registerParam: RegistSubEventReq = {
        subEventInfo: {
          ...param,
          onSiteRegit: "Y",
          preRegit: "Y",
        },
        registerForm: formParam,
      };

      await dispatch(createRegisterSubEvent(registerParam as RegistSubEventReq));
      history.goBack();
    } else {
      const subEventRes = await createSubEvent(param);
      if (subEventRes) {
        subEventRes.matching === "Y" ? history.push(`/form/subEvent/${subEventRes.id}/match`) : history.push(`/form/subEvent/${subEventRes.id}/noMatch`);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="lg" style={{paddingTop: "20px", paddingBottom: "20px"}}>
      {/* 이벤트 기간 선택 validation alert dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          root: classes.dialogRoot,
          paper: classes.dialogRoot,
        }}
      >
        <DialogTitle id="alert-dialog-title">{t("addSubEvent.checkDate")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("addSubEvent.setItWithinTheMainEventPeriod")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t("dialog.done")}
          </Button>
        </DialogActions>
      </Dialog>

      <form onSubmit={onSubmit}>
        {/* 서브이벤트 구분 */}
        <div style={{display: "flex", marginTop: 10}}>
          <SubHeadingTypo>{t("addSubEvent.subEventType")}</SubHeadingTypo>
          <Box width={5} />
          <Tooltip
            title={
              <>
                {t("addSubEvent.consultationInfo")}
                <br />
                {t("addSubEvent.conferenceInfo")}
                <br />
                {t("addSubEvent.registrationInfo")}
              </>
            }
            placement="right-end"
          >
            <InfoIcon color="disabled" />
          </Tooltip>
        </div>
        <Grid container direction="row">
          <Grid sm={12} md={12} lg={12}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="category" name="category" row value={subEvent.category} onChange={handleOnChange}>
                {category != null && category.map((gb, index) => <FormControlLabel key={`category-${index}`} value={gb.code} control={<Radio />} label={gb.value} />)}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        {/* 매치메이킹 여부 */}
        {/* {subEvent.category !== "presentation" && (
          <>
            <SubTitle>{t("addSubEvent.matchmaking")}</SubTitle>
            <SubHeadingTypo>{t("addSubEvent.needMatchingManagement")}</SubHeadingTypo>
            <Grid container direction="row">
              <Grid sm={12} md={12} lg={12}>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="matching" name="matching" row value={subEvent.matching} onChange={handleOnChange}>
                    {matching != null && matching.map((gb, index) => <FormControlLabel key={`matching-${index}`} value={gb.code} control={<Radio />} label={gb.value} />)}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )} */}

        {subEvent.category !== "registration" && (
          <>
            <SubTitle>{t("addSubEvent.nameOfParicipatingGroup")}</SubTitle>
            <Grid container direction="row">
              <Grid sm={6} md={6} lg={6} style={{paddingRight: 10}}>
                <SubHeadingTypo>{`${t("addSubEvent.participantA")}${subEvent.category === "presentation" ? `(${t("addSubEvent.attendee")})` : `(${t("addSubEvent.seller")})`}`}</SubHeadingTypo>
                <OutlinedTxtField name="sgroupName" value={subEvent.sgroupName} fullWidth onChange={handleOnChange} placeholder={t("addSubEvent.participantA")} />
              </Grid>
              <Grid sm={6} md={6} lg={6} style={{paddingRight: 10}}>
                <SubHeadingTypo>{`${t("addSubEvent.participantB")}${subEvent.category === "presentation" ? `(${t("addSubEvent.presenter")})` : `(${t("addSubEvent.buyer")})`}`}</SubHeadingTypo>
                <OutlinedTxtField name="bgroupName" value={subEvent.bgroupName} fullWidth onChange={handleOnChange} placeholder={t("addSubEvent.participantB")} />
              </Grid>
            </Grid>
          </>
        )}

        {subEvent.matching === "Y" && (
          <>
            <SubTitle>{t("addSubEvent.exposureRange")}</SubTitle>
            <SubHeadingTypo>{t("addSubEvent.theTargetSetting")}</SubHeadingTypo>
            <Grid container direction="row">
              <Grid sm={12} md={12} lg={12}>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="targetScope" name="targetScope" row value={subEvent.targetScope} onChange={handleOnChange}>
                    {targetScope != null && targetScope.map((ts, index) => <FormControlLabel key={`targetScope-${index}`} value={ts.code} control={<Radio />} label={ts.value} />)}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
        {subEvent.category == "registration" && (
          <div style={{width: "70%"}}>
            <RegistrationPartiForm subEventCategory={subEvent.category} registerForm={registerForm} setRegisterForm={setRegisterForm} />
          </div>
        )}

        <SubTitle>{t("addSubEvent.subEventPeriod")}</SubTitle>
        <Grid style={{display: "flex", alignItems: "center"}}>
          <TextField
            variant="outlined"
            id="datetime-local"
            label={t("addSubEvent.start")}
            type="datetime-local"
            value={subEvent.subEventStartDate}
            name="startDate"
            onChange={e => handleSubEventStartDateChange(e.target.value, "onChange")}
            onBlur={e => handleSubEventStartDateChange(e.target.value, "onBlur")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: {padding: "10.5px 0 10.5px 10px", margin: 0},
            }}
            InputProps={{
              style: {padding: "0 10px 0 0", margin: 0},
            }} // readOnly: true
          />
          <div style={{padding: "0 10px"}}>-</div>
          {/* <TimePicker
            minutesStep={600}
          /> */}

          <TextField
            variant="outlined"
            id="datetime-local"
            label={t("addSubEvent.end")}
            type="datetime-local"
            value={subEvent.subEventEndDate}
            name="endDate"
            onChange={e => handleSubEndDateChange(e.target.value, "onChange")}
            onBlur={e => handleSubEndDateChange(e.target.value, "onBlur")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: {padding: "10.5px 0 10.5px 10px", margin: 0},
              step: 900,
              min: 0,
            }}
            InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
          />
        </Grid>

        {/* 참가그룹 A, B 참가신청 기간 */}
        {/* {subEvent.category !== "registration" && ( */}
        <div>
          <SubTitle>
            {/*셀러*/}
            {subEvent.category !== "registration" ? `${subEvent.sgroupName} ${t("addSubEvent.sApplyPeriod")}` : t("addSubEvent.preRegistrationPeriod")}
          </SubTitle>
          <Grid style={{display: "flex", alignItems: "center"}}>
            <TextField
              variant="outlined"
              id="datetime-local"
              label={`${subEvent.sgroupName} ${t("addSubEvent.start")}`} // 셀러
              type="datetime-local"
              value={subEvent.sellRegStartDate}
              name="sellRegStartDate"
              onChange={e => handleGroupDateChange(e, "onChange")}
              onBlur={e => handleGroupDateChange(e, "onBlur")}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {padding: "10.5px 0 10.5px 10px", margin: 0},
              }}
              InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
            />
            <div style={{padding: "0 10px"}}>-</div>
            <TextField
              variant="outlined"
              id="datetime-local"
              label={`${subEvent.sgroupName} ${t("addSubEvent.end")}`} // 셀러
              type="datetime-local"
              value={subEvent.sellRegEndDate}
              name="sellRegEndDate"
              onChange={e => handleGroupDateChange(e, "onChange")}
              onBlur={e => handleGroupDateChange(e, "onBlur")}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {padding: "10.5px 0 10.5px 10px", margin: 0},
              }}
              InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
            />
          </Grid>
          {/* 바이어 */}
          {subEvent.category !== "registration" && (
            <>
              <SubTitle>
                {subEvent.bgroupName} {t("addSubEvent.bApplyPeriod")}
              </SubTitle>
              <Grid style={{display: "flex", alignItems: "center"}}>
                <TextField
                  variant="outlined"
                  id="datetime-local"
                  label={`${subEvent.bgroupName} ${t("addSubEvent.start")}`} // 바이어
                  type="datetime-local"
                  value={subEvent.buyRegStartDate}
                  name="buyRegStartDate"
                  onChange={e => handleGroupDateChange(e, "onChange")}
                  onBlur={e => handleGroupDateChange(e, "onBlur")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {padding: "10.5px 0 10.5px 10px", margin: 0},
                  }}
                  InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
                />
                <div style={{padding: "0 10px"}}>-</div>
                <TextField
                  variant="outlined"
                  id="datetime-local"
                  label={`${subEvent.bgroupName} ${t("addSubEvent.end")}`} //바이어
                  type="datetime-local"
                  value={subEvent.buyRegEndDate}
                  name="buyRegEndDate"
                  onChange={e => handleGroupDateChange(e, "onChange")}
                  onBlur={e => handleGroupDateChange(e, "onBlur")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {padding: "10.5px 0 10.5px 10px", margin: 0},
                  }}
                  InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
                />
              </Grid>
            </>
          )}
        </div>
        {/* )} */}

        {subEvent.matching === "Y" ? (
          <Matched
            subEvent={subEvent}
            event={event}
            setSubEvent={setSubEvent}
            disabledTime={disabledTime}
            setDisabledTime={setDisabledTime}
            handleOnChange={handleOnChange}
            onChangeMeetingTime={onChangeMeetingTime}
          />
        ) : (
          <></>
          // subEvent.category !== "presentation" && (
          //   <Mismatch
          //     subEvent={subEvent}
          //     setSubEvent={setSubEvent}
          //     handleOnChange={handleOnChange}
          //   />)
        )}
        <Box height={20} />
        <Button
          type="submit"
          style={{
            boxShadow: "none",
            backgroundColor: "#5f4b8b",
            color: "white",
            padding: "9px 16px",
          }}
        >
          {t("common.save")}
        </Button>
        <Button
          style={{
            boxShadow: "none",
            backgroundColor: "#eee",
            padding: "9px 16px",
            marginLeft: 10,
          }}
          onClick={() => history.push(`/event/detail/${params.id}`)}
        >
          {t("common.cancel")}
        </Button>
      </form>
    </Container>
  );
};

interface DispatchProps {
  createSubEvent: (subEvent: SubEventInfo) => any;
  getEvent: (id: string) => void;
}

interface reduxStateProps {
  event: Event["event"];
}

const mapStateToProps = (state: AppState): reduxStateProps => ({
  event: state.events.event,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>): DispatchProps => ({
  createSubEvent: bindActionCreators(createSubEvent, dispatch),
  getEvent: bindActionCreators(getEvent, dispatch),
});

export interface TimeListProps {
  id: string;
  startTime: string;
  endTime: String;
  impossibleAt: String;
}

// 미팅 불가시간 셋팅(id 포함 하기위해 함수 분리)
export const meetingTimeSetting = (subEvent: SubEventInfo) => {
  const matchReqTime = subEvent.matchReqTime as number;
  const matchBreakTime = subEvent.matchBreakTime as number;
  const matchNumPerDay = subEvent.matchNumPerDay as number;

  let meetingTimeList: TimeListProps[] = []; // 필요데이터 {시작시간, 종료시간, 불가여부}

  // 날짜별 매칭시간 기준 값
  let impossibleStartTime = moment(`2020-01-01 ${subEvent.matchStartTime}:00`);

  // 날짜별 매칭시간 조회 (시작시간, 종료시간, 불가여부)
  Array.from({length: matchNumPerDay}, (x, i) => {
    const startTime = impossibleStartTime.format("HH:mm");
    const endTime = impossibleStartTime.add(matchReqTime, "m").format("HH:mm");

    meetingTimeList.push({
      id: uuid.v4(),
      startTime: startTime,
      endTime: endTime,
      impossibleAt: "N",
    });

    impossibleStartTime = impossibleStartTime.add(matchBreakTime, "m");
  });

  return meetingTimeList;
};

export default connect(mapStateToProps, mapDispatchToProps)(SubEventAdd);
