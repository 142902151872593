import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import PxSelectBox from "../../../../SelectBox/PxSelectBox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import PxOutlinedFormControl from "../../../../Forms/PxOutlinedFormControl";

interface Props {
  typeList: string[];
  selectedType: string;
  setSelectedType: Dispatch<SetStateAction<string>>;
}

const RegisterTypeSelector: React.FC<Props> = ({typeList, selectedType, setSelectedType}) => {
  const [t] = useTranslation("lang", {useSuspense: false});

  const onChangeSelectBox = (e: any) => {
    setSelectedType(e.target.value);
  };

  return (
    <div>
      <label>참가 유형</label>
      <PxOutlinedFormControl>
        <PxSelectBox value={selectedType} onChange={e => onChangeSelectBox(e)} displayEmpty input={<OutlinedInput margin="dense" />}>
          <MenuItem value={""}>{t("applicationList.all")}</MenuItem>
          {typeList &&
            typeList.map(typeItem => {
              return <MenuItem value={typeItem}>{typeItem}</MenuItem>;
            })}
        </PxSelectBox>
      </PxOutlinedFormControl>
    </div>
  );
};

export default RegisterTypeSelector;
