import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getApplicationMeetingsForExcel} from "../../../../actions/application";
import {ApplicationFormAnswers, ApplicationMeetingInfo} from "../../../../types/models/Application";
import {ExportCSV} from "../../../ExportCSV/ExportCSV";
import HeadingTypo from "../../../Typhography/HeadingTypo";
import {useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {CircularProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import axios from "axios";
import moment from "moment";
const groupBy = require("json-groupby");

const MeetingResultExcel = ({classes}: {classes: Record<"root" | "companyDiv" | "radioForm" | "meetingDiv", string>}) => {
  const [t, i18n] = useTranslation("lang", {useSuspense: false});
  const params: {id: string} = useParams(); // subEventId
  const [data, setData] = useState<{
    // 엑셀 export data
    data: any[];
    fileName: string;
  }>(null!);
  const [filteredData, setFilteredData] = useState<{
    // 라디오 or 체크박스 값에 따라 필터된 excel로 export할 데이터
    buyer: any[];
    seller: any[];
    fileName: string;
  }>(null!);
  const [groupNames, setGroupNames] = useState<{
    // 전체 데이터
    buyer: string;
    seller: string;
  }>();

  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  // 전체 참가기업 신청서 리스트를 가져온 후 excel로 export 할 data 생성
  const getSubEventApplications = useCallback(async () => {
    const result = await getApplicationMeetingsForExcel(params.id);
    if (!subEvent || !result) return false;

    const resultData = result.map((application: ApplicationMeetingInfo) => {
      const refinedAnswer: any = {};
      // 답변자 구분
      refinedAnswer[t("meetingList.group")] = application.type === "seller" ? subEvent.sgroupName : subEvent.bgroupName;
      refinedAnswer[t("meetingList.corporateName")] = application.name;
      refinedAnswer[t("meetingList.manager")] = application.managerName;
      refinedAnswer[t("meetingList.apply")] = application.applicantCount;
      refinedAnswer[t("meetingList.await")] = application.waitingAcceptorCount;
      refinedAnswer[t("meetingList.n/a")] = application.disagreeCount;
      refinedAnswer[t("meetingList.concluded")] = application.agreeCount;
      refinedAnswer[t("common.registerDate")] = moment(application.createdDate).format("YYYY-MM-DD");

      return refinedAnswer;
    });

    const fileName = `${subEvent.mainEvent?.name}_${t("subEventSidebar.meetingStatus")} (${moment().format("MM_DD_HH:mm")})`;

    // resultData object 형식 = [{ 컬럼이름1: '컬럼내용1', 컬럼이름2: '컬럼내용2', ... }]
    setData({data: resultData, fileName: fileName});
  }, [params.id, subEvent]);

  useEffect(() => {
    if (subEvent !== undefined) {
      setGroupNames({
        seller: subEvent.sgroupName,
        buyer: subEvent.bgroupName,
      });
      getSubEventApplications();
    }
  }, [subEvent, getSubEventApplications]);

  // 참가자 탭 나누기
  useEffect(() => {
    if (data !== null && subEvent !== undefined) {
      setFilteredData(null!);
      const seller: any[] = data.data.filter(d => d[t("meetingList.group")] === subEvent.sgroupName);
      const buyer: any[] = data.data.filter(d => d[t("meetingList.group")] === subEvent.bgroupName);
      const fileName = `${subEvent.mainEvent?.name}_${t("subEventSidebar.meetingStatus")} (${moment().format("MMDDHHmm")})`;

      seller.push({[t("meetingList.group")]: ""});
      seller.push(total(seller));
      buyer.push({[t("meetingList.group")]: ""});
      buyer.push(total(buyer));
      setFilteredData({seller, buyer, fileName: fileName});
    }
  }, [data, subEvent]);

  function total(group: any[]) {
    const groupTotal = group.reduce(
      (
        counter: {
          applicantCount: number;
          waitingAcceptorCount: number;
          impossibleCount: number;
          agreeCount: number;
        },
        application
      ) => ({
        applicantCount: (counter.applicantCount || 0) + (application[t("meetingList.apply")] || 0),
        waitingAcceptorCount: (counter.waitingAcceptorCount || 0) + (application[t("meetingList.await")] || 0),
        impossibleCount: (counter.impossibleCount || 0) + (application[t("meetingList.n/a")] || 0),
        agreeCount: (counter.agreeCount || 0) + (application[t("meetingList.concluded")] || 0),
      }),
      {
        applicantCount: 0,
        waitingAcceptorCount: 0,
        impossibleCount: 0,
        agreeCount: 0,
      }
    );
    return {
      [t("meetingList.group")]: i18n.language === "ko" ? "합계" : "totals",
      [t("meetingList.apply")]: groupTotal.applicantCount,
      [t("meetingList.await")]: groupTotal.waitingAcceptorCount,
      [t("meetingList.n/a")]: groupTotal.impossibleCount,
      [t("meetingList.concluded")]: groupTotal.agreeCount,
    };
  }

  return (
    <div className={classes.companyDiv}>
      <HeadingTypo fontweight="bold">{t("excel.meetingStatus")}</HeadingTypo>

      {filteredData !== null ? (
        <ExportCSV sellerData={filteredData.seller} buyerData={filteredData.buyer} sellerName={groupNames!.seller} buyerName={groupNames!.buyer} fileName={filteredData.fileName} />
      ) : (
        <CircularProgress size={30} />
      )}
    </div>
  );
};

export default MeetingResultExcel;
