import React, {useEffect, useState} from "react";

// types
import SubHeadingTypo from "../../../Typhography/SubHeadingTypo";
import {SubEvenPathContext} from "../../../../layouts/SubEvent";

// @material-ui
import Grid from "@material-ui/core/Grid";
import {TypographyProps} from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Autocomplete from "@material-ui/lab/Autocomplete";

// custom ui comp
import OutlinedTxtField from "../../../Inputs/OutlinedTxtField";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";

import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";

// icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {createInterpreter} from "../../../../actions/interpreter";
import {useTranslation} from "react-i18next";

import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {addTeamMember} from "../../../../actions/TeamManage";
import {setAlertAction} from "../../../../actions/alerts";
import Alert from "../../../../types/models/Alert";

type TypoProp = TypographyProps;

const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const {...rest} = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const SubTitleWrap = styled.div`
  display: flex;
`;
const ExampleFont = styled.h1`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.6;
  margin: 32px 0 8px 10px;
`;

const TeamManageAdd = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [t] = useTranslation("lang", {useSuspense: false});
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  const [name, setName] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [emailAddress, setEmailAddress] = useState<string>();
  const [organization, setOrganization] = useState<string>();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (name !== "" || emailAddress !== "" || phoneNumber !== "") {
      dispatch(addTeamMember(Number(subEvent?.id), name as string, emailAddress as string, phoneNumber as string, organization as string));
      setName("");
      setPhoneNumber("");
      setEmailAddress("");
      setOrganization("");
      const alert: Alert = {
        id: "smsSendAlertId",
        msg: "등록되었습니다.",
        alertType: "warning",
      };
      dispatch(setAlertAction(alert));
    }
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
      </Grid>
      <Grid item style={{padding: "0 0 0 64px"}} md={12}>
        <form onSubmit={onSubmit}>
          <SubTitle>{t("common.name")}</SubTitle>
          <OutlinedTxtField name="name" fullWidth value={name} onChange={e => setName(e.target.value)} />

          <SubTitle>{t("common.company")}</SubTitle>
          <OutlinedTxtField name="organization" fullWidth value={organization} onChange={e => setOrganization(e.target.value)} />

          <SubTitleWrap>
            <SubTitle>{t("common.phone")}</SubTitle>
            <ExampleFont>(입력 예시: 01012345678)</ExampleFont>
          </SubTitleWrap>
          <OutlinedTxtField name="phoneNumber" fullWidth value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />

          <SubTitle>{t("common.email")}</SubTitle>
          <OutlinedTxtField name="email" fullWidth value={emailAddress} onChange={e => setEmailAddress(e.target.value)} />

          <Grid container alignContent="space-between" spacing={1} style={{justifyContent: "flex-end", marginTop: "32px"}}>
            <Grid item>
              <PxButton backgroundcolor="purple" type="submit">
                <ButtonTypo>{t("common.register")}</ButtonTypo>
              </PxButton>
            </Grid>
            <Grid item>
              <PxButton
                backgroundcolor="grey"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ButtonTypo>{t("common.cancel")}</ButtonTypo>
              </PxButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default TeamManageAdd;
