import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup, Tab, Tabs} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/styles";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {changeSettingModeAction, getMailSetting, registEmailAddress, saveMailSetting, unRegistEmailAddress, verifyMailAction} from "../../../../actions/mail";
import {AppState} from "../../../../store";
import Alert from "../../../../types/models/Alert";
import {MailRegistType, MailSettingType, RegistRuternType} from "../../../../types/models/Mail";
import {getRequestAndReturn, postRequest, postRequestAndReturn} from "../../../../utils/utils";
import PxButton from "../../../Buttons/PxButton";
import PxOutlinedTxtField from "../../../Inputs/PxOutlinedTxtField";
import ButtonTypo from "../../../Typhography/ButtonTypo";
import SubHeadingTypo from "../../../Typhography/SubHeadingTypo";
import MailRegistModal from "./MailRegistModal";
import EventApplyMail from "./MailTemplate/EventApplyMail";
import uuid from "uuid";
import {setAlertAction} from "../../../../actions/alerts";
import PxInputBase from "../../../Inputs/PxInputBase";
import {getSignedRequest} from "../../../../actions/files";

const useStyles = makeStyles(theme => ({
  root: {
    "&$checked": {
      color: "#5f4b8b",
    },
  },
  checked: {},
  textField: {
    padding: "10px",
  },
}));

enum MailSendType {
  PEXPO = "PEXPO",
  CUSTOM = "CUSTOM",
}

type SystemMailItem = "logoImage" | "eventName" | "operator" | "replyMailAddress" | "copyrightState";
type SenderItem = "senderName" | "senderMailAddress" | "replyMailAddress";

const initData: MailSettingType = {
  id: 0,
  mode: "",
  senderName: "",
  senderMailAddress: "",
  mailRegistState: "UNREGISTERED",
  systemMailConfig: {
    id: 0,
    logoImage: "",
    eventName: "",
    operator: "",
    replyMailAddress: "",
    copyrightState: "",
  },
};

const MailSetting: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const mailSetting = useSelector((state: AppState) => state.mails.setting);
  const [open, setOpen] = useState<boolean>(false);
  const [mailCheckModal, setMailCheckModal] = useState<boolean>(false);
  const [customMailSettings, setCustomMailSettings] = useState<MailSettingType>(initData);

  useEffect(() => {
    if (!subEvent) return;
    dispatch(getMailSetting(Number(subEvent.id as string)));
  }, [subEvent, dispatch]);

  useEffect(() => {
    if (mailSetting?.id !== customMailSettings.id) {
      setCustomMailSettings({
        id: mailSetting.id,
        mode: mailSetting.mode,
        senderName: mailSetting.senderName,
        senderMailAddress: customMailValue(mailSetting.mode, mailSetting.senderMailAddress),
        mailRegistState: mailSetting.mailRegistState,
        systemMailConfig: mailSetting.systemMailConfig,
      });
      // systemMailConfig: mailSetting.systemMailConfig != undefined ? { ...mailSetting.systemMailConfig } : { ...customMailSettings.systemMailConfig };
    } else if (mailSetting.mode !== customMailSettings.mode) {
      setCustomMailSettings({
        ...customMailSettings,
        mode: mailSetting.mode,
        senderMailAddress: customMailValue(mailSetting.mode, mailSetting.senderMailAddress),
      });
    } else if (mailSetting.mailRegistState !== customMailSettings.mailRegistState) {
      setCustomMailSettings({
        ...customMailSettings,
        mailRegistState: mailSetting.mailRegistState,
        senderMailAddress: mailSetting.senderMailAddress,
      });
    }
  }, [mailSetting, customMailSettings, setCustomMailSettings]);

  const customMailValue = (mode: string, mailValue: string): string => {
    if (mode === MailSendType.CUSTOM && mailValue === "info@pexpo.io") {
      return "";
    }
    return mailValue;
  };

  const handleSettingType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    dispatch(changeSettingModeAction(value));
  };

  const deleteLogoImage = () => {
    if (!customMailSettings || !customMailSettings.systemMailConfig) return;
    setCustomMailSettings({...customMailSettings, systemMailConfig: {...customMailSettings.systemMailConfig, logoImage: ""}});
  };

  // 이메일 로고 등록
  const handleImageAdd = async (
    e: React.ChangeEvent<{
      files: any;
      name?: string | undefined;
    }>,
    gubun: string
  ) => {
    if (!customMailSettings || !customMailSettings.systemMailConfig) return;
    e.preventDefault();

    const file = e.target.files[0];
    const fileParts = file.name.split(".");
    const originalFileName = fileParts[0];
    const timestamp = new Date().getTime();
    const fileType = file.type.split("/")[1];
    const fileName = originalFileName + "&&" + timestamp + "." + fileType;
    const folderPath = "event/";
    const fileSize = file.size;

    const res: any = await dispatch(getSignedRequest(file, fileName, fileType, folderPath, fileSize));

    const data = res.data;
    if (data.filePath != "") {
      setCustomMailSettings({...customMailSettings, systemMailConfig: {...customMailSettings.systemMailConfig, logoImage: data.filePath}});
    }
  };

  const handleSystemMailSettings = (type: SystemMailItem, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!customMailSettings || !customMailSettings.systemMailConfig) return;
    const newValue = event.target.value;

    const updatedSystemMailConfig = {
      ...customMailSettings.systemMailConfig,
      [type]: newValue,
    };

    setCustomMailSettings({
      ...customMailSettings,
      systemMailConfig: updatedSystemMailConfig,
    });
  };

  const handleCopyrightHide = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!customMailSettings || !customMailSettings.systemMailConfig) return;
    const checked = event.target.checked;
    setCustomMailSettings({
      ...customMailSettings,
      systemMailConfig: {
        ...customMailSettings.systemMailConfig,
        copyrightState: checked ? "HIDE" : "SHOW",
      },
    });
  };

  const handleSystemSenderSetting = (type: SenderItem, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;

    switch (type) {
      case "senderName":
        setCustomMailSettings({...customMailSettings, senderName: value});
        break;
      case "senderMailAddress":
        setCustomMailSettings({...customMailSettings, senderMailAddress: value});
        break;
    }
  };

  const handleSaveBtn = () => {
    // setOpen(!open);
    const alert: Alert = {
      alertType: "warning",
      id: uuid.v4(),
      msg: "변경되었습니다.",
    };
    if (mailSetting.mode == MailSendType.CUSTOM && mailSetting.mailRegistState !== "REGISTERED") {
      alert.msg = "이메일을 등록해주세요.";
      dispatch(setAlertAction(alert));
      return;
    }

    setOpen(false);
    dispatch(saveMailSetting(customMailSettings));
    dispatch(setAlertAction(alert));
  };

  const registEmailOnClick = () => {
    if (validateEmail(customMailSettings.senderMailAddress)) {
      dispatch(registEmailAddress(Number(subEvent!.id), customMailSettings.senderMailAddress));
      setMailCheckModal(true);
    } else {
      const alert: Alert = {
        alertType: "warning",
        id: uuid.v4(),
        msg: "올바른 이메일 양식이 아닙니다 다시 확인해주세요.",
      };

      dispatch(setAlertAction(alert));
    }
  };

  function validateEmail(email: string) {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return re.test(email);
  }

  const unRegisterEmailOnClick = () => {
    dispatch(unRegistEmailAddress(Number(subEvent?.id)));
  };

  const checkVerifyState = async () => {
    const res: any = await dispatch(getRequestAndReturn(`/api/mail-setting/verify-email/${subEvent?.id}`, {}));
    const data: RegistRuternType = res;
    let message: string = "";

    if (data.mailRegistState === "REGISTERED") {
      message = "등록되었습니다.";
    } else {
      message = "등록되지 않았습니다. 메일함에서 링크를 클릭해서 등록을 완료해주세요.";
    }
    const alert: Alert = {
      alertType: "warning",
      id: uuid.v4(),
      msg: message,
    };

    dispatch(verifyMailAction(data));
    dispatch(setAlertAction(alert));
  };

  const saveCheck = () => {
    if (customMailSettings.mode == "PEXPO") {
      handleSaveBtn();
    } else {
      setOpen(true);
    }
  };

  const RegistStateBtn = (mailRegistState: MailRegistType) => {
    switch (mailRegistState) {
      case "UNREGISTERED":
        return (
          <PxButton backgroundcolor="purple" onClick={registEmailOnClick}>
            <ButtonTypo>등록</ButtonTypo>
          </PxButton>
        );
      case "REGISTERED":
        return (
          <PxButton backgroundcolor="purple" onClick={unRegisterEmailOnClick}>
            <ButtonTypo>삭제</ButtonTypo>
          </PxButton>
        );
      case "PENDING":
        return (
          <div style={{width: "20%", display: "flex", justifyContent: "space-around"}}>
            <PxButton backgroundcolor="purple" onClick={checkVerifyState}>
              <ButtonTypo>인증 완료</ButtonTypo>
            </PxButton>
            <PxButton backgroundcolor="purple" onClick={unRegisterEmailOnClick}>
              <ButtonTypo>취소</ButtonTypo>
            </PxButton>
          </div>
        );
    }
  };

  const formatDate = (isoString: string): string => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const second = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };

  return (
    <>
      {/* {mailSetting && ( */}
      <RadioGroup row value={customMailSettings.mode} onChange={handleSettingType} style={{padding: "50px 30px"}}>
        <FormControlLabel control={<Radio classes={{root: classes.root, checked: classes.checked}} />} label="Pexpo" value={MailSendType.PEXPO} />
        <FormControlLabel control={<Radio classes={{root: classes.root, checked: classes.checked}} />} label="고객사(유료)" value={MailSendType.CUSTOM} />
      </RadioGroup>
      {/* )} */}
      {customMailSettings.mode === MailSendType.PEXPO && subEvent && (
        <PexpoContentContainer>
          <EventApplyMail
            language="kr"
            userName="user"
            eventName={"Jeongwon Kim"}
            eventStart={formatDate(subEvent.mainEvent?.startDate as string)}
            eventEnd={formatDate(subEvent.mainEvent?.endDate as string)}
            subEventName={subEvent.category as string}
            subEventStart={formatDate(subEvent.regStartDate as string)}
            subEventEnd={formatDate(subEvent.regEndDate as string)}
            date={subEvent.regEndDate as string}
            link="https://naver.com"
          />
          <FormContainer>
            <PxOutlinedTxtField style={{marginBottom: "20px"}} label="발신 이름" value="펙스포" inputProps={{readOnly: true}} />
            <PxOutlinedTxtField label="발신 이메일" value="info@pexpo.io" inputProps={{readOnly: true}} />
            {/* <PxOutlinedTxtField style={{padding: "20px 10px"}} label="회신 이메일" value="info@pexpo.io" inputProps={{readOnly: true}} /> */}
          </FormContainer>
        </PexpoContentContainer>
      )}
      {customMailSettings.mode === MailSendType.CUSTOM && customMailSettings && (
        <>
          <FormContainer>
            <PxOutlinedTxtField style={{margin: "20px 10px"}} label="발신 이름" value={customMailSettings.senderName} onChange={e => handleSystemSenderSetting("senderName", e)} />
            <div style={{display: "flex", alignItems: "center"}}>
              <PxOutlinedTxtField
                label="발신 이메일"
                style={{margin: "20px 10px", width: "80%"}}
                value={customMailSettings.senderMailAddress}
                placeholder="발신 이메일"
                onChange={e => handleSystemSenderSetting("senderMailAddress", e)}
                inputProps={{readOnly: mailSetting.mailRegistState === "REGISTERED"}}
              />
              {RegistStateBtn(mailSetting.mailRegistState)}
            </div>
            {/* <PxOutlinedTxtField style={{margin: "20px 10px"}} label="회신 이메일" value={customMailSettings.replyMailAddress} onChange={e => handleSystemSenderSetting("replyMailAddress", e)} /> */}
          </FormContainer>

          <EmailSettingContainer>
            {subEvent && (
              <EventApplyMail
                language="kr"
                userName="user"
                eventName={subEvent.mainEvent?.name as string}
                eventStart={formatDate(subEvent.mainEvent?.startDate as string)}
                eventEnd={formatDate(subEvent.mainEvent?.endDate as string)}
                subEventName={subEvent.category as string}
                subEventStart={formatDate(subEvent.subEventStartDate as string)}
                subEventEnd={formatDate(subEvent.subEventEndDate as string)}
                date={subEvent.regEndDate as string}
                link="https://naver.com"
                type="custom"
              />
            )}
            <EmailCustomContainer>
              {/* <Grid
                container
                alignContent="space-between"
                spacing={1}
                style={{
                  margin: "32px 0 0 0",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <SubHeadingTypo style={{margin: "0"}}>행사 로고</SubHeadingTypo>
                  <SubHeadingTypo>200*50px 권장</SubHeadingTypo>
                </Grid>
                {customMailSettings.systemMailConfig && (customMailSettings.systemMailConfig.logoImage === "" || customMailSettings.systemMailConfig.logoImage == null) && (
                  <Grid>
                    <Button
                      variant="contained"
                      component="label"
                      style={{
                        boxShadow: "none",
                        backgroundColor: "#eee",
                        padding: "9px 16px",
                      }}
                    >
                      등록
                      <input
                        type="file"
                        style={{display: "none"}}
                        name="file1"
                        accept="image/gif, image/jpeg, image/png"
                        onChange={e => {
                          handleImageAdd(e, "logo");
                        }}
                      />
                    </Button>
                  </Grid>
                )}
              </Grid>
              {customMailSettings.systemMailConfig && customMailSettings.systemMailConfig.logoImage != "" && customMailSettings.systemMailConfig.logoImage != null && (
                <Grid container direction="row">
                  <Grid sm={12} md={12} lg={12}>
                    <img style={{width: "100%"}} alt="event logo" src={customMailSettings.systemMailConfig.logoImage} />
                    <br />
                    <p style={{wordBreak: "break-all"}}>{customMailSettings.systemMailConfig.logoImage}</p>
                    <Button
                      variant="contained"
                      component="label"
                      style={{
                        boxShadow: "none",
                        backgroundColor: "#eee",
                        padding: "4.5px 8px",
                        margin: "5px 10px",
                      }}
                      onClick={deleteLogoImage}
                    >
                      삭제
                    </Button>
                  </Grid>
                </Grid>
              )} */}
              <PxOutlinedTxtField
                label="행사종류 입력 (예: 전시, 회의, 상담 등)"
                placeholder="행사종류 입력 (전시, 회의, 상담 등)"
                value={customMailSettings.systemMailConfig?.eventName || ""}
                onChange={e => handleSystemMailSettings("eventName", e)}
                borderColor="#ffa500"
                // hoverColor="#ffa500"
                focusColor="#ffa500"
              />
              <PxOutlinedTxtField
                label="운영사 입력"
                placeholder="운영사 입력"
                value={customMailSettings.systemMailConfig?.operator || ""}
                onChange={e => handleSystemMailSettings("operator", e)}
                borderColor="#008000"
                focusColor="#008000"
              />
              <PxOutlinedTxtField label="회신메일 변경" value={customMailSettings.systemMailConfig?.replyMailAddress || ""} onChange={e => handleSystemMailSettings("replyMailAddress", e)} />
              <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <SubHeadingTypo style={{fontWeight: "bold"}}>Pexpo 권한 copyright</SubHeadingTypo>
                <div style={{display: "flex", alignItems: "center"}}>
                  <label htmlFor="checkboxId" style={{margin: "10px"}}>
                    숨김
                  </label>
                  <input type="checkbox" id="checkboxId" checked={customMailSettings.systemMailConfig?.copyrightState === "HIDE" ? true : false} onChange={e => handleCopyrightHide(e)} />
                </div>
              </div>
            </EmailCustomContainer>
          </EmailSettingContainer>
        </>
      )}

      <div style={{display: "flex", justifyContent: "center", paddingTop: "20px"}}>
        <PxButton backgroundcolor="purple" onClick={saveCheck}>
          <ButtonTypo>저장</ButtonTypo>
        </PxButton>
      </div>

      <MailRegistModal />

      {/* 고객사로 저장 다이얼로그 */}
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {/* <DialogTitle id="alert-dialog-title">{"다이얼로그 제목"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText style={{color: "black"}}>유료버전입니다. 사용하시겠습니까?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <PxButton onClick={() => setOpen(false)} color="primary">
            <ButtonTypo>취소</ButtonTypo>
          </PxButton>
          <PxButton onClick={handleSaveBtn} color="primary" backgroundcolor="purple">
            <ButtonTypo>확인</ButtonTypo>
          </PxButton>
        </DialogActions>
      </Dialog>

      {/* 이메일 등록 다이얼로그 */}
      <Dialog open={mailCheckModal} onClose={() => setMailCheckModal(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {/* <DialogTitle id="alert-dialog-title">{"다이얼로그 제목"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText style={{color: "black", whiteSpace: "pre-wrap"}}>{`메일이 발송되었습니다. \n 메일 링크 확인 후 호스트 페이지로 돌아와 이메일 등록을 완료하세요.`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <PxButton onClick={() => setMailCheckModal(false)} color="primary" backgroundcolor="purple">
            <ButtonTypo>확인</ButtonTypo>
          </PxButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MailSetting;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const EmailSettingContainer = styled.div`
  display: flex;
  padding: 10px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

const EmailCustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid;
  width: calc(100% - 500px);
  padding: 10px;
  div {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

const PexpoContentContainer = styled.div`
  display: flex;

  @media screen and (max-width: 1150px) {
    flex-direction: column;
  }
`;
