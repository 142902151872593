import { AppActions, AppState } from "../store";
import { connectionCountry, countryTzCheck } from "../utils/momentTz";

// types
import { CountryApiInfo, CountryInfo } from "../types/models/Country";
import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";

export const setCountryAction = (country: CountryApiInfo): AppActions => ({
  type: "SET_COUNTRY",
  country,
});

export const setCountriesAction = (countries: CountryInfo): AppActions => ({
  type: "SET_COUNTRIES",
  countries,
});

export const setCountry = <Country>() => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  // 현재 접속위치
  let countryData = await connectionCountry();
  countryData["thisCountryTz"] = countryTzCheck(countryData.country.toString());

  // 관리자가 등록한 국가 목록 조회
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/countries`;
  try {
    var res: AxiosResponse = await axios.get(path, config);

    const countriesArray = res.data;
    if (countriesArray.length > 0) {
      let setCountries: any;
      countriesArray.map((country: CountryInfo) => {
        if (setCountries === undefined) {
          setCountries = {
            [country.code.toString()]: {
              koName: country.koName,
              enName: country.enName,
              timezone: country.timezone,
              // i18code: country.i18code, // 언어 따로 지정
            },
          };
        } else {
          Object.assign(setCountries, {
            [country.code.toString()]: {
              koName: country.koName,
              enName: country.enName,
              timezone: country.timezone,
              // i18code: country.i18code,
            },
          });
        }
      });
      await dispatch(setCountriesAction(setCountries));
    }

    await dispatch(setCountryAction(countryData));

    return true;
  } catch (err) {
    const error: Error = err.response.data;
  }
};

// 국가 선택
export const choiceCountry = <CountryApiInfo>(
  key: string,
  country: string,
  countryMomentCode: string
) => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
  let countryData = await connectionCountry();

  countryData["country"] = country;
  countryData["countryCode"] = key;
  countryData["thisCountryTz"] = countryMomentCode;

  dispatch(setCountryAction(countryData));
  return true;
};
