import moment from "moment";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export default ({
  startDate,
  endDate,
  setCalendarDate,
  setDate,
  setCalendarOpen,
}: {
  startDate?: string;
  endDate?: string;
  setCalendarDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setCalendarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const todayClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      let todayDate = moment();
      // 오늘 날짜 와 selectableStartDate,selectableEndDate 비교
      if (startDate && endDate && !todayDate.isBetween(startDate, endDate, undefined, "[]")) {
        todayDate = moment(startDate);
      }
      setDate(todayDate);
      setCalendarDate(todayDate);
      setCalendarOpen(false);
    },
    [startDate, endDate]
  );

  return <TodayBtn onClick={todayClick}>{t("calendar.today")}</TodayBtn>;
};

const TodayBtn = styled.button`
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  box-shadow: none;
  position: absolute;
  right: 0px;
  &:hover {
    border-radius: 7px;
    outline: none;
    background-color: #e9eaed;
  }
  &:focus {
    outline: none;
  }
`;
