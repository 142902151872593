import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { closeDialog } from "../../actions/dialogs";
import { AppState } from "../../store";
import PxButton from "../Buttons/PxButton";

/* 
1. 다이얼로그 안에 넣는건 자유
2. 다이얼로그 밖에 클릭시 창닫기 
3. 안에 클릭할시 창 닫히지 않음
4. width, bakcground, 버튼 등등 커스텀 가능
*/

export default () => {
  const dispatch = useDispatch();
  const dialogData = useSelector((state: AppState) => state.dialogs.pxDialog);

  const close = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    dispatch(closeDialog(e));
  };
  return dialogData.open && dialogData.children ? (
    <BackGround isBackgroundColor={(dialogData.isBackgroundColor = true)} onClick={close}>
      <Container width={dialogData.width} onClick={(e) => e.stopPropagation()}>
        {dialogData.children}
        <Footer justifyContent={(dialogData.footerJustifyContent = "flex-end")}>
          {dialogData.footer ? (
            dialogData.footer
          ) : (
            <>
              <PxButton
                backgroundcolor="transparent"
                boxshadow={false}
                txtColor="blue"
                onClick={close}
              >
                취소
              </PxButton>
              <PxButton backgroundcolor="transparent" boxshadow={false} txtColor="blue">
                확인
              </PxButton>
            </>
          )}
        </Footer>
      </Container>
    </BackGround>
  ) : null;
};

const BackGround = styled.div<{ isBackgroundColor: boolean }>`
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(props) => props.isBackgroundColor && "rgba(0, 0, 0, 0.5)"};
`;

const Container = styled.div<{ width?: string }>`
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 20px 20px 10px;
  max-width: 85vw;
  max-height: 85vh;
  min-width: 340px;
  min-height: 140px;
  width: ${(props) => props.width};
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
`;

const Footer = styled.footer<{
  justifyContent?: "flex-start" | "space-between" | "space-around" | "flex-end" | "center";
}>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
`;
