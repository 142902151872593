import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {AppState} from "../../../../store";
import {getRequestAndReturn} from "../../../../utils/utils";

interface Statistics {
  smsCount: number;
  lmsCount: number;
  totalCost: number;
}

const SmsStatistics = () => {
  const dispatch = useDispatch();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [statistics, setStatistics] = useState<Statistics>({
    smsCount: 0,
    lmsCount: 0,
    totalCost: 0,
  });

  useEffect(() => {
    if (subEvent?.id == null) return;
    getSmsStatistics(subEvent.id);
  }, [subEvent]);

  const getSmsStatistics = async (subEventId: string) => {
    const result: any = await dispatch(getRequestAndReturn(`/api/smss/${subEventId}/statistics`, {}));
    console.log(`result : `, result);
    setStatistics(result);
  };

  return (
    <PriceContainer>
      <ul style={{margin: "0"}}>
        <li>
          <BoldSpan>SMS : </BoldSpan>
          <span>
            <BlueIcon>{statistics.smsCount} </BlueIcon>/ 30원
          </span>
        </li>
        <li>
          <BoldSpan>LMS : </BoldSpan>
          <span>
            <BlueIcon>{statistics.lmsCount} </BlueIcon>/ 100원
          </span>
        </li>
        <BoldSpan>
          총 비용 : <BlueIcon>{statistics.totalCost}</BlueIcon> 원
        </BoldSpan>
      </ul>
    </PriceContainer>
  );
};

export default SmsStatistics;

const PriceContainer = styled.div`
  margin-left: auto;
  padding-right: 20px;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const BlueIcon = styled.span`
  color: blue;
`;
