import React, {useCallback, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {useHistory, RouteComponentProps} from "react-router-dom";
import {Column} from "material-table";
import _ from "lodash";

// actions
import {AppState} from "../../../../store";

// custom ui comp
import PxTable from "../../../../components/Table/PxTable";
import {getCounsels, resetCounsels} from "../../../../actions/counsels";
import {MeetingInfo} from "../../../../types/models/Meeting";
import {MeetingSurvey} from "../../../../types/models/Counsel";
import {setTableInfoLoadingAction} from "../../../../actions/tableInfo";
import {useTranslation} from "react-i18next";

const groupBy = require("json-groupby");

interface RowType {
  meetingDate: string;
  meetingTime: string;
  status: string;
  applicantName: string;
  acceptorName: string;
  isSurveyWrite: string;
}
interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}
interface MeetingSurveyExcelRefinedData {
  name?: string;
  modifiedDate?: string;
  otherCompanyName?: string;
  survey?: MeetingSurvey[];
  type?: string;
  date?: string;
  startTime?: string;
  endTime?: string;
  status?: string;
}

interface MeetingSurveyList {
  meetingId: string;
  meetingTime: string;
  status: string;
  applicantName: string;
  acceptorName: string;
}

const CounselList: React.FC<RouteComponentProps> = ({match}) => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const dispatch = useDispatch();
  const param: {id: string} = useParams();

  const {counsels} = useSelector((state: AppState) => state.counsels);
  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: "미팅 날짜",
        field: "meetingDate",
      },
      {
        title: "미팅 시간",
        field: "meetingTime",
      },
      {
        title: "체결 상태",
        field: "status",
      },
      {
        title: "미팅 신청",
        field: "applicantName",
      },
      {
        title: "미팅 수락",
        field: "acceptorName",
      },
      {
        title: "상담일지 작성",
        field: "isSurveyWrite",
      },
    ],
    data: [],
  });

  useEffect(() => {
    return () => {
      dispatch(resetCounsels());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callGetCounsels = useCallback(async () => {
    await dispatch(getCounsels(Number(param.id)));
    dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  useEffect(() => {
    callGetCounsels();
  }, [callGetCounsels]);

  useEffect(() => {
    if (counsels !== undefined && counsels.length !== 0) {
      const tableData: RowType[] = counsels.map(counsel => {
        const meetingData: RowType = {
          meetingDate: counsel.date as string,
          meetingTime: `${counsel.startTime} ~ ${counsel.endTime}`,
          status: counsel.status as string,
          applicantName: counsel.applicantName as string,
          acceptorName: counsel.acceptorName as string,
          isSurveyWrite: counsel.meetingSurveys.length === 0 ? "N" : "Y",
        };
        return meetingData;
      });

      setState({...state, data: tableData});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counsels]);

  return (
    <>
      {/* <div style={{ textAlign: "right", margin: "5px" }}>
        <PxButton
          backgroundcolor="purple"
          fullWidth
          onClick={() => {
            history.push(`/subEvent/${param.id}/counsel/counselExcel`);
          }}
          padding="15px 0"
        >
          <ButtonTypo>전체 상담일지 바로가기</ButtonTypo>
        </PxButton>
      </div> */}

      <PxTable<RowType>
        columns={state.columns}
        data={state.data}
        // onRowClick={(event, rowData, togglePanel) => togglePanel!()}
        // detailPanel={(rowData) => {
        //   return (
        //     <>
        //       <CounselListTabeItem
        //         meetingInfo={rowData.meetingList}
        //         companyName={rowData.name}
        //       />
        //     </>
        //   );
        // }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: false,
          defaultExpanded: false,
          search: false,
          selection: false,
          exportButton: false, // csv 다운
        }}
      />
    </>
  );
};

export default CounselList;

// const applicantNameList = groupBy(counsels, ["applicantName"]);
//       const acceptorNameList = groupBy(counsels, ["acceptorName"]);
//       const applicantsList = _.merge(applicantNameList, acceptorNameList);

//       const tableData = Object.entries(applicantsList).map(
//         ([applicantName, applicantMeetingList]: any) => {
//           let writtenSurveyCount = 0; // 작성한 상담일지 수
//           let type: string; // 기업의 type (seller or buyer)
//           let meetingList: MeetingInfo[] = []; // 상담일지를 작성한 meeting list
//           //   console.log(applicantMeetingList, "applicantMeetingList");
//           if (applicantMeetingList[0].meetingAcceptor.name === applicantName) {
//             type = applicantMeetingList[0].meetingAcceptor.type;
//           } else {
//             type = applicantMeetingList[0].meetingApplicant.type;
//           }
//           applicantMeetingList.forEach((applicantMeeting: any) => {
//             if (applicantMeeting.meetingAcceptor.name === applicantName) {
//               if (applicantMeeting.acceptorMeetingSurvey.length !== 0) {
//                 ++writtenSurveyCount;
//                 meetingList.push(applicantMeeting);
//               }
//             } else {
//               if (applicantMeeting.applicantMeetingSurvey.length !== 0) {
//                 ++writtenSurveyCount;
//                 meetingList.push(applicantMeeting);
//               }
//             }
//           });

//           return {
//             name: applicantName,
//             type: type,
//             written: writtenSurveyCount,
//             noWritten: applicantMeetingList.length - writtenSurveyCount,
//             meetingList: meetingList,
//           };
//         }
//       );
