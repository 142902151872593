import { RegitDeskApplicants } from "../types/models/Applicants";
import ApplicantsActionTypes from "../types/actions/ApplicantsAction";
import { RegisterUserPage, RegitUser } from "../types/models/Registration";

interface ApplicantsReduxStateProps {
  applicants?: RegitDeskApplicants[];
  applicant?: RegitDeskApplicants;
  regitUsers?: RegisterUserPage;
}

const applicantsDefaultState: ApplicantsReduxStateProps = {};

const applicantsReducer = (
  state = applicantsDefaultState,
  action: ApplicantsActionTypes
): ApplicantsReduxStateProps => {
  switch (action.type) {
    case "GET_APPLICANT":
      return { ...state, applicant: action.applicant };
    case "GET_APPLICANTS":
      return { ...state, applicants: action.applicants };
    case "RESET_APPLICANT":
      return { ...state, applicant: undefined, regitUsers: undefined };
    case "GET_REGIT_USER":
      return { ...state, regitUsers: action.regitUsers };
    default:
      return state;
  }
};

export default applicantsReducer;
