import React from "react";

// custom comp
import GridContainer from "../../components/Grid/PxGridContainer";
import HomeTable from "./HomeTable";

const Home: React.FC = (props) => {
  return (
    <>
      <GridContainer parent={true}>
        <HomeTable />
      </GridContainer>
    </>
  );
};

export default Home;
