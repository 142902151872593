import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams, RouteComponentProps, useLocation} from "react-router-dom";
import {Column} from "material-table";
import {Chip, Grid, MenuItem, OutlinedInput, Tab, Tabs, TypographyProps} from "@material-ui/core";
import styled from "styled-components";
// actions
import {createMail, getMailSetting, getMailTargets, getRegisterMailTargets, getRequestInfo, groupMailSend} from "../../../../actions/mail";
import {AppState} from "../../../../store";

// custom ui comp
import PxTable from "../../../Table/PxTable";
import SubHeadingTypo from "../../../Typhography/SubHeadingTypo";
import OutlinedTxtField from "../../../Inputs/OutlinedTxtField";
import {GroupMailSendReq, MailContentType, MailInfo, MailRecipient} from "../../../../types/models/Mail";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";
import {setDialog} from "../../../../actions/dialogs";
import {confirmAlert} from "react-confirm-alert";
import {confirmModalOptions} from "../../../../utils/confirmModalOptions";
import PxOutlinedFormControl from "../../../Forms/PxOutlinedFormControl";
import PxSelectBox from "../../../SelectBox/PxSelectBox";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import {setTableInfoLoadingAction} from "../../../../actions/tableInfo";
import {useTranslation} from "react-i18next";
import {removeLoadingAction, setLoadingAction} from "../../../../actions/loading";
import Body1Typo from "../../../Typhography/Body1Typo";
import {getSubEvent} from "../../../../actions/subEvents";
import {makeStyles} from "@material-ui/core/styles";
import PxEditor from "../../../Editor/PxEditor";
import MailEditor from "./MailEditor";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const {...rest} = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const Folder = styled.div<{open: boolean}>`
  overflow: hidden;
  display: ${props => (props.open ? "none" : "block")};
  /* transition: all 0.3s; */
`;

const FolderBtn = styled.span`
  float: right;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
`;

interface RowType {
  applicantId: string;
  approval: string;
  category: string;
  company: string;
  email: string;
  keyword: string;
  method: string;
  name: string;
  type: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

interface GroupMailRes {
  applicationId: string;
  approval: string;
  category: string;
  company: string;
  email: string;
  keyword: string;
  method: string;
  name: string;
  phoneNumber: string;
}

const GroupMailAdd: React.FC<RouteComponentProps> = ({match}) => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const param: {id: string; secondId: string} = useParams();

  const user = useSelector((state: AppState) => state.users);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const {mailTargets, setting, mailRequestInfo} = useSelector((state: AppState) => state.mails);
  const resendUsers = useSelector((state: AppState) => state.mails.mailResend?.targetUsers);
  const [folder, setfolder] = useState<boolean>(false);
  const [selectParam, setSelectParam] = useState("");
  const [contentType, setContentType] = useState<MailContentType>(MailContentType.COMMON);
  const [templateContent, setTemplateContent] = useState<string>("");
  const [commonContent, setCommonContent] = useState<string>("");
  const [mail, setMail] = useState<MailInfo>({
    subEventId: `${param.id}`,
    senderName: user.company,
    recipient: [],
    title: "",
    content: "",
  });
  const [toUserList, setToUserList] = useState<number[]>([]);

  /**
   *    재전송 페이지가 아닐 경우 subEvent의 유저 리스트를 불러옴
   */
  useEffect(() => {
    if (location.pathname.includes("resend")) return;
    callMailTargets(subEvent);
  }, [subEvent, location]);

  useEffect(() => {
    if (location.pathname.includes("requestCopy")) {
      dispatch(getRequestInfo(Number(param.secondId)));
    }
  }, [location, param]);

  /**
   *    이메일 설정 값이 없으면 호출
   */
  useEffect(() => {
    if (setting.id < 1 || !subEvent) return;
    dispatch(getMailSetting(Number(subEvent?.id as string)));
  }, [setting, subEvent]);

  /**
   *    이메일 설정값이 있으면 보내는 사람 이름 설정
   */
  useEffect(() => {
    if (setting.id == 0) return;
    setMail(prev => {
      return {...prev, senderName: setting.senderName};
    });
  }, [setting, setMail]);

  /**
   *    재전송 페이지인 경우 보낼 대상 값이 없으면 이전 페이지로 리턴
   */
  useEffect(() => {
    if (location.pathname.includes("resend") && mailRequestInfo == undefined) {
      history.goBack();
    }
  }, [location, mailRequestInfo]);

  /**
   *    resend 일때 기본 값 세팅
   */
  useEffect(() => {
    if (!location.pathname.includes("resend")) return;
    if (mailRequestInfo == undefined) return;
    if (resendUsers == undefined || resendUsers.length < 1) return;

    const targetIds: number[] = resendUsers.map(user => user.id);
    setToUserList(targetIds);

    setMail(prev => {
      return {
        ...prev,
        title: mailRequestInfo.title,
      };
    });

    setContentType(mailRequestInfo.mailContentType as MailContentType);

    mailRequestInfo.mailContentType === MailContentType.COMMON ? setCommonContent(mailRequestInfo.content) : setTemplateContent(mailRequestInfo.content);
  }, [location, mailRequestInfo, resendUsers, setMail]);

  /**
   *    copy일때 기본 값 세팅
   */
  useEffect(() => {
    if (!location.pathname.includes("requestCopy")) return;
    if (mailRequestInfo == undefined) return;

    setMail(prev => {
      return {
        ...prev,
        title: mailRequestInfo.title,
      };
    });

    setContentType(mailRequestInfo.mailContentType as MailContentType);

    mailRequestInfo.mailContentType === MailContentType.COMMON ? setCommonContent(mailRequestInfo.content) : setTemplateContent(mailRequestInfo.content);
  }, [location, mailRequestInfo, setMail]);

  const callMailTargets = useCallback(
    async subEvent => {
      if (!subEvent) return;
      if (subEvent.category == "registration") {
        const res: any = await dispatch(getRegisterMailTargets(Number(param.id)));
        const resData: GroupMailRes[] = res;
        const registerData: RowType[] = resData.map(item => {
          const convertData: RowType = {
            applicantId: item.applicationId,
            approval: item.approval,
            category: item.category,
            company: item.company,
            email: item.email,
            keyword: item.keyword,
            method: item.method,
            name: item.name,
            type: "",
          };
          return convertData;
        });

        setState(prevCreateDate => {
          return {...prevCreateDate, data: registerData};
        });
        dispatch(setTableInfoLoadingAction(false));
      } else {
        await dispatch(getMailTargets(param.id));
        dispatch(setTableInfoLoadingAction(false));
      }
    },
    [dispatch, param.id]
  );

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("mailAdd.group"),
        field: "type",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: t("common.email"),
        field: "email",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: t("common.company"),
        field: "company",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: t("common.name"),
        field: "name",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: t("mailAdd.category"),
        field: "category",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: t("mailAdd.keyword"),
        field: "keyword",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: t("mailAdd.approvalStatus"),
        field: "approval",
        cellStyle: {width: "100px", paddingRight: 0},
      },
    ],
    data: [],
  });

  useEffect(() => {
    let tableData: RowType[] = [];
    if (Array.isArray(mailTargets) && mailTargets.length > 0 && subEvent) {
      mailTargets
        .filter(data => data.type?.includes(selectParam))
        .map(mailTarget => {
          const rowData: RowType = {
            approval: mailTarget.approval || "",
            category: mailTarget.category || "",
            company: mailTarget.company || "",
            email: mailTarget.email || "",
            keyword: mailTarget.keyword || "",
            method: mailTarget.method || "",
            name: mailTarget.name || "",
            type: mailTarget.type === "seller" ? subEvent.sgroupName : subEvent.bgroupName,
            applicantId: mailTarget.usersId || "",
          };
          tableData.push(rowData);
        });
    }
    setState(prevCreateDate => {
      return {...prevCreateDate, data: tableData};
    });
  }, [selectParam]);

  // 메일 대상 선택
  const changeTarget = (changeData: RowType[]) => {
    let targetArray: MailRecipient[] = [];

    const userIdList: number[] = changeData.map((data: RowType) => Number(data.applicantId));

    setToUserList(userIdList);

    changeData.map((data: RowType) => {
      targetArray.push({
        email: data.email,
        company: data.company,
        name: data.name,
        recipientId: data.applicantId,
      });
    });
    setMail({...mail, recipient: targetArray});
  };

  // 이벤트 컨텐츠 내용(에디터) 변경
  const handleContentChange = (value: string) => {
    //태그 넣어보기
    setMail({
      ...mail,
      content: value as string,
    });
  };

  // 메일 내용 변경
  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    setMail({
      ...mail,
      [e.target.name as string]: e.target.value as string,
    });
  };

  useEffect(() => {
    setMail(prev => {
      return {...prev, content: contentType === MailContentType.TEMPLATE ? templateContent : commonContent};
    });
  }, [contentType, templateContent, commonContent]);

  const createRecipientsChips = () => {
    if (location.pathname.includes("resend")) {
      return resendUsers?.map((user, idx) => {
        return <Chip key={`email-${idx}`} label={user.email} color="primary" variant="outlined" style={{margin: "3px 10px 3px 0"}} />;
      });
    } else {
      if (mail.recipient !== undefined) {
        return mail.recipient.map((user, idx) => {
          return <Chip key={`email-${idx}`} label={user.email} color="primary" variant="outlined" style={{margin: "3px 10px 3px 0"}} />;
        });
      } else {
        return <div style={{alignSelf: "center", color: "#888"}}>{t("mailAdd.selectItFromTheList")}</div>;
      }
    }
  };

  // 메일 발송
  const onSubmit = async () => {
    // e.preventDefault();
    setMail({...mail, senderName: mail.senderName?.trim()});

    const content = extractCodeBlockContent(mail.content);

    confirmAlert(
      confirmModalOptions({
        title: "메일을 발송하시겠습니까??",
        click: async () => {
          dispatch(setLoadingAction());

          if (subEvent?.category == "registration") {
            const groupMailSendReq: GroupMailSendReq = {
              subEventId: Number(param.id),
              title: mail.title,
              contentType: contentType,
              content: content,
              userList: toUserList,
            };
            const sendRes = await dispatch(groupMailSend(groupMailSendReq));
            dispatch(removeLoadingAction());
            history.goBack();
          } else {
            const mailRes = await dispatch(createMail(mail));
            dispatch(removeLoadingAction());
            if (mailRes.toString() === "success") {
              dispatch(setDialog(`메일 발송`, ["메일이 발송되었습니다."]));

              history.push(`/subEvent/${param.id}/mail`);
            }
          }
        },
      })
    );
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, tabValue: MailContentType) => {
    setContentType(tabValue);
    // window.localStorage.setItem("mailTab", JSON.stringify(newTapNumber));
  };

  const extractCodeBlockContent = (content: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const codeBlockElement = doc.querySelector(".ql-syntax");
    return codeBlockElement?.textContent ?? content;
  };

  return (
    <form>
      {!location.pathname.includes("resend") && (
        <>
          <SubTitle>
            {t("mailAdd.list")}
            <FolderBtn onClick={() => setfolder(!folder)}>
              {folder ? (
                <>
                  {t("mailAdd.open")} <ArrowDropDownIcon />
                </>
              ) : (
                <>
                  {t("mailAdd.fold")} <ArrowDropUpIcon />
                </>
              )}
            </FolderBtn>
          </SubTitle>

          <Folder open={folder}>
            <Grid
              container
              direction="row"
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                minHeight: "60px",
              }}
            >
              <PxTable<RowType>
                // components={{
                //   Toolbar: (props) => {
                //     return (
                //       <PxGridContainer direction="column">
                //         {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                //         <MTableToolbar {...props} />
                //       </PxGridContainer>
                //     );
                //   },
                // }}
                title={
                  <div
                    style={{
                      width: "100%",

                      margin: 5,
                    }}
                  >
                    <PxOutlinedFormControl>
                      <PxSelectBox
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px",
                        }}
                        value={selectParam}
                        onChange={e => setSelectParam(e.target.value as string)}
                        displayEmpty
                        input={<OutlinedInput margin="dense" />}
                      >
                        <MenuItem value={""}>{t("mailAdd.all")}</MenuItem>
                        <MenuItem value={"seller"}>{subEvent ? subEvent.sgroupName : "SELLER"}</MenuItem>
                        <MenuItem value={"buyer"}>{subEvent ? subEvent.bgroupName : "BUYER"}</MenuItem>
                      </PxSelectBox>
                    </PxOutlinedFormControl>
                  </div>
                }
                columns={state.columns}
                data={state.data}
                // material-table 속성: https://material-table.com/#/docs/features/search
                options={{
                  toolbar: true,
                  actionsColumnIndex: -1,
                  paging: false,
                  headerStyle: {position: "sticky", top: 0},
                  maxBodyHeight: "300px",
                  // pageSize: 10,
                  showTitle: true,
                  defaultExpanded: false,
                  search: true,
                  selection: true,
                  searchFieldAlignment: "right",
                  exportButton: false, // csv 다운
                }}
                onSelectionChange={rows => changeTarget(rows)}
              />
            </Grid>
          </Folder>
        </>
      )}
      <SubTitle></SubTitle>
      <SubTitle>{t("mailAdd.recipient")}</SubTitle>
      <Grid
        container
        direction="row"
        style={{
          padding: "10px",
          minHeight: "60px",
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        }}
      >
        {createRecipientsChips()}
      </Grid>
      <SubTitle> {t("common.title")}</SubTitle>
      <Grid container direction="row">
        <OutlinedTxtField fullWidth onChange={handleOnChange} name="title" value={mail.title} placeholder={t("mailAdd.typeTheTitle")} />
      </Grid>
      <SubTitle> {t("common.content")}</SubTitle>
      <Tabs value={contentType} onChange={handleTabChange} TabIndicatorProps={{style: {backgroundColor: "purple"}}} style={{marginBottom: "20px"}}>
        <Tab label="일반" value={MailContentType.COMMON} />
        <Tab label="템플릿" value={MailContentType.TEMPLATE} />
      </Tabs>
      {contentType === MailContentType.COMMON && (
        <div>
          <Body1Typo>{t("mailAdd.contentTip")}</Body1Typo>
          <PxEditor value={commonContent} onChange={setCommonContent} folderPath="event/" placeholder={t("mailAdd.typeTheContent")} />
        </div>
      )}
      {contentType === MailContentType.TEMPLATE && subEvent?.mainEvent && (
        <>
          <MailEditor
            eventId={subEvent?.mainEvent?.id as number}
            eventMainImage={subEvent.mainEvent?.image == "" ? subEvent.mainEvent.mainImageFile.filePath : subEvent.mainEvent?.image}
            logoImage={subEvent.mainEvent?.logoImageFile == null ? "" : subEvent.mainEvent?.logoImageFile.filePath}
            setTemplateContent={setTemplateContent}
          />
        </>
      )}
      <Grid container alignContent="space-between" spacing={1}>
        <Grid item>
          <PxButton backgroundcolor="bluePurple" onClick={e => onSubmit()}>
            <ButtonTypo txtcolor="white">{t("mailList.send")}</ButtonTypo>
          </PxButton>
        </Grid>
        <Grid item>
          <PxButton backgroundcolor="grey" onClick={() => history.goBack()}>
            <ButtonTypo>{t("common.cancel")}</ButtonTypo>
          </PxButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default GroupMailAdd;

const useStyles = makeStyles(theme => ({
  quill: {
    display: "inline-block",
    width: "100%",
    height: "100%",
    "& .ql-editor": {
      minHeight: 200,
      maxHeight: 800,
      height: "auto",
    },
  },
}));
