import React, { useEffect, useState } from "react";
import Estimate, {
  CalendarSelector,
  InputNumber,
  Item,
  Divider,
  Price,
  PexpoAccount,
  Taxbill,
  LoadFileInput,
  Amount,
  DialogImgBtn,
  ENUM_PRICE,
  returnWithDotPrice,
} from "./Estimate";
import PxSwitch from "../../switch/PxSwitch";
import PxButton from "../../Buttons/PxButton";
import { fileStateToInitial, getFile, getFiles, getSignedRequest } from "../../../actions/files";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import moment from "moment";
import { createEstimate, modifyEstimate } from "../../../actions/estimate";
import { useHistory } from "react-router-dom";
import Caption2Typo from "../../Typhography/Caption2Typo";
import { getOnlyFileName } from "../../../utils/utils";
import { newAlert } from "../../../actions/alerts";

export interface EstimateProps {
  subEventId: number;
  maxUsers: number;
  type: "estimate" | "quoation";
  numberOfPrinter: number;
  printStartDate: string;
  printEndDate: string;
  numberOfPassPocket: number;
  customDesign: boolean;
  customRolls: number;
  customDesignImg: number;
  supporters: number;
  supportStartDate: string;
  supportEndDate: string;
  totalPrice: number;
  deposit: number;
  finalPrice: number;
  taxBillEmail: string;
  businessRegistration: number;
}

export default () => {
  const dispatch = useDispatch();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent); // layout 페이지에서 subEvent data load
  const user = useSelector((state: AppState) => state.users);
  const files = useSelector((state: AppState) => state.files);
  const [filesName, setFilesName] = useState({
    customDesign: { name: "", path: "" },
    businessRegistration: { name: "", path: "" },
  });
  const now = moment().format("YYYY-MM-DD");
  const [isModify, setIsModify] = useState<boolean>(false);
  const history = useHistory();
  const [estimateData, setEstimateData] = useState<EstimateProps>({
    subEventId: 0,
    maxUsers: 0,
    type: "estimate", // 예상견적 , admin : 최종견적
    numberOfPrinter: 0,
    printStartDate: now,
    printEndDate: now,
    numberOfPassPocket: 0,
    customDesign: false,
    customRolls: 0,
    customDesignImg: 0,
    supporters: 1,
    supportStartDate: now,
    supportEndDate: now,
    totalPrice: 0,
    deposit: 0,
    finalPrice: 0,
    taxBillEmail: user.taxInvoiceMail || "",
    businessRegistration: parseFloat(user.businessImageId || ""),
  });

  useEffect(() => {
    if (user && user.taxInvoiceMail && user.businessImageId) {
      setEstimateData({
        ...estimateData,
        taxBillEmail: user.taxInvoiceMail,
        businessRegistration: parseFloat(user.businessImageId),
      });
      getImageFile(parseFloat(user.businessImageId));
    }
  }, [user]);
  const getImageFile = async (id: number) => {
    const result: any = await dispatch(getFile(id));
    setFilesName({
      ...filesName,
      businessRegistration: { name: result.fileName, path: result.filePath },
    });
  };
  useEffect(() => {
    if (
      subEvent &&
      subEvent.estimates &&
      subEvent.estimates.length > 0 &&
      subEvent.estimates.some((data) => data.type === "estimate")
    ) {
      const estimate = subEvent.estimates.find((data) => data.type === "estimate")!;
      setIsModify(true);
      setEstimateData({
        ...estimateData,
        subEventId: estimate.subEventId.id,
        maxUsers: estimate.maxUsers,
        type: estimate.type,
        numberOfPrinter: estimate.numberOfPrinter || 0,
        printStartDate: estimate.printStartDate || now,
        printEndDate: estimate.printEndDate || now,
        numberOfPassPocket: estimate.numberOfPassPocket || 0,
        customDesign: estimate.customDesign,
        customRolls: estimate.customRolls || 0,
        customDesignImg: estimate.customDesignImg ? estimate.customDesignImg.id : 0,
        supporters: estimate.supporters || 0,
        supportStartDate: estimate.supportStartDate || now,
        supportEndDate: estimate.supportEndDate || now,
        totalPrice: estimate.totalPrice,
        deposit: estimate.deposit,
        finalPrice: estimate.totalPrice - estimate.deposit,
        taxBillEmail: estimate.taxBillEmail || user.taxInvoiceMail || "",
        businessRegistration: estimate.businessRegistration.id,
      });
      setFilesName({
        customDesign: {
          name: estimate.customDesignImg ? estimate.customDesignImg.fileName : "",
          path: estimate.customDesignImg ? estimate.customDesignImg.filePath : "",
        },
        businessRegistration: {
          name: estimate.businessRegistration.fileName,
          path: estimate.businessRegistration.filePath,
        },
      });
    }
  }, [subEvent]);

  //데이터 입력
  const onChagne = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = event.currentTarget.name;

    //숫자 입력
    if (
      (name === "maxUsers" ||
        name === "numberOfPrinter" ||
        name === "numberOfPassPocket" ||
        name === "customRolls" ||
        name === "supporters") &&
      (!isNaN(Number(event.currentTarget.value)) || event.currentTarget.value === "")
    ) {
      //숫자만 입력가능하게 replace
      const value =
        event.currentTarget.value === ""
          ? 0
          : parseInt(event.currentTarget.value.replace(/[^0-9]/g, ""));

      // 날짜 카운트 , 같은 날짜 값 = 1
      const printDateCount =
        moment(estimateData.printEndDate, "YYYY-MM-DD").diff(
          moment(estimateData.printStartDate, "YYYY-MM-DD"),
          "day"
        ) + 1;
      const supportDateCount =
        moment(estimateData.supportEndDate, "YYYY-MM-DD").diff(
          moment(estimateData.supportStartDate, "YYYY-MM-DD"),
          "day"
        ) + 1;

      const data: CaulatorProps[] = [
        { num: name === "maxUsers" ? value : estimateData.maxUsers, price: ENUM_PRICE.USER },
        {
          num: (name === "numberOfPrinter" ? value : estimateData.numberOfPrinter) * printDateCount,
          price: ENUM_PRICE.PRINTER,
        },
        {
          num: name === "numberOfPassPocket" ? value : estimateData.numberOfPassPocket,
          price: ENUM_PRICE.PASSPOCKET,
        },
        {
          num: (name === "supporters" ? value : estimateData.supporters) * supportDateCount,
          price: ENUM_PRICE.SUPPORTER,
        },
        { num: name === "customRolls" ? value : estimateData.customRolls, price: ENUM_PRICE.CUSTOMROLLS },
      ];
      const totalPrice = caulator(data);
      setEstimateData({
        ...estimateData,
        [name]: value,
        totalPrice,
        finalPrice: totalPrice - estimateData.deposit,
      });
    }
    // 날짜 입력
    if (
      name === "printStartDate" ||
      name === "printEndDate" ||
      name === "supportStartDate" ||
      name === "supportEndDate"
    ) {
      const value = event.currentTarget.value;
      const printDateCount =
        moment(name === "printEndDate" ? value : estimateData.printEndDate, "YYYY-MM-DD").diff(
          moment(name === "printStartDate" ? value : estimateData.printStartDate, "YYYY-MM-DD"),
          "day"
        ) + 1;
      const supportDateCount =
        moment(name === "supportEndDate" ? value : estimateData.supportEndDate, "YYYY-MM-DD").diff(
          moment(name === "supportStartDate" ? value : estimateData.supportStartDate, "YYYY-MM-DD"),
          "day"
        ) + 1;

      const data: CaulatorProps[] = [
        { num: estimateData.maxUsers, price: ENUM_PRICE.USER },
        {
          num: estimateData.numberOfPrinter * printDateCount,
          price: ENUM_PRICE.PRINTER,
        },
        {
          num: estimateData.numberOfPassPocket,
          price: ENUM_PRICE.PASSPOCKET,
        },
        {
          num: estimateData.supporters * supportDateCount,
          price: ENUM_PRICE.SUPPORTER,
        },
        { num: estimateData.customRolls, price: ENUM_PRICE.CUSTOMROLLS },
      ];
      const totalPrice = caulator(data);
      setEstimateData({
        ...estimateData,
        [name]: event.currentTarget.value,
        totalPrice,
        finalPrice: totalPrice - estimateData.deposit,
      });
    } else if (name === "taxBillEmail") {
      setEstimateData({
        ...estimateData,
        [name]: event.currentTarget.value,
      });
    }
  };

  //파일 등록 // 디스패치로 -> 파일검사 -> s3등록 -> 결과 fileSeletor 에서 추출후 파일이름 보여주고 id 값 estimate에 입력
  const handleImageAdd = (
    e: React.ChangeEvent<{
      files: any;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();
    const file = e.target.files[0];
    const originalFileName = file.name.split(".");
    originalFileName.splice(-1, 1);
    const timestamp = new Date().getTime();
    const fileType = file.type.split("/")[1];
    const fileName = originalFileName.join() + "&&" + timestamp + "." + fileType;
    const folderPath = e.currentTarget.name === "customDesign" ? "subEvent/passDesign/" : "auth/";
    const fileSize = file.size;
    const gubun = e.currentTarget.name as string; // 여러파일등록시 어떤 파일을 등록했는지 구분

    dispatch(getSignedRequest(file, fileName, fileType, folderPath, fileSize, gubun));
  };
  //파일 등록결과
  useEffect(() => {
    if (files.id !== "") {
      files.gubun === "businessRegistration"
        ? setFilesName({
            ...filesName,
            businessRegistration: { name: files.fileName, path: files.filePath },
          })
        : setFilesName({
            ...filesName,
            customDesign: { name: files.fileName, path: files.filePath },
          });
      files.gubun === "businessRegistration"
        ? setEstimateData({ ...estimateData, businessRegistration: parseFloat(files.id) })
        : setEstimateData({ ...estimateData, customDesignImg: parseFloat(files.id) });
    }
    // 리덕스 초기화
    dispatch(fileStateToInitial());
  }, [files]);

  const submitBtn = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (!subEvent || !subEvent.id) {
      return dispatch(newAlert("새로고침 후 다시 시도해 주세요", estimateData.subEventId + ""));
    } else if (estimateData.businessRegistration <= 0) {
      return dispatch(newAlert("사업자등록증을 추가해 주세요", estimateData.subEventId + ""));
    } else if (estimateData.customDesign && estimateData.customRolls <= 9) {
      return dispatch(
        newAlert("커스텀 출입증은 10롤 이상부터 주문할 수 있습니다", estimateData.subEventId + "")
      );
    } else if (estimateData.supporters <= 0) {
      return dispatch(newAlert("기술지원은 최소 1명이 필요합니다", estimateData.subEventId + ""));
    }
    const result: any = isModify
      ? await dispatch(modifyEstimate({ ...estimateData }))
      : await dispatch(createEstimate({ ...estimateData, subEventId: parseFloat(subEvent.id) }));
    if (result) {
      history.goBack();
    }
  };

  return (
    <Estimate title="회의 견적내기" subEvent={subEvent!}>
      <Item
        title="무선 출입증 프린터"
        subTitle={`1대당 ₩${returnWithDotPrice(ENUM_PRICE.PRINTER)}`}
        input={
          <InputNumber
            name="numberOfPrinter"
            value={estimateData.numberOfPrinter}
            onChange={onChagne}
          />
        }
      />
      <Item
        title="대여 기간"
        subTitle="시작일 하루전 설치 및 테스트"
        justify="flex-start"
        input={
          <>
            <CalendarSelector
              name="printStartDate"
              value={estimateData.printStartDate}
              onChange={onChagne}
            />{" "}
            -{" "}
            <CalendarSelector
              name="printEndDate"
              value={estimateData.printEndDate}
              onChange={onChagne}
            />
          </>
        }
      />
      <Divider />
      <Item
        title="출입증 목걸이"
        subTitle={
          <>
            1개당 ₩{returnWithDotPrice(ENUM_PRICE.PASSPOCKET)} [
            <DialogImgBtn
              title="출입증 기본 디자인 보기"
              link="https://pexpo2.s3.ap-northeast-2.amazonaws.com/images/name_badge.png"
            />
            ]
          </>
        }
        input={
          <InputNumber
            name="numberOfPassPocket"
            value={estimateData.numberOfPassPocket}
            onChange={onChagne}
          />
        }
      />
      <Divider />
      <Item
        title="커스텀 출입증 디자인"
        subTitle="10 x 13(cm) 사이즈로 제작"
        input={
          <PxSwitch
            checked={estimateData.customDesign}
            onChange={() =>
              setEstimateData({
                ...estimateData,
                customDesign: !estimateData.customDesign,
                customRolls: !estimateData.customDesign ? 10 : 0,
              })
            }
            label=""
          />
        }
      />

      {estimateData && estimateData.customDesign && (
        <>
          <Item
            title="디자인 시안"
            subTitle={
              filesName.customDesign.name ? (
                <DialogImgBtn
                  title={filesName.customDesign.name}
                  link={filesName.customDesign.path}
                />
              ) : (
                "없음"
              )
            }
            input={
              <LoadFileInput
                name="customDesignImg"
                onChange={handleImageAdd}
                accept="image/jpeg, image/png"
              />
            }
          />
          <Item
            title="주문 수량"
            subTitle={`1롤당 ₩${returnWithDotPrice(ENUM_PRICE.CUSTOMROLLS)} / 1롤당 250장 인쇄 / 최소 주문수량 10롤`}
            input={
              <InputNumber
                name="customRolls"
                value={estimateData.customRolls}
                onChange={onChagne}
              />
            }
          />
        </>
      )}

      <Item
        title="기술지원"
        subTitle={`1인당 ₩${returnWithDotPrice(ENUM_PRICE.SUPPORTER)} / 최소 1명 필수`}
        input={
          <InputNumber
            name="supporters"
            defaultValue={1}
            value={estimateData.supporters}
            onChange={onChagne}
          />
        }
      />
      <Item
        title="지원 기간"
        subTitle="시작일 하루전 오리엔테이션 포함"
        justify="flex-start"
        input={
          <>
            <CalendarSelector
              name="supportStartDate"
              value={estimateData.supportStartDate}
              onChange={onChagne}
            />{" "}
            -
            <CalendarSelector
              name="supportEndDate"
              value={estimateData.supportEndDate}
              onChange={onChagne}
            />
          </>
        }
      />
      <Amount amount={estimateData.finalPrice} />
      <Caption2Typo style={{ marginBottom: 22 }}>
        ※ 잔금은 이벤트 종료 후 2일 이내 입금요망
      </Caption2Typo>

      <PexpoAccount />

      <Taxbill
        name="taxBillEmail"
        onChange={onChagne}
        value={estimateData.taxBillEmail}
        subTitle={
          filesName.businessRegistration.name ? (
            <DialogImgBtn
              title={filesName.businessRegistration.name}
              link={filesName.businessRegistration.path}
            />
          ) : (
            "없음"
          )
        }
        nameInput="businessRegistration"
        onChangeInput={handleImageAdd}
      />

      <PxButton
        boxshadow={false}
        fullWidth
        backgroundcolor="#512da8"
        noBorderRadius={true}
        txtColor="white"
        onClick={submitBtn}
      >
        저장하기
      </PxButton>
    </Estimate>
  );
};

//합계 계산기
interface CaulatorProps {
  num: number; //개수
  price: number; //개당 가격
}

function caulator(props: CaulatorProps[]) {
  let amount: number = 0;
  props.forEach((item) => {
    amount += item.num * item.price;
  });
  return amount;
}
