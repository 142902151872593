import { AppActions, AppState } from "../store";
import axios, { AxiosResponse } from "axios";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import { getRequest, patchRequest, postRequest, postRequestAndReturn, putRequest } from "../utils/utils";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { setAlert } from "../utils/utils";

// types
import { RegistSubEventReq, SubEventInfo } from "../types/models/SubEvent";
import { Dispatch } from "react";

export const getSubEventAction = (subEvent: SubEventInfo): AppActions => ({
  type: "GET_SUB_EVENT",
  subEvent: subEvent,
});

export const removeSubEventAction = (): AppActions => ({
  type: "REMOVE_SUB_EVENT",
});

export const getSubEventsAction = (subEvents: SubEventInfo[]): AppActions => ({
  type: "GET_SUB_EVENTS",
  subEvents,
});

export const resetSubEventsAction = (): AppActions => ({
  type: "RESET_SUB_EVENTS",
});

export const modifyPaymentAction = (subEvent: SubEventInfo): AppActions => ({
  type: "MODIFY_PAYMENT",
  subEvent,
});

export const modifyCounselFormOnlyEngAction = (subEvent: Pick<SubEventInfo,'id'|'counselFormOnlyEng'>): AppActions => ({
  type: "MODIFY_COUNSEL_ONLYENG",
  subEvent,
});

export const modifyMeetingAvailableAction = (subEvent: Pick<SubEventInfo,'meetingAvailable'>): AppActions => ({
  type: "MODIFY_MEETING_AVAILABLE",
  subEvent
});

///////////////////////////// nextrise 미팅 목적 공개 여부 이벤트

export const toggleMeetingPurposeOpenAction = (meetingPurposeOpen:"Y" | "N"): AppActions => ({
  type: "TOGGLE_MEETING_PURPOSE",
  meetingPurposeOpen
})

export const toggleMeetingPurpose = (subEventId: number) => {
  return postRequest(
    { subEventId:subEventId },
    "/api/subEvents/toggleMeetingPurpose",
    toggleMeetingPurposeOpenAction
  )
}

////////////////////////////////////////////////////////////////

export const getSubEvent = (subEventId: number) => {
  return getRequest(subEventId, `/api/subEvents/detail`, getSubEventAction, {
    id: subEventId,
  });
};

export const removeSubEvent = () => (dispatch: Dispatch<AppActions>) => {
  dispatch(removeSubEventAction());
};

export const createSubEvent = (subEvent: SubEventInfo) => {
  if (subEvent.matching === "Y") {
    return postRequestAndReturn("/api/subEvents/matching", subEvent);
  }
  return postRequestAndReturn("/api/subEvents/mismatch", subEvent);
};

export const createRegisterSubEvent = (subEvent: RegistSubEventReq) => {
  return postRequestAndReturn("/api/registerSubEvents/create",subEvent);
}

export const modifySubEvent = (subEvent: SubEventInfo) => {
  if (subEvent.matching === "Y") {
    return putRequest(subEvent, "/api/subEvents/matching");
  }
  return putRequest(subEvent, "/api/subEvents/mismatch");
};

export const modifyRegisterSubEvent = (subEvent: RegistSubEventReq) => {
  return postRequest(subEvent, "/api/registerSubEvents/update");
}

export const getSubEvents =
  <SubEvent>(eventId: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/subEvents?eventId=${eventId}`;
    try {
      let res: AxiosResponse = await axios.get(path, config);
      dispatch(getSubEventsAction(res.data));
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

// 이벤트 상태변경([승인요청, 승인, 거절, 삭제] -> 삭제)
export const modifySubEventApprovalDelete =
  (id: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      params: { id: id },
      data: {},
    };

    const path = "/api/subEvents/approvalDelete";
    try {
      var res: any = await axios.get(path, config);

      return "success";
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return "error";
    }
  };

export const modifyPayment = (data: { id: number; type: "W" | "N" }) => {
  return putRequest(data, "/api/subEvents/payment", modifyPaymentAction);
};

export const modifySubEventMeetingAvailable = (data: { id: string, meetingDirection:string }) => {
  return putRequest(
    data,
    "/api/subEvents/meetingAvailable",
    modifyMeetingAvailableAction
  );
};

export const modifyCounselFormOnlyEng = (data: { id: string }) => {
  return patchRequest(
    data,
    "/api/subEvents/changeCounselFormOnlyEng",
    modifyCounselFormOnlyEngAction
  );
};

export const getSubEventsByMainEventId = (mainEventId: number) => {
  return getRequest(null, "/api/subEvents/mainEvent", undefined, {
    mainEventId: mainEventId,
  });
};
