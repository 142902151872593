import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { AppActions, AppState } from "../../store";
import { ThunkDispatch } from "redux-thunk";

// actions
import { createUser, getAuthToken } from "../../actions/users";

// types
import User from "../../types/models/User";

// custom ui comp
import OutlinedTxtField from "../../components/Inputs/PxOutlinedTxtField";
import PxSelectBox from "../../components/SelectBox/PxSelectBox";
import PxOutlinedFormControl from "../../components/Forms/PxOutlinedFormControl";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typhography/ButtonTypo";

// mateiral ui comp
import MenuItem from "@material-ui/core/MenuItem";
import Container from "@material-ui/core/Container";
import { countryPhoneNumberCode } from "../../utils/constants";
import styled from "styled-components";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { fileStateToInitial, getSignedRequest } from "../../actions/files";
import {
  createTemporaryCode,
  createTemporaryCodeAgain,
  getTemporaryCode,
} from "../../actions/temporaryCode";
import { setDialog } from "../../actions/dialogs";
import { setAlertMessage } from "../../actions/alerts";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const Title = styled.div`
  height: 24px;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #5f4b8b;
`;
const InputForm = styled.div`
  width: 960px;
  height: 100%;
  min-height: 56px;
  box-shadow: ${(props: { top?: boolean }) =>
    props.top ? "inset 0 1px 0 0 #bdbdbd" : "inset 0 1px 0 0 #e0e0e0"};
  background-color: #fafafa;

  display: flex;
  flex-direction: row;
`;
const InputTitle = styled.div`
  width: 100%;
  max-width: 246px;
  align-self: center;
  padding-left: 16px;

  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.38);
`;
const InputContent = styled.div`
  width: 100%;
  align-self: center;
  padding-right: 10px;
`;

type Props = DispatchProps;

const Register: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [user, setUser] = useState<User>({
    email: "",
    name: "",
    password: "",
    role: "HOST",
    company: "",
    countryNumber: "82",
    phoneNumber: "",
    taxInvoiceMail: "",
    businessCards: [],
    businessImageId: "-1",
    businessImage: {},
  });
  const [taxInvoiceData, setTaxInvoiceData] = useState({
    stop: 0, // 0: 인증요청, 1: 인증확인, 2: 인증완료
    code: "",
    count: 0, // 요청횟수
  });
  const [businessCheck, setBusinessCheck] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const files = useSelector((state: AppState) => state.files);
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const [isCapsLock, setIsCapsLock] = useState(false);
  const handleOnChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>
  ) => {
    setUser({
      ...user,
      [event.target.name as string]: event.target.value as string,
    });
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const s = String.fromCharCode(event.keyCode);
    if (
      s.toUpperCase() === s &&
      s.toLowerCase() !== s &&
      !event.shiftKey &&
      event.key.toLowerCase() !== event.key
    ) {
      setIsCapsLock(true);
    } else {
      setIsCapsLock(false);
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (businessCheck) {
      if (taxInvoiceData.stop !== 2) {
        await dispatch(setAlertMessage("세금계산서 이메일이 인증되지않았습니다."));
        return false;
      } else if (user.businessImageId === "-1") {
        await dispatch(setAlertMessage("사업자등록증을 등록해주세요"));
        return false;
      }
    } else if (
      /^\w+([0-9a-zA-Z][_0-9a-zA-Z\.-]*@[_0-9a-zA-Z-]+(\.[_0-9a-zA-Z\--]+){1,2})+$/.test(
        user.email
      ) === false
    ) {
      await dispatch(setAlertMessage("regularExpression.emailValid"));
      return false;
    } else if (!user.password) {
      await dispatch(setAlertMessage("regularExpression.emptyPassword"));
      return false;
    } else if (
      !user.password!.match(/[a-z]/) ||
      !user.password!.match(/[0-9]/) ||
      user.password.length < 8
    ) {
      await dispatch(setAlertMessage(t("regularExpression.password")));
      return false;
    }

    const resData = await props.createUser(user);
    if (resData !== undefined) {
      await dispatch(
        getAuthToken({
          email: user.email,
          password: user.password!,
        })
      );
      history.push("/");
    }
  };

  // 파일업로드 결과
  useEffect(() => {
    if (files.id !== "") {
      setUser({ ...user, businessImage: files, businessImageId: files.id });
    }

    // 리덕스 초기화
    dispatch(fileStateToInitial());
  }, [files]);

  // 파일 등록
  const handleBusinessImageAdd = (
    e: React.ChangeEvent<{
      files: any;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();

    const file = e.target.files[0];
    const fileParts = file.name.split(".");
    const originalFileName = fileParts[0];
    const timestamp = new Date().getTime();
    const fileType = file.type.split("/")[1];
    const fileName = originalFileName + "&&" + timestamp + "." + fileType;
    const folderPath = "auth/";
    const fileSize = file.size;
    const gubun = "mainImage"; // 여러파일등록시 어떤 파일을 등록했는지 구분

    dispatch(getSignedRequest(file, fileName, fileType, folderPath, fileSize, gubun));
  };

  // 첨부파일 삭제
  const handleBusinessImageRemove = () => {
    setUser({ ...user, businessImage: {}, businessImageId: "-1" });
  };

  // 세금계산서 이메일 발송
  const handleTaxInvoiceSend = async () => {
    const sendCodeResult: any = await dispatch(
      createTemporaryCode(user.taxInvoiceMail!, user.name !== "" ? user.name : "고객")
    );
    if (sendCodeResult !== "error") {
      dispatch(setDialog(t("register.mailSendingNote"), [t("register.mailHasBeenSent")]));

      changeTaxInvoiceData(sendCodeResult, 1);
    } else {
      changeTaxInvoiceData(taxInvoiceData.code, taxInvoiceData.stop);
    }
  };

  // 세금계산서 이메일 재발송
  const handleTaxInvoiceReSend = async () => {
    const sendCodeResult: any = await dispatch(
      createTemporaryCodeAgain(user.taxInvoiceMail!, user.name !== "" ? user.name : "고객")
    );
    if (sendCodeResult !== "error") {
      dispatch(setDialog(t("register.mailSendingNote"), [t("register.mailHasBeenSent")]));
      changeTaxInvoiceData(sendCodeResult, 1);
    }
  };

  // 세금계산서 이메일 확인
  const handleTaxInvoiceCheck = async () => {
    const taxInvoiceResult: any = await dispatch(
      getTemporaryCode(user.taxInvoiceMail!, taxInvoiceData.code)
    );

    if (taxInvoiceResult === "success") {
      changeTaxInvoiceData("", 2);
      dispatch(
        setDialog(t("register.mailAuthenticationGuide"), [t("register.mailHasBeenAuthenticated")])
      );
    }
  };

  const changeTaxInvoiceData = (code: string, stop: number) => {
    setTaxInvoiceData((prevState) => {
      const prevTaxInvoiceData = prevState;
      return { code: code, stop: stop, count: prevTaxInvoiceData.count + 1 };
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Container style={{ width: "100%", maxWidth: "960px" }}>
        <form onSubmit={onSubmit}>
          <Title>{t("register.userInfo")}</Title>

          <InputForm top>
            <InputTitle>{t("register.email")}</InputTitle>
            <InputContent>
              <OutlinedTxtField
                name="email"
                fullWidth
                onChange={handleOnChange}
                border="1px solid #e0e0e0"
                style={{ backgroundColor: "white" }}
                InputProps={{
                  style: {
                    backgroundColor: "whtie",
                    fontSize: "14px",
                    fontWeight: "bold",
                    lineHeight: "1.5",
                    height: "36px",
                  },
                }}
              />
            </InputContent>
          </InputForm>

          <InputForm>
            <InputTitle>{t("register.corporate")}</InputTitle>
            <InputContent>
              <OutlinedTxtField
                name="company"
                fullWidth
                onChange={handleOnChange}
                border="1px solid #e0e0e0"
                style={{ backgroundColor: "white" }}
                InputProps={{
                  style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    lineHeight: "1.5",
                    height: "36px",
                  },
                }}
              />
            </InputContent>
          </InputForm>

          <InputForm>
            <InputTitle>{t("register.name")}</InputTitle>
            <InputContent>
              <OutlinedTxtField
                name="name"
                fullWidth
                onChange={handleOnChange}
                border="1px solid #e0e0e0"
                style={{ backgroundColor: "white" }}
                InputProps={{
                  style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    lineHeight: "1.5",
                    height: "36px",
                  },
                }}
              />
            </InputContent>
          </InputForm>

          <InputForm>
            <InputTitle>{t("register.password")}</InputTitle>
            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  id="outlined-adornment-password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={user.password}
                  onChange={handleOnChange}
                  onKeyDown={handleOnKeyDown}
                  inputProps={{
                    style: {
                      fontSize: "14px",
                      fontWeight: "bold",
                      height: "36px",
                      padding: "0 10px",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{ padding: 0, margin: 0 }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {isCapsLock && <div>{t("common.isCapsLock")}</div>}
              {!user.password!.match(/[a-z]/) && (
                <div style={{ color: "red" }}>{t("register.containLowercaseLetters")}</div>
              )}
              {!user.password!.match(/[0-9]/) && (
                <div style={{ color: "red" }}>{t("register.containNumber")}</div>
              )}
            </InputContent>
          </InputForm>

          <InputForm>
            <InputTitle>{t("register.phoneNumber")}</InputTitle>
            <InputContent style={{ display: "flex", justifyContent: "space-between" }}>
              <OutlinedTxtField
                style={{
                  justifyContent: "center",
                  width: "49%",
                  backgroundColor: "white",
                }}
                name="phoneNumber"
                fullWidth
                onChange={handleOnChange}
                border="1px solid #e0e0e0"
                InputProps={{
                  style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    lineHeight: "1.5",
                    height: "36px",
                  },
                }}
              />
              <InputContent style={{ width: "49%" }}>
                <PxOutlinedFormControl fullWidth>
                  <PxSelectBox
                    style={{
                      justifyContent: "center",
                      backgroundColor: "white",
                    }}
                    value={user.countryNumber}
                    onChange={handleOnChange}
                    name="countryNumber"
                    displayEmpty
                    input={<OutlinedInput margin="dense" />}
                  >
                    <MenuItem value="" disabled>
                      {t("common.countryCode")}
                    </MenuItem>
                    {countryPhoneNumberCode.map((countryCode) => (
                      <MenuItem value={countryCode.code}>
                        {" "}
                        {i18n.language === "ko" ? countryCode.country : countryCode.countryEn}
                      </MenuItem>
                    ))}
                  </PxSelectBox>
                </PxOutlinedFormControl>
              </InputContent>
            </InputContent>
          </InputForm>

          <Title style={{ marginTop: "32px" }}>
            {t("register.additionalInfo")}
            <Checkbox
              checked={businessCheck === true}
              onChange={() => setBusinessCheck(!businessCheck)}
              style={{ margin: 0, padding: 0 }}
            />
          </Title>
          {businessCheck && (
            <>
              <InputForm top>
                <InputTitle>{t("register.taxInvoiceMail")}</InputTitle>
                <InputContent style={{ display: "flex", justifyContent: "space-between" }}>
                  <OutlinedTxtField
                    name="taxInvoiceMail"
                    fullWidth
                    onChange={handleOnChange}
                    border="1px solid #e0e0e0"
                    disabled={taxInvoiceData.stop === 2}
                    style={{
                      justifyContent: "center",
                      width: "49%",
                      backgroundColor: "white",
                    }}
                    InputProps={{
                      style: {
                        fontSize: "14px",
                        fontWeight: "bold",
                        lineHeight: "1.5",
                        height: "36px",
                      },
                    }}
                  />
                  <InputContent style={{ width: "49%" }}>
                    <>
                      {taxInvoiceData.stop === 0 ? (
                        <PxButton
                          style={{ padding: "4px 8px" }}
                          backgroundcolor="purple"
                          type="button"
                          onClick={handleTaxInvoiceSend}
                        >
                          <ButtonTypo className="font-weight-bold">
                            {t("register.requestForAuthentication")}
                          </ButtonTypo>
                        </PxButton>
                      ) : taxInvoiceData.stop === 1 ? (
                        <PxButton
                          style={{ padding: "4px 8px" }}
                          backgroundcolor="purple"
                          type="button"
                          onClick={handleTaxInvoiceCheck}
                        >
                          <ButtonTypo className="font-weight-bold">
                            {t("register.authenticationVerification")}
                          </ButtonTypo>
                        </PxButton>
                      ) : null}{" "}
                      {taxInvoiceData.count > 0 && taxInvoiceData.stop < 2 && (
                        <PxButton
                          style={{ padding: "4px 8px" }}
                          backgroundcolor="purple"
                          type="button"
                          onClick={handleTaxInvoiceReSend}
                        >
                          <ButtonTypo className="font-weight-bold">
                            {t("register.reRequesForAuthentication")}
                          </ButtonTypo>
                        </PxButton>
                      )}
                    </>
                  </InputContent>
                </InputContent>
              </InputForm>

              <InputForm>
                <InputTitle>{t("register.businessRegistrationCertificate")}</InputTitle>
                <InputContent>
                  <InputContent style={{ width: "49%" }}>
                    {Object.keys(user.businessImage).length !== 0 &&
                    JSON.stringify(user.businessImage) !== JSON.stringify({}) ? (
                      <Grid sm={12} md={12} lg={12}>
                        {user.businessImage.fileType
                          .toLowerCase()
                          .match(/(jpg|jpeg|png|gif|bmp)$/) && (
                          <>
                            <img
                              width="300px"
                              src={user.businessImage.filePath}
                              alt={user.businessImage.fileName}
                            />
                            <br />
                          </>
                        )}
                        {user.businessImage.fileName}
                        <Button
                          variant="contained"
                          component="label"
                          style={{
                            boxShadow: "none",
                            backgroundColor: "#eee",
                            padding: "4.5px 8px",
                            margin: "5px 10px",
                          }}
                          onClick={handleBusinessImageRemove}
                        >
                          {t("common.remove")}
                        </Button>
                      </Grid>
                    ) : (
                      <Button
                        variant="contained"
                        component="label"
                        style={{
                          boxShadow: "none",
                          backgroundColor: "#eee",
                          padding: "9px 16px",
                        }}
                      >
                        {t("common.add")}
                        <input
                          type="file"
                          accept="image/gif, image/jpeg, image/png, image/bmp"
                          style={{ display: "none" }}
                          onChange={handleBusinessImageAdd}
                        />
                      </Button>
                    )}
                  </InputContent>
                </InputContent>
              </InputForm>
            </>
          )}
          <div style={{ textAlign: "center" }}>
            <PxButton
              className="my-4"
              style={{ padding: "12px 48px" }}
              backgroundcolor="default"
              onClick={() => history.goBack()}
              type="button"
            >
              <ButtonTypo className="font-weight-bold">{t("common.cancel")}</ButtonTypo>
            </PxButton>{" "}
            <PxButton
              className="my-4"
              style={{ padding: "12px 48px" }}
              backgroundcolor="purple"
              type="submit"
            >
              <ButtonTypo className="font-weight-bold">{t("register.register")}</ButtonTypo>
            </PxButton>
          </div>
        </form>
      </Container>
    </>
  );
};

interface DispatchProps {
  createUser: (user: User) => void;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>): DispatchProps => ({
  createUser: bindActionCreators(createUser, dispatch),
});

export default connect(null, mapDispatchToProps)(Register);
