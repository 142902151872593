import React from "react";
import BaseTemplate, {EventContent, LinkButton, MailContent, TargetBox} from "./BaseTemplate";
import TargetBoxContainer from "./TargetBoxContainer";

interface MeetingMailProp {
  language: "kr" | "en";
  userName: string;
  eventName: string;
  eventStart: string;
  eventEnd: string;
  subEventName: string;
  subEventStart: string;
  subEventEnd: string;
  date: string;
  link: string;
  type?: "default" | "custom";
}

export default ({language, userName, eventName, eventStart, eventEnd, subEventName, subEventStart, subEventEnd, date, link, type}: MeetingMailProp) => {
  return (
    <BaseTemplate title={language === "en" ? "Participating application notice" : "참가신청 알림"} language={language} type={type}>
      {language === "en" ? (
        <>
          <MailContent>
            Hello, {userName}
            <br />
            Thank you for participating in the event.
          </MailContent>
          <EventContent>
            Event Name:&nbsp; {eventName}&nbsp;({subEventName})<br />
            Period:&nbsp;{eventStart}(GMT+9) ~&nbsp;{eventEnd}(GMT+9)
            <br />
            Sub-event name:&nbsp;{subEventName}
            <br />
            Sub-event period:&nbsp;{subEventStart}(GMT+9) ~&nbsp;{subEventEnd}(GMT+9)
            <br />
            Application time:&nbsp;{date}
            <br />
          </EventContent>
          <LinkButton target="_blank" href={link}>
            Go to event{" "}
          </LinkButton>
        </>
      ) : (
        <>
          <MailContent>
            {userName}님 안녕하세요
            <br />
            <TargetBoxContainer type={type}>이벤트</TargetBoxContainer>에 참가해주셔서 감사합니다.
          </MailContent>
          <EventContent>
            <TargetBoxContainer type={type}>이벤트</TargetBoxContainer> 명:&nbsp; {eventName}&nbsp;({subEventName})<br />
            <TargetBoxContainer type={type}>이벤트</TargetBoxContainer> 기간:
            <br />
            &nbsp;{eventStart}(GMT+9) ~&nbsp;{eventEnd}(GMT+9)
            <br />
            서브<TargetBoxContainer type={type}>이벤트</TargetBoxContainer>명:&nbsp;{subEventName}
            <br />
            서브<TargetBoxContainer type={type}>이벤트</TargetBoxContainer> 기간:&nbsp;{subEventStart}(GMT+9) ~&nbsp;{subEventEnd}(GMT+9)
            <br />
            신청일시:&nbsp;{date}
            <br />
          </EventContent>
          <LinkButton target="_blank" href={link}>
            <TargetBoxContainer type={type}>이벤트</TargetBoxContainer> 바로가기
          </LinkButton>
        </>
      )}
    </BaseTemplate>
  );
};
