import { AppActions, AppState } from "../store";
import Alert from "../types/models/Alert";
import { Dispatch } from "react";
import uuid from "uuid";

export const setAlertAction = (alert: Alert): AppActions => ({
  type: "SET_ALERT",
  alert: [alert],
});

export const removeAlertAction = (id: string): AppActions => ({
  type: "REMOVE_ALERT",
  id: id,
});

export const setAlert = (alert: Alert) => (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  dispatch(setAlertAction(alert));

  setTimeout(() => {
    dispatch(removeAlertAction(alert.id));
  });
};

export const setAlertMessage = (message: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const alert: Alert = {
    alertType: "warning",
    id: uuid.v4(),
    msg: message,
  };

  dispatch(setAlertAction(alert));
};


export const newAlert = (msg: string,id:string,alertType: "warning"|"info" |"error" | "default" | "success" = "warning") => 
(dispatch: Dispatch<AppActions>,
  getState: () => AppState) => {
  let alert: Alert = {
    alertType,
    id: id,
    msg,
  };
  dispatch(setAlertAction(alert));
  setTimeout(() => {
    dispatch(removeAlertAction(alert.id));
  });
};
