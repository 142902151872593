import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { findEmailByCode, changePwd } from "../../actions/users";
import { setAlertMessage } from "../../actions/alerts";
import { useTranslation } from "react-i18next";

const ChangeUserPwd = styled.div`
  width: 100%;
  min-width: 280px;
  max-width: 640px;
  padding: 24px;
`;

const ButtonBase = styled.button`
  font-size: 18px;
  padding: 0.7em 2em;
  border-radius: 3px;
  border: none;
  // margin: 0 0.32em;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const SendMailButton = styled(ButtonBase)`
  height: 56px;
  width: 100%;
  background: black;
  color: white;
  margin-top: 58px;
`;

const Title = styled.div`
  height: 36px;
  font-size: 24px;
  font-weight: bold;
  color: #282828;

  cursor: pointer;
`;

const SubTitle = styled.div`
  min-height: 48px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Content = styled.div`
  height: 0;
  transition: height 0.3s;
  transition-timing-function: linear;
  overflow: hidden;
  ${(props: { on: boolean }) => {
    if (props.on) {
      return `height:400px`;
    }
  }}
`;

const ChangePwd = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation("lang", { useSuspense: false });
  const param: { id: string } = useParams();
  const [isCapsLock, setIsCapsLock] = useState(false);
  const [changeUserPwd, setChangeUserPwd] = useState({
    temporaryCode: param.id,
    email: "",
    password: "",
    role: "host",
  });
  const [hidden, setHidden] = useState(true);

  const { email, password } = changeUserPwd;

  useEffect(() => {
    findUser();
  }, []);

  const findUser = async () => {
    const res = await dispatch(findEmailByCode(param.id));
    if (res !== undefined) {
      setChangeUserPwd({ ...changeUserPwd, email: res.toString() });
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const s = String.fromCharCode(event.keyCode);
    if (
      s.toUpperCase() === s &&
      s.toLowerCase() !== s &&
      !event.shiftKey &&
      event.key.toLowerCase() !== event.key
    ) {
      setIsCapsLock(true);
    } else {
      setIsCapsLock(false);
    }
  };

  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    const name = e.target.name as string;
    let value = e.target.value as string;
    setChangeUserPwd({ ...changeUserPwd, [name]: value });
  };

  const submitChangePwd = async () => {
    if (!password.match(/[a-z]/) || !password.match(/[0-9]/) || password.length < 8) {
      await dispatch(setAlertMessage(t("regularExpression.password")));
      return false;
    }
    const mailRes: any = await dispatch(changePwd(changeUserPwd));
    if (mailRes) history.push("/auth/login");
  };

  return (
    <>
      <ChangeUserPwd>
        <Title onClick={() => setHidden(!hidden)}>{t("changePwd.title")}</Title>
        <Content on={hidden}>
          <SubTitle>
            <span>
              {email === ""
                ? t("changePwd.targetNotFound")
                : `${t("changePwd.signedUpEmail")} ${email}`}
            </span>
          </SubTitle>
          <TextField
            margin="normal"
            fullWidth
            type="password"
            id="password"
            label={t("register.password")}
            name="password"
            value={password}
            helperText={isCapsLock ? "CapsLock 키가 눌려있습니다." : ""}
            onKeyDown={handleOnKeyDown}
            onChange={(e) => handleOnChange(e)}
          />
          {!password!.match(/[a-z]/) && (
            <div style={{ color: "red" }}>{t("register.containLowercaseLetters")}</div>
          )}
          {!password!.match(/[0-9]/) && (
            <div style={{ color: "red" }}>{t("register.containNumber")}</div>
          )}
          <SendMailButton type="button" onClick={submitChangePwd}>
            {t("changePwd.submit")}
          </SendMailButton>
        </Content>
      </ChangeUserPwd>
    </>
  );
};

export default ChangePwd;
