import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getCounselsAnswers} from "../../../../actions/counsels";
import {ExportCSV} from "../../../ExportCSV/ExportCSV";
import HeadingTypo from "../../../Typhography/HeadingTypo";
import {useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {categoryAndCheckboxSelectTextToLinkAndText} from "../../../../utils/utils";
import {CircularProgress} from "@material-ui/core";
import {CounselInfo, MeetingSurvey} from "../../../../types/models/Counsel";
import {useTranslation} from "react-i18next";
import moment from "moment";
import axios from "axios";
import ExportSurveyExcel from "../../../ExportCSV/ExportSurveyExcel";

interface MeetingSurveyExcelRefinedData {
  name?: string;
  managerName?: string;
  modifiedDate?: string;
  otherCompanyName?: string;
  otherCompanyManagerName?: string;
  survey?: MeetingSurvey[];
  type?: string;
  date?: string;
  startTime?: string;
  endTime?: string;
  status?: string;
}

const MeetingSurveyExcel = ({classes}: {classes: Record<"root" | "companyDiv" | "radioForm" | "meetingDiv", string>}) => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const params: {id: string} = useParams(); // subEventId
  const [isSurveyContent, setIsSurveyContent] = useState<boolean>(false);
  const [data, setData] = useState<{
    // 엑셀 export data
    data: any[];
    sheetNames: string[];
    fileName: string;
  }>(null!);
  const [filteredData, setFilteredData] = useState<{
    // 라디오 or 체크박스 값에 따라 필터된 excel로 export할 데이터
    data: any[];
    fileName: string;
    sheetNames: string[];
  }>(null!);
  const [groupNames, setGroupNames] = useState<{
    // 전체 데이터
    buyer: string;
    seller: string;
  }>();
  // const [sheetNames, setSheetNames] = useState<string[]>([]);
  // const [radioValue, setRadioValue] = useState<string>("seller"); // 구분 radio value

  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  const meetingSurveyProcess = useCallback(async () => {
    const sheetNameArr: string[] = [];

    const result: CounselInfo[] = await getCounselsAnswers(params.id);

    const meetingData = result.map(counsel => {
      const meeting: MeetingSurveyExcelRefinedData = {};
      meeting["survey"] = counsel.meetingSurveys;
      meeting["status"] = counsel.meetingSurveys.length === 0 ? "미작성" : "작성완료";
      meeting["name"] = counsel.acceptorName;
      meeting["managerName"] = counsel.meetingAcceptor?.applicants ? counsel.meetingAcceptor?.applicants[0].businessCardId!.name : "";
      meeting["type"] = counsel.meetingAcceptor?.type;
      meeting["otherCompanyName"] = counsel.applicantName;
      meeting["otherCompanyManagerName"] = counsel.meetingApplicant?.applicants ? counsel.meetingApplicant?.applicants[0].businessCardId!.name : "";
      meeting["date"] = counsel.date;
      meeting["startTime"] = counsel.startTime;
      meeting["endTime"] = counsel.endTime;

      sheetNameArr.push(createSheetName(sheetNameArr, meeting));

      return meeting;
    });

    const fileName = `${subEvent!.mainEvent?.name}_상담일지(${moment().format("MMDDHHmm")})`;

    setData({data: meetingData, sheetNames: sheetNameArr, fileName: fileName});
  }, [params.id, subEvent]);

  const createSheetName = (sheetNameArray: string[], meeting: MeetingSurveyExcelRefinedData) => {
    const baseSheetName = cleanSheetName(`${meeting.type === "seller" ? meeting.name : meeting.otherCompanyName}_${meeting.date?.substring(5, 10)}_${meeting.startTime?.replace(":", "-")}`);

    return createUniqueSheetName(sheetNameArray, baseSheetName);
  };

  useEffect(() => {
    if (subEvent !== undefined) {
      // subEvent에서 설정한 seller 및 buyer naming 변경
      setGroupNames({
        seller: subEvent.sgroupName,
        buyer: subEvent.bgroupName,
      });
      meetingSurveyProcess();
    }
  }, [subEvent, meetingSurveyProcess]);

  const getFile = async (id: number) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    try {
      const res = await axios.get(`/api/fileS3?id=${id}`, config);
      return res.data.filePath;
    } catch (err) {
      return "";
    }
  };

  const cleanSheetName = (sheetName: string): string => {
    const maxLength = 26;
    const invalidChars = /[:\/\?\*\[\]\\\"]/g;

    return sheetName.replace(invalidChars, "_").substring(0, maxLength);
  };

  const createUniqueSheetName = (sheetNameArray: string[], baseSheetName: string): string => {
    let uniqueSheetName = baseSheetName;
    let counter = 1;

    while (sheetNameArray.includes(uniqueSheetName)) {
      uniqueSheetName = `${baseSheetName}_${counter}`;
      counter++;
    }

    return uniqueSheetName;
  };

  return (
    <div className={classes.meetingDiv}>
      <HeadingTypo fontweight="bold">{t("excel.meetingNote")}</HeadingTypo>
      {/* 셀러, 바이어 구분 해서 엑셀 출력 (subEvent에 따라 seller와 buyer의 문구가 다름) */}
      {/* { groupNames !== undefined && (
        <div className={classes.radioForm}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gubun"
              name="gubunRadio"
              row
              value={radioValue}
              onChange={handleChange}
            >
              {Object.entries(groupNames).map(([key, value]) => {
                return <FormControlLabel key={key} value={key} control={<Radio />} label={value} />;
              })}
            </RadioGroup>
          </FormControl>
        </div>
      )} */}

      {data !== null ? (
        // <ExportCSV sellerData={filteredData.seller} buyerData={filteredData.buyer} sellerName={groupNames!.seller} buyerName={groupNames!.buyer} fileName={filteredData.fileName} />
        <ExportSurveyExcel sheetData={data.data} fileName={data.fileName} sheetNames={data.sheetNames} isSurveyContent={isSurveyContent} />
      ) : (
        <CircularProgress size={30} />
      )}
    </div>
  );
};

export default MeetingSurveyExcel;
