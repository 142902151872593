import Country, { CountryApiInfo, CountryInfo } from "../types/models/Country";
import { SubEventInfo } from "../types/models/SubEvent";
import { parallaxCalculation } from "./momentTz";

export const currentCountryTime = (
  subEvent: SubEventInfo,
  date: "subEventStartDate" | "subEventEndDate" | "mainStartDate" | "mainEndDate",
  countries: CountryInfo,
  country: CountryApiInfo
) => {
  const countryCode = subEvent?.mainEvent?.countryCode ?? "KR";
  // const { country, countries } = selector((state: AppState) => state.countries);
  let time;
  if (date === "mainStartDate") {
    time = subEvent!.mainEvent!.startDate;
  } else if (date === "mainEndDate") {
    time = subEvent!.mainEvent!.endDate;
  } else {
    time = subEvent[date];
  }
  const subEventStartTime = parallaxCalculation(
    time,
    countries![countryCode! as string] as any,
    countries![country!.countryCode!] as any,
    "YYYY-MM-DD"
  );
  return subEventStartTime as string;
};
