import Meeting from "../types/models/Meeting";
import MeetingActionTypes from "../types/actions/MeetingAction";

const meetingDefaultState: Meeting = {
  meeting: {
    id: "",
    subEventId: "", // 서브이벤트
    applicant: "", // 신청자(application id)
    acceptor: "", // 수락자(application id)
    applicantName: "", // 신청기업
    acceptorName: "", // 수락기업
    meetingId: "", // 미팅시간 key
    status: "waiting", // 상태
    createdDate: "",
    modifiedDate: "",
    applicationFormAnswers: [], // 자유양식 답변(기업 기본정보)
  },
  meetings: [],
  meetingTargets: [],
  matchingList:{
    content:[],
    pageable: {},
    last: false,
    totalElements: 0,
    totalPages: 0,
    size: 1,
    number: 0,
    sort: {
        sorted: false,
        unsorted: true,
        empty: true
    },
    numberOfElements: 1,
    first: true,
    empty: false
    },
  waitingAcceptMeetingTargets: [],
  meetingVideo: undefined,
  meetingTotalCount: {
    bgroupApplicantCount :0,
    bgroupWaitingAcceptorCount:0,
    bgroupImpossibleCount:0,
    sgroupApplicantCount:0,
    sgroupWaitingAcceptorCount:0,
    sgroupImpossibleCount:0,
    totalAgreeCount: 0,
    sgroupAgreeCount: 0,
    sgroupDisagreeCount: 0,
    bgroupAgreeCount: 0,
    bgroupDisagreeCount: 0
  }
};

const meetingReducer = (
  state = meetingDefaultState,
  action: MeetingActionTypes
): Meeting => {
  switch (action.type) {
    case "CREATE_MEETING":
    case "GET_MEETING":
      return { ...state, meeting: action.meeting };
    case "GET_WAITING_ACCEPT_MEETING_TARGETS":
      return {
        ...state,
        waitingAcceptMeetingTargets: action.waitingAcceptMeetingTargets,
      };
    case "GET_MATCHINGLIST":
      return {
        ...state,
        matchingList:action.matchingList,
      }
    case "WAITING_ACCEPT_MEETING_TARGETS_RESET":
      return { ...state, waitingAcceptMeetingTargets: [] };
    case "GET_MEETING_TARGETS":
      return { ...state, meetingTargets: action.meetingTargets };
    case "MEETING_TARGETS_RESET":
      return { ...state, meetingTargets: [] };
    case "MEETINGS_RESET":
      return { ...state, meetings: [] };
    case "GET_MEETINGS":
      return { ...state, meetings: action.meetings };
    case "SET_MEETING_VIDEO":
      return { ...state, meetingVideo: action.meetingVideo };
    case "GET_MEETING_TOTAL_COUNT":
      return {...state, meetingTotalCount: action.meetingTotalCount};
    default:
      return state;
  }
};

export default meetingReducer;
