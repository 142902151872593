import Box from "@material-ui/core/Box";
import React, { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../actions/dialogs";
import PxButton from "../Buttons/PxButton";
import Body1Typo from "../Typhography/Body1Typo";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import { Trans, useTranslation } from "react-i18next";
interface WarningRemoveProps {
  title: string;
  warningMsg: string | ReactNode;
  text: string;
  onClick: () => void;
  submitText?: string;
}

const WarningRemove = ({ title, warningMsg, text, onClick, submitText }: WarningRemoveProps) => {
  const dispacth = useDispatch();
  const [t] = useTranslation("lang", { useSuspense: false });
  const [checkText, setCheckText] = useState<string>();
  const close = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    dispacth(closeDialog(e));
  };
  return (
    <>
      <Box position="absolute" top="0px" right="0px" onClick={close} padding="10px">
        <CloseIcon />
      </Box>
      <Body1Typo fontSize="17px">{title}</Body1Typo>
      <Body1Typo style={{ color: "#f9a825" }}>
        <WarningIcon style={{ height: "18px" }} />
        {warningMsg}
      </Body1Typo>
      <Box height={1} borderTop="solid 0.5px rgba(0, 0, 0, 0.23)" marginY="15px" marginX="-20px" />
      <Body1Typo>
        <Trans t={t} i18nKey={"common.warningAgreeText"}>
          '<strong>{{ text }}</strong>'을(를) 입력해주세요
        </Trans>
      </Body1Typo>
      <input
        style={{ width: "100%", padding: "6px 10px", marginBottom: "15px" }}
        onChange={(e) => setCheckText(e.currentTarget.value)}
      />
      <Box display="flex" justifyContent="center">
        <PxButton
          backgroundcolor="transparent"
          boxshadow={false}
          fullWidth
          txtColor="warning"
          padding="6px"
          border={true}
          onClick={(e) => {
            onClick();
            close(e);
          }}
          disabled={checkText !== text}
        >
          {submitText ? submitText : "확인"}
        </PxButton>
      </Box>
    </>
  );
};

export default WarningRemove;
