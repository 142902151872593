import React, {useEffect} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {useHistory, useParams, useLocation} from "react-router-dom";
import {getSubEvents, modifySubEventApprovalDelete} from "../../actions/subEvents";
import {AppState, AppActions} from "../../store";

import {Column} from "material-table";

// types
import SubEvent from "../../types/models/SubEvent";

// custom ui comp
import PxTable from "../../components/Table/PxTable";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typhography/ButtonTypo";
import {ThunkDispatch} from "redux-thunk";
import {bindActionCreators} from "redux";
import moment from "moment";
import {setDialog} from "../../actions/dialogs";
import {parallaxCalculation} from "../../utils/momentTz";
import {confirmAlert} from "react-confirm-alert";
import {confirmModalOptions} from "../../utils/confirmModalOptions";
import {useTranslation} from "react-i18next";

interface RowType {
  subEvCategory: string;
  subEvCreatedDate: string;
  subEvDate: string;
  subEvUpdatedDate: string;
  subEvId: string;
  subEvMatching: string; // suvEvent matching Y or N 확인, 어떤 양식을 보여줄지 확인하기 위함
  approval: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

type Props = DispatchProps & reduxStateProps;

const SubEventList: React.FC<Props> = props => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const params: {id: string} = useParams();
  const {country, countries} = useSelector((state: AppState) => state.countries);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  const {subEvents, getSubEvents} = props;
  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("eventDetail.subEventType"),
        field: "subEvCategory",
        cellStyle: {
          width: "calc(100%-600px)",
          paddingRight: 0,
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "",
        field: "subEvSetting",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
        render: rowData =>
          rowData?.approval !== "D" && (
            <PxButton
              backgroundcolor="grey"
              style={{borderRadius: 20, padding: 5}}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation();
                history.push(`${location.pathname}/SubEventModify/${rowData.subEvId}`);
              }}
            >
              <ButtonTypo>{t("common.setting")}</ButtonTypo>
            </PxButton>
          ),
      },
      {
        title: "",
        field: "subEvFormSetting",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
        render: rowData => {
          return rowData.subEvCategory !== "registration" ? (
            // rowData?.approval !== "D" && rowData?.approval !== "Y" && (
            rowData?.approval !== "D" && (
              <PxButton
                backgroundcolor="purple"
                style={{padding: 5}}
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.stopPropagation();
                  rowData.subEvMatching === "Y" ? history.push(`/form/subEvent/${rowData.subEvId}/match`) : history.push(`/form/subEvent/${rowData.subEvId}/noMatch`);
                }}
              >
                <ButtonTypo>{t("eventDetail.form")}</ButtonTypo>
              </PxButton>
            )
          ) : (
            <></>
          );
        },
      },
      // {
      //   title: t("eventDetail.form"),
      //   field: "subEvCreatedDate",
      //   cellStyle: {
      //     width: "100px",
      //     paddingRight: 0,
      //   },
      // },
      {
        title: t("common.registerDate"),
        field: "subEvDate",
        width: "200px",
        cellStyle: {
          paddingRight: 0,
        },
      },
      {
        title: t("common.reviseDate"),
        field: "subEvUpdatedDate",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
      },
      {
        title: "",
        field: "subEvFormSetting",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
        render: rowData =>
          rowData.approval !== "Y" && (
            <>
              <PxButton
                backgroundcolor="grey"
                style={{borderRadius: 5, padding: 7}}
                onClick={async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.stopPropagation();
                  confirmAlert(
                    confirmModalOptions({
                      title: t("noticeDetail.deleteAlert"),
                      click: async () => {
                        const res: any = await dispatch(modifySubEventApprovalDelete(rowData.subEvId));
                        if (res === "success") {
                          dispatch(setDialog(`하위이벤트 승인상태 변경`, [t("subEventList.requestEventDeleted")]));
                          getSubEvents(params.id);
                        }
                      },
                    })
                  );
                }}
              >
                <ButtonTypo>{t("common.delete")}</ButtonTypo>
              </PxButton>
            </>
          ),
      },
    ],
    data: [],
  });

  useEffect(() => {
    let tableData: any = [];
    if (Array.isArray(subEvents) && subEvents.length > 0) {
      const countryCode = subEvent?.mainEvent?.countryCode ?? "KR";

      subEvents.map(subEv => {
        // 국가에 맞는 시작시간 조회
        const subEventStartTime = parallaxCalculation(subEv.subEventStartDate, countries![countryCode! as string] as any, countries![country!.countryCode!] as any, "YYYY-MM-DD");

        // 국가에 맞는 끝시간 조회
        const subEventEndTime = parallaxCalculation(subEv.subEventEndDate, countries![countryCode! as string] as any, countries![country!.countryCode!] as any, "YYYY-MM-DD");
        const rowData = {
          subEvCategory: subEv.category === "presentation" ? "Conference" : subEv.category, //presentation : Conference 모두 변경 / 21.04.19
          subEvDate: `${subEventStartTime} - ${subEventEndTime}`,
          subEvCreatedDate: moment(subEv.createdDate).format("YYYY-MM-DD"),
          subEvUpdatedDate: moment(subEv.modifiedDate).format("YYYY-MM-DD"),
          subEvId: subEv.id,
          subEvMatching: subEv.matching,
          approval: subEv.mainEvent?.approval,
        };
        tableData.push(rowData);
      });
    }
    setState(prevCreateDate => {
      return {...prevCreateDate, data: tableData};
    });
  }, [subEvents]);

  return (
    <PxTable<RowType>
      columns={state.columns}
      data={state.data}
      onRowClick={(evt, selectedRow) => {
        if (selectedRow?.subEvCategory === "registration") {
          history.push(`/subEvent/${selectedRow?.subEvId}/regitApplicationList`);
        } else {
          history.push(`/subEvent/${selectedRow?.subEvId}/application`);
        }
      }}
      initPageSize={5}
      options={{
        actionsColumnIndex: -1,
        showTitle: false,
        defaultExpanded: false,
        search: false,
        toolbar: false,
      }}
    />
  );
};

interface DispatchProps {
  getSubEvents: (id: string) => void;
}

interface reduxStateProps {
  subEvents: SubEvent["subEvents"];
}

const mapStateToProps = (state: AppState): reduxStateProps => ({
  subEvents: state.subEvents.subEvents,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>): DispatchProps => ({
  getSubEvents: bindActionCreators(getSubEvents, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubEventList);
