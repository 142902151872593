// import grapesjs from "grapesjs";
import grapesjs from "grapesjs";
import 'grapesjs/dist/css/grapes.min.css'
import gjsPresetWebpage from "grapesjs-preset-webpage"
import gjsBlockBasic from "grapesjs-blocks-basic"
import gjsTabs from "grapesjs-tabs"
// import gjsSlider from "grapesjs-lory-slider"
import gjsNavBar from "grapesjs-navbar"
import gjsStyleBg from "grapesjs-style-bg"
import gjsTailwind from "grapesjs-tailwind"
import gjsNewsLetter from "grapesjs-preset-newsletter"


const escapeName = (name) => `${name}`.trim().replace(/([^a-z0-9\w-:/]+)/gi, '-');


const webPlugin = [
    gjsPresetWebpage,
    gjsBlockBasic,
    gjsTabs,
    gjsNavBar,
    gjsStyleBg,
    gjsTailwind,
];

const newsLetterPlugin = [gjsNewsLetter];
export const geditorConfig = (eventId, type) => {

    const editor = grapesjs.init({
        container: "#editor",
        fromElement: true,
        showOffsets: true,
        noticeOnUnload: false,
        storageManager: false,
        selectorManager: { escapeName },
        plugins: type == "mail" ? newsLetterPlugin : webPlugin,
        assetManager: {
            upload: "/api/fileS3/editor-file",
            multiUpload:false,
            headers: {
                "Authorization" : `${localStorage.token}`
            },
            params: {
                folder: `template/${eventId}`,  // 예: 동적으로 폴더 지정
                eventId: eventId,                // 예: 추가 동적 파라미터
            },
            autoAdd:true
        },
        canvas:{
            styles: [`https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap`]
        },
        styleManager: {
            sectors: [{
                name: 'typography',
                open: true,
                buildProps: ['font-family'],
                properties: [{
                    id: 'font-family',
                    name: 'Font',
                    property: 'font-family',
                    default: "Nanum Gothic",
                    options: [
                        {
                            "value": "Nanum Gothic",
                            "name": "맑은 고딕"
                        },
                        {
                            "id": "Arial, Helvetica, sans-serif",
                            "label": "Arial"
                        },
                        {
                            "id": "Arial Black, Gadget, sans-serif",
                            "label": "Arial Black"
                        },
                        {
                            "id": "Brush Script MT, sans-serif",
                            "label": "Brush Script MT"
                        },
                        {
                            "id": "Comic Sans MS, cursive, sans-serif",
                            "label": "Comic Sans MS"
                        },
                        {
                            "id": "Courier New, Courier, monospace",
                            "label": "Courier New"
                        },
                        {
                            "id": "Georgia, serif",
                            "label": "Georgia"
                        },
                        {
                            "id": "Helvetica, sans-serif",
                            "label": "Helvetica"
                        },
                        {
                            "id": "Impact, Charcoal, sans-serif",
                            "label": "Impact"
                        },
                        {
                            "id": "Lucida Sans Unicode, Lucida Grande, sans-serif",
                            "label": "Lucida Sans Unicode"
                        },
                        {
                            "id": "Tahoma, Geneva, sans-serif",
                            "label": "Tahoma"
                        },
                        {
                            "id": "Times New Roman, Times, serif",
                            "label": "Times New Roman"
                        },
                        {
                            "id": "Trebuchet MS, Helvetica, sans-serif",
                            "label": "Trebuchet MS"
                        },
                        {
                            "id": "Verdana, Geneva, sans-serif",
                            "label": "Verdana"
                        },
                    ]
                }]
            }]
        },
    })

    return editor;
}
