import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../../../../store";

// types
import SubHeadingTypo from "../../../Typhography/SubHeadingTypo";
import { SpeakerInfo } from "../../../../types/models/Speaker";
import { SubEvenPathContext } from "../../../../layouts/SubEvent";

// actions
import { getSignedRequest, fileStateToInitial } from "../../../../actions/files";
import { createSpeaker } from "../../../../actions/speaker";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import { TypographyProps } from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// custom ui comp
import OutlinedTxtField from "../../../Inputs/OutlinedTxtField";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PxEditor from "../../../Editor/PxEditor";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const SpeakerAdd = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [t] = useTranslation("lang", { useSuspense: false });
  const { subEventId } = useContext(SubEvenPathContext);
  const [speaker, setSpeaker] = useState<SpeakerInfo>({
    subEventId: subEventId, // 서브이벤트 아이디
    name: "", // 이름
    belongs: "", // 소속
    title: "", // 직함
    mobileNumber: "", // 휴대번호
    email: "", // 이메일
    introduce: "", // 소개
    photo: "", // 사진
    photoFile: {},
    approval: "", // 승인여부 (Y/N)
    createdDate: "",
    modifiedDate: "",
  });

  const files = useSelector((state: AppState) => state.files);

  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    let name: string = e.target.name as string;
    let value: string = e.target.value as string;

    setSpeaker({ ...speaker, [name]: value });
  };

  // 이벤트 컨텐츠 내용(에디터) 변경
  const handleContentChange = (value: string) => {
    setSpeaker({ ...speaker, introduce: value });
  };

  // 파일업로드 결과
  useEffect(() => {
    if (files.id !== "") {
      setSpeaker({ ...speaker, photoFile: files, photo: files.id });
    }

    // 리덕스 초기화
    dispatch(fileStateToInitial());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  // 파일 등록
  const handlePhotoAdd = (
    e: React.ChangeEvent<{
      files: any;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();

    const file = e.target.files[0];
    const fileParts = file.name.split(".");
    const originalFileName = fileParts[0];
    const timestamp = new Date().getTime();
    const fileType = fileParts[1];
    const fileName = originalFileName + "&&" + timestamp + "." + fileType;
    const folderPath = "subEvent/speaker/";
    const fileSize = file.size;
    const gubun = ""; // 여러파일등록시 어떤 파일을 등록했는지 구분

    dispatch(getSignedRequest(file, fileName, fileType, folderPath, fileSize, gubun));
  };

  // 사진 삭제
  const handlePhotoRemove = () => {
    setSpeaker({ ...speaker, photoFile: {}, photo: "-1" });
  };

  // 이벤트 등록
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const speakerRes: any = await dispatch(createSpeaker(speaker));
    if (speakerRes === "success") history.goBack();
  };

  return (
    <Grid container>
      <Grid item>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
      </Grid>
      <Grid item style={{ padding: "0 0 0 64px" }}>
        <form onSubmit={onSubmit}>
          <SubTitle>이름</SubTitle>
          <OutlinedTxtField
            name="name"
            value={speaker.name}
            fullWidth
            onChange={handleOnChange}
            placeholder="이름를 입력해주세요"
          />

          <SubTitle>소속</SubTitle>
          <OutlinedTxtField
            name="belongs"
            value={speaker.belongs}
            fullWidth
            onChange={handleOnChange}
            placeholder="소속을 입력해주세요"
          />

          <SubTitle>직함</SubTitle>
          <OutlinedTxtField
            name="title"
            value={speaker.title}
            fullWidth
            onChange={handleOnChange}
            placeholder="직함을 입력해주세요"
          />

          <SubTitle>휴대번호</SubTitle>
          <OutlinedTxtField
            name="mobileNumber"
            value={speaker.mobileNumber}
            fullWidth
            onChange={handleOnChange}
            placeholder="휴대번호을 입력해주세요"
          />

          <SubTitle>이메일</SubTitle>
          <OutlinedTxtField
            name="email"
            value={speaker.email}
            fullWidth
            onChange={handleOnChange}
            placeholder="이메일을 입력해주세요"
          />

          <SubTitle>소개</SubTitle>
          <PxEditor
            value={speaker.introduce}
            onChange={handleContentChange}
            folderPath="event/"
            placeholder={t("speakerAdd.typeTheBiography")}
          />
          <SubTitle>사진</SubTitle>
          {Object.keys(speaker.photoFile).length === 0 &&
            JSON.stringify(speaker.photoFile) === JSON.stringify({}) && (
              <Grid>
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#eee",
                    padding: "9px 16px",
                  }}
                >
                  추가하기
                  <input
                    type="file"
                    style={{ display: "none" }}
                    name="file1"
                    onChange={handlePhotoAdd}
                  />
                </Button>
              </Grid>
            )}
          {Object.keys(speaker.photoFile).length !== 0 &&
            JSON.stringify(speaker.photoFile) !== JSON.stringify({}) && (
              <Grid sm={12} md={12} lg={12}>
                <img src={speaker.photoFile.filePath} alt={speaker.photoFile.fileName} />
                <br />
                {speaker.photoFile.fileName}
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#eee",
                    padding: "4.5px 8px",
                    margin: "5px 10px",
                  }}
                  onClick={handlePhotoRemove}
                >
                  삭제하기
                </Button>
              </Grid>
            )}

          <Grid
            container
            alignContent="space-between"
            spacing={1}
            style={{ justifyContent: "flex-end", marginTop: "32px" }}
          >
            <Grid item>
              <PxButton backgroundcolor="purple" type="submit">
                <ButtonTypo>스피커 등록</ButtonTypo>
              </PxButton>
            </Grid>
            <Grid item>
              <PxButton backgroundcolor="grey">
                <ButtonTypo>취소</ButtonTypo>
              </PxButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default SpeakerAdd;
