import React from "react";
import styled from "styled-components";
import {useLocation, useHistory} from "react-router-dom";
import {AppState} from "../../store";
import {useTranslation} from "react-i18next";

// bootstrap ui component
import Navbar from "react-bootstrap/Navbar";

// material icon
import HomeIcon from "@material-ui/icons/Home";

// material ui custom comp
import GridContainer from "../Grid/PxGridContainer";
import TitleTypo from "../Typhography/TitleTypo";
import PxGridItem from "../Grid/PxGridItem";
import PxButton from "../Buttons/PxButton";
import ButtonTypo from "../Typhography/ButtonTypo";
import PxIconButton from "../Buttons/PxIconButton";
import {useSelector} from "react-redux";
import PxGridContainer from "../Grid/PxGridContainer";

const StyledNavbar = styled(Navbar)`
  padding: 15px;
  background-color: white;
  & .nav-item {
    padding-right: 2em;
  }
`;

interface FormNavbarProps {}

const FormNavbar: React.FC<FormNavbarProps> = props => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const location = useLocation();
  const history = useHistory();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  const locationIncude = (path: string) => {
    return history.location.pathname.includes(path);
  };

  const setTitle = () => {
    if (subEvent !== undefined) {
      switch (true) {
        case locationIncude("sellerPartiForm"): {
          return `${subEvent.sgroupName} ${t("form.registration")}`; // 셀러
        }
        case locationIncude("sellerCounselForm"): {
          return `${subEvent.sgroupName} ${t("form.meetingNote")}`; // 셀러
        }
        case locationIncude("sellerSurveyForm"): {
          return `${subEvent.sgroupName} ${t("form.postSurvey")}`; // 셀러
        }
        case locationIncude("buyerPartiForm"): {
          return `${subEvent.bgroupName} ${t("form.registration")}`; // 바이어
        }
        case locationIncude("buyerCounselForm"): {
          return `${subEvent.bgroupName} ${t("form.meetingNote")}`; // 바이어
        }
        case locationIncude("buyerSurveyForm"): {
          return `${subEvent.bgroupName} ${t("form.postSurvey")}`; // 바이어
        }
        case locationIncude("boothPartiForm"): {
          return "부스업체 참가신청";
        }
        case locationIncude("boothSurveyForm"): {
          return "부스업체 사후설문";
        }
        case locationIncude("visitorPartiForm"): {
          return "사전등록 참관객 참가신청(사전설문)";
        }
        case locationIncude("visitorSurveyForm"): {
          return "사전등록 참관객 사후설문";
        }
        case locationIncude("preview"): {
          return t("common.preview");
        }
        case locationIncude("addBuyerPartiForm"): {
          return `${subEvent.bgroupName} ${t("form.registration")}`; // 바이어
        }
        case locationIncude("addSellerPartiForm"): {
          return `${subEvent.sgroupName} ${t("form.registration")}`; // 셀러
        }
        default: {
          return t("form.makeARegisteringForm");
        }
      }
    }
  };

  const setButtonName = () => {
    switch (true) {
      case locationIncude("PartiForm"):
      case locationIncude("CounselForm"):
      case locationIncude("SurveyForm"):
        return t("common.cancel");
      default: {
        return t("common.complete");
      }
    }
  };

  return subEvent !== undefined ? (
    <StyledNavbar expand="lg" sticky="top" className="border-bottom">
      <>
        {location.pathname === "/form/meeting" || location.pathname === "/form/exhibition" ? (
          <GridContainer direction="row" alignItems="center" justify="space-between" className="px-4">
            <PxGridItem>
              <TitleTypo>양식</TitleTypo>
            </PxGridItem>
            <PxGridItem>
              <PxButton backgroundcolor="grey" onClick={() => history.goBack()}>
                <ButtonTypo>저장 후 나가기</ButtonTypo>
              </PxButton>
              <PxButton backgroundcolor="purple" className="ml-3">
                <ButtonTypo>등록완료</ButtonTypo>
              </PxButton>
            </PxGridItem>
          </GridContainer>
        ) : (
          <PxGridContainer direction="row" alignItems="center" justify="space-between">
            <PxIconButton
              onClick={() => {
                history.push("/");
              }}
            >
              <HomeIcon />
            </PxIconButton>
            {/* <GridContainer direction="row" justify="center"> */}
            <TitleTypo>{setTitle()}</TitleTypo>
            {/* </GridContainer> */}
            <PxButton
              backgroundcolor="purple"
              onClick={() => {
                if (setButtonName() === t("common.cancel")) {
                  if (locationIncude("match")) {
                    history.push(`/form/subEvent/${subEvent?.id}/match`);
                  } else {
                    history.push(`/form/subEvent/${subEvent?.id}/noMatch`);
                  }
                } else {
                  history.push(`/event/detail/${subEvent?.mainEvent?.id}`);
                }
              }}
            >
              <ButtonTypo>{setButtonName()}</ButtonTypo>
            </PxButton>
          </PxGridContainer>
        )}
      </>
    </StyledNavbar>
  ) : null;
};

export default FormNavbar;
