import React, {useState, useEffect, Fragment} from "react";
import {useHistory} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AppState, AppActions} from "../../store";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import {TypographyProps} from "@material-ui/core/Typography";

// types
import Event, {EventInfo, FooterItem} from "../../types/models/Event";

// custom ui comp
import OutlinedTxtField from "../Inputs/OutlinedTxtField";
import SubHeadingTypo from "../Typhography/SubHeadingTypo";
import ShowFile from "../Common/ShowFile";
import PxButton from "../Buttons/PxButton";

// icons
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import PersonIcon from "@material-ui/icons/Person";

import styled from "styled-components";
import ButtonTypo from "../Typhography/ButtonTypo";
import {parallaxCalculation} from "../../utils/momentTz";
import {setDialog} from "../../actions/dialogs";
import {modifyEventApprovalDelete} from "../../actions/events";
import PxGoogleMap from "../Map/PxGoogleMap";
import PxGridItem from "../Grid/PxGridItem";
import {useTranslation} from "react-i18next";
import {Chip} from "@material-ui/core";
import {getRequestAndReturn} from "../../utils/utils";
import EventFooterEdit from "./EventFooterEdit";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const {...rest} = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

type Props = DispatchProps & reduxStateProps;

const EventDetailContent: React.FC<Props> = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {event} = props;
  const [t, i18n] = useTranslation("lang", {useSuspense: false});
  const [footerUseState, setFooterUseState] = useState<boolean>(false);
  const [eventDetailContent, setEventDetailContent] = useState<EventInfo>({
    name: "", // 이벤트 이름
    nameEn: "", // 이벤트 이름 (영문)
    countryCode: "KR", // 주최지 KOR
    startDate: "", // 이벤트 시작일
    endDate: "", // 이벤트 종료일
    image: "", // 이벤트 이미지
    locationCheck: true,
    location: "", // 이벤트 장소
    locationDetail: "", // 이벤트 장소 상세
    lat: "", // 위도
    lng: "", // 경도
    content: "", // 이벤트 개요
    contentEn: "", // 이벤트 개요(영문)
    homePageCheck: true, // 이벤트 웹사이트 체크
    homePage: "", // 이벤트 웹사이트
    atchFile: [], // 첨부파일
    atchFile1: -1, // 첨부파일1
    atchFile2: -1, // 첨부파일2
    atchFile3: -1, // 첨부파일3
    atchFile4: -1, // 첨부파일4
    atchFile5: -1, // 첨부파일5
    mainImageFile: {}, // 메인이미지 정보
    mainImage: -1, // 메인이미지
    digitalMap: "", // 디지털맵
    managers: [], // 담당자
    footer: [],
  });
  const {countries} = useSelector((state: AppState) => state.countries!);
  const [footerItems, setFooterItems] = useState<FooterItem[]>([]);

  useEffect(() => {
    if (event) {
      setEventDetailContent(event);
      // getFooterItems(event?.id as number);
    }
  }, [event]);

  const timeset = (date: string, countryCode: string) => {
    return parallaxCalculation(date, "", countries![countryCode] as any, "YYYY-MM-DD HH:mm");
  };

  // 삭제 다이얼로그
  const deleteClickHandler = async (id: string) => {
    const res: any = await dispatch(modifyEventApprovalDelete(id));
    if (res === "success") {
      dispatch(setDialog(t("eventList.chagneOfStatus"), [t("eventList.requestEventDeleted")]));
      history.push(`/event`);
    }
  };

  return (
    <>
      {eventDetailContent !== undefined && eventDetailContent.id !== undefined && (
        <>
          <SubTitle>{t("eventOverview.hostingPlace")}</SubTitle>
          <Grid style={{display: "flex", alignItems: "center"}}>
            <OutlinedTxtField
              fullWidth
              padding="0px 0px 8px 0px"
              disabled
              value={countries![eventDetailContent.countryCode!] !== undefined ? countries![eventDetailContent.countryCode!].koName : ""}
            />
          </Grid>

          <SubTitle>{t("eventOverview.period")}</SubTitle>
          <Grid style={{display: "flex", alignItems: "center"}}>
            <OutlinedTxtField
              fullWidth
              padding="0px 0px 8px 0px"
              disabled
              value={`${timeset(eventDetailContent.startDate, eventDetailContent.countryCode! as string)} ~ ${timeset(eventDetailContent.endDate, eventDetailContent.countryCode! as string)}`}
            />
          </Grid>
          <SubTitle>{t("makeEvent.categories")}</SubTitle>
          <Grid>
            {eventDetailContent.categories?.split("^&").map(category => (
              <Chip
                style={{
                  backgroundColor: "rgb(95, 75, 139)",
                  color: "white",
                  margin: "4px",
                  fontSize: "14px",
                }}
                label={t(`categories.${category}`)}
              />
            ))}
          </Grid>
          {eventDetailContent.location !== "" && eventDetailContent.locationDetail !== "" && (
            <>
              <SubTitle>{t("eventOverview.placeSet")}</SubTitle>
              {/* 지도 */}
              <PxGridItem className="mb-1">
                {eventDetailContent.lat !== "" && eventDetailContent.lng !== "" && (
                  <PxGoogleMap
                    containerElement={<div style={{height: `240px`}} />}
                    mapElement={<div style={{height: `100%`}} />}
                    lat={Number(eventDetailContent.lat)}
                    lng={Number(eventDetailContent.lng)}
                    marker={true}
                  />
                )}
              </PxGridItem>
              <Grid container direction="row">
                <Grid sm={12} md={12} lg={12}>
                  <OutlinedTxtField fullWidth padding="0px 0px 8px 0px" disabled value={eventDetailContent.location} />
                </Grid>
                <Grid sm={12} md={12} lg={12}>
                  <OutlinedTxtField fullWidth disabled value={eventDetailContent.locationDetail} />
                </Grid>
              </Grid>
            </>
          )}
          {/* 개요 */}
          <SubTitle>{t("eventOverview.content")}</SubTitle>
          <Grid
            sm={12}
            md={12}
            lg={12}
            style={{
              border: "1px solid #ccc",
              padding: "10.5px 14px",
              overflow: "scroll",
              maxHeight: "500px",
            }}
          >
            <div dangerouslySetInnerHTML={{__html: eventDetailContent.content}} />
          </Grid>
          {/* 개요(영문) */}
          {i18n.language === "ko" && (
            <>
              <SubTitle>{t("eventOverview.contentEn")}</SubTitle>
              <Grid
                sm={12}
                md={12}
                lg={12}
                style={{
                  border: "1px solid #ccc",
                  padding: "10.5px 14px",
                  overflow: "scroll",
                  maxHeight: "500px",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: eventDetailContent.contentEn ?? "",
                  }}
                />
              </Grid>
            </>
          )}

          {eventDetailContent.homePage !== "" && (
            <>
              <SubTitle>{t("eventOverview.WebsiteLink")}</SubTitle>
              <Grid container direction="row">
                <Grid sm={12} md={12} lg={12}>
                  <OutlinedTxtField disabled value={eventDetailContent.homePage} fullWidth />
                </Grid>
              </Grid>
            </>
          )}

          {(event?.atchFile1 !== -1 || event?.atchFile2 !== -1 || event?.atchFile3 !== -1 || event?.atchFile4 !== -1 || event?.atchFile5 !== -1) && (
            <>
              <SubTitle>{t("eventOverview.attachment")}</SubTitle>
              <Grid container direction="row">
                <Grid sm={12} md={12} lg={12}>
                  {event?.atchFile1 !== -1 && <ShowFile fileId={event?.atchFile1} />}
                  {event?.atchFile2 !== -1 && <ShowFile fileId={event?.atchFile2} />}
                  {event?.atchFile3 !== -1 && <ShowFile fileId={event?.atchFile3} />}
                  {event?.atchFile4 !== -1 && <ShowFile fileId={event?.atchFile4} />}
                  {event?.atchFile5 !== -1 && <ShowFile fileId={event?.atchFile5} />}
                </Grid>
              </Grid>
            </>
          )}

          {/* 대표 이미지 */}
          <SubTitle>{t("eventOverview.thumbnailShot")}</SubTitle>
          <Grid container direction="row">
            <Grid sm={12} md={12} lg={12}>
              <img
                src={eventDetailContent.mainImageFile !== null ? eventDetailContent.mainImageFile.filePath : eventDetailContent.image}
                alt={eventDetailContent.mainImageFile !== null ? eventDetailContent.mainImageFile.fileName : `${eventDetailContent.name}mainImage`}
                style={{width: "100%", maxWidth: "200px"}}
              />
              <br />
              {eventDetailContent.mainImageFile !== null && eventDetailContent.mainImageFile.fileName}
            </Grid>
          </Grid>

          {/* 배너 이미지 */}
          {eventDetailContent.bannerImageFile !== undefined && eventDetailContent.bannerImageFile !== null && (
            <>
              <SubTitle>{t("eventOverview.banner")}</SubTitle>
              <Grid container direction="row">
                <Grid sm={12} md={12} lg={12}>
                  <img src={eventDetailContent.bannerImageFile.filePath} alt={eventDetailContent.bannerImageFile.fileName} style={{width: "100%", maxWidth: "200px"}} />
                  <br />
                  {eventDetailContent.bannerImageFile.fileName}
                </Grid>
              </Grid>
            </>
          )}

          {/* 담당자 */}
          <Grid container direction="row">
            {Array.isArray(eventDetailContent.managers) && eventDetailContent.managers.length > 0
              ? eventDetailContent.managers.map((evManagers: any, idx: number) => (
                  <Fragment key={`manager-${idx}`}>
                    <Grid
                      container
                      alignContent="space-between"
                      spacing={1}
                      style={{
                        margin: "32px 0 0 0",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                      }}
                    >
                      <Grid>
                        <SubTitle>{t("eventOverview.manager")}</SubTitle>
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                      <Grid sm={12} md={12} lg={12}>
                        <OutlinedTxtField
                          fullWidth
                          padding="0px 0px 8px 0px"
                          propPadding="10.5px 0"
                          value={evManagers.name}
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon
                                  style={{
                                    color: "#ccc",
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid sm={12} md={12} lg={12}>
                        <OutlinedTxtField
                          fullWidth
                          padding="0px 0px 8px 0px"
                          propPadding="10.5px 0"
                          value={evManagers.email}
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon
                                  style={{
                                    color: "#ccc",
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid container alignContent="space-between" spacing={1}>
                        <Grid item sm={6} md={6} lg={6}>
                          <OutlinedTxtField
                            fullWidth
                            padding="0px 0px 8px 0px"
                            propPadding="10.5px 0"
                            value={evManagers.phone}
                            disabled
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PhoneIcon
                                    style={{
                                      color: "#ccc",
                                      width: "18px",
                                      height: "18px",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item sm={6} md={6} lg={6}>
                          <OutlinedTxtField
                            fullWidth
                            padding="0px 0px 8px 0px"
                            propPadding="10.5px 0"
                            value={evManagers.countryNumber}
                            disabled
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PhoneIcon
                                    style={{
                                      color: "#ccc",
                                      width: "18px",
                                      height: "18px",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Fragment>
                ))
              : null}
          </Grid>

          {/* } */}
          <Grid container alignContent="space-between" spacing={1}>
            <Grid item sm={12} style={{textAlign: "right", marginTop: "30px"}}>
              {event?.approval !== "D" && (
                <>
                  <PxButton backgroundcolor="purple" onClick={() => history.push(`/event/modify/${eventDetailContent.id}`)}>
                    <ButtonTypo>{t("eventOverview.modify")}</ButtonTypo>
                  </PxButton>{" "}
                </>
              )}
              {/* <PxButton backgroundcolor="purple" onClick={() => history.push(`/event`)}>
                <ButtonTypo>{t("eventOverview.eventList")}</ButtonTypo>
              </PxButton>{" "} */}
              <PxButton backgroundcolor="grey" onClick={() => deleteClickHandler(eventDetailContent!.id!.toString())}>
                <ButtonTypo>{t("eventOverview.remove")}</ButtonTypo>
              </PxButton>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

interface DispatchProps {}

interface reduxStateProps {
  event: Event["event"];
}

const mapStateToProps = (state: AppState): reduxStateProps => ({
  event: state.events.event,
});

const mapDispatchToprops = (dispatch: ThunkDispatch<any, any, AppActions>): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToprops)(EventDetailContent);
