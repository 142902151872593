import { Dispatch } from "react";
import { AppActions, AppState } from "../store";
import { RegitDeskApplicants } from "../types/models/Applicants";
import { getRequest, postRequest } from "../utils/utils";
import axios, { AxiosResponse } from "axios";
import Alert from "../types/models/Alert";
import { setAlert } from "../utils/utils";
import Error from "../types/models/Error";
import { removeAlertAction, setAlertAction } from "./alerts";
import { RegisterFormAnswer, RegisterUserDetail, RegisterUserPage, RegitUser } from "../types/models/Registration";
import { OnSiteUser } from "../types/models/Printer";

export const getApplicantAction = (
  applicant: RegitDeskApplicants
): AppActions => ({
  type: "GET_APPLICANT",
  applicant,
});

export const getApplicantsAction = (
  applicants: RegitDeskApplicants[]
): AppActions => ({
  type: "GET_APPLICANTS",
  applicants,
});


export const resetApplicant = (): AppActions => ({
  type: "RESET_APPLICANT",
});

export const getApplicants = (subEventId: string) => {
  return getRequest(null, "/api/applicants/deskUsers", getApplicantsAction, {
    subEventId: subEventId,
  });
};

export const getApplicant = (id: string) => {
  return getRequest(null, "/api/applicants/deskUser", getApplicantAction, {
    id: id,
  });
};

export const updateRegisterFormAnswers = (registerFormAnswer:Array<RegisterFormAnswer>) => {
  return postRequest({formAnswerReq:registerFormAnswer}, '/api/registerAnswer/update');
}

export const updateApplicant =
  (applicant: RegitDeskApplicants) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Accept-Language": "ko",
        },
      };

      const body = JSON.stringify(applicant);

      const res: AxiosResponse<RegitDeskApplicants> = await axios.put(
        "/api/applicants/deskUserUpdate",
        body,
        config
      );

      if (getState().applicants.applicants) {
        const applicants = [...getState().applicants.applicants!];

        const updatedIndex = applicants.findIndex(
          (applicant) => applicant.id === res.data.id
        );

        applicants.splice(updatedIndex, 1, res.data);

        dispatch(getApplicantsAction(applicants));
      }
      return true;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, "errorAlert");
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return false;
    }
  };

export const getApplicantByPhoneNum =
  (subEventId: string, phoneNum: string) =>
  async (dispatch: Dispatch<AppActions>) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Accept-Language": "ko",
        },
        params: { subEventId: subEventId, phoneNum: phoneNum },
        data: {},
      };

      const res: AxiosResponse<RegitDeskApplicants> = await axios.get(
        "/api/applicants/deskPrintUser",
        config
      );

      return res.data;
    } catch (err) {
      const error = err.response.data;
      const alert: Alert = setAlert(
        err.response.status,
        error,
        "printDeskError"
      );
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

export const getOnSiteRegitUserByPhoneNum =
  (subEventId: string, phoneNum: string) =>
  async (dispatch: Dispatch<AppActions>) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Accept-Language": "ko",
        },
        params: { subEventId: subEventId, phone: phoneNum },
        data: {},
      };

      const res: AxiosResponse<OnSiteUser> = await axios.get(
        "/api/onSiteRegit/user",
        config
      );

      return res.data;
    } catch (err) {
      const error = err.response.data;
      const alert: Alert = setAlert(
        err.response.status,
        error,
        "printDeskError"
      );
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };
