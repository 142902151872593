import React, { ReactNode } from "react";
import styled from "styled-components";

// bs comp
import { MuiThemeProvider, createMuiTheme, ThemeProviderProps } from "@material-ui/core";

interface PxBreakPointProps {
  children: ReactNode;
}

type Prop = PxBreakPointProps;

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "''Spoqa Han Sans Neo', sans-serif",
  },
});

const PxBreakpoints = ({ children }: Prop) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default PxBreakpoints;
