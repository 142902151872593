import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import { SubEventInfo } from "../../../types/models/SubEvent";
import makeStyles from "@material-ui/core/styles/makeStyles";

/*****************

요일 번역이 되어야함
캘린더에서 해당일 호버시 주간이 백그라운드 색들어가서 주간이 선택되는 느낌 주기
미팅잡힌날에는 해당일에 빨간점 표시
요청이나 대기인날은 노락색 표시
오늘 버튼 
다음달 버튼 이전달 버튼

******************/

export default ({
  subEventInfo,
  disabledTime,
  dayRef,
  setDayRef,
  width,
  repeatSettingValue,
}: {
  subEventInfo: SubEventInfo;
  dayRef: React.MutableRefObject<HTMLDivElement>[];
  disabledTime: any;
  setDayRef: React.Dispatch<React.SetStateAction<React.MutableRefObject<HTMLDivElement>[]>>;
  repeatSettingValue: string;
  width?: string;
}) => {
  const [date, setDate] = useState<moment.Moment>(moment(subEventInfo.subEventStartDate));
  const [calendarDate, setCalendarDate] = useState(date.startOf("months"));
  const [t] = useTranslation("lang", { useSuspense: false });

  const updateCalendarDate = (
    //달력 날짜 변경
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation(); //없으면 다음페이지로 넘어가버림
    event.preventDefault();
    // setDayRef({ current: undefined }); //선택한 날짜 취소 안하면 불가시간설정에서 사용자가 생각한 날짜와 다르게 적용될 수 있음
    setCalendarDate(
      calendarDate
        .clone()
        .add(event.currentTarget.name === "nextWeek" ? 1 : -1, "months")
        .startOf("months")
    );
  };

  const itemClick = useCallback(
    // 날짜 선택
    (selectDate: moment.Moment, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setDate(selectDate.clone());
      setCalendarDate(selectDate.clone());
      e.currentTarget?.setAttribute("style", "background-color:#d1c4e9");

      if (repeatSettingValue === "default") {
        dayRef[0]?.current.setAttribute("style", "background-color:transplate");
        setDayRef([{ current: e.currentTarget }]);
      } else if (repeatSettingValue === "multiple") {
        if (dayRef.some((ref) => ref.current === e.currentTarget)) {
          setDayRef(dayRef.filter((ref) => ref.current !== e.currentTarget));
          e.currentTarget?.setAttribute("style", "background-color:transplate");
        } else {
          setDayRef([...dayRef, { current: e.currentTarget }]);
        }
      }
    },
    [dayRef, repeatSettingValue]
  );

  useEffect(() => {
    if (dayRef.length <= 0) return;
    dayRef.forEach((ref) => ref.current.setAttribute("style", "background-color:transplate"));
    setDayRef([]);
  }, [repeatSettingValue]);

  useEffect(() => {
    if (dayRef.length <= 0 || !window) return;
    dayRef.forEach((ref) =>
      window?.document
        ?.getElementById(ref.current.id)
        ?.setAttribute("style", "background-color:#d1c4e9")
    );
  }, [calendarDate]);

  const calendarGenerator = useCallback(
    /// 달력만들기
    (date: moment.Moment) => {
      const subEventStartDate = subEventInfo.subEventStartDate.split("T")[0];
      const subEventEndDate = subEventInfo.subEventEndDate.split("T")[0];
      // startOf('month') : 이번 달의 첫번 째 날로 설정 set to the first of this month, 12:00 am
      // week() : Week of Year. 이번 년도의 몇번째 주인가? => 1월 1일 이면 1, 12월 31일이면 53
      const startWeek = date.clone().startOf("month").week();
      // endOf('month').week() : 이번 달의 마지막 날로 설정 한 후 그것이 이번 년도의 몇번째 주인지 체크
      // 만약 이번 해의 첫번째 주(1월 1일이 속한 주)라면 53으로 세팅, 아니라면 그대로 유지
      // 이런 작업의 이유는 마지막 주는 53 이고 첫번째주는 1이기 때문에
      const endWeek =
        date.clone().endOf("month").week() === 1 ? 53 : date.clone().endOf("month").week();
      let calendar = [];
      calendar.push(
        // 요일 추가
        <Row currentDate={false} key={0}>
          {[
            t("calendar.SUN"),
            t("calendar.MON"),
            t("calendar.TUE"),
            t("calendar.WED"),
            t("calendar.THU"),
            t("calendar.FRI"),
            t("calendar.SAT"),
          ].map((w, i) => {
            return (
              <Item key={i}>
                <span>{w}</span>
              </Item>
            );
          })}
        </Row>
      );

      for (let week = startWeek; week <= endWeek; week++) {
        const startOfWeek = date.clone().week(week).startOf("week");
        const endOfWeek = date.clone().week(week).endOf("week").add(-1, "day"); // 일요일 부터 토요일까지 한주로 달력에 표시해주기 때문에 -1
        calendar.push(
          <Row
            currentDate={
              startOfWeek.isBetween(subEventStartDate, subEventEndDate, undefined, "[]") ||
              endOfWeek.isBetween(subEventStartDate, subEventEndDate, undefined, "[]")
            }
            key={week}
          >
            {Array(7)
              .fill(0)
              .map((n, i) => {
                // 오늘 => 주어진 주의 시작 => n + i일 만큼 더해서 각 주의 '일'을 표기한다.
                const current = date
                  .clone()
                  .week(week)
                  .startOf("week")
                  .add(n + i, "day");

                const currentDate = current.isBetween(
                  subEventStartDate,
                  subEventEndDate,
                  undefined,
                  "[]"
                ); // 서브 이벤트 기간에 포함 확인 포함된 날짜일때만 선택가능 , 미포함날짜는 disabled
                let color = currentDate ? "#4b4c4e" : "#a9aaad";
                const dateFormat = current.format("YYYY-MM-DD");
                return (
                  <Item
                    color={color}
                    key={i}
                    id={dateFormat}
                    onClick={(e) => currentDate && itemClick(current, e)}
                    currentDate={currentDate}
                  >
                    <span>{current.format("D")}</span>
                    <ItemFooter>
                      {checkDiabledTime(disabledTime, dateFormat) && (
                        <Status id={`x_${dateFormat}`} />
                      )}
                    </ItemFooter>
                  </Item>
                );
              })}
          </Row>
        );
      }
      return calendar;
    },
    [moment, itemClick, disabledTime]
  );

  return (
    <Calendar onClick={(e) => e.stopPropagation()} width={width}>
      <Box justifyContent="center" fontSize="14px" display="flex">
        <MonthMoveBtn name="prevWeek" onClick={updateCalendarDate}>
          <ChevronLeftIcon />
        </MonthMoveBtn>
        <CalenderBtn>{calendarDate.format("YYYY. MM")}</CalenderBtn>
        <MonthMoveBtn name="nextWeek" onClick={updateCalendarDate}>
          <ChevronRightIcon />
        </MonthMoveBtn>
      </Box>
      {calendarGenerator(calendarDate)}
    </Calendar>
  );
};

const CalenderBtn = styled.div`
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 5px;
  display: flex;
  align-items: center;
  &:focus {
    outline: none;
  }
`;

const MonthMoveBtn = styled.button`
  background-color: transparent;
  border: none;
  box-shadow: none;
  &:hover {
    border-radius: 20%;
    outline: none;
    background-color: #e9eaed;
  }
  &:focus {
    outline: none;
  }
`;

const Calendar = styled.div<{ width?: string }>`
  width: 100%;
  height: 100%;
  display: grid;
  background-color: white;
  gap: 10px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 24px 38px 3px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 20px;
`;
const Row = styled.div<{ currentDate: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  grid-auto-flow: column;
`;
const Item = styled.div<{ color?: string; currentDate?: boolean }>`
  color: ${(props) => (props.color ? props.color : "#4b4c4e")};
  text-align: center;
  border-radius: 8px;
  ${(props) =>
    props.currentDate &&
    css`
      cursor: pointer;
      &:hover {
        background-color: #c7c8cb;
      }
    `}
`;

const ItemFooter = styled.div`
  display: flex;
  margin-top: 4px;
  height: 6px;
  justify-content: center;
`;

const Status = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: red;
  &:not(:first-child) {
    margin-left: 5px;
  }
`;

function checkDiabledTime(disabledTime: any, date: string): boolean {
  const [year, month, day] = date.split("-");
  return (
    disabledTime &&
    disabledTime.hasOwnProperty(year) &&
    disabledTime[year].hasOwnProperty(month) &&
    disabledTime[year][month].length > 0 &&
    disabledTime[year][month].some((d: string) => d.split("_")[0] === day)
  );
}

//시작일 이후의 첫번째 해당 요일을 찾는다.
//
