import React from "react";
import {Trans, useTranslation} from "react-i18next";
import Banner from "./Banner";
import GroupIcon from "../../../../assets/icons/sharp-groups.svg";
import {SubEventInfo} from "../../../../types/models/SubEvent";

interface Props {
  subEvent: SubEventInfo;
}

const RegisterBanner: React.FC<Props> = ({subEvent}) => {
  const [t] = useTranslation("lang", {useSuspense: false});

  return (
    <Banner
      bgColor="#af52de"
      IconNode={<img src={GroupIcon} />}
      estimateLink={`/estimate/subEvent/${subEvent.id}/conference`}
      actionBtnTypo={t("eventDetail.finalQuote")}
      actionBtnClick={() => {}}
      title={<>{t("common.register")}</>}
      body={
        <Trans t={t} i18nKey="eventDetail.conferenceBannerInfo">
          출입증 발급을 위한 무선프린터, 출입증 용지, 목걸이 등<br />
          현장에 필요한 서비스가 있는지 확인해보세요.
        </Trans>
      }
    />
  );
};

export default RegisterBanner;
