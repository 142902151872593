import React, {useEffect, useState} from "react";
import PxButton from "../Buttons/PxButton";
import FileSaver from "file-saver";
// import XLSX from "xlsx";
import XLSX from "xlsx-js-style";
import ButtonTypo from "../Typhography/ButtonTypo";
import {useTranslation} from "react-i18next";
import {MeetingSurvey} from "../../types/models/Counsel";
import {useSelector} from "react-redux";
import {AppState} from "../../store";

interface ExportSurveyProps {
  sheetData: MeetingSurveyExcelRefinedData[];
  sheetNames: string[];
  fileName: string;
  isSurveyContent: boolean;
}

interface MeetingSurveyExcelRefinedData {
  name?: string;
  managerName?: string;
  modifiedDate?: string;
  otherCompanyName?: string;
  otherCompanyManagerName?: string;
  survey: MeetingSurvey[];
  type?: string;
  date?: string;
  startTime?: string;
  endTime?: string;
  status?: string;
}

const ExportSurveyExcel = ({sheetData, fileName, sheetNames, isSurveyContent}: ExportSurveyProps) => {
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [t] = useTranslation("lang", {useSuspense: false});
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const createExcelData = (meetings: MeetingSurveyExcelRefinedData[]) => {
    const wsData = meetings.map((meeting, index) => {
      const {survey, status, name, managerName, type, otherCompanyName, otherCompanyManagerName, date, startTime, endTime} = meeting;
      const otherType = type === subEvent?.sgroupName ? subEvent?.bgroupName : subEvent?.sgroupName;
      let mergeInfo: any[] = []; // To store cell merging info
      let currentRow = 6; // Starting row number

      const meetingSheet = [
        ["구분", "소속명", "담당자", "구분", "상대 소속명", "상대 담당자"],
        [type, name, managerName, otherType, otherCompanyName, otherCompanyManagerName],
        [],
        ["미팅 날짜", "시작시간", "종료시간", "", "작성여부"],
        [date, startTime, endTime, "", status],
        [],
        ...survey?.flatMap(item => {
          // if (item.type === "long") {
          //   mergeInfo.push({startRow: currentRow + 1, endRow: currentRow + 5}); // Store merging info
          // }
          currentRow += 2; // We add 2 rows per survey item
          return [[item.title], [item.content], []];
        }),
      ];
      return {data: meetingSheet, mergeInfo};
    });

    return wsData;
  };

  const exportToCSV = (excelInfo: {data: any[][]; mergeInfo: {startRow: number; endRow: number}[]}[], fileName: string) => {
    let wb = XLSX.utils.book_new();

    if (excelInfo.length === 0) {
      const ws = XLSX.utils.aoa_to_sheet([]);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    } else {
      excelInfo.forEach(({data, mergeInfo}, index) => {
        const ws = XLSX.utils.aoa_to_sheet(data);
        // Handle merged cells
        ws["!merges"] = mergeInfo.map(info => ({s: {r: info.startRow, c: 0}, e: {r: info.endRow, c: 5}})); // Use stored merging info

        XLSX.utils.book_append_sheet(wb, ws, sheetNames[index]);
      });
    }

    const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <PxButton backgroundcolor="purple" onClick={e => exportToCSV(createExcelData(sheetData), fileName)}>
      <ButtonTypo>{t("excel.download(xlsx)")}</ButtonTypo>
    </PxButton>
  );
};

export default ExportSurveyExcel;
