import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { AppActions, AppState } from "../store";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import Program, { ProgramInfo } from "../types/models/Program";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { setAlert } from "../utils/utils";
import moment from "moment";

export const createProgramAction = (program: ProgramInfo): AppActions => ({
  type: "CREATE_PROGRAM",
  program,
});

export const getProgramsAction = (programs: ProgramInfo[]): AppActions => ({
  type: "GET_PROGRAMS",
  programs,
});

export const getProgramAction = (program: ProgramInfo): AppActions => ({
  type: "GET_PROGRAM",
  program,
});

export const createProgram = (program: ProgramInfo) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  // datetime-local 에서 .toISOString 인식을 못해서 action에서 처리
  program.startDate = moment(program.startDate).toISOString();
  program.endDate = moment(program.endDate).toISOString();

  const body = JSON.stringify(program);

  const path = `/api/programs`;
  try {
    var res: AxiosResponse<Event> = await axios.post(path, body, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

export const getPrograms = <Program>(subEventId: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/programs?subEventId=${subEventId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getProgramsAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

export const getProgram = <Program>(programId: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/programs/detail?programId=${programId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getProgramAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};
