import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { useHistory, useParams } from "react-router";
import { AppState } from "../../../../store";
import { OnSiteUser } from "../../../../types/models/Printer";
import { removeAlertAction, setAlertAction } from "../../../../actions/alerts";
import {
  createOnSiteRegitUser,
  setPrinterLoadingAction,
} from "../../../../actions/printers";
import { printLabel } from "../../../../actions/deskPrint";
import HeadlineTypo from "../../../Typhography/HeadlineTypo";
import SubHeadingTypo from "../../../Typhography/SubHeadingTypo";
import OutlinedTxtField from "../../../Inputs/OutlinedTxtField";
import PxGridContainer from "../../../Grid/PxGridContainer";
import PxButton from "../../../Buttons/PxButton";
import TitleTypo from "../../../Typhography/TitleTypo";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
// import {
//   createOnSiteRegitUser,
//   setPrinterLoadingAction,
// } from "../../actions/printers";
// import { printLabel } from "../../actions/deskPrint";
// import { removeAlertAction, setAlertAction } from "../../actions/alerts";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 50px 150px 50px",
  },
  subHeadingTypo: {
    margin: "10px 0 8px 0",
    fontWeight: "bold",
  },
}));

const PrinterOnSite = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const cellPhoneRegExp = new RegExp("^\\d+$"); // 핸드폰 번호 입력 정규식 (숫자만 입력가능)
  const params = useParams<{ subEventId: string }>();
  const { onSiteRegit, printer, loading, alert } = useSelector(
    (state: AppState) => state.printers
  );
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [onSiteUser, setOnSiteUser] = useState<OnSiteUser>({
    name: "",
    phoneNum: "",
    type: "",
    subEventId: "",
  });

  useEffect(() => {
    if (subEvent) {
      setOnSiteUser((prevState) => ({
        ...prevState,
        type: onSiteRegit,
        subEventId: subEvent.id!,
      }));
    }
  }, [onSiteRegit, params.subEventId, subEvent]);

  useEffect(() => {
    if (loading === false && alert) {
      dispatch(setAlertAction(alert!));
      setTimeout(() => {
        dispatch(removeAlertAction(alert!.id));
        dispatch(setPrinterLoadingAction(false, undefined));
      });
      if (alert.id === "printerSuccess") {
        history.goBack();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, alert, dispatch]);

  const textFieldOnChange = (
    e: React.ChangeEvent<{
      value: string;
      name: string;
    }>
  ) => {
    if (e.target.name === "phoneNum") {
      if (cellPhoneRegExp.test(e.target.value) || e.target.value === "") {
        setOnSiteUser({ ...onSiteUser, [e.target.name]: e.target.value });
      }
    } else {
      setOnSiteUser({ ...onSiteUser, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = async () => {
    const result: any = await dispatch(createOnSiteRegitUser(onSiteUser));
    if (result && printer && subEvent) {
      printLabel({
        applicantId: Number(result.id),
        eventName: subEvent.mainEvent?.name ?? "",
        name: result.name,
        dispatch,
        ipAddress: printer.ipAddress,
        printerName: printer.name,
        type:
          onSiteRegit === "seller" ? subEvent.sgroupName : subEvent.bgroupName,
        companyName: result.companyName,
        position: result.position,
      });
    }
  };

  return (
    <Container className={classes.root} maxWidth="sm">
      <Backdrop open={loading} style={{ zIndex: 1000, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <HeadlineTypo style={{ textAlign: "center" }}>
        현장등록(
        {onSiteRegit === "seller" ? subEvent?.sgroupName : subEvent?.bgroupName}
        )
      </HeadlineTypo>
      <Box height={20} />
      <SubHeadingTypo className={classes.subHeadingTypo}>이름</SubHeadingTypo>
      <OutlinedTxtField
        name="name"
        value={onSiteUser.name}
        fullWidth
        onChange={textFieldOnChange}
        placeholder="이름을 입력해주세요."
      />
      <Box height={10} />
      <SubHeadingTypo className={classes.subHeadingTypo}>
        핸드폰 번호
      </SubHeadingTypo>
      <OutlinedTxtField
        name="phoneNum"
        value={onSiteUser.phoneNum}
        fullWidth
        onChange={textFieldOnChange}
        placeholder="핸드폰번호를 입력해주세요."
      />
      <Box height={10} />
      <SubHeadingTypo className={classes.subHeadingTypo}>
        회사이름(선택)
      </SubHeadingTypo>
      <OutlinedTxtField
        name="companyName"
        value={onSiteUser.companyName ?? ""}
        fullWidth
        onChange={textFieldOnChange}
        placeholder="회사이름을 입력해주세요."
      />
      <Box height={10} />
      <SubHeadingTypo className={classes.subHeadingTypo}>
        직함(선택)
      </SubHeadingTypo>
      <OutlinedTxtField
        name="position"
        value={onSiteUser.position ?? ""}
        fullWidth
        onChange={textFieldOnChange}
        placeholder="직함을 입력해주세요."
      />
      <Box height={40} />
      <PxGridContainer justify="center">
        <PxButton
          noBorderRadius
          padding="10px 60px"
          onClick={() => history.goBack()}
        >
          <TitleTypo txtcolor="secondary">취소</TitleTypo>
        </PxButton>
        <PxButton
          backgroundcolor="purple"
          noBorderRadius
          padding="10px 60px"
          onClick={onSubmit}
        >
          <TitleTypo>완료</TitleTypo>
        </PxButton>
      </PxGridContainer>
    </Container>
  );
};

export default PrinterOnSite;
