import React, {Fragment, useEffect, useState} from "react";
import {ApplicationFormAnswers} from "../../../../../types/models/Application";
import ContentFormItem from "./ContentFormItem";
import {useCompanyInfoContext} from "./useCompanyInfoContext";
import find from "lodash/find";
import filter from "lodash/filter";
import TitleTypo from "../../../../../components/Typhography/TitleTypo";
import styled from "styled-components";
import PxButton from "../../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../../components/Typhography/ButtonTypo";
import Paper from "@material-ui/core/Paper";
import PxGridContainer from "../../../../../components/Grid/PxGridContainer";
const groupBy = require("json-groupby");

const RootDiv = styled.div`
  margin-bottom: 20px;
`;

const FormDiv = styled(Paper)`
  padding: 10px;
  margin-bottom: 30px;
`;

const CompanyInfoAdditional = () => {
  const {additionalForm, additionalApplicationFormAnswers, modify, modifiedApplicationFormAnswers, onClickAdditionalAnswerAdd, onClickAdditionalAnswerDelete} = useCompanyInfoContext();

  // 추가정보 답변의 additionOrder 값의로 groupby 한 값
  const [groupedAnswer, setGroupedAnswers] = useState<{
    [additionOrder: string]: ApplicationFormAnswers[];
  }>();

  // 수정된 추가정보 답변의 additionOrder 값으로 groupby 한 값
  const [groupedModifiedAnswers, setGroupedModifiedAnswers] = useState<{
    [additionOrder: string]: ApplicationFormAnswers[];
  }>();

  useEffect(() => {
    // 답변목록을 additionOrder (추가정보 순서)에 따라 분류
    if (additionalApplicationFormAnswers.length !== 0) {
      const seperatedAdditionalAnswers = groupBy(additionalApplicationFormAnswers, ["additionOrder"]);
      setGroupedAnswers(seperatedAdditionalAnswers);
    } else {
      setGroupedAnswers({});
    }
  }, [additionalApplicationFormAnswers]);

  useEffect(() => {
    if (modify === true) {
      // 추가정보 답변만 filtering
      const modifiedApplicationAdditionalAnswers = filter(modifiedApplicationFormAnswers, {repeatInfo: "Y"});

      // 추가정보 순서로 groupby
      const groupedAnswers = groupBy(modifiedApplicationAdditionalAnswers, ["additionOrder"]);

      setGroupedModifiedAnswers(groupedAnswers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedApplicationFormAnswers]);

  return additionalForm !== undefined ? (
    <Fragment>
      {modify === false &&
        groupedAnswer !== undefined &&
        Object.values(groupedAnswer).map((seperAnswers: ApplicationFormAnswers[], index) => (
          <FormDiv key={index}>
            <TitleTypo>{`추가정보 ${index + 1}`}</TitleTypo>
            {additionalForm.formIds.map((formId, index) => {
              const applicationFormAnswer = find(seperAnswers, {
                uuid: formId,
              });

              const answers: ApplicationFormAnswers[] = [];
              if (additionalForm.formData[formId].type === "many") {
                seperAnswers.forEach(answer => {
                  if (answer.uuid === formId) {
                    answers.push(answer);
                  }
                });
              }

              return (
                <ContentFormItem
                  key={index}
                  modify={modify}
                  contentFormData={additionalForm.formData[formId]} // 신청서 자율양식 form 형식
                  applicationFormAnswer={applicationFormAnswer!} // 신청서 답변
                  applicationManyFormAnswers={answers}
                  whtAdditional={true}
                />
              );
            })}
          </FormDiv>
        ))}
      {modify === true && groupedModifiedAnswers !== undefined && (
        <RootDiv>
          {Object.entries(groupedModifiedAnswers).map(([key, seperAnswers], orderIndex) => (
            <FormDiv key={orderIndex} elevation={10}>
              <PxGridContainer alignItems="center" justify="space-between">
                <TitleTypo>{`추가정보 ${orderIndex + 1}`}</TitleTypo>
                <PxButton
                  padding="8px 15px"
                  backgroundcolor="grey"
                  onClick={() => {
                    onClickAdditionalAnswerDelete(parseInt(key));
                  }}
                >
                  <ButtonTypo>삭제</ButtonTypo>
                </PxButton>
              </PxGridContainer>
              {additionalForm.formIds.map((formId, index) => {
                const applicationFormAnswer = find(seperAnswers, {
                  uuid: formId,
                });

                const answers: ApplicationFormAnswers[] = [];
                if (additionalForm.formData[formId].type === "many") {
                  seperAnswers.forEach(answer => {
                    if (answer.uuid === formId) {
                      answers.push(answer);
                    }
                  });
                }

                return (
                  <ContentFormItem
                    key={index}
                    modify={modify}
                    contentFormData={additionalForm.formData[formId]} // 신청서 자율양식 form 형식
                    applicationFormAnswer={applicationFormAnswer!} // 신청서 답변
                    applicationManyFormAnswers={answers}
                    whtAdditional={true}
                    additionOrder={orderIndex} // 추가정보 순서
                  />
                );
              })}
            </FormDiv>
          ))}
          {/* 추가버튼 */}
          <PxButton backgroundcolor="purple" fullWidth onClick={onClickAdditionalAnswerAdd}>
            <ButtonTypo>추가</ButtonTypo>
          </PxButton>
        </RootDiv>
      )}
    </Fragment>
  ) : null;
};

export default CompanyInfoAdditional;
