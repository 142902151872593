import {Column, MTableToolbar} from "material-table";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {match as Match, RouteComponentProps, useHistory, useParams} from "react-router-dom";
import {getMails, updateMailOpened} from "../../../../../actions/mail";
import {setTableInfoLoadingAction} from "../../../../../actions/tableInfo";
import {AppState} from "../../../../../store";
import {getRequestAndReturn, postRequestAndReturn} from "../../../../../utils/utils";
import PxGridContainer from "../../../../Grid/PxGridContainer";
import PxTable from "../../../../Table/PxTable";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import BarChartIcon from "@material-ui/icons/BarChart";
import {IconButton} from "@material-ui/core";
import abcd from "@material-ui/icons/BarChartRounded";
import styled from "styled-components";

// interface RowType {
//   id: string;
//   code: string;
//   title: string;
//   // content: string;
//   createdDate: string;
//   createdUserId: string;
//   subEventId: string;
//   toMail: string;
//   status: string;
//   sendTime: string;
// }

interface RowType {
  id: number;
  requestUuid: string;
  title: string;
  createdDate: string;
  totalCount: number;
  deliveries: string;
  opens: string;
  clicks: string;
}

interface ResType {
  id: number;
  requestUuid: string;
  title: string;
  createdDate: string;
  totalCount: number;
  deliveries: number;
  opens: number;
  clicks: number;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

interface ChangeMailData {
  mailId: number;
  status: string;
}

interface MatchParams {
  id: string;
}

// interface Props extends RouteComponentProps<MatchParams> {

// }

const MailRequestList = ({match}: {match: Match}) => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const history = useHistory();
  const dispatch = useDispatch();
  const param: {id: string} = useParams();
  const mails = useSelector((state: AppState) => state.mails.mails);
  const sendgrid = useSelector((state: AppState) => state.mails.sendGridMails);
  // const subEvent
  const [changMailStatus, setChangMailStatus] = useState<ChangeMailData[]>([]);
  const [content, setContent] = useState<Array<ResType>>([]);
  const [totalElement, setTotalElement] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [state, setState] = useState<TableStateType>({
    columns: [
      {
        title: "메일 ID",
        field: "requestUuid",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: "제목",
        field: "title",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: "생성일",
        field: "createdDate",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: "수신자",
        field: "totalCount",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: "전송",
        field: "deliveries",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: "열기",
        field: "opens",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: "클릭",
        field: "clicks",
        cellStyle: {width: "100px", paddingRight: 0},
      },
      {
        title: "",
        field: "preview",
        cellStyle: {width: "25px"},
        render: rowData => {
          return (
            <MenuIconContainer>
              <IconButton onClick={() => visibilityClick(rowData.id)}>
                <VisibilityIcon />
              </IconButton>
              <IconButton onClick={() => copyClick(rowData.id)}>
                <CopyIcon />
              </IconButton>
              <IconButton onClick={() => barChartClick(rowData.id)}>
                <BarChartIcon />
              </IconButton>
            </MenuIconContainer>
          );
        },
      },
    ],
    data: [],
  });

  const visibilityClick = (requestId: number) => {
    history.push(`/subEvent/${param.id}/mailPreview/${requestId}`);
  };

  const copyClick = async (requestId: number) => {
    history.push(`/subEvent/${param.id}/mail/requestCopy/${requestId}`);
  };

  const barChartClick = (requestId: number) => {
    history.push(`/subEvent/${param.id}/mailDashBoard/${requestId}`);
  };

  useEffect(() => {
    getMailRequestList();
  }, []);

  const getMailRequestList = async () => {
    const res: any = await dispatch(
      getRequestAndReturn("/api/mails/request/list", {
        subEventId: param.id,
        page: 0,
        size: 10,
      })
    );

    setContent(res.content);
    setTotalElement(res.totalElements);
    setTotalPage(res.totalPages);
  };

  useEffect(() => {
    const rowData = content.map(item => {
      const row: RowType = {
        id: item.id,
        requestUuid: item.requestUuid,
        title: item.title,
        createdDate: item.createdDate,
        totalCount: item.totalCount,
        deliveries: getStatePercent(item.totalCount, item.deliveries),
        opens: getStatePercent(item.totalCount, item.opens),
        clicks: getStatePercent(item.totalCount, item.clicks),
      };
      return row;
    });
    setState(prevData => {
      return {...prevData, data: rowData};
    });
  }, [content]);

  const getStatePercent = (totalCount: number, targetCount: number): string => {
    if (targetCount === 0) return `0%`;
    return `${((targetCount / totalCount) * 100).toFixed(1)}%`;
  };

  return (
    <>
      <PxTable<RowType>
        components={{
          Toolbar: props => {
            return (
              <PxGridContainer direction="column">
                {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                <MTableToolbar {...props} />
              </PxGridContainer>
            );
          },
        }}
        title=""
        columns={state.columns}
        actionData={param.id}
        totalPage={totalPage}
        totalElements={totalElement}
        getAction={getMails}
        data={state.data}
        onRowClick={(evt, selectedRow) => {
          // history.push(`${match.url}/${selectedRow?.id}`);
        }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
          paginationType: "normal",
          paging: true,
          pageSize: 10,
        }}
      />
    </>
  );
};

export default MailRequestList;

const MenuIconContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
