import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, RouteComponentProps } from "react-router-dom";
import { AppState } from "../../../../store";
import { Column, MTableToolbar } from "material-table";

// actions
import { getSpeakers } from "../../../../actions/speaker";
// custom ui comp
import PxTable from "../../../../components/Table/PxTable";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import PxGridContainer from "../../../Grid/PxGridContainer";

import moment from "moment";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";

interface RowType {
  speakerName: string;
  speakerBelongs: string;
  speakerTitle: string;
  speakerCreatedDate: string;
  speakerDate: string;
  speakerUpdatedDate: string;
  speakerentId: string;
  speakerApproval: "Y" | "N";
  speakerId: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const SpeakerList: React.FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const param: { id: string } = useParams();
  const speakers = useSelector((state: AppState) => state.speakers.speakers);

  // 확정 버튼에 따른 값 변경
  const approvalTitle = {
    N: {
      buttonTitle: "확정하기",
      buttonValue: "Y",
    },
    Y: {
      buttonTitle: "확정 취소하기",
      buttonValue: "X",
    },
  };

  const callGetSpeakers = useCallback(async () => {
    await dispatch(getSpeakers(param.id));
    dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  useEffect(() => {
    callGetSpeakers();
  }, [callGetSpeakers]);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: "이름",
        field: "speakerName",
        cellStyle: {
          width: "calc(100%-600px)",
          paddingRight: 0,
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "소속",
        field: "speakerBelongs",
      },
      {
        title: "직함",
        field: "speakerTitle",
      },
      {
        title: "등록일",
        field: "speakerCreatedDate",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
      },
      {
        title: "수정일",
        field: "speakerUpdatedDate",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
      },
      // {
      //   title: "상태",
      //   field: "speakerStatus",
      //   cellStyle: {
      //     width: "100px",
      //     paddingRight: 0,
      //   },
      //   render: (rowData) => (
      //     <PxButton
      //       backgroundcolor="purple"
      //       style={{ padding: 5 }}
      //       onClick={() =>
      //         console.log(approvalTitle[rowData.speakerApproval].buttonValue)
      //       }
      //     >
      //       <ButtonTypo>
      //         {approvalTitle[rowData.speakerApproval].buttonTitle}
      //       </ButtonTypo>
      //     </PxButton>
      //   ),
      // },
    ],
    data: [],
  });

  useEffect(() => {
    let tableData: any = [];
    if (Array.isArray(speakers) && speakers.length > 0) {
      speakers.map((speaker) => {
        const rowData = {
          speakerName: speaker.name,
          speakerBelongs: speaker.belongs,
          speakerTitle: speaker.title,
          speakerApproval: speaker.approval,
          speakerCreatedDate: moment(speaker.createdDate).format("YYYY-MM-DD"),
          speakerUpdatedDate: moment(speaker.modifiedDate).format("YYYY-MM-DD"),
          speakerId: speaker.id,
        };
        tableData.push(rowData);
      });
    }
    setState((prevCreateDate) => {
      return { ...prevCreateDate, data: tableData };
    });
  }, [speakers]);

  return (
    <>
      <div style={{ textAlign: "right", margin: "10px" }}>
        <PxButton
          backgroundcolor="purple"
          onClick={() => history.push(`${match.url}/add`)}
        >
          <ButtonTypo>스피커 추가</ButtonTypo>
        </PxButton>
      </div>

      <PxTable<RowType>
        components={{
          Toolbar: (props) => {
            return (
              <PxGridContainer direction="column">
                {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                <MTableToolbar {...props} />
              </PxGridContainer>
            );
          },
        }}
        title="Basic Selection Preview"
        columns={state.columns}
        data={state.data}
        onRowClick={(evt, selectedRow) => {
          history.push(`${match.url}/${selectedRow?.speakerId}`);
        }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
      />
    </>
  );
};

export default SpeakerList;
