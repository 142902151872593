import { TableInfo } from "../types/models/TableInfo";
import TableInfoActionTypes from "../types/actions/TableInfoAction";

const tableInfoDefaultState: TableInfo = {
  name: "",
  pageSize: 10,
  pageNum: 0,
  isLoading: true,
};

const tableInfoReducer = (
  state = tableInfoDefaultState,
  action: TableInfoActionTypes
): TableInfo => {
  switch (action.type) {
    case "SET_TABLE_INFO":
      return action.tableInfo;
    case "SET_TABLE_LOADING":
      return {
        ...state,
        isLoading: action.loading,
      };
    default:
      return state;
  }
};

export default tableInfoReducer;
