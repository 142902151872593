import Mail from "../types/models/Mail";
import MailActionTypes from "../types/actions/MailAction";
import { MailStatus } from "../components/SubEvent/InsideMenu/Mail/MailRequest/RequestDashboard";

const mailDefaultState: Mail = {
  mail: undefined,
  mails: {
    content:[],
    pageable: {},
    last: false,
    totalElements: 0,
    totalPages: 0,
    size: 1,
    number: 0,
    sort: {
        sorted: false,
        unsorted: true,
        empty: true
    },
    numberOfElements: 1,
    first: true,
    empty: false
  },
  systemMails: {
    content:[],
    pageable: {},
    last: false,
    totalElements: 0,
    totalPages: 0,
    size: 1,
    number: 0,
    sort: {
        sorted: false,
        unsorted: true,
        empty: true
    },
    numberOfElements: 1,
    first: true,
    empty: false
  },
  mailTargets: [],
  sendGridMails: { content: [], page: 0 },
  setting: {
    id: 0,
    mode: "",
    senderName: "",
    senderMailAddress: "",
    mailRegistState: "UNREGISTERED",
  },
};

const mails = (state = mailDefaultState, action: MailActionTypes): Mail => {
  switch (action.type) {
    case "CREATE_MAIL":
    case "GET_MAIL":
      return {...state, mail: action.mail };
    case "GET_MAILS":
      return {
        ...state,
        mails: action.mails,
      };
      case "GET_SYSTEM_MAILS":
        return {
          ...state,
          systemMails: action.systemMails,
        };
    case "GET_MAIL_TARGETS":
      return {
        ...state,
        mailTargets: action.mailTargets,
      };
    case "GET_SENDGRID_MAILS":
      return {
        ...state,
        sendGridMails: action.sendGridMails,
      }
    case "GET_MAIL_SETTING":
    case "SAVE_MAIL_SETTING":
    case "UPDATE_MAIL_SETTING":
      return {
        ...state,
        setting: action.setting
      }
    case "REGIST_MAIL_ADDRESS":
    case "UNREGIST_MAIL_ADDRESS":
    case "VERIFY_MAIL_ADDRESS":
      return {
        ...state,
        setting: {
          ...state.setting,
          senderMailAddress: action.registState.senderMailAddress,
          mailRegistState: action.registState.mailRegistState
        }
      }
    case "CHANGE_SETTING_MODE":
      return {
        ...state,
        setting: {
          ...state.setting,
          mode: action.mode
        }
      }
    case "CREATE_RESEND_USERS":
      return {
        ...state,
        mailResend: {
          targetUsers: action.users
        }
      }
    case "DELETE_RESEND_USERS":
      return {
        ...state,
        mailResend: {
          targetUsers: []
        }
      }
    case "CREATE_REQUEST_INFO":
      return {
        ...state,
        mailRequestInfo: action.requestInfo
      }
    case "DELETE_REQUEST_INFO":
      return {
        ...state,
        mailRequestInfo: undefined
      }
    default:
      return state;
  }
};

export default mails;
