import { AppActions } from "../store";
import { getRequest } from "../utils/utils";
import { CounselInfo } from "../types/models/Counsel";
import axios from "axios";

export const getCounselsAction = (counsels: CounselInfo[]): AppActions => ({
  type: "GET_COUNSELS",
  counsels,
});

export const resetCounsels = (): AppActions => ({
  type: "RESET_COUNSELS",
});

export const resetCounselsExcel = (): AppActions => ({
  type: "RESET_COUNSELS_EXCEL",
});

export const getCounselsExcelAction = (
  counselsExcel: CounselInfo[]
): AppActions => ({
  type: "GET_COUNSELS_EXCEL",
  counselsExcel,
});

export const getCounsels = (subEventId: number) => {
  return getRequest(
    null,
    "/api/meetings/subEventSurveyList",
    getCounselsAction,
    { subEventId: subEventId }
  );
};

export const getCounselsExcel = (subEventId: number) => {
  return getRequest(
    null,
    "/api/meetings/subEventSurveyList",
    getCounselsExcelAction,
    { subEventId: subEventId }
  );
};

export const getCounselsAnswers = async (subEventId: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    params: { subEventId: subEventId },
    data: {},
  };

  try {
    const res = await axios.get("/api/meetings/subEventSurveyList", config);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};
