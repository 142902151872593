import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getSpeaker } from "../../../../actions/speaker";
import { AppState } from "../../../../store";

// types
import SubHeadingTypo from "../../../Typhography/SubHeadingTypo";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import { TypographyProps } from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// custom ui comp
import OutlinedTxtField from "../../../Inputs/OutlinedTxtField";
import ShowFile from "../../../Common/ShowFile";

import styled from "styled-components";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const SpeakerDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const param: { secondId: string } = useParams();
  const speaker = useSelector((state: AppState) => state.speakers.speaker);

  useEffect(() => {
    dispatch(getSpeaker(param.secondId));
  }, []);

  return speaker !== undefined && speaker.id !== undefined ? (
    <Grid container>
      <Grid item>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
      </Grid>
      <Grid item style={{ padding: "0 0 0 64px", width: "100%" }}>
        <SubTitle>이름</SubTitle>
        <OutlinedTxtField value={speaker.name} fullWidth disabled />

        <SubTitle>소속</SubTitle>
        <OutlinedTxtField value={speaker.belongs} fullWidth disabled />

        <SubTitle>직함</SubTitle>
        <OutlinedTxtField value={speaker.title} fullWidth disabled />

        <SubTitle>휴대번호</SubTitle>
        <OutlinedTxtField value={speaker.mobileNumber} fullWidth disabled />

        <SubTitle>이메일</SubTitle>
        <OutlinedTxtField value={speaker.email} fullWidth disabled />

        <SubTitle>소개</SubTitle>
        <Grid
          style={{
            border: "1px solid #ccc",
            padding: "10.5px 14px",
            overflow: "scroll",
            maxHeight: "500px",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: speaker.introduce }} />
        </Grid>

        <SubTitle>사진</SubTitle>
        <ShowFile fileId={speaker.photo} />
      </Grid>
    </Grid>
  ) : null;
};

export default SpeakerDetail;
