import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import PxButton from "../../Buttons/PxButton";
import Body3Typo from "../../Typhography/Body3Typo";
import Estimate, { Amount, Item, PexpoAccount } from "./Estimate";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Body1Typo from "../../Typhography/Body1Typo";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { modifyPayment } from "../../../actions/subEvents";
import { newAlert } from "../../../actions/alerts";
import { useTranslation } from "react-i18next";

export default () => {
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const history = useHistory();
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const [termAgree, setTermAgree] = useState<boolean>(false);
  useEffect(() => {
    if (subEvent?.payment === "Y") history.goBack();
  }, [subEvent]);

  const submitBtn = async () => {
    if (!subEvent) return false;
    else if (!subEvent.id) return false;
    else if (!termAgree) {
      dispatch(newAlert("이용요금 납부조건 및 순서에 동의해주세요", subEvent?.id));
      return false;
    }

    const result: any = await dispatch(modifyPayment({ id: parseFloat(subEvent.id), type: "W" }));
    if (result) history.replace(`/event/detail/${subEvent.mainEvent?.id}`);
  };
  return (
    <Estimate title="유료플랜 전환" subEvent={subEvent!}>
      <Item
        title="상담회 보증금"
        subTitle="입금 확인시, 승인제한 기능 해제"
        subTitleColor="purple700"
        justify="flex-start"
        fontWeight={500}
        input={
          <Box width="100%" marginTop="20px">
            <Amount amount={500000} />
          </Box>
        }
        caption={<Body3Typo>※ 세금계산서와 최종 견적서는 이벤트 종료 후 발송해드립니다.</Body3Typo>}
      />
      <PxButton
        fullWidth
        backgroundcolor="#f1f2f5"
        txtColor="black0.6"
        margin="10px 0px 32px 0px "
        onClick={() => {
          history.push(`/estimate/subEvent/${subEvent?.id}/consulting`);
        }}
      >
        {t("eventDetail.estimatedQuote")}
      </PxButton>

      <PexpoAccount />
      <FormControlLabel
        style={{ marginTop: "-12px" }}
        control={
          <Checkbox
            icon={<CheckCircleIcon />}
            checkedIcon={<CheckCircleIcon style={{ color: "#512da8" }} />}
            name="term"
            onChange={() => setTermAgree(!termAgree)}
          />
        }
        label={
          <Body1Typo
            txtcolor={termAgree ? "purple700" : "black0.6"}
            onClick={() => {
              // alert("이용약관");
            }}
          >
            이용요금 납부조건 및 순서에 동의
          </Body1Typo>
        }
      />
      <Box display="flex" marginTop="58px">
        <PxButton
          fullWidth
          backgroundcolor="transparent"
          txtColor="bluePurple"
          onClick={() => history.goBack()}
        >
          취소
        </PxButton>
        <PxButton
          boxshadow={false}
          fullWidth
          backgroundcolor="#512da8"
          noBorderRadius={true}
          txtColor="white"
          onClick={submitBtn}
        >
          신청하기
        </PxButton>
      </Box>
    </Estimate>
  );
};
