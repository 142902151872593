import React from "react";
import styled from "styled-components";

// material ui comp
import Typography, { TypographyProps } from "@material-ui/core/Typography";

interface HeadlineTypoProps {
  txtcolor?: "rgba(0,0,0,0.87)" | "purple";
}

type Prop = HeadlineTypoProps & TypographyProps;

const HeadlineTypo: React.FC<Prop> = styled((props: Prop) => {
  const { txtcolor, ...rest } = props;
  return <Typography {...rest} />;
})`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -1px;
  color: ${(props) => {
    if (props.txtcolor === "purple") {
      return "rgba(95, 75, 139, 1.0)";
    }
    return props.txtcolor;
  }};
`;

HeadlineTypo.defaultProps = {
  txtcolor: "rgba(0,0,0,0.87)",
};

export default HeadlineTypo;
