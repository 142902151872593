import React, { ReactNode, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import styled from "styled-components";

// *************************************************************************************************//
// ********** 커스텀 드랍다운 메뉴 사용 시 children 값에 Material MenuItem component 를 넣어주면 됨 ********** //
// *************************************************************************************************//

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

interface PxMenuProps {
  children: ReactNode;
  label: string | undefined;
  boxShadow?: boolean;
}

const MeettingStatusBtn = styled.button<{ anchorEl: null | HTMLElement; boxShadow?: boolean }>`
  background-color: transparent;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  padding-left: 8px;
  padding-right: 25px;
  margin-left: 8px;
  text-align: inherit;
  transform: translate(0%, 10%);
  color: rgba(0, 0, 0, 0.87);
  box-shadow: ${(props) =>
    props.boxShadow
      ? "0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);"
      : "0"};
  &:focus {
    outline: none;
    background-color: transparent;
  }
  &::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    top: calc(50% - 5px);
    right: 0px;
    border-top: 5px solid rgba(0, 0, 0, 0.87);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
`;

const PxDropDown = styled((props: PxMenuProps) => {
  const { label, children, boxShadow } = props;

  const handleClose = () => {
    setMeetingAnchorEl(null);
  };

  useEffect(() => {
    handleClose();
  }, [label]);
  const [meetingAnchorEl, setMeetingAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMeetingClick = (event: React.MouseEvent<HTMLElement>) => {
    setMeetingAnchorEl(event.currentTarget);
  };

  return (
    <>
      <MeettingStatusBtn
        boxShadow={boxShadow}
        anchorEl={meetingAnchorEl}
        onClick={handleMeetingClick}
      >
        {label}
      </MeettingStatusBtn>
      <StyledMenu
        id="Pexpo-login-Menu"
        anchorEl={meetingAnchorEl}
        open={Boolean(meetingAnchorEl)}
        onClose={handleClose}
      >
        {children}
      </StyledMenu>
    </>
  );
})``;

export default PxDropDown;
