import React, {useEffect, useState} from "react";
import {NewMeetingDataType, VideoCallParticipantInfo} from "./Monitoring";
import {makeStyles} from "@material-ui/core/styles";
import PxGridContainer from "../../../Grid/PxGridContainer";
import PxGridItem from "../../../Grid/PxGridItem";
import MeetingCard from "./MeetingCard";
import {useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {parallaxCalculation} from "../../../../utils/momentTz";
import styled from "styled-components";
import moment from "moment";
import {MeetingTimeInfo} from "../../../../types/models/SubEvent";
import Caption2Typo from "../../../Typhography/Caption2Typo";
import HeadlineTypo from "../../../Typhography/HeadlineTypo";
import {useTranslation} from "react-i18next";

const groupBy = require("json-groupby");

interface BuyerMonitoringProps {
  meetings: NewMeetingDataType[];
  buyerParticipants: VideoCallParticipantInfo[];
  selectDate: {showDate: string; date: string};
  selectTime: string;
  searchWord: string;
  meetingLoading: boolean;
  isConnetion: boolean;
}

const BuyerMonitoring = ({meetings, buyerParticipants, selectDate, selectTime, searchWord, isConnetion, meetingLoading}: BuyerMonitoringProps) => {
  const classes = useStyles();
  const [t] = useTranslation("lang", {useSuspense: false});
  const [buyerMeetingData, setBuyerMeetingData] = useState<{
    [companyName: string]: NewMeetingDataType[];
  } | null>();
  // const now = moment();
  const [meetingList, setMeetingList] = useState<Array<NewMeetingDataType> | null>();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const {country, countries} = useSelector((state: AppState) => state.countries!);
  const [participants, setParticipants] = useState<{
    [roomId: string]: VideoCallParticipantInfo[];
  }>();

  // 전체 셀러 참가자를 roomId 값으로 group -> 미팅카드를 뿌려줄때, 해당 미팅카드에 속한 참가자들과 같이 뿌려주기 위함
  useEffect(() => {
    if (buyerParticipants.length === 0) {
      setParticipants(undefined);
    } else {
      const groupedBuyerParticipants = groupBy(buyerParticipants, ["roomId"]);
      setParticipants(groupedBuyerParticipants);
    }
  }, [buyerParticipants]);

  useEffect(() => {
    if (meetings.length !== 0) {
      if (subEvent !== undefined && subEvent.meetingData !== "") {
        const countryCode = subEvent.mainEvent?.countryCode;
        const meetingDataWithTimeZone = meetings.map(meeting => {
          // 국가에 맞는 시작시간 조회
          const countryStartDate = parallaxCalculation(`${meeting.date}T${meeting.startTime}`, countries![countryCode! as string] as any, countries![country!.countryCode!] as any, "YYYY-MM-DD HH:mm");
          // 국가에 맞는 종료시간 조회
          const countryEndDate = parallaxCalculation(`${meeting.date}T${meeting.endTime}`, countries![countryCode! as string] as any, countries![country!.countryCode!] as any, "YYYY-MM-DD HH:mm");

          const splitStartDate = countryStartDate.split(" ");
          const splitEndDate = countryEndDate.split(" ");

          return Object.assign(meeting, {
            showMeetingStartTime: splitStartDate[1],
            showMeetingEndTime: splitEndDate[1],
            showMeetingStartDate: splitStartDate[0],
            showMeetingEndDate: splitEndDate[0],
          });
        });

        // 대소문자 구분없이 검색유저 필터링
        const filterCompanyBySearchWord = (
          data: NewMeetingDataType & {
            showMeetingStartTime: any;
            showMeetingEndTime: any;
            showMeetingStartDate: any;
            showMeetingEndDate: any;
          }
        ) => {
          if (data.meetingApplicant?.name?.toLocaleLowerCase().includes(searchWord.toLocaleLowerCase()) || data.meetingAcceptor?.name?.toLocaleLowerCase().includes(searchWord.toLocaleLowerCase())) {
            return true;
          } else if (data.meetingApplicant?.name?.toUpperCase().includes(searchWord.toUpperCase()) || data.meetingApplicant?.name?.toLocaleLowerCase().includes(searchWord.toLocaleLowerCase())) {
            return true;
          } else {
            return false;
          }
        };
        const groupByCompanyName = groupBy(meetingDataWithTimeZone.filter(filterCompanyBySearchWord), ["showMeetingStartDate"]);

        const date = moment(selectDate.showDate).format("YYYY-MM-DD");
        if (groupByCompanyName[`${date}`] && groupByCompanyName[`${date}`]) {
          console.log(`showData`);
          setBuyerMeetingData(groupBy(groupByCompanyName[`${date}`], ["showMeetingStartTime"]));
          setMeetingList(groupByCompanyName[`${date}`]!.filter((data: MeetingTimeInfo) => data.startTime.match(selectTime)));
        } else {
          setBuyerMeetingData(null);
          setMeetingList(null);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetings, country, selectDate, selectTime, searchWord]);

  return (
    <PxGridContainer direction="row">
      {buyerMeetingData && buyerMeetingData !== undefined && meetingList!.length > 0 ? (
        <>
          {/* 선택한 시간 보기 */}
          {selectTime && selectTime !== "" ? (
            <Container>
              <Content>
                {meetingList!.map(meetingData => (
                  <PxGridItem className={classes.gridItem} key={meetingData.id}>
                    <div className={classes.meetingCardDiv}>
                      <MeetingCard meetingData={meetingData} participants={participants === undefined ? [] : participants[meetingData.id!]} isConnetion={isConnetion} />
                    </div>
                  </PxGridItem>
                ))}
              </Content>
            </Container>
          ) : (
            /* 전체시간 보기 */
            React.Children.toArray(
              Object.entries(buyerMeetingData!)
                .sort((a, b) => {
                  return moment(a[1][0].meetingStartDate).isAfter(b[1][0].meetingStartDate) ? 1 : -1;
                })
                .map(([key, value], index) => (
                  <Container>
                    <Caption2Typo>{`${value[0].showMeetingStartTime} ~ ${value[0].showMeetingEndTime}`}</Caption2Typo>
                    <Content>
                      {value.map(meetingData => (
                        <div className={classes.meetingCardDiv}>
                          <MeetingCard isConnetion={isConnetion} meetingData={meetingData} participants={participants === undefined ? [] : participants[meetingData.id!]} />
                        </div>
                      ))}
                    </Content>
                  </Container>
                ))
            )
          )}
        </>
      ) : (
        !meetingLoading && (
          <Container>
            <div style={{textAlign: "center"}}>
              <HeadlineTypo style={{fontWeight: "normal"}}>{t("monitoring.noMeetings")}</HeadlineTypo>
            </div>
          </Container>
        )
      )}
    </PxGridContainer>
  );
};

export default BuyerMonitoring;

const useStyles = makeStyles(theme => ({
  gridItem: {
    paddingTop: "0px !important",
  },
  meetingCardDiv: {
    marginTop: 8,
  },
}));

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const Content = styled.div`
  width: 100%;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 240px));
`;
