import React, { ReactNode } from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu, { MenuProps } from "@material-ui/core/Menu";

// *************************************************************************************************//
// ********** 커스텀 드랍다운 메뉴 사용 시 children 값에 Material MenuItem component 를 넣어주면 됨 ********** //
// *************************************************************************************************//

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

interface PxMenuProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  children: ReactNode;
  anchorVertical?: number | "top" | "center" | "bottom";
  anchorhorizontal?: number | "center" | "left" | "right";
  transformVertical?: number | "top" | "center" | "bottom";
  transformHorizontal?: number | "center" | "left" | "right";
  marginBottom?: number;
  className?: any;
}

const PxMenu = ({
  anchorEl,
  setAnchorEl,
  children,
  anchorVertical,
  anchorhorizontal,
  transformVertical,
  transformHorizontal,
  marginBottom,
  className,
}: PxMenuProps) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledMenu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: anchorVertical ?? "bottom",
        horizontal: anchorhorizontal ?? "right",
      }}
      transformOrigin={{
        vertical: transformVertical ?? "top",
        horizontal: transformHorizontal ?? "center",
      }}
      style={{
        transform: marginBottom ? `translateY(${marginBottom}px)` : "none",
      }}
      classes={{
        paper: className ?? {},
      }}
    >
      {children}
    </StyledMenu>
  );
};

export default PxMenu;
