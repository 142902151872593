import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import Meeting, { MeetingInfo } from "../../types/models/Meeting";
import { parallaxCalculation } from "../../utils/momentTz";
import Today from "./Today";
const groupBy = require("json-groupby");

/*****************

요일 번역이 되어야함
캘린더에서 해당일 호버시 주간이 백그라운드 색들어가서 주간이 선택되는 느낌 주기
미팅잡힌날에는 해당일에 빨간점 표시
요청이나 대기인날은 노락색 표시
오늘 버튼 
다음달 버튼 이전달 버튼
나라별 시간들 맞춰줘야함 
******************/

export default ({
  date,
  setDate,
  isDay,
  meetings,
}: {
  date: moment.Moment;
  setDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  isDay?: boolean;
  meetings?: MeetingInfo[];
}) => {
  const [calendarDate, setCalendarDate] = useState(date);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [t] = useTranslation("lang", { useSuspense: false });
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const { country, countries } = useSelector((state: AppState) => state.countries);
  const [localDate, setLocalDate] = useState<{ startDate: string; endDate: string }>({
    startDate: "",
    endDate: "",
  }); //서브이벤트 시작날 , 끝나는날
  const updateDate = useCallback(
    (
      //버튼 날짜 변경
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setDate(
        date.clone().add(event.currentTarget.name === "nextWeek" ? 1 : -1, isDay ? "day" : "weeks")
      );
    },
    [isDay, date]
  );
  const updateCalendarDate = (
    //달력 날짜 변경
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setCalendarDate(
      calendarDate.clone().add(event.currentTarget.name === "nextWeek" ? 1 : -1, "months")
    );
  };

  useEffect(() => {
    if (
      calendarDate.isBetween(localDate.startDate, localDate.endDate, undefined, "[]") ||
      !localDate.startDate
    )
      return;
    setCalendarDate(moment(localDate.startDate));
  }, [localDate]);

  useEffect(() => {
    if (subEvent) {
      const startDate = parallaxCalculation(
        subEvent.subEventStartDate,
        "",
        countries![country!.countryCode!] as any,
        "YYYY-MM-DD"
      );
      const endDate = parallaxCalculation(
        subEvent.subEventEndDate,
        "",
        countries![country!.countryCode!] as any,
        "YYYY-MM-DD"
      );
      setLocalDate({
        startDate,
        endDate,
      });
    }
  }, [subEvent]);

  const calendarGenerator = useCallback(
    (date: moment.Moment, meetings: Meeting["meetings"] = []) => {
      if (subEvent) {
        const meetingsDate = groupBy(
          meetings.map((d) => {
            return {
              ...d,
              date: parallaxCalculation(
                `${d.date}T${d.startTime}`,
                "",
                countries![country!.countryCode!] as any,
                "YYYY-MM-DD"
              ),
            };
          }),
          ["date"]
        );
        const countryCode = subEvent!.mainEvent?.countryCode;
        const countMinute =
          subEvent.matchNumPerDay! * (subEvent.matchReqTime! + subEvent.matchBreakTime!) -
          subEvent.matchBreakTime!;
        // 미팅시작 기준 날짜
        const defaultDate = `${subEvent!.subEventEndDate.split("T")[0]}T${
          subEvent.matchStartTime
        }:00`;
        //미팅 마지막 시간 계산
        const endTimeCalculation = moment(defaultDate).add(countMinute, "m");

        const subEventStartDate = parallaxCalculation(
          `${subEvent!.subEventStartDate.split("T")[0]}T${subEvent.matchStartTime}:00`,
          countries![countryCode! as string] as any,
          countries![country!.countryCode!] as any,
          "YYYY-MM-DD"
        );

        // 국가 시간 변경시 마지막 미팅시간이 다음날로 넘어갈 경우가 있음
        // 마지막 미팅시간 구해서 계산해줘야함
        const subEventEndDate = parallaxCalculation(
          endTimeCalculation.format("YYYY-MM-DDTHH:mm"),
          countries![countryCode! as string] as any,
          countries![country!.countryCode!] as any,
          "YYYY-MM-DD"
        );

        // startOf('month') : 이번 달의 첫번 째 날로 설정 set to the first of this month, 12:00 am
        // week() : Week of Year. 이번 년도의 몇번째 주인가? => 1월 1일 이면 1, 12월 31일이면 53

        const startWeek = date.clone().startOf("month").week();

        // endOf('month').week() : 이번 달의 마지막 날로 설정 한 후 그것이 이번 년도의 몇번째 주인지 체크
        // 만약 이번 해의 첫번째 주(1월 1일이 속한 주)라면 53으로 세팅, 아니라면 그대로 유지
        // 이런 작업의 이유는 마지막 주는 53 이고 첫번째주는 1이기 때문에
        const itemClick = (selectDate: moment.Moment) => {
          setDate(selectDate.clone());
          setCalendarDate(selectDate.clone());
          setCalendarOpen(false);
        };
        const endWeek =
          date.clone().endOf("month").week() === 1 ? 53 : date.clone().endOf("month").week();

        let calendar = [];
        calendar.push(
          <Row currentDate={false} key={0}>
            {[
              t("calendar.SUN"),
              t("calendar.MON"),
              t("calendar.TUE"),
              t("calendar.WED"),
              t("calendar.THU"),
              t("calendar.FRI"),
              t("calendar.SAT"),
            ].map((w, i) => {
              return (
                <Item key={i}>
                  <span>{w}</span>
                </Item>
              );
            })}
          </Row>
        );
        for (let week = startWeek; week <= endWeek; week++) {
          const startOfWeek = date.clone().week(week).startOf("week");
          const endOfWeek = date.clone().week(week).endOf("week").add(-1, "day"); // 일요일 부터 토요일까지 한주로 달력에 표시해주기 때문에 -1
          calendar.push(
            <Row
              currentDate={
                startOfWeek.isBetween(subEventStartDate, subEventEndDate, undefined, "[]") ||
                endOfWeek.isBetween(subEventStartDate, subEventEndDate, undefined, "[]")
              }
              key={week}
              isDay={isDay}
            >
              {Array(7)
                .fill(0)
                .map((n, i) => {
                  // 오늘 => 주어진 주의 시작 => n + i일 만큼 더해서 각 주의 '일'을 표기한다.
                  let current = date
                    .clone()
                    .week(week)
                    .startOf("week")
                    .add(n + i, "day");

                  const currentDate = current.isBetween(
                    subEventStartDate,
                    subEventEndDate,
                    undefined,
                    "[]"
                  ); // 서브 이벤트 기간에 포함 확인
                  let color = currentDate ? "#4b4c4e" : "#a9aaad";

                  const agree = meetingsDate[current.format("YYYY-MM-DD")]?.some(
                    (m: Meeting["meeting"]) => m?.status === "agree"
                  );
                  const wait = meetingsDate[current.format("YYYY-MM-DD")]?.some(
                    (m: Meeting["meeting"]) => m?.status === "waiting"
                  );
                  return (
                    <Item
                      color={color}
                      key={i}
                      onClick={() => currentDate && itemClick(current)}
                      isDay={isDay}
                      currentDate={currentDate}
                    >
                      <span>{current.format("D")}</span>
                      <ItemFooter>
                        {agree && <Status />}
                        {wait && <Status status="wait" />}
                      </ItemFooter>
                    </Item>
                  );
                })}
            </Row>
          );
        }
        return calendar;
      }
    },
    [moment, country, countries, parallaxCalculation, subEvent, isDay]
  );

  return (
    <Container>
      <MonthMoveBtn
        disabled={date.isSameOrBefore(localDate.startDate)}
        name="prevWeek"
        onClick={updateDate}
      >
        <ChevronLeftIcon />
      </MonthMoveBtn>
      <CalenderBtn style={{ fontSize: 18 }} onClick={() => setCalendarOpen(!calendarOpen)}>
        {date.format("YYYY. MM. DD")}
      </CalenderBtn>
      <MonthMoveBtn
        disabled={date.isSameOrAfter(localDate.endDate)}
        name="nextWeek"
        onClick={updateDate}
      >
        <ChevronRightIcon />
      </MonthMoveBtn>
      {calendarOpen ? (
        <Dialog
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setCalendarOpen(!calendarOpen);
          }}
        >
          <Calendar onClick={(e) => e.stopPropagation()}>
            <Box justifyContent="center" fontSize="14px" display="flex" position="relative">
              <MonthMoveBtn name="prevWeek" onClick={updateCalendarDate}>
                <ChevronLeftIcon />
              </MonthMoveBtn>
              <CalenderBtn>{calendarDate.format("YYYY. MM")}</CalenderBtn>
              <MonthMoveBtn name="nextWeek" onClick={updateCalendarDate}>
                <ChevronRightIcon />
              </MonthMoveBtn>
              <Today
                startDate={localDate.startDate}
                endDate={localDate.endDate}
                setCalendarDate={setCalendarDate}
                setDate={setDate}
                setCalendarOpen={setCalendarOpen}
              />
            </Box>
            {subEvent && calendarGenerator(calendarDate, meetings)}
          </Calendar>
        </Dialog>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: inline;
`;

const CalenderBtn = styled.button`
  background-color: transparent;
  border: none;
  box-shadow: none;
  &:hover {
    border-radius: 10px;
    outline: none;
    background-color: #e9eaed;
  }
  &:focus {
    outline: none;
  }
`;

const MonthMoveBtn = styled.button`
  background-color: transparent;
  border: none;
  box-shadow: none;
  &:hover {
    border-radius: 20%;
    outline: none;
    background-color: #e9eaed;
  }
  &:focus {
    outline: none;
  }
`;

const Dialog = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Calendar = styled.div`
  display: grid;
  background-color: white;
  min-width: 280px;
  gap: 10px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 24px 38px 3px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 20px;
`;
const Row = styled.div<{ currentDate: boolean; isDay?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  grid-auto-flow: column;
  ${(props) =>
    props.currentDate &&
    !props.isDay &&
    css`
      cursor: pointer;
      &:not(:nth-child(2)) {
        &:hover {
          border-radius: 8px;
          background-color: #c7c8cb;
        }
      }
    `}/* cursor: pointer; */
  /* &:not(:nth-child(2)) {
    &:hover {
      border-radius: 8px;
      background-color: #c7c8cb;
    }
  } */
  /* &:hover {
    border-radius: 8px;
    background-color: #c7c8cb;
  } */
`;
const Item = styled.div<{ color?: string; currentDate?: boolean; isDay?: boolean }>`
  color: ${(props) => (props.color ? props.color : "#4b4c4e")};
  text-align: center;
  ${(props) =>
    props.currentDate &&
    props.isDay &&
    css`
      cursor: pointer;
      &:hover {
        background-color: #c7c8cb;
      }
    `}
`;

const ItemFooter = styled.div`
  display: flex;
  margin-top: -2px;
  height: 6px;
  justify-content: center;
`;

const Status = styled.div<{ status?: string }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.status === "wait" ? "rgb(252, 178, 30)" : "rgb(69, 39, 160)"};
  &:not(:first-child) {
    margin-left: 5px;
  }
`;
