import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import MaterialTable, {Column, MaterialTableProps} from "material-table";

// Icons
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {AppState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useRouteMatch} from "react-router-dom";
import {setTableInfoAction, setTableInfoLoadingAction} from "../../actions/tableInfo";
import {CircularProgress, createMuiTheme, Divider} from "@material-ui/core";
import {theme} from "../PxBreakpoint/PxBreakpoint";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Pagination from "../Pagination/Pagination";
import {debounce, size} from "lodash";
import Box from "@material-ui/core/Box";
import CancelIcon from "@material-ui/icons/Cancel";
import NoResultPage from "../NoResult/NoResultPage";
import SmsStatistics from "../SubEvent/InsideMenu/Sms/SmsStatistics";

/************

테이블 커스텀 페이지네이션 사용시 
해당 페이지에서 데이터 로드 안해줘도 테이블 페이지에서 데이터 로드해줌
getAction 부분 return 값으로 res.data로 넣어줘야함 

*************/

export const tableIcons = {
  Add: forwardRef<any>((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef<any>((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef<any>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<any>((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef<any>((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef<any>((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef<any>((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef<any>((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef<any>((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef<any>((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef<any>((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef<any>((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef<any>((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef<any>((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef<any>((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef<any>((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef<any>((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const StyledDiv = styled.div<{footer: boolean}>`
  & button:focus {
    outline: none !important;
  }
  & .MuiPaper-elevation2 {
    box-shadow: "none !important";
  }
  width: 100%;
  & th.MuiTableCell-root {
    width: 0 !important;
  }
  & .MuiTablePagination-toolbar {
    display: ${props => (props.footer ? "" : "none")};
  }
  & table > tfoot > tr > td > div {
    display: ${props => (props.footer ? "" : "none")};
  }
`;

export const customTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation2: {
        boxShadow: "none",
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: 0,
        [theme.breakpoints.down("lg")]: {
          paddingLeft: 0,
        },
        [theme.breakpoints.down(600)]: {
          paddingLeft: 0,
        },
        [theme.breakpoints.up(600)]: {
          paddingLeft: 0,
        },
        [theme.breakpoints.down("md")]: {
          paddingLeft: 0,
        },
        [theme.breakpoints.down("sm")]: {
          paddingLeft: 0,
        },
        [theme.breakpoints.down("xs")]: {
          paddingLeft: 0,
        },
      },
    },
  },
});

interface PxProps {
  getAction?: any;
  totalPage?: number;
  totalElements?: number;
  actionData?: any;
  initPageSize?: number;
  dialog?: boolean; // dialog에서 PxTable을 사용할때 true값으로 사용
  currentPage?: number;
}

type PxTableComponent<T extends object> = React.FC<MaterialTableProps<T> & PxProps>;

const PxTable = <T extends object>(props: MaterialTableProps<T> & PxProps) => {
  const {columns, data, actions, options, getAction, totalPage, totalElements, actionData, title, initPageSize, dialog, currentPage, ...rest} = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const tableInfo = useSelector((state: AppState) => state.tableInfo); // 리덕스에 저장한 테이블 정보
  const [page, setPage] = useState<number>(currentPage!); // initial page number
  const [pageSize, setPageSize] = useState<number>(initPageSize ?? 10); // intial page Size
  const [selectType, setSelectType] = useState<string>();
  const [t, i18n] = useTranslation("lang", {useSuspense: false});
  const [tableColumns, setTableColumns] = useState<Column<T>[]>(columns);
  const [search, setSearch] = useState<string>("");
  const [selectRow, setSelectRow] = useState<boolean>(false);
  const tableRef = useRef<any>(null);

  let fromPrevious = false;

  // 테이블의 default setting (pageSize, pageNumber, default sorting)을 설정해줌
  useEffect(() => {
    if (tableInfo.name === location.pathname) {
      setPage(tableInfo.pageNum);
      // if (tableInfo.pageSize == pageSize) setPageSize(tableInfo.pageSize);
      fromPrevious = true;

      // set columns default sorting
      if (tableInfo.sorting !== undefined) {
        const newTableColumns = [...columns];
        newTableColumns[tableInfo.sorting.coulumnId].defaultSort = tableInfo.sorting.columnDirection;
        setTableColumns(newTableColumns);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableInfo, location.pathname, pageSize]);

  // 전체 테이블의 로딩 state를 redux tableinfo 에서 관리
  // 테이블 데이터를 불러오는 곳에서 불러오기가 끝나면 tableinfo redux state의 loading state를 false로 꼭 해줘야함!!
  // 테이블이 unmount 될 때 loading state를 true로 해줌 (다른 테이블에서 loading screen을 위함)
  useEffect(() => {
    return () => {
      // PxTable이 있는 화면에서 dialog로 PxTable을 또 띄우면 Loading상태가 꼬이는 버그해결
      if (dialog === undefined) {
        dispatch(setTableInfoLoadingAction(true));
      }
    };
  }, [dispatch]);

  useEffect(() => {
    // page size 변경시 테이블 row수 변경
    if (tableRef && tableRef.current) {
      tableRef.current.dataManager.changePageSize(pageSize);
    }
  }, [pageSize]);

  const onChangeData = useCallback(
    async (page: number, pageSize: number, search: string) => {
      // 검색할때 , 페이지 사이즈 변경시 page = 0
      if (getAction) {
        dispatch(setTableInfoLoadingAction(true));
        let result;
        if (tableInfo.name !== location.pathname) {
          if (options?.search) {
            // actionData = subEventId, type(seller,buyer)같은 정보
            result = actionData ? await dispatch(getAction(actionData, page, pageSize, search)) : await dispatch(getAction(page, pageSize, search));
          } else {
            result = actionData ? await dispatch(getAction(actionData, page, pageSize)) : await dispatch(getAction(page, pageSize));
          }
        } else {
          if (fromPrevious) {
            setSearch(search);
            if (!tableInfo?.type) {
              result = actionData ? await dispatch(getAction(actionData, page, pageSize, search)) : await dispatch(getAction(page, pageSize, tableInfo.search));
            } else {
              result = actionData ? await dispatch(getAction({...actionData, type: tableInfo.type}, page, pageSize, search)) : await dispatch(getAction(page, pageSize, tableInfo.search));
            }
          } else {
            result = actionData ? await dispatch(getAction(actionData, page, pageSize, search)) : await dispatch(getAction(page, pageSize, tableInfo.search));
          }
          // if ((actionData?.type && actionData.type.length !== 0) || (actionData?.type.length === 0 && tableInfo.type && tableInfo.type.length !== 0)) {
          //   setSearch(search);
          //   if (actionData?.type) {
          //     result = actionData ? await dispatch(getAction(actionData, page, pageSize, search)) : await dispatch(getAction(page, pageSize, tableInfo.search));
          //   } else {
          //     result = actionData ? await dispatch(getAction({...actionData, type: tableInfo.type}, page, pageSize, search)) : await dispatch(getAction(page, pageSize, tableInfo.search));
          //   }
          // } else {
          //   if (tableInfo?.search) {
          //     setSearch(tableInfo.search);
          //     result = actionData ? await dispatch(getAction(actionData, page, pageSize, tableInfo.search)) : await dispatch(getAction(page, pageSize, tableInfo.search));
          //   } else {
          //     result = actionData ? await dispatch(getAction(actionData, page, pageSize)) : await dispatch(getAction(page, pageSize));
          //   }
          // }
        }

        //result return 값으로 res.data 받아야 함
        (!result || result.content.length <= 0) && dispatch(setTableInfoLoadingAction(false));
      }

      actionData.type || tableInfo.type
        ? dispatch(
            setTableInfoAction({
              name: location.pathname,
              pageNum: page,
              pageSize: pageSize,
              search: search,
              type: `${fromPrevious ? tableInfo.type : actionData.type}`,
            })
          )
        : dispatch(
            setTableInfoAction({
              name: location.pathname,
              pageNum: page,
              pageSize: pageSize,
              search: search,
            })
          );
    },
    [actionData, getAction]
  );

  useEffect(() => {
    // delayedQuery(0, pageSize, search);
    // if (actionData) onChangeData(page, pageSize, search);
    delayedQuery.current = debounce(onChangeData, 1000);
    if (actionData) {
      // if (!actionData.pageSize) actionData.pageSize = 10;
      const pageNum = tableInfo.name !== location.pathname || (actionData.type && actionData.type !== tableInfo?.type) || (search && search !== tableInfo.search) ? 0 : tableInfo.pageNum;
      // const searchWord = tableInfo.search ? tableInfo.search : search;
      // const paramPageSize = tableInfo.name == location.pathname ? tableInfo.pageSize : actionData.pageSize;

      if (actionData.pageSize) {
        setPageSize(actionData.pageSize);
      }

      onChangeData(pageNum, pageSize, search);
      // onChangeData(pageNum, paramPageSize, searchWord);
    } else {
      return delayedQuery.current.cancel;
    }
  }, [actionData]);

  const delayedQuery = useRef(debounce(onChangeData, 1000));

  // useEffect(() => {
  //   delayedQuery.current = debounce(onChangeData, 1000);
  // }, [actionData]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    dispatch(setTableInfoLoadingAction(true));
    setSearch(searchValue);
    delayedQuery.current(0, pageSize, searchValue);
    return delayedQuery.current.cancel;
  };

  // table sorting order가 바뀔 경우 tableInfo.sorting redux state 변경
  const onOrderChage = (orderColumnId: number, orderDirection: "desc" | "asc") => {
    // sorting 상태가 초기화 될때의 orderColumnId 값이 -1로 넘어옴
    if (orderColumnId === -1) {
      dispatch(
        setTableInfoAction({
          name: location.pathname,
          pageNum: page,
          pageSize: pageSize,
          sorting: undefined,
        })
      );
    } else {
      dispatch(
        setTableInfoAction({
          name: location.pathname,
          pageNum: page,
          pageSize: pageSize,
          sorting: {
            coulumnId: orderColumnId,
            columnDirection: orderDirection,
          },
        })
      );
    }
  };

  const TitleRender = () => {
    const match = useRouteMatch(`/subEvent/:subEventId/sms`);
    if (match?.isExact) {
      return <SmsStatistics />;
    } else {
      return (
        <>
          {title}
          <Box />
        </>
      );
    }
  };

  // MaterialTable이 처음 랜더링 될 때만 option의 initialPage가 동작함
  return (
    <MuiThemeProvider theme={customTheme}>
      <StyledDiv footer={getAction && totalPage !== undefined ? false : true}>
        {getAction && options?.search && !selectRow ? (
          <TableHeader>
            {TitleRender()}
            <SearchBox>
              <SearchInput id="searchRef" value={search} onChange={onChange} placeholder="Search" />
              <CancelIcon
                style={{width: 18, cursor: "pointer"}}
                onClick={() => {
                  setSearch("");
                  onChangeData(0, pageSize, "");
                }}
              />
            </SearchBox>
          </TableHeader>
        ) : null}
        <MaterialTable
          tableRef={tableRef}
          icons={tableIcons}
          columns={tableColumns}
          data={tableInfo.isLoading ? [] : data}
          actions={actions}
          title={getAction && options?.search ? "" : title}
          // onChangePage={onChangePage}
          // onChangeRowsPerPage={onChangeRowsPerPage}
          // onOrderChange={onOrderChage}
          onSelectionChange={data => {
            if (data.length !== 0) {
              setSelectRow(true);
            } else {
              setSelectRow(false);
            }
          }}
          localization={{
            body: {
              emptyDataSourceMessage:
                tableInfo.isLoading === true ? (
                  <Box padding="200px">
                    <CircularProgress style={{color: "rgb(95, 75, 139)"}} />
                  </Box>
                ) : (
                  <NoResultPage />
                ),
            },
          }}
          options={{
            ...options,
            toolbar: getAction && options?.search ? selectRow : options?.search,
            paginationType: "normal",
            initialPage: page,
            pageSize: pageSize,
            pageSizeOptions: [5, 10, 20, 30, 40, 50, 80, 100],
          }}
          {...rest}
        />
        {getAction && totalPage ? (
          <>
            <Pagination
              page={page}
              totalPage={totalPage}
              onChange={(e, page) => {
                console.log(`page : `, page);
                onChangeData(page, pageSize, search);
              }}
              pageSize={pageSize}
              totalElements={totalElements}
              onChangeSize={e => {
                setPageSize(e.target.value as number);
                onChangeData(0, e.target.value as number, search);
              }}
            />
            <Divider style={{marginTop: 16}} />
          </>
        ) : null}
      </StyledDiv>
    </MuiThemeProvider>
  );
};

// const MemoizedPxTable: PxTableComponent<any> = React.memo(PxTable) as PxTableComponent<any>;

export default PxTable;

const TableHeader = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchBox = styled.div`
  width: 240px;
  padding: 7px 12px 9px 16px;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input`
  width: 100%;
  font-size: 14px;
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
  }
`;
