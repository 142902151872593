import {Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs} from "@material-ui/core";
import {TabPanel} from "@material-ui/lab";
import React, {useState} from "react";
import {CreateRegitUserRes, InvalidRegitUser, ResultDialogParam} from "../../../../../types/models/Registration";
import PxButton from "../../../../Buttons/PxButton";
import ButtonTypo from "../../../../Typhography/ButtonTypo";
import RegitInvalidTable from "../RegitInvalidTable";
import RegitSuccessTable from "../RegitSuccessTable";

type Props = {
  isOpen: boolean;
  resultData: ResultDialogParam;
  closeModal: () => void;
};

const CreateResultDialog: React.FC<Props> = ({isOpen, resultData, closeModal}) => {
  const [tabValue, setTabValue] = useState<string>("success");

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>등록 결과</DialogTitle>
        <DialogContent>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="등록 성공한 유저" value="success" />
            <Tab label="등록 실패한 유저" value="fail" />
          </Tabs>
          {tabValue === "success" && <RegitSuccessTable successUsers={resultData.successUsers} />}

          {tabValue === "fail" && <RegitInvalidTable invalidUsers={resultData.failUsers} />}
        </DialogContent>
        <DialogActions>
          <PxButton onClick={closeModal}>
            <ButtonTypo>닫기</ButtonTypo>
          </PxButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateResultDialog;
