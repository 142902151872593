import { Column, MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";
import PxTable from "../../../Table/PxTable";
import { makeStyles } from "@material-ui/core/styles";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";
import Box from "@material-ui/core/Box";
import { getApplicants } from "../../../../actions/applicants";
import { useHistory, useParams } from "react-router";
import { AppState } from "../../../../store";
import { useTranslation } from "react-i18next";
import { printLabel } from "../../../../actions/deskPrint";
import Alert from "../../../../types/models/Alert";
import { removeAlertAction, setAlertAction } from "../../../../actions/alerts";

const useStyles = makeStyles(() => ({
  tableToolbarRoot: {
    justifyContent: "center",
    "& fieldset": {
      borderRadius: 28,
      borderColor: "#e0e0e0",
    },
  },
  tableToolbarSpacer: {
    flex: "none",
  },
  tableSearchField: {
    minWidth: 420,
  },
}));

interface RowType {
  id: string;
  bussinessCardId: string;
  type: string;
  name: string;
  company: string;
  phoneNumber: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

interface SearchAndPrintTableProps {
  mainEventName: string;
}
const SearchAndPrintTable = ({ mainEventName }: SearchAndPrintTableProps) => {
  const classes = useStyles();
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const applicants = useSelector(
    (state: AppState) => state.applicants.applicants
  );
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const printer = useSelector((state: AppState) => state.printers.printer);

  const onClickApplicantPrint = (rowData: RowType) => {
    if (printer && subEvent) {
      printLabel({
        applicantId: Number(rowData.id),
        dispatch,
        eventName: mainEventName,
        companyName: rowData.company,
        position: rowData.type,
        name: rowData.name,
        type:
          rowData.type === "seller" ? subEvent.sgroupName : subEvent.bgroupName,
        ipAddress: printer.ipAddress,
        printerName: printer.name,
      });
    } else {
      const alert: Alert = {
        id: "noPrinterAlert",
        msg: "프린터를 선택해주세요.",
        alertType: "warning",
      };
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

  const [state, setState] = useState<TableStateType>({
    columns: [
      {
        title: "구분",
        field: "type",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "이름",
        field: "name",
        cellStyle: {
          width: "200px",
          paddingRight: 0,
        },
      },
      {
        title: "소속",
        field: "company",
        cellStyle: {
          width: "calc(100%-800px)",
          paddingRight: 0,
        },
      },
      {
        title: "휴대번호",
        field: "phoneNumber",
        cellStyle: {
          width: "250px",
          paddingRight: 0,
        },
      },
      {
        title: "",
        field: "approval",
        cellStyle: {
          width: "150px",
          paddingRight: 0,
        },
        render: (rowData: RowType) => {
          return (
            <div style={{ display: "flex" }}>
              <PxButton
                padding="5px 3px"
                backgroundcolor="grey"
                onClick={() => {
                  history.push(
                    `/subEvent/${params.id}/regitDesk/${rowData.id}`
                  );
                }}
              >
                <ButtonTypo>수정</ButtonTypo>
              </PxButton>
              <Box width={5} />
              {printer && (
                <PxButton
                  padding="5px"
                  backgroundcolor="darkGrey"
                  onClick={() => {
                    onClickApplicantPrint(rowData);
                  }}
                >
                  <ButtonTypo>인쇄</ButtonTypo>
                </PxButton>
              )}
            </div>
          );
        },
      },
    ],
    data: [],
  });

  useEffect(() => {
    const getApplicantsData = async () => {
      await dispatch(getApplicants(params.id));
      dispatch(setTableInfoLoadingAction(false));
    };
    getApplicantsData();
  }, [dispatch, params.id]);

  useEffect(() => {
    if (applicants) {
      const deskApplicants = [...applicants];
      const tableData = deskApplicants.map<RowType>((applicant) => ({
        id: applicant.id,
        bussinessCardId: applicant.businessCardId,
        type: applicant.type,
        name: applicant.name,
        company: applicant.company,
        phoneNumber: `(${applicant.countryNumber.toString()})${
          applicant.phoneNumber
        }`,
      }));

      setState((prevState) => {
        return { ...prevState, data: tableData };
      });
    }
  }, [applicants]);

  return (
    <PxTable
      columns={state.columns}
      data={state.data}
      options={{
        showTitle: false,
        actionsColumnIndex: -1,
        pageSize: 10,
        search: true,
        searchFieldVariant: "outlined",
      }}
      localization={{
        toolbar: { searchPlaceholder: "이름, 소속, 휴대번호 조회" },
      }}
      components={{
        Toolbar: (props) => (
          <MTableToolbar
            {...props}
            classes={{
              root: classes.tableToolbarRoot,
              spacer: classes.tableToolbarSpacer,
              searchField: classes.tableSearchField,
            }}
          />
        ),
      }}
    />
  );
};

export default SearchAndPrintTable;
