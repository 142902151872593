import Country from "../types/models/Country";
import CountryActionTypes from "../types/actions/CountryAction";

const countryDefaultState: Country = {
  country: {
    city: "Seoul",
    continent: "Asia",
    country: "South Korea",
    countryCode: "KR",
    thisCountryTz: "Asia/Seoul",
  },
  countries: {
    KR: {
      koName: "대한민국",
      enName: "Korean",
      timezone: "Asia/Seoul",
      i18code: "ko",
    },
  },
};

const userReducer = (
  state = countryDefaultState,
  action: CountryActionTypes
): Country => {
  switch (action.type) {
    case "SET_COUNTRY":
      return { ...state, country: action.country };
    case "SET_COUNTRIES":
      return { ...state, countries: action.countries };
    case "REMOVE_COUNTRY":
      return { ...state, country: state.country };
    default:
      return state;
  }
};

export default userReducer;
