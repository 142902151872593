import React from "react";
import uuid from "uuid";
import { useParams } from "react-router-dom";

// 자율양식 form component
import AutoForm from "../../autonomous/AutoForm";
import { Form } from "../../../../types/models/Form";

const VisitorSurveyForm = () => {
  const params = useParams<{ id: string }>(); // subEvent id

  const makeDefaultFormData = () => {
    const map: Form = {
      type: "VS",
      formData: { formData: {}, formIds: [] },
      content: "",
      subEventId: parseInt(params.id),
    };
    const data = [
      { title: "이름", type: "short" },
      { title: "소속", type: "short" },
      { title: "직함", type: "short" },
      { title: "휴대번호", type: "short" },
      { title: "이메일", type: "short" },
      { title: "키워드", type: "keyword" },
    ].map((v) => {
      const formId = `form-${uuid.v4()}`;
      map.formData!.formIds.push(formId);
      return {
        [formId]: {
          id: formId,
          index: 0,
          type: v.type,
          selectable: false,
          optionable: false,
          title: v.title,
          explain: "",
          secretCheck: "N",
          explainCheck: "N",
          requireCheck: "N",
          selectContent: {
            formData: {
              [formId]: {
                id: formId,
                explain: "",
                index: 0,
              },
            },
            formIds: [formId],
          },
          content: "",
        },
      };
    });
    Object.assign(map.formData!.formData, ...data);
    return map;
  };
  const initialData = makeDefaultFormData();

  return (
    <>
      <AutoForm initialFormData={initialData} />
    </>
  );
};

export default VisitorSurveyForm;
