import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React from "react";
import { useTranslation } from "react-i18next";

export default ({
  value,
  setValue,
}: {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<"default" | "week" | "multiple">>;
}) => {
  ///
  const [t] = useTranslation("lang", { useSuspense: false });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as "default" | "week" | "multiple");
  };

  return (
    <FormControl fullWidth component="fieldset" size="small">
      <RadioGroup
        row
        defaultValue="default"
        name="gender1"
        style={{ alignItems: "center" }}
        value={value}
        onChange={handleChange}
      >
        <FormLabel style={{ width: "auto", marginRight: 20 }} component="legend">
          {t("matched.repeatSetting")}
        </FormLabel>

        <FormControlLabel
          defaultChecked
          value="default"
          control={<Radio size="small" color="primary" />}
          label={t("matched.defaultDisabled")}
        />
        <FormControlLabel
          value="week"
          control={<Radio size="small" color="primary" />}
          label={t("matched.weekDisabled")}
        />
        <FormControlLabel
          value="multiple"
          control={<Radio size="small" color="primary" />}
          label={t("matched.multipleDisabled")}
        />
      </RadioGroup>
    </FormControl>
  );
};
