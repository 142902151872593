import React, {createContext, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getMonitoringMeetings, getVideoConnectedUser, meetingsActionStateToInitial} from "../../../../actions/meeting";
import {AppState} from "../../../../store";
import {makeStyles} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MonitoringData from "./BuyerMonitoring";
import moment from "moment";
import Clock from "../../../Clock/Clock";
import {MeetingInfo} from "../../../../types/models/Meeting";
import Chip from "@material-ui/core/Chip";
import PxIconButton from "../../../Buttons/PxIconButton";
import RefreshOutlinedIcon from "@material-ui/icons/RefreshOutlined";
// import VideoDialog from "../../../../video";
import MenuItem from "@material-ui/core/MenuItem";
import PxOutlinedFormControl from "../../../Forms/PxOutlinedFormControl";
import {choiceCountry} from "../../../../actions/country";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import PxDropDown from "../../../SelectBox/PxDropDown";
import {SubEventInfo} from "../../../../types/models/SubEvent";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import {createStyles, Paper, Theme, withStyles} from "@material-ui/core";
import Caption3Typo from "../../../Typhography/Caption3Typo";
import {debounce} from "lodash";
import {useTranslation} from "react-i18next";
import PxPurpleSwitch from "../../../switch/PxSwitch";
import PxCalendar from "../../../PxCalendar/PxCalendar";
import {CountryApiInfo, CountryInfo} from "../../../../types/models/Country";
import {parallaxCalculation} from "../../../../utils/momentTz";
const momentTz = require("moment-timezone");

interface MeetingContextType {
  videoDialogOpen: boolean;
  setVideoDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MeetingContext = createContext<MeetingContextType>(null!);

export interface NewMeetingDataType extends MeetingInfo {
  meetingStartDate: moment.Moment;
  meetingStartDateString: string;
  meetingEndDate: moment.Moment;
  meetingEndDateString: string;
  companyName?: string;
  companyId?: string;
  compnayCountry?: number;
  managerEmail?: string;
  managerName?: string;
  managerPhoneNumber?: string;
  otherCompanyCountry?: number;
  otherCompanyName?: string;
  otherCompanyId?: string;
  otherManagerEmail?: string;
  otherManagerName?: string;
  otherManagerPhoneNumber?: string;
  showMeetingStartTime?: string;
  showMeetingEndTime?: string;
  showMeetingStartDate?: string;
  showMeetingEndDate?: string;
  attendeeType?: string;
}

export interface RefinedMeetingDataType {
  seller: NewMeetingDataType[];
  buyer: NewMeetingDataType[];
}
export interface VideoCallParticipantInfo {
  roomId: string; // room의 uniqueName('subEventId/meetingId') 에서 meetingId 값
  type: string;
  identity: string; // 해당룸의 참가자목록
}

// 시간선택
// 시간 선택후 미팅리스트 정리

const Monitoring = () => {
  const [t, i18n] = useTranslation("lang", {useSuspense: false});
  const classes = useStyles();
  const dispatch = useDispatch();
  const params: {id: string} = useParams(); // id: suvbEventId
  const [nowDate, setNowDate] = useState<string>("");
  const [activeTab, setActiveTab] = useState<number>(0); // 셀러 바이어 선택 탭 0셀러 1바이어
  const [meetingLoading, setMeetingLoading] = useState<boolean>(true); // 미팅 테이블 api loading state
  const [participantsLoading, setParticipantsLoading] = useState<boolean>(false); // 영상통화 참가자 목록 api loading state
  const [isConnetion, setIsConnetion] = useState<boolean>(false);
  const [selectTimeTab, setSelectTimeTab] = useState<number>(0);
  const [timeTabArr, setTimeTabArr] = useState<{showStartTime: string; showEndTime: string}[]>([]);
  const [date, setDate] = useState<moment.Moment>(() => moment());
  const [selectDay, setSelectDay] = useState<{
    showDate: string;
    date: string;
  }>({
    showDate: nowDate,
    date: nowDate,
  });
  const [countryName, setCountryName] = useState<string>("");
  const [searchWord, setSearchWord] = useState<string>("");
  const [refinedMeetingData, setRefinedMeetingData] = useState<RefinedMeetingDataType>({
    seller: [],
    buyer: [],
  });
  // video Call dialog
  const [videoDialogOpen, setVideoDialogOpen] = useState<boolean>(false);
  // video Call 참가자 리스트
  const [videoCallParticipants, setVideoCallParticipants] = useState<VideoCallParticipantInfo[]>([]);
  //   const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const {country, countries} = useSelector((state: AppState) => state.countries!);
  const meetings = useSelector((state: AppState) => state.meetings.meetings);
  const subEvent = useSelector((state: {subEvents: {subEvent: SubEventInfo}}) => state.subEvents.subEvent);

  // 비디오 참가자 데이터 다시 불러오기
  const meetingDataRefresh = () => {
    callGetVideoConnectedUsers();
  };

  // 국가변경
  const changeCountry = async (e: React.MouseEvent) => {
    const key: string = e.currentTarget.getAttribute("value") as string;
    const getCountry = countries![key];
    setCountryName(e.currentTarget.id);
    dispatch(choiceCountry(key, getCountry.koName, getCountry.timezone));
  };

  // 나라 변경
  useEffect(() => {
    const nowTimeToTimeZone = momentTz.tz(moment(), country?.thisCountryTz);
    setNowDate(nowTimeToTimeZone.format("YYYY. MM.DD"));
    setCountryName(i18n.language === "ko" ? countries![country!.countryCode!].koName : countries![country!.countryCode!].enName);
  }, [country]);

  useEffect(() => {
    if (!subEvent || !subEvent.subEventStartDate || !subEvent.subEventEndDate) return;
    const localStartDate = parallaxCalculation(subEvent.subEventStartDate, "", countries![country!.countryCode!] as any, "YYYY-MM-DD");
    const localEndDate = parallaxCalculation(subEvent.subEventStartDate, "", countries![country!.countryCode!] as any, "YYYY-MM-DD");
    if (date.isBetween(localStartDate, localEndDate, undefined, "[]")) return;
    setDate(moment(subEvent.subEventStartDate));
  }, [subEvent]);

  useEffect(() => {
    const serverDate = parallaxCalculation(date.format("YYYY-MM-DD"), countries![country!.countryCode!] as any, "", "YYYY-MM-DD");
    setSelectDay({showDate: date.format("YYYY-MM-DD"), date: serverDate});
  }, [date]);

  useEffect(() => {
    callGetMeetings();
    // 미팅 목록 초기화
    return () => {
      dispatch(meetingsActionStateToInitial());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 해당 서브이벤트에서 영상통화에 참여하고 있는 사람들의 목록을 불러온 후 videoCallParticipants state에 할당
  const callGetVideoConnectedUsers = useCallback(async () => {
    if (meetings !== undefined && meetings.length !== 0) {
      setParticipantsLoading(true);
      const result: VideoCallParticipantInfo[] = await getVideoConnectedUser(meetings[0].subEventId!);
      setParticipantsLoading(false);
      if (result !== undefined) {
        setVideoCallParticipants(result);
      }
    }
  }, [meetings]);
  // 미팅을 받아오는 api call, loading state 변경
  const callGetMeetings = async () => {
    setMeetingLoading(true);
    await dispatch(getMonitoringMeetings(params.id));
    setMeetingLoading(false);
  };

  // 해당 이벤트의 전체 참가자 목록을 불러옴
  useEffect(() => {
    callGetVideoConnectedUsers();
    // 실시간 갱신 interval 10초
    const inter = setInterval(() => callGetVideoConnectedUsers(), 10000);
    return () => {
      clearInterval(inter);
    };
  }, [callGetVideoConnectedUsers, meetings]);

  //바이어 셀러 데이터에 가공
  useEffect(() => {
    console.log(`meetings : `, meetings);
    if (meetings !== undefined && meetings?.length !== 0) {
      const filteredMeetings = meetings.filter(m => m.status === "agree");
      const newMeetingData: NewMeetingDataType[] = filteredMeetings.map(meeting => {
        const startDate = moment(`${meeting.date} ${meeting.startTime}`);
        const endDate = moment(`${meeting.date} ${meeting.endTime}`);
        return {
          ...meeting,
          meetingStartDate: startDate,
          meetingStartDateString: startDate.format("YYYY-MM-DD hh:mm"),
          meetingEndDate: endDate,
          meetingEndDateString: endDate.format("YYYY-MM-DD hh:mm"),
        };
      });

      const buyerList: NewMeetingDataType[] = [];
      const sellerList: NewMeetingDataType[] = [];
      newMeetingData.forEach((meeting: NewMeetingDataType) => {
        const buyerMeetingData: NewMeetingDataType = {
          ...meeting,
          companyName: meeting.meetingApplicant?.name,
          companyId: meeting.meetingApplicant?.id,
          compnayCountry: meeting.meetingApplicant?.countryNumber,
          managerEmail: meeting.meetingApplicant?.email,
          managerPhoneNumber: meeting.meetingApplicant?.applicants,
          managerName: meeting.meetingApplicant?.managerName,
          otherCompanyName: meeting.meetingAcceptor?.name,
          otherCompanyId: meeting.meetingAcceptor?.id,
          otherCompanyCountry: meeting.meetingAcceptor?.countryNumber,
          otherManagerEmail: meeting.meetingAcceptor?.email,
          otherManagerPhoneNumber: meeting.meetingAcceptor?.applicants,
          otherManagerName: meeting.meetingAcceptor?.managerName,
          attendeeType: meeting.meetingApplicant?.attendeeType == "offline" && meeting.meetingAcceptor?.attendeeType == "offline" ? "offline" : "online",
        };
        const sellerMeetingData: NewMeetingDataType = {
          ...meeting,
          companyName: meeting.meetingAcceptor?.name,
          companyId: meeting.meetingAcceptor?.id,
          compnayCountry: meeting.meetingAcceptor?.countryNumber,
          managerPhoneNumber: meeting.meetingAcceptor?.applicants,
          managerEmail: meeting.meetingAcceptor?.email,
          managerName: meeting.meetingAcceptor?.managerName,
          otherCompanyName: meeting.meetingApplicant?.name,
          otherCompanyId: meeting.meetingApplicant?.id,
          otherCompanyCountry: meeting.meetingApplicant?.countryNumber,
          otherManagerPhoneNumber: meeting.meetingApplicant?.applicants,
          otherManagerEmail: meeting.meetingApplicant?.email,
          otherManagerName: meeting.meetingApplicant?.managerName,
          attendeeType: meeting.meetingApplicant?.attendeeType == "offline" && meeting.meetingAcceptor?.attendeeType == "offline" ? "offline" : "online",
        };
        buyerList.push(buyerMeetingData);
        sellerList.push(sellerMeetingData);
      });

      const refinedData: RefinedMeetingDataType = {
        buyer: buyerList,
        seller: sellerList,
      };

      setRefinedMeetingData(refinedData);
    }
  }, [meetings]);

  //국가변경시 날짜/시간 맞게 재입력
  useEffect(() => {
    if (subEvent) {
      createMeetingTimeForm();
    }
  }, [subEvent, country]);

  // 미팅날짜 가공
  const createMeetingTimeForm = () => {
    //////////// 미팅데이터 사용자의 접속국가 및 시간대에 따라 가공 ////////////
    const serverTime = parallaxCalculation(date.format("YYYY-MM-DDTHH:ss"), countries![country!.countryCode!] as any, "");

    setTimeTabArr(
      timeArrGenerator(
        subEvent.subEventStartDate,
        subEvent.subEventEndDate,
        serverTime,
        subEvent.matchStartTime!,
        subEvent.matchReqTime!,
        subEvent.matchBreakTime!,
        subEvent.matchNumPerDay!,
        countries!,
        country!
      )
    );
  };

  //검색
  const debounceWord = debounce(word => {
    setSearchWord(word);
  }, 500);

  const onChangeSearach = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceWord(e.target.value.trim());
  };

  //시간 탭변경
  const handleChangeTimeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectTimeTab(newValue);
  };
  // 셀러 바이어 선택
  const handleActiveTabChange = (event: React.MouseEvent) => {
    setActiveTab(parseInt(event.currentTarget.getAttribute("value")!));
  };

  return (
    <MeetingContext.Provider
      value={{
        setVideoDialogOpen: setVideoDialogOpen,
        videoDialogOpen: videoDialogOpen,
      }}
    >
      {/* {videoDialogOpen === true ? <VideoDialog videoDialogOpen={videoDialogOpen} setVideoDialogOpen={setVideoDialogOpen} /> : null} */}
      <div className={classes.root}>
        <div className={classes.titleRoot}>
          <PxIconButton className={classes.iconButton} padding={5} onClick={meetingDataRefresh}>
            <RefreshOutlinedIcon />
          </PxIconButton>
          <PxDropDown label={activeTab === 0 ? (subEvent && subEvent.sgroupName) ?? "SELLER" : (subEvent && subEvent.bgroupName) ?? "BUYER"}>
            <MenuItem value={0} onClick={handleActiveTabChange}>
              {subEvent && subEvent.sgroupName}
            </MenuItem>
            <MenuItem value={1} onClick={handleActiveTabChange}>
              {subEvent && subEvent.bgroupName}
            </MenuItem>
          </PxDropDown>
          <Caption3Typo display="inline">&nbsp;&nbsp;&nbsp;|&nbsp;</Caption3Typo>

          {/* 날짜 selector */}
          <PxCalendar date={date} setDate={setDate} isDay meetings={meetings?.filter(meeting => meeting.status === "agree")} />

          {/* 연락서 switch */}
          <PxPurpleSwitch className={classes.connetion} checked={isConnetion} onChange={() => setIsConnetion(!isConnetion)} label={t("monitoring.contact")} />

          <div className={classes.divFlex} />
          <PxOutlinedFormControl style={{marginRight: 20}}>
            {/* <InputLabel id="demo-simple-select-outlined-label">
              접속국가
            </InputLabel> */}
            <PxDropDown label={countryName}>
              {React.Children.toArray(
                Object.keys(countries!).map(countryMap => (
                  <MenuItem value={countryMap} id={i18n.language === "ko" ? countries![countryMap].koName : countries![countryMap].enName} onClick={changeCountry}>
                    {i18n.language === "ko" ? countries![countryMap].koName : countries![countryMap].enName}
                  </MenuItem>
                ))
              )}
            </PxDropDown>
          </PxOutlinedFormControl>
          <Chip className={classes.chip} variant="outlined" label={<Clock />} />

          {/* 검색 */}
          <SearchBox>
            <SearchInput type="text" placeholder={t("monitoring.search")} onChange={onChangeSearach} />
            <SearchIcon />
          </SearchBox>
        </div>

        {/* 시간탭 */}
        <Paper elevation={0}>
          <StyleTabs variant="scrollable" value={selectTimeTab} onChange={handleChangeTimeTabs} aria-label="disabled tabs example">
            <StyleTab label={t("common.all")}></StyleTab>
            {React.Children.toArray(
              timeTabArr.length > 0 && timeTabArr.map((time: {showStartTime: string; showEndTime: string}) => <StyleTab label={`${time.showStartTime} ~ ${time.showEndTime} `} />)
            )}
          </StyleTabs>
        </Paper>

        {participantsLoading === true && <LinearProgress />}

        <div className={classes.meetingTableRoot}>
          {meetingLoading === true && (
            <div className={classes.loadingDiv}>
              <CircularProgress />
            </div>
          )}

          <MonitoringData
            selectTime={timeTabArr[selectTimeTab - 1]?.showStartTime ?? ""} //전체 탭이 있기때문에 -1 해줘야함
            selectDate={selectDay}
            meetings={activeTab === 1 ? refinedMeetingData.buyer : refinedMeetingData.seller} //바이어 셀러 선택
            buyerParticipants={videoCallParticipants}
            searchWord={searchWord}
            meetingLoading={meetingLoading}
            isConnetion={isConnetion}
          />
        </div>
      </div>
    </MeetingContext.Provider>
  );
};

export default Monitoring;

const StyleTabs = withStyles({
  indicator: {
    backgroundColor: "rgb(81, 45, 168)",
  },
  root: {
    marginBottom: 5,
    boxShadow: " 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 3px 4px 0 rgba(0, 0, 0, 0.14)",
    "&$selected": {
      color: "rgb(81, 45, 168)",
      fontWeight: 500,
    },
  },
})(Tabs);

const StyleTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: "normal",
      fontSize: "1rem",
      "&$selected": {
        color: "rgb(81, 45, 168)",
        fontWeight: 500,
      },
      "&:focus": {
        color: "rgb(81, 45, 168)",
        outline: "none",
      },
    },
    selected: {},
  })
)((props: {label: string}) => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  titleRoot: {
    height: 80,
    display: "flex",
    padding: "0px 40px",
    borderBottom: "solid 1px #dcdee1",
    alignItems: "center",
  },
  meetingTableRoot: {
    width: "100%",
    height: "calc(100% - 39px)",
    overflowY: "scroll",
    padding: "0px 40px 40px 40px",
    backgroundColor: "rgb(250, 250, 250)",
  },
  appbar: {
    backgroundColor: "#e9ecef",
    marginBottom: 1,
  },
  divFlex: {
    flex: 1,
  },
  chip: {
    float: "right",
    padding: 7,
    minWidth: 100,
    fontSize: "1rem",
    "& span": {
      padding: 0,
      transform: "translate(0%, 10%)",
    },
  },
  iconButton: {
    color: "blue",
    backgroundColor: "aliceblue",
  },
  loadingDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& div": {
      textAlign: "center",
      "& p": {
        marginBottom: "10px",
      },
    },
  },
  connetion: {
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 10,
  },
}));

const SearchBox = styled.div`
  min-width: 240px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.04);
  margin-left: 20px;
  padding: 7px 16px;
`;

const SearchInput = styled.input`
  width: calc(100% - 24px);
  font-size: 16px;
  border: none;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
`;
function timeArrGenerator(
  subEventStartDate: string,
  subEventEndDate: string,
  startDate: string, // 서버시간으로 받기(한국시간 기준)
  startTime: string, // 서버시간으로 받기
  reqTime: number,
  breakTime: number,
  numPerDay: number,
  countries: CountryInfo,
  country: CountryApiInfo
): {showStartTime: string; showEndTime: string}[] {
  // timecard 만들어주는 함수 -----
  // 서브이벤트 시작날
  // 서브이벤트 시작시간
  // 서브이벤트 끝나는날
  // 미팅 소요시간
  // 미팅 쉬는시간
  // 미팅 횟수
  // --------------------------
  // subEvent , countrycode 변경될때마다 업데이트

  let meetingTimeList: {showStartTime: string; showEndTime: string}[] = []; // 필요데이터 {시작시간, 종료시간, 불가여부}
  // 날짜별 매칭시간 기준 값
  let impossibleStartTime = moment(`${startDate.split("T")[0]} ${startTime}`);

  if (impossibleStartTime.isBefore(subEventStartDate.split("T")[0])) return meetingTimeList;
  if (impossibleStartTime.isAfter(`${subEventEndDate.split("T")[0]}T${startTime}`)) return meetingTimeList;
  let localStartTime = moment(parallaxCalculation(impossibleStartTime.format("YYYY-MM-DDTHH:mm:ss"), "", countries![country!.countryCode!] as any));
  // 날짜별 매칭시간 조회 (시작시간, 종료시간, 불가여부)
  Array.from({length: numPerDay || 0}, (x, i) => {
    meetingTimeList.push({
      showStartTime: localStartTime.format("HH:mm"),
      showEndTime: localStartTime.add(reqTime, "m").format("HH:mm"),
    });
    localStartTime.add(breakTime, "m");
    impossibleStartTime = impossibleStartTime.add(breakTime, "m");
  });

  return meetingTimeList;
}
