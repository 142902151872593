import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";

import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../../components/Grid/PxGridItem";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {makeStyles} from "@material-ui/core/styles";
import TitleTypo from "../../../../components/Typhography/TitleTypo";
import {useDispatch, useSelector} from "react-redux";
import {deleteWebinar, getWebinar, getWebinarQA, isZoomWebinar} from "../../../../actions/webinar";
import {AppState} from "../../../../store";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import Body1Typo from "../../../../components/Typhography/Body1Typo";
import {Webinar} from "../../../../types/models/Webinar";
import styled from "styled-components";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import SubHeadingTypo from "../../../../components/Typhography/SubHeadingTypo";
import {getOnlyFileName} from "../../../../utils/utils";
import FileSaver from "file-saver";
// import Video from "../../../../video";
import useCalcTime from "../../../../hooks/useCalcTime";
import {confirmAlert} from "react-confirm-alert";
import {confirmModalOptions} from "../../../../utils/confirmModalOptions";
import {newAlert} from "../../../../actions/alerts";
import {useTranslation} from "react-i18next";
import CaptionTypo from "../../../Typhography/CaptionTypo";
import Box from "@material-ui/core/Box";
import WebinarTextAera from "./WebinarTextAera";
import Body3Typo from "../../../Typhography/Body3Typo";
import Divider from "@material-ui/core/Divider";
import Question from "./WebinarQuestion";
import Pagination from "../../../Pagination/Pagination";

const WebinarDetail = () => {
  const classes = useStyles();
  const history = useHistory();
  const {secondId: webinarId} = useParams<{secondId: string}>();
  const dispatch = useDispatch();
  const [calcTimeByTimezone] = useCalcTime();
  const webinarQA = useSelector((state: AppState) => state.webinars.webinarQA);
  const {country, countries} = useSelector((state: AppState) => state.countries);
  const user = useSelector((state: AppState) => state.users);

  const webinar = useSelector((state: AppState) => state.webinars.webinar);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [qaSelect, setQASelect] = useState<"" | "complete" | "incomplete">("");
  const [page, setPage] = useState<number>(0);
  const [webinarData, setWebinarData] = useState<Webinar>();
  const [isZoom, setIsZoom] = useState<boolean>(false);
  const [videoDialogOpen, setVideoDialogOpen] = useState<boolean>(false);
  const [t] = useTranslation("lang", {useSuspense: false});
  useEffect(() => {
    dispatch(getWebinar(webinarId));
    dispatch(getWebinarQA(webinarId, qaSelect, page));
  }, [dispatch, webinarId]);
  useEffect(() => {
    dispatch(getWebinarQA(webinarId, qaSelect, page));
  }, [qaSelect, page]);

  useEffect(() => {
    if (webinar && subEvent?.mainEvent) {
      const newWebinarData = {
        ...webinar,
        startDate: calcTimeByTimezone(webinar.startDate, "YYYY-MM-DD HH:mm"),
        endDate: calcTimeByTimezone(webinar.endDate, "YYYY-MM-DD HH:mm"),
      };
      if (!webinar.zoomLink && webinar.isZoom === "Y") {
        dispatch(isZoomWebinar(webinarId, "N"));
      }
      webinar.zoomLink && setIsZoom(webinar.isZoom === "Y" ? true : false);
      setWebinarData(newWebinarData);
    }
  }, [calcTimeByTimezone, subEvent, webinar]);

  const onClickFileDownLoad = (filePath: string, fileName: string) => {
    FileSaver.saveAs(filePath, fileName);
  };

  const onClickModify = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(`${history.location.pathname}/modify`);
  };
  const onClickDelete = async (e: React.MouseEvent) => {
    e.preventDefault();
    confirmAlert(
      confirmModalOptions({
        title: t("noticeDetail.deleteAlert"),
        click: async () => {
          const result: any = await dispatch(deleteWebinar(webinarId));
          if (result) {
            history.goBack();
          }
        },
      })
    );
  };

  //zoom 링크로 연결
  const changeIsZoom = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (webinar?.zoomLink !== "") {
      if (isZoom) {
        const result: any = await dispatch(isZoomWebinar(webinarId, "N"));
      } else {
        const result: any = await dispatch(isZoomWebinar(webinarId, "Y"));
      }
    } else {
      dispatch(newAlert(t("webinarDetail.addZoomLink"), webinarId));
    }
  };

  const enterZoomLinke = (e: React.MouseEvent) => {
    if (webinar?.zoomLink) {
      window.open(webinar.zoomLink, "_blank");
    } else {
      alert("줌링크를 추가해주세요");
    }
    // setVideoDialogOpen(true);
  };

  return (
    <div>
      {/* {videoDialogOpen === true && <Video videoDialogOpen={videoDialogOpen} setVideoDialogOpen={setVideoDialogOpen} />} */}
      <PxGridContainer alignItems="center" justify="space-between">
        <PxGridItem>
          <ArrowBackIcon className={classes.arrowBackIcon} onClick={() => history.goBack()} />
        </PxGridItem>
        {/* 수정/삭제하기 */}
        <PxGridItem>
          <PxButton margin="0px 5px 0px 0px" backgroundcolor="purple" disabled={webinar?.zoomLink ? false : true} onClick={enterZoomLinke}>
            <ButtonTypo> {webinarData && webinarData.zoomLink ? t("webinarDetail.enter") : t("webinarDetail.preparing")}</ButtonTypo>
          </PxButton>

          <PxButton backgroundcolor="purple" onClick={onClickModify}>
            <ButtonTypo>{t("common.modify")}</ButtonTypo>
          </PxButton>
          <PxButton onClick={onClickDelete}>
            <ButtonTypo>{t("common.delete")}</ButtonTypo>
          </PxButton>
        </PxGridItem>
      </PxGridContainer>
      <div className={classes.contentRoot}>
        <TitleTypo fontSize={20}>
          {webinarData?.title}
          {webinarData && !webinarData.zoomLink && (
            <CaptionTypo txtcolor="red" style={{float: "right"}}>
              {t("webinarDetail.addZoomLink")}
            </CaptionTypo>
          )}

          {/* <PxPurpleSwitch
            disabled={webinar?.zoomLink ? false : true}
            style={{ float: "right" }}
            checked={isZoom}
            onChange={changeIsZoom}
            label={t("webinarDetail.switchToZoom")}
          /> */}
        </TitleTypo>

        <Body1Typo txtcolor={"black0.38"}>
          {webinarData?.startDate} - {webinarData?.endDate}
        </Body1Typo>

        {webinar?.content && <WebinarContentDiv dangerouslySetInnerHTML={{__html: webinar!.content}} />}

        {webinar?.atchFiles &&
          webinar.atchFiles.length > 0 &&
          webinar.atchFiles.map(file => (
            <PxButton
              className={classes.fileButton}
              backgroundcolor="grey"
              startIcon={<AttachFileIcon />}
              onClick={e => {
                e.preventDefault();
                onClickFileDownLoad(file.filePath, getOnlyFileName(file.fileName, file.fileType));
              }}
            >
              <ButtonTypo>{getOnlyFileName(file.fileName, file.fileType)}</ButtonTypo>
            </PxButton>
          ))}

        {webinar?.presenters && webinar.presenters.length > 0 && (
          <Card className={classes.presenterCardRoot} elevation={0}>
            <PresenterAvatar />
            <PxGridContainer direction="column">
              <SubHeadingTypo>{webinar.presenters[0].name}</SubHeadingTypo>
              {webinar.presenters[0].applicants &&
                webinar.presenters[0].applicants.map((a, index) => (
                  <div key={`presenter-${index}`}>
                    <Body1Typo txtcolor="black0.6">
                      {a.businessCard.email} {`(${a.businessCard.countryNumber})${a.businessCard.phoneNumber}`}
                    </Body1Typo>
                  </div>
                ))}
            </PxGridContainer>
          </Card>
        )}
        <Box marginY="40px">
          <PxGridContainer direction="column" justify="space-between">
            <TitleTypo fontSize={21} fontWeight="400" gutterBottom>
              {t("webinarDetail.questions")}
            </TitleTypo>
            <WebinarTextAera />
            <PxGridContainer style={{gap: 10, marginTop: 20, marginBottom: 10}} justify="space-between" alignItems="flex-end">
              <PxGridContainer style={{gap: 15, width: "initial"}} justify="space-between" wrap="nowrap">
                <PxButton className={classes.button} padding="0px" border={false} hover={false} outline={false} onClick={() => setQASelect("")}>
                  <Body3Typo txtcolor={qaSelect !== "" ? "secondary" : undefined}>{t("webinarDetail.all")}</Body3Typo>
                </PxButton>
                <PxButton className={classes.button} padding="0px" hover={false} border={false} outline={false} onClick={() => setQASelect("complete")}>
                  <Body3Typo txtcolor={qaSelect !== "complete" ? "secondary" : undefined}>{t("webinarDetail.complete")}</Body3Typo>
                </PxButton>
                <PxButton className={classes.button} padding="0px" hover={false} border={false} outline={false} onClick={() => setQASelect("incomplete")}>
                  <Body3Typo txtcolor={qaSelect !== "incomplete" ? "secondary" : undefined}>{t("webinarDetail.incomplete")}</Body3Typo>
                </PxButton>
              </PxGridContainer>
              <Body1Typo fontSize="0.825rem" txtcolor="secondary">
                &nbsp;{webinarQA.totalElements}&nbsp;Questions
              </Body1Typo>
            </PxGridContainer>
            <Divider />
            {webinarQA && webinarData && countries && country && webinarQA.content.length > 0
              ? webinarQA.content.map(qa => (
                  <Question key={qa.id} webinarQAData={qa} countries={countries} country={country} countryCode={subEvent?.mainEvent?.countryCode} webinar={webinarData} user={user} />
                ))
              : null}
          </PxGridContainer>
          <Pagination page={page} totalPage={webinarQA.totalPages} onChange={(e, page) => setPage(page - 1)} />
        </Box>
      </div>
    </div>
  );
};

export default WebinarDetail;

const WebinarContentDiv = styled.div`
  img {
    max-width: 100%;
  }
  line-height: 1.75;
  color: #616161;
  min-height: 140px;
  margin-top: 16px;
  margin-bottom: 20px;
`;

const PresenterAvatar = styled(Avatar)`
  margin: 0 10px;
  background-color: lightgrey;
  width: 40px;
  height: 40px;
`;

const useStyles = makeStyles(theme => ({
  arrowBackIcon: {
    cursor: "pointer",
    margin: 12,
    width: 24,
    height: 24,
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
      marginBottom: 20,
    },
  },
  contentRoot: {
    paddingLeft: 50,
    marginTop: 24,
  },
  presenterCardRoot: {
    marginTop: 24,
    display: "flex",
  },
  presenterCardDetails: {
    paddingLeft: 10,
    display: "flex",
    flexDirection: "column",
  },
  presenterCardContent: {
    flex: "1 0 auto",
    padding: 0,
  },
  fileButton: {
    marginRight: 10,
  },
  button: {
    minWidth: "auto",
  },
}));
