import React, {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {removeFormAction} from "../../../actions/forms";
import {useTranslation} from "react-i18next";

// custom comp
import MakeFormComponent from "./MakeFormComponent";

// context
import useFormContext from "../../../components/Provider/FormProvider/useFormContext";
import {AppState} from "../../../store";
import {getSubEvent} from "../../../actions/subEvents";
// import useGTranslate from "../../../hooks/useGTranslate";
import SubHeadingTypo from "../../../components/Typhography/SubHeadingTypo";
import Box from "@material-ui/core/Box";

const NoMeetingFormSetting: React.FC = () => {
  const [t] = useTranslation("lang", {useSuspense: false});
  // const [translateText] = useGTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const {setInitialLoad} = useFormContext();
  const params = useParams<{id: string}>(); // 서브 이벤트 id 값을 가져오기 위함
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const event = useSelector((state: AppState) => state.events.event);

  useEffect(() => {
    dispatch(getSubEvent(parseInt(params.id)));
    setInitialLoad(true);
    dispatch(removeFormAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.push(`/event/detail/${event!.id}`);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      {/* 설명회 양식만들기 종류 */}
      {subEvent?.category === "presentation" && (
        <>
          <SubHeadingTypo fontweight="bold" gutterBottom>
            {subEvent?.sgroupName}
          </SubHeadingTypo>
          {/* 참가자 신청양식 */}
          <MakeFormComponent
            title={`${subEvent.sgroupName} ${t("form.registration")}`}
            onClick={() => {
              history.push(`/form/subEvent/${params.id}/noMatch/attPartiForm`);
            }}
            required={true}
            done={subEvent?.sellerPartiForm === 0 ? false : true}
          />
          {/* 참가자 설문양식 */}
          <MakeFormComponent
            title={`${subEvent.sgroupName} ${t("form.survey")}`}
            onClick={() => {
              history.push(`/form/subEvent/${params.id}/noMatch/attCounselForm`);
            }}
            done={subEvent?.sellerSurveyForm === 0 ? false : true}
          />

          <Box paddingY={5} />

          <SubHeadingTypo fontweight="bold" gutterBottom>
            {subEvent?.bgroupName}
          </SubHeadingTypo>
          {/* 발표자 참가양식 */}
          <MakeFormComponent
            title={`${subEvent.bgroupName} ${t("form.registration")}`}
            onClick={() => {
              history.push(`/form/subEvent/${params.id}/noMatch/prePartiForm`);
            }}
            required={true}
            done={subEvent?.buyerPartiForm === 0 ? false : true}
          />
          {/* 발표자 설문양식 */}
          <MakeFormComponent
            title={`${subEvent.bgroupName} ${t("form.survey")}`}
            onClick={() => {
              history.push(`/form/subEvent/${params.id}/noMatch/preCounselForm`);
            }}
            done={subEvent?.buyerSurveyForm === 0 ? false : true}
          />
        </>
      )}
      {subEvent?.boothCompany === "Y" && (
        <>
          <MakeFormComponent
            title="부스업체 참가신청"
            onClick={() => {
              history.push(`/form/subEvent/${params.id}/noMatch/boothPartiForm`);
            }}
          />
          <MakeFormComponent
            title="부스업체 사후설문"
            onClick={() => {
              history.push(`/form/subEvent/${params.id}/noMatch/boothSurveyForm`);
            }}
          />
        </>
      )}
      {subEvent?.preRegit === "Y" && (
        <>
          <MakeFormComponent
            title="사전등록 참관객 참가신청(사전설문)"
            onClick={() => {
              history.push(`/form/subEvent/${params.id}/noMatch/visitorPartiForm`);
            }}
          />
          <MakeFormComponent
            title="사전등록 참관객 사후설문"
            onClick={() => {
              history.push(`/form/subEvent/${params.id}/noMatch/visitorSurveyForm`);
            }}
          />{" "}
        </>
      )}
    </>
  );
};

export default NoMeetingFormSetting;
