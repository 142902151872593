import React from "react";
import Banner from "./Banner";
import GroupIcon from "../../../../assets/icons/sharp-groups.svg";
import { SubEventInfo } from "../../../../types/models/SubEvent";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

export default ({ subEvent }: { subEvent: SubEventInfo }) => {
  const history = useHistory();
  const [t] = useTranslation("lang", { useSuspense: false });
  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (subEvent.estimates && !subEvent.estimates.some((data) => data.type === "quoation")) {
      return alert("최종견적서가 아직 작성되지 않았습니다.");
    }
    history.push(`/estimate/subEvent/${subEvent.id}/conferenceQuoation`);
  };
  return (
    <Banner
      bgColor="#af52de"
      IconNode={<img src={GroupIcon} />}
      estimateLink={`/estimate/subEvent/${subEvent.id}/conference`}
      actionBtnTypo={t("eventDetail.finalQuote")}
      actionBtnClick={onClick}
      title={<>{t("common.conference")}</>}
      body={
        <Trans t={t} i18nKey="eventDetail.conferenceBannerInfo">
          출입증 발급을 위한 무선프린터, 출입증 용지, 목걸이 등<br />
          현장에 필요한 서비스가 있는지 확인해보세요.
        </Trans>
      }
    />
  );
};
