import React from "react";
import {AppState} from "../../../../../store";
import {useSelector} from "react-redux";
import Card from "@material-ui/core/Card";
import {makeStyles, CardContent} from "@material-ui/core";
import PxGridContainer from "../../../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../../../components/Grid/PxGridItem";
import SubHeadingTypo from "../../../../../components/Typhography/SubHeadingTypo";
import TitleTypo from "../../../../../components/Typhography/TitleTypo";
import ButtonTypo from "../../../../../components/Typhography/ButtonTypo";
import BusinessCard from "../../../../../types/models/BusinessCard";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  cardRoot: {
    padding: 20,
    marginTop: 20,
    border: "1px solid #ddd",
    width: 450,
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0px 40px 0px",
      padding: 5,
      width: "100%",
    },
  },
}));

interface Props {
  businessCards: BusinessCard[];
}

const CompanyManagerInfo: React.FC<Props> = ({businessCards}) => {
  const classes = useStyles();

  const application = useSelector((state: AppState) => state.applications.application);

  // return application !== undefined ? (
  //   <>
  //     {application!.applicants!.map((applicant, index) => {
  //       return (
  //         applicant.businessCard !== undefined && (
  return (
    <>
      {
        businessCards.map((businessCard, index) => {
          return (
            <>
              <Card className={classes.cardRoot} key={`manager-${index}`}>
                <CardContent>
                  <PxGridContainer direction="column">
                    <PxGridItem>
                      <SubHeadingTypo>{businessCard.company}</SubHeadingTypo>
                    </PxGridItem>
                    <PxGridItem className="mt-4">
                      <TitleTypo>{businessCard.name}</TitleTypo>
                    </PxGridItem>
                    <PxGridItem className="mt-4">
                      <ButtonTypo>{`${businessCard.email}`}</ButtonTypo>
                    </PxGridItem>
                    <PxGridItem>
                      <ButtonTypo>{`(${businessCard.countryNumber})${businessCard.phoneNumber}`}</ButtonTypo>
                    </PxGridItem>
                  </PxGridContainer>
                </CardContent>
              </Card>
            </>
          ); // end map return
        }) // end map
      }
    </>
  );
};
export default CompanyManagerInfo;
