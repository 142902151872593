import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";

interface Props {
  bgColor: string;
  body: ReactNode;
  title: ReactNode;
  IconNode: ReactNode;
  estimateLink?: string;
  actionBtnTypo?: string;
  actionDisabled?: boolean;
  actionBtnClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default (props: Props) => {
  const {bgColor, title, body, IconNode, estimateLink, actionBtnTypo, actionDisabled, actionBtnClick} = props;
  const history = useHistory();
  const [t] = useTranslation("lang", {useSuspense: false});
  return (
    <Container>
      <Content>
        <Icon bgColor={bgColor}>{IconNode}</Icon>
        {
          <div>
            <Title>{title}</Title>
            <Body>{body}</Body>
          </div>
        }
      </Content>
      <ButtonContainer>
        {/* <PxButton backgroundcolor="#f1f2f5" onClick={() => history.push(estimateLink)}>
          <ButtonTypo txtcolor="black0.6">{t("eventDetail.estimatedQuote")}</ButtonTypo>
        </PxButton> */}
        {/* {actionBtnTypo && actionBtnClick && (
          <PxButton
            disabled={actionDisabled}
            className="last"
            backgroundcolor="black.87"
            onClick={actionBtnClick}
          >
            <ButtonTypo txtcolor="white">{actionBtnTypo}</ButtonTypo>
          </PxButton>
        )} */}
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 26px 40px 26px 48px;
  border-radius: 3px;
  align-items: center;
  border: solid 1px rgba(0, 0, 0, 0.12);
  margin-bottom: 32px;
  &:not(:first-child) {
    margin-bottom: 8px !important;
  }
  &:not(:first-child) {
    margin-top: 32px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div<{bgColor: string}>`
  height: 68px;
  width: 68px;
  font-weight: bold;
  border-radius: 50%;
  margin-right: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bgColor};
  color: white;
`;

const ButtonContainer = styled.div`
  display: inline;
  & .last {
    margin-left: 8px;
  }
`;

const Title = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
`;
const Body = styled.span`
  font-weight: 500;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
`;
