import React from "react";
import PxButton from "../Buttons/PxButton";
import FileSaver from "file-saver";
// import XLSX from "xlsx";
import * as XLSX from "xlsx-js-style";
import ButtonTypo from "../Typhography/ButtonTypo";
import {useTranslation} from "react-i18next";

type interpreterColor = {[k: string]: string};

interface ExportCSVProps {
  sellerData: any[];
  buyerData: any[];
  fileName: string;
  marges?: any[];
  sellerName: string;
  buyerName: string;
  type?: string;
  interpreterColor?: interpreterColor;
}

export const ExportCSV = ({sellerData, buyerData, fileName, marges, sellerName, buyerName, type, interpreterColor}: ExportCSVProps) => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";

  // const backgroundColor = ["6666FF", "CC00CC", "6633CC", "CC9900", "00CCCC", "CC0000", "6666FF", "CC00CC", "6633CC", "00CCCC"];

  const exportToCSV = (sellerData: any[], buyerData: any[], name: string, sellerName: string, buyerName: string, marges?: any[]) => {
    const sellerWs = XLSX.utils.json_to_sheet(sellerData);
    const buyerWs = XLSX.utils.json_to_sheet(buyerData);

    // 미팅 Excel style setting
    if (type == "meeting") {
      let sellerWorkSheetCol = [];
      let buyerWorkSheetCol = [];

      // seller sheet 스타일

      for (const key in sellerWs) {
        if (typeof sellerWs[key] != "object") continue;

        if (sellerWs[key].v.toString().includes("통역 :") && interpreterColor != undefined) {
          const interpreterName: string = sellerWs[key].v.toString().split("\r\n")[0].split("통역 :")[1] as string;

          sellerWs[key].s = {
            width: 20,
            fill: {
              fgColor: {rgb: interpreterColor[interpreterName]},
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          };
        } else if (sellerWs[key].v.toString().includes("(online)")) {
          sellerWs[key].s = {
            width: 20,
            fill: {
              fgColor: {rgb: "009900"},
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          };
        } else if (sellerWs[key].v.toString().includes("(offline)")) {
          sellerWs[key].s = {
            width: 20,
            fill: {
              fgColor: {rgb: "CC0000"},
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          };
        } else {
          sellerWs[key].s = {
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          };
        }
        sellerWorkSheetCol.push({width: 24});
      }

      // buyer sheet 스타일 지정

      for (const key in buyerWs) {
        if (typeof buyerWs[key] != "object") continue;

        if (buyerWs[key].v.toString().includes("통역 :") && interpreterColor != undefined) {
          const interpreterName: string = buyerWs[key].v.toString().split("\r\n")[0].split("통역 :")[1] as string;
          buyerWs[key].s = {
            width: 20,
            fill: {
              fgColor: {rgb: interpreterColor[interpreterName]},
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          };
        } else if (buyerWs[key].v.toString().includes("(online)")) {
          buyerWs[key].s = {
            width: 20,
            fill: {
              fgColor: {rgb: "009900"},
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              // wrapText: true,
            },
          };
        } else if (buyerWs[key].v.toString().includes("(offline)")) {
          buyerWs[key].s = {
            width: 20,
            fill: {
              fgColor: {rgb: "CC0000"},
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              // wrapText: true,
            },
          };
        } else {
          buyerWs[key].s = {
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          };
        }
        buyerWorkSheetCol.push({width: 24});
      }

      sellerWs["!cols"] = sellerWorkSheetCol;
      buyerWs["!cols"] = buyerWorkSheetCol;
    }

    const pattern_sheetName = /[~!@\#$%<>?^&*\()\[\]\/\-|.{}=+_\’\s]+/g;
    const replaceSellerName = sellerName.replace(pattern_sheetName, "_");
    const replaceBuyerName = buyerName.replace(pattern_sheetName, "_");

    const wb = {
      Sheets: {
        [`${replaceSellerName}`]: {
          ...sellerWs,
          "!merges": marges,
        },
        [`${replaceBuyerName}`]: {
          ...buyerWs,
          "!merges": marges,
        },
      },
      SheetNames: [`${replaceSellerName}`, `${replaceBuyerName}`],
    };

    const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});

    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <PxButton backgroundcolor="purple" onClick={e => exportToCSV(sellerData, buyerData, fileName, sellerName, buyerName, marges)}>
      <ButtonTypo>{t("excel.download(xlsx)")}</ButtonTypo>
    </PxButton>
  );
};
//
