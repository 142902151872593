import MaterialTable, {Column, MTableToolbar} from "material-table";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {customTheme, StyledDiv, tableIcons} from "../../../../Table/PxTable";
import {makeStyles, MuiThemeProvider} from "@material-ui/core/styles";
import PxButton from "../../../../Buttons/PxButton";
import ButtonTypo from "../../../../Typhography/ButtonTypo";
import {getRegitUser, toggleAttendanceState} from "../../../../../actions/registration";
import {useHistory, useParams} from "react-router";
import {AppState} from "../../../../../store";
import {useTranslation} from "react-i18next";
import {AttendanceState, RegitUser} from "../../../../../types/models/Registration";
import styled from "styled-components";
import RegisterSearchBar from "./RegisterListSearchBar";
import RegisterTypeSelector from "./RegisterTypeSelector";
import RegisterMethodSelector from "./RegisterMethodSelector";

interface TableAndSearchFieldProps {
  onClickApplicantPrint: (rowData: RegitUser) => void;
  typeList: string[];
}

interface TableStateType {
  columns: Array<Column<RegitUser>>;
  data: RegitUser[];
}

const useStyles = makeStyles(() => ({
  tableToolbarRoot: {
    justifyContent: "center",
    "& fieldset": {
      borderRadius: 28,
      borderColor: "#e0e0e0",
    },
  },
  tableToolbarSpacer: {
    flex: "none",
  },
  tableSearchField: {
    minWidth: 420,
  },
  container: {
    marginTop: 20,
  },
  buttonRoot: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
  },
  formControl: {
    minWidth: 150,
  },
}));

const TableAndSearchField: React.FC<TableAndSearchFieldProps> = ({onClickApplicantPrint, typeList}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [t] = useTranslation("lang", {useSuspense: false});
  const registerUsers = useSelector((state: AppState) => state.registration.userPage);
  const params = useParams<{id: string}>();
  const [selectedType, setSelectedType] = useState<string>("");
  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [state, setState] = useState<TableStateType>({
    columns: [
      {
        title: "구분",
        field: "type",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "등록 방식",
        field: "method",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "이름",
        field: "name",
        cellStyle: {
          width: "200px",
          paddingRight: 0,
        },
      },
      {
        title: "소속",
        field: "companyName",
        cellStyle: {
          width: "calc(100%-800px)",
          paddingRight: 0,
        },
      },
      {
        title: "휴대번호",
        field: "phoneNum",
        cellStyle: {
          width: "250px",
          paddingRight: 0,
        },
      },
      {
        title: "이메일",
        field: "email",
        cellStyle: {
          width: "calc(100%-800px)",
          paddingRight: 0,
        },
      },
      {
        title: "출석 여부",
        field: "attendance",
        cellStyle: {
          width: "calc(100%-800px)",
          paddingRight: 0,
        },
        render: (rowData: RegitUser) => {
          const isAttendance: boolean = rowData.attendanceState == AttendanceState.ATTENDANCE ? true : false;
          return (
            <div style={{display: "flex"}}>
              <PxButton
                padding="5px"
                backgroundcolor={isAttendance ? "purple" : "darkGrey"}
                onClick={e => {
                  e.stopPropagation();
                  dispatch(toggleAttendanceState(Number(rowData!.id)));
                }}
              >
                <ButtonTypo>{isAttendance ? "출석중" : "결석중"}</ButtonTypo>
              </PxButton>
            </div>
          );
        },
      },
      {
        title: "",
        field: "approval",
        cellStyle: {
          width: "150px",
          paddingRight: 0,
        },
        render: (rowData: RegitUser) => {
          return (
            <div style={{display: "flex"}}>
              <PxButton
                padding="5px"
                backgroundcolor="darkGrey"
                onClick={e => {
                  e.stopPropagation();
                  onClickApplicantPrint(rowData);
                }}
              >
                <ButtonTypo>인쇄</ButtonTypo>
              </PxButton>
            </div>
          );
        },
      },
    ],
    data: [],
  });

  useEffect(() => {
    dispatch(getRegitUser(params.id, selectedMethod, selectedType, search, page, rowsPerPage));
  }, [selectedMethod, selectedType, search, page, rowsPerPage]);

  useEffect(() => {
    setSearch("");
  }, [selectedType]);

  useEffect(() => {
    if (registerUsers) {
      const deskApplicants = [...registerUsers.content];
      const tableData = deskApplicants.map<RegitUser>(applicant => ({
        id: applicant.id,
        name: applicant.name,
        phoneNum: applicant.phoneNum,
        type: applicant.type,
        companyName: applicant.companyName,
        email: applicant.email,
        position: applicant.position,
        method: applicant.method,
        attendanceState: applicant.attendanceState,
      }));

      setState(prevState => {
        return {...prevState, data: tableData};
      });
    }
  }, [registerUsers]);

  return (
    <MuiThemeProvider theme={customTheme}>
      <StyledDiv footer={true}>
        <TableHeader>
          <RegisterMethodSelector selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} />
          <RegisterTypeSelector typeList={typeList} selectedType={selectedType} setSelectedType={setSelectedType} />
          <RegisterSearchBar onSearch={setSearch} />
        </TableHeader>
        <MaterialTable
          columns={state.columns}
          icons={tableIcons}
          data={state.data}
          options={{
            showTitle: false,
            actionsColumnIndex: -1,
            pageSize: rowsPerPage,
            search: false,
            searchFieldVariant: "outlined",
            paginationType: "normal",
            pageSizeOptions: [1, 10, 20, 30, 40, 50, 80, 100],
            toolbar: false,
            paging: true,
          }}
          page={page}
          totalCount={registerUsers?.totalElements}
          onChangePage={page => setPage(page)}
          onChangeRowsPerPage={rows => {
            setRowsPerPage(rows);
            setPage(0);
          }}
          localization={{
            toolbar: {searchPlaceholder: "이름, 소속, 휴대번호 조회"},
          }}
          onRowClick={(event, rowData) => {
            history.push(`/subEvent/${params.id}/regitApplicationDetail/${rowData?.id}`);
          }}
          components={{
            Toolbar: props => (
              <MTableToolbar
                {...props}
                classes={{
                  root: classes.tableToolbarRoot,
                  spacer: classes.tableToolbarSpacer,
                  searchField: classes.tableSearchField,
                }}
              />
            ),
          }}
        />
      </StyledDiv>
    </MuiThemeProvider>
  );
};

export default TableAndSearchField;

const TableHeader = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;
