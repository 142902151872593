import React, {useEffect, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {AppState} from "../../../store";
import uuid from "uuid";
import {useHistory, useParams} from "react-router-dom";

// types
import {FormData} from "../../../types/models/AutonoForm";

// component
import AutonomousForm from "../autonomous/AutonomousForm";
import PxAutonoForm from "../autonomous/PxAutonomousForm";
// material comp
import PxButton from "../../../components/Buttons/PxButton";
import ButtonTypo from "../../../components/Typhography/ButtonTypo";

// material icons
import AddIcon from "@material-ui/icons/Add";
import {Form} from "../../../types/models/Form";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import SubHeadingTypo from "../../../components/Typhography/SubHeadingTypo";
import {setAddFormsAction, createForm} from "../../../actions/forms";
import {useTranslation} from "react-i18next";
import {exportSgroupAdd} from "../setting/template/templateData";

const AddtionalForm = () => {
  const dispatch = useDispatch();
  const {additionalFormData, formData, content, templateType} = useSelector((state: AppState) => state.forms);
  const [t] = useTranslation("lang", {useSuspense: false});
  const {id} = useParams<{id: any}>(); // id: subEventId
  const [initData, setInitialData] = React.useState<FormData>({
    formData: {},
    formIds: [],
  });
  const history = useHistory();
  const forms = useSelector((state: AppState) => state.forms);

  useEffect(() => {
    if (additionalFormData) {
      if (templateType !== "export" || (additionalFormData.formIds.length !== 0 && templateType == "export")) {
        setInitialData(additionalFormData);
      } else if (templateType === "export" && history.location.pathname.includes("addSellerPartiForm")) {
        const map: FormData = {
          formData: {},
          formIds: [],
        };
        const exportAddData = exportSgroupAdd.map(v => {
          const formId = `form-${uuid.v4()}`;
          map.formIds.push(formId);

          return {
            [formId]: {
              id: formId,
              index: 0,
              type: v.type,
              selectable: v.title == "카테고리" || v.title == "키워드" ? false : true,
              optionable: true,
              title: v.title,
              explain: "",
              secretCheck: "N",
              explainCheck: v.explainable ? "Y" : "N",
              requireCheck: v.required ? "Y" : "N",
              explainable: v.explainable,
              selectContent: {
                formData: {
                  [formId]: {
                    id: formId,
                    explain: "",
                    index: 0,
                  },
                },
                formIds: [formId],
              },
              content: "",
              keywordOptions: [],
            },
          };
        });

        Object.assign(map.formData, ...exportAddData);
        Object.assign(additionalFormData as FormData, map);

        setInitialData(additionalFormData);
      }
    }
  }, [additionalFormData, templateType]);

  // 양식추가하기 버튼 onClick function
  const formAddButtonOnClick = () => {
    // 기본 자율양식 form 데이터
    const newFormId = `form-${uuid.v4()}`;
    const newForm = {
      [newFormId]: {
        id: newFormId,
        index: 0,
        type: "single",
        title: "",
        explain: "",
        secretCheck: "N",
        explainCheck: "N",
        requireCheck: "N",
        selectContent: {
          formData: {
            [newFormId]: {
              id: newFormId,
              explain: "",
              index: 0,
            },
          },
          formIds: [newFormId],
        },
      },
    };
    setInitialData(prevState => {
      const formData = Object.assign(prevState!.formData, newForm);
      const formIds = prevState!.formIds;
      formIds.push(newFormId);
      return {...prevState, formData, formIds};
    });
  };

  // 양식 복사 onClick fucntion
  const formCopyButtonOnClick = (formId: string, index: number) => {
    const newFormId = `form-${uuid.v4()}`;
    // 복사할 폼과 같은 폼 생성
    const newForm = {
      [newFormId]: {
        ...initData!.formData[formId],
        id: newFormId,
        selectContent: {
          formData: {},
          formIds: [] as string[],
        },
      },
    };

    // 단일 및 복수 선택지 내용을 복사하기 위한 변수
    const selectContent: FormData = {
      formData: {},
      formIds: [],
    };
    // 전체 form에 selectForm의 key값과 id 값만 변경해서 넣어줌
    for (let [, value] of Object.entries(initData!.formData[formId].selectContent!.formData)) {
      const selectFormId = `form-${uuid.v4()}`;
      Object.assign(selectContent.formData, {
        [selectFormId]: {
          id: selectFormId,
          explain: value.explain,
          index: value.index,
        },
      });
    }

    // select Form의 index값에 따라 정렬 후 formIds 값에 넣어줌
    const selectFormOrderByIndex = Object.values(selectContent.formData).sort((a, b) => a.index! - b.index!);
    const formIds = selectFormOrderByIndex.map(value => {
      return value.id;
    });
    selectContent.formIds = formIds;

    // select content object값 갱신
    Object.assign(newForm[newFormId].selectContent, selectContent);

    // 생성된 폼을 formData에 추가하고 formIds 배열에 기존폼 index 옆에 추가
    setInitialData(prevState => {
      const formData = Object.assign(initData!.formData, newForm);
      const formIds = prevState!.formIds;
      formIds.splice(index + 1, 0, newFormId);
      return {...prevState, formData, formIds};
    });
  };

  // 양식 삭제 onClick function
  const formDeleteButtonOnClick = (selectId: string) => {
    // const newData = initData.formData.filter((value) => value.id !== selectId);
    delete initData!.formData[selectId]; // delete object property
    setInitialData(prevState => {
      // delete formId in formIds array
      const formIds = prevState!.formIds.filter(value => value !== selectId);
      return {...prevState, formIds};
    });
  };

  // 복수/단수 선택 양식 추가 onClick function
  const selectContentFormAdd = (formId: string) => {
    const newFormId = `form-${uuid.v4()}`;
    const newSelectForm = {
      [newFormId]: {
        id: newFormId,
        explain: "",
        index: initData!.formData[formId].selectContent?.formIds.length,
      },
    };

    //@ts-ignore
    setInitialData(prevState => {
      const formData = prevState!.formData;
      Object.assign(formData[formId].selectContent?.formData, newSelectForm);
      formData[formId].selectContent?.formIds.push(newFormId);
      return {...prevState, formData};
    });
  };
  // 복수/단수 선택 폼 삭제 메소드
  const selectContentFormDelete = (formId: string, selectFormId: string) => {
    //@ts-ignore
    setInitialData(prevState => {
      const formData = prevState!.formData;
      delete formData[formId].selectContent?.formData[selectFormId];
      const formIds = formData[formId].selectContent?.formIds.filter(value => value !== selectFormId);
      formData[formId].selectContent!.formIds = formIds!;
      return {...prevState, formData};
    });
  };

  const backButtonOnClick = () => {
    const form: Form = {
      content: content,
      additionalFormData: initData,
    };

    dispatch(setAddFormsAction(form));
    history.goBack();
  };

  // 미리보기 버튼 onclick function, redux에 forms data 저장 후 미리보기 화면이동
  const previewOnClick = () => {
    const form: Form = {
      additionalFormData: initData,
    };

    dispatch(setAddFormsAction(form));
    history.push("/form/add/preview");
  };

  // 완료 버튼 onClick function
  const onSubmit = useCallback(async () => {
    const form: Form = {
      id: forms.id,
      type: "",
      subEventId: id,
      content: JSON.stringify(formData),
      additional: JSON.stringify(initData),
    };

    if (history.location.pathname.includes("addBuyerPartiForm")) {
      form["type"] = "BYP";
    } else if (history.location.pathname.includes("addSellerPartiForm")) {
      form["type"] = "SP";
    }

    const result: any = await dispatch(createForm(form));

    if (result) {
      history.push(`/form/subEvent/${id}/match`);
    }
  }, [initData]);

  return (
    <div>
      <SubHeadingTypo fontweight="bold">{t("additionalForm.additionalInfo")}</SubHeadingTypo>
      <SubHeadingTypo txtcolor="secondary">{t("additionalForm.eg")}</SubHeadingTypo>
      {initData !== undefined && (
        <AutonomousForm
          colunmId="columId-1"
          initialData={initData}
          setInitialData={setInitialData}
          draggableComp={
            <PxAutonoForm
              id=""
              index={0}
              elevation={0}
              setSelected={() => {}}
              draghandle={undefined}
              formCopy={formCopyButtonOnClick}
              formDelete={formDeleteButtonOnClick}
              initialData={initData!}
              setInitialData={setInitialData}
              selectContentFormAdd={selectContentFormAdd}
              selectContentFormDelete={selectContentFormDelete}
            />
          }
        />
      )}
      <PxButton fullWidth backgroundcolor="grey" startIcon={<AddIcon />} className="mt-3" onClick={formAddButtonOnClick}>
        <ButtonTypo>{t("additionalForm.addForm")}</ButtonTypo>
      </PxButton>
      <PxGridContainer justify="space-between" style={{marginTop: 100}}>
        <PxButton padding="10px 60px 10px 60px" onClick={backButtonOnClick}>
          <ButtonTypo>{t("additionalForm.previous")}</ButtonTypo>
        </PxButton>
        {/* 완성된 양식 미리보기 버튼 */}
        <PxButton backgroundcolor="grey" onClick={previewOnClick} padding="10px 50px 10px 50px">
          <ButtonTypo>{t("additionalForm.preview")}</ButtonTypo>
        </PxButton>

        <PxButton backgroundcolor="purple" padding="10px 60px 10px 60px" onClick={onSubmit}>
          <ButtonTypo>{t("additionalForm.done")}</ButtonTypo>
        </PxButton>
      </PxGridContainer>
    </div>
  );
};

export default AddtionalForm;
