import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { AppActions, AppState } from "../store";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { setAlert } from "../utils/utils";

// 세금계산서 인증 메일 발송
export const createTemporaryCode = (email: string,userName: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify({ email ,userName });

  const path = `/api/temporaryCodes`;
  try {
    var res: AxiosResponse<Event> = await axios.post(path, body, config);

    return res.data;
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

// 세금계산서 인증 메일 재발송
export const createTemporaryCodeAgain = (email: string, userName : string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify({ email, userName });

  const path = `/api/temporaryCodes/temporaryCodesAgain`;
  try {
    var res: AxiosResponse<Event> = await axios.post(path, body, config);

    return res.data;
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

// 세금계산서 메일 인증 업데이트(상태값 N -> Y)
export const approvalTemporaryCode = (temporaryCode: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify({ temporaryCode: temporaryCode });

  const path = "/api/temporaryCodes/approval";
  try {
    var res: any = await axios.put(path, body, config);

    return { result: "success", message: "" };
  } catch (err) {
    const error: Error = err.response.data;

    return { result: "error", message: error.detail };
  }
};

// 인증여부 조회
export const getTemporaryCode = <TemporaryCode>(
  email: string,
  temporaryCode: string
) => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/temporaryCodes/detail?email=${email}&temporaryCode=${temporaryCode}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};
