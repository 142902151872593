import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, RouteComponentProps } from "react-router-dom";
import { AppState } from "../../../../store";
import { Column, MTableToolbar } from "material-table";

// actions
import { getNotices } from "../../../../actions/notice";

// custom ui comp
import PxTable from "../../../../components/Table/PxTable";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import PxGridContainer from "../../../Grid/PxGridContainer";

import moment from "moment";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";
import { useTranslation } from "react-i18next";

interface RowType {
  noticeTitle: string;
  noticeCreatedDate: string;
  noticeUpdatedDate: string;
  noticeId: string | undefined;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const NoticeList: React.FC<RouteComponentProps> = ({ match }) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const history = useHistory();
  const param: { id: string } = useParams();
  const notices = useSelector((state: AppState) => state.notices.notices);

  const callGetNotices = useCallback(async () => {
    await dispatch(getNotices(param.id));
    if (!notices || notices.content.length <= 0) dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  useEffect(() => {
    callGetNotices();
  }, [callGetNotices]);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("common.title"),
        field: "noticeTitle",
        cellStyle: {
          width: "calc(100%-600px)",
          paddingRight: 0,
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("common.registerDate"),
        field: "noticeCreatedDate",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
      },
      {
        title: t("common.reviseDate"),
        field: "noticeUpdatedDate",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
      },
    ],
    data: [],
  });

  useEffect(() => {
    let tableData: RowType[] = [];
    if (notices?.content && Array.isArray(notices?.content) && notices.content.length > 0) {
      notices.content.forEach((notice) => {
        const rowData: RowType = {
          noticeTitle: notice.title,
          noticeCreatedDate: moment(notice.createdDate).format("YYYY-MM-DD"),
          noticeUpdatedDate: moment(notice.modifiedDate).format("YYYY-MM-DD"),
          noticeId: notice.id,
        };
        tableData.push(rowData);
      });
      setState((prevCreateDate) => {
        return { ...prevCreateDate, data: tableData };
      });
      dispatch(setTableInfoLoadingAction(false));
    }
  }, [notices]);

  return (
    <>
      <div style={{ textAlign: "right", margin: "10px" }}>
        <PxButton backgroundcolor="purple" onClick={() => history.push(`${match.url}/add`)}>
          <ButtonTypo>{t("common.add")}</ButtonTypo>
        </PxButton>
      </div>

      <PxTable<RowType>
        components={{
          Toolbar: (props) => {
            return (
              <PxGridContainer direction="column">
                {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                <MTableToolbar {...props} />
              </PxGridContainer>
            );
          },
        }}
        title=""
        getAction={getNotices}
        totalPage={notices?.totalPages}
        actionData={param.id}
        columns={state.columns}
        data={state.data}
        onRowClick={(evt, selectedRow) => {
          history.push(`${match.url}/${selectedRow?.noticeId}`);
        }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
      />
    </>
  );
};

export default NoticeList;
