import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Checkbox,
  DeviceLabels,
  Flex,
  FormField,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  PrimaryButton,
  Select,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";
import {
  DefaultBrowserBehavior,
  MeetingSessionConfiguration,
} from "amazon-chime-sdk-js";

import { createGetAttendeeCallback, fetchMeeting } from "../../utils/api";
import { useAppState } from "../../providers/AppStateProvider";
import { getErrorContext } from "../../providers/ErrorProvider";
import meetingConfig from "../../meetingConfig";
import { MeetingMode, VideoFiltersCpuUtilization } from "../../types";
import routes from "../../constants/routes";

import { StyledWrapper, StyledAudioGroup, StyledVideoGroup } from "./Styled";
import MicrophoneDevices from "./MicrophoneDevices";
import SpeakerDevices from "./SpeakerDevices";
import CameraDevices from "./CameraDevices";

const DeviceSelection = () => {
  const meetingManager = useMeetingManager();
  const {
    region,
    meetingId,
    localUserName,
    meetingMode,
    enableSimulcast,
    priorityBasedPolicy,
    keepLastFrameWhenPaused,
    isWebAudioEnabled,
    videoTransformCpuUtilization: videoTransformCpuUtilization,
    setJoinInfo,
    isEchoReductionEnabled,
    toggleEchoReduction,
    toggleWebAudio,
    toggleSimulcast,
    togglePriorityBasedPolicy,
    toggleKeepLastFrameWhenPaused,
    setMeetingMode,
    setMeetingId,
    setLocalUserName,
    setRegion,
    setCpuUtilization,
  } = useAppState();
  const [meetingErr, setMeetingErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { errorMessage, updateErrorMessage } = useContext(getErrorContext());
  const history = useHistory();
  const browserBehavior = new DefaultBrowserBehavior();

  useEffect(() => {
    handleJoinMeeting();
  }, []);

  const handleJoinMeeting = async () => {
    // e.preventDefault();
    const id = meetingId.trim().toLocaleLowerCase();
    const attendeeName = localUserName.trim();

    if (!id || !attendeeName) {
      if (!attendeeName) {
        setNameErr(true);
      }

      if (!id) {
        setMeetingErr(true);
      }

      return;
    }

    setIsLoading(true);
    meetingManager.getAttendee = createGetAttendeeCallback(id);

    try {
      const { JoinInfo } = await fetchMeeting(
        id,
        attendeeName,
        region,
        isEchoReductionEnabled
      );
      setJoinInfo(JoinInfo);
      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        JoinInfo?.Meeting,
        JoinInfo?.Attendee
      );
      setRegion(JoinInfo.Meeting.MediaRegion);
      meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers =
        enableSimulcast;
      if (priorityBasedPolicy) {
        meetingSessionConfiguration.videoDownlinkBandwidthPolicy =
          priorityBasedPolicy;
      }
      meetingSessionConfiguration.keepLastFrameWhenPaused =
        keepLastFrameWhenPaused;
      const options = {
        deviceLabels:
          meetingMode === MeetingMode.Spectator
            ? DeviceLabels.None
            : DeviceLabels.AudioAndVideo,
        enableWebAudio: isWebAudioEnabled,
        logLevel: meetingConfig.logLevel,
        postLoggerConfig: meetingConfig.postLogConfig,
        logger: meetingConfig.logger,
      };
      console.log(
        `meetingsession : ${meetingSessionConfiguration}`,
        meetingSessionConfiguration
      );

      await meetingManager.join({
        meetingInfo: JoinInfo.Meeting,
        attendeeInfo: JoinInfo.Attendee,
        deviceLabels:
          meetingMode === MeetingMode.Spectator
            ? DeviceLabels.None
            : DeviceLabels.AudioAndVideo,
      });
      if (meetingMode === MeetingMode.Spectator) {
        await meetingManager.start();
        history.push(`${routes.MEETING}/${meetingId}`);
      } else {
        setMeetingMode(MeetingMode.Attendee);
        console.log("routes.device : ", routes.DEVICE);
        history.push("/videoMeeting/device");
      }
    } catch (error) {
      updateErrorMessage((error as Error).message);
    }
  };

  return (
    <StyledWrapper>
      <StyledAudioGroup>
        <MicrophoneDevices />
        <SpeakerDevices />
      </StyledAudioGroup>
      <StyledVideoGroup>
        <CameraDevices />
      </StyledVideoGroup>
    </StyledWrapper>
  );
};

export default DeviceSelection;
