import React, { Fragment } from "react";
import CompanyInfoView from "./CompanyInfoView";
import CompanyInfoProvider from "./CompanyInfoProvider";
import CompanyInfoTopView from "./CompanyInfoTopView";

const CompanyInfo = () => {
  return (
    <Fragment>
      <CompanyInfoProvider>
        <CompanyInfoTopView />
        <CompanyInfoView />
      </CompanyInfoProvider>
    </Fragment>
  );
};

export default CompanyInfo;
