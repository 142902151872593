import React, {useEffect, useState} from "react";
import Banner from "./Banner";
import DoneIcon from "@material-ui/icons/Done";
import {SubEventInfo} from "../../../../types/models/SubEvent";
import {useHistory} from "react-router-dom";
import {newAlert} from "../../../../actions/alerts";
import {useDispatch, useSelector} from "react-redux";
import {Trans, useTranslation} from "react-i18next";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {getRequestAndReturn} from "../../../../utils/utils";
import {AppState} from "../../../../store";

const useStyles = makeStyles(theme => ({
  disableCheckBox: {
    "&.Mui-disabled": {
      color: "rgb(0, 204, 0)",
    },
  },
  disableLabel: {
    "&.Mui-disabled .MuiTypography-body1": {
      color: "inherit",
    },
  },
}));

export default ({subEvent}: {subEvent: SubEventInfo}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const event = useSelector((state: AppState) => state.events.event);
  const [t] = useTranslation("lang", {useSuspense: false});
  const [emailStatus, setEmailStatus] = useState<boolean>(false);
  const [smsStatus, setSmsStatus] = useState<boolean>(false);

  useEffect(() => {
    if (event == undefined) return;
    getEventPaymentState(event.id as number);
  }, [event]);

  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (subEvent.id && subEvent.estimates && !subEvent.estimates.some(data => data.type === "quoation")) {
      return dispatch(newAlert("최종견적서가 아직 작성되지 않았습니다.", subEvent.id));
    }
    history.push(`/estimate/subEvent/${subEvent.id}/conferenceQuoation`);
  };

  const getEventPaymentState = async (eventId: number) => {
    const res: any = await dispatch(getRequestAndReturn(`/api/events/payment-status/${eventId}`, {}));
    setEmailStatus(res.emailStatus);
    setSmsStatus(res.smsStatus);
  };

  return (
    <Banner
      bgColor="#34c759"
      IconNode={<DoneIcon style={{width: 40, height: 40}} />}
      estimateLink={`/estimate/subEvent/${subEvent.id}/consulting`}
      actionBtnTypo={t("eventDetail.finalQuote")}
      actionBtnClick={onClick}
      title={
        <Trans t={t} i18nKey="eventDetail.paidPlanBannerTitle">
          상담회 <span style={{color: "#512da8"}}>유료 플랜</span> 이용중
        </Trans>
      }
      body={
        <>
          <FormControlLabel className={classes.disableLabel} control={<Checkbox className={classes.disableCheckBox} checked={smsStatus} />} label="SMS" disabled />
          <FormControlLabel style={{marginLeft: "10px"}} className={classes.disableLabel} control={<Checkbox className={classes.disableCheckBox} checked={emailStatus} />} label="Email" disabled />
        </>
      }
    />
  );
};
