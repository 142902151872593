import Progress from "../types/models/Progress";
import ProgressActionTypes from "../types/actions/ProgressAction";

const progressDefaultState: Progress = { open: "false" };

const userReducer = (
  state = progressDefaultState,
  action: ProgressActionTypes
): Progress => {
  switch (action.type) {
    case "SET_PROGRESS":
      return action.progress;
    default:
      return state;
  }
};

export default userReducer;
