import React, {useCallback, useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {getMeetingVideo} from "../../../../actions/meeting";

import {makeStyles} from "@material-ui/core/styles";
import Card, {CardProps} from "@material-ui/core/Card";
import {MeetingContext, NewMeetingDataType, VideoCallParticipantInfo} from "./Monitoring";
import SubHeadingTypo from "../../../Typhography/SubHeadingTypo";
import {useDispatch, useSelector} from "react-redux";
import PxGridContainer from "../../../Grid/PxGridContainer";
import PxGridItem from "../../../Grid/PxGridItem";
import {AppState} from "../../../../store";
import styled from "styled-components";
import Brightness1Icon from "@material-ui/icons/Brightness1";

interface MeetingCardProps {
  meetingData: NewMeetingDataType;
  participants: VideoCallParticipantInfo[];
  isConnetion: boolean;
  // attendeeIdentities: string[];
}

interface CustomCardProps extends CardProps {
  attendeeType: string;
}

const MeetingCard = ({meetingData, participants, isConnetion}: MeetingCardProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {setVideoDialogOpen} = useContext(MeetingContext);
  const [attendeeList, setAttendeeList] = useState<string[]>([]);
  const [attendeeCount, setAttendeeCount] = useState<number>(0);
  const user = useSelector((state: AppState) => state.users);

  useEffect(() => {
    if (participants !== undefined) {
      const identities: string[] = participants.map(participant => participant.identity);
      setAttendeeList(identities);
      setAttendeeCount(identities.length);
    }
  }, [participants]);

  const joinMeeting = () => {
    history.push(`/videoMeeting/device`);
  };

  const setVideoAttendeeCount = useCallback(() => {
    return <AttendeesCount>ATTENDEE : {attendeeCount} </AttendeesCount>;
  }, [attendeeCount]);

  const setVideoAttendeeConnected = useCallback(
    (companyName: string) => {
      if (attendeeList.length !== 0) {
        if (attendeeList.indexOf(companyName) !== -1) {
          return <Brightness1Icon style={{fontSize: 14, color: "rgb(126, 211, 33)"}} />;
        } else {
          return <Brightness1Icon style={{fontSize: 14, color: "rgb(208, 2, 27)"}} />;
        }
      } else {
        return <Brightness1Icon style={{fontSize: 14, color: "rgb(208, 2, 27)"}} />;
      }
    },
    [attendeeList]
  );

  return (
    <CustomCard
      variant="outlined"
      attendeeType={meetingData.attendeeType as string}
      onClick={async () => {
        await dispatch(getMeetingVideo(Number(meetingData.id!), Number(user.id!)));
        // setVideoDialogOpen(true);
        joinMeeting();
      }}
    >
      <PxGridContainer direction="column" justify="space-between">
        {/* <AttendeesCount>ATTENDEE : {attendeeCount} </AttendeesCount> */}
        {setVideoAttendeeCount()}
        <PxGridItem style={{maxWidth: "100%"}}>
          <SubHeadingTypo className={classes.companyNameTypo}>
            {setVideoAttendeeConnected(meetingData.companyName!)}&nbsp;&nbsp;
            {meetingData.companyName}
            <div style={{display: isConnetion ? "block" : "none"}}>
              <AdditionalData>{meetingData.managerName}</AdditionalData>
              <AdditionalData>{meetingData.managerEmail}</AdditionalData>
              <AdditionalData>
                +{meetingData.compnayCountry})&nbsp;
                {meetingData.managerPhoneNumber}
              </AdditionalData>
            </div>
          </SubHeadingTypo>
          <SubHeadingTypo className={classes.companyNameTypo}>
            {setVideoAttendeeConnected(meetingData.otherCompanyName!)}
            &nbsp;&nbsp;
            {meetingData.otherCompanyName}
            <div style={{display: isConnetion ? "block" : "none"}}>
              <AdditionalData>{meetingData.otherManagerName}</AdditionalData>
              <AdditionalData>{meetingData.otherManagerEmail}</AdditionalData>
              <AdditionalData>
                +{meetingData.otherCompanyCountry})&nbsp;
                {meetingData.otherManagerPhoneNumber}
              </AdditionalData>
            </div>
          </SubHeadingTypo>
        </PxGridItem>
        <PxGridItem>
          {/* <PxIconButton padding={7}>
              {setVideoIcon(meetingData.companyName!, meetingData.otherCompanyName!)}
            </PxIconButton> */}
        </PxGridItem>
      </PxGridContainer>
    </CustomCard>
  );
};

export default MeetingCard;

const useStyles = makeStyles({
  pos: {
    marginBottom: 12,
  },
  cardDateTypo: {
    backgroundColor: "aliceblue",
    textAlign: "center",
  },
  cardTimeTitle: {
    textAlign: "center",
  },
  cardContent: {
    border: "none",
  },
  companyNameTypo: {
    padding: "7px 0px 9px 0px",
    fontSize: "1rem !important",
    width: "100%",
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    // text-overflow:ellipsis;
    //   white-space:nowrap;
  },
});

const CustomCard = styled(Card)<CustomCardProps>`
  &:hover {
    cursor: pointer;
  }
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  /* border: solid 1px rgb(233, 234, 237); */
  border: solid 1px ${props => (props.attendeeType == "offline" ? "rgb(208, 2, 27)" : `rgb(126, 211, 33)`)};
  padding: 8px 16px;
`;

const AdditionalData = styled.div`
  font-size: 0.875rem;
  padding-left: 25px;
  word-break: break-all;
  white-space: break-spaces;
`;

const AttendeesCount = styled.div`
  text-align: end;
`;
