import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { deleteNotice, getNotice } from "../../../../actions/notice";
import { AppState } from "../../../../store";

// types
import SubHeadingTypo from "../../../Typhography/SubHeadingTypo";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import { TypographyProps } from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// custom ui comp
import OutlinedTxtField from "../../../Inputs/OutlinedTxtField";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const NoticeDetail = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const dispatch = useDispatch();
  const param: { id: string; secondId: string } = useParams(); // id: subEvent id, secondId: notice id
  const notice = useSelector((state: AppState) => state.notices.notice);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getNotice(param.secondId));
  }, [dispatch, param.secondId]);

  const onClickDeleteNotify = async () => {
    const result = await deleteNotice(param.secondId);
    if (result) {
      history.goBack();
    }
  };

  return (
    <>
      <Grid container>
        <Grid item>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              margin: "23px",
              width: "18px",
              height: "18px",
              objectFit: "contain",
            }}
            onClick={() => history.goBack()}
          />
        </Grid>
        <Grid item style={{ padding: "0 0 0 64px", width: "100%" }}>
          <PxButton
            backgroundcolor="grey"
            style={{ float: "right" }}
            onClick={() => {
              setDeleteAlertOpen(true);
            }}
          >
            <ButtonTypo>{t("noticeDetail.delete")}</ButtonTypo>
          </PxButton>
          <PxButton
            backgroundcolor="purple"
            style={{ float: "right", marginRight: 10 }}
            onClick={() => {
              history.push(
                `/subEvent/${param.id}/notice/${param.secondId}/modify`
              );
            }}
          >
            <ButtonTypo>{t("noticeDetail.modify")}</ButtonTypo>
          </PxButton>

          <SubTitle>{t("common.title")}</SubTitle>
          <OutlinedTxtField
            value={notice ? notice.title : ""}
            fullWidth
            disabled
          />
          <SubTitle>{t("common.content")}</SubTitle>
          <Grid
            style={{
              border: "1px solid #ccc",
              padding: "10.5px 14px",
              overflow: "scroll",
              maxHeight: "500px",
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: notice ? notice.content : "" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={deleteAlertOpen}
        onClose={() => {
          setDeleteAlertOpen(false);
        }}
      >
        <DialogTitle>{t("noticeDetail.deleteAlert")}</DialogTitle>
        <DialogActions>
          <PxButton backgroundcolor="purple" onClick={onClickDeleteNotify}>
            <ButtonTypo>{t("noticeDetail.yes")}</ButtonTypo>
          </PxButton>
          <PxButton
            backgroundcolor="grey"
            onClick={() => {
              setDeleteAlertOpen(false);
            }}
          >
            <ButtonTypo>{t("noticeDetail.no")}</ButtonTypo>
          </PxButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NoticeDetail;
