import {makeStyles} from "@material-ui/core/styles";
import React, {useMemo, useRef} from "react";
import ReactQuill, {Quill} from "react-quill";
import {useDispatch} from "react-redux";
import {getEditorImageRequest} from "../../actions/files";
// editor https://www.npmjs.com/package/react-quill#custom-toolbar
import "react-quill/dist/quill.snow.css";
import imageResize from "@looop/quill-image-resize-module-react";
import VideoResize from "./VideoResizer/VideoResize";

Quill.register("modules/imageResize", imageResize);
Quill.register("modules/VideoResize", VideoResize);

interface QuillProps {
  value: any;
  onChange: (value: string) => void;
  defaultValue?: string;
  placeholder?: string;
  folderPath: "mail/" | "event/" | "subEvent/webinar/" | "subEvent/program/" | "subEvent/passDesign/" | "subEvent/speaker/" | "subEvent/notice/";
}

type Prop = QuillProps;

export default (props: Prop) => {
  const {value, onChange, placeholder, folderPath, defaultValue} = props;
  const quillRef = useRef<ReactQuill>(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  /**
   * image 제어
   */
  function imageHandler() {
    if (quillRef && quillRef.current) {
      const quill: any = quillRef.current;
      const range = quill.getEditor().getSelection();

      // input file tag 생성
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", ".png,.jpg,.jpeg");
      input.click();

      // input change
      input.onchange = async (e: any) => {
        const file = e.target.files[0];
        const originalFileName = file.name.split(".");
        originalFileName.splice(-1, 1);
        const timestamp = new Date().getTime();
        const fileType = file.type.split("/")[1];
        const fileName = originalFileName.join() + "&&" + timestamp + "." + fileType;

        const fileSize = file.size;
        const gubun = e.currentTarget.name as string; // 여러파일등록시 어떤 파일을 등록했는지 구분

        const result: any = await dispatch(getEditorImageRequest(file, fileName, fileType, folderPath, fileSize, gubun));
        quill.getEditor().insertEmbed(range.index, "image", result.data.filePath);
      };
    }
  }

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{header: 1}, {header: 2}],
          ["bold", "italic", "underline"],
          [{script: "sub"}, {script: "super"}], // superscript/subscript
          [{size: ["small", false, "large", "huge"]}],
          [{color: []}, {background: []}],
          [{list: "ordered"}, {list: "bullet"}],
          [{align: []}],
          ["link", "image", "video", "code-block"],
        ],
        handlers: {
          image: imageHandler,
          // video: videoHandler,
        },
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
      VideoResize: {
        modules: ["Resize", "DisplaySize", "Toolbar"],
      },
    }),
    []
  );

  return <ReactQuill theme="snow" ref={quillRef} defaultValue={defaultValue} className={classes.quill} value={value} onChange={onChange} placeholder={placeholder} modules={modules} />;
};

const useStyles = makeStyles(theme => ({
  quill: {
    display: "inline-block",
    width: "100%",
    height: "100%",
    "& .ql-editor": {
      minHeight: 200,
      maxHeight: 600,
      height: "auto",
      "& iframe": {
        pointerEvents: "none",
      },
      "& .ql-video": {
        pointerEvents: "none",
      },
    },
  },
}));
