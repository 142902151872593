import React from "react";
import styled from "styled-components";
import { Link, Switch, RouteComponentProps, Route } from "react-router-dom";

// router
import { authRoutes } from "../router/router";

// type
import RouteType from "../types/models/Route";

// custom comp
import GridContainer from "../components/Grid/PxGridContainer";

// material comp
import Container from "@material-ui/core/Container";

// images
import PexpoLogo from "../assets/images/logo.png";
import GridItem from "../components/Grid/PxGridItem";
import PxIconButton from "../components/Buttons/PxIconButton";
import LanguageIcon from "@material-ui/icons/Language";
import PxMenu from "../components/Menu/PxMenu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslation } from "react-i18next";

const StyledForm = styled.form`
  margin: 10px;
`;

const Auth: React.FC<RouteComponentProps> = ({ match }) => {
  const [langIconAnchorEl, setLangIconAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t, i18n } = useTranslation("lang", { useSuspense: false });
  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return <Route exact path={prop.layout + prop.path} component={prop.component} key={key} />;
      }
      return null;
    });
  };
  const handleLanguageIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setLangIconAnchorEl(event.currentTarget);
  };
  return (
    <Container className="pt-3">
      <GridContainer justify="flex-end">
        <PxIconButton onClick={handleLanguageIconClick}>
          <LanguageIcon style={{ fontSize: 24 }} />
        </PxIconButton>
        <PxMenu anchorEl={langIconAnchorEl} setAnchorEl={setLangIconAnchorEl}>
          <MenuItem
            onClick={() => {
              localStorage.setItem("language", "ko");
              i18n.changeLanguage("ko");
              setLangIconAnchorEl(null);
            }}
          >
            <ListItemText primary={t("mainNavbar.korean")} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              localStorage.setItem("language", "en");
              i18n.changeLanguage("en");
              setLangIconAnchorEl(null);
            }}
          >
            <ListItemText primary={t("mainNavbar.english")} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              localStorage.setItem("language", "cn");
              i18n.changeLanguage("cn");
              setLangIconAnchorEl(null);
            }}
          >
            <ListItemText primary={t("mainNavbar.chinese")} />
          </MenuItem>
        </PxMenu>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem>
          <Link to="/">
            <img
              src={PexpoLogo}
              width="100"
              height="33"
              className="d-inline-block align-top"
              alt="Pexpo logo"
            />
          </Link>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center" className="mt-5">
        <GridItem>
          <StyledForm>
            <Switch>{getRoutes(authRoutes)}</Switch>
          </StyledForm>
        </GridItem>
      </GridContainer>
    </Container>
  );
};

export default Auth;
