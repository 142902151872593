import React from "react";
import styled from "styled-components";

// material ui comp
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";

interface PxIconButtonProps {
  padding?: number;
  hoverNone?: boolean;
}

type Prop = IconButtonProps & PxIconButtonProps;

const PxIconButton: React.FC<Prop> = styled((props: Prop) => {
  return <IconButton {...props} />;
})`
  padding: ${(props) => `${props.padding}px`};
  outline: none !important;
  &:hover {
    background-color: ${(props) => (props.hoverNone ? "transparent" : "rgb(241, 242, 245)")};
  }
`;

PxIconButton.defaultProps = {
  padding: 10,
};

export default PxIconButton;
