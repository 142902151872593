import React, {useState} from "react";
import {useRealtimeChatState} from "../../providers/ChatProvider";
import {TextField, Button} from "@material-ui/core";
import {Send} from "@material-ui/icons";
import styled from "styled-components";

const ChatEditor = () => {
  const {sendChatData} = useRealtimeChatState();
  const [inputMsg, setInputMsg] = useState("");

  const editorKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter" && inputMsg != "") {
      sendChatData(inputMsg);
      setInputMsg("");
    }
  };

  const editorOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    console.log(`value : ${value}`);
    setInputMsg(value);
  };

  const sendClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (inputMsg != "") {
      sendChatData(inputMsg);
      setInputMsg("");
    }
  };

  const EditorContainer = styled.div`
    width: 100%;
    height: 100%;
  `;

  return (
    <>
      <TextField hiddenLabel id="filled-hidden-label-small" value={inputMsg} variant="filled" size="small" style={{width: "80%"}} onKeyDown={editorKeyDown} onChange={editorOnChange} />
      <Button variant="contained" endIcon={<Send />} color="primary" onClick={sendClick}>
        send
      </Button>
    </>
  );
};

export default ChatEditor;
