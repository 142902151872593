import React from "react";
import styled from "styled-components";

import InputBase, { InputBaseProps } from "@material-ui/core/InputBase";

interface PxInputBase {
  fontsize?: number;
  fontweight?: "bold" | "normal";
  padding?: string;
}

type Prop = PxInputBase & InputBaseProps;

const PxInputBase: React.FC<Prop> = styled((props: Prop) => {
  const { fontsize, padding, ...rest } = props;
  return <InputBase {...rest} />;
})`
  font-size: ${(props) => props.fontsize + "px"};
  font-weight: ${(props) => props.fontweight ?? "normal"};
  padding: ${(props) => props.padding ?? "6px 0 7px"};
`;

PxInputBase.defaultProps = {
  fontsize: 16,
};

export default PxInputBase;
