import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { AppState, AppActions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators } from "redux";
import { removeUser } from "../../actions/users";
import { choiceCountry } from "../../actions/country";

// types
import User from "../../types/models/User";

// bootstrap ui component
import Navbar from "react-bootstrap/Navbar";

// material ui custom comp
import GridContainer from "../Grid/PxGridContainer";
import PxButton from "../Buttons/PxButton";
import GridItem from "../Grid/PxGridItem";
import ButtonTypo from "../Typhography/ButtonTypo";
import PxIconButton from "../Buttons/PxIconButton";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import PxMenu from "../Menu/PxMenu";

// images
import PexpoLogo from "../../assets/images/pexpo_signature.svg";
import LanguageIcon from "@material-ui/icons/Language";
import { useTranslation } from "react-i18next";

const StyledNavbar = styled(Navbar)`
  padding: 25px;
  background-color: white;
  & .nav-item {
    padding-right: 2em;
  }
`;

type Props = ReduxStateProps & DispatchProps;

const MainNavbar: React.FC<Props> = (props) => {
  const { users, removeUser } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("lang", { useSuspense: false });

  const { country, countries } = useSelector((state: AppState) => state.countries!);

  const [langIconAnchorEl, setLangIconAnchorEl] = React.useState<null | HTMLElement>(null);

  const changeCountry = async (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    const key: string = e.target.value as string;
    const getCountry = countries![key];

    await dispatch(choiceCountry(key, getCountry.koName, getCountry.timezone));
  };

  const handleLanguageIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setLangIconAnchorEl(event.currentTarget);
  };

  return (
    <StyledNavbar expand="md" sticky="top">
      <>
        <GridContainer direction="row" justify="space-between" alignItems="center">
          <GridItem>
            <img
              src={PexpoLogo}
              width="130"
              height="40"
              className="d-inline-block align-top"
              alt="Pexpo logo"
            />
          </GridItem>
          <GridItem>
            {/* 나라변경 메인에서 미팅에서 사용중 이후 필요시 주석 풀 것
            <PxSelectBox
              style={{
                justifyContent: "center",
                backgroundColor: "white",
                marginRight: '20px'
              }}
              value={country?.countryCode}
              onChange={changeCountry}
              displayEmpty
              name="countryCode"
              input={<OutlinedInput margin="dense" />}
            >
              {Object.keys(countries!).map((countryMap) => (
                <MenuItem value={countryMap}>{countries![countryMap].koName}</MenuItem>
              ))}
            </PxSelectBox> */}
            <PxButton backgroundcolor="purple" onClick={() => history.push("/event/add")}>
              <ButtonTypo>{t("mainNavbar.makeEvent")}</ButtonTypo>
            </PxButton>
            <PxButton onClick={() => history.push("/event")}>
              <ButtonTypo>{t("mainNavbar.eventList")}</ButtonTypo>
            </PxButton>
            {/* <PxButton>
              <ButtonTypo>{t("mainNavbar.profile")}</ButtonTypo>
            </PxButton> */}
            {users.userId ? (
              <PxButton onClick={() => removeUser()}>
                <ButtonTypo>{t("mainNavbar.logout")}</ButtonTypo>
              </PxButton>
            ) : (
              <PxButton onClick={() => history.push("/auth/login")}>
                <ButtonTypo>{t("mainNavbar.login")}</ButtonTypo>
              </PxButton>
            )}
            {/* 언어변경 아이콘버튼 */}
            <PxIconButton onClick={handleLanguageIconClick}>
              <LanguageIcon style={{ fontSize: 24 }} />
            </PxIconButton>
            <PxMenu anchorEl={langIconAnchorEl} setAnchorEl={setLangIconAnchorEl}>
              <MenuItem
                onClick={() => {
                  localStorage.setItem("language", "ko");
                  i18n.changeLanguage("ko");
                  setLangIconAnchorEl(null);
                }}
              >
                <ListItemText primary={t("mainNavbar.korean")} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  localStorage.setItem("language", "en");
                  i18n.changeLanguage("en");
                  setLangIconAnchorEl(null);
                }}
              >
                <ListItemText primary={t("mainNavbar.english")} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  localStorage.setItem("language", "cn");
                  i18n.changeLanguage("cn");
                  setLangIconAnchorEl(null);
                }}
              >
                <ListItemText primary={t("mainNavbar.chinese")} />
              </MenuItem>
            </PxMenu>
          </GridItem>
        </GridContainer>
      </>
    </StyledNavbar>
  );
};

interface ReduxStateProps {
  users: User;
}

const mapStateToProps = (state: AppState) => ({
  users: state.users,
});

interface DispatchProps {
  removeUser: () => void;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>): DispatchProps => ({
  removeUser: bindActionCreators(removeUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainNavbar);
