import React from "react";
import styled from "styled-components";
import WarningIcon from "@material-ui/icons/Warning";
import { useTranslation } from "react-i18next";

const DefaultNotice = styled.div`
  background-color: rgb(242, 231, 254);
  padding: 17px 16px 15px;
  color: rgb(81, 45, 168);
  font-size: 1rem;
  text-align: center;
  margin-bottom: 10px;
`;

export default () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  return (
    <DefaultNotice>
      <WarningIcon style={{ width: "1.2rem" }} /> &nbsp;{" "}
      {t("registeringForm.defaultAlert")}
    </DefaultNotice>
  );
};
