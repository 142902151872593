import {Paper} from "@material-ui/core";
import React, {useEffect} from "react";
import {Area, Bar, CartesianGrid, Cell, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {ChartDataType} from "./RequestDashboard";

const colors = ["#8884d8", "#82ca9d", "#ffc658", "#ff8042", "#413ea0"];

type Props = {
  title: string;
  chartData: Array<ChartDataType>;
};

const CustomBarChart: React.FC<Props> = ({title, chartData}) => {
  useEffect(() => {
    console.log(`chartData : `, chartData);
  }, [chartData]);

  return (
    <Paper elevation={8} style={{padding: 16, width: "100%", height: "500px", marginBottom: "10px"}}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          layout="vertical"
          data={chartData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid />
          <XAxis type="number" domain={[0, 300]} />
          <YAxis dataKey="name" type="category" scale="band" />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" barSize={20} fill="#413ea0">
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default CustomBarChart;
