import { Dispatch } from "redux";
import Alert from "../types/models/Alert";
import { setPrinterLoadingAction } from "./printers";

interface DrawDeviceFontParamsType {
  text: string;
  x: number;
  y: number;
  fontType: string;
  widthEnlarge: number;
  heightEnlarge: number;
  rotation: number;
  invert: number;
  bold: number;
  alignment: number;
}

interface DrawBlockParamsType {
  startHorizontal: number;
  startVertical: number;
  endHorizontal: number;
  endVertical: number;
  option: string;
  thickness: number;
}

var paperWidth = 720;
var label_data = { id: 1, functions: {} };
var label_func: any = {};
var incLabelNum = 0;
var result: string = "";

function setResult(value: string) {
  result = value;
}

function clearBuffer() {
  const _a = { clearBuffer: [] };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;

  return JSON.stringify(label_data);
}

function printBuffer() {
  switch (arguments.length) {
    case 0:
      const a = { printBuffer: [] };
      label_func["func" + incLabelNum] = a;
      incLabelNum++;
      break;
    case 1:
      const b = { printBuffer: [arguments[0]] };
      label_func["func" + incLabelNum] = b;
      incLabelNum++;
      break;
  }
}

function setSpeed(speed: 0 | 1 | 2 | 3 | 4 | 5 | 6) {
  var _a = { setSpeed: [speed] };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;
}

function setDensity(density: number) {
  // 0~20
  var _a = { setDensity: [density] };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;
}

function setWidth(width: number) {
  var _a = { setWidth: [width] };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;
}

function drawDeviceFont({
  text,
  x,
  y,
  fontType,
  widthEnlarge,
  heightEnlarge,
  rotation,
  invert,
  bold,
  alignment,
}: DrawDeviceFontParamsType) {
  var _a = {
    drawDeviceFont: [
      text,
      x,
      y,
      fontType,
      widthEnlarge,
      heightEnlarge,
      rotation,
      invert,
      bold,
      alignment,
    ],
  };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;
}

function drawBlock({
  startHorizontal,
  startVertical,
  endHorizontal,
  endVertical,
  option,
  thickness,
}: DrawBlockParamsType) {
  var _a = {
    drawBlock: [
      startHorizontal,
      startVertical,
      endHorizontal,
      endVertical,
      option,
      thickness,
    ],
  };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;
}

interface DrawQrCodePrams {
  data: string;
  x: number;
  y: number;
  model: number;
  eccLevel: number;
  size: number;
  rotation: number;
}

function drawQRCode({
  data,
  x,
  y,
  model,
  eccLevel,
  size,
  rotation,
}: DrawQrCodePrams) {
  var _a = { drawQRCode: [data, x, y, model, eccLevel, size, rotation] };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;
}

function getLabelData() {
  label_data.functions = label_func;
  label_func = {};
  incLabelNum = 0;

  return JSON.stringify(label_data);
}

function makeResultInquiryData(
  requestId: string,
  responseId: string,
  timeout: number
) {
  return (
    '{"RequestID":' +
    requestId +
    ',"ResponseID":"' +
    responseId +
    '","Timeout":' +
    timeout +
    "}"
  );
}

export const requestPrint = (
  serverURL: string,
  strPrinterName: string,
  strSubmit: any,
  dispatch: Dispatch<any>
) => {
  const requestURL = serverURL + strPrinterName;
  const xmlHttpReq = new XMLHttpRequest();

  if (xmlHttpReq === undefined) {
    return;
  }

  xmlHttpReq.open("POST", requestURL, true);

  xmlHttpReq.setRequestHeader(
    "Content-Type",
    "application/x-www-form-urlencoded"
  );

  console.log(`request data : ${strSubmit} `)

  xmlHttpReq.send(strSubmit);

  xmlHttpReq.onreadystatechange = function () {
    console.log(`xmlHttpReq : `,xmlHttpReq)
    if (xmlHttpReq.readyState === 4 && xmlHttpReq.status === 200) {
      dispatch(setPrinterLoadingAction(true));
      const res = JSON.parse(xmlHttpReq.responseText);
      const ret = res.Result;
      if (ret.search("ready") >= 0 || ret.search("progress") >= 0) {
        checkResult(
          serverURL,
          "POST",
          strPrinterName,
          res.RequestID,
          res.ResponseID,
          dispatch
        );
      } else if (ret.search("duplicated") >= 0) {
        const alert: Alert = {
          id: "printerSuccess",
          msg: "명찰 출력에 성공하였습니다. 잠시만 기다려주세요.",
          alertType: "warning",
        };
        dispatch(setPrinterLoadingAction(false, alert));
      }
    } else if (xmlHttpReq.readyState === 4 && xmlHttpReq.status === 404) {
      const alert: Alert = {
        id: "printerNotFound",
        msg: "프린터를 찾을 수 없습니다. 프린터 연결을 확인해주세요.",
        alertType: "warning",
      };
      dispatch(setPrinterLoadingAction(false, alert));
    } else if (xmlHttpReq.readyState === 4) {
      const alert: Alert = {
        id: "printerServerNotFound",
        msg: "프린터 서버를 찾을 수 없습니다.",
        alertType: "warning",
      };
      dispatch(setPrinterLoadingAction(false, alert));
    }
  };
};

interface PrintLabelParamsType {
  applicantId: number;
  eventName: string;
  subEventName?: string;
  companyName: string;
  position: string;
  name: string;
  type: string;
  ipAddress: string;
  printerName: string;
  dispatch: Dispatch<any>;
}

interface DrawVectorFontParamType {
  text: string;
  x: number;
  y: number;
  fontType: string;
  fontWidth: number;
  fontHeight: number;
  rightSpacing: number;
  bold: 0 | 1;
  invert: 0 | 1;
  italic: 0 | 1;
  rotation: 0 | 1 | 2 | 3; // 0: 회전안함, 1: 90도, 2: 180도, 3: 270도
  alignment: 0 | 1 | 2; // 0: 왼쪽정렬, 1: 오른쪽정렬, 2: 중앙정렬
  rtol: boolean; // true: right to left, false: left to right 문자열 인쇄방향
}

function drawVectorFont({
  text,
  x,
  y,
  fontType,
  fontWidth,
  fontHeight,
  rightSpacing,
  bold,
  invert,
  italic,
  rotation,
  alignment,
  rtol,
}: DrawVectorFontParamType) {
  var _a = {
    drawVectorFont: [
      text,
      x,
      y,
      fontType,
      fontWidth,
      fontHeight,
      rightSpacing,
      bold,
      invert,
      italic,
      rotation,
      alignment,
      rtol,
    ],
  };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;
}


// function drawVectorFont(text:any, x:any, y:any, fontType:any, fontWidth:any, fontHeight:any, rightSpacing:any, bold:any, invert:any, italic:any, rotation:any, alignment:any, rtol:any) {
//   var _a = {drawVectorFont:[text, x, y, fontType, fontWidth, fontHeight, rightSpacing, bold, invert, italic, rotation, alignment, rtol]};
//   label_func["func"+incLabelNum] = _a;
//   incLabelNum++;
// }


export function printLabel({
  applicantId,
  eventName,
  subEventName,
  companyName,
  position,
  name,
  type,
  dispatch,
  ipAddress,
  printerName,
}: PrintLabelParamsType) {
  clearBuffer();

  // setSpeed(4);
  // setDensity(20);
  setWidth(paperWidth);

  if (subEventName) {
    drawVectorFont({
      text: subEventName,
      x: 380,
      y: 140,
      fontType: "K",
      fontWidth: 100,
      fontHeight: 100,
      rightSpacing: 0,
      bold: 1, // 바탕전환
      alignment: 2,
      invert: 0,
      italic: 0,
      rotation: 0,
      rtol: false,
    });
  }

  // 이벤트 이름
  drawVectorFont({
    // text:eventName, // 최대 15자
    text:"길게길게길게길게길게12345123123",
    x: 350,
    y: subEventName ? 180 : 160,
    fontType: "K",
    fontWidth: 80,
    fontHeight: 80,
    rightSpacing: 0,
    bold: 1,
    alignment: 2,
    invert: 0,
    italic: 0,
    rotation: 0,
    rtol: false,
  });

  // // 회사이름
  drawVectorFont({
    text: companyName ?? "",
    x: 350, //companyTxtX,
    y: 350,
    fontType: "K",
    fontWidth: 60,
    fontHeight: 60,
    rightSpacing: 0,
    bold: 0,
    alignment: 2,
    invert: 0, // 배경전환
    italic: 0,
    rotation: 0,
    rtol: false,
  });

  // // 회사이름
  drawVectorFont({
    text: name,
    x: 350, //companyTxtX,
    y: 450,
    fontType: "K",
    fontWidth: 80,
    fontHeight: 80,
    rightSpacing: 0,
    bold: 1,
    alignment: 2,
    invert: 0, // 배경전환
    italic: 0,
    rotation: 0,
    rtol: false,
  });

  // 직함
  // drawVectorFont({
  //   text: position ?? "",
  //   x: 430, //positionTxtX,
  //   y: 670,
  //   fontType: "K",
  //   fontWidth: 60,
  //   fontHeight: 60,
  //   rightSpacing:0,
  //   bold: 0,
  //   alignment: 2,
  //   invert: 0,
  //   italic: 0,
  //   rotation: 0,
  //   rtol: false,
  // });

  drawQRCode({
    data: JSON.stringify({ id: applicantId }), //applicantId
    x: 405,
    y: 610,
    model: 0,
    eccLevel: 0,
    size: 5,
    rotation: 1,
  });

  // 타입 (seller or buyer etc)
  drawVectorFont({
    text: type,
    x: 350, //positionTxtX,
    y: 780,
    fontType: "K",
    fontWidth: 80,
    fontHeight: 80,
    rightSpacing: 0,
    bold: 0,
    alignment: 2,
    invert: 0,
    italic: 0,
    rotation: 0,
    rtol: false,
  });

  printBuffer();

  const strSubmit = getLabelData();

  requestPrint(
    `http://${ipAddress}:18080/WebPrintSDK/`,
    printerName,
    strSubmit,
    dispatch
  );
}

function checkResult(
  serverURL: string,
  method: string,
  strPrinterName: string,
  requestId: string,
  responseId: string,
  dispatch: Dispatch<any>
) {
  //_callback
  const requestURL = serverURL + strPrinterName + "/checkStatus";

  const xmlHttpCheck = new XMLHttpRequest();

  const inquiryData = makeResultInquiryData(requestId, responseId, 30);

  xmlHttpCheck.open(method, requestURL, true);
  xmlHttpCheck.setRequestHeader(
    "Content-Type",
    "application/x-www-form-urlencoded"
  );
  xmlHttpCheck.send(inquiryData);
  xmlHttpCheck.timeout = 5000;

  xmlHttpCheck.onreadystatechange = function () {
    dispatch(setPrinterLoadingAction(true));
    if (xmlHttpCheck.readyState === 4 && xmlHttpCheck.status === 200) {
      const res = JSON.parse(xmlHttpCheck.responseText);
      const ret = res.Result;

      if (ret.search("ready") >= 0 || ret.search("progress") >= 0) {
        checkResult(
          serverURL,
          method,
          strPrinterName,
          requestId,
          responseId,
          dispatch
        );
      } else {
        const alert: Alert = {
          id: "printerSuccess",
          msg: "명찰 출력에 성공하였습니다. 잠시만 기다려주세요.",
          alertType: "success",
        };

        dispatch(setPrinterLoadingAction(false, alert));
      }
    } else if (xmlHttpCheck.readyState === 4 && xmlHttpCheck.status === 404) {
      const alert: Alert = {
        id: "printerNotFound",
        msg: "프린터를 찾을 수 없습니다. 프린터 연결을 확인해주세요.",
        alertType: "warning",
      };
      dispatch(setPrinterLoadingAction(false, alert));
    } else if (xmlHttpCheck.readyState === 4) {
      const alert: Alert = {
        id: "printerServerNotFound",
        msg: "프린터 서버를 찾을 수 없습니다.",
        alertType: "warning",
      };
      dispatch(setPrinterLoadingAction(false, alert));
    }
  };
}
