import React from "react";
import styled, { css } from "styled-components";

// material ui comp
import Grid, { GridProps } from "@material-ui/core/Grid";

interface PxGridItemProps extends GridProps {
  fullwidth?: boolean;
}

const PxGridItem: React.FC<PxGridItemProps> = styled(
  (props: PxGridItemProps) => {
    const { fullwidth, ...rest } = props;
    return <Grid item {...rest} />;
  }
)`
  ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
    `}
`;

export default PxGridItem;

// material custom component 만들기 방법2
// const CustomGrid = styled(Grid)``;

// const PxGridItem: React.FC<PxGridItemProps & GridProps> = (props) => {
//   return <CustomGrid item {...props} />;
// };
