// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, {useEffect, useState} from "react";
import {DeviceLabels, Heading} from "amazon-chime-sdk-component-library-react";
import MeetingJoinDetails from "../../containers/MeetingJoinDetails";
import {StyledLayout} from "./Styled";
import DeviceSelection from "../../components/DeviceSelection";
import DevicePermissionPrompt from "../../containers/DevicePermissionPrompt";
import {useAppState} from "../../providers/AppStateProvider";
import {useMeetingManager} from "amazon-chime-sdk-component-library-react";
import {createGetAttendeeCallback, fetchMeeting} from "../../utils/api";
import {MeetingSessionConfiguration} from "amazon-chime-sdk-js";
import {MeetingMode} from "../../types";
import {AppState} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import {v4} from "uuid";
import {removeLoadingAction, setLoadingAction} from "../../../actions/loading";
import {setAlertAction, removeAlertAction} from "../../../actions/alerts";
import {useHistory} from "react-router-dom";

const DeviceSetup: React.FC = () => {
  const history = useHistory();
  const meetingManager = useMeetingManager();
  const {id, email, userId, name, isAuthenticated} = useSelector((state: AppState) => state.users);
  const subEventId = useSelector((state: AppState) => state.subEvents.subEvent?.id);
  const dispatch = useDispatch();
  // const meeting = useSelector((state: AppState) => state.meetings.meetingVideo);
  const meeting = useSelector((state: AppState) => state.meetings.meetingVideo);

  const [isLoading, setIsLoading] = useState(false);

  const {
    region,
    meetingId,
    localUserName,
    meetingMode,
    enableSimulcast,
    priorityBasedPolicy,
    keepLastFrameWhenPaused,
    isWebAudioEnabled,
    videoTransformCpuUtilization: videoTransformCpuUtilization,
    setJoinInfo,
    isEchoReductionEnabled,
    toggleEchoReduction,
    toggleWebAudio,
    toggleSimulcast,
    togglePriorityBasedPolicy,
    toggleKeepLastFrameWhenPaused,
    setMeetingMode,
    setMeetingId,
    setLocalUserName,
    setRegion,
    setCpuUtilization,
  } = useAppState();

  useEffect(() => {
    createMeetingSession();
  }, []);

  const createMeetingSession = async () => {
    const meetingRoom = `${meeting?.subEventId}|${meeting?.id}` as string;
    setMeetingId(meetingRoom);

    const attendeeName: string = `<host>${name}|host` as string;
    setLocalUserName(attendeeName);
    if (meetingRoom == undefined || name == undefined) {
      dispatch(removeLoadingAction);
      dispatch(
        setAlertAction({
          id: "warning",
          msg: "비정상적인 접속입니다.",
          alertType: "warning",
        })
      );
      setTimeout(() => {
        dispatch(removeAlertAction);
      });
    }

    meetingManager.getAttendee = createGetAttendeeCallback(meetingRoom);

    try {
      dispatch(setLoadingAction());

      const {JoinInfo} = await fetchMeeting(
        // subEventId as string,
        meetingRoom,
        attendeeName,
        region,
        isEchoReductionEnabled
      );

      dispatch(removeLoadingAction());

      setJoinInfo(JoinInfo);
      const meetingSessionConfiguration = new MeetingSessionConfiguration(JoinInfo.Meeting, JoinInfo.Attendee);

      setRegion(JoinInfo.Meeting.MediaRegion);

      meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = enableSimulcast;

      if (priorityBasedPolicy) {
        meetingSessionConfiguration.videoDownlinkBandwidthPolicy = priorityBasedPolicy;
      }

      meetingSessionConfiguration.keepLastFrameWhenPaused = keepLastFrameWhenPaused;

      await meetingManager.join({
        meetingInfo: JoinInfo.Meeting,
        attendeeInfo: JoinInfo.Attendee,
        deviceLabels: DeviceLabels.AudioAndVideo,
      });

      setMeetingMode(MeetingMode.Attendee);
    } catch (error) {
      dispatch(removeLoadingAction());
      console.log(`device error : ${error}`, error);
    }
  };

  return (
    <StyledLayout>
      <Heading tag="h1" level={3} css="align-self: flex-start">
        Device settings
      </Heading>
      <DeviceSelection />
      <MeetingJoinDetails />
      <DevicePermissionPrompt />
    </StyledLayout>
  );
};

export default DeviceSetup;
