import Speaker from "../types/models/Speaker";
import SpeakerActionTypes from "../types/actions/SpeakerAction";

const speakerDefaultState: Speaker = {
  speaker: {
    subEventId: "", // 서브이벤트 id
    name: "", // 이름
    belongs: "", // 소속
    title: "", // 직함
    mobileNumber: "", // 휴대번호
    email: "", // 이메일
    introduce: "", // 소개
    photo: "", // 첨부파일
    photoFile: {},
  },
  speakers: [],
};

const speakerReducer = (
  state = speakerDefaultState,
  action: SpeakerActionTypes
): Speaker => {
  switch (action.type) {
    case "CREATE_SPEAKER":
    case "GET_SPEAKER":
      return { speaker: action.speaker };
    case "GET_SPEAKERS":
      return {
        speakers: action.speakers,
      };
    default:
      return state;
  }
};

export default speakerReducer;
