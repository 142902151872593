import React, { useCallback } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SubEventInfo } from "../../../../types/models/SubEvent";
import Banner from "./Banner";

export default ({ subEvent }: { subEvent: SubEventInfo }) => {
  const history = useHistory();
  const [t] = useTranslation("lang", { useSuspense: false });
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      history.push(`/estimate/subEvent/${subEvent.id}/upgrade`);
    },
    [history, subEvent]
  );
  return (
    <Banner
      bgColor="#ff9500"
      IconNode={"FREE"}
      estimateLink={`/estimate/subEvent/${subEvent.id}/consulting`}
      actionDisabled={subEvent.mainEvent?.approval === "D"}
      actionBtnTypo={t("eventDetail.switchToPaid")}
      actionBtnClick={onClick}
      title={
        <Trans t={t} i18nKey="eventDetail.freePlanBannerTitle">
          상담회 <span style={{ color: "#512da8" }}>무료 플랜</span> 이용중
        </Trans>
      }
      body={
        <Trans t={t} i18nKey="eventDetail.freeBannerInfo">
          무료 플랜에서는 승인할 수 있는 참가자가 10명으로 제한됩니다. <br />
          플랜 변경 후 입금을 완료하시면 담당자가 신속히 확인 후 연락드리겠습니다.
        </Trans>
      }
    />
  );
};
