import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SearchAndPrintTable from "./SearchAndPrintTable";
import { printLabel } from "../../../../actions/deskPrint";
import { AppState } from "../../../../store";
import PxSelectBox from "../../../SelectBox/PxSelectBox";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {
  getPrinterAction,
  getPrinters,
  setPrinterLoadingAction,
} from "../../../../actions/printers";
import Alert from "../../../../types/models/Alert";
import { removeAlertAction, setAlertAction } from "../../../../actions/alerts";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 30,
  },
  buttonRoot: {
    display: "flex",
    justifyContent: "center",
    marginTop: 80,
    marginBottom: 30,
  },
  formControl: {
    minWidth: 150,
  },
}));

const RegistrationDesk = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const { printers, printer, loading, alert } = useSelector(
    (state: AppState) => state.printers
  );
  const [tableRefresh, setTableRefresh] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getPrinters());
  }, [dispatch]);

  useEffect(() => {
    if (loading === false && alert) {
      dispatch(setAlertAction(alert!));
      setTimeout(() => {
        dispatch(removeAlertAction(alert!.id));
        dispatch(setPrinterLoadingAction(false, undefined));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, alert, dispatch]);

  const printerOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (printers) {
      setTableRefresh(!tableRefresh);
      dispatch(
        getPrinterAction(
          printers.find((printer) => printer.id === event.target.value)!
        )
      );
    }
  };

  const onClickTestPrint = () => {
    if (subEvent?.mainEvent && printer) {
      printLabel({
        applicantId: 0,
        dispatch,
        eventName: subEvent.mainEvent.name,
        companyName: "테스트 회사",
        name: "테 스 형",
        position: "직 함",
        type: "타입",
        ipAddress: printer.ipAddress,
        printerName: printer.name,
      });
    } else {
      const alert: Alert = {
        id: "noPrinterAlert",
        msg: "프린터를 선택해주세요.",
        alertType: "warning",
      };
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

  return (
    <Container className={classes.container}>
      <Backdrop open={loading} style={{ zIndex: 1000, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.buttonRoot}>
        {printers && (
          <FormControl className={classes.formControl}>
            <PxSelectBox
              input={<OutlinedInput margin="dense" style={{ padding: 3 }} />}
              onChange={printerOnChange}
            >
              {printers.map((printer) => (
                <MenuItem value={printer.id}>{printer.name}</MenuItem>
              ))}
            </PxSelectBox>
          </FormControl>
        )}
        <Box width={10} />
        <PxButton
          backgroundcolor={selectedTab === 0 ? "purple" : "grey"}
          padding="10px 15px"
          onClick={() => {
            setSelectedTab(0);
          }}
        >
          <ButtonTypo>조회 및 출력</ButtonTypo>
        </PxButton>
        <Box width={10} />
        <PxButton
          backgroundcolor={selectedTab === 1 ? "purple" : "grey"}
          padding="10px 15px"
          onClick={() => {
            window.open(`/subEvent/${subEvent?.id}/onSiteRegit`, "_blank");
            // setSelectedTab(1);
          }}
        >
          <ButtonTypo>현장등록</ButtonTypo>
        </PxButton>
        <Box width={10} />
        <PxButton
          backgroundcolor="darkGrey"
          padding="10px 15px"
          onClick={onClickTestPrint}
        >
          <ButtonTypo>인쇄 테스트</ButtonTypo>
        </PxButton>
        {/* <Box width={10} />
        <PxButton
          backgroundcolor="grey"
          padding="10px 15px"
          onClick={() => {
            window.open(`/subEvent/${subEvent?.id}/qrScanner`, "_blank");
          }}
        >
          <ButtonTypo>QR Scanner</ButtonTypo>
        </PxButton> */}
      </div>

      <StyledDiv>
        {subEvent?.mainEvent && selectedTab === 0 && tableRefresh && (
          <SearchAndPrintTable
            mainEventName={
              subEvent === undefined ? "" : subEvent.mainEvent!.name
            }
          />
        )}
        {subEvent?.mainEvent && selectedTab === 0 && tableRefresh === false && (
          <SearchAndPrintTable
            mainEventName={
              subEvent === undefined ? "" : subEvent.mainEvent!.name
            }
          />
        )}
      </StyledDiv>
    </Container>
  );
};

export default RegistrationDesk;

const StyledDiv = styled.div`
  & button:focus {
    outline: none !important;
  }
  & .MuiPaper-elevation2 {
    box-shadow: none;
  }
  width: 100%;
  & th.MuiTableCell-root {
    width: 0 !important;
  }
  & div.MuiToolbar-root {
    padding-left: 0px !important;
  }
  & th.MuiTableCell-root,
  td.MuiTableCell-root {
    padding: 10px 0px;
  }
  & td.MuiTableCell-root {
    color: rgba(0, 0, 0, 0.6) !important;
  }
`;
