import React from "react";
import styled, { css } from "styled-components";

import Select, { SelectProps } from "@material-ui/core/Select";

interface PxSelectBoxProps extends SelectProps {
  border?: boolean;
}

const PxSelectBox: React.FC<PxSelectBoxProps> = styled((props: PxSelectBoxProps) => {
  const { border, ...rest } = props;
  return <Select {...rest} />;
})`
  ${(props) => {
    if (props.border !== undefined && !props.border) {
      return css`
        border: none;
      `;
    }
  }};
`;

export default PxSelectBox;
