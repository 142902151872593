import React from "react";
import styled, {css} from "styled-components";

// material ui comp
import TextField, {OutlinedTextFieldProps} from "@material-ui/core/TextField";

// Partial <> 안의 모든 props들을 optional로 바꿈
interface PxOutlinedTxtFieldProps extends Partial<OutlinedTextFieldProps> {
  padding?: string;
  inputpadding?: number;
  fontsize?: number;
  border?: string;
  cursor?: string;
  borderColor?: string;
  hoverColor?: string;
  focusColor?: string;
}

const PxOutlinedTxtField: React.FC<PxOutlinedTxtFieldProps> = styled((props: PxOutlinedTxtFieldProps) => {
  const {padding, fontsize, inputpadding, cursor, ...rest} = props;

  return <TextField variant="outlined" inputProps={{style: {padding: inputpadding, fontSize: fontsize, cursor: cursor}}} {...rest} />;
})`
  padding: ${props => (props.padding == null ? "0" : props.padding)};
  & fieldset {
    border: ${props => props.border || "1px solid rgba(0, 0, 0, 0.23)"};
    border-color: ${props => props.borderColor || "inherit"};
    &:hover {
      border-color: ${props => props.hoverColor || "inherit"};
    }
  },
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${props => props.focusColor || "inherit"};;
    }
  }
}
`;

PxOutlinedTxtField.defaultProps = {};

export default PxOutlinedTxtField;
