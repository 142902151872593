import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";

import { AppState } from "../../../../store";

// types
import SubHeadingTypo from "../../../Typhography/SubHeadingTypo";
import { ProgramInfo } from "../../../../types/models/Program";
import { SubEvenPathContext } from "../../../../layouts/SubEvent";

// actions
import { getSignedRequest, fileStateToInitial } from "../../../../actions/files";
import { createProgram } from "../../../../actions/program";
import { getSpeakers } from "../../../../actions/speaker";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import { TypographyProps } from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";

// icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// custom ui comp
import OutlinedTxtField from "../../../Inputs/OutlinedTxtField";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";
import PxEditor from "../../../Editor/PxEditor";
import { useTranslation } from "react-i18next";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const ProgramAdd = () => {
  const history = useHistory();
  const param: { id: string } = useParams();
  const dispatch = useDispatch();
  const { subEventId } = useContext(SubEvenPathContext);
  const [t] = useTranslation("lang", { useSuspense: false });
  const files = useSelector((state: AppState) => state.files);
  const speakers = useSelector((state: AppState) => state.speakers.speakers);

  const [program, setProgram] = useState<ProgramInfo>({
    subEventId: subEventId, // 서브이벤트 아이디
    title: "", // 제목
    content: "", // 내용
    startDate: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"), // 프로그램 시작일
    endDate: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"), // 프로그램 종료일
    atch: "-1", // 첨부파일
    atchFile: {},
    classification: "", // 분류
    speakerList: [], // 스피커
    createdDate: "",
    modifiedDate: "",
  });

  // 스피커 selectbox 목록 값
  const [speakerSelectLists, setSpeakerSelectList] = useState<any>();

  // 스피커 selectbox 목록 셋팅
  useEffect(() => {
    if (Array.isArray(speakers) && speakers.length > 0) {
      let selectBoxDataSettings: any = {};

      speakers.map((speaker) => {
        selectBoxDataSettings[
          `${speaker.id}`
        ] = `${speaker.name} / ${speaker.belongs} / ${speaker.title}`;
      });
      setSpeakerSelectList(selectBoxDataSettings);
    }
  }, [speakers]);
  const handleOnSpeakerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setProgram({ ...program, speakerList: event.target.value as string[] });
  };

  useEffect(() => {
    dispatch(getSpeakers(param.id));
  }, []);

  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    let name: string = e.target.name as string;
    let value: string = e.target.value as string;

    setProgram({ ...program, [name]: value });
  };

  // 에디터 내용 변경
  const handleContentChange = (value: string) => {
    setProgram({
      ...program,
      content: value as string,
    });
  };

  // 파일업로드 결과
  useEffect(() => {
    if (files.id !== "") {
      setProgram({ ...program, atchFile: files, atch: files.id });
    }

    // 리덕스 초기화
    dispatch(fileStateToInitial());
  }, [files]);

  // 파일 등록
  const handleAtchFileAdd = (
    e: React.ChangeEvent<{
      files: any;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();

    const file = e.target.files[0];
    const fileParts = file.name.split(".");
    const originalFileName = fileParts[0];
    const timestamp = new Date().getTime();
    const fileType = fileParts[1];
    const fileName = originalFileName + "&&" + timestamp + "." + fileType;
    const folderPath = "subEvent/program/";
    const fileSize = file.size;
    const gubun = ""; // 여러파일등록시 어떤 파일을 등록했는지 구분

    dispatch(getSignedRequest(file, fileName, fileType, folderPath, fileSize, gubun));
  };

  // 첨부파일 삭제
  const handleAtchFileRemove = () => {
    setProgram({ ...program, atchFile: {}, atch: "-1" });
  };

  // 이벤트 등록
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const programRes: any = await dispatch(createProgram(program));
    if (programRes === "success") history.goBack();
  };

  return (
    <Grid container>
      <Grid item>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
      </Grid>
      <Grid item style={{ padding: "0 0 0 64px" }}>
        <form onSubmit={onSubmit}>
          <SubTitle>제목</SubTitle>
          <OutlinedTxtField
            name="title"
            value={program.title}
            fullWidth
            onChange={handleOnChange}
            placeholder="제목을 입력해주세요"
          />

          <SubTitle>내용</SubTitle>
          <Grid sm={12} md={12} lg={12}>
            <PxEditor
              value={program.content}
              onChange={handleContentChange}
              folderPath="event/"
              placeholder={t("programAdd.typeTheContent")}
            />
          </Grid>

          <SubTitle>프로그램 기간</SubTitle>

          <Grid
            container
            alignContent="space-between"
            spacing={1}
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <Grid sm={6}>
              <TextField
                type="datetime-local"
                name="startDate"
                value={program.startDate}
                onChange={handleOnChange}
                variant="outlined"
                style={{ width: "100%" }}
                inputProps={{
                  style: {
                    padding: "10.5px 10px 10.5px 10px",
                    margin: 0,
                    minWidth: "235px",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6}>
              <TextField
                type="datetime-local"
                name="endDate"
                value={program.endDate}
                onChange={handleOnChange}
                variant="outlined"
                style={{ width: "100%" }}
                inputProps={{
                  style: {
                    padding: "10.5px 10px 10.5px 10px",
                    margin: 0,
                    minWidth: "235px",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <SubTitle>첨부파일</SubTitle>
          {Object.keys(program.atchFile).length === 0 &&
            JSON.stringify(program.atchFile) === JSON.stringify({}) && (
              <Grid>
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#eee",
                    padding: "9px 16px",
                  }}
                >
                  추가하기
                  <input
                    type="file"
                    style={{ display: "none" }}
                    name="file1"
                    onChange={handleAtchFileAdd}
                  />
                </Button>
              </Grid>
            )}
          {Object.keys(program.atchFile).length !== 0 &&
            JSON.stringify(program.atchFile) !== JSON.stringify({}) && (
              <Grid sm={12} md={12} lg={12}>
                <img src={program.atchFile.filePath} alt={program.atchFile.fileName} />
                <br />
                {program.atchFile.fileName}
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#eee",
                    padding: "4.5px 8px",
                    margin: "5px 10px",
                  }}
                  onClick={handleAtchFileRemove}
                >
                  삭제하기
                </Button>
              </Grid>
            )}

          {/* <SubTitle>프로그램 분류</SubTitle>
          <OutlinedTxtField
            name="classification"
            value={program.classification}
            fullWidth
            onChange={handleOnChange}
            placeholder="분류을 입력해주세요"
          /> */}
          {speakerSelectLists !== undefined && (
            <>
              <SubTitle>스피커</SubTitle>
              <Select
                fullWidth
                displayEmpty
                variant="outlined"
                style={{
                  padding: "4px 10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={program.speakerList}
                onChange={handleOnSpeakerChange}
                input={<Input />}
                renderValue={(selected: any | unknown) => {
                  if ((selected as string[]).length === 0) {
                    return "스피커를 선택해주세요";
                  }
                  var renderVal: string[] = [];
                  selected.map((selectedVal: string) =>
                    renderVal.push(speakerSelectLists[`${selectedVal}`])
                  );

                  return (renderVal as string[]).join(", ");
                }}
              >
                {Object.entries(speakerSelectLists).map((speakerValue: any) => (
                  <MenuItem key={speakerValue[0]} value={speakerValue[0]}>
                    <Checkbox checked={program.speakerList.indexOf(speakerValue[0]) > -1} />
                    <ListItemText primary={speakerValue[1]} />
                  </MenuItem>
                ))}
              </Select>
            </>
          )}

          <Grid
            container
            alignContent="space-between"
            spacing={1}
            style={{ justifyContent: "flex-end", marginTop: "32px" }}
          >
            <Grid item>
              <PxButton backgroundcolor="purple" type="submit">
                <ButtonTypo>프로그램 등록</ButtonTypo>
              </PxButton>
            </Grid>
            <Grid item>
              <PxButton backgroundcolor="grey">
                <ButtonTypo>취소</ButtonTypo>
              </PxButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ProgramAdd;
