import { EstimateProps } from "../components/SubEvent/Payment/ConsultingEstimate";
import { postRequest, putRequest } from "../utils/utils";

export const createEstimate = (data: EstimateProps) => {
    return postRequest(data, "/api/estimate");
  };


export const modifyEstimate =  (data: EstimateProps) => {
  return putRequest(data, "/api/estimate/modify");
};