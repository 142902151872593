import React, {useEffect} from "react";
import {useHistory, Link} from "react-router-dom";
import styled from "styled-components";
import Naver from "../../assets/images/icon-naver.svg";
import Facebook from "../../assets/images/icon-facebook.svg";
import Linkedin from "../../assets/images/icon-linkedin.svg";
import Insta from "../../assets/images/icon-instagram.svg";

// bootstrap component
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";

// custom Component
import CaptionTypo from "../Typhography/CaptionTypo";
import {useTranslation} from "react-i18next";

import Body1Typo from "../Typhography/Body1Typo";
import {EtcLinkCustom, ManualCumstom, SocialLinkCustom} from "../../types/models/Event";
import {Copyright} from "@material-ui/icons";

const StyledContainer = styled(Container)`
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;

  & div.nav {
    justify-content: center;

    & a {
      color: grey;
    }
  }
`;

const SectionBar = styled.span`
  ::before {
    content: "|";
    color: rgba(0, 0, 0, 0.38);
    font-size: 0.875rem;
    font-weight: 500;
    position: absolute;
    transform: translateY(calc(50% - 4px));
  }
`;

const StyleRow = styled(Row)`
  margin: 0;
`;

const Manual = styled.div`
  padding: 9px 36px;
  color: rgb(81, 45, 168);
  border: 1px solid rgb(81, 45, 168);
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;

interface Props {
  manual?: ManualCumstom;
  social?: SocialLinkCustom;
  etc?: EtcLinkCustom;
  copyrightHide?: boolean;
  customState?: boolean;
}

const Footer: React.FC<Props> = ({manual, social, etc, copyrightHide, customState}) => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const history = useHistory();
  const pathName = history.location.pathname;

  return (
    <StyledContainer fluid="true">
      <StyleRow style={{marginTop: 40}}>
        <Col>
          <Nav>
            <Nav.Item>
              <Nav.Link target="_blank" eventKey="link-2" href={manual?.url == "" || manual?.url == undefined || !customState ? t("footer.manualLink") : manual.url}>
                <Manual>{manual?.text == "" || manual?.text == undefined ? t("footer.onlineManual") : manual?.text}</Manual>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </StyleRow>
      <StyleRow style={{marginTop: 32}}>
        <Col>
          <Nav>
            {social && social.blog.hide === true && customState ? (
              <></>
            ) : (
              <Nav.Item>
                <Nav.Link target="_blank" eventKey="link-2" href={social == undefined || social?.blog.link == "" || !customState ? "https://blog.naver.com/pexpo" : social!.blog.link}>
                  <img src={Naver} />
                </Nav.Link>
              </Nav.Item>
            )}
            {social && social.facebook.hide === true && customState ? (
              <></>
            ) : (
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  eventKey="link-2"
                  href={social == undefined || social?.facebook.link == "" || !customState ? "https://www.facebook.com/leslie.park.984" : social!.facebook.link}
                >
                  <img src={Facebook} />
                </Nav.Link>
              </Nav.Item>
            )}
            {social && social.instagram.hide === true && customState ? (
              <></>
            ) : (
              <Nav.Item>
                <Nav.Link
                  eventKey="link-2"
                  target="_blank"
                  href={social == undefined || social?.instagram.link == "" || !customState ? "https://www.instagram.com/pexpo_kinterch/?hl=ko" : social!.instagram.link}
                >
                  <img src={Insta} />
                </Nav.Link>
              </Nav.Item>
            )}
            {social && social.linkedin.hide === true && customState ? (
              <></>
            ) : (
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  eventKey="link-2"
                  href={social == undefined || social?.linkedin.link || !customState ? "https://www.linkedin.com/in/leslie-park-cem-34098954/" : social!.linkedin.link}
                >
                  <img src={Linkedin} />
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </Col>
      </StyleRow>
      <StyleRow style={{marginTop: 20}}>
        <Col>
          <Nav activeKey="/main">
            <Nav.Item>
              <Nav.Link target="_blank" eventKey="link-1" href={etc?.term == "" || etc?.term == undefined || !customState ? "footer.termsOfUseLink" : etc.term}>
                <Body1Typo>{t("footer.termsOfUse")}</Body1Typo>
              </Nav.Link>
            </Nav.Item>
            <SectionBar />
            <Nav.Item>
              <Nav.Link target="_blank" eventKey="link-2" href={etc?.privacy_policy == "" || etc?.privacy_policy == undefined || !customState ? t("footer.privacyPolicyLink") : etc.privacy_policy}>
                <Body1Typo>{t("footer.privacyPolicy")}</Body1Typo>
              </Nav.Link>
            </Nav.Item>
            <SectionBar />
            <Nav.Item>
              <Nav.Link
                eventKey="link-2"
                onClick={() => {
                  window.location.assign(`mailto:${etc?.email_inquiry == "" || etc?.email_inquiry == undefined || !customState ? "info@pexpo.io" : etc.email_inquiry}`);
                }}
              >
                <Body1Typo>{t("footer.emailInquiry")}</Body1Typo>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </StyleRow>
      <StyleRow className="text-center text-muted">
        <Col
          className="p-4
        "
        >
          {copyrightHide && copyrightHide === true && customState ? <></> : <CaptionTypo>©2020 Pexpo. All rights reserved.</CaptionTypo>}
        </Col>
      </StyleRow>
    </StyledContainer>
  );
};

export default Footer;
