import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import axios from "axios";

import { AppState } from "../../../../store";

// types
import SubHeadingTypo from "../../../Typhography/SubHeadingTypo";
import { SubEvenPathContext } from "../../../../layouts/SubEvent";

// actions
import { getSignedRequest, fileStateToInitial } from "../../../../actions/files";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import { TypographyProps } from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// custom ui comp
import OutlinedTxtField from "../../../Inputs/OutlinedTxtField";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";
import throttle from "lodash/throttle";
import { PresenterType, Webinar } from "../../../../types/models/Webinar";
import WebinarPresenter from "./WebinarPresenter";
import { createWebinar } from "../../../../actions/webinar";
import { removeLoadingAction, setLoadingAction } from "../../../../actions/loading";
import PxGridContainer from "../../../Grid/PxGridContainer";
import Body1Typo from "../../../Typhography/Body1Typo";
import { getOnlyFileName, isDate } from "../../../../utils/utils";
import { useTranslation } from "react-i18next";
import { newAlert } from "../../../../actions/alerts";
import uuid from "uuid";
import { gmtTimeAdjustment } from "../../../../utils/momentTz";
import PxEditor from "../../../Editor/PxEditor";

interface SubTitleType extends TypographyProps {
  margin?: string;
  fontweight?: string;
}

export const SubTitle = styled((props: SubTitleType) => {
  const { margin, ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: ${(props) => props.margin ?? "22px 0 8px 0"};
  font-weight: bold;
`;

const WebinarAdd = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const dispatch = useDispatch();
  const { subEventId } = useContext(SubEvenPathContext);
  const [zooFolder, setZoomFolder] = useState<boolean>(false);

  const files = useSelector((state: AppState) => state.files);

  // user search autocomplete input
  const [userEmailInput, setUserEmailInput] = useState<string>("");
  // 검색한 user 리스트
  const [userList, setUserList] = useState<PresenterType[]>([]);
  const [presenterInfo, setPresenterInfo] = useState<PresenterType>({
    userId: "",
    emailId: "",
    company: t("webinarAdd.presenter"),
    phoneNumber: "",
    countryNumber: "82",
    email: "",
    name: "",
  });
  const [webinar, setWebinar] = useState<Webinar>({
    subEventId: subEventId, // 서브이벤트 아이디
    title: "", // 제목
    content: "", // 내용
    startDate: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"), // 프로그램 시작일
    endDate: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"), // 프로그램 종료일
    atchFiles: [],
    atchFileIds: [],
    createdDate: "",
    modifiedDate: "",
    zoomLink: "",
    isZoom: "Y",
  });
  const [isNew, setIsNew] = useState<boolean>(false);

  const getUserList = React.useMemo(
    () =>
      throttle(async (input: string) => {
        const result = await axios.get("/api/users/list", {
          params: { email: input },
        });

        const userListData: PresenterType[] = result.data.map((user: PresenterType) => {
          return {
            userId: user.id,
            name: user.name,
            emailId: user.email,
            email: user.email,
            phoneNumber: user.phoneNumber,
            countryNumber: user.countryNumber,
            company: user.company,
          };
        });

        setUserList(userListData);
      }, 1500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (userEmailInput.length > 2) {
      getUserList(userEmailInput);
    }
  }, [userEmailInput, getUserList]);

  //웨비나 정보입력
  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();

    setWebinar({
      ...webinar,
      [e.target.name as string]: e.target.value as string,
    });
  };
  //기간 입력
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = e.currentTarget;
    if (false === isDate(target.value)) return false;

    const startDate = target.name === "startDate" ? target.value : webinar.startDate;
    const endDate = target.name === "endDate" ? target.value : webinar.endDate;
    const type = target.name === "endDate" ? "end" : "start";
    const returnWorkingPeriod = gmtTimeAdjustment(startDate, endDate, type);

    setWebinar({
      ...webinar,
      startDate: returnWorkingPeriod.startTime,
      endDate: returnWorkingPeriod.endTime,
    });
  };

  //발표자 정보입력
  const handlePresenterInfoOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();

    setPresenterInfo({
      ...presenterInfo,
      [e.target.name as string]: e.target.value as string,
    });
  };

  // 에디터 내용 변경
  const handleContentChange = (value: string) => {
    setWebinar({
      ...webinar,
      content: value as string,
    });
  };

  // 파일업로드 결과
  useEffect(() => {
    if (files.id !== "") {
      const webinarFiles = webinar.atchFiles;
      webinarFiles?.push(files);
      const webinarFileIds = webinar.atchFileIds;
      webinarFileIds?.push(files.id);
      setWebinar({
        ...webinar,
        atchFiles: webinarFiles,
        atchFileIds: webinarFileIds,
      });

      // 파일 리덕스 초기화
      dispatch(fileStateToInitial());
    }
  }, [dispatch, files, webinar]);

  // 파일 등록
  const handleAtchFileAdd = (
    e: React.ChangeEvent<{
      files: any;
      name?: string | undefined;
      value?: string | undefined;
    }>
  ) => {
    e.preventDefault();

    const file = e.target.files[0];
    const fileParts = file.name.split(".");
    const originalFileName = fileParts[0];
    const timestamp = new Date().getTime();
    const fileType = file.type.split("/")[1];
    const fileName = originalFileName + "&&" + timestamp + "." + fileType;
    const folderPath = "subEvent/webinar/";
    const fileSize = file.size;
    const gubun = ""; // 여러파일등록시 어떤 파일을 등록했는지 구분

    e.target.value = "";

    dispatch(getSignedRequest(file, fileName, fileType, folderPath, fileSize, gubun));
  };

  // 첨부파일 삭제
  const handleAtchFileRemove = (index: number) => {
    const atchFiles = webinar.atchFiles;
    const atchFileIds = webinar.atchFileIds;
    atchFiles?.splice(index, 1);
    atchFileIds?.splice(index, 1);
    setWebinar({ ...webinar, atchFiles: atchFiles, atchFileIds: atchFileIds });
  };

  // 이벤트 등록
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setLoadingAction());
    //유효성 검사
    if (!webinar.title) {
      dispatch(newAlert(t("webinarAdd.typeTheSession"), uuid.v4()));
      // } else if (!webinar.zoomLink || webinar.zoomLink.indexOf("zoom.us") === -1) {
      //   dispatch(newAlert(t("webinarAdd.typeZoomLink"), uuid.v4()));
    } else if (!presenterInfo.userId && !isNew) {
      // 기존유저선택후 이메일 입력안했을시
      dispatch(newAlert(t("webinarAdd.typeTheEmailIDAndSelectAPresenterFromTheList"), uuid.v4()));
    } else if (!presenterInfo.name) {
      dispatch(newAlert(t("webinarAdd.typeTheName"), uuid.v4()));
    } else if (!presenterInfo.email && isNew) {
      // 새로운 유저 선택후 이메일 입력안했을시
      dispatch(newAlert(t("webinarAdd.typeTheEmailAddress"), uuid.v4()));
    } else if (!presenterInfo.phoneNumber) {
      dispatch(newAlert(t("webinarAdd.typeThePhoneNo"), uuid.v4()));
    } else if (!webinar.content) {
      dispatch(newAlert(t("webinarAdd.typeTheContent"), uuid.v4()));
    } else {
      const webinarData: Webinar & PresenterType = {
        ...webinar,
        ...presenterInfo,
      };

      const result: any = await dispatch(createWebinar(webinarData));

      dispatch(removeLoadingAction());

      if (result) {
        history.goBack();
      }
    }
    dispatch(removeLoadingAction());
  };

  return (
    <Grid container>
      <Grid item>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
      </Grid>
      <Grid item style={{ padding: "0 0 0 64px" }}>
        <form onSubmit={onSubmit}>
          <SubTitle>{t("webinarAdd.session")}</SubTitle>
          <OutlinedTxtField
            name="title"
            value={webinar.title}
            fullWidth
            onChange={handleOnChange}
            placeholder={t("webinarAdd.typeTheSession")}
          />

          {/* 웨비나 기간 */}
          <div>
            <SubTitle>{t("webinarAdd.date")}</SubTitle>
            <Grid
              container
              alignContent="space-between"
              spacing={1}
              style={{ width: "100%", justifyContent: "space-between" }}
            >
              <Grid sm={6}>
                <TextField
                  type="datetime-local"
                  name="startDate"
                  value={webinar.startDate}
                  onChange={handleDateChange}
                  variant="outlined"
                  style={{ width: "100%" }}
                  inputProps={{
                    style: {
                      padding: "10.5px 10px 10.5px 10px",
                      margin: 0,
                      minWidth: "235px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid sm={6}>
                <TextField
                  type="datetime-local"
                  name="endDate"
                  value={webinar.endDate}
                  onChange={handleDateChange}
                  variant="outlined"
                  style={{ width: "100%" }}
                  inputProps={{
                    style: {
                      padding: "10.5px 10px 10.5px 10px",
                      margin: 0,
                      minWidth: "235px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div>
            <SubTitle style={{ cursor: "pointer" }}>{t("webinarAdd.zoomLink")}</SubTitle>
            <OutlinedTxtField
              name="zoomLink"
              value={webinar.zoomLink}
              fullWidth
              onChange={handleOnChange}
              placeholder={t("webinarAdd.typeZoomLink")}
            />
          </div>

          {/* 발표자 */}
          <WebinarPresenter
            setPresenterInfo={setPresenterInfo}
            presenterInfo={presenterInfo}
            userEmailInput={userEmailInput}
            setUserEmailInput={setUserEmailInput}
            userList={userList}
            setUserList={setUserList}
            handleOnChange={handlePresenterInfoOnChange}
            setIsNew={setIsNew}
          />

          {/* 내용 */}
          <div>
            <SubTitle>{t("webinarAdd.content")}</SubTitle>
            <Grid sm={12} md={12} lg={12}>
              <PxEditor
                value={webinar.content}
                onChange={handleContentChange}
                folderPath="subEvent/webinar/"
                placeholder={t("webinarAdd.typeTheContent")}
              />
            </Grid>
          </div>

          {/* 첨부파일 */}
          <div>
            <SubTitle>{t("webinarAdd.attachFile")}</SubTitle>
            {/* 첨푸파일 목록 */}
            {webinar.atchFiles?.map((file, index) => (
              <PxGridContainer key={`atchFile-${index}`} direction="row" alignItems="center">
                <Body1Typo>{getOnlyFileName(file.fileName, file.fileType)}</Body1Typo>
                <PxButton
                  onClick={() => {
                    handleAtchFileRemove(index);
                  }}
                >
                  <ButtonTypo>{t("common.delete")}</ButtonTypo>
                </PxButton>
              </PxGridContainer>
            ))}
            {/* 첨부파일 추가 */}
            {webinar.atchFiles && webinar.atchFiles.length < 3 && (
              <Grid>
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#eee",
                    padding: "9px 16px",
                  }}
                >
                  {t("common.add")}
                  <input type="file" style={{ display: "none" }} onChange={handleAtchFileAdd} />
                </Button>
              </Grid>
            )}
          </div>

          <Grid
            container
            alignContent="space-between"
            spacing={1}
            style={{ justifyContent: "flex-end", marginTop: "32px" }}
          >
            <Grid item>
              <PxButton backgroundcolor="purple" type="submit">
                <ButtonTypo>{t("common.save")}</ButtonTypo>
              </PxButton>
            </Grid>
            <Grid item>
              <PxButton
                backgroundcolor="grey"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ButtonTypo>{t("common.cancel")}</ButtonTypo>
              </PxButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default WebinarAdd;
