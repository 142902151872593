import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getApplicant, resetApplicant, updateApplicant } from "../../../../actions/applicants";
import PxButton from "../../../Buttons/PxButton";
import PxGridContainer from "../../../Grid/PxGridContainer";
import PxOutlinedTxtField from "../../../Inputs/PxOutlinedTxtField";
import ButtonTypo from "../../../Typhography/ButtonTypo";
import SubHeadingTypo from "../../../Typhography/SubHeadingTypo";
import { makeStyles } from "@material-ui/core/styles";
import { RegitDeskApplicants } from "../../../../types/models/Applicants";
import { AppState } from "../../../../store";
import PxSelectBox from "../../../SelectBox/PxSelectBox";
import MenuItem from "@material-ui/core/MenuItem";
import { countryPhoneNumberCode } from "../../../../utils/constants";
import { useTranslation } from "react-i18next";
import PxOutlinedFormControl from "../../../Forms/PxOutlinedFormControl";
import { removeLoadingAction, setLoadingAction } from "../../../../actions/loading";
import { newAlert, setAlert } from "../../../../actions/alerts";

const useStyles = makeStyles(() => ({
  rootContainer: {
    marginTop: 80,
  },
  buttonRoot: {
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
  },
  outlinedInput: {
    width: 117,
    "& div": {
      padding: 10,
    },
  },
  cellPhoneInputField: {
    marginLeft: 20,
  },
}));

const ApplicantModify = () => {
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ id: string; secondId: string }>();

  const applicant = useSelector((state: AppState) => state.applicants.applicant);

  const [applicantInfo, setApplicantInfo] = useState<RegitDeskApplicants>({
    id: "",
    businessCardId: "",
    name: "",
    position: "",
    company: "",
    countryNumber: "",
    phoneNumber: "",
    type: "",
    email: "",
  });

  useEffect(() => {
    dispatch(getApplicant(params.secondId));

    return () => {
      dispatch(resetApplicant());
    };
  }, [dispatch, params.secondId]);

  useEffect(() => {
    if (applicant) {
      setApplicantInfo(applicant);
    }
  }, [applicant]);

  const onChangeText = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string;
    }>
  ) => {
    e.persist();
    setApplicantInfo({
      ...applicantInfo,
      [e.target.name as string]: e.target.value,
    });
    setApplicantInfo((prevState) => ({
      ...prevState,
      [e.target.name as string]: e.target.value as string,
    }));
  };

  const onSubmit = async () => {
    dispatch(setLoadingAction());
    const result: any = await dispatch(updateApplicant(applicantInfo));

    if (result) {
      history.goBack();
      dispatch(newAlert("수정완료", "applicantModifyAlert", "success"));
    }
    dispatch(removeLoadingAction());
  };

  return (
    <Container maxWidth="sm" className={classes.rootContainer}>
      <PxGridContainer direction="column" alignContent="center">
        <SubHeadingTypo>이름</SubHeadingTypo>
        <PxOutlinedTxtField
          name="name"
          value={applicantInfo.name}
          fullWidth
          inputpadding={10}
          onChange={onChangeText}
        />
        <Box height={20} />
        <SubHeadingTypo>회사이름</SubHeadingTypo>
        <PxOutlinedTxtField
          name="company"
          onChange={onChangeText}
          value={applicantInfo.company}
          fullWidth
          inputpadding={10}
          disabled
        />
        <Box height={20} />
        <SubHeadingTypo>직책</SubHeadingTypo>
        <PxOutlinedTxtField
          name="position"
          onChange={onChangeText}
          value={applicantInfo.position}
          fullWidth
          inputpadding={10}
        />
        <Box height={20} />
        <SubHeadingTypo>이메일</SubHeadingTypo>
        <PxOutlinedTxtField
          name="email"
          onChange={onChangeText}
          value={applicantInfo.email}
          fullWidth
          inputpadding={10}
        />
        <Box height={20} />
        <SubHeadingTypo>핸드폰 번호</SubHeadingTypo>
        <div style={{ display: "flex" }}>
          <PxOutlinedFormControl>
            <PxSelectBox
              className={classes.outlinedInput}
              value={applicantInfo.countryNumber}
              onChange={onChangeText}
              displayEmpty
              name="countryNumber"
            >
              <MenuItem value="" disabled>
                {i18n.language === "ko" ? "국가코드" : "Country Code"}
              </MenuItem>
              {countryPhoneNumberCode.map((countryCode) => (
                <MenuItem value={countryCode.code}>
                  {i18n.language === "ko" ? countryCode.country : countryCode.countryEn}
                </MenuItem>
              ))}
            </PxSelectBox>
          </PxOutlinedFormControl>
          <PxOutlinedTxtField
            fullWidth
            name="phoneNumber"
            onChange={onChangeText}
            value={applicantInfo.phoneNumber}
            inputpadding={10}
            className={classes.cellPhoneInputField}
          />
        </div>
        <Box height={20} />
      </PxGridContainer>
      <div className={classes.buttonRoot}>
        <PxButton backgroundcolor="purple" padding="10px 40px 10px 40px" onClick={onSubmit}>
          <ButtonTypo>완료</ButtonTypo>
        </PxButton>
        <Box width={10} />
        <PxButton
          backgroundcolor="grey"
          padding="10px 40px 10px 40px"
          onClick={() => {
            history.goBack();
          }}
        >
          <ButtonTypo>취소</ButtonTypo>
        </PxButton>
      </div>
    </Container>
  );
};

export default ApplicantModify;
