import axios from "axios";
import moment from "moment";
import { CountryInfo } from "../types/models/Country";

// 접속 국가 조회 (main 에서 조회 후 리덕스 추가)
export const connectionCountry = async () => {
  // const path = `https://extreme-ip-lookup.com/json`;
  // const returnCountry = await axios
  //   .post(path, { headers: { "Content-Type": "text/json" } })
  //   .catch((err) => {
  //     return "";
  //   })
  //   .then((result: any) => {
  //     // 목록: https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a
  //     let resData = result?.data;
  //     if (resData === undefined)
  //       resData = {
  //         businessName: "",
  //         businessWebsite: "",
  //         city: "Seoul",
  //         continent: "Asia",
  //         country: "South Korea",
  //         countryCode: "KR",
  //         ipName: "",
  //         ipType: "Residential",
  //         isp: "Korea Telecom",
  //         lat: "37.566",
  //         lon: "126.9784",
  //         org: "Korea Telecom",
  //         query: "175.211.95.82",
  //         region: "Seoul",
  //         status: "success",
  //         thisCountryTz: "Asia/Seoul",
  //       };

  //     console.log(">>>>> 현재 접속국가입니다");
  //     console.log(resData);

  //     return resData;
  //   });

  const resData = {
    businessName: "",
    businessWebsite: "",
    city: "Seoul",
    continent: "Asia",
    country: "South Korea",
    countryCode: "KR",
    ipName: "",
    ipType: "Residential",
    isp: "Korea Telecom",
    lat: "37.566",
    lon: "126.9784",
    org: "Korea Telecom",
    query: "175.211.95.82",
    region: "Seoul",
    status: "success",
    thisCountryTz: "Asia/Seoul",
  };

  return resData;
};

// 타임존 지원하는 국가 목록
const licensedCountryList: any = {
  sweden: "Europe/Stockholm", // 스웨덴(스톡홀름): sweden | Europe/Stockholm
  israel: "Asia/Jerusalem", // 이스라엘(예루살렘): israel | Asia/Jerusalem
  singapore: "Asia/Singapore", // 싱가폴(싱가포르): singapore | Asia/Singapore
  united: "Europe/London", // 영국(런던): united Kingdom | Europe/London
  finland: "Europe/Helsinki", // 핀란드(헬싱키): finland | Europe/Helsinki
  vietnam: "Etc/GMT-7", // 배트남(헬싱키): finland | Etc/GMT-7
  bangkok: "Asia/Bangkok", //태국(방콕): Thailand | Bangkok
  // canada: "Canada/Central", // vpn 테스트용
  // netherlands: "Europe/Amsterdam", // vpn 테스트용
  netherlands: "Etc/GMT+1", // vpn 테스트용
};

// 나라 타임존 조회 [ 결과 예시: Asia/Seoul ]
export const countryTzCheck = (country: string) => {
  /* 변경전
  const path = `https://extreme-ip-lookup.com/json`;
  const returnCountry = await axios
    .post(path, { headers: { "Content-Type": "text/json" } })
    .catch((err) => {
      return "";
    })
    .then((result: any) => {
      // 목록: https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a
      const licensedCountry: any = {
        sweden: "Europe/Stockholm", // 스웨덴(스톡홀름): sweden | Europe/Stockholm
        israel: "Asia/Jerusalem", // 이스라엘(예루살렘): israel | Asia/Jerusalem
        singapore: "Asia/Singapore", // 싱가폴(싱가포르): singapore | Asia/Singapore
        united: "Europe/London", // 영국(런던): united Kingdom | Europe/London
        finland: "Europe/Helsinki", // 핀란드(헬싱키): finland | Europe/Helsinki
        vietnam: "Etc/GMT-7", // 배트남(헬싱키): finland | Etc/GMT-7
        // canada: "Canada/Central", // vpn 테스트용
        // netherlands: "Europe/Amsterdam", // vpn 테스트용
        netherlands: "Etc/GMT+9", // vpn 테스트용
      };
      const resData = result?.data;
      const thisCountry = resData?.country.toLowerCase();
      let returnValue = "Asia/Seoul";

      if (Object.prototype.hasOwnProperty.call(licensedCountry, thisCountry)) {
        returnValue = licensedCountry[thisCountry];
      }

      return returnValue;
    });
  */

  const licensedCountry: any = licensedCountryList;
  const thisCountry = country.toLowerCase();
  let returnValue = "Asia/Seoul";

  if (Object.prototype.hasOwnProperty.call(licensedCountry, thisCountry)) {
    returnValue = licensedCountry[thisCountry];
  }

  return returnValue;
};

/**
// 타임존 적용 시간 리턴
export const countryDate = (
  date: any,
  thisCountryTz: string,
  format?: string
) => {
  const moment = require("moment-timezone");

  let momentFormat = "YYYY-MM-DD HH:mm";
  if (format !== undefined) momentFormat = format;
  let returnValue = moment.utc(date).tz(thisCountryTz).format(momentFormat);

  return returnValue;
};
*/

// 날짜 비교 (시작일 종료일 비교, 벗어날경우 맞춤) // mismatch.tsx 파일 날짜 수정후 삭제 예정
export const checkTheWorkingPeriod = (
  startTime: any,
  endTime: any,
  standard: "start" | "end"
) => {
  const timeDiff = moment(startTime, "YYYY-MM-DD").diff(
    moment(endTime, "YYYY-MM-DD"),
    "day"
  );

  if (timeDiff > 0 && standard === "start") endTime = startTime;
  if (timeDiff > 0 && standard === "end") startTime = endTime;

  return {
    startTime: startTime,
    endTime: endTime,
  };
};

/** 날짜 가공 (시작일 종료일 비교, 벗어날경우 맞춤) */
export const gmtTimeAdjustment = (
  startTime: any, // 시작일
  endTime: any, // 종료일
  standard: "start" | "end" // 시작일시 종료일시 구분
) => {
  // 날짜 단순 비교
  const startIsoTime = new Date(startTime as string).toISOString();
  const endIsoTime = new Date(endTime as string).toISOString();

  const timeDiff = moment(startIsoTime, "YYYY-MM-DDTHH:mm").diff(
    moment(endIsoTime, "YYYY-MM-DDTHH:mm"),
    "minute"
  );

  return {
    startTime: startTime,
    endTime: endTime,
  };
};

export const timeBlurEvent = (
  startTime: any,
  endTime: any,
  standard: "start" | "end"
) => {

  const startIsoTime = new Date(startTime as string).toISOString();
  const endIsoTime = new Date(endTime as string).toISOString();

  const timeDiff = moment(startIsoTime, "YYYY-MM-DDTHH:mm").diff(
    moment(endIsoTime, "YYYY-MM-DDTHH:mm"),
    "minute"
  );

  if (timeDiff > 0 && standard === "start" ) {
    endTime = startTime;
  }
  if (timeDiff > 0 && standard === "end" ) {
    startTime = endTime;
  }

  return {
    startTime: startTime,
    endTime: endTime,
  };
}

// 나라 시차계산
export const parallaxCalculation = (
  standardTime: string,
  referenceCountry: CountryInfo | "", // 기준국가
  changeCountry: CountryInfo | "", // 변경국가
  timeFormat?: string
) => {
  let showFormat = "YYYY-MM-DDTHH:mm";
    if (timeFormat !== undefined && timeFormat !== "") showFormat = timeFormat;
  /** 나라별 gmt 체크 constants.ts countryCode.code2 값 */
  // 기준국가 지정
  const referenceCountryTz: string =
    referenceCountry !== undefined && referenceCountry !== ""
      ? referenceCountry.timezone.toString()
      : "Asia/Seoul";

  // 변경국가 지정
  const changeCountryTz: string =
    changeCountry !== undefined && changeCountry !== ""
      ? changeCountry.timezone.toString()
      : "Asia/Seoul";

  // 변환
  const localMoment = require("moment-timezone");

  const momentTzTime = localMoment.tz(
    standardTime,
    referenceCountryTz
  );
  
  /** 기존
    const showTime = momentTzTime.format(showFormat);
    const saveTime = momentTzTime.tz(changeCountryTz).format(showFormat);
    return saveTime;
  */

  // 변환, 기존과 다른점: gmt 0으로 만든 후 변환
  const passTzTime = momentTzTime.clone().tz("Iceland");

  let saveTime;
  if (changeCountryTz.indexOf("Etc/GMT") > -1) {
    let gmtTime = convertEtcToGmt(changeCountryTz);

    saveTime = passTzTime.clone().utcOffset(gmtTime);
  } else {
    saveTime = passTzTime.clone().tz(changeCountryTz);
  }

  return saveTime.format(showFormat);
};

// 현재시간
export const nowISOString = () => {
  return new Date(moment().format("YYYY-MM-DD")).toISOString();
};

// etc를 gmt 시간 변환 (etc 시간 +- 바껴있어서 보여줄때 바꿔야됨)
export const convertEtcToGmt = (paramCountryTz: string) => {
  let gmtTime = paramCountryTz.substring(7, 8) === "+" ? "-" : "+";
  if (parseInt(paramCountryTz.substring(8)) < 10) gmtTime += "0";
  gmtTime += paramCountryTz.substring(8) + ":00";

  return gmtTime;
};
