import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {getRegitUser, getRegitUserById} from "../../../../../../actions/registration";
import {AppState} from "../../../../../../store";
import CompanyInfo from "../../CompanyInfo/CompanyInfo";
import UserDetailTab from "./UserDetailTab";
import UserInfo from "./UserInfo";

const RegisterUserDetail: React.FC = () => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{id: string; secondId: string}>();
  const registerUser = useSelector((state: AppState) => state.registration.userDetail);

  // 승인상태 변경 값
  const [approvalModalData, setApprovalModalData] = useState({
    open: false,
    title: "",
    action: "",
    rowData: [],
    message: "",
  });

  useEffect(() => {
    dispatch(getRegitUserById(params.secondId));
  }, [dispatch]);

  // [승인, 거절, 취소, 삭제] 모달 오픈
  const stateChangeModalOpen = (title: string, action: string) => {
    setApprovalModalData({
      ...approvalModalData,
      open: true,
      title: title,
      action: action,
      message: "",
    });
  };

  // modal init
  const stateChangeModalClose = () => {
    setApprovalModalData({
      ...approvalModalData,
      open: false,
      title: "",
      action: "",
      message: "",
    });
  };

  // 거절사유 수정
  const stateChangeMessageChange = (message: string) => {
    setApprovalModalData({
      ...approvalModalData,
      message: message,
    });
  };

  // 상태변경 버튼 디자인
  const statusChangeButtonForm = (status: string, action: string) => {
    let title = "";

    if (status === "waiting" && action === "approval") {
      title = t("common.approval");
    } else if (status === "waiting" && action === "reject") {
      title = t("common.reject");
    } else if ((status === "approval" || status === "reject") && action === "cancel") {
      title = t("common.cancel");
    }

    if (action === "delete") {
      title = t("common.delete");
    }

    return title !== "" ? (
      <Button
        onClick={() => {
          stateChangeModalOpen(title, action);
        }}
        style={{
          border: "1px solid #465662",
          backgroundColor: "white",
          color: "#465662",
          margin: "3px",
        }}
        size="small"
      >
        {title}
      </Button>
    ) : null;
  };

  return (
    <>
      <Dialog open={approvalModalData.open} onClose={stateChangeModalClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{minWidth: 320}}>
          {approvalModalData.title}하시겠습니까?
        </DialogTitle>
        {approvalModalData.action === "reject" && (
          <DialogContent>
            <DialogContentText>반려사유를 입력해주세요</DialogContentText>
            <TextField autoFocus margin="dense" label="반려사유" type="text" value={approvalModalData.message} onChange={(e: any) => stateChangeMessageChange(e.target.value)} fullWidth />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={stateChangeModalClose} color="primary">
            {t("common.close")}
          </Button>
          <Button
            onClick={async () => {
              // await approvalChangeOneFunc(application, approvalModalData.action, approvalModalData.message);
              // await stateChangeModalClose();
            }}
            color="primary"
          >
            {t("common.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              margin: "23px",
              width: "18px",
              height: "18px",
              objectFit: "contain",
            }}
            onClick={() => history.goBack()}
          />
        </Grid>
        <Grid item style={{padding: "0 0 0 64px", width: "100%"}}>
          <div style={{height: "32px"}}>
            {t("common.registerDate")} : {moment(registerUser.createdDate).format("YYYY-MM-DD")}{" "}
          </div>
          <div
            style={{
              height: "32px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <div>{["approval", "reject", "cancel", "delete"].map(statusBtn => statusChangeButtonForm(application.approval!, statusBtn))}</div> */}
          </div>
          {/* 기업정보 component */}
          <UserInfo />
          {/* <CompanyInfo /> */}
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterUserDetail;
