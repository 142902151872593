import React, { createContext, useState, ReactNode } from "react";

export interface FormContextType {
  initialLoad: boolean;
  setInitialLoad: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormContext = createContext<FormContextType>(null!);

interface FormProviderProps {
  children: ReactNode;
}

const FormProvider = ({ children }: FormProviderProps) => {
  // 양식 만들기에서 db에 기존 저장한 양식데이터가 있을경우 처음만 load 하도록 true, false로 판단 (추후 자율양식 전체 구조를 다시 만들어야 함)
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  return (
    <FormContext.Provider
      value={{
        initialLoad,
        setInitialLoad,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormProvider;
